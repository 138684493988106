import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { PostCallApi } from "../../../../../../Action/Action";
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateBankDeposit() {
  const [distList, setDistList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolList, setSchoolList] = useState();
  const [scid, setScId] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (distList?.length > 0) {
      if (id) {
        BankDepositEdit(id);
      }
    }
    // eslint-disable-next-line
  }, [distList, id]);

  useEffect(() => {
    if (schoolList) {
      if (id) {
        setValue("schoolId", scid);
      }
    }
    // eslint-disable-next-line
  }, [schoolList, id]);

  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                schId: sd?.schoolDistrictId,
                districtName: sd?.districtName,
              };
            });
            setDistList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const onSubmit = async (data) => {
    let sendData = {
      ...data,
      createdBy: LogDetail?.userId,
      totalBankDepositAmount: data?.totalBankDepositAmount
        ? data?.totalBankDepositAmount
        : null,
      // bankDepositId: data?.bankDepositId ? data?.bankDepositId : null,
      bankDepositDate: data?.bankDepositDate ? data?.bankDepositDate : null,
      depositSlipNumber: data?.depositSlipNumber
        ? data?.depositSlipNumber
        : null,
      depositNotes: data?.depositNotes ? data?.depositNotes : null,
      schoolId: data?.schoolId ? data?.schoolId : null,
      schoolDistrictId: data?.schoolDistrictId ? data?.schoolDistrictId : null,
    };

    let alldata = {
      url: `/api/BankDeposit/CreateBankDeposit`,
      body: sendData,
      headers: headers,
    };
    setOpenCircle(true);

    let res = await PostCallApi(alldata);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        reset();
        navigate(-1);
      } else {
        setOpenCircle(false);
      }
    }
  };

  const BankDepositEdit = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/BankDeposit/GetBankDepositDataByID?bankDepositId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let bankData = res.data.payload[0];
            setScId(bankData?.schoolId);
            setValue("schoolDistrictId", bankData?.schoolDistrictId);
            getSchoolList(bankData?.schoolDistrictId);
            setValue("bankDepositId", bankData?.bankDepositId);
            setValue("depositSlipNumber", bankData?.depositSlipNumber);
            setValue(
              "bankDepositDate",
              bankData?.bankDepositDate
                ? bankData?.bankDepositDate?.split("T")[0]
                : ""
            );
            setValue(
              "totalBankDepositAmount",
              bankData?.totalBankDepositAmount
            );
            setValue("depositNotes", bankData?.depositNotes);
            //setOverDe(catData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
      });
  };

  const getSchoolList = (id) => {
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload?.map((sd, i) => {
                return { label: sd.schoolName, value: sd.schoolId };
              });
              setSchoolList(SdData);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createUserManage">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Create Bank Deposit</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          disabled={id ? true : false}
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.schId}>
                                {data?.districtName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> School
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School </option>
                          {schoolList?.map((item, i) => (
                            <option key={i} value={item?.value}>
                              {item?.label}
                            </option>
                          ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Deposit Slip Number{" "}
                          <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="depositSlipNumber"
                          {...register("depositSlipNumber", {
                            required: "This field is required",
                          })}
                        />
                        {errors.depositSlipNumber && (
                          <span role="alert" className="error_text">
                            {errors.depositSlipNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Bank Deposit Date <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("bankDepositDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.bankDepositDate && (
                          <span role="alert" className="error_text">
                            {errors.bankDepositDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Total Bank Deposit Amount{" "}
                          <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="totalBankDepositAmount"
                          {...register("totalBankDepositAmount", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid Number the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 75",
                            },
                          })}
                        />
                        {errors.totalBankDepositAmount && (
                          <span role="alert" className="error_text">
                            {errors.totalBankDepositAmount.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Deposit Notes <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="depositNotes"
                          {...register("depositNotes", {
                            required: "This field is required",
                          })}
                        />
                        {errors.depositNotes && (
                          <span role="alert" className="error_text">
                            {errors.depositNotes.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateBankDeposit;
