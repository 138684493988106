import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateStudentAttendance() {
  const [distList, setDistList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolList, setSchoolList] = useState();
  const [overDe, setOverDe] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getSchoolList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (distList?.length > 0) {
      if (id) {
        StudentAttendanceEdit(id);
      }
    }
    // eslint-disable-next-line
  }, [distList, id]);
  useEffect(() => {
    if (schoolList) {
      if (id) {
        setValue("schoolId", overDe?.sat?.schoolId);
      }
    }
    // eslint-disable-next-line
  }, [schoolList, id]);
  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                schId: sd?.schoolDistrictId,
                districtName: sd?.districtName,
              };
            });
            setDistList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = (id) => {
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setSchoolList(res.data.payload);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };
  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    setOpenCircle(true);
    Axios.post("/api/StudentAttendance/CreateStudentAttendance", sendData, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const StudentAttendanceEdit = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/StudentAttendance/GetStudentAttendanceDataByID?studentAttendanceId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let catData = res.data.payload;
            setValue("schoolDistrictId", catData?.sat?.schoolDistrictId);
            setValue("studentAttendanceId", catData?.sat?.studentAttendanceId);
            setOverDe(catData);
            setValue(
              "attendancePercentage",
              catData?.sat?.attendancePercentage
            );
            setValue("attendanceCount", catData?.sat?.attendanceCount);
            setValue("date", catData?.sat?.date);
            setValue("servingPeriod", catData?.sat?.servingPeriod);
            setValue("paymentStatus", catData?.sat?.paymentStatus);
            getSchoolList(catData?.sat?.schoolDistrictId);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createUserManage">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Create Student Attendance</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => {
                              getSchoolList(e.target.value);
                            },
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.schId}>
                                {data?.districtName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School Name <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          disabled={id ? true : false}
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School Name</option>
                          {schoolList &&
                            schoolList?.map((item, i) => (
                              <option value={item.schoolId} key={i}>
                                {item.schoolName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Attendance Percentage{" "}
                          <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Attendance Percentage"
                          {...register("attendancePercentage", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid amount the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                            },
                            validate: (val) => {
                              if (100 < val) {
                                return "You can not enter more than 100";
                              }
                            },
                          })}
                        />
                        {errors.attendancePercentage && (
                          <span role="alert" className="error_text">
                            {errors.attendancePercentage.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Attendance Count <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Attendance Count"
                          {...register("attendanceCount", {
                            required: "This field is required",
                          })}
                        />
                        {errors.attendanceCount && (
                          <span role="alert" className="error_text">
                            {errors.attendanceCount.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Date <span className="red-color">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("date", {
                            required: "This field is required",
                          })}
                        />
                        {errors.date && (
                          <span role="alert" className="error_text">
                            {errors.date.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Serving Period <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("servingPeriod", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        >
                          <option value="">Select Serving Period</option>
                          <option value="Breakfast">Breakfast</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.servingPeriod && (
                          <span className="formError errorMssg error_text">
                            {errors.servingPeriod.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Payment Status <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("paymentStatus", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        >
                          <option value="">Select Payment Status</option>
                          <option value="FREE">FREE</option>
                          <option value="PAID">PAID</option>
                          <option value="REDUCED">REDUCED</option>
                          <option value="FOSTER CHILD">FOSTER CHILD</option>
                          <option value="SNAP">SNAP</option>
                          <option value="TNAF">TNAF</option>
                        </select>
                        {errors.paymentStatus && (
                          <span role="alert" className="error_text">
                            {errors.paymentStatus.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateStudentAttendance;
