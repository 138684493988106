import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Backdrop, CircularProgress } from "@mui/material";
import PropTypes from "prop-types";
import { Box, TableHead, TableRow, TableSortLabel } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import Axios from "../../../../../Utils/AxiosConfig";
import "./index.scss";
import CheckboxTree from "react-checkbox-tree";
import { GetCallApi } from "../../../../../Action/Action";

const columns = [
  { field: "col0", headerName: "Menu Name" },
  //   { field: "col1", headerName: "Menu Type" },
  { field: "col2", headerName: "Parent Menu Name" },
  //   { field: "col3", headerName: "Menu Url" },
  { field: "col4", headerName: "Description" },
];

function RoleMenuAllocation() {
  const [open, setOpen] = useState(false);
  const [derror, setDerror] = useState();
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [roleList, setRoleList] = useState();
  const [selectUser, setSelectUser] = useState([]);
  // const [distList, setDistList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [mlist, setMlist] = useState([]);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [roleId, setRoleId] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    // const user = selectUser?.filter((item) => item.add === true);
    // let pr = mlist
    //   ?.filter((x) => checked?.includes(x.value.toString()))
    //   ?.map((x) => x?.menuId);
    let ch = mlist
      ?.filter((x) => x?.children?.length > 0)
      ?.map((x) => {
        if (
          x.children?.filter((c) => checked?.includes(c?.value?.toString()))
            ?.length > 0
        ) {
          return {
            ...x,
            children: x.children?.filter((c) =>
              checked?.includes(c?.value?.toString())
            ),
          };
        }
        return undefined;
      })
      ?.filter((x) => x !== undefined)
      ?.map((x) => x?.menuId);
    let meSeList = [...ch, ...checked?.map((x) => parseInt(x))];
    // return false;
    if (meSeList?.length >= 1) {
      const acc = {
        menuId: meSeList,
        roleId: data?.roleId,
        createdBy: LogDetail?.userId,
        // schoolDistrictId: data?.schoolDistrictId,
      };
      setOpen(true);
      let url = `/api/RoleMenuAllocation/AddRoleMenuAllocation`;
      Axios.post(url, acc, { headers }).then((response) => {
        if (response.data.success) {
          setOpen(false);
          setSelectUser([]);
          reset();
          function titleCase(str) {
            var splitStr = str?.toLowerCase().split(" ");
            for (var i = 0; i < splitStr.length; i++) {
              splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(" ");
          }
          setCheckBoxVal(false);
          setRoleId("");
          reset();
          setDerror();
          setMlist([]);
          setChecked([]);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: titleCase(response.data.message),
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          setDerror({ err_msg: response.data.message });
          setOpen(false);
        }
      });
    } else {
      setDerror({ err_msg: "Select minimun one menu" });
    }
  };
  useEffect(() => {
    // getDistrictList();
    getRoleList();
    // eslint-disable-next-line
  }, []);

  const GetMenuList = (mId) => {
    if (mId) {
      setOpen(true);
      let url = `/api/RoleMenuAllocation/GetRoleMenuByRoleId?roleId=${mId}`;
      Axios.get(url, { headers })
        .then((response) => {
          if (response.data.success) {
            setOpen(false);
            setRoleId(mId);
            setCheckBoxVal(false);
            const data = response?.data.payload?.map((item, i) => {
              return {
                no: i,
                add: item?.isAssigned,
                menuId: item?.menuId,
                col0: item?.menuName,
                col1: item?.menuType,
                col2: item?.parentMenuName,
                col3: item?.menuUrl,
                col4: item?.description,
              };
            });
            setSelectUser(data);
            let allMainCh = true;

            data?.map((as, i) => {
              if (as?.add === false) {
                return (allMainCh = false);
              }
              return 0;
            });

            if (allMainCh) {
              setCheckBoxVal(true);
            }

            let nr = response?.data.payload
              ?.filter((x) => x?.parentMenuId === 0)
              ?.map((me) => {
                let child = response?.data.payload
                  ?.filter((d) => d?.parentMenuId === me?.menuId)
                  ?.map((c) => {
                    return {
                      ...c,
                      value: c?.menuId,
                      label: c?.menuName,
                    };
                  });
                if (child?.length > 0) {
                  return {
                    ...me,
                    value: me?.menuId,
                    label: me?.menuName,
                    children: child,
                  };
                }
                return {
                  ...me,
                  value: me?.menuId,
                  label: me?.menuName,
                };
              });
            setMlist(nr);
            let ch = nr
              ?.filter((x) => x?.children?.length > 0)
              ?.map((c) => c?.children?.filter((a) => a?.isAssigned === true));
            ch = ch
              ?.filter((x) => x?.length > 0)
              ?.map((c) => c?.map((v) => v?.menuId));
            ch = ch.toString()?.split(",");
            let pr = nr
              ?.filter((x) => x?.children === undefined)
              ?.filter((x) => x?.isAssigned === true)
              ?.map((x) => x?.menuId);
            pr = pr.toString()?.split(",");
            let sendArr = [...ch, ...pr];

            setChecked(sendArr);
          } else {
            setOpen(false);
            setSelectUser([]);
            setMlist([]);
            setChecked([]);
          }
        })
        .catch((e) => {
          setOpen(false);
          console.log(e);
        });
    } else {
      setCheckBoxVal(false);
      setRoleId("");
      setSelectUser([]);
      setMlist([]);
      setChecked([]);
    }
  };
  // const getDistrictList = () => {
  //   Axios.get(
  //     `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
  //     { headers }
  //   )
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res.data.success) {
  //           const dlist = res.data.payload?.map((sd) => {
  //             return {
  //               schId: sd?.schoolDistrictId,
  //               Districtname: sd?.districtName,
  //             };
  //           });
  //           setDistList(dlist?.length > 0 ? dlist : []);
  //         }
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  const getRoleList = async (useDe) => {
    setRoleList([]);
    let seRole = {
      url: `/api/Roles/GetAllRoleData?userId=${LogDetail?.userId}`
    }
    let res = await GetCallApi(seRole)
    if (res?.status === 200 && res.data.success) {
      const rList = res?.data?.payload
      setRoleList(rList);
      if (useDe && rList) {
        setTimeout(() => {
          setValue("roleId", useDe?.roleId)
        }, 500);
      }
    } else {
      setRoleList([])
    }
  }

  // const getRoleList = (sch) => {
  //   setMlist([]);
  //   setChecked([]);
  //   if (sch) {
  //     let url = `/api/Roles/GetDistrictWiseRoleData?schoolDistrictId=${sch}`;
  //     Axios.get(url, { headers })
  //       .then((response) => {
  //         if (response.data.success) {
  //           const data = response?.data.payload;
  //           setRoleList(data);
  //           setValue("roleId", "");
  //           setCheckBoxVal(false);
  //           setSelectUser([]);
  //         }
  //       })
  //       .catch((error) => console.log(error));
  //   } else {
  //     setRoleList([]);
  //     setValue("roleId", "");
  //     setCheckBoxVal(false);
  //     setSelectUser([]);
  //   }
  // };

  const handleAll = (val) => {
    const s = selectUser?.map((item) => {
      return {
        ...item,
        add: val,
      };
    });
    setSelectUser(s);
  };
  // const handleMainCheck = (e, index, item) => {
  //   let allMainCh = true;

  //   const s = selectUser?.filter((se, i) => se.no !== index);

  //   let select = {
  //     ...item,
  //     no: index,
  //     add: e,
  //   };

  //   let arr = s;
  //   arr.splice(index, 0, select);
  //   setSelectUser(arr);
  //   if (e) {
  //     arr?.map((as, i) => {
  //       if (as.add === false) {
  //         allMainCh = false;
  //       }
  //       return 0;
  //     });

  //     if (allMainCh) {
  //       setCheckBoxVal(true);
  //     } else {
  //       setCheckBoxVal(false);
  //     }
  //   } else {
  //     setCheckBoxVal(false);
  //   }
  // };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F1F6FC",
    },
  }));
  // const [order, setOrder] = React.useState("asc");
  // const [orderBy, setOrderBy] = React.useState("col0");
  // function descendingComparator(a, b, orderBy) {
  //   if (
  //     b[orderBy]?.toString()?.toLowerCase() <
  //     a[orderBy]?.toString()?.toLowerCase()
  //   ) {
  //     return -1;
  //   }
  //   if (
  //     b[orderBy]?.toString()?.toLowerCase() >
  //     a[orderBy]?.toString()?.toLowerCase()
  //   ) {
  //     return 1;
  //   }
  //   return 0;
  // }

  // function getComparator(order, orderBy) {
  //   return order === "desc"
  //     ? (a, b) => descendingComparator(a, b, orderBy)
  //     : (a, b) => -descendingComparator(a, b, orderBy);
  // }
  // function stableSort(array, comparator) {
  //   const stabilizedThis = array?.map((el, index) => [el, index]);

  //   stabilizedThis?.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) {
  //       return order;
  //     }
  //     return a[1] - b[1];
  //   });
  //   return stabilizedThis?.map((el) => el[0]);
  // }
  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  // };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <StyledTableCell>
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                disabled={selectUser?.length === 0 ? true : false}
                checked={checkBoxVal}
                onChange={(e) => {
                  handleAll(e.target.checked);
                  setCheckBoxVal(e.target.checked);
                }}
              />
              <span className="checkmark"></span>
            </label>
            Add
          </StyledTableCell>
          {columns?.map((headCell) => {
            return (
              <StyledTableCell
                key={headCell?.field}
                align="left"
                sortDirection={orderBy === headCell?.field ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell?.field}
                  direction={orderBy === headCell?.field ? order : "asc"}
                  onClick={createSortHandler(headCell?.field)}
                >
                  {headCell.headerName}
                  {orderBy === headCell?.field ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  const handleSch = (e) => {
    GetMenuList(e);
  };
  const handleRemove = () => {
    setCheckBoxVal(false);
    setRoleId("");
    reset();
    setDerror();
    setSelectUser([]);
    setMlist([]);
    setChecked([]);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="rolemenu_listpage">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="product_box ">
                <div className="all_title ProductTitle">
                  Role Menu Allocation
                </div>
              </div>
              <form
                className=" mt-4 create_school_form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="row">
                  {/* <div className="col-md-4">
                    <div className="form-group access_role_select_box ">
                      <label>School District
                        <span className="red-color"> *</span>
                      </label>
                      <select
                        name=""
                        className="form-select"
                        {...register("schoolDistrictId", {
                          required: "This field is required",
                          onChange: (e) => getRoleList(e.target.value),
                        })}
                      >
                        <option value="">Select School District</option>
                        {distList &&
                          distList?.map((data, i) => (
                            <option key={i} value={data?.schId}>
                              {data?.Districtname}
                            </option>
                          ))}
                      </select>
                      {errors.schoolDistrictId && (
                        <span role="alert" className="error_text">
                          {errors.schoolDistrictId.message}
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className="col-md-4">
                    <div className="form-group access_role_select_box ">
                      <label className="form-label">Role Name
                        <span className="red-color"> *</span></label>
                      <select
                        className={`form-select `}
                        aria-label="Default select example"
                        {...register("roleId", {
                          required: "This field is required",
                          onChange: (e) => handleSch(e.target.value),
                        })}
                      >
                        <option value=""> Select Role Name</option>
                        {roleList &&
                          roleList?.map((data, i) => (
                            <option value={data?.roleId} key={i}>
                              {data?.roleName}
                            </option>
                          ))}
                      </select>
                      {errors?.roleId && (
                        <span role="alert" className="error_text">
                          {errors?.roleId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  {/* <div className="table-reponsive table-box">
                <Table className="table-striped">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody>
                    {stableSort(selectUser, getComparator(order, orderBy))?.map(
                      (item, index) => {
                        return (
                          <tr key={item?.no}>
                            <StyledTableCell className="column_data">
                              <label className="personal_detail_label">
                                <input
                                  type="checkbox"
                                  className="login_check"
                                  checked={item.add}
                                  onChange={(e) => {
                                    handleMainCheck(
                                      e.target.checked,
                                      item.no,
                                      item
                                    );
                                  }}
                                />
                                <span className="checkmark"></span>
                              </label>
                            </StyledTableCell>
                            {columns?.map((column) => {
                              const value = item[column?.field];

                              return (
                                <StyledTableCell
                                  className="column_data"
                                  key={column?.field}
                                >
                                  {column?.format && typeof value === "number"
                                    ? column?.format(value)
                                    : value}
                                </StyledTableCell>
                              );
                            })}
                          </tr>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </div> */}
                  <div className="add_family">
                    <CheckboxTree
                      nodes={mlist}
                      checked={checked}
                      expanded={expanded}
                      onCheck={(checked) => setChecked(checked)}
                      onExpand={(expanded) => setExpanded(expanded)}
                    />
                  </div>
                  {derror?.err_msg && (
                    <span role="alert" className="error_text">
                      {derror.err_msg}
                    </span>
                  )}
                  {roleId &&
                    <div className="form-group d-flex border-0 mt-3 px-3">
                      <Button className="theme_btn  me-3" type="submit">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn  btn_space"
                        onClick={handleRemove}
                      >
                        Cancel
                      </Button>
                    </div>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default React.memo(RoleMenuAllocation);
