import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const CreateFinanceIncome = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [incomeCategory, setIncomeCategory] = useState([]);
  const [incomeSubCategory, setIncomeSubCategory] = useState([]);
  const [updated, setUpdated] = useState("");
  const [distList, setDistList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);

  const { id } = useParams();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const { fields, append } = useFieldArray({
    control,
    name: "financeIncomeSubModels",
  });

  useEffect(() => {
    if (id) {
      getEditedData(id);
    } else {
      append();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    const districtList = LogDetail?.districtClasses?.map((x) => {
      return {
        value: x?.schoolDistrictId,
        label: x?.schoolDistrictName,
      }
    })
    setDistList(districtList ?? []);
    const schoolList = LogDetail?.schoolClasses?.map((x) => {
      return {
        value: x?.schoolId,
        label: x?.schoolName,
        schId: x?.schoolDistrictId
      }
    })
    setSchoolList(schoolList ?? []);
    if (!id) {
      setTimeout(() => {
        setValue("schoolDistrictId", districtList[0]?.value);
        let filterSchool = schoolList?.filter((x) => x?.schId === districtList[0]?.value)
        setTimeout(() => {
          setValue('schoolId', filterSchool?.length === 1 ? filterSchool[0]?.value : "")
          getIncomeCategory("", districtList[0]?.value, filterSchool?.length === 1 ? filterSchool[0]?.value : "");
          getIncomeSubCategory("", districtList[0]?.value, filterSchool?.length === 1 ? filterSchool[0]?.value : "");
        }, 500);
      }, 500);
    }
    // eslint-disable-next-line
  }, []);

  const handleDistrict = (sdId) => {
    fields?.map((_, ind) => {
      return (
        setValue(`financeIncomeSubModels.${ind}.incomeCategoryId`, "")
      )
    })
    if (sdId) {
      setTimeout(() => {
        setValue("schoolDistrictId", sdId);
        setTimeout(() => {
          setValue('schoolId', "")
          getIncomeCategory("", sdId, "");
          getIncomeSubCategory("", sdId, "");
        }, 500);
      }, 500);
    } else {
      setValue('schoolId', "")
      getIncomeCategory("", "", "");
      getIncomeSubCategory("", "", "");
    }
  }

  const handleSchool = (schoolId, distId) => {
    fields?.map((_, ind) => {
      return (
        setValue(`financeIncomeSubModels.${ind}.incomeCategoryId`, "")
      )
    })
    if (schoolId) {
      setTimeout(() => {
        setTimeout(() => {
          setValue('schoolId', schoolId)
          getIncomeCategory("", distId, schoolId);
          getIncomeSubCategory("", distId, schoolId);
        }, 500);
      }, 500);
    } else {
      setValue('schoolId', "")
      getIncomeCategory("", distId, "");
      getIncomeSubCategory("", distId, "");
    }
  }

  // const getSchoolList = async (id, schId) => {
  //   setSchoolList([]);
  //   let seData = {
  //     url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
  //   };
  //   let res = await GetCallApi(seData);

  //   if (res.status === 200) {
  //     if (res.data.success) {
  //       const data = res?.data.payload?.map((item) => {
  //         return {
  //           value: item?.schoolId,
  //           label: item?.schoolName,
  //         };
  //       });
  //       setSchoolList(data ?? []);
  //       if (schId && res.data.payload?.length > 0) {
  //         setTimeout(() => {
  //           setValue(`schoolId`,
  //             schId
  //           );
  //         }, 500);
  //       }
  //     }
  //   }
  // };

  // const getDistrictList = async (dis) => {
  //   let seData = {
  //     url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
  //   };
  //   let res = await GetCallApi(seData);

  //   if (res?.status === 200) {
  //     if (res.data.success) {
  //       const dlist = res.data.payload?.map((sd) => {
  //         return {
  //           value: sd?.schoolDistrictId,
  //           label: sd?.districtName,
  //         };
  //       });
  //       setDistList(dlist?.length > 0 ? dlist : []);
  //       if (dis && dlist?.length > 0) {
  //         setTimeout(() => {
  //           setValue(`schoolDistrictId`,
  //             dis
  //           );
  //         }, 500);
  //       }
  //     }
  //   }
  // };

  // const getPrevDistrictList = async (schId) => {
  //   setPreSchool([]);
  //   let seData = {
  //     url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
  //   };
  //   try {
  //     let res = await GetCallApi(seData);
  //     if (res?.status === 200) {
  //       if (res.data.success) {
  //         const districts = res.data.payload;

  //         const schoolPromises = districts.map(async (sd) => {
  //           setPreSchool([]);
  //           const apiUrl = {
  //             url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${sd?.schoolDistrictId}&userId=${LogDetail?.userId}`,
  //           };
  //           const res = await GetCallApi(apiUrl);
  //           if (res?.status === 200) {
  //             if (res.data.success) {
  //               return res.data.payload.map((school) => ({
  //                 value: school?.schoolId,
  //                 label: school?.schoolName,
  //                 schoolDistrictId: sd?.schoolDistrictId,
  //               }))
  //             }
  //           }
  //           return [];
  //         });

  //         const allSchools = (await Promise.all(schoolPromises)).flat();
  //         setPreSchool(allSchools);
  //         if (schId && res.data.payload?.length > 0) {
  //           setTimeout(() => {
  //             setValue(`schoolId`,
  //               schId
  //             );
  //           }, 500);
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching school districts or school lists:", error);
  //   }
  // };

  // get edit data

  const getEditedData = async (id) => {
    setOpen(true);
    let sendData = {
      url: `/api/FinanceIncome/GetFinanceIncomeDataById?incomeCategoryId=${id}`,
    };

    let res = await GetCallApi(sendData);
    setOpen(false);
    if (res?.status === 200 && res.data.success) {
      let editsiteData = res?.data.payload;
      let allD = [
        {
          incomeId: id,
          description: editsiteData.description,
          amount: editsiteData.amount,
          incomeDate: editsiteData.incomeDate.split("T")[0],
          incomeCategoryId: editsiteData.incomeCategoryId,
          incomeSubCategoryId: editsiteData.incomeSubCategoryId,
          schoolDistrictId: editsiteData?.schoolDistrictId,
          schoolId: editsiteData?.schoolId,
        },
      ];
      setValue("financeIncomeSubModels", allD);
      setValue("schoolDistrictId", editsiteData?.schoolDistrictId);
      setTimeout(() => {
        setValue("schoolId", editsiteData?.schoolId);
      }, 500);
      // getIncomeCategory(editsiteData.incomeCategoryId);
      // getIncomeSubCategory(editsiteData.incomeSubCategoryId);
      getIncomeCategory(editsiteData.incomeCategoryId, editsiteData?.schoolDistrictId, editsiteData?.schoolId);
      getIncomeSubCategory(editsiteData.incomeSubCategoryId, editsiteData?.schoolDistrictId, editsiteData?.schoolId);
      // getSchoolList(editsiteData?.schoolDistrictId, editsiteData?.schoolId);
    } else {
      append();
    }
  };

  // get all income category
  const getIncomeCategory = async (incCatId, scId, schList) => {
    setIncomeCategory([]);
    setOpen(true);
    let seData = {
      url: `/api/FinanceIncomeCategory/GetFinanceIncomeCategorysData?active=${"true"}`,
      body: {
        schoolDistrictId: scId ? scId : "00000000-0000-0000-0000-000000000000",
        schoolId: schList ? [schList] : []
      }
    };

    let resp = await PostCallApi(seData);
    setOpen(false);
    if (resp?.status === 200 && resp.data.success) {
      let allIncCategory = resp?.data?.payload?.map((x) => {
        return {
          ...x,
        };
      });
      setIncomeCategory(allIncCategory);
      if (incCatId && allIncCategory?.length > 0) {
        setTimeout(() => {
          setValue(`financeIncomeSubModels.${0}.incomeCategoryId`, incCatId);
        }, 500);
      }
    }
  };

  // get all income sub category
  const getIncomeSubCategory = async (incSubCatId, scId, schList) => {
    setIncomeSubCategory([]);
    setOpen(true);
    let seData = {
      url: `/api/FinanceIncomeSubCategory/GetFinanceIncomeSubCategorysData`,
      body: {
        schoolDistrictId: scId ? scId : "00000000-0000-0000-0000-000000000000",
        schoolId: schList ? [schList] : []
      }
    };

    let resp = await PostCallApi(seData);
    setOpen(false);
    if (resp?.status === 200 && resp.data.success) {
      let allSubIncome = resp?.data?.payload?.filter(
        (c) => c?.isActive === true
      );
      setIncomeSubCategory(allSubIncome);
      if (incSubCatId && allSubIncome?.length > 0) {
        setTimeout(() => {
          setValue(
            `financeIncomeSubModels.${0}.incomeSubCategoryId`,
            incSubCatId
          );
        }, 500);
      }
    }
  };

  // submit handler
  const onSubmit = async (data) => {
    setOpen(true);
    let expData = {
      createdBy: LogDetail?.userId,
      financeIncomeSubModels: data?.financeIncomeSubModels?.map((x) => {
        return {
          ...x,
          schoolDistrictId: data?.schoolDistrictId,
          schoolId: data?.schoolId
        }
      })
    };

    let seData = {
      url: `/api/FinanceIncome/CreateFinanceIncome`,
      body: expData,
    };

    let respo = await PostCallApi(seData);
    setOpen(false);
    if (respo?.status === 200 && respo.data.success) {
      Swal.fire({
        position: "top-end",
        width: 400,
        text: respo.data.message,
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "#48953e",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
      navigate(-1);
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        text: respo?.data?.message || "Something went wrong, please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };

  const handleRemove = (ind) => {
    let deleted = getValues("financeIncomeSubModels")?.filter(
      (_, i) => i !== ind
    );
    setValue("financeIncomeSubModels", []);
    setTimeout(() => {
      setValue(
        "financeIncomeSubModels",
        deleted?.map((x) => {
          return {
            ...x,
            incomeDate: x?.incomeDate?.toString() !== "Invalid Date"
              ? new Date(x?.incomeDate).toISOString()?.split("T")[0]
              : "",
          };
        })
      );
    }, 100);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_ExpenceCategory">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">
                {id ? "Edit" : "Create"} Finance Income
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)} className="primary-box">
                  <div className="row">
                    <div className="row border-bottom my-3">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>
                            District <span className="red-color">*</span>
                          </label>
                          <select
                            name=""
                            className="form-select"
                            {...register(`schoolDistrictId`, {
                              onChange: (e) => handleDistrict(e.target.value),
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select School District</option>
                            {distList &&
                              distList?.map((data, i) => (
                                <option key={i} value={data?.value}>
                                  {data?.label}
                                </option>
                              ))}
                          </select>
                          {errors?.schoolDistrictId && (
                            <span role="alert" className="error_text">
                              {errors?.schoolDistrictId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label>School  <span className="red-color">*</span> </label>
                          <select
                            name=""
                            className="form-select"
                            {...register(`schoolId`, {
                              required: "This field is required",
                              onChange: (e) => handleSchool(e.target.value, getValues('schoolDistrictId')),
                            })}
                          >
                            <option value="">Select School</option>
                            {schoolList &&
                              schoolList?.filter((x) => x?.schId === watch(`schoolDistrictId`))?.map((data, i) => (
                                <option key={i} value={data?.value}>
                                  {data?.label}
                                </option>
                              ))}
                          </select>
                          {errors?.schoolId && (
                            <span role="alert" className="error_text">
                              {errors?.schoolId.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {fields?.map((_, ind) => {
                      return (
                        <React.Fragment key={ind}>
                          <div
                            className={`row ${fields?.length - 1 === ind
                              ? ""
                              : "border-bottom my-4"
                              }`}
                          >
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Income Category{" "}
                                  <span className="red-color">*</span>{" "}
                                </label>
                                <select
                                  className="form-select"
                                  {...register(
                                    `financeIncomeSubModels.${ind}.incomeCategoryId`,
                                    {
                                      required: "This field is required",
                                      onChange: (e) =>
                                        setUpdated(
                                          updated === e.target.value
                                            ? e.target.value
                                            : e.target.value
                                        ),
                                    }
                                  )}
                                >
                                  <option value="">
                                    Select Income Category
                                  </option>
                                  {incomeCategory.map((item) => (
                                    <option
                                      value={item.incomeCategoryId}
                                      key={item.incomeCategoryId}
                                    >
                                      {item.categoryName}
                                    </option>
                                  ))}
                                </select>
                                {errors.financeIncomeSubModels?.[ind]
                                  ?.incomeCategoryId && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.financeIncomeSubModels?.[ind]
                                          ?.incomeCategoryId.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>

                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Income Sub Category{" "}
                                  <span className="red-color">*</span>{" "}
                                </label>
                                <select
                                  className="form-select"
                                  {...register(
                                    `financeIncomeSubModels.${ind}.incomeSubCategoryId`,
                                    {
                                      required: "This field is required",
                                    }
                                  )}
                                >
                                  <option value="">
                                    Select Income Sub Category
                                  </option>
                                  {incomeSubCategory
                                    ?.filter(
                                      (x) =>
                                        watch(
                                          `financeIncomeSubModels.${ind}.incomeCategoryId`
                                        ) === x?.incomeCategoryId
                                    )
                                    ?.map((item) => (
                                      <option
                                        value={item.incomeSubCategoryId}
                                        key={item.incomeSubCategoryId}
                                      >
                                        {item.subCategoryName}
                                      </option>
                                    ))}
                                </select>
                                {errors.financeIncomeSubModels?.[ind]
                                  ?.incomeSubCategoryId && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.financeIncomeSubModels?.[ind]
                                          ?.incomeSubCategoryId.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Income Date{" "}
                                  <span className="red-color">*</span>{" "}
                                </label>
                                <input
                                  type="date"
                                  className="form-control"
                                  {...register(
                                    `financeIncomeSubModels.${ind}.incomeDate`,
                                    {
                                      required: "This field is required",
                                      valueAsDate: true,
                                    }
                                  )}
                                />
                                {errors.financeIncomeSubModels?.[ind]
                                  ?.incomeDate && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.financeIncomeSubModels?.[ind]
                                          ?.incomeDate.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  Amount <span className="red-color">*</span>{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Amount"
                                  {...register(
                                    `financeIncomeSubModels.${ind}.amount`,
                                    {
                                      required: "This field is required",
                                      pattern: {
                                        value: /^\d+(\.\d{1,2})?$/,
                                        message:
                                          "Please enter correct format  ex. 0.75 or 12",
                                      },
                                    }
                                  )}
                                />
                                {errors.financeIncomeSubModels?.[ind]
                                  ?.amount && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.financeIncomeSubModels?.[ind]
                                          ?.amount.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="form-group">
                                <label>
                                  Description{" "}
                                  <span className="red-color">*</span>{" "}
                                </label>
                                <textarea
                                  rows={2}
                                  className="form-control"
                                  placeholder="Enter Description"
                                  {...register(
                                    `financeIncomeSubModels.${ind}.description`,
                                    {
                                      required: "This field is required",
                                      maxLength: {
                                        value: 255,
                                        message:
                                          "Description cannot exceed 255 characters",
                                      },
                                      pattern: {
                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                        message:
                                          "Starting and Ending Space not allowed",
                                      },
                                    }
                                  )}
                                />
                                {errors.financeIncomeSubModels?.[ind]
                                  ?.description && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.financeIncomeSubModels?.[ind]
                                          ?.description.message
                                      }
                                    </span>
                                  )}
                              </div>
                            </div>
                            {!id ? (
                              ind === 0 ? (
                                <>
                                  <div className="col-md-3 text-end mb-2">
                                    <Button
                                      className="theme_btn"
                                      onClick={() => append({})}
                                    >
                                      Add
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-md-3 text-end mb-2">
                                    <Button
                                      type="button"
                                      onClick={() => handleRemove(ind)}
                                      className="delete_btn"
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </>
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </React.Fragment>
                      );
                    })}
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
};

export default CreateFinanceIncome;
