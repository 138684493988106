import React, { Suspense, lazy, useEffect, useState } from "react";
// import { styled, alpha } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import Button from "@mui/material/Button";
import "./index.scss";
import { Box, Modal } from "@mui/material";
import { Link } from "react-router-dom";
// import { BsPlusSquareDotted } from "react-icons/bs";
import closesquare from "../../../../../../Assets/PngImage/closesquare.png";
// import InputBase from "@mui/material/InputBase";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "../../../../../../Utils/AxiosConfig";

const ListTable = lazy(() =>
  import("../../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

// const Search = styled("div")(({ theme }) => ({
//   position: "relative",
//   borderRadius: theme.shape.borderRadius,
//   color: "#6B696D",
//   backgroundColor: alpha(theme.palette.common.white, 0.15),
//   "&:hover": {
//     backgroundColor: alpha(theme.palette.common.white, 0.25),
//   },
//   [theme.breakpoints.up("sm")]: {
//     marginLeft: theme.spacing(0),
//     width: "auto",
//   },
// }));
// const SearchIconWrapper = styled("div")(({ theme }) => ({
//   padding: theme.spacing(2, 2, 4, 2),
//   height: "100%",
//   position: "absolute",
//   pointerEvents: "none",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
// }));
// const StyledInputBase = styled(InputBase)(({ theme }) => ({
//   color: "#6B696D",
//   "& .MuiInputBase-input": {
//     // vertical padding + font size from searchIcon
//     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
//     transition: theme.transitions.create("width"),
//     width: "100%",
//     [theme.breakpoints.up("md")]: {},
//   },
// }));

function AllergenTab() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => {
    if (id) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };
  const handleClose = () => setOpen(false);

  const productHead = [
    { id: "allergenName", label: "Allergen Name" },
    { id: "allergenGroup", label: "Allergen Group" },
  ];
  const {
    register,
    handleSubmit,
    // setValue,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const onSubmit = (data) => {
    console.log(data);
    navigate("/prospects", { replace: true });
  };
  useEffect(() => {
    GetStudentPrimaryData();
    // eslint-disable-next-line
  }, [deleteInfo]);
  const [prdList, setPrdList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const GetStudentPrimaryData = () => {
    Axios.get(`/api/GetAllergenData?userId=${LogDetail?.userId}`, { headers })
      .then((res) => {
        if (res?.status === 200) {
          let prList = res.data.payload?.map((sd) => {
            return {
              allergenId: sd?.allergenId,
              allergenName: sd?.allergenName,
              allergenGroup: sd?.allergenGroup,
              Action: (
                <>
                  <Link
                    type="button"
                    className="btn_edit me-2"
                    onClick={(e) => handleOpen(sd?.allergenId)}
                  >
                    <MdEdit fontSize={24} />
                  </Link>
                  <DeletePopup
                    title={"Delete Parent"}
                    text={sd?.firstName + " " + sd?.lastName}
                    url={`/api/DeleteAllergenData?allergenId=${sd?.allergenId}`}
                  />
                </>
              ),
            };
          });
          setPrdList(prList);
        } else {
          setPrdList([]);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <Suspense fallback={renderLoader()}>
      <ListTable headCells={productHead} action={true} ListData={prdList} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal-pop"
      >
        <Box sx={style}>
          <div className="allergen-popup">
            <div className="add-text">
              {/* <h5></h5> */}
              <Link to="" type="button" onClick={handleClose}>
                <img src={closesquare} alt="" />
              </Link>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Allergen Name <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Allergen Name"
                      {...register("Allergen", {
                        required: "This field is required",
                        pattern: {
                          message: "Allergen Name",
                        },
                      })}
                    />
                    {errors.Allergen && (
                      <span role="alert" className="error_text">
                        {errors.Allergen.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Allergen Group <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Allergen Group"
                      {...register("Allergengroup", {
                        required: "This field is required",
                        pattern: {
                          message: "Allergen Group",
                        },
                      })}
                    />
                    {errors.Allergengroup && (
                      <span role="alert" className="error_text">
                        {errors.Allergengroup.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  <Button type="submit" className="theme_btn me-2">
                    Update
                  </Button>
                  <Button className="cancel_btn" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </Suspense>
  );
}

export default AllergenTab;
