import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { Button, Tab, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TablePaginationDemo from "../../../../../Components/Reusable/CustomPagination";

function InvoiceProcessingForm() {
    const [fileNames, setFileNames] = useState([]);
    const [pdfDoc, setPdfDoc] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [file, setFile] = useState(null);
    const [detailsShow, setDetailsShow] = useState(false);
    const [values, setValues] = React.useState("1");

    const canvasRef = useRef(null);

    const {
        register,
        formState: { errors },
    } = useForm();

    const handleChangeTab = (event, newValue) => {
        setValues(newValue);
    };

    // useEffect(() => {
    //     if (pageNum !== pdfDoc?._pdfInfo?.numPages) {
    //         setDetailsShow(false);
    //         setValues("1");
    //     }
    //     // eslint-disable-next-line
    // }, [pageNum, pdfDoc?._pdfInfo?.numPages])

    useEffect(() => {
        if (file) {
            const loadPdf = async () => {
                const pdfjsLib = window["pdfjs-dist/build/pdf"];
                pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

                try {
                    const arrayBuffer = await file.arrayBuffer();
                    const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
                    setPdfDoc(pdf);
                    setPageNum(1);
                } catch (error) {
                    console.error("Error loading PDF:", error);
                } finally {
                }
            };

            loadPdf();
        }
    }, [file]);

    useEffect(() => {
        const renderPage = async (num) => {
            if (!pdfDoc) return;

            const page = await pdfDoc.getPage(num);
            const viewport = page.getViewport({ scale: 3 });
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            await page.render(renderContext).promise;
        };

        if (pdfDoc) {
            renderPage(pageNum);
        }
    }, [pdfDoc, pageNum]);

    // const nextPage = () => {
    //     if (pdfDoc && pageNum < pdfDoc.numPages) {
    //         setPageNum(pageNum + 1);
    //     }
    // };

    // const prevPage = () => {
    //     if (pdfDoc && pageNum > 1) {
    //         setPageNum(pageNum - 1);
    //     }
    // };

    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file);
            setFileNames(file?.name);
        }
    };

    // const handleInvoiceDocs = (event) => {
    //     const files = Array.from(event.target.files);
    //     setFileNames(files);
    // };


    const triggerFileInput = () => {
        document.getElementById("file_input").click();
    };

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = Array.from(e.dataTransfer.files);
        handleFiles(files);
    };

    const handleFiles = (files) => {
        let dndFile = files[0];
        if (dndFile) {
            setFile(dndFile);
            setFileNames(dndFile?.name);
        }
    };

    const handleSetForm = () => {
        setDetailsShow(true);
        setValues("2");
    };

    return (
        <>
            <section className="invoice_process">
                <div className="main_wrapper">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="all_title mb-4">Invoice Processing Form</h4>
                            <div className="row">
                                <div className="col-md-8">
                                    <h5 className="img_viewer">Image Viewer</h5>
                                    {fileNames.length > 0 ? (
                                        <>
                                            <div className="pdf_viewer text-center">
                                                <canvas
                                                    ref={canvasRef}
                                                    style={{
                                                        width: `${70}%`,
                                                        height: `${1123}px`,
                                                        border: "1px solid black",
                                                    }}
                                                ></canvas>
                                            </div>
                                            <div className="invoice_btn d-flex justify-content-around my-3">
                                                <TablePaginationDemo
                                                    pageNum={pageNum}
                                                    setPageNum={setPageNum}
                                                    allPage={pdfDoc?._pdfInfo?.numPages}
                                                />
                                                {/* <Button
                                                    className="previous_next_btn"
                                                    onClick={prevPage}
                                                    disabled={pageNum <= 1}
                                                >
                                                    {'<'} Previous
                                                </Button>
                                                <Button
                                                    className="previous_next_btn"
                                                    onClick={nextPage}
                                                    disabled={pageNum >= pdfDoc?._pdfInfo?.numPages}
                                                >
                                                    Next {'>'}
                                                    {pageNum}-{pdfDoc?._pdfInfo?.numPages}
                                                </Button> */}
                                                {/* <Button
                                                    className={`mark_end_btn ${pageNum !== pdfDoc?._pdfInfo?.numPages ? "disabled" : "enable"}`}
                                                    // disabled={pageNum !== pdfDoc?._pdfInfo?.numPages}
                                                >
                                                    Mark End of Invoice
                                                </Button> */}
                                                <Button
                                                    className="launch_ai_btn"
                                                    onClick={handleSetForm}
                                                    disabled={pageNum !== pdfDoc?._pdfInfo?.numPages}
                                                >
                                                    Launch Ai Review
                                                </Button>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-md-4">
                                    <div className="invoice_tab">
                                        <TabContext value={values}>
                                            <div className="d-flex justify-content-evenly align-items-center flex-wrap mb-4">
                                                <Box className="tab_group back_color d-flex justify-content-evenly">
                                                    <TabList
                                                        onChange={handleChangeTab}
                                                        aria-label="lab API tabs example"
                                                        className="tab_list"
                                                    >
                                                        <Tab
                                                            label="Upload Invoice Images"
                                                            value="1"
                                                            className="tabs_link tab_1"
                                                        />
                                                        {detailsShow ?
                                                            <Tab
                                                                label="Invoice Details"
                                                                value="2"
                                                                className="tabs_link tab_2"
                                                            />
                                                            : ""}
                                                    </TabList>
                                                </Box>
                                            </div>
                                            <TabPanel value="1" className="tab_panel p-0">
                                                <>
                                                    {/* <h5 className="invoice_head">Upload Invoice Images</h5> */}
                                                    <div
                                                        id="cmp_docs_display"
                                                        className="drag_drop"
                                                        onClick={triggerFileInput}
                                                        onDragEnter={handleDrag}
                                                        onDragLeave={handleDrag}
                                                        onDragOver={handleDrag}
                                                        onDrop={handleDrop}
                                                    >
                                                        {fileNames.length === 0 ? (
                                                            "Drag and drop invoice images here, or click to select files."
                                                        ) : (
                                                            <div>{fileNames}</div>
                                                        )}
                                                    </div>
                                                    <input
                                                        type="file"
                                                        id="file_input"
                                                        accept=".pdf"
                                                        className="form-control"
                                                        style={{ display: "none" }}
                                                        onChange={onFileChange}
                                                    />
                                                    <input
                                                        type="hidden"
                                                        {...register("invoiceURL", {
                                                            required: "This field is required",
                                                        })}
                                                    />
                                                </>
                                            </TabPanel>
                                            <TabPanel value="2" className="tab_panel p-0">
                                                <>
                                                    {/* <h5 className="invoice_head mt-3">Invoice Details</h5> */}
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    Invoice Number
                                                                    {/* <span className="red-color">*</span>{" "} */}
                                                                </label>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    placeholder="Invoice Number"
                                                                    {...register("invoiceNumber", {
                                                                        // required: "This field is required",
                                                                        maxLength: {
                                                                            value: 250,
                                                                            message: "Max 250 characters",
                                                                        },
                                                                        pattern: {
                                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                            message: "Starting and Ending Space not allowed",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors?.invoiceNumber && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.invoiceNumber.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    Invoice Date
                                                                    {/* <span className="red-color">*</span>{" "} */}
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    {...register("incomeDate", {
                                                                        // required: "This field is required",
                                                                        valueAsDate: true,
                                                                    })}
                                                                />
                                                                {errors?.incomeDate && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.incomeDate.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    Due Date
                                                                    {/* <span className="red-color">*</span>{" "} */}
                                                                </label>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    {...register("dueDate", {
                                                                        // required: "This field is required",
                                                                        valueAsDate: true,
                                                                    })}
                                                                />
                                                                {errors?.dueDate && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.dueDate.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    Vendor
                                                                    {/* <span className="red-color">*</span>{" "} */}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Vendor"
                                                                    className="form-control"
                                                                    {...register("Vendor", {
                                                                        // required: "This field is required",
                                                                        maxLength: {
                                                                            value: 255,
                                                                            message: "Max 255 characters",
                                                                        },
                                                                        pattern: {
                                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                            message: "Starting and Ending Space not allowed",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors.Vendor && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors.Vendor.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label>
                                                                    Amount
                                                                    {/* <span className="red-color">*</span>{" "} */}
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Amount"
                                                                    {...register("amount", {
                                                                        // required: "This field is required",
                                                                        pattern: {
                                                                            value: /^\d{1,16}(\.\d{0,2})?$/,
                                                                            message: "Invalid amount format",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors?.amount && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.amount.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label>
                                                                    Description
                                                                    {/* <span className="red-color">*</span>{" "} */}
                                                                </label>
                                                                <textarea
                                                                    rows={3}
                                                                    className="form-control"
                                                                    placeholder="Description"
                                                                    {...register("description", {
                                                                        // required: "This field is required",
                                                                        maxLength: {
                                                                            value: 255,
                                                                            message: "Description cannot exceed 255 characters",
                                                                        },
                                                                        pattern: {
                                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                            message: "Starting and Ending Space not allowed",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors?.description && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.description.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5 className="invoice_head">Action</h5>
                                                    <div className="action_btn text-center">
                                                        <Button
                                                            className="previous_next_btn"
                                                        >
                                                            Approve for Payment
                                                        </Button>
                                                        <Button
                                                            className="reject_btn"
                                                        >
                                                            Reject to Vendor
                                                        </Button>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="reason_reject">
                                                                Reason for Rejection
                                                                {/* <span className="red-color">*</span>{" "} */}
                                                            </label>
                                                            <textarea
                                                                rows={3}
                                                                className="form-control"
                                                                placeholder="Rejection"
                                                                {...register("description", {
                                                                    // required: "This field is required",
                                                                    maxLength: {
                                                                        value: 255,
                                                                        message: "Description cannot exceed 255 characters",
                                                                    },
                                                                    pattern: {
                                                                        value: /^[^\s]+(\s+[^\s]+)*$/,
                                                                        message: "Starting and Ending Space not allowed",
                                                                    },
                                                                })}
                                                            />
                                                            {errors?.description && (
                                                                <span role="alert" className="error_text">
                                                                    {errors?.description.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            </TabPanel>
                                        </TabContext>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default InvoiceProcessingForm;
