import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function VoidCashierReportTab(props) {
  const { tableRef, startDate, endDate, back } = props;
  // const { tableRef, repoData, startDate, endDate, back } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const reporttable = [
    {
      Cashier: "Cashier :",
      CashierName: "Riyaz Ahemd",
      Date: "2021-04-08 14:05",
      Menu: "Breakfast Side",
      MenuTitle: "-Beef chorizo(1)",
      QTY: "1",
      Price: "10.00",
    },
    {
      Date: "2021-04-08 14:05",
      Menu: "Beef",
      MenuTitle: "-Asahi Beer",
      QTY: "1",
      Price: "10.00",
    },
    {
      Date: "2021-04-08 14:05",
      Menu: "Hot Drinks",
      MenuTitle: "-chai Latte",
      QTY: "1",
      Price: "10.00",
    },
    {
      Date: "2021-04-08 14:05",
      Menu: "Beef",
      MenuTitle: "-Asahi Beer",
      QTY: "1",
      Price: "10.00",
    },
    {
      Date: "",
      Menu: "",
      MenuTitle: "Sub Total",
      QTY: "",
      Price: "$1234.00",
      classset: "cash-border",
    },
    {
      Date: "",
      Menu: "",
      MenuTitle: "Grand Total",
      QTY: "",
      Price: "$1234.00",
      classset: "cash-border",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <section className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Void By Cashier Report</h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">Void by Cashier Report</h5>
        <div className="border-dash pb-3"></div>
        {reporttable?.map((text, i) => (
          <div className="report-table">
            <h6 className="title-width">
              {text?.Cashier} {text?.CashierName}
            </h6>
            <h6 className="title-width">{text?.Date}</h6>
            <h6 className="title-width">{text?.Menu}</h6>
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width menutile-margin">{text?.MenuTitle}</h6>
              <h6 className="title-width">{text?.QTY}</h6>
              <h6 className="title-width">{text?.Price}</h6>
            </div>
          </div>
        ))}
        <h6 className="text-center endreport-border">
          ------End Report-------
        </h6>
      </section>
    </Suspense>
  );
}

export default VoidCashierReportTab;
