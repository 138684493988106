import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import signcalendar from "../../../../Assets/SvgImage/signcalendar.svg";
import wednesday from "../../../../Assets/SvgImage/wednesday.svg";
import calendar from "../../../../Assets/SvgImage/calenda-plus.svg";
import today from "../../../../Assets/SvgImage/today.svg";
// import FreeReduce from "../../../../Assets/SvgImage/FreeReduce.svg";
import Charts from "../../../../Components/Reusable/Chart/Charts";
import Axios from "../../../../Utils/AxiosConfig";
import CountBox from "./CountBox";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function Dashboard() {
  const [openCircle] = useState(false);
  const [mealDetail, setMealDetail] = useState();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [chart4] = useState([
    {
      name: "Months",
      data: [
        49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1,
        95.6, 54.4,
      ],
      color: "#31B680",
    },
  ]);

  const chartOptions3 = {
    chart: {
      type: "spline",
      inverted: false,
    },
    title: {
      align: "left",
      text: "",
      style: {
        fontSize: "22px",
        color: "#1A1A1A",
        fontWeight: "500",
      },
    },
    subTitle: {
      text: "",
    },
    yAxis: {
      title: {
        text: "",
        color: "#fff",
      },
      lineWidth: 0,
      maxPadding: 0.05,
      gridLineWidth: 1,
      crosshair: true,
      gridLineColor: "#ebebeb",
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
    },

    xAxis: {
      lineWidth: 0,
      gridLineColor: "#D9D9D9",
      gridLineWidth: 2,
      crosshair: true,
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
    },

    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#464E5F",
        fontWeight: "500",
        fontSize: "15px",
      },
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    // series: chart3,
    series: [
      {
        lineColor: "#F79153",
        color: "#F79153",
        name: "Cost of Meals",
        data: [1, 9, 7, 8, 5, 12, 5],
      },
      {
        lineColor: "#49C7DB",
        color: "#49C7DB",
        name: "",
        data: [1, 9, 7, 8, 5, 12, 5].reverse(),
      },
    ],
  };
  const chartOptions4 = {
    chart: {
      type: "column",
      height: 485 + "px",
    },
    title: {
      align: "left",
      text: "Monthly Payments",
      margin: 50,
      style: {
        fontSize: "22px",
        color: "#1A1A1A",
        fontWeight: "500",
      },
    },
    xAxis: {
      lineWidth: 1,
      lineColor: "#464E5F",
      categories: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
      crosshair: true,
    },
    yAxis: {
      lineWidth: 1,
      lineColor: "#464E5F",
      min: 0,
      title: {
        text: "Payments",
        margin: 30,
        style: {
          color: "#464E5F",
          fontWeight: "500",
          fontSize: "17px",
        },
      },
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
      gridLineColor: "transparent",
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#464E5F",
        fontWeight: "500",
        fontSize: "15px",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: chart4,
  };

  useEffect(() => {
    GetDashBoardTotalCountList();
    // eslint-disable-next-line
  }, []);
  const GetDashBoardTotalCountList = () => {
    Axios.get(
      `/api/DashBoard/GetDashBoardTotalCountList?studentId=${LogDetail?.studentGuidId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setMealDetail(res.data.payload);
          } else {
            setMealDetail();
          }
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="web_dashboard">
          <div className="main_wrapper">
            <div className="all_title">Dashboard</div>
            <div className="admin_main_box mt-4">
              <div className="row ">
                <div className="col-md-12">
                  <div className="meal_main h-100">
                    <div className="row h-100">
                      <CountBox
                        image={wednesday}
                        name="Weekly Meals Counts"
                        num={mealDetail ? mealDetail : 0}
                      />
                      <CountBox
                        image={calendar}
                        name="Monthly Meals Counts"
                        num={mealDetail ? mealDetail : 0}
                      />
                      <CountBox
                        image={signcalendar}
                        name="Weekly Cost"
                        num={mealDetail ? mealDetail : 0}
                      />
                      <CountBox
                        image={today}
                        name="Monthly Cost"
                        num={mealDetail ? mealDetail : 0}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-3">
                  <div className="chart_box_1 box__shadow pb-0">
                    <div className="chart_hed mb-3">
                      <div className="d-flex align-items-center justify-content-between ">
                        <h4>Daily Meal Count</h4>
                        <p className="m-0">1-March-2023, 30-March-2023</p>
                      </div>
                      <div className="sales_count">
                        <h6>Months</h6>
                      </div>
                    </div>
                    <Charts series={chartOptions3} />
                  </div>
                </div>
                <div className="col-md-7 mt-3">
                  <div className="chart_box_1 box__shadow ">
                    <Charts series={chartOptions4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Dashboard;
