import React, { Suspense, useEffect, useState, useRef } from "react";
import { Button, Tab } from "@mui/material";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useForm } from "react-hook-form";
import DailySalesReportTab from "./DailySalesReportTab";
import HourlySalaryReportTab from "./HourlySalaryReportTab";
import HalfHourlTab from "./HalfHourlTab";
import TopSalesReportTab from "./TopSalesReportTab";
import DiscountDetailsReportTab from "./DiscountDetailsReportTab";
import VoidReportTab from "./VoidReportTab";
import HourlyQTYSoldTab from "./HourlyQTYSoldTab";
import SalesmixReportTab from "./SalesmixReportTab";
import BillReportDetailsTab from "./BillReportDetailsTab";
import DepartmentSalesReportTab from "./DepartmentSalesReportTab";
import VoidCashierReportTab from "./VoidCashierReportTab";
import DailyMealReportTab from "./DailyMealReportTab";
import Axios from "../../../../Utils/AxiosConfig.jsx";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import Select from "react-select";
import { AcademicYearListApi } from "../../../../Action/AcademicYear.jsx";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CashierSideReport() {
  const [open, setOpen] = React.useState(false);
  const [menu, setMenu] = React.useState("1");
  const [downloadName, setDownloadName] = useState("Daily Sales Report");
  const [openCircle, setOpenCircle] = useState(false);
  const [repoData, setRepoData] = useState([]);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const [derror, setDerror] = useState();
  const [school, setSchool] = useState();
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [schoolYearList, setSchoolYearList] = useState([]);

  const tableRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const handleChangeTab = (event, newValue) => {
    reset();
    setSchool([]);
    setSchoolSelect([]);
    setSchoolList([]);
    if (newValue === "6") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (newValue === "1") {
      setDownloadName("Daily Sales Report");
    } else if (newValue === "2") {
      setDownloadName("Hourly Sales Report");
    } else if (newValue === "3") {
      setDownloadName("Half Hour Sales Report");
    } else if (newValue === "4") {
      setDownloadName("Top 25 Sales Report");
    } else if (newValue === "5") {
      setDownloadName("Discount Detail Report");
    } else if (newValue === "6") {
      setDownloadName("Void Report");
    } else if (newValue === "7") {
      setDownloadName("Hourly QTY Sold Report");
    } else if (newValue === "8") {
      setDownloadName("Sales Mix Report");
    } else if (newValue === "9") {
      setDownloadName("Bill Report Details");
    } else if (newValue === "10") {
      setDownloadName("Department Sales Report");
    } else if (newValue === "11") {
      setDownloadName("Void By Cashier Report");
    } else if (newValue === "12") {
      setDownloadName("Daily Meal Report");
    }
    setRepoData([]);
    setMenu(newValue);
  };

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  // useEffect(() => {
  //   getReportData(menu);
  //   // eslint-disable-next-line
  // }, [menu]);
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (menu !== "4") {
      getAcademicYear();
    }
    // eslint-disable-next-line
  }, [menu])

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getAcademicYear = async () => {
    let resp = await AcademicYearListApi();
    if (resp?.length > 0) {
      setSchoolYearList(resp);
    }
  }

  const getReportData = (val) => {
    let is_success = true;
    // if (val) {
    //   is_success = false;
    //   setDerror({ err: "Your start date can not greater than from end date" });
    // }

    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }

    let url = `/api/Report/GetDailySalesReportData`;
    if (menu === "1") {
      url = `/api/Report/GetDailySalesReportData`;
    } else if (menu === "4") {
      url = "/api/Report/GetTop25SalesReportData";
    } else if (menu === "12") {
      url = "/api/Report/GetMealCountReport";
    }
    if (is_success) {
      setDerror({ err: "" });
      let sd = {
        schoolDistrictId: val?.schoolDistrictId ? val?.schoolDistrictId : null,
        startDate: val?.startDate ? val?.startDate : null,
        endDate: val?.endDate ? val?.endDate : null,
        userId: LogDetail?.userId,
        schoolId: schoolSelect?.map((x) => x.value),
        mealService: val?.mealService ? val?.mealService : "",
        academicYearId: menu !== "4" ? val?.academicYearId : 0
      };
      setStartDate(val?.startDate);
      setEndDate(val?.endDate);
      let distName = districtList?.filter(
        (x) => x.value === sd?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();
      let sdate = sd?.startDate ? sd?.startDate?.toISOString() : null;
      let edate = sd?.endDate ? sd?.endDate?.toISOString() : null;
      let reData = {
        startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
        endDate: edate ? convertDate(edate?.split("T")[0]) : null,
        distName: distName,
        schName: schName,
        Name: LogDetail?.firstName + " " + LogDetail?.lastName,
      };
      setSchool(reData);
      setOpenCircle(true);
      Axios.post(url, sd, { headers })
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setOpenCircle(false);
              const SdData = res.data.payload;
              setRepoData(SdData);
            } else {
              setOpenCircle(false);
              setRepoData([]);
            }
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };
  let Time = new Date();
  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);
  const handleDownloadPdf = async () => {
    const element = tableRef.current;
    let newPdfDate = Time.toLocaleString();

    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`${downloadName}.pdf`);
        setDownloadpdf(false);
      });
  };
  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    // width: "100%",
    // height: "100%",
    // position: "relative",
    // margin: "0",
  };

  const getSchoolList = (id) => {
    setSchoolList([]);
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const dlist = res.data.payload?.map((sd) => {
                return {
                  ...sd,
                  value: sd?.schoolId,
                  label: sd?.schoolName,
                };
              });
              let uslist = [{ value: "all", label: "Select All" }, ...dlist];

              setSchoolList(uslist?.length > 1 ? uslist : []);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };
  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolDistrictId,
                label: sd?.districtName,
              };
            });
            setDistrictList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="reports-page main-section">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Cashier Report</h4>
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-body" id="printable-content">
                    <TabContext value={menu}>
                      <TabPanel value="1" className="tab_panel ">
                        <DailySalesReportTab
                          tableRef={tableRef}
                          back={back}
                          repoData={repoData}
                          school={school}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </TabPanel>
                      <TabPanel value="2" className="tab_panel ">
                        <HourlySalaryReportTab
                          tableRef={tableRef}
                          back={back}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </TabPanel>
                      <TabPanel value="3" className="tab_panel ">
                        <HalfHourlTab
                          tableRef={tableRef}
                          back={back}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </TabPanel>
                      <TabPanel value="4" className="tab_panel ">
                        <TopSalesReportTab
                          tableRef={tableRef}
                          back={back}
                          school={school}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </TabPanel>
                      <TabPanel value="5" className="tab_panel ">
                        <DiscountDetailsReportTab
                          tableRef={tableRef}
                          back={back}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </TabPanel>
                      <TabPanel value="6" className="tab_panel ">
                        <VoidReportTab
                          tableRef={tableRef}
                          back={back}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </TabPanel>
                      <TabPanel value="7" className="tab_panel ">
                        <HourlyQTYSoldTab
                          tableRef={tableRef}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                          back={back}
                        />
                      </TabPanel>
                      <TabPanel value="8" className="tab_panel ">
                        <SalesmixReportTab
                          tableRef={tableRef}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                          back={back}
                        />
                      </TabPanel>
                      <TabPanel value="9" className="tab_panel ">
                        <BillReportDetailsTab
                          tableRef={tableRef}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                          back={back}
                        />
                      </TabPanel>
                      <TabPanel value="10" className="tab_panel ">
                        <DepartmentSalesReportTab
                          tableRef={tableRef}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                          back={back}
                        />
                      </TabPanel>
                      <TabPanel value="11" className="tab_panel ">
                        <VoidCashierReportTab
                          tableRef={tableRef}
                          repoData={repoData}
                          startDate={startDate}
                          endDate={endDate}
                          back={back}
                        />
                      </TabPanel>
                      <TabPanel value="12" className="tab_panel ">
                        <DailyMealReportTab
                          tableRef={tableRef}
                          repoData={repoData}
                          school={school}
                          startDate={startDate}
                          endDate={endDate}
                          back={back}
                        />
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div className="card-body">
                    <div className="payment-section">
                      <form onSubmit={handleSubmit(getReportData)}>
                        <div className="col-md-12 date-block gap-3">
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <label> District
                                <span className="red-color"> *</span>
                              </label>
                              <select
                                name=""
                                className="form-select"
                                {...register("schoolDistrictId", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    getSchoolList(e.target.value),
                                })}
                              >
                                <option value="">Select School District</option>
                                {districtList &&
                                  districtList?.map((data, i) => (
                                    <option key={i} value={data?.value}>
                                      {data?.label}
                                    </option>
                                  ))}
                              </select>
                              {errors.schoolDistrictId && (
                                <span role="alert" className="error_text">
                                  {errors.schoolDistrictId.message}
                                </span>
                              )}
                            </div>
                            <div className="col-md-6 form-group">
                              <label> School
                                <span className="red-color"> *</span>
                              </label>
                              <Select
                                isMulti
                                name="type"
                                options={schoolList}
                                // closeMenuOnSelect={false}
                                // hideSelectedOptions={false}
                                className="form-neselect"
                                classNamePrefix="select"
                                placeholder="Select School"
                                value={schoolSelect ? schoolSelect : null}
                                onChange={(selected) => {
                                  true &&
                                    selected.length &&
                                    selected.find(
                                      (option) => option.value === "all"
                                    )
                                    ? handleIOCMulti(schoolList.slice(1))
                                    : !true
                                      ? handleIOCMulti(
                                        (selected && selected.value) || null
                                      )
                                      : handleIOCMulti(selected);
                                }}
                                style={{ width: "100%" }}
                              />
                              {derror?.err && (
                                <span
                                  role="alert"
                                  className="d-block error_text"
                                >
                                  {derror.err}
                                </span>
                              )}
                            </div>
                            <div className="col-md-6 form-group">
                              <label>Start Date
                                <span className="red-color"> *</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                {...register("startDate", {
                                  required: "This field is required",
                                  valueAsDate: true,
                                })}
                              />
                              {errors.startDate && (
                                <span role="alert" className="error_text">
                                  {errors.startDate.message}
                                </span>
                              )}
                            </div>
                            <div className="col-md-6 form-group">
                              <label>End Date
                                <span className="red-color"> *</span>
                              </label>
                              <input
                                type="date"
                                className="form-control"
                                {...register("endDate", {
                                  required: "This field is required",
                                  valueAsDate: true,
                                  validate: (val) => {
                                    if (watch("startDate") > val) {
                                      return "Your start date can not greater than from end date";
                                    }
                                  },
                                })}
                              />
                              {errors.endDate && (
                                <span role="alert" className="error_text">
                                  {errors.endDate.message}
                                </span>
                              )}
                            </div>
                            {menu !== "4" &&
                              <div className="col-md-6 form-group">
                                <label> School Year </label>
                                <select
                                  name=""
                                  className="form-select"
                                  {...register("academicYearId", {
                                    valueAsNumber: true
                                  })}
                                >
                                  <option value="0">Select School Year</option>
                                  {schoolYearList &&
                                    schoolYearList?.map((data, i) => (
                                      <option key={i} value={data?.value}>
                                        {data?.label}
                                      </option>
                                    ))}
                                </select>
                                {errors.academicYearId && (
                                  <span role="alert" className="error_text">
                                    {errors.academicYearId.message}
                                  </span>
                                )}
                              </div>
                            }
                            {menu === "12" ? (
                              <div className="col-md-6 form-group">
                                <label>Meal Service</label>
                                <select
                                  className="form-select"
                                  {...register("mealService", {
                                    required: "This field is required",
                                  })}
                                >
                                  <option value="All">All</option>
                                  <option value="Breakfast">Breakfast</option>
                                  <option value="Dinner">Dinner</option>
                                  <option value="Lunch">Lunch</option>
                                  <option value="Snacks">Snacks</option>
                                </select>
                                {errors.mealService && (
                                  <span role="alert" className="error_text">
                                    {errors.mealService.message}
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {open ? (
                            <div className="form-group mb-0">
                              <label>Order Source </label>

                              <select
                                className="form-control"
                                {...register("State", {
                                  required: "This field is required",
                                })}
                              >
                                <option value="">Select State </option>
                                <option value="10">Online</option>
                                <option value="20">Offline</option>
                                <option value="30">News</option>
                              </select>
                              {errors.State && (
                                <span role="alert" className="error_text">
                                  {errors.State.message}
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="d-flex gap-3 mb-3">
                          <Button
                            type="button"
                            className="cancel_btn"
                            onClick={() => setRepoData([])}
                          >
                            Cancel
                          </Button>
                          {openCircle ? (
                            <Button className="theme_btn text-end">
                              Applying Filter{" "}
                              <CircularProgress
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            </Button>
                          ) : (
                            <Button
                              className="theme_btn text-end"
                              type="submit"
                            >
                              Apply
                            </Button>
                          )}
                          <Button
                            type="button"
                            className="theme_btn justify-content-end"
                            onClick={() => setDownloadpdf(true)}
                          >
                            Print{" "}
                          </Button>
                        </div>
                      </form>
                      <div className="tab-section">
                        <TabContext value={menu}>
                          <TabList
                            onChange={handleChangeTab}
                            aria-label="lab API tabs example"
                            className="tab_list"
                          >
                            <Tab
                              label="Daily Sales Report"
                              value="1"
                              className="tabs_link tab_1"
                            />
                            {/* <Tab
                            label="Hourly Sales Report"
                            value="2"
                            className="tabs_link tab_2"
                          />
                          <Tab
                            label="Half Hour Sales Report"
                            value="3"
                            className="tabs_link tab_3"
                          /> */}
                            <Tab
                              label="Top 25 Sales Report"
                              value="4"
                              className="tabs_link tab_4"
                            />
                            {/* <Tab
                            label="Discount Details Report"
                            value="5"
                            className="tabs_link tab_5"
                          /> */}
                            {/* <Tab
                            label="Void Report"
                            value="6"
                            className="tabs_link tab_6"
                          />
                          <Tab
                            label="Hourly QTY Sold"
                            value="7"
                            className="tabs_link tab_7"
                          />
                          <Tab
                            label="Sales mix Report"
                            value="8"
                            className="tabs_link tab_8"
                          />
                          <Tab
                            label="Bill Report Details"
                            value="9"
                            className="tabs_link tab_9"
                          />
                          <Tab
                            label="Department Sales Report"
                            value="10"
                            className="tabs_link tab_10"
                          />
                          <Tab
                            label="Void by Cashier Report"
                            value="11"
                            className="tabs_link tab_11"
                          /> */}
                            {/* <Tab
                              label="Daily Meal Report"
                              value="12"
                              className="tabs_link tab_11"
                            /> */}
                          </TabList>
                        </TabContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CashierSideReport;
