import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { languageChange } from "../reducers/language";

export default function ChangeLanguage() {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const Lang = useSelector((state) => state.language.lang);

    function handleLanguage(e) {
        i18n?.changeLanguage(e.value);
        localStorage.setItem("Language", e.value);
        dispatch(languageChange(e.value));
    }

    // setCurrentLang(currLang)
    useEffect(() => {
        i18n?.changeLanguage(Lang)
        localStorage.setItem("Language", Lang ? Lang : "en");
        // eslint-disable-next-line
    }, []);

    const options = [
        {
            value: "en",
            label: (
                <span
                    className="language_select_option"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                >
                    English
                </span>
            ),
        },
        {
            value: "hi",
            label: (
                <span
                    className="language_select_option"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                >
                    Hindi
                </span>
            ),
        },
        {
            value: "sp",
            label: (
                <span
                    className="language_select_option"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                >
                    Spanish
                </span>
            ),
        },
        {
            value: "ch",
            label: (
                <span
                    className="language_select_option"
                    style={{ fontSize: "16px", fontWeight: "500" }}
                >
                    Chinese
                </span>
            ),
        },
    ];

    return (
        <div className="col-md-2">
            <Select
                // className={`languageSelect ${currentLang}`}
                className="dropdown-single-select"
                name="colors"
                placeholder={
                    Lang === "en"
                        ? options[0].label
                        : Lang === "hi"
                            ? options[1].label
                            : Lang === "sp"
                                ? options[2].label
                                : options[3].label
                }
                onChange={handleLanguage}
                options={options}
                isMulti={false}
            />
        </div>
    );
}
