import React, { Suspense, useEffect, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import "./index.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Axios from "../../../../../../Utils/AxiosConfig";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateSubCategory() {
  const { id } = useParams();

  const [catList, setcategorieslist] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  let navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    GetAllCategoryData();
    // eslint-disable-next-line
  }, []);

  const GetAllCategoryData = () => {
    Axios.get(
      `/api/ProductCategory/GetAllProductCategoryData?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setcategorieslist(res.data.payload);
          }
        } else {
          setcategorieslist([]);
        }
      })
      .catch((e) => console.log(e));
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    setOpenCircle(true);
    Axios.post(`/api/ProductSubCategory/CreateProductSubCategory`, sendData, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (catList?.length > 0) {
      if (id) {
        EditsubCategory(id);
      }
    }
    // eslint-disable-next-line
  }, [catList, id]);
  const EditsubCategory = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/ProductSubCategory/GetProductSubCategoryDataByID?productSubCategoryId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let catData = res.data.payload;
            setValue("productCategoryId", catData?.productCategoryId);
            setValue("productSubCategoryId", catData?.productSubCategoryId);
            setValue("productSubCategoryName", catData?.productSubCategoryName);
            setValue("productSubCategoryCode", catData?.productSubCategoryCode);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="ProductSubCategories">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">Sub Category</div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Category Name <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("productCategoryId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Category</option>
                          {catList?.map((item, i) => (
                            <option value={item.productCategoryId} key={i}>
                              {item.productCategoryName}
                            </option>
                          ))}
                        </select>
                        {errors.productCategoryId && (
                          <span className="formError errorMssg error_text">
                            {errors.productCategoryId.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Sub Category Name <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sub Category Name"
                          {...register("productSubCategoryName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.productSubCategoryName && (
                          <span role="alert" className="error_text">
                            {errors.productSubCategoryName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Sub Category Code <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sub Category Code"
                          {...register("productSubCategoryCode", {
                            required: "This field is required",
                          })}
                        />
                        {errors.productSubCategoryCode && (
                          <span role="alert" className="error_text">
                            {errors.productSubCategoryCode.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end mt-3">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateSubCategory;
