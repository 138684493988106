import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "studentname", label: "Students Name" },
  { id: "parentName", label: "Parent Name" },
  { id: "remark", label: "Remark" },
  { id: "createon", label: "Created On" },
];

function ComunicationhistoryTab() {
  const [openCircle, setOpenCircle] = useState(false);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    ComunicationhistoryTab();
    // eslint-disable-next-line
  }, []);

  const ComunicationhistoryTab = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/Parents/GetCommunicationHistoryAllData?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                studentname: sd?.studentName,
                parentName: sd?.parentName,
                remark: sd?.message,
                createon: sd?.dateOfCommunication,
              };
            });
            setPrdList(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <ListTable headCells={productHead} ListData={prdList} />
      </Suspense>
    </>
  );
}

export default ComunicationhistoryTab;
