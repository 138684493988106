import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { BsPlusSquareDotted } from "react-icons/bs";
import { GetCallApi } from "../../../../../Action/Action";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "allergenGroup", label: "Allergen Group" },
  { id: "allergenName", label: "Allergen Name", widthFix: 600, },
];
function AllergenGroup() {
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const [openCircle, setOpenCircle] = useState(false);
  const [prdList, setPrdList] = useState([]);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getAllergenList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const getAllergenList = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AllergenGroup/GetAllergenGroupData`,
      headers: headers
    }
    const res = await GetCallApi(seData)
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        const AdData = res.data.payload?.map((ad, i) => {
          return {
            ...ad,
            // allergenName: ad?.allergenData[0]?.allergenName,
            allergenName: ad?.allergenData?.map((c) => c?.allergenName)?.join(", "),
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`edit/${ad?.allergenGroupId}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
                <DeletePopup
                  title={"Delete Allergen Group"}
                  text={ad?.allergenGroup}
                  url={`/api/AllergenGroup/DeleteAllergenGroup?allergenGroupId=${ad?.allergenGroupId}`}
                />
              </>
            ),
          };
        });
        setPrdList(AdData);
      } else {
        setOpenCircle(false);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="user_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">
                    Allergen Group List
                  </div>
                  <div className="d-flex">
                    {/* <Button to={""} className="cancel_btn me-4">
                  Download
                </Button> */}
                    <Link to={"Create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}
export default React.memo(AllergenGroup);
