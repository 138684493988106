import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { BsFillPatchCheckFill } from "react-icons/bs";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ParentMakePayment() {
  const [stdList, setStdList] = useState([]);

  const [expiryDt, setExpiryDt] = useState("");
  const [derror, setDerror] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [cardNo, setCardNo] = useState("");
  const [payDetail, setPayDetail] = useState();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getStudentList();
    // eslint-disable-next-line
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }
  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }
  const onSubmit = (data) => {
    let success = true;
    if (expiryDt?.length < 4) {
      setDerror({ time_err: "Enter Expire Date" });
      success = false;
    }
    if (success) {
      setDerror({ time_err: "" });
      let sendData = {
        ...data,
        studentId: data?.studentGuidId,
        email: LogDetail?.email,
        expMonth: expiryDt?.split("/")[0],
        expYear: expiryDt?.split("/")[1],
        amount: parseFloat(data?.amount),
      };

      setOpenCircle(true);
      Axios.post("/api/StudentPayment/CreateStudentPayment", sendData, {
        headers,
      })
        .then((res) => {
          if (res?.status === 200) {
            // if (res.data.) {
            setOpenCircle(false);
            if (res.data.isPaid) {
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: res.data?.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "#48953e",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
              reset();
              // navigate("/payment-history", { replace: true });
            } else {
              setDerror({ time_err: res.data.message });
            }
            let stdId = stdList?.filter(
              (x) => x?.studentGuidId === data?.studentGuidId
            )[0];
            let payData = {
              ...sendData,
              // studentGuidId: LogDetail?.studentGuidId,
              schoolDistrictId: stdId?.schoolDistrictId,
              schoolId: stdId?.schoolId,
              nameOnCard: sendData?.cardName,
              customerReference: generateId(6),
              paymentReference: res.data?.paymentReference
                ? res.data?.paymentReference
                : "",
              paymentAmount: sendData?.amount,
              status: res.data.isPaid ? "Success" : "Failed",
            };
            if (res.data.isPaid) {
              setPayDetail(payData);
            }
            Axios.post(`/api/StudentPayment/AddPaymentRecord`, payData, {
              headers,
            })
              .then((res) => {
                if (res?.status === 200) {
                  if (res.data.success) {
                    setExpiryDt("");
                    setCardNo("");
                  }
                }
              })
              .catch((e) => console.log(e));
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };

  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 4);
    return expDateFormatter;
  };

  const handleAmount = (e) => {
    let fees = (parseFloat(e) * 5) / 100;
    let schre = (parseFloat(e) * 95) / 100;
    let stripe = (parseFloat(e) * 3.2) / 100;
    let swan = (parseFloat(e) * 1.8) / 100;
    function mathRound(val) {
      return (Math.round(val * 100) / 100).toFixed(2);
    }
    setValue("fees", mathRound(fees));
    setValue("schoolReceivableAmount", mathRound(schre));
    setValue("stripeCharges", mathRound(stripe));
    setValue("swanCharges", mathRound(swan));
  };
  const handleCardDisplay = () => {
    const rawText = [...cardNo.split(" ").join("")]; // Remove old space
    const creditCard = []; // Create card as array
    rawText.forEach((t, i) => {
      if (i % 4 === 0 && i !== 0) creditCard.push(" "); // Add space
      creditCard.push(t);
    });
    return creditCard.join(""); // Transform card array to string
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getStudentList = () => {
    Axios.get(
      `/api/ParentModule/GetStudentListByParentID?parentGuidId=${LogDetail?.parentsGuidId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                ...sd,
                value: sd?.studentGuidId,
                label: sd?.studentName,
              };
            });
            setStdList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {payDetail ? (
          <section className="payment_success">
            {/* <img src={paymentBanner} className="img-fluid" alt="" /> */}
            <div className="main_wrapper">
              <div className="payment_inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="success_message_box">
                      <BsFillPatchCheckFill className="icon" />
                      <div>
                        <h1 className="m-0">Payment Successful</h1>
                        <p>
                          We will share payment receipt on registered email.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row paymentAndUserInfo mt-4">
                  <div className="col-md-6">
                    <ul>
                      <li>
                        Payment Reference Id :{" "}
                        <span>{payDetail?.paymentReference}</span>
                      </li>
                      <li>
                        Paid Amount : <span>$ {payDetail?.paymentAmount}</span>
                      </li>
                      <li>
                        Payment Date :{" "}
                        <span>
                          {convertDate(new Date().toISOString().split("T")[0]) +
                            " " +
                            new Date().toLocaleTimeString()}
                        </span>
                      </li>
                      {/* <li>Payment Status : <span>$ 1250.00</span></li> */}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li>
                        Name :{" "}
                        <span>
                          {LogDetail?.firstName + " " + LogDetail?.lastName}
                        </span>
                      </li>
                      <li>
                        Email : <span>{LogDetail?.email}</span>
                      </li>
                      <li>
                        Phone : <span>{LogDetail?.primaryPhoneNumber}</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-12 text-center">
                    <Button
                      className="theme_btn mt-3"
                      onClick={() => setPayDetail()}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="makepayment-page">
            <div className="main_wrapper">
              <div className="hed_top d-flex align-items-center justify-content-between mb-3">
                <div className="all_title">Make Payment</div>
              </div>
              <div className="card">
                <div className="card-body">
                  <form
                    action=""
                    onSubmit={handleSubmit(onSubmit)}
                    className="primary-box"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            {" "}
                            Student Name <span className="red-color">*</span>
                          </label>
                          <select
                            className="form-select"
                            {...register("studentGuidId", {
                              required: "This field is required",
                            })}
                          >
                            <option value="">Select Student</option>
                            {stdList.map((item, i) => (
                              <option key={i} value={item?.value}>
                                {item?.label}
                              </option>
                            ))}
                          </select>
                          {errors.studentGuidId && (
                            <span role="alert" className="error_text">
                              {errors.studentGuidId.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Card Number <span className="red-color">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Card Number"
                            value={handleCardDisplay()}
                            {...register("cardNumber", {
                              required: "This field is required",
                              // pattern: {
                              //   value: /^4[0-9]{2,}$/,
                              //   message: "Enter valid Number",
                              // },
                              minLength: {
                                value: 19,
                                message: "Enter only 16 digit number",
                              },
                              maxLength: {
                                value: 19,
                                message: "Enter only 16 digit number",
                              },
                              onChange: (e) => setCardNo(e.target.value),
                            })}
                          />
                          {errors.cardNumber && (
                            <span role="alert" className="error_text">
                              {errors.cardNumber.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Name On Card <span className="red-color">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Name On Card  "
                            {...register("cardName", {
                              required: "This field is required",
                            })}
                          />
                          {errors.cardName && (
                            <span role="alert" className="error_text">
                              {errors.cardName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Card Expiry <span className="red-color">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="MM/YY"
                            onChange={(e) => setExpiryDt(e.target.value)}
                            value={expriy_format(expiryDt)}
                          />
                          {derror?.time_err && (
                            <span role="alert" className="d-block error_text">
                              {derror.time_err}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            CVC <span className="red-color">*</span>{" "}
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Enter CVC"
                            maxLength={3}
                            {...register("cvc", {
                              required: "This field is required",
                              // valueAsNumber: true,
                            })}
                          />
                          {errors.cvc && (
                            <span role="alert" className="error_text">
                              {errors.cvc.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Payment Amount <span className="red-color">*</span>{" "}
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Enter Payment Amount"
                            min={1}
                            {...register("amount", {
                              required: "This field is required",
                              pattern: {
                                value: /^\d+(\.\d{1,2})?$/,
                                message:
                                  "Enter valid amount the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                              },
                              onChange: (e) => handleAmount(e.target.value),
                            })}
                          />
                          {errors.amount && (
                            <span role="alert" className="error_text">
                              {errors.amount.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Fees <span className="red-color">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Payment Amount"
                            {...register("fees")}
                            disabled
                          />
                          {errors.fees && (
                            <span role="alert" className="error_text">
                              {errors.fees.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Net Amount To School{" "}
                            <span className="red-color">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Payment Amount"
                            {...register("schoolReceivableAmount")}
                            disabled
                          />
                          {errors.schoolReceivableAmount && (
                            <span role="alert" className="error_text">
                              {errors.schoolReceivableAmount.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-12 text-end">
                        <Button type="submit" className="theme_btn me-2">
                          Submit
                        </Button>
                        {/* <Button className="cancel_btn" onClick={()=>navigate("/district-setting",{replace:true})}>Cancel</Button> */}
                        <Button
                          className="cancel_btn"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        )}
      </Suspense>
    </>
  );
}

export default ParentMakePayment;
