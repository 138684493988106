import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetCallApi } from "../../../../../../../Action/Action";

const ListTable = lazy(() =>
  import("../../../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "studentNumber", label: "Student Number" },
  { id: "remark", label: "Remark" },
  { id: "date", label: "Date" },
];
function CustomerSheet(props) {
  const { id } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [cushistory, setCushistory] = useState([]);

  // const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getCushis();
    // eslint-disable-next-line
  }, []);
  const getCushis = async () => {
    let seData = {
      url: `/api/payment/paymentHistory?customerId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data?.success) {
        let cus = res.data.payload;
        setCushistory(cus);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <ListTable headCells={productHead} ListData={cushistory} />
      </Suspense>
    </>
  );
}

export default CustomerSheet;
