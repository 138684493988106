import React, { Suspense, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Config } from "../../Utils/Config";
import Swal from "sweetalert2";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { FiUploadCloud } from "react-icons/fi";
import { GetCallApi, PostCallApi } from "../../Action/Action";
// import uploadimg from "../../../images/Icon/file-upload.svg";

const renderLoader = () => <p></p>;

function ReplyTicket(props) {
  const { changeTab, replyT, setReplyT } = props;
  const [openCircle, setOpenCircle] = useState(false);
  let LogDetail = useSelector((state) => state.login?.LoginDetails);
  const [ticketDetail, setTicketDetail] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const onSubmit = async (data) => {
    let tdata = {
      ...data,
      TicketId: ticketDetail?.ticketId,
      userId: LogDetail?.userId,
      createdBy: LogDetail?.userId,
      IsSentByFigSupport: LogDetail?.roleId === 1 ? true : false,
      IsWithOutLogin: false,
    };
    delete tdata?.imgUpload;
    setOpenCircle(true);
    let seData = {
      url: `/api/SupportTicket/RespondSupportTicket`,
      body: tdata,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        // handleclose();
        getTicketData(replyT?.ticketId);
        reset();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(res.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }
  const handleImg = (e) => {
    setValue("FilePath", e.target.files[0]);
    setValue("imgUpload", e.target.files[0].name);
    e.target.files = null;
  };
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    if (replyT?.ticketId) {
      getTicketData(replyT?.ticketId, true);
    }
    // eslint-disable-next-line
  }, [replyT]);

  const getTicketData = async (tId, cl) => {
    let seData = {
      url: `/api/SupportTicket/GetSupportTicketById?ticketId=${tId}&userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    let rs;

    if (res?.status === 200) {
      if (res.data.success) {
        setTicketDetail(res.data.payload);
        rs = res.data.payload;
        if (rs?.status !== "Close") {
          if (cl) {
            let seData = {
              url: `/api/SupportTicket/UpdateReadByAdmin`,
              body: {
                ticketId: tId,
                userId: LogDetail?.userId,
                isReadByAdmin: LogDetail?.roleId === 1 ? true : false,
              },
              headers: headers,
            };
            let respo = await PostCallApi(seData);
            if (respo?.status === 200) {
            }
          }
        }
      }
    }
  };

  const closeTicket = async () => {
    let ticketId = ticketDetail?.ticketId;
    setOpenCircle(true);
    let seData = {
      url: `/api/SupportTicket/UpdateTicketStatus?ticketId=${ticketId}&status=Close&modifiedBy=${LogDetail?.userId}`,
      body: {},
      headers: headers,
    };
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        // handleclose();
        getTicketData(replyT?.ticketId);
        reset();
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: titleCase(res.data?.message),
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const handleclose = () => {
    changeTab("2");
    setReplyT();
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="myTicket">
          {ticketDetail === undefined ? (
            <div className="no_data">No Data Found</div>
          ) : (
            <>
              <div className="d-flex justify-content-between">
                <div className="title">Ticket Detail</div>
                {ticketDetail?.status === "Close" ? (
                  ""
                ) : (
                  <Button className="cancel_btn" onClick={closeTicket}>
                    Close Ticket
                  </Button>
                )}
              </div>
              <div className="content">
                <ul>
                  <li>
                    <div className="left">Ticket Id :</div>
                    <div className="right">{ticketDetail?.ticketId}</div>
                  </li>
                  <li>
                    <div className="left">Created Time :</div>
                    <div className="right">{ticketDetail?.createdOn}</div>
                  </li>
                  <li>
                    <div className="left">Priority :</div>
                    <div className="right">{ticketDetail?.priority}</div>
                  </li>
                  <li>
                    <div className="left">Category :</div>
                    <div className="right">
                      {ticketDetail?.supportCategoryName}
                    </div>
                  </li>{" "}
                  <li>
                    <div className="left">Status :</div>
                    <div className="right">{ticketDetail?.status}</div>
                  </li>
                  {/* <li>
              <div className="left">Elapsed Time :</div>
              <div className="right">18 Days</div>
            </li> */}
                  <li>
                    <div className="left">District Name :</div>
                    <div className="right">
                      {ticketDetail?.schoolDistrictName}
                    </div>
                  </li>{" "}
                  <li>
                    <div className="left">School Name :</div>
                    <div className="right">{ticketDetail?.schoolName}</div>
                  </li>
                  <li>
                    <div className="left">Email :</div>
                    <div className="right">{ticketDetail?.email}</div>
                  </li>{" "}
                  <li>
                    <div className="left">Phone :</div>
                    <div className="right">{ticketDetail?.phone}</div>
                  </li>
                  <li>
                    <div className="left">Subject :</div>
                    <div className="right">{ticketDetail?.subject}</div>
                  </li>
                  <hr className="mb-0" />
                  <li>
                    {/* <div className="left">Description :</div> */}
                    <div className="right no_br">
                      {ticketDetail?.supportTicketTransaction?.map((des, i) => (
                        <div
                          className={`text_box  my-3 ${
                            des.isSentByFigSupport ? "FigAd" : "FigUs"
                          }`}
                          key={i}
                        >
                          <p className="m-0">
                            <span>{des.msgUserName} :</span> {des.description}
                          </p>
                          {des?.filePath ? (
                            <div>
                              <a
                                href={`${Config.API_HOST_URL_live}${des?.filePath}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {des.fileName}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                      {/* <p>
                        <span>Jennifer(04/28/2023 00:24:34) :</span> Hi, this is
                        jennifer, How may i help you today?
                      </p> */}
                    </div>
                  </li>
                  <hr className="mt-0" />
                </ul>
                {ticketDetail?.status === "Close" ? (
                  ""
                ) : (
                  <div className="reply_div">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label>Reply : </label>
                        <textarea
                          className="form-control"
                          rows="10"
                          placeholder="Write your reply here...."
                          {...register("Description", {
                            required: "This field is required",
                          })}
                        ></textarea>
                        {errors?.Description && (
                          <span role="alert" className="error_text">
                            {errors.Description.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group  mt-2 col-md-6">
                        <label className="form-label">Upload Attachment</label>
                        <div className="upload_img">
                          <input
                            type="text"
                            className="form-control upload_box"
                            placeholder="No file Chosen"
                            readOnly
                            {...register("imgUpload", {})}
                          />
                          <input
                            type="file"
                            id="attch_ti"
                            // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="form-control"
                            style={{ display: "none" }}
                            placeholder="User You Want To Be In District"
                            onChange={handleImg}
                          />
                          <label
                            htmlFor="attch_ti"
                            className="upload_img_label mb-0 "
                          >
                            {/* <img src={uploadimg} className="img-fluid" alt="" /> */}
                            <FiUploadCloud />
                          </label>
                        </div>
                        {errors?.imgUpload && (
                          <span role="alert" className="error_text">
                            {errors.imgUpload.message}
                          </span>
                        )}
                      </div>
                      <div className=" mt-2 d-flex ">
                        <Button type="submit" className="theme_btn me-2">
                          Submit
                        </Button>
                        <Button className="cancel_btn" onClick={handleclose}>
                          Back
                        </Button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </Suspense>
    </>
  );
}

export default ReplyTicket;
