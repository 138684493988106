import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreatedistrictManagment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openCircle, setOpenCircle] = useState(false);
  const [distList, setDistList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    setOpenCircle(true);
    Axios.post("/api/Roles/CreateRole", sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (distList) {
      if (id) {
        EditRoleManagment(id);
      }
    }
    // eslint-disable-next-line
  }, [distList, id]);
  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                schId: sd?.schoolDistrictId,
                Districtname: sd?.districtName,
              };
            });
            setDistList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const EditRoleManagment = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/Roles/GetRoleDataByID?roleId=${id}&userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let sdDetail = res.data.payload;
            setValue("roleId", sdDetail?.roleId);
            setValue("roleName", sdDetail?.roleName);
            setValue("schoolDistrictId", sdDetail?.schoolDistrictId);
            // setValue("parentRoleId", sdDetail?.parentRoleId);
            // setValue("description", sdDetail?.description);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createprimarytab-page">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Role</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Role Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Role Name"
                          {...register("roleName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.roleName && (
                          <span role="alert" className="error_text">
                            {errors.roleName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            // required: "This field is required",
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.schId}>
                                {data?.Districtname}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreatedistrictManagment;
