import React, { useEffect, useState } from 'react'
import { Box, Modal, Button } from "@mui/material";
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { GetCallApi, PostCallApi } from '../../../../../Action/Action';
import Swal from 'sweetalert2';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    p: 4,
};

function EditReport(props) {

    const { dataSend, setOpen, open, overDe, servingList, userList, setOverDe, GetCashierList,
        GetServingList, onSubmit, setOpenCircle, singleId, setSingleId } = props

    const [districtList, setDistrictList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [entryTypeList, setEntryTypeList] = useState([]);

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        if (schoolList) {
            if (overDe) {
                setValue("schoolId", overDe?.bmc?.schoolId);
            }
        }
        // eslint-disable-next-line
    }, [schoolList, overDe]);

    useEffect(() => {
        if (servingList) {
            if (overDe) {
                setValue("servingLineId", overDe?.bmc?.servingLineId);
            }
        }
        // eslint-disable-next-line
    }, [servingList, overDe]);

    useEffect(() => {
        if (userList) {
            if (overDe) {
                setValue("cashierUserID", overDe?.bmc?.cashierUserId);
            }
        }
        // eslint-disable-next-line
    }, [userList, overDe]);

    useEffect(() => {
        if (open) {
            getDistrictList();
            getEntryTypeList();
        }
        // eslint-disable-next-line
    }, [open]);

    useEffect(() => {
        if (singleId?.length > 0) {
            EditBulkMealData(singleId);
        }
        // eslint-disable-next-line
    }, [singleId])

    const EditBulkMealData = async (id) => {
        let sedata = {
            url: `/api/BulkMeal/GetBulkMealCountsDataByID?bulkMealCountId=${id}`,
            headers: headers,
        };
        setOpenCircle(true);

        let res = await GetCallApi(sedata);

        if (res?.status === 200) {
            if (res.data.success) {
                setOpenCircle(false);
                let meData = res.data.payload;
                setOverDe(meData);
                setValue("bulkMealCountId", meData?.bmc?.bulkMealCountId);
                setValue("schoolDistrictId", meData?.bmc?.schoolDistrictId);
                getSchoolList(meData?.bmc?.schoolDistrictId);
                GetServingList(meData?.bmc?.schoolDistrictId, meData?.bmc?.schoolId);
                GetCashierList(meData?.bmc?.schoolDistrictId, meData?.bmc?.schoolId);
                setValue("mealService", meData?.bmc?.mealService);
                setValue(
                    "date",
                    meData?.bmc?.date ? meData?.bmc?.date?.split("T")[0] : ""
                );
                setValue("mealCount", meData?.bmc?.mealCount);
                setValue("entryType", meData?.bmc?.entryType);
                setValue("paymentStatus", meData?.bmc?.paymentStatus);
                setSingleId()
            } else {
                setOpenCircle(false);
            }
        } else {
            setOpenCircle(false);
        }
    }

    const getEntryTypeList = async () => {
        let seData = {
            url: `/api/Common/GetEntryTypes`,
            headers: headers,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload;
                setEntryTypeList(dlist?.length > 0 ? dlist : []);
            }
        }
    };
    function convertDate(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const getDistrictList = async () => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);

        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setDistrictList(dlist?.length > 0 ? dlist : []);
                if (!singleId) {
                    setTimeout(() => {
                        setValue("schoolDistrictId", dataSend?.schoolDistrictId)
                    }, 1000);
                    setValue("mealService", dataSend?.mealService);
                    setValue("date", dataSend?.date ? convertDate(dataSend?.date) : "");
                    getSchoolList(dataSend?.schoolDistrictId);
                }

            }
        }
    };

    const getSchoolList = async (id) => {
        // setPrdList([]);
        setSchoolList([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);

            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 1 ? uslist : []);
                }
            }
        } else {
            setSchoolList([]);
        }
    };

    const updateList = async (data) => {
        let sendData = {
            ...data,
            price: 0,
            createdBy: LogDetail?.userId,
        };
        let seData = {
            url: "/api/BulkMeal/CreateBulkMealCounts",
            body: sendData,
            headers: headers,
        };
        setOpenCircle(true);
        let res = await PostCallApi(seData);

        if (res?.status === 200) {
            if (res.data.success) {
                setOpen(false)
                setOpenCircle(false);
                reset();
                setOverDe();
                handleClose();
                onSubmit(dataSend);

                // navigate(-1);
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: res.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            } else {
                setOpenCircle(false);
            }
        } else {
            setOpenCircle(false);
        }
    };

    const handleshowDrop = (e, ml) => {
        if (e) {
            GetServingList(getValues("schoolDistrictId"), e);
            GetCashierList(getValues("schoolDistrictId"), e);
        } else {
        }
    };

    const handleClose = () => {
        setOpen(false);
        reset();
    };

    return (
        <div>
            {open && (
                <>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className="toggle_delete_data"
                    >
                        <Box sx={style} className="delete_data_box">
                            <div className="bulk_create_section">
                                <div className="product_box ">
                                    <div className="all_title ProductTitle">Bulk Entry</div>
                                </div>
                                {/* <div className="card mt-3">
                        <div className="card-body"> */}
                                <form className="my-3" onSubmit={handleSubmit(updateList)}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> District
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("schoolDistrictId", {
                                                        required: "This field is required",
                                                        onChange: (e) =>
                                                            getSchoolList(e.target.value),
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {districtList &&
                                                        districtList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.schoolDistrictId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.schoolDistrictId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> School
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    {...register("schoolId", {
                                                        required: "This field is required",
                                                        onChange: (e) =>
                                                            handleshowDrop(e.target.value),
                                                    })}
                                                >
                                                    <option value="">Select School </option>
                                                    {schoolList.map((item, i) => (
                                                        <option key={i} value={item?.value}>
                                                            {item?.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.schoolId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.schoolId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> Serving Line
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("servingLineId", {
                                                        required: "This field is required",
                                                        valueAsNumber: true,
                                                    })}
                                                >
                                                    <option value="">Select Serving Line</option>
                                                    {servingList &&
                                                        servingList?.map((data, i) => (
                                                            <option key={i} value={data?.servingLineId}>
                                                                {data?.servingLineName}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.servingLineId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.servingLineId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> Meal Service
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    className="form-select"
                                                    {...register("mealService", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select Meal Service </option>
                                                    <option value="Breakfast">Breakfast</option>
                                                    <option value="Dinner">Dinner</option>
                                                    <option value="Lunch">Lunch</option>
                                                    <option value="Snacks">Snacks</option>
                                                </select>
                                                {errors.mealService && (
                                                    <span role="alert" className="error_text">
                                                        {errors.mealService.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Meal Count
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Meal Count"
                                                    min="1"
                                                    {...register("mealCount", {
                                                        required: "This field is required",
                                                        valueAsNumber: true,
                                                    })}
                                                />
                                                {errors.mealCount && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.mealCount.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Meal Date
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder="Meal Date"
                                                    {...register("date", {
                                                        required: "This field is required",
                                                    })}
                                                />
                                                {errors.date && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.date.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> Cashier
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("cashierUserID", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select Cashier</option>
                                                    {userList &&
                                                        userList?.map((data, i) => (
                                                            <option key={i} value={data?.userId}>
                                                                {data?.name}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.cashierUserID && (
                                                    <span role="alert" className="error_text">
                                                        {errors.cashierUserID.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> Payment Type
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("paymentStatus", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select Payment Type</option>
                                                    <option value="F">FREE</option>
                                                    <option value="P">PAID</option>
                                                    {/* <option value="R">REDUCED</option>
                          <option value="FOSTER CHILD">FOSTER CHILD</option>
                          <option value="SNAP">SNAP</option>
                          <option value="TNAF">TNAF</option> */}
                                                </select>
                                                {errors.paymentStatus && (
                                                    <span role="alert" className="error_text">
                                                        {errors.paymentStatus.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> Entry Type
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("entryType", {
                                                        required: "This field is required",
                                                        // onChange: (e) =>
                                                        //   setEntryType(e.target.value),
                                                    })}
                                                >
                                                    <option value="">Select Entry Type</option>
                                                    {entryTypeList &&
                                                        entryTypeList?.map((ent, i) => (
                                                            <option key={i} value={ent.stateName}>
                                                                {ent?.stateName}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.entryType && (
                                                    <span role="alert" className="error_text">
                                                        {errors.entryType.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-end mt-3">
                                            <Button type="submit" className="theme_btn me-2">
                                                Submit
                                            </Button>
                                            <Button
                                                className="cancel_btn"
                                                onClick={handleClose}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Box>
                    </Modal>
                </>
            )}
        </div>
    )
}

export default EditReport