import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress, Box, Tab } from "@mui/material";
import "./index.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CreateParent from "./CreateParent";
import CreateCommunicationAddress from "./CreateCommunicationAddress";
import { useParams } from "react-router-dom";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreatePrimaryTab() {
  const { id } = useParams();

  const [values, setValues] = React.useState("1");
  const [parentId, setParentId] = useState();

  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };
  useEffect(() => {
    if (id) {
      setParentId(id);
    }
  }, [id]);
  return (
    <Suspense fallback={renderLoader()}>
      <section className="student-tabpage">
        <div className="main_wrapper">
          <div className="product_box ">
            <div className="ProductTitle w-100">
              <TabContext value={values}>
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
                  <Box className="tab_group d-flex justify-content-between ">
                    <TabList
                      onChange={handleChangeTab}
                      aria-label="lab API tabs example"
                      className="tab_list"
                    >
                      <Tab
                        label="Parent Information"
                        value="1"
                        className="tabs_link tab_1"
                      />

                      <Tab
                        label="Permanent Address"
                        value="2"
                        className="tabs_link tab_3"
                      />
                      <Tab
                        label="Communication Address"
                        value="3"
                        className="tabs_link tab_3"
                      />
                    </TabList>
                  </Box>
                </div>
                <TabPanel value="1" className="tab_panel p-0">
                  <CreateParent
                    setValues={setValues}
                    setParentId={setParentId}
                  />
                </TabPanel>
                <TabPanel value="2" className="tab_panel p-0">
                  <CreateCommunicationAddress
                    addressType="Permenent"
                    parentId={parentId}
                    values={values}
                    setValues={setValues}
                  />
                </TabPanel>
                <TabPanel value="3" className="tab_panel p-0">
                  <CreateCommunicationAddress
                    addressType="Communication"
                    values={values}
                    parentId={parentId}
                  />
                  {/* <CommunicationTab /> */}
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default CreatePrimaryTab;
