import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function DiscountDetailsReportTab(props) {
  const { tableRef, startDate, endDate,  back } = props;
  // const { tableRef, startDate, endDate, repoData, back } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const reporttable = [
    {
      tilte: "Bill NO",
      Item: "Item",
      Count: "Count",
      Amount: "Amount",
      classset: "cash-border",
    },
    {
      tilte: "12)$10 Brekkie",
      Item: "",
      Count: "1",
      Amount: "2.11",
    },
    {
      tilte: "Asahi Beer",
      Item: "Brekky",
      Count: "Hotpot",
      Amount: "2.11",
    },
    {
      tilte: "Asahi Beer",
      Item: "",
      Count: "1",
      Amount: "9.11",
    },
    {
      tilte: "12)$10 Brekkie",
      Item: "",
      Count: "",
      Amount: "2.11",
    },
    {
      tilte: "Rosted veg Pizza ",
      Item: "",
      Count: "",
      Amount: "",
    },
  ];
  const reporttable1 = [
    {
      tilte1: "Asahi Beer",
      Item1: "Asahi Beer",
      Count1: "9.11",
    },
    {
      tilte1: "TS-37-26454813-10",
      Item1: "Cappuccino",
      Count1: "2.00",
    },
    {
      tilte1: "TS-37-26454813-10",
      Item1: "Cappuccino",
      Count1: "2.00",
    },
    {
      tilte1: "TS-37-26454813-10",
      Item1: "Cappuccino",
      Count1: "2.00",
    },
    {
      tilte1: "TS-37-26454813-10",
      Item1: "Cappuccino",
      Count1: "2.00",
    },
    {
      tilte1: "Grand Total",
      Item1: "182569852513.18",
      Count1: "$0.00",
      classset: "cash-border",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <div className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Discount Details Report</h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">Discount Details Report</h5>
        <div className="report-table">
          {reporttable?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width">{text?.tilte}</h6>
              <h6 className="title-width">{text?.Item}</h6>
              <h6 className="title-width">{text?.Count}</h6>
              <h6 className="title-width">{text?.Amount}</h6>
            </div>
          ))}
          <div className="endreport-border"></div>
          {reporttable1?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width">{text?.tilte1}</h6>
              <h6 className="title-width">{text?.Item1}</h6>
              <h6 className="title-width">{text?.Count1}</h6>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </div>
    </Suspense>
  );
}

export default DiscountDetailsReportTab;
