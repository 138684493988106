import React from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./index.scss";
import default_image from "../../../Assets/default_profile.jpg"

function ImgPopup(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    "justify-content": "center",
    "flex-direction": "column",
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <button
        className="btn_Img_show"
        type="button"
        onClick={handleOpen}
        style={{ backgroundColor: "transparent", border: 0 }}
      >
        <img
          src={props.img}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = default_image;
          }}
          height={40}
          width={40}
          style={{ borderRadius: 50 }}
        />
      </button>
      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_img_Show"
          >
            <Box sx={style} className="delete_data_box">
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                  bgcolor: "#fff",
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className="img_show">
                <img src={props.img} alt="" className="img-fluid "
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = default_image;
                  }} />
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default React.memo(ImgPopup);
