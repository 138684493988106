import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function TopSalesReportTab(props) {
  const { tableRef, repoData, back, school } = props;

  let Time = new Date();

  return (
    <Suspense fallback={renderLoader()}>
      <section className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <img
              src={
                // downloadPdf
                //   ?
                require("../../../../../Assets/PngImage/Header_logo_1.png")
                // : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
              }
              alt=""
              width={100}
              height={80}
            />
            <div className="ms-2">
              <label
                style={{
                  fontSize: "18px",
                  color: "#2c2e35",
                  fontWeight: 600,
                  marginBottom: "7px",
                }}
              >
                Top 25 Sales Report
              </label>
              <div
                className="names"
                style={{
                  fontSize: "16px",
                  color: "#2c2e35",
                  fontWeight: 600,
                  marginBottom: "7px",
                }}
              >
                {school?.startDate ? school?.startDate : ""}
                {school?.endDate ? " - " + school?.endDate : ""}
              </div>
            </div>
          </div>
          <div>
            <label
              style={{
                fontSize: "18px",
                color: "#2c2e35",
                fontWeight: 600,
                marginBottom: "7px",
                width: "250px",
                whiteSpace: "pre-line",
              }}
            >
              {school?.schName}
            </label>
            <div
              className="names"
              style={{
                fontSize: "16px",
                color: "#2c2e35",
                fontWeight: 600,
                marginBottom: "7px",
              }}
            >
              {school?.distName}
            </div>
            <div
              className="names"
              style={{
                fontSize: "16px",
                color: "#2c2e35",
                fontWeight: 600,
                marginBottom: "7px",
              }}
            >
              {Time.toLocaleString()}
            </div>
            <div
              className="names"
              style={{
                fontSize: "16px",
                color: "#2c2e35",
                fontWeight: 600,
              }}
            >
              {school?.Name}
            </div>
          </div>
        </div>
        {/* <div className="border-dash"></div> */}
        {/* <h6 className="mt-3">POS</h6> */}
        {/* <h5>top 25 sales</h5> */}
        <div className="report-table">
          <div disablePadding className={"table-box cash-border"}>
            {/* <div key={i} className={text?.classset === cashborder ? cashborder : borderdash}> */}
            <h6 className="title-width-bill">Bill</h6>
            <h6 className="title-width text-start">Order Name</h6>
            <h6 className="title-width">QTY</h6>
            <h6 className="title-width">Amount</h6>
          </div>
          {repoData?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              {/* <div key={i} className={text?.classset === cashborder ? cashborder : borderdash}> */}
              <h6 className="title-width-bill">{text?.bill}</h6>
              <h6 className="title-width text-start">{text?.orderName}</h6>
              <h6 className="title-width">{text?.qty}</h6>
              {/* <h6 className="title-width">
                {(Math.round(text?.totalAmount * 100) / 100).toFixed(2)}
              </h6> */}
              <h6 className="title-width">{text?.totalAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" })}</h6>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </section>
    </Suspense>
  );
}

export default TopSalesReportTab;
