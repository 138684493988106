import React, { Suspense, useState } from "react";
import { Box, Tab } from "@mui/material";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import PrimaryTab from "./PrimaryTab";
import SecondnaryTab from "./SecondnaryTab";
import CommunicationTab from "./CommunicationTab";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function Households() {
  const [createLink, setCreateLink] = useState("/createprimaryTab/create");
  const [values, setValues] = React.useState("1");
  const [parentId, setParentId] = useState();

  // const handleChangeTab = (event, newValue) => {
  //   setValues(newValue);
  // };
  const handleChangeTab = (event, newValue) => {
    if (newValue === "1") {
      setCreateLink("/createprimaryTab/create");
      setParentId();
    }
    // else if (newValue == 2) {
    //   setCreateLink("/CreateSecondnaryTab/create");
    // }
    else if (newValue === "3") {
      setCreateLink();
    }
    setValues(newValue);
  };

  return (
    <Suspense fallback={renderLoader()}>
      {/* <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
      </div> */}
      <section className="student-tabpage">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="product_box ">
                <div className="ProductTitle w-100">
                  <TabContext value={values}>
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
                      <Box className="tab_group d-flex justify-content-between ">
                        <TabList
                          onChange={handleChangeTab}
                          aria-label="lab API tabs example"
                          className="tab_list"
                        >
                          <Tab
                            label="Parent Information"
                            value="1"
                            className="tabs_link tab_1"
                          />
                          {/* <Tab
                        label="Secondnary Information"
                        value="2"
                        className="tabs_link tab_2"
                      /> */}
                          <Tab
                            label="Communication History"
                            value="3"
                            className="tabs_link tab_3"
                          />
                        </TabList>
                      </Box>
                      <div className="create-btn">
                        {values === "1" ? (
                          <Link to={createLink} className="theme_btn">
                            <BsPlusSquareDotted
                              fontSize={24}
                              className="me-3"
                            />
                            Create
                          </Link>
                        ) : (
                          ""
                        )}
                        {/* {values === "1" || values === "2" ? (
                      <Link to={createLink} className="theme_btn">
                        <BsPlusSquareDotted fontSize={24} className="me-3" />
                        Create
                      </Link>
                    ) : (
                      ""
                    )} */}
                      </div>
                    </div>
                    <TabPanel value="1" className="tab_panel p-0">
                      <PrimaryTab
                        setParentId={setParentId}
                        gotoCom={setValues}
                      />
                    </TabPanel>
                    <TabPanel value="2" className="tab_panel p-0">
                      <SecondnaryTab />
                    </TabPanel>
                    <TabPanel value="3" className="tab_panel p-0">
                      <CommunicationTab parentId={parentId} />
                    </TabPanel>
                  </TabContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default Households;
