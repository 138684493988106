import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Axios from "../../../../../../../Utils/AxiosConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Button } from "@mui/material";

function CreateCommunicationAddress(props) {
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [openCircle, setOpenCircle] = useState(false);
  const [siteList, setSiteList] = useState();

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let sendData = {
      ...data,
      createdBy: LogDetail?.userId,
      addressType: props.addressType,
      parentGuidId: props.parentId,
    };
    setOpenCircle(true);

    Axios.post("/api/ParentAddress/CreateParentAddress", sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            if (props.values === "3") {
              reset();
              navigate(-1);
            } else {
              props.setValues("3");
            }
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (siteList?.length > 0) {
      if (props.parentId) {
        EditParentTab(props.parentId);
      }
    }
    // eslint-disable-next-line
  }, [siteList, props.parentId]);
  useEffect(() => {
    getStateList();
    // eslint-disable-next-line
  }, []);
  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setSiteList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const EditParentTab = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/ParentAddress/GetParentAddressDataByID?parentGuidId=${id}&addressType=${props.addressType}&userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let edData = res.data.payload;
            setValue("parentAddressId", edData?.parentAddressId);
            setValue("address", edData?.address);
            setValue("apartment", edData?.apartment);
            setValue("city", edData?.city);
            setValue("state", edData?.state);
            setValue("zip", edData?.zip);
            setValue("preferredLangue", edData?.perferredLangue);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <section className="createprimarytab-page">
        {/* <div className="main_wrapper"> */}
        {/* <h4 className="all_title mb-4">Parent Information</h4> */}
        <div className="card">
          <div className="card-body">
            <form
              action=""
              onSubmit={handleSubmit(onSubmit)}
              className="primary-box"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Address <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      {...register("address", {
                        required: "This field is required",
                      })}
                    />
                    {errors.address && (
                      <span role="alert" className="error_text">
                        {errors.address.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      APT
                      {/* <span className="red-color">*</span> */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="APT"
                      {...register("apartment", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.apartment && (
                      <span role="alert" className="error_text">
                        {errors.apartment.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      city <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="city"
                      {...register("city", {
                        required: "This field is required",
                      })}
                    />
                    {errors.city && (
                      <span role="alert" className="error_text">
                        {errors.city.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {" "}
                      State <span className="red-color">*</span>
                    </label>
                    <select
                      name=""
                      className="form-select"
                      {...register("state", {
                        required: "This field is required",
                        valueAsNumber: true,
                      })}
                    >
                      <option value="">Select state</option>
                      {siteList &&
                        siteList?.map((data, i) => (
                          <option key={i} value={data?.stateId}>
                            {data?.stateName}
                          </option>
                        ))}
                    </select>
                    {errors.state && (
                      <span role="alert" className="error_text">
                        {errors.state.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Zip <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Zip"
                      {...register("zip", {
                        required: "This field is required",
                        pattern: {
                          message: "zip",
                        },
                      })}
                    />
                    {errors.zip && (
                      <span role="alert" className="error_text">
                        {errors.zip.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Preferred Language <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Preferred Language"
                      {...register("preferredLangue", {
                        required: "This field is required",
                      })}
                    />
                    {errors.preferred && (
                      <span role="alert" className="error_text">
                        {errors.preferred.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  {props.parentId ? (
                    <Button type="submit" className="theme_btn me-2">
                      {props.values === "3" ? "Submit" : "Next"}
                    </Button>
                  ) : props.values === "3" ? (
                    <Button
                      className="theme_btn me-2"
                      onClick={() => navigate(-1)}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      className="theme_btn me-2"
                      onClick={() => props.setValues("3")}
                    >
                      Next
                    </Button>
                  )}
                  <Button className="cancel_btn" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
}

export default CreateCommunicationAddress;
