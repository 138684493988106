import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function LoginDetailsTab() {
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <div className="login-section">
          <div className="row">
            <div className="col-lg-5 col-md-8 col-10">
              <div className="login-box mb-5">
                <h6>Email</h6>
                <Link href="mailto:Janny@gmail.com">{LogDetail?.email}</Link>
              </div>
              <div className="login-box">
                <h6>Password</h6>
                <p>{LogDetail?.usPs}</p>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default LoginDetailsTab;
