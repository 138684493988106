import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import { useForm } from "react-hook-form";
import filter from "../../../../Assets/PngImage/filter.png";

const ListTable = lazy(() =>
  import("../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "mealName", label: "Meal Name", click: true },
  { id: "mealType", label: "Meal Type" },
  { id: "districtName", label: "District Name" },
  { id: "price", label: "Price" },
  { id: "orderByDate", label: "Order By Date" },
];
function MealInformation() {
  const [mealList, setMealList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // let deleteInfo = useSelector((state) => state.Common?.DeleteData);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getMealList();
    // eslint-disable-next-line
  }, []);
  const getMealList = (data) => {
    setOpenCircle(true);
    setMealList([]);
    let Usdata = {
      studentId: LogDetail?.studentGuidId,
      startDate: data?.startDate ? data?.startDate : null,
      endDate: data?.endDate ? data?.endDate : null,
      userId: LogDetail?.userId,
    };

    Axios.post(`/api/Student/GetMealInfoByStudentId`, Usdata, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let sdlist = res.data.payload?.map((x) => {
              return {
                ...x,
                mealList: x?.mealName,
                mealName:
                  x?.mealName?.length > 25
                    ? x?.mealName?.substring(0, 25) + "..."
                    : x?.mealName,
                tlt: "Meal Name",
              };
            });
            setMealList(sdlist);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
            setMealList([]);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="meals_information ">
          <div className="main_wrapper">
            <div className="product_box ">
              <div className="all_title ProductTitle">Meal Information</div>
            </div>
            <div className="white-box">
              <form onSubmit={handleSubmit(getMealList)}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Start Date <span className="red-color">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("startDate", {
                          required: "This field is required",
                          valueAsDate: true,
                        })}
                      />
                      {errors.startDate && (
                        <span role="alert" className="error_text">
                          {errors.startDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        End Date <span className="red-color">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("endDate", {
                          required: "This field is required",
                          valueAsDate: true,
                          validate: (val) => {
                            if (watch("startDate") > val) {
                              return "Your start date can not greater than from end date";
                            }
                          },
                        })}
                      />
                      {errors.endDate && (
                        <span role="alert" className="error_text">
                          {errors.endDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 my-auto text-end">
                    <Button type="submit" className="theme_btn filter-img">
                      <img src={filter} className="me-1 " alt="" /> Apply
                    </Button>
                  </div>
                </div>
              </form>
              <ListTable
                headCells={productHead}
                action={false}
                ListData={mealList}
              />
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default MealInformation;
