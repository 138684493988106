import React, { Suspense, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateSecondnaryTab() {
  const [citylist, setcitylist] = useState([]);
  const [statelist, setstatelist] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    GetCityData();
    GetStateData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      EditSecondaryTab(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const GetCityData = () => {
    Axios.get(`/HouseholdSecondaryTab`)
      .then((res) => {
        if (res?.status === 200) {
          setcitylist(res.data);
        } else {
          setcitylist([]);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetStateData = () => {
    Axios.get(`/HouseholdSecondaryTab`)
      .then((res) => {
        if (res?.status === 200) {
          setstatelist(res.data);
        } else {
          setstatelist([]);
        }
      })
      .catch((e) => console.log(e));
  };

  const onSubmit = (data) => {
    if (data?.id) {
      Axios.put(`/HouseholdSecondaryTab/${data.id}`, data)
        .then((res) => {
          reset();
          if (res?.status === 200) {
            navigate(-1);
          }
        })
        .catch((e) => console.log(e));
    } else {
      Axios.post("/HouseholdSecondaryTab", data)
        .then((res) => {
          reset();
          navigate(-1);
        })
        .catch((e) => console.log(e));
    }
  };

  const EditSecondaryTab = (id) => {
    Axios.get(`/HouseholdSecondaryTab/${id}`).then((res) => {
      let editsecondaryData = res.data;
      setValue("id", editsecondaryData?.id);
      setValue("address", editsecondaryData?.address);
      setValue("apt", editsecondaryData?.apt);
      setValue("city", editsecondaryData?.city);
      setValue("state", editsecondaryData?.state);
      setValue("zip", editsecondaryData?.zip);
      setValue("pname", editsecondaryData?.pname);
      setValue("email", editsecondaryData?.email);
    });
  };

  return (
    <Suspense fallback={renderLoader()}>
      <section className="createSecoundTab">
        <div className="main_wrapper">
          <h4 className="all_title mb-4">Secondnary Information</h4>
          <div className="card">
            <div className="card-body">
              <form
                action=""
                onSubmit={handleSubmit(onSubmit)}
                className="primary-box"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Address <span className="red-color">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        {...register("address", {
                          required: "This field is required",
                          pattern: {
                            message: "address",
                          },
                        })}
                      />
                      {errors.address && (
                        <span role="alert" className="error_text">
                          {errors.address.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        APT <span className="red-color">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="APT"
                        {...register("apt", {
                          required: "This field is required",
                          pattern: {
                            message: "apt",
                          },
                        })}
                      />
                      {errors.apt && (
                        <span role="alert" className="error_text">
                          {errors.apt.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        city <span className="red-color">*</span>
                      </label>
                      <select
                        name=""
                        className="form-select"
                        id="selectmethod"
                        defaultValue="0"
                        {...register("city", {
                          required: true,
                        })}
                      >
                        <option value="0">Select city</option>
                        {citylist?.map((item, i) => (
                          <option value={item.city} key={i}>
                            {item.city}
                          </option>
                        ))}
                      </select>
                      {errors.city && (
                        <span role="alert" className="error_text">
                          {errors.city.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        {" "}
                        State <span className="red-color">*</span>
                      </label>
                      <select
                        name=""
                        className="form-select"
                        id="selectmethod"
                        defaultValue="0"
                        {...register("state", {
                          required: true,
                        })}
                      >
                        <option value="0">Select state</option>
                        {statelist?.map((item, i) => (
                          <option value={item.state} key={i}>
                            {item.state}
                          </option>
                        ))}
                      </select>
                      {errors.state && (
                        <span role="alert" className="error_text">
                          {errors.state.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Zip <span className="red-color">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Zip "
                        {...register("zip", {
                          required: "This field is required",
                          pattern: {
                            message: "zip",
                          },
                        })}
                      />
                      {errors.zip && (
                        <span role="alert" className="error_text">
                          {errors.zip.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        parent Name <span className="red-color">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="parent Name  "
                        {...register("pname", {
                          required: "This field is required",
                          pattern: {
                            message: "pname",
                          },
                        })}
                      />
                      {errors.pname && (
                        <span role="alert" className="error_text">
                          {errors.pname.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        Email <span className="red-color">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Email "
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            message: "email",
                          },
                        })}
                      />
                      {errors.email && (
                        <span role="alert" className="error_text">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Button type="submit" className="theme_btn me-2">
                      Submit
                    </Button>
                    <Button className="cancel_btn" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default CreateSecondnaryTab;
