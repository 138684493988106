import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import Smallpop from "../../../Components/Reusable/Smallpop";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

function AssignHeadMenu() {
  const [pathname, setPathname] = useState();
  const [wideSubMenu, setWideSubMenu] = useState(false);

  const [options, setOptions] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      setPathname(location.pathname);
    }
  }, [location]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const MenuList = useSelector((state) => state.headerMenu?.LoginHeadList);

  const MenuName = useSelector((state) => state.setting?.MenuName);
  let navigate = useNavigate();
  const showSub = (event, item) => {
    setWideSubMenu(false);
    if(item?.menuName === "Reports"){
      setWideSubMenu(true);
    }
    if (item?.subMenu?.length === 0) {
      navigate(item?.url, { replace: true });
    } else if (item?.subMenu !== undefined) {
      setAnchorEl(event?.currentTarget);
      setOptions(item?.subMenu);
    } else {
      setAnchorEl();
      setOptions();
    }
  };
  return (
    <Nav
      navbar
      className="header_item mx-auto"
      style={{
        display: "flex",
        listStyle: "none",
        justifyContent: "space-around",
      }}
    >
      {MenuList?.map((item, i) => (
        <React.Fragment key={i}>
          {item?.subMenu?.length > 0 ? (
            <NavItem
              className={`header_active nav-item ${
                item.subMenu?.find((data) => data?.menuName === MenuName)
                  ? `linear ${MenuName} `
                  : ""
              }`}
              key={i}
            >
              <NavLink
                className={`header_link ${
                  item.subMenu?.find((data) => data?.menuUrl === pathname)
                    ? "ac"
                    : ""
                }`}
                onClick={(e) => showSub(e, item)}
                to={`${item?.subMenu?.length > 0 ? "" : item.menuUrl}`}
              >
                {item.menuName}
                {item?.subMenu?.length > 0 ? (
                  <i className="fas fa-chevron-down icon"></i>
                ) : (
                  ""
                )}
              </NavLink>
            </NavItem>
          ) : (
            <NavItem
              className={`header_active nav-item ${
                item.subMenu?.find((data) => data?.menuName === MenuName)
                  ? `linear ${MenuName} `
                  : ""
              }`}
              key={i}
            >
              <NavLink
                className={`header_link ${
                  pathname === item.menuUrl ? "ac" : ""
                }`}
                to={`${item.menuUrl}`}
              >
                {item.menuName}
              </NavLink>
            </NavItem>
          )}
        </React.Fragment>
      ))}
      <Smallpop
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        wideSubMenu={wideSubMenu}
        optionsMenu={options}
        megamenu={options?.length >= 4 ? "megamenu" : ""}
        className={`Menu_icon_settings`}
      />
    </Nav>
  );
}

export default React.memo(AssignHeadMenu);
