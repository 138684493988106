import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  DeleteData: false,
  DashPopup: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    DashboarPopupOpen(state, action) {
      state.DashPopup = action.payload;
    },
    DeleteDataFor(state, action) {
      state.DeleteData = action.payload;
    },
    resetCommon: () => initialState,
  },
});

export const { DeleteDataFor, resetCommon, DashboarPopupOpen } =
  commonSlice.actions;

export default commonSlice.reducer;
