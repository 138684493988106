import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function DepartmentSalesReportTab(props) {
  const { tableRef, startDate, endDate, back } = props;
  // const { tableRef, repoData, startDate, endDate, back } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const reporttable = [
    {
      Department: "Department",
      Count: "Count",
      Amount: "Amount",
      Amt: "Amt%",
      classset: "cash-border",
    },
    {
      Department: "Beer",
      Count: "105",
      Amount: "8908.70",
      Amt: "0.01",
    },
    {
      Department: "Box Specials",
      Count: "105",
      Amount: "8908.70",
      Amt: "7.34",
    },
    {
      Department: "Breads & Salads",
      Count: "18",
      Amount: "2108.70",
      Amt: "0.03",
    },
    {
      Department: "Breads , st,arters & Salads",
      Count: "3943",
      Amount: "115401.00",
      Amt: "0.34",
    },
    {
      Department: "Box Specials",
      Count: "105",
      Amount: "8908.70",
      Amt: "7.34",
    },
    {
      Department: "Brekkie",
      Count: "105",
      Amount: "8908.70",
      Amt: "0.01",
    },
    {
      Department: "Grand Total",
      Count: "",
      Amount: "89047898.70",
      Amt: "$0.00",
      classset: "cash-border",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <section className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Department Sales Report</h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">Department Sales Report</h5>
        <div className="report-table">
          {reporttable?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width">{text?.Department}</h6>
              <h6 className="title-width">{text?.Count}</h6>
              <h6 className="title-width">{text?.Amount}</h6>
              <h6 className="title-width">{text?.Amt}</h6>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </section>
    </Suspense>
  );
}

export default DepartmentSalesReportTab;
