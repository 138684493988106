import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateReimbursementRate() {
  const [distList, setDistList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [stateList, setStateList] = useState();
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [schoolList, setSchoolList] = useState();
  const [overlaySelect, setOverlaySelect] = useState([]);
  const [derror, setDerror] = useState();
  const [reim, setReim] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getStateList();
    getSchoolYear();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (distList?.length > 0) {
      if (id) {
        ReimbursementRatesEdit(id);
      }
    }
    // eslint-disable-next-line
  }, [distList, id]);
  // useEffect(() => {
  //   if (schoolList) {
  //     if (id) {
  //       setValue("schoolId", overDe?.rr?.schoolId);
  //     }
  //   }
  // }, [schoolList, id]);
  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                schId: sd?.schoolDistrictId,
                districtName: sd?.districtName,
              };
            });
            setDistList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getSchoolYear = () => {
    function convertYear(str) {
      var date = new Date(str);
      return date.getFullYear();
    }
    Date.prototype.addYears = function (n) {
      var now = new Date();
      return new Date(now?.setFullYear(now?.getFullYear() + n));
    };
    let schY = [...Array(5).keys()]?.map((item, i) => {
      let td = convertYear(new Date().addYears(5 - (i + 2)));

      td = td.toString().slice(2);
      let ny = convertYear(new Date().addYears(5 - (i + 1)));
      ny = ny.toString().slice(2);

      return {
        id: td + "-" + ny,
        year:
          convertYear(new Date().addYears(5 - (i + 2))) +
          "-" +
          convertYear(new Date().addYears(5 - (i + 1))),
      };
    });
    schY.reverse();
    setSchoolYearList(schY);
  };
  const onSubmit = (data) => {
    let is_success = true;
    if (!id) {
      if (overlaySelect?.length === 0) {
        is_success = false;
        setDerror({ sch_err: "Add minimum one school" });
      }
    }
    if (is_success) {
      setDerror({ sch_err: "" });

      let sendData = {
        ...data,
        createdBy: LogDetail?.userId,
        schoolId: id ? [data?.schoolId] : overlaySelect,
      };
      setOpenCircle(true);
      Axios.post("/api/ReimbursementRates/CreateReimbursementRates", sendData, {
        headers,
      })
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setOpenCircle(false);
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: res.data?.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "#48953e",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
              reset();
              navigate(-1);
            } else {
              setDerror({ err: res.data.message });
              setOpenCircle(false);
            }
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const ReimbursementRatesEdit = (id) => {
    setOpenCircle(true);
    Axios.get(
      `api/ReimbursementRates/GetReimbursementRatesDataByID?recordId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let catData = res.data.payload[0];
            setReim(catData.rr);
            setValue("schoolDistrictId", catData?.rr?.schoolDistrictId);
            getSchoolList(catData?.rr?.schoolDistrictId);
            setValue("recordId", catData?.rr?.recordId);
            setValue("mealStatusType", catData?.rr?.mealStatusType);
            setValue("mealType", catData?.rr?.mealType);
            setValue("rateType", catData?.rr?.rateType);
            setValue("federalRate", catData?.rr?.federalRate);
            setValue("stateRate", catData?.rr?.stateRate);
            setValue("totalRate", catData?.rr?.totalRate);
            setValue("schoolYear", catData?.rr?.schoolYear);
            setValue("state", catData?.rr?.state);

            //setOverDe(catData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (schoolList?.length > 0) {
      if (reim) {
        setValue("schoolId", reim.schoolId);
      }
    }
    // eslint-disable-next-line
  }, [schoolList, reim]);

  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setStateList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = (id) => {
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload?.map((sd, i) => {
                return { label: sd.schoolName, value: sd.schoolId };
              });
              setSchoolList(SdData);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };
  const handleIOCMulti = (e) => {
    setOverlaySelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createUserManage">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Create ReimbursementRate</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          disabled={id ? true : false}
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.schId}>
                                {data?.districtName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>School</label>
                        {id ? (
                          <>
                            {" "}
                            <select
                              name=""
                              className="form-select"
                              // disabled={id ? true : false}
                              {...register("schoolId", {
                                required: "This field is required",
                              })}
                            >
                              <option value="">Select School </option>
                              {schoolList &&
                                schoolList?.map((data, i) => (
                                  <option key={i} value={data?.value}>
                                    {data?.label}
                                  </option>
                                ))}
                            </select>
                            {errors.schoolId && (
                              <span role="alert" className="error_text">
                                {errors.schoolId.message}
                              </span>
                            )}
                          </>
                        ) : (
                          <Select
                            isMulti
                            name="type"
                            options={schoolList}
                            // closeMenuOnSelect={false}
                            // hideSelectedOptions={false}
                            className="form-neselect"
                            classNamePrefix="select"
                            placeholder="Select School"
                            value={schoolList?.filter((obj) =>
                              overlaySelect?.includes(obj.value)
                            )}
                            onChange={handleIOCMulti}
                            style={{ width: "100%" }}
                          />
                        )}
                        {derror?.sch_err && (
                          <span role="alert" className="d-block error_text">
                            {derror.sch_err}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School Name <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          disabled={id ? true : false}
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School Name</option>
                          {schoolList &&
                            schoolList?.map((item, i) => (
                              <option value={item.schoolId} key={i}>
                                {item.schoolName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          MealStatus Type <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("mealStatusType", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        >
                          <option value="">Select MealStatus Type</option>
                          <option value="F">FREE</option>
                          <option value="P">PAID</option>
                          <option value="R">REDUCED</option>
                          <option value="FOSTER CHILD">FOSTER CHILD</option>
                          <option value="SNAP">SNAP</option>
                          <option value="TNAF">TNAF</option>
                        </select>
                        {errors.mealStatusType && (
                          <span className="formError errorMssg error_text">
                            {errors.mealStatusType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Meal Type <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("mealType", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        >
                          <option value="">Select Meal Type</option>
                          <option value="Breakfast">Breakfast</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.mealType && (
                          <span className="formError errorMssg error_text">
                            {errors.mealType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Rate Type <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("rateType", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        >
                          <option value="">Select Rate Type</option>
                          <option value="HIGH">HIGH</option>
                          <option value="REG">REG</option>
                        </select>
                        {errors.rateType && (
                          <span className="formError errorMssg error_text">
                            {errors.rateType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Federal Rate <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Federal Rate"
                          {...register("federalRate", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid ceu the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                            },
                          })}
                        />
                        {errors.federalRate && (
                          <span role="alert" className="error_text">
                            {errors.federalRate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          State Rate <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="State Rate"
                          {...register("stateRate", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid amount the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                            },
                          })}
                        />
                        {errors.stateRate && (
                          <span role="alert" className="error_text">
                            {errors.stateRate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Total Rate <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Total Rate"
                          {...register("totalRate", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid amount the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                            },
                          })}
                        />
                        {errors.totalRate && (
                          <span role="alert" className="error_text">
                            {errors.totalRate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          School Year <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolYear", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                          })}
                        >
                          <option value="">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.id}>
                                {data?.year}
                              </option>
                            ))}
                        </select>
                        {errors.schoolYear && (
                          <span role="alert" className="error_text">
                            {errors.schoolYear.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          State <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("state", {
                            // required: "This field is required",
                            // valueAsNumber: true,
                          })}
                        >
                          <option value="">Select State</option>
                          {stateList &&
                            stateList?.map((data, i) => (
                              <option key={i} value={data?.stateId}>
                                {data?.stateName}
                              </option>
                            ))}
                        </select>
                        {errors.state && (
                          <span role="alert" className="error_text">
                            {errors.state.message}
                          </span>
                        )}
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateReimbursementRate;
