import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress, Box, Backdrop, Modal } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
// import { BsPlusCircleDotted } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
// import DeletePopup from "../../../../../Components/Reusable/DeletePopup";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const templateHead = [
  { id: "Remark", label: "Remark" },
  { id: "studentNumber", label: "Student Number" },
  { id: "studentName", label: "Student Name" },
  { id: "headOfHouseholdName", label: "Parent Name" },
  { id: "schoolName", label: "School Name" },
  { id: "unallocatedBalance", label: "Balance" },
  { id: "email", label: "Email" },
  { id: "phone1", label: "Phone" },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function CustomersList() {
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // const [derror, setDerror] = useState();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const handleClose = () => {
    setOpen(false);
    // setDerror({ err: "" });
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setParentList(dlist?.length > 0 ? dlist : []);
      }
    }
  };
  const getSchoolList = async (id) => {
    setSchoolList([]);
    setValue("schoolId", "");
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          let uslist = [...dlist];
          // let uslist = [{ value: "all", label: "Select All" }, ...dlist];

          setSchoolList(uslist?.length > 0 ? uslist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };

  const getCustList = async (data) => {
    let seData = {
      // url: `/api/Customer/GetAllCustomer`,
      url: `/api/Customer/GetCustomerFilter`,
      body: {
        ...data,
        schoolDistrictId: data?.schoolDistrictId
          ? data?.schoolDistrictId
          : null,
        schoolId: data?.schoolId ? data?.schoolId : null,
      },
      headers: headers,
    };
    setOpenCircle(true);
    // let res = await GetCallApi(seData);
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        let d = res?.data?.payload?.map((x) => {
          return {
            ...x,
            Remark: (
              <div>
                <Button onClick={handleOpen} className="ms-3 add-log-btn">
                  Add Log
                </Button>
                <Button className="ms-3 add-log-btn">Send Payment Link</Button>
              </div>
            ),
            studentName: x?.firstName + " " + x?.lastName,
            unallocatedBalance: x?.unallocatedBalance?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`edit/${x?.customerId}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
                {/* <DeletePopup
                  title={"Customer"}
                  text={x?.firstName + " " + x?.lastName}
                  url={`/api/Customer/DeleteCustomerById?customerId=${x?.customerId}`}
                /> */}
              </>
            ),
          };
        });
        setPrdList(d);
      }
    }
  };
  return (
    <Suspense fallback={renderLoader()}>
      <section className="customer_listpage">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="product_box">
                <div className="all_title ProductTitle">Customer List</div>
                {/* <Link to={"create"} className="theme_btn">
                  <BsPlusCircleDotted fontSize={24} className="me-3" />
                  Create
                </Link> */}
              </div>
              <form onSubmit={handleSubmit(getCustList)}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label> District</label>
                      <select
                        name=""
                        className="form-select"
                        {...register("schoolDistrictId", {
                          // required: "This field is required",
                          onChange: (e) => getSchoolList(e.target.value),
                        })}
                      >
                        <option value="">Select School District</option>
                        {parentList &&
                          parentList?.map((data, i) => (
                            <option key={i} value={data?.value}>
                              {data?.label}
                            </option>
                          ))}
                      </select>
                      {errors.schoolDistrictId && (
                        <span role="alert" className="error_text">
                          {errors.schoolDistrictId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>School</label>
                      <select
                        name=""
                        className="form-select"
                        {...register("schoolId", {
                          // required: "This field is required",
                        })}
                      >
                        <option value="">Search School</option>
                        {schoolList &&
                          schoolList?.map((data, i) => (
                            <option key={i} value={data?.value}>
                              {data?.label}
                            </option>
                          ))}
                      </select>
                      {errors.schoolId && (
                        <span className="formError errorMssg error_text">
                          {errors.schoolId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Student Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Number"
                        {...register("studentNo")}
                      />
                      {errors.studentNo && (
                        <span className="formError errorMssg error_text">
                          {errors.studentNo.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        {...register("firstName")}
                      />
                      {errors.firstName && (
                        <span className="formError errorMssg error_text">
                          {errors.firstName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        {...register("lastName")}
                      />
                      {errors.lastName && (
                        <span className="formError errorMssg error_text">
                          {errors.lastName.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Email Criteria</label>
                      <select
                        name=""
                        className="form-select"
                        {...register("emailCriteria")}
                      >
                        <option value="all">All</option>
                        <option value="has emails">Has Emails</option>
                        <option value="no emails">No Emails</option>
                      </select>
                      {errors.emailCriteria && (
                        <span className="formError errorMssg error_text">
                          {errors.emailCriteria.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Order by</label>
                      <select
                        name=""
                        className="form-select"
                        {...register("orderBy")}
                      >
                        <option value="low balance">Low Balance</option>
                        <option value="high balance">High Balance</option>
                      </select>
                      {errors.orderBy && (
                        <span className="formError errorMssg error_text">
                          {errors.orderBy.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" ms-auto mt-auto col-md-3">
                    <div className="form-group text-end filter-img">
                      {openCircle ? (
                        <Button className="theme_btn text-end">
                          Applying Filter{" "}
                          <CircularProgress
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        </Button>
                      ) : (
                        <Button className="theme_btn text-end" type="submit">
                          <img src={filter} className="me-1" alt="" />
                          Apply
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <ListTable
                headCells={templateHead}
                action={true}
                ListData={prdList}
              />
            </div>
          </div>
        </div>
      </section>
      <>
        {/* <button className="btn_dlt " onClick={handleOpen}>
                    <MdDelete className="icon" fontSize={24} />
                </button> */}

        {open && (
          <>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_open_data"
            >
              <Box sx={style} className="open_box">
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => 9999 }}
                  open={openCircle}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_detail_box">
                  <h6>Add Remark</h6>
                  <div className="col-md-12 p-3">
                    <div className="form-group">
                      <label>Remark</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please add remark..."
                      //     {...register("lastName", {
                      //         required: "This field is required",
                      //     })}
                      />
                    </div>
                  </div>
                  <div className="popup_detail">
                    <button
                      type="button"
                      className="popup_btn save"
                    // onClick={handledelete}
                    >
                      Save Log
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </>
    </Suspense>
  );
}

export default CustomersList;
