import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop } from "@mui/material";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import defaultProfile from "../../../../Assets/default_profile.jpg";
import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import {Config} from "../../../../Utils/Config";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import Select from "react-select";

const ListTable = lazy(() =>
  import("../../../../Components/Reusable/ListTable")
);

const ImgPopup = lazy(() => import("../../../../Components/Reusable/ImgPopup"));
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "profilePhoto", label: "Profile" },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "birthDate", label: "DOB" },
  { id: "gradeId", label: "Grade" },
  { id: "schoolName", label: "School/Site" },
  // { id: "primary", label: "Primary household" },
  // { id: "elegibility", label: "Eligibility" },
  { id: "email", label: "Email" },
  { id: "homeroom", label: "Homeroom" },
  // { id: "accountbalance", label: "Account Balance" },
  // { id: "active", label: "Active" },
];

function ManualOrderEntry() {
  const [openCircle, setOpenCircle] = useState(false);
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [servingList, setServingList] = useState([]);
  const [overlayList, setOverlayList] = useState([]);
  const [mealList, setMealList] = useState([]);
  const [selectedMeal, setSelectedMeal] = useState([]);
  const [homeList, setHomeList] = useState([]);
  const [userList, setUserList] = useState();

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const [prdList, setPrdList] = useState([]);
  const [prdAddAr, setPrdAddAr] = useState([]);

  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolDistrictId,
                label: sd?.districtName,
              };
            });
            setDistrictList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = (id) => {
    setPrdList([]);
    setPrdAddAr([]);
    setSchoolList([]);
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const dlist = res.data.payload?.map((sd) => {
                return {
                  value: sd?.schoolId,
                  label: sd?.schoolName,
                };
              });
              setSchoolList(dlist?.length > 0 ? dlist : []);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };
  const GetServingList = (district, sch) => {
    if (district && sch) {
      Axios.get(
        `/api/ServingLine/GetServingLineDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userId=${LogDetail?.userId}`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload;
              setServingList(SdData);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setServingList([]);
    }
  };
  const GetOverlayList = (sch, ml) => {
    if (sch && ml) {
      setSelectedMeal([]);
      Axios.get(
        `/api/OverLay/GetOverLayDataBySchoolId?schoolId=${sch}&userId=${LogDetail?.userId}&mealService=${ml}`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload;
              setOverlayList(SdData);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setOverlayList([]);
    }
  };
  const GetMealList = (ol, ml) => {
    if (ol && ml) {
      Axios.get(
        `/api/Meals/GetMealsByOverlayId?overlayId=${ol}&mealtype=${ml}&userId=${LogDetail?.userId}`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload?.map((x) => {
                return { ...x, label: x?.mealTitle, value: x?.mealId };
              });
              setMealList(SdData);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setMealList([]);
    }
  };
  const GetUserData = (district, sch) => {
    if (district && sch) {
      Axios.get(
        `/api/User/GetUserDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userType=Cashier`
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload?.map((sd, i) => {
                return {
                  name: sd?.name,
                  userId: sd?.userId,
                };
              });
              setUserList(SdData?.length > 0 ? SdData : []);
            } else {
              setUserList([]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setUserList([]);
    }
  };
  const StudentTabCreate = (dis, sch, home) => {
    setOpenCircle(true);
    setPrdAddAr([]);
    let sendData = {
      schoolDistrictId: dis,
      schoolId: sch,
      name: "",
      gradeId: "",
      homeroom: home ? home : "",
    };
    Axios.post(`/api/Student/GetStudentDataBySchoolDistrictID`, sendData, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                profilePhoto: (
                  <ImgPopup
                    img={
                      sd?.profilePhoto
                        ? `${Config.API_HOST_URL_live}${sd?.profilePhoto}`
                        : defaultProfile
                    }
                  />
                ),
                address: sd?.address,
                birthDate: sd?.birthDate
                  ? new Date(sd?.birthDate)?.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : "",
                city: sd?.city,
                country: sd?.country,
                email: sd?.email,
                firstName: sd?.firstName,
                lastName: sd?.lastName,
                gradeId: sd?.gradeId,
                joiningDate: sd?.joiningDate,
                phone: sd?.phone,
                schoolName: sd?.schoolName,
                schoolDistrict: sd?.schoolDistrict,
                state: sd?.state,
                studentGuidId: sd?.studentGuidId,
                studentId: sd?.studentId,
                studentNumber: sd?.studentNumber,
                zipCode: sd?.zipCode,
                homeroom: sd?.homeroom,
                isActive: "ture",
                Action: (
                  <>
                    <label className=" personal_detail_label">
                      <input
                        type="checkbox"
                        className="login_check"
                        name="Remember Me"
                        checked={
                          prdAddAr?.filter((x) => x === sd?.studentGuidId)
                            ?.length > 0
                            ? true
                            : false
                        }
                        onChange={(e) =>
                          handleAddProduct(
                            e?.target?.checked,
                            sd?.studentGuidId
                          )
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </>
                ),
              };
            });
            setPrdList(SdData);
          } else {
            setOpenCircle(false);
            setPrdList([]);
            setPrdAddAr([]);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const handleAddProduct = (val, studentGuidId) => {
    if (val) {
      setPrdAddAr((oldArray) => [...oldArray, studentGuidId]);
    } else {
      setPrdAddAr((oldArray) =>
        oldArray?.filter((data) => data !== studentGuidId)
      );
    }
  };
  const handleSelectAll = (val, list) => {
    if (val) {
      setPrdAddAr(list?.map((x) => x.studentGuidId));
    } else {
      setPrdAddAr([]);
    }
  };
  useEffect(() => {
    if (prdAddAr?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [prdAddAr]);
  const changetbData = (val) => {
    let newtb = prdList?.map((pd) => {
      return {
        ...pd,
        Action: (
          <label className=" personal_detail_label">
            <input
              type="checkbox"
              className="login_check"
              name="Remember Me"
              checked={
                prdAddAr?.filter((x) => x === pd?.studentGuidId)?.length > 0
                  ? true
                  : false
              }
              onChange={(e) =>
                handleAddProduct(e?.target?.checked, pd?.studentGuidId)
              }
            />
            <span className="checkmark"></span>
          </label>
        ),
      };
    });
    setPrdList(newtb);
  };

  const AddStudents = (data) => {
    let sendData = {
      ...data,
      mealId: selectedMeal,
      studentGuidId: prdAddAr,
      loginUserId: LogDetail?.userId,
      freeMealAlaCarte: 'Ala Carte',
      isPointRedeemedMeal: false,
    };
    setOpenCircle(true);
    // return false;
    Axios.post(`/api/MealOrder/SaveBulkMealOrderEntry`, sendData, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            setPrdAddAr([]);
            setSelectedMeal([]);
            setPrdList([]);
            reset();
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const GetTeacherList = (dis, sch) => {
    Axios.post(
      `/api/Parents/ParentsListByDistrictAndSchoolId?schoolDistrictId=${dis}&schoolId=${sch}&parentName=`,
      {},
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload;
            setHomeList(dlist);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const handleshowDrop = (e, ml) => {
    if (e) {
      GetServingList(getValues("schoolDistrictId"), e);
      GetUserData(getValues("schoolDistrictId"), e);
      StudentTabCreate(getValues("schoolDistrictId"), e);
      setValue("homeroom", "");
      GetTeacherList(getValues("schoolDistrictId"), e);
    } else {
      setPrdList([]);
      setPrdAddAr([]);
    }
  };

  const handleMealMulti = (e) => {
    setSelectedMeal(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="role_listpage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">
                    Manual Meal Entry
                  </div>
                </div>
                <form>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {districtList &&
                            districtList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School</label>
                        <select
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                            onChange: (e) =>
                              handleshowDrop(
                                e.target.value,
                                getValues("mealService")
                              ),
                          })}
                        >
                          <option value="">Select School </option>
                          {schoolList &&
                            schoolList?.map((item, i) => (
                              <option key={i} value={item?.value}>
                                {item?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> Homeroom</label>
                        <select
                          className="form-select"
                          {...register("homeroom", {
                            required: "This field is required",
                            onChange: (e) =>
                              StudentTabCreate(
                                getValues("schoolDistrictId"),
                                getValues("schoolId"),
                                e.target.value
                              ),
                          })}
                        >
                          <option value="">Select Homeroom </option>
                          {homeList &&
                            homeList?.map((item, i) => (
                              <option key={i} value={item?.homeroom}>
                                {item?.homeroom}
                              </option>
                            ))}
                        </select>
                        {errors.homeroom && (
                          <span role="alert" className="error_text">
                            {errors.homeroom.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> Serving Line</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("servingLineId", {
                            required: "This field is required",
                            valueAsNumber: true,
                          })}
                        >
                          <option value="">Select Serving Line</option>
                          {servingList &&
                            servingList?.map((data, i) => (
                              <option key={i} value={data?.servingLineId}>
                                {data?.servingLineName}
                              </option>
                            ))}
                        </select>
                        {errors.servingLineId && (
                          <span role="alert" className="error_text">
                            {errors.servingLineId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> Meal Service</label>
                        <select
                          className="form-select"
                          {...register("mealService", {
                            required: "This field is required",
                            onChange: (e) =>
                              GetOverlayList(
                                getValues("schoolId"),
                                e.target.value
                              ),
                          })}
                        >
                          <option value="">Select Meal Service </option>
                          <option value="Breakfast">Breakfast</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.mealService && (
                          <span role="alert" className="error_text">
                            {errors.mealService.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> Overlay</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("overlayId", {
                            required: "This field is required",
                            valueAsNumber: true,
                            onChange: (e) =>
                              GetMealList(
                                e.target.value,
                                getValues("mealService")
                              ),
                          })}
                        >
                          <option value="">Select Overlay</option>
                          {overlayList &&
                            overlayList?.map((data, i) => (
                              <option key={i} value={data?.overlayId}>
                                {data?.overlayName}
                              </option>
                            ))}
                        </select>
                        {errors.overlayId && (
                          <span role="alert" className="error_text">
                            {errors.overlayId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> Meal</label>
                        <Select
                          isMulti
                          name="type"
                          options={mealList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select Meal"
                          value={mealList?.filter((obj) =>
                            selectedMeal?.includes(obj.value)
                          )}
                          onChange={handleMealMulti}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> Cashier</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("cashierId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Cashier</option>
                          {userList &&
                            userList?.map((data, i) => (
                              <option key={i} value={data?.userId}>
                                {data?.name}
                              </option>
                            ))}
                        </select>
                        {errors.cashierId && (
                          <span role="alert" className="error_text">
                            {errors.cashierId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Order Date</label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("orderDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.orderDate && (
                          <span role="alert" className="error_text">
                            {errors.orderDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <ListTable
                    headCells={productHead}
                    action={true}
                    actionFirst={true}
                    ListData={prdList}
                    btnTxt={"Add Order"}
                    // AddProductToDistrict={AddProductToDistrict}
                    selectAllBtn={true}
                    prdAddAr={prdAddAr}
                    AddStudents={handleSubmit(AddStudents)}
                    selectingAll={handleSelectAll}
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ManualOrderEntry;
