import React, { Suspense, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./index.scss";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { Config } from "../../../../../Utils/Config";
import { BsFillPatchCheckFill } from "react-icons/bs";
import default_image from "../../../../../Assets/default_profile.jpg";
import { useBarcode } from "next-barcode";
import NotFound from "../../../../../Assets/Image_not_available.jpg";
import Select from "react-select";
import {
  SwalError,
  SwalMessage,
  SwalSuccess,
} from "../../../../../Components/Reusable/SwalMessage";
import { SearchMyChildren } from "../SearchMyChildren";
import { customFilter } from "../../../../../Components/Reusable/CustomFilterForReactSelect";
import { useTranslation } from "react-i18next";
import ChangeLanguage from "../../../../../locales/ChangeLang";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 800 : "90%",
  maxHeight: "80%",
  overflow: "auto",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function CallNewBarcode({ barcodeValue }) {
  const { inputRef } = useBarcode({
    value: barcodeValue,
    options: {
      background: "#ffffff",
    },
  });
  return <svg ref={inputRef} />;
}

export function StudentPopup(props) {
  const {
    open,
    setOpen,
    openCircle,
    studentGuiId,
    setStudentGuiId,
    setOpenCircle,
  } = props;

  const [profileImg, setProfileImg] = useState();
  const [barCodeId, setBarCodeId] = useState();
  const [studData, setStudData] = useState();

  useEffect(() => {
    if (studentGuiId) {
      StudentDetails(studentGuiId);
    }
    // eslint-disable-next-line
  }, [studentGuiId]);

  const StudentDetails = async (sgId) => {
    setProfileImg();
    setStudentGuiId();
    if (sgId) {
      setOpenCircle(true);
      let stdUrl = {
        url: `/api/Survey/GetStudentDataByStudentGuidID?studentGuidId=${sgId}`,
      };
      const respo = await GetCallApi(stdUrl);
      setOpenCircle(false);
      if (respo?.status === 200) {
        if (respo?.data?.success) {
          let allData = respo?.data?.payload;
          setStudData(allData);
          setProfileImg(allData?.profilePhoto);
          setBarCodeId(allData?.barcode);
          //   setValue("studentId", allData?.studentId);
          //   setValue("schoolDistrict", allData?.schoolDistrict);
          //   setValue("school", allData?.school);
          //   setValue("studentName", allData?.firstName + " " + allData?.lastName);
          //   setValue("gradeId", allData?.gradeId);
          //   setValue("birthDate", convertDate(allData?.birthDate));
          //   setValue("joiningDate", convertDate(allData?.joiningDate));
          //   setValue("phone", allData?.phone);
          //   setValue("email", allData?.email);
          //   setValue("address", allData?.address);
          //   setValue("city", allData?.city);
          //   setValue("state", allData?.state);
          //   setValue("zipCode", allData?.zipCode);
          //   setValue("country", allData?.country);
          //   setValue("paymentStatus", allData?.paymentStatus);
          //   setValue(
          //     "studentBalance",
          //     allData?.studentBalance?.toLocaleString("en-US", {
          //       style: "currency",
          //       currency: "USD",
          //     })
          //   );
          //   setValue("gender", allData?.gender);
          //   setValue("homeroom", allData?.homeroom);
          //   setValue("ethnicity", allData?.ethnicity);
          //   setValue("foster", allData?.foster);
          //   setValue("allergenGroupName", allData?.allergenGroupName);
          //   setValue("isDC", allData?.isDC);
        }
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setStudentGuiId();
    // reset();
  };

  return (
    open && (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="student_deatils_modal"
        >
          <Box sx={style} className="delete_data_box">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => 9999 }}
              open={openCircle}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <div
              className="popup_delete_detail_box"
              style={{
                display: "inline-block",
                overflow: "auto",
                height: "100%",
              }}
            >
              <h6>Student Detail</h6>
              <div className="p-4">
                <div className="row main_div">
                  <div className="col-xl-3 col-lg-3 col-md-4">
                    <div className="form-group">
                      <label className="form-label">Profile Image</label>
                      <div>
                        <img
                          src={
                            profileImg
                              ? `${Config?.API_HOST_URL_live}${profileImg}`
                              : default_image
                          }
                          width={270}
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = default_image;
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4">
                    <div className="form-group">
                      <div className="form-group">
                        <label>Barcode</label>
                        <div>
                          <CallNewBarcode barcodeValue={barCodeId} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12  ">
                    <div className=" student_details table-responsive">
                      <table className="table ">
                        <tbody>
                          <tr>
                            <td>Student Id</td>
                            <td>{studData?.studId}</td>
                            <td>Student Name</td>
                            <td>
                              {studData?.firstName + " " + studData?.lastName}
                            </td>
                          </tr>{" "}
                          <tr>
                            <td>School District</td>
                            <td>{studData?.schoolDistrict}</td>
                            <td>School</td>
                            <td>{studData?.school}</td>
                          </tr>
                          <tr>
                            <td>Email</td>
                            <td>{studData?.email}</td>
                            <td>Grade</td>
                            <td>{studData?.gradeId}</td>
                          </tr>{" "}
                          <tr>
                            <td>Birth Date</td>
                            <td>{studData?.birthDate}</td>
                            <td>Joining Date</td>
                            <td>{studData?.joiningDate}</td>
                          </tr>{" "}
                          <tr>
                            <td>Phone</td>
                            <td>{studData?.phone}</td>
                            <td>Address</td>
                            <td>{studData?.address}</td>
                          </tr>{" "}
                          <tr>
                            <td>City</td>
                            <td>{studData?.city}</td>
                            <td>State</td>
                            <td>{studData?.state}</td>
                          </tr>{" "}
                          <tr>
                            <td>Country</td>
                            <td>{studData?.country}</td>
                            <td>Zip Code</td>
                            <td>{studData?.zipCode}</td>
                          </tr>
                          <tr>
                            <td>Payment Status</td>
                            <td>{studData?.paymentStatus}</td>
                            <td>Gender</td>
                            <td>{studData?.gender}</td>
                          </tr>
                          <tr>
                            <td>Student Balance</td>
                            <td>
                              {studData?.studentBalance?.toLocaleString(
                                "en-US",
                                {
                                  style: "currency",
                                  currency: "USD",
                                }
                              )}
                            </td>
                            <td>Home Room</td>
                            <td>{studData?.homeroom}</td>
                          </tr>{" "}
                          <tr>
                            <td>Ethnicity</td>
                            <td>{studData?.ethnicity}</td>
                            <td>Foster</td>
                            <td>{studData?.foster}</td>
                          </tr>
                          <tr>
                            <td>Allergen Group Name</td>
                            <td>{studData?.allergenGroupName}</td>
                            <td>Is DC</td>
                            <td>{studData?.isDC ? "True" : "False"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Student Id</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("studentId")}
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">School District</label>
                          <input
                            name=""
                            className="form-select"
                            type="text"
                            disabled
                            {...register("schoolDistrict")}
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label"> School</label>

                          <input
                            name=""
                            className="form-select"
                            type="text"
                            disabled
                            {...register("school", {
                              required: "This field is required",
                            })}
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Student Name</label>

                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("studentName", {
                              required: "This field is required",
                            })}
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Email</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("email", {
                              required: "This field is required",
                            })}
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Grade</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("gradeId", {
                              required: "This field is required",
                            })}
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Birth Date</label>
                          <input
                            name=""
                            className="form-control"
                            type="date"
                            disabled
                            {...register("birthDate", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.birthDate && (
                            <span role="alert" className="error_text">
                              {errors.birthDate.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Joining Date</label>
                          <input
                            name=""
                            className="form-control"
                            type="date"
                            disabled
                            {...register("joiningDate", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.joiningDate && (
                            <span role="alert" className="error_text">
                              {errors.birtjoiningDatehDate.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Phone</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("phone", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.phone && (
                            <span role="alert" className="error_text">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Address</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("address", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.address && (
                            <span role="alert" className="error_text">
                              {errors.address.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">city</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("city", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.city && (
                            <span role="alert" className="error_text">
                              {errors.city.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">State</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("state", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.state && (
                            <span role="alert" className="error_text">
                              {errors.state.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Country</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("country", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.country && (
                            <span role="alert" className="error_text">
                              {errors.country.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Zip Code</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("zipCode", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.zipCode && (
                            <span role="alert" className="error_text">
                              {errors.zipCode.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Payment Status</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("paymentStatus", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.paymentStatus && (
                            <span role="alert" className="error_text">
                              {errors.paymentStatus.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Gender</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("gender", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.gender && (
                            <span role="alert" className="error_text">
                              {errors.gender.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Student Balance</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("studentBalance", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.studentBalance && (
                            <span role="alert" className="error_text">
                              {errors.studentBalance.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Home Room</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("homeroom", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.homeroom && (
                            <span role="alert" className="error_text">
                              {errors.homeroom.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">EthniCity</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("ethnicity", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.ethnicity && (
                            <span role="alert" className="error_text">
                              {errors.ethnicity.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Foster</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("foster", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.foster && (
                            <span role="alert" className="error_text">
                              {errors.foster.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Allergen Group Name
                          </label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("allergenGroupName", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.allergenGroupName && (
                            <span role="alert" className="error_text">
                              {errors.allergenGroupName.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-4">
                        <div className="form-group">
                          <label className="form-label">Is DC</label>
                          <input
                            name=""
                            className="form-control"
                            type="text"
                            disabled
                            {...register("isDC", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.isDC && (
                            <span role="alert" className="error_text">
                              {errors.isDC.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="text-end">
                  <Button
                    type="button"
                    className="cancel_btn"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    )
  );
}

function CreateSurveyParent() {
  const { id, dId } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [distList, setDistList] = useState([]);
  const [selectDist, setSelectDist] = useState("");
  const [stdList, setStdList] = useState([]);
  const [studId, setStudId] = useState();
  const [qiId, setQiId] = useState("");
  const [payDetail, setPayDetail] = useState(false);
  const [open, setOpen] = useState(false);
  const [studentGuiId, setStudentGuiId] = useState();
  const [distLogo, setDistLogo] = useState([]);
  const [searchMyChildOpen, setSearchMyChildOpen] = useState();
  const [schoolList, setSchoolList] = useState([]);

  const { t } = useTranslation();
  const nav = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { fields, append } = useFieldArray({
    control,
    name: "StudentList",
  });

  const { fields: Field1, append: append1 } = useFieldArray({
    control,
    name: "houseHoldList",
  });

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const Lang = useSelector((state) => state.language.lang);

  useEffect(() => {
    if (Lang) {
      clearErrors();
    }
    // eslint-disable-next-line
  }, [Lang]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (!payDetail) {
      getDistrictList(LogDetail?.parentsGuidId);
      getStateList();
      setValue("actualIncome", "0");
      setValue("paymentCycle", "Annual");
      setValue("surveyBenifitField", "NA");
      if (!id) {
        append1({
          birthDate: "",
          firstName: "",
          lastName: "",
          isStudent: false,
          studentGuidId: null,
          grade: "",
          actualIncome: 0,
          paymentCycle: "",
          studentType: "",
          country: "USA",
          removable: true,
          houseHoldIncomeSub: [
            { incomeSource: "", incomeAmount: "", paymentCycle: "" },
          ],
        });
      }
    }
    // eslint-disable-next-line
  }, [payDetail]);

  useEffect(() => {
    if (fields) {
      setValue("NoOfPeoeple", fields?.length);
    }
    // eslint-disable-next-line
  }, [fields]);

  useEffect(() => {
    setSelectDist(dId);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (selectDist) {
      getSchoolList(selectDist);
      getDistrictListLogo(selectDist);
    }
    // eslint-disable-next-line
  }, [selectDist, id]);

  const getStateList = async (sId) => {
    let stateUrl = {
      url: `/api/Common/GetStateList?userId=${LogDetail?.parentsGuidId}`,
      headers: headers,
    };
    const res = await GetCallApi(stateUrl);
    if (res?.status === 200) {
      if (res.data.success) {
        setStateList(res.data.payload);
        if (sId && res.data.payload) {
          setTimeout(() => {
            setValue("state", sId);
          }, 500);
        }
      }
    }
  };

  const getDistrictList = async (pId) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/SchoolDistrict/GetDistrictListByParentsId?parentsId=${pId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
        if (dlist?.length === 1) {
          setTimeout(() => {
            setSelectDist(dlist[0]?.value);
          }, 500);
        }
      }
    }
  };

  const getSchoolList = async (dsId) => {
    setOpenCircle(true);
    setSchoolList([]);
    if (dsId) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${dsId}&userId=${LogDetail?.parentsGuidId}`,
      };
      let res = await GetCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          setSchoolList(dlist?.length > 0 ? dlist : []);
        }
      }
    }
    if (id) {
      getEditStudentList(id);
    } else {
      setTimeout(() => {
        getStudentList(LogDetail?.parentsGuidId, dsId);
      }, 200);
    }
  };

  const getDistrictListLogo = async (distId) => {
    if (distId) {
      let seData = {
        url: `/api/SchoolDistrict/GetDistrictLogoById?schoolDistrictId=${distId}`,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload;
          setDistLogo(dlist?.length > 0 ? dlist : []);
        }
      }
    }
  };

  const getStudentList = async (id, dsId) => {
    setOpenCircle(true);
    setValue("StudentList", []);
    let stdUrl = {
      url: `/api/ParentModule/GetStudentListByParentID?parentGuidId=${id}`,
      headers: headers,
    };
    const res = await GetCallApi(stdUrl);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload;
        if (dlist === undefined || dlist?.length === 0) {
          const data = [
            {
              birthDate: "",
              firstName: "",
              lastName: "",
              isStudent: true,
              studentGuidId: null,
              fosterChild: "NA",
              grade: "",
              schoolId: "",
              isFosterChild: false,
              studentType: "",
              country: "USA",
            },
          ];
          setValue("StudentList", data);
          setValue("houseHoldList", [
            {
              ...data[0],
              isStudent: false,
              houseHoldIncomeSub: [
                { incomeSource: "", incomeAmount: "", paymentCycle: "" },
              ],
            },
          ]);
        }
        let nr = dlist
          ?.filter((x) => x?.schoolDistrictId === dsId)
          ?.map((std, index) => {
            return {
              birthDate: std?.birthDate?.split("T")[0],
              firstName: std?.firstName,
              lastName: std?.lastName,
              isStudent: true,
              studentGuidId: std?.studentGuidId ? std?.studentGuidId : null,
              grade: std?.grade,
              isFosterChild: std?.isFosterChild,
              fosterChild: std?.fosterChild ? std?.fosterChild : "NA",
              schoolId: std?.schoolId,
              studentType: "",
              studentId: std?.studentId,
              country: "USA",
              removable: true,
            };
          });
        if (nr?.length > 0 || nr !== undefined) {
          setValue("StudentList", nr);
          setStdList(dlist?.length > 0 ? dlist : []);
          if (stdList) {
            // added for remove warning
          }
          handleChoise("val", 0);
        }
        handleActualIncome();
      } else {
        const data = [
          {
            birthDate: "",
            firstName: "",
            lastName: "",
            isStudent: true,
            studentGuidId: null,
            schoolId: "",
            fosterChild: "NA",
            isFosterChild: false,
            grade: "",
            studentType: "",
            country: "USA",
            removable: true,
          },
        ];
        setValue("StudentList", data);
        setValue("houseHoldList", [
          {
            ...data[0],
            isStudent: false,
            houseHoldIncomeSub: [
              { incomeSource: "", incomeAmount: "", paymentCycle: "" },
            ],
          },
        ]);
      }
    }
  };

  const getEditStudentList = async (id) => {
    setOpenCircle(true);
    if (id) {
      let apiUrl = {
        url: `/api/Survey/GetHouseHoldSurveryByHouseHoldId?houseHoldId=${id}`,
        headers: headers,
      };
      const res = await GetCallApi(apiUrl);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          let srData = res.data.payload.hhs;
          setValue(
            "surveyBenifitField",
            srData?.surveyBenifitField
              ? srData?.surveyBenifitField === "FDPIR"
                ? "FDPIR"
                : srData?.surveyBenifitField === "SNAP"
                ? "SNAP"
                : srData?.surveyBenifitField === "TANF"
                ? "TANF"
                : srData?.surveyBenifitField === "NA"
                ? "NA"
                : srData?.surveyBenifitField
              : ""
          );
          setValue("caseName", srData?.caseName);
          setValue("caseNumber", srData?.caseNumber);
          setValue("firstName", srData?.firstName);
          setValue("fullName", srData?.fullName);
          setValue("address", srData?.address);
          setValue("apartment", srData?.apartment);
          setValue("phone", srData?.phone);
          setValue("city", srData?.city);
          getStateList(srData?.state);
          setValue("ssnNumber", srData?.ssnnumber);
          setValue("signatureName", srData?.signature);
          setValue("isSSN", srData?.isSsn);
          setValue("NoOfPeoeple", srData?.noOfPeoeple);
          setValue("isOtherStateEbtbenifit", srData?.isOtherStateEbtbenifit);
          setValue("isSummerEBT", srData?.isSummerEbt);
          setValue("sign", srData?.signature?.length > 0 ? true : false);
          setValue("houseHoldSurveyId", id);
          setValue("totalActualIncome", srData?.totalActualIncome);
          setValue("totalChildIncome", srData?.totalChildIncome);
          setValue("childPaymentCycle", srData?.childPaymentCycle);
          setValue(
            "emailConfirmationAddress",
            srData?.emailConfirmationAddress
          );
          setValue("isHispanic", srData?.isHispanic?.toString());
          let arV = [];
          if (srData?.isAmericanIndianAlaskaNative) {
            arV.push("isAmericanIndianAlaskaNative");
          }
          if (srData?.isAsian) {
            arV.push("isAsian");
          }
          if (srData?.isBlackAfricanAmerican) {
            arV.push("isBlackAfricanAmerican");
          }
          if (srData?.isNativeHawaiianOtherPacificIslande) {
            arV.push("isNativeHawaiianOtherPacificIslande");
          }
          if (srData?.isWhite) {
            arV.push("isWhite");
          }
          setValue("isRace", arV?.length > 0 ? arV : []);

          setTimeout(() => {
            setValue("paymentCycle", srData?.paymentCycle);
            setValue("totalActualIncome", srData?.totalActualIncome);
          }, 200);
          const dlist = res.data.payload.studentList;
          setSelectDist(
            srData?.hhs?.schoolDistrictId
              ? srData?.hhs?.schoolDistrictId
              : dlist?.find((x) => x?.isStudent === true)?.schoolDistrictId
          );
          if (dlist === undefined || dlist?.length === 0) {
            const data = [
              {
                birthDate: "",
                firstName: "",
                lastName: "",
                isStudent: true,
                isFosterChild: false,
                studentGuidId: null,
                grade: "",
                schoolId: "",
                fosterChild: "NA",
                actualIncome: "",
                paymentCycle: "",
                studentType: "",
                country: "USA",
              },
            ];
            setValue("StudentList", data);
            setValue("houseHoldList", [
              {
                ...data[0],
                isStudent: false,
                houseHoldIncomeSub: [
                  { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                ],
              },
            ]);
          }
          let nr = dlist?.map((std, index) => {
            return {
              birthDate: std?.birthDate?.split("T")[0],
              firstName: std?.firstName,
              lastName: std?.lastName,
              isStudent: std?.isStudent ? true : false,
              studentGuidId: std?.studentGuidId,
              grade: std?.grade,
              schoolId: std?.schoolId ? std?.schoolId : "",
              actualIncome: std?.actualIncome ? std?.actualIncome : 0,
              fosterChild: std?.fosterChild ? std?.fosterChild : "NA",
              isFosterChild: std?.isFosterChild,
              paymentCycle: "",
              studentType: "",
              studentId: std?.studentId,
              country: "USA",
              removable: true,
              houseHoldStudentSurveyId: std?.houseHoldStudentSurveyId,
              houseHoldIncomeSub: std?.houseHoldIncomeList?.map((x, i) => {
                return {
                  incomeAmount: x?.incomeAmount,
                  incomeSource: x?.incomeSource,
                  paymentCycle: x?.paymentCycle,
                };
              }),
            };
          });
          if (nr?.length > 0 || nr !== undefined) {
            nr = [...nr];
            setValue(
              "StudentList",
              nr?.filter((x) => x?.isStudent === true)
            );
            setValue(
              "houseHoldList",
              nr?.filter((x) => x?.isStudent === false)
            );
            handleChoise("val", 0);
          }
          handleActualIncome();
        } else {
          const data = [
            {
              birthDate: "",
              firstName: "",
              lastName: "",
              isStudent: true,
              studentGuidId: null,
              schoolId: "",
              fosterChild: "NA",
              isFosterChild: false,
              grade: "",
              studentType: "",
              country: "USA",
              removable: true,
            },
          ];
          setValue("StudentList", data);
          setValue("houseHoldList", [
            {
              ...data[0],
              isStudent: false,
              houseHoldIncomeSub: [
                { incomeSource: "", incomeAmount: "", paymentCycle: "" },
              ],
            },
          ]);
        }
      }
    }
  };

  const handleChoise = (id, index) => {
    setStudId(id);
    setQiId(index);
    if (id === studId) {
      setStudId(studId);
    }
    if (index === qiId) {
      setQiId(qiId);
    }
    if (index === "NA") {
      handleActualIncome();
      setValue("caseName", "");
      setValue("caseNumber", "");
    }
  };

  const onSubmit = async (data) => {
    let is_success = true;
    if (data?.StudentList?.length === 0) {
      is_success = false;
      await SwalMessage("Select minimun one as student");
    }

    let radioVal = {};

    if (Array.isArray(data?.isRace)) {
      const validCheckboxNames = [
        "isAmericanIndianAlaskaNative",
        "isAsian",
        "isBlackAfricanAmerican",
        "isNativeHawaiianOtherPacificIslande",
        "isWhite",
      ];
      const filteredRaceKeys = data?.isRace.filter((key) =>
        validCheckboxNames.includes(key)
      );
      filteredRaceKeys.forEach(function (prop, index) {
        radioVal[prop] = true;
      });
    }

    let allstudent = [];
    if (data?.surveyBenifitField === "NA") {
      allstudent = [...data?.StudentList, ...data?.houseHoldList];
    } else {
      allstudent = data?.StudentList;
    }
    if (is_success) {
      setOpenCircle(true);
      let sendData = {
        ...data,
        ...radioVal,
        createdBy: LogDetail?.parentsGuidId,
        TotalHousholdAnuualIncome: 0,
        isHispanic:
          data?.isHispanic === "true"
            ? true
            : data?.isHispanic === "false"
            ? false
            : null,
        schoolDistrictId: selectDist ? selectDist : null,
        parentGuidId: id ? null : LogDetail?.parentsGuidId,
        StudentSurvey: allstudent?.map((x) => {
          return {
            ...x,
            studentGuidId: x.studentGuidId ? x?.studentGuidId : null,
            isFosterChild: x?.isFosterChild ? x?.isFosterChild : false,
            actualIncome: x?.actualIncome ? x?.actualIncome : 0,
            schoolId: x?.schoolId ? x?.schoolId : null,
            schoolDistrictId: selectDist ? selectDist : null,
            houseHoldIncomeSub:
              x?.houseHoldIncomeSub?.length > 0
                ? x?.houseHoldIncomeSub?.map((y) => {
                    return {
                      ...y,
                      incomeAmount: y?.incomeAmount >= 0 ? y?.incomeAmount : 0,
                    };
                  })
                : [],
          };
        }),
      };
      let Sedata = new FormData();

      if (data?.StudentList?.length > 0) {
        data?.StudentList?.map((d) => {
          return Sedata.append("StudentSurvey", JSON.stringify(d));
        });
      } else {
        Sedata.append("StudentSurvey", []);
      }
      ["StudentList"].forEach((e) => delete sendData[e]);
      for (var key in sendData) {
        Sedata.append(key, sendData[key]);
      }

      let createUrl = {
        url: `/api/Survey/CreateHouseholdInformationSurvey`,
        body: sendData,
        headers: headers,
      };

      const res = await PostCallApi(createUrl);
      if (res?.status === 200 && res.data.success) {
        !id ? nav(-1) : window.close();
        SwalSuccess(res.data?.message);
        reset();
        setOpenCircle(false);
      } else {
        SwalError(res.data?.message);
        setOpenCircle(false);
      }
    }
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const handleAdd = (item) => {
    append({
      birthDate: convertDate(item?.birthDate),
      firstName: item?.firstName,
      lastName: item?.lastName,
      studentId: item?.studentId,
      isStudent: true,
      studentGuidId: item?.studentGuidId ? item?.studentGuidId : null,
      schoolId: item?.schoolId,
      grade: item?.grade,
      removable: true,
      bg: item?.bg,
      fosterChild: "NA",
      isFosterChild: false,
    });
  };

  const handleActualIncome = () => {
    const studentList = getValues("StudentList");
    const houseHold = getValues("houseHoldList");
    let Allmember = [...studentList, ...houseHold];
    const paymentCycles = Allmember.map((std) => std?.paymentCycle);

    const firstCycle = paymentCycles[0];
    const allCyclesMatch = paymentCycles.every((cycle) => cycle === firstCycle);

    if (allCyclesMatch) {
      const totalMulti = 1;
      const allIncome = Allmember.filter((std) => std?.actualIncome)
        .map((x) => totalMulti * x?.actualIncome)
        .reduce((acc, current) => acc + parseFloat(current), 0);

      setValue("paymentCycle", firstCycle);
      setValue("totalActualIncome", allIncome);
    } else {
      const allIncome1 = Allmember.filter((std) => std?.actualIncome)
        .map((x) => {
          const totalMulti1 =
            x.paymentCycle === "Annual"
              ? 1
              : x.paymentCycle === "Monthly"
              ? 12
              : x.paymentCycle === "TwicePerMonth"
              ? 24
              : x.paymentCycle === "EveryTwoWeeks"
              ? 26
              : x.paymentCycle === "Weekly"
              ? 52
              : 1;

          return x.actualIncome * totalMulti1;
        })
        .reduce((acc, current) => acc + parseFloat(current), 0);

      setValue("paymentCycle", "Annual");
      setValue("totalActualIncome", allIncome1);
    }
  };

  const handleCaseNum = (num) => {
    if (num?.length === 10) {
      setValue("totalActualIncome", 0);
      getValues("StudentList")?.map((_, i) => {
        return (
          setValue(`StudentList.${i}.actualIncome`, 0),
          setValue(`StudentList.${i}.paymentCycle`, "Annual")
        );
      });
      getValues("houseHoldList")?.map((x, i) => {
        return (
          setValue(`houseHoldList.${i}.actualIncome`, 0),
          setValue(`houseHoldList.${i}.paymentCycle`, "Annual")
        );
      });
    } else {
      handleActualIncome();
    }
  };

  const handleCreateRemove = (ind) => {
    let deleted = getValues("StudentList")?.filter((_, i) => i !== ind);
    setValue("StudentList", []);
    setTimeout(() => {
      setValue(
        "StudentList",
        deleted?.map((x) => {
          return {
            ...x,
            birthDate: !isNaN(new Date(x?.birthDate).getTime())
              ? new Date(x?.birthDate).toISOString().split("T")[0]
              : "",
            actualIncome: x?.actualIncome ? x?.actualIncome : "",
          };
        })
      );
      handleActualIncome();
    }, 100);
  };

  const handleRemove1 = (ind) => {
    let deleted = getValues("houseHoldList")?.filter((_, i) => i !== ind);
    setValue("houseHoldList", []);
    setTimeout(() => {
      setValue(
        "houseHoldList",
        deleted?.map((x) => {
          return {
            ...x,
            birthDate: !isNaN(new Date(x?.birthDate).getTime())
              ? new Date(x?.birthDate).toISOString().split("T")[0]
              : "",
            actualIncome: x?.actualIncome ? x?.actualIncome : "",
          };
        })
      );
      handleActualIncome();
    }, 100);
  };

  const handleAddAnother = (ind) => {
    let deleted = getValues("houseHoldList");
    setTimeout(() => {
      setValue(
        "houseHoldList",
        deleted?.map((x, i) => {
          if (ind === i) {
            let payment =
              x?.houseHoldIncomeSub?.length > 0
                ? [
                    ...x?.houseHoldIncomeSub,
                    { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                  ]
                : [{ incomeSource: "", incomeAmount: "", paymentCycle: "" }];
            return {
              ...x,
              houseHoldIncomeSub: payment,
            };
          }
          return {
            ...x,
          };
        })
      );
    }, 100);
  };

  const handleSchDist = (e) => {
    setSelectDist(e);
    getSchoolList(e);
  };

  const handleRemove2 = (ind, index) => {
    let deleted = getValues("houseHoldList")?.map((x, hi) => {
      if (hi === index) {
        return {
          ...x,
          houseHoldIncomeSub: x?.houseHoldIncomeSub?.filter(
            (_, i) => i !== ind
          ),
        };
      }
      return x;
    });
    setTimeout(() => {
      setValue(
        "houseHoldList",
        deleted?.map((x) => {
          return {
            ...x,
          };
        })
      );
      handleActualIncome();
    }, 100);
  };

  const handleActualIncome1 = () => {
    const houseHold = getValues("houseHoldList");
    const child = [
      {
        houseHoldIncomeSub: [
          {
            paymentCycle: getValues("childPaymentCycle"),
            incomeAmount: getValues("totalChildIncome"),
          },
        ],
      },
    ];
    let allIn = [...houseHold, ...child];
    const paymentCycles = allIn.map((std) =>
      std?.houseHoldIncomeSub?.map((x) => x?.paymentCycle)
    );

    const flattenedCycles = paymentCycles.flat().filter(Boolean);
    const firstCycle = flattenedCycles[0];
    const allCyclesMatch = flattenedCycles.every(
      (cycle) => cycle === firstCycle
    );

    if (allCyclesMatch) {
      const totalMulti = 1;

      const allIncome = allIn
        .map((s) => s?.houseHoldIncomeSub)
        .flat()
        .filter((x) => x?.incomeAmount)
        .map((x) => totalMulti * parseFloat(x?.incomeAmount || 0))
        .reduce((acc, current) => acc + current, 0);

      setValue("paymentCycle", firstCycle || "Annual");
      setValue("totalActualIncome", allIncome);
    } else {
      const allIncome1 = allIn
        .map((s) => s?.houseHoldIncomeSub)
        .flat()
        .filter((x) => x?.incomeAmount)
        .map((x) => {
          const totalMulti1 =
            x.paymentCycle === "Annual"
              ? 1
              : x.paymentCycle === "Monthly"
              ? 12
              : x.paymentCycle === "TwicePerMonth"
              ? 24
              : x.paymentCycle === "EveryTwoWeeks"
              ? 26
              : x.paymentCycle === "Weekly"
              ? 52
              : 1;

          return parseFloat(x.incomeAmount || 0) * totalMulti1;
        })
        .reduce((acc, current) => acc + current, 0);

      setValue("paymentCycle", "Annual");
      setValue("totalActualIncome", allIncome1);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {payDetail ? (
          <section className="payment_success">
            <div className="main_wrapper">
              <div className="payment_inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="success_message_box">
                      <BsFillPatchCheckFill className="icon" />
                      <div>
                        <h1 className="m-0">Form Submitted Successful</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row paymentAndUserInfo mt-4">
                  <div className="col-md-12 text-center">
                    <Button
                      className="theme_btn mt-3"
                      onClick={() => setPayDetail(false)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <>
            <section className="survey_create">
              <div className="card">
                <div className="card-body">
                  {!id && distList?.length !== 1 ? (
                    distList?.length > 0 ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="col-md-3 me-2">
                          <div className="form-group">
                            <label className="form-label">
                              Select District
                            </label>
                            <Select
                              isMulti={false}
                              name="colors"
                              options={distList}
                              isClearable={true}
                              className="dropdown-single-select"
                              classNamePrefix="select"
                              placeholder="Select District"
                              filterOption={customFilter}
                              value={distList?.filter(
                                (obj) => obj.value === selectDist
                              )}
                              onChange={(e) => handleSchDist(e?.value)}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {selectDist ? (
                    <>
                      <div className="hed">
                        <div className="d-flex">
                          <img
                            src={`${Config.API_HOST_URL_live}/${distLogo[0]}`}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NotFound;
                            }}
                            className="header_logo img-fluid"
                            alt="header_logo"
                          />
                          <h2 className="mx-auto">{t("SurveyTitle")}</h2>
                          <ChangeLanguage />
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="info">
                            <h5>
                              <b>{t("PartATitle")}</b>
                            </h5>
                            <p>{t("PartADesc")}</p>
                          </div>
                          <Button
                            className="ms-auto theme_btn"
                            onClick={() => setSearchMyChildOpen(true)}
                          >
                            {t("SearchmychildrenBtn")}
                          </Button>
                        </div>
                      </div>
                      <hr />
                      <div className="houseHoldForm">
                        <form
                          action=""
                          onSubmit={handleSubmit(onSubmit)}
                          className="primary-box"
                        >
                          <div className="partA">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>{t("TableHead3")}</th>
                                    <th>{t("TableHead4")}</th>
                                    <th>{t("TableHead5")}</th>
                                    <th>{t("TableHead2")}</th>
                                    {getValues(`StudentList`)?.filter(
                                      (x) => x.isStudent === true
                                    )?.length > 0 ? (
                                      <>
                                        <th>{t("TableHead6")}</th>
                                        <th>{t("TableHead10")} </th>
                                        <th>{t("TableHead7")} </th>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fields
                                    ?.filter((x) => x.isStudent === true)
                                    ?.map((item, index) => (
                                      <tr key={index}>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            style={{
                                              minWidth: "135px",
                                              // maxWidth: "15px",
                                            }}
                                            {...register(
                                              `StudentList.${index}.birthDate`,
                                              {
                                                required:
                                                  t("RequiredValidation"),
                                                valueAsDate: true,
                                                validate: {
                                                  isValidDate: (value) => {
                                                    if (value > new Date()) {
                                                      return t(
                                                        "DateValidation"
                                                      );
                                                    }
                                                  },
                                                },
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]
                                            ?.birthDate && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.StudentList?.[index]
                                                  ?.birthDate.message
                                              }
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t(
                                              "placeholderFirstName"
                                            )}
                                            {...register(
                                              `StudentList.${index}.firstName`,
                                              {
                                                required:
                                                  t("RequiredValidation"),
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]
                                            ?.firstName && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.StudentList?.[index]
                                                  ?.firstName.message
                                              }
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t(
                                              "placeholderLastName"
                                            )}
                                            {...register(
                                              `StudentList.${index}.lastName`,
                                              {
                                                required:
                                                  t("RequiredValidation"),
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]
                                            ?.lastName && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.StudentList?.[index]
                                                  ?.lastName.message
                                              }
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <select
                                            name=""
                                            className="form-select"
                                            {...register(
                                              `StudentList.${index}.fosterChild`
                                            )}
                                          >
                                            <option value="NA">
                                              {t("TypeDropVal5")}
                                            </option>
                                            <option value="FosterChild">
                                              {t("TypeDropVal1")}
                                            </option>
                                            <option value="MigrantWorker">
                                              {t("TypeDropVal2")}
                                            </option>
                                            <option value="Runway">
                                              {t("TypeDropVal3")}
                                            </option>
                                            <option value="HomeLess">
                                              {t("TypeDropVal4")}
                                            </option>
                                          </select>
                                          {errors.StudentList?.[index]
                                            ?.fosterChild && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.StudentList?.[index]
                                                  ?.fosterChild.message
                                              }
                                            </span>
                                          )}
                                        </td>
                                        {getValues(`StudentList`)?.filter(
                                          (x) => x.isStudent === true
                                        )?.length > 0 ? (
                                          getValues(
                                            `StudentList.${index}.isStudent`
                                          ) ? (
                                            <>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={t(
                                                    "placeholderStudentId"
                                                  )}
                                                  style={{
                                                    minWidth: "90px",
                                                    maxWidth: "90px",
                                                  }}
                                                  {...register(
                                                    `StudentList.${index}.studentId`
                                                  )}
                                                />
                                                {errors.StudentList?.[index]
                                                  ?.studentId && (
                                                  <span
                                                    role="alert"
                                                    className="error_text"
                                                  >
                                                    {
                                                      errors.StudentList?.[
                                                        index
                                                      ]?.studentId.message
                                                    }
                                                  </span>
                                                )}
                                              </td>
                                              <td>
                                                <select
                                                  name=""
                                                  id=""
                                                  className="form-select"
                                                  {...register(
                                                    `StudentList.${index}.schoolId`
                                                  )}
                                                >
                                                  <option value="">
                                                    {" "}
                                                    {t("placeholderSchool")}
                                                  </option>
                                                  {schoolList &&
                                                    schoolList?.map((ds, i) => (
                                                      <option
                                                        value={ds?.value}
                                                        key={i}
                                                      >
                                                        {ds?.label}
                                                      </option>
                                                    ))}
                                                </select>
                                              </td>
                                              <td>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder={t(
                                                    "placeholderGrade"
                                                  )}
                                                  style={{
                                                    minWidth: "50px",
                                                    maxWidth: "50px",
                                                  }}
                                                  {...register(
                                                    `StudentList.${index}.grade`,
                                                    {
                                                      // required: "This field is required",
                                                    }
                                                  )}
                                                />
                                                {errors.StudentList?.[index]
                                                  ?.grade && (
                                                  <span
                                                    role="alert"
                                                    className="error_text"
                                                  >
                                                    {
                                                      errors.StudentList?.[
                                                        index
                                                      ]?.grade.message
                                                    }
                                                  </span>
                                                )}
                                              </td>
                                            </>
                                          ) : (
                                            <>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </>
                                          )
                                        ) : (
                                          ""
                                        )}
                                        <td>
                                          {index === 0 ? (
                                            <Button
                                              className="theme_btn"
                                              onClick={() => {
                                                append({
                                                  birthDate: "",
                                                  firstName: "",
                                                  lastName: "",
                                                  isStudent: true,
                                                  grade: "",
                                                  schoolId: "",
                                                  fosterChild: "NA",
                                                  studentType: "",
                                                  country: "USA",
                                                  removable: true,
                                                });
                                              }}
                                            >
                                              <AiOutlinePlus className="icon" />
                                            </Button>
                                          ) : item?.removable ? (
                                            <Button
                                              className="cancel_btn"
                                              onClick={() => {
                                                handleCreateRemove(index);
                                              }}
                                            >
                                              <AiOutlineClose className="icon" />
                                            </Button>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="partB">
                            <h5>
                              <b>{t("PartBTitle")}</b>
                            </h5>
                            <ul>
                              <li>
                                {t("PartBDesc1")}

                                <RadioGroup
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="radio-buttons-group"
                                  className="flex-row"
                                  value={
                                    getValues("surveyBenifitField")
                                      ? getValues("surveyBenifitField")
                                      : "NA"
                                  }
                                >
                                  <FormControlLabel
                                    className="radioBtn"
                                    value="FDPIR"
                                    label={t("RadioName1")}
                                    {...register("surveyBenifitField", {
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.value
                                        ),
                                    })}
                                    control={<Radio />}
                                  />
                                  <FormControlLabel
                                    className="radioBtn"
                                    value="SNAP"
                                    label={t("RadioName2")}
                                    {...register("surveyBenifitField", {
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.value
                                        ),
                                    })}
                                    control={<Radio />}
                                  />
                                  <FormControlLabel
                                    className="radioBtn"
                                    value="TANF"
                                    label={t("RadioName3")}
                                    {...register("surveyBenifitField", {
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.value
                                        ),
                                    })}
                                    control={<Radio />}
                                  />
                                  <FormControlLabel
                                    className="radioBtn"
                                    value="NA"
                                    label={t("RadioName4")}
                                    control={<Radio />}
                                    {...register("surveyBenifitField", {
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.value,
                                          "surveyBenifitField"
                                        ),
                                    })}
                                  />
                                </RadioGroup>
                                {errors.surveyBenifitField && (
                                  <span role="alert" className="error_text">
                                    {errors.surveyBenifitField.message}
                                  </span>
                                )}
                              </li>
                              <li>
                                {t("PartBDesc2")} <b>{t("SkipPart")}</b>
                              </li>
                            </ul>
                            {getValues("surveyBenifitField") === "TANF" ||
                            getValues("surveyBenifitField") === "FDPIR" ||
                            getValues("surveyBenifitField") === "SNAP" ? (
                              <div className="row form mb-3">
                                <div className="col-md-4 input_div">
                                  <label>{t("RadioSelectInput1")}</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("RadioSelectPlaceholder1")}
                                    {...register(`caseName`, {
                                      required: t("RequiredValidation"),
                                    })}
                                  />
                                  {errors.caseName && (
                                    <span role="alert" className="error_text">
                                      {errors.caseName.message}
                                    </span>
                                  )}
                                </div>{" "}
                                <div className="col-md-4 input_div">
                                  <label>{t("RadioSelectInput2")}</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("RadioSelectPlaceholder2")}
                                    {...register(`caseNumber`, {
                                      required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleCaseNum(e?.target?.value),
                                      minLength: {
                                        value: 10,
                                        message: t("MinChar"),
                                      },
                                      maxLength: {
                                        value: 10,
                                        message: t("MaxChar"),
                                      },
                                    })}
                                  />
                                  {errors.caseNumber && (
                                    <span role="alert" className="error_text">
                                      {errors.caseNumber.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="partC">
                            {getValues("surveyBenifitField") === "TANF" ||
                            getValues("surveyBenifitField") === "FDPIR" ||
                            getValues("surveyBenifitField") === "SNAP" ? (
                              ""
                            ) : (
                              <>
                                <h5>
                                  <b>{t("PartCTitle")}</b>
                                </h5>
                                <h6>{t("PartCDesc1")}</h6>
                                <p>{t("PartCDesc2")}</p>
                                <div className="table-responsive">
                                  <table className="table table-bordered">
                                    <thead>
                                      <tr
                                        style={{ backgroundColor: "#f3f3f3" }}
                                      >
                                        <th>{t("TableHead3")}</th>
                                        <th>{t("TableHead4")}</th>
                                        <th>{t("TableHead5")}</th>
                                        <th></th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Field1?.filter(
                                        (x) => x?.isStudent === false
                                      )?.map((item, index) => (
                                        <React.Fragment key={index}>
                                          <tr key={index}>
                                            <td>
                                              <input
                                                type="date"
                                                className="form-control"
                                                style={{
                                                  minWidth: "135px",
                                                  // maxWidth: "135px",
                                                }}
                                                {...register(
                                                  `houseHoldList.${index}.birthDate`,
                                                  {
                                                    required:
                                                      t("RequiredValidation"),
                                                    valueAsDate: true,
                                                    validate: {
                                                      isValidDate: (value) => {
                                                        if (
                                                          value > new Date()
                                                        ) {
                                                          return t(
                                                            "DateValidation"
                                                          );
                                                        }
                                                      },
                                                    },
                                                  }
                                                )}
                                              />
                                              {errors.houseHoldList?.[index]
                                                ?.birthDate && (
                                                <span
                                                  role="alert"
                                                  className="error_text"
                                                >
                                                  {
                                                    errors.houseHoldList?.[
                                                      index
                                                    ]?.birthDate.message
                                                  }
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t(
                                                  "placeholderFirstName"
                                                )}
                                                {...register(
                                                  `houseHoldList.${index}.firstName`,
                                                  {
                                                    required:
                                                      t("RequiredValidation"),
                                                  }
                                                )}
                                              />
                                              {errors.houseHoldList?.[index]
                                                ?.firstName && (
                                                <span
                                                  role="alert"
                                                  className="error_text"
                                                >
                                                  {
                                                    errors.houseHoldList?.[
                                                      index
                                                    ]?.firstName.message
                                                  }
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t(
                                                  "placeholderLastName"
                                                )}
                                                {...register(
                                                  `houseHoldList.${index}.lastName`,
                                                  {
                                                    required:
                                                      t("RequiredValidation"),
                                                  }
                                                )}
                                              />
                                              {errors.houseHoldList?.[index]
                                                ?.lastName && (
                                                <span
                                                  role="alert"
                                                  className="error_text"
                                                >
                                                  {
                                                    errors.houseHoldList?.[
                                                      index
                                                    ]?.lastName.message
                                                  }
                                                </span>
                                              )}
                                            </td>
                                            <td>
                                              <Button
                                                className="theme_btn"
                                                onClick={() =>
                                                  handleAddAnother(index)
                                                }
                                              >
                                                {t("AddIncomeBtn")}
                                              </Button>
                                            </td>
                                            <td>
                                              {index === 0 ? (
                                                <Button
                                                  className="theme_btn"
                                                  // onClick={addRow}
                                                  onClick={() => {
                                                    append1({
                                                      birthDate: "",
                                                      firstName: "",
                                                      lastName: "",
                                                      isStudent: false,
                                                      grade: "",
                                                      fosterChild: "",
                                                      actualIncome: "",
                                                      paymentCycle: "",
                                                      studentType: "",
                                                      country: "USA",
                                                      removable: true,
                                                      houseHoldIncomeSub: [
                                                        {
                                                          incomeSource: "",
                                                          incomeAmount: "",
                                                          paymentCycle: "",
                                                        },
                                                      ],
                                                    });
                                                  }}
                                                >
                                                  <AiOutlinePlus className="icon" />
                                                </Button>
                                              ) : item?.removable ? (
                                                <Button
                                                  className="cancel_btn"
                                                  onClick={() => {
                                                    handleRemove1(index);
                                                  }}
                                                >
                                                  <AiOutlineClose className="icon" />
                                                </Button>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                          </tr>
                                          <>
                                            {item?.houseHoldIncomeSub?.length >
                                              0 && (
                                              <React.Fragment>
                                                <tr
                                                  style={{
                                                    backgroundColor: "#d4eed6",
                                                  }}
                                                >
                                                  <td></td>
                                                  <td>{t("TableHead11")}</td>
                                                  <td>{t("TableHead8")}</td>
                                                  <td>{t("TableHead9")}</td>
                                                  <td></td>
                                                </tr>
                                                {item?.houseHoldIncomeSub?.map(
                                                  (_, index1) => (
                                                    <tr key={index1}>
                                                      <td></td>
                                                      <td>
                                                        <select
                                                          name=""
                                                          className="form-select"
                                                          {...register(
                                                            `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeSource`
                                                            // {
                                                            //   required:
                                                            //     t("RequiredValidation"),
                                                            // }
                                                          )}
                                                        >
                                                          <option value="">
                                                            {t("SeIn")}
                                                          </option>
                                                          <option value="Work">
                                                            {t("Work")}
                                                          </option>
                                                          <option value="Public Assistance">
                                                            {t(
                                                              "PublicAssistance"
                                                            )}
                                                          </option>
                                                          <option value="Child Support">
                                                            {t("ChildSupport")}
                                                          </option>
                                                          <option value="Alimony">
                                                            {t("Alimony")}
                                                          </option>
                                                          <option value="Pension">
                                                            {t("Pension")}
                                                          </option>
                                                          <option value="Retirment">
                                                            {t("Retirment")}
                                                          </option>
                                                          <option value="Social Security">
                                                            {t(
                                                              "SocialSecurity"
                                                            )}
                                                          </option>
                                                          <option value="SSI">
                                                            {t("SSI")}
                                                          </option>
                                                          <option value="VA Benefits">
                                                            {t("VABenefits")}
                                                          </option>{" "}
                                                          <option value="Other">
                                                            {t("Other")}
                                                          </option>
                                                        </select>

                                                        {errors.houseHoldList?.[
                                                          index
                                                        ]?.houseHoldIncomeSub?.[
                                                          index1
                                                        ]?.incomeSource && (
                                                          <span
                                                            role="alert"
                                                            className="error_text"
                                                          >
                                                            {
                                                              errors
                                                                .houseHoldList?.[
                                                                index
                                                              ]
                                                                ?.houseHoldIncomeSub?.[
                                                                index1
                                                              ]?.incomeSource
                                                                .message
                                                            }
                                                          </span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          placeholder={t(
                                                            "placeholderActualIncome"
                                                          )}
                                                          {...register(
                                                            `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeAmount`,
                                                            {
                                                              valueAsNumber: true,
                                                              required:
                                                                t(
                                                                  "RequiredValidation"
                                                                ),
                                                              onChange: () =>
                                                                handleActualIncome1(),
                                                              validate: {
                                                                isValidNumber: (
                                                                  value
                                                                ) => {
                                                                  let v =
                                                                    /^\d+(\.\d{1,2})?$/;
                                                                  if (
                                                                    !v.test(
                                                                      value
                                                                    )
                                                                  ) {
                                                                    return t(
                                                                      "EnterActualIncome"
                                                                    );
                                                                  }
                                                                },
                                                              },
                                                            }
                                                          )}
                                                        />
                                                        {errors.houseHoldList?.[
                                                          index
                                                        ]?.houseHoldIncomeSub?.[
                                                          index1
                                                        ]?.incomeAmount && (
                                                          <span
                                                            role="alert"
                                                            className="error_text"
                                                          >
                                                            {
                                                              errors
                                                                .houseHoldList?.[
                                                                index
                                                              ]
                                                                ?.houseHoldIncomeSub?.[
                                                                index1
                                                              ]?.incomeAmount
                                                                .message
                                                            }
                                                          </span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <select
                                                          name=""
                                                          className="form-select"
                                                          {...register(
                                                            `houseHoldList.${index}.houseHoldIncomeSub.${index1}.paymentCycle`,
                                                            {
                                                              // required: t("RequiredValidation"),
                                                              onChange: () =>
                                                                handleActualIncome1(),
                                                              validate: (
                                                                val
                                                              ) => {
                                                                if (
                                                                  watch(
                                                                    `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeAmount`
                                                                  ) > 0 &&
                                                                  val === ""
                                                                ) {
                                                                  return t(
                                                                    "RequiredValidation"
                                                                  );
                                                                }
                                                              },
                                                            }
                                                          )}
                                                        >
                                                          <option value="">
                                                            {t("PayCycleDrop")}
                                                          </option>
                                                          <option value="Annual">
                                                            {t(
                                                              "PayCycleDropVal1"
                                                            )}
                                                          </option>
                                                          <option value="Monthly">
                                                            {t(
                                                              "PayCycleDropVal2"
                                                            )}
                                                          </option>
                                                          <option value="TwicePerMonth">
                                                            {t(
                                                              "PayCycleDropVal3"
                                                            )}
                                                          </option>
                                                          <option value="EveryTwoWeeks">
                                                            {t(
                                                              "PayCycleDropVal4"
                                                            )}
                                                          </option>
                                                          <option value="Weekly">
                                                            {t(
                                                              "PayCycleDropVal5"
                                                            )}
                                                          </option>
                                                        </select>
                                                        {errors.houseHoldList?.[
                                                          index
                                                        ]?.houseHoldIncomeSub?.[
                                                          index1
                                                        ]?.paymentCycle && (
                                                          <span
                                                            role="alert"
                                                            className="error_text"
                                                          >
                                                            {
                                                              errors
                                                                .houseHoldList?.[
                                                                index
                                                              ]
                                                                ?.houseHoldIncomeSub?.[
                                                                index1
                                                              ]?.paymentCycle
                                                                .message
                                                            }
                                                          </span>
                                                        )}
                                                      </td>
                                                      <td>
                                                        {index1 === 0 ? (
                                                          ""
                                                        ) : (
                                                          <Button
                                                            className="cancel_btn"
                                                            onClick={() => {
                                                              handleRemove2(
                                                                index1,
                                                                index
                                                              );
                                                            }}
                                                          >
                                                            <AiOutlineClose className="icon" />
                                                          </Button>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </React.Fragment>
                                            )}
                                          </>
                                        </React.Fragment>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                                <h6>{t("Part3b")}</h6>
                                <div className="row my-3">
                                  <div className="col-md-4 input_div">
                                    <label>{t("TotalChildAnnual")}</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={""}
                                      placeholder={t(
                                        "TotalChildAnnualPlaceholder"
                                      )}
                                      {...register(`totalChildIncome`, {
                                        // required: t("RequiredValidation"),
                                        valueAsNumber: true,
                                        onChange: () => handleActualIncome1(),
                                        // validate: {
                                        //   isValidNumber: (value) => {
                                        //     let v = /^\d+(\.\d{1,2})?$/;
                                        //     if (!v.test(value)) {
                                        //       return t("EnterActualIncome");
                                        //     }
                                        //   },
                                        // },
                                      })}
                                    />
                                    {errors.totalChildIncome && (
                                      <span role="alert" className="error_text">
                                        {errors.totalChildIncome.message}
                                      </span>
                                    )}
                                  </div>{" "}
                                  <div className="col-md-4 input_div">
                                    <label>{t("ChildPaymentCycleLabel")}</label>
                                    <select
                                      name=""
                                      className="form-select"
                                      {...register("childPaymentCycle", {
                                        // required: t("RequiredValidation"),
                                        onChange: () => handleActualIncome1(),
                                        validate: (val) => {
                                          if (
                                            watch(`totalChildIncome`) > 0 &&
                                            val === ""
                                          ) {
                                            return t("RequiredValidation");
                                          }
                                        },
                                      })}
                                    >
                                      <option value="">
                                        {t("ChildPaymentCycleDrop1")}
                                      </option>
                                      <option value="Annual">
                                        {t("PaymentCycleDrop2")}
                                      </option>
                                      <option value="Monthly">
                                        {t("PaymentCycleDrop3")}
                                      </option>
                                      <option value="TwicePerMonth">
                                        {t("PaymentCycleDrop4")}
                                      </option>
                                      <option value="EveryTwoWeeks">
                                        {t("PaymentCycleDrop5")}
                                      </option>
                                      <option value="Weekly">
                                        {t("PaymentCycleDrop6")}
                                      </option>
                                    </select>
                                    {errors.childPaymentCycle && (
                                      <span role="alert" className="error_text">
                                        {errors.childPaymentCycle.message}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            <h4 className="text-center text-bg-light py-2">
                              {t("FormHeading")}
                            </h4>

                            <div className="row form mb-3">
                              <div className="col-md-4 input_div">
                                <label>{t("HhSize")}</label>
                                <input
                                  type="text"
                                  disabled={
                                    getValues("surveyBenifitField") ===
                                      "TANF" ||
                                    getValues("surveyBenifitField") ===
                                      "FDPIR" ||
                                    getValues("surveyBenifitField") === "SNAP"
                                      ? false
                                      : true
                                  }
                                  className="form-control"
                                  placeholder={t("HhSizePlaceholder")}
                                  {...register(`NoOfPeoeple`, {
                                    required: t("RequiredValidation"),
                                  })}
                                />
                                {errors.NoOfPeoeple && (
                                  <span role="alert" className="error_text">
                                    {errors.NoOfPeoeple.message}
                                  </span>
                                )}
                              </div>{" "}
                              <div className="col-md-4 input_div">
                                <label>{t("TotalAnnual")}</label>
                                <input
                                  type="text"
                                  disabled={true}
                                  className="form-control"
                                  placeholder={t("TotalAnnualPlaceholder")}
                                  {...register(`totalActualIncome`, {
                                    // required: t("RequiredValidation"),
                                  })}
                                />
                                {errors.totalActualIncome && (
                                  <span role="alert" className="error_text">
                                    {errors.totalActualIncome.message}
                                  </span>
                                )}
                              </div>{" "}
                              <div className="col-md-4 input_div">
                                <label>{t("PaymentCycleLabel")}</label>
                                <select
                                  name=""
                                  disabled={true}
                                  className="form-select"
                                  {...register("paymentCycle", {})}
                                >
                                  <option value="">
                                    {t("PaymentCycleDrop1")}
                                  </option>
                                  <option value="Annual">
                                    {t("PaymentCycleDrop2")}
                                  </option>
                                  <option value="Monthly">
                                    {t("PaymentCycleDrop3")}
                                  </option>
                                  <option value="TwicePerMonth">
                                    {t("PaymentCycleDrop4")}
                                  </option>
                                  <option value="EveryTwoWeeks">
                                    {t("PaymentCycleDrop5")}
                                  </option>
                                  <option value="Weekly">
                                    {t("PaymentCycleDrop6")}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="partD">
                            <h5>
                              <b>{t("PartDTitle")}</b>
                            </h5>
                            <p>{t("PartDDesc")}</p>
                            <div className="row form">
                              <div className="col-md-4 input_div">
                                <label>{t("ParentFirstName")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("ParentFirstNamePlaceholder")}
                                  {...register(`firstName`, {
                                    required: t("RequiredValidation"),
                                  })}
                                />
                                {errors.firstName && (
                                  <span role="alert" className="error_text">
                                    {errors.firstName.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 input_div">
                                <label>{t("TableHead5")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("placeholderLastName")}
                                  {...register(`fullName`, {
                                    required: t("RequiredValidation"),
                                  })}
                                />
                                {errors.fullName && (
                                  <span role="alert" className="error_text">
                                    {errors.fullName.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 mt-4">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    {...register(`isSSN`, {
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("NoSSN")}
                                </label>
                                {errors.isSSN && (
                                  <span role="alert" className="error_text">
                                    {errors.isSSN.message}
                                  </span>
                                )}{" "}
                              </div>
                              {watch("isSSN") === true ? (
                                ""
                              ) : (
                                <div className="col-md-4 input_div">
                                  <label>{t("SSNNumber")}</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder={t("placeholderSSNNumber")}
                                    {...register(`ssnNumber`, {
                                      required: t("RequiredValidation"),
                                      maxLength: {
                                        value: 4,
                                        message: t("SSNNumberValidation"),
                                      },
                                      minLength: {
                                        value: 4,
                                        message: t("SSNNumberValidation"),
                                      },
                                    })}
                                  />
                                  {errors.ssnNumber && (
                                    <span role="alert" className="error_text">
                                      {errors.ssnNumber.message}
                                    </span>
                                  )}
                                </div>
                              )}
                              <div className="col-md-4 input_div">
                                <label>{t("ParentAddress")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("ParentAddressPlaceholder")}
                                  {...register(`address`, {
                                    required: t("RequiredValidation"),
                                  })}
                                />
                                {errors.address && (
                                  <span role="alert" className="error_text">
                                    {errors.address.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 input_div">
                                <label>{t("ParentAPTNumber")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("ParentAPTPlaceholder")}
                                  {...register(`apartment`, {
                                    // required: t("RequiredValidation"),
                                  })}
                                />
                                {errors.apartment && (
                                  <span role="alert" className="error_text">
                                    {errors.apartment.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 input_div">
                                <label>{t("ParentHomeMobile")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("ParentHomeMobilePlaceholder")}
                                  {...register(`phone`, {
                                    required: t("RequiredValidation"),
                                    pattern: {
                                      value:
                                        /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                      message: t("ValidPhone"),
                                    },
                                  })}
                                />
                                {errors.phone && (
                                  <span role="alert" className="error_text">
                                    {errors.phone.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 input_div">
                                <label>{t("ParentCity")}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={t("ParentCityPlaceholder")}
                                  {...register(`city`, {
                                    required: t("RequiredValidation"),
                                  })}
                                />
                                {errors.city && (
                                  <span role="alert" className="error_text">
                                    {errors.city.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 input_div">
                                <label>{t("ParentState")}</label>
                                <select
                                  name=""
                                  className="form-select"
                                  {...register("state", {
                                    required: t("RequiredValidation"),
                                    valueAsNumber: true,
                                  })}
                                >
                                  <option value="">
                                    {t("ParentStatePlaceholder")}
                                  </option>
                                  {stateList &&
                                    stateList?.map((data, i) => (
                                      <option key={i} value={data?.stateId}>
                                        {data?.stateName}
                                      </option>
                                    ))}
                                </select>
                                {errors.state && (
                                  <span role="alert" className="error_text">
                                    {errors.state.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 input_div">
                                <label> {t("ParentEmail")}</label>
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder={t("ParentEmailPlaceholder")}
                                  {...register(`emailConfirmationAddress`, {
                                    // required: "This field is required",
                                    pattern: {
                                      value: /\S+@\S+\.\S+/,
                                      message: t("EmailFormat"),
                                    },
                                  })}
                                />
                                {errors.emailConfirmationAddress && (
                                  <span role="alert" className="error_text">
                                    {errors.emailConfirmationAddress.message}
                                  </span>
                                )}
                              </div>
                              <div className="col-md-4 input_div mt-2">
                                <label></label>
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    {...register(`isSummerEBT`, {
                                      // required: t("RequiredValidation"),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("CheckBoxForCorrectInfo1")}
                                </label>
                                {errors.isSummerEBT && (
                                  <span role="alert" className="error_text">
                                    {errors.isSummerEBT.message}
                                  </span>
                                )}{" "}
                              </div>
                              <div className="my-4">
                                <h5>{t("OptionalTitle")}</h5>
                                <p>{t("OptionalDesc")}</p>
                                <div>
                                  <div className="row mb-2">
                                    <div className="col-md-2">
                                      <label>{t("EthnicityLabel")}</label>
                                    </div>
                                    <div className="col-md-6">
                                      <label className="personal_detail_label">
                                        <input
                                          type="radio"
                                          className="login_check"
                                          name="isHispanic"
                                          id="hispanic"
                                          value={true}
                                          {...register(`isHispanic`, {
                                            // required: t("RequiredValidation"),
                                            onChange: (e) =>
                                              handleChoise(
                                                e.target.checked,
                                                e.target.checked
                                              ),
                                          })}
                                        />
                                        <span className="checkmark"></span>
                                        {t("Hispa1")}
                                      </label>
                                      {errors.isHispanic && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {errors.isHispanic.message}
                                        </span>
                                      )}
                                    </div>

                                    <div className="col-md-4">
                                      <label className="personal_detail_label">
                                        <input
                                          type="radio"
                                          className="login_check"
                                          name="isHispanic"
                                          id="notHispanic"
                                          value={false}
                                          {...register(`isHispanic`, {
                                            // required: t("RequiredValidation"),
                                            onChange: (e) =>
                                              handleChoise(
                                                e.target.checked,
                                                e.target.checked
                                              ),
                                          })}
                                        />
                                        <span className="checkmark"></span>
                                        {t("NotHispa")}
                                      </label>
                                      {errors.isHispanic && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {errors.isHispanic.message}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2">
                                      <label>{t("RaceLabel")}</label>
                                    </div>
                                    <div className="col-md-2">
                                      <label className=" personal_detail_label">
                                        <input
                                          type="checkbox"
                                          className="login_check"
                                          name="Remember Me"
                                          value={"isAmericanIndianAlaskaNative"}
                                          {...register(`isRace`, {
                                            // required: t("RequiredValidation"),
                                            onChange: (e) =>
                                              handleChoise(
                                                e.target.checked,
                                                e.target.checked
                                              ),
                                          })}
                                        />
                                        <span className="checkmark"></span>
                                        {t("fisrtRace")}
                                      </label>
                                      {errors.isRace && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {errors.isRace.message}
                                        </span>
                                      )}{" "}
                                    </div>
                                    <div className="col-md-2">
                                      <label className=" personal_detail_label">
                                        <input
                                          type="checkbox"
                                          className="login_check"
                                          value={"isAsian"}
                                          name="Remember Me"
                                          {...register(`isRace`, {
                                            // required: t("RequiredValidation"),
                                            onChange: (e) =>
                                              handleChoise(
                                                e.target.checked,
                                                e.target.checked
                                              ),
                                          })}
                                        />
                                        <span className="checkmark"></span>
                                        {t("SecondRace")}
                                      </label>
                                      {errors.isRace && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {errors.isRace.message}
                                        </span>
                                      )}{" "}
                                    </div>
                                    <div className="col-md-2">
                                      <label className=" personal_detail_label">
                                        <input
                                          type="checkbox"
                                          className="login_check"
                                          name="Remember Me"
                                          value={"isBlackAfricanAmerican"}
                                          {...register(`isRace`, {
                                            // required: t("RequiredValidation"),
                                            onChange: (e) =>
                                              handleChoise(
                                                e.target.checked,
                                                e.target.checked
                                              ),
                                          })}
                                        />
                                        <span className="checkmark"></span>
                                        {t("thirdRace")}
                                      </label>
                                      {errors.isRace && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {errors.isRace.message}
                                        </span>
                                      )}{" "}
                                    </div>
                                    <div className="col-md-2">
                                      <label className=" personal_detail_label">
                                        <input
                                          type="checkbox"
                                          className="login_check"
                                          name="Remember Me"
                                          value={
                                            "isNativeHawaiianOtherPacificIslande"
                                          }
                                          {...register(`isRace`, {
                                            // required: t("RequiredValidation"),
                                            onChange: (e) =>
                                              handleChoise(
                                                e.target.checked,
                                                e.target.checked
                                              ),
                                          })}
                                        />
                                        <span className="checkmark"></span>
                                        {t("fourRace")}
                                      </label>
                                      {errors.isRace && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {errors.isRace.message}
                                        </span>
                                      )}{" "}
                                    </div>
                                    <div className="col-md-2">
                                      <label className=" personal_detail_label">
                                        <input
                                          type="checkbox"
                                          className="login_check"
                                          name="Remember Me"
                                          value={"isWhite"}
                                          {...register(`isRace`, {
                                            // required: t("RequiredValidation"),
                                            onChange: (e) =>
                                              handleChoise(
                                                e.target.checked,
                                                e.target.checked
                                              ),
                                          })}
                                        />
                                        <span className="checkmark"></span>
                                        {t("whiteName")}
                                      </label>
                                      {errors.isRace && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {errors.isRace.message}
                                        </span>
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-9">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    {...register(`isOtherStateEbtbenifit`, {
                                      required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("CheckBoxForCorrectInfo")}
                                </label>
                                {errors.isOtherStateEbtbenifit && (
                                  <span role="alert" className="error_text">
                                    {errors.isOtherStateEbtbenifit.message}
                                  </span>
                                )}{" "}
                              </div>
                              <div className="col-md-9">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    {...register(`sign`, {
                                      required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("FirstCheckBox")}
                                </label>
                                {errors.sign && (
                                  <span role="alert" className="error_text">
                                    {errors.sign.message}
                                  </span>
                                )}{" "}
                              </div>
                              {watch("sign") ? (
                                <div className="col-md-4 input-div mt-2">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      "CheckBoxForCorrectInfoAfterShowBox"
                                    )}
                                    {...register(`signatureName`, {
                                      required: t("RequiredValidation"),
                                    })}
                                  />
                                  {errors.signatureName && (
                                    <span role="alert" className="error_text">
                                      {errors.signatureName.message}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="mt-3 text-end">
                            {openCircle ? (
                              <Button disabled className="theme_btn me-3">
                                <CircularProgress
                                  sx={{
                                    color: "#fff",
                                    marginLeft: "0.5rem",
                                    height: "23px !important",
                                    width: "23px !important",
                                  }}
                                />
                              </Button>
                            ) : (
                              <Button type="submit" className="theme_btn me-3">
                                {t("SubmitBtn")}
                              </Button>
                            )}
                            <Button
                              className="cancel_btn"
                              onClick={() => (!id ? nav(-1) : window.close())}
                            >
                              {t("CancelBtn")}
                            </Button>
                          </div>
                        </form>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </section>
          </>
        )}
        {open && (
          <StudentPopup
            open={open}
            setOpen={setOpen}
            openCircle={openCircle}
            setOpenCircle={setOpenCircle}
            studentGuiId={studentGuiId}
            setStudentGuiId={setStudentGuiId}
          />
        )}
        {searchMyChildOpen && (
          <SearchMyChildren
            open={searchMyChildOpen}
            setOpen={setSearchMyChildOpen}
            fields={fields}
            handleAdd={handleAdd}
          />
        )}
      </Suspense>
    </>
  );
}

export default CreateSurveyParent;
