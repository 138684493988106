import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

function CreateMealPoint() {
    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [parentList, setParentList] = useState([]);
    const [creditType, setCreditType] = useState();

    const navigate = useNavigate();

    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (id) {
            getEditedData(id);
        } else {
            getDistrictList();
        }
        // eslint-disable-next-line
    }, [id]);

    const getDistrictList = async (schId) => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setParentList(dlist?.length > 0 ? dlist : []);
                if (dlist && schId) {
                    setTimeout(() => {
                        setValue("schoolDistrictId", schId);
                    }, 300);
                }
            }
        }
    };

    const getEditedData = async (id) => {
        setOpen(true);

        let sendData = {
            url: `/api/MealPoint/GetMealPointDataByID?mealPointId=${id}`,
        };

        let res = await GetCallApi(sendData);
        setOpen(false);
        if (res?.status === 200) {
            if (res.data.success) {
                let editsiteData = res?.data?.payload[0];
                setValue("mealType", editsiteData?.mealType);
                setValue("points", editsiteData?.points);
                setValue("mealPointId", parseInt(id));
                setValue("freeMealAlaCarte", editsiteData?.freeMealAlaCarte);
                getDistrictList(editsiteData?.schoolDistrictId);
                setCreditType(editsiteData?.type);
            }
        }
    };

    const onSubmit = async (data) => {
        setOpen(true);

        let passData = {
            ...data,
            type: "Point Accumulation",
            createdBy: LogDetail?.userId,
        };

        let seData = {
            url: `/api/MealPoint/CreateMealPoint`,
            body: passData,
        };
        let respo = await PostCallApi(seData);
        setOpen(false);
        if (respo?.status === 200) {
            if (respo.data.success) {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
                navigate(-1);
            } else {
                setOpen(false);
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo?.data?.message
                        ? respo?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            setOpen(false);
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: respo?.data?.message
                    ? respo?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="create_meal_point">
                    <div className="main_wrapper">
                        <div className="ProductCategories_hed">
                            <div className="all_title ProductTitle">
                                {id ? "Edit Meal Point" : "Create Meal Point"}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action=""
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="primary-box"
                                >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    District
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("schoolDistrictId", {
                                                        required: "This field is required",
                                                        // onChange: (e) => getSchoolList(e.target.value),
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {parentList &&
                                                        parentList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.schoolDistrictId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.schoolDistrictId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Meal Service <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("mealType", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select Meal Service </option>
                                                    <option value="Breakfast">Breakfast</option>
                                                    <option value="Dinner">Dinner</option>
                                                    <option value="Lunch">Lunch</option>
                                                    <option value="Snacks">Snacks</option>
                                                </select>
                                                {errors.mealType && (
                                                    <span className="error_text">
                                                        {errors.mealType.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Credit Type <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("type", {
                                                        required: "This field is required",
                                                        onChange: (e) => setCreditType(e?.target?.value),
                                                    })}
                                                >
                                                    <option value="">Select Credit Type </option>
                                                    <option value="Point Accumulation">
                                                        Point Accumulation
                                                    </option>
                                                </select>
                                                {errors.type && (
                                                    <span className="error_text">
                                                        {errors.type.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Points <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Points"
                                                    {...register("points", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 150,
                                                            message: "Max 150 characters",
                                                        },
                                                    })}
                                                />
                                                {errors.points && (
                                                    <span role="alert" className="error_text">
                                                        {errors.points.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        {creditType !== "Point Redemption" && (
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>
                                                        Meal Type <span className="red-color">*</span>
                                                    </label>
                                                    <select
                                                        name=""
                                                        className="form-select"
                                                        {...register("freeMealAlaCarte", {
                                                            required: "This field is required",
                                                        })}
                                                    >
                                                        <option value="">Select Meal Type </option>
                                                        <option value="Ala Carte">Ala Carte</option>
                                                        <option value="Reimbursable">Reimbursable</option>
                                                    </select>
                                                    {errors.freeMealAlaCarte && (
                                                        <span className="error_text">
                                                            {errors.freeMealAlaCarte.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        {/* <div className="col-md-3 Is_Free_MealOrder">
                                            <div className="form-group">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <label className="personal_detail_label">
                                                            <input
                                                                type="checkbox"
                                                                className="login_check"
                                                                name="Remember Me"
                                                                {...register("IsFreeMealOrder", {
                                                                    // required: "This field is required",
                                                                })}
                                                            />
                                                            <span className="checkmark"> </span>
                                                            Is Free Meal Order{" "}
                                                        </label>
                                                    </div>
                                                    {errors.IsFreeMealOrder && (
                                                        <span role="alert" className="error_text">
                                                            {errors.IsFreeMealOrder.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-12 text-end">
                                            <Button type="submit" className="theme_btn me-2">
                                                Submit
                                            </Button>
                                            <Button
                                                className="cancel_btn"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default CreateMealPoint;
