import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateDistributor() {
  const [openCircle, setOpenCircle] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      EditDistributor(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    setOpenCircle(true);

    Axios.post("/api/Distributor/CreateDistributor", sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const EditDistributor = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/Distributor/GetDistributorDataById?distributerId=${id}?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let useDe = res.data.payload;
            setValue("distributorId", useDe?.distributorId);
            setValue("distributorName", useDe?.distributorName);
            setValue("distributorCode", useDe?.distributorCode);

            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createprimarytab-page">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Distributor</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Distributor Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Distributor Name"
                          {...register("distributorName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.distributorName && (
                          <span role="alert" className="error_text">
                            {errors.distributorName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Distributor Code <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Distributor Code"
                          {...register("distributorCode", {
                            required: "This field is required",
                          })}
                        />
                        {errors.distributorCode && (
                          <span role="alert" className="error_text">
                            {errors.distributorCode.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateDistributor;
