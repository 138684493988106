import React, { useEffect, useState } from "react";
import userIcon from "../../../../Assets/PngImage/userIcon.png";
import submitted from "../../../../Assets/PngImage/submitted.png";
import complateIcon from "../../../../Assets/PngImage/complateIcon.png";
import survey from "../../../../Assets/PngImage/survey.png";
import AdminCountBox from "./AdminCountBox";
import Charts from "../../../../Components/Reusable/Chart/Charts";
import "chart.js/auto";
import Highcharts from "highcharts";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import Chart from "react-apexcharts";
import LinearProgress from "@mui/material/LinearProgress";
import { PostCallApi } from "../../../../Action/Action";

HighchartsMore(Highcharts);

SolidGauge(Highcharts);

// let highchartOptions1 = {
//   chart: {
//     type: "solidgauge",
//   },
//   title: {
//     text: "Solid Gauge Chart",
//   },
//   pane: {
//     center: ["50%", "85%"],
//     size: "140%",
//     startAngle: -90,
//     endAngle: 90,
//     background: {
//       backgroundColor: "#EEE",
//       innerRadius: "60%",
//       outerRadius: "100%",
//       shape: "arc",
//     },
//   },
//   tooltip: {
//     enabled: false,
//   },
//   yAxis: {
//     min: 0,
//     max: 100,
//     title: {
//       text: "Speed",
//     },
//     stops: [
//       [0.1, "#55BF3B"], // green
//       [0.5, "#DDDF0D"], // yellow
//       [0.9, "#DF5353"], // red
//     ],
//     lineWidth: 0,
//     minorTickInterval: null,
//     tickAmount: 2,
//     labels: {
//       y: 16,
//     },
//   },
//   plotOptions: {
//     solidgauge: {
//       dataLabels: {
//         y: 5,
//         borderWidth: 0,
//         useHTML: true,
//       },
//     },
//   },
//   series: [
//     {
//       name: "Speed",
//       data: [80],
//       dataLabels: {
//         format:
//           '<div style="text-align:center"><span style="font-size:25px">{y}</span><br/><span style="font-size:12px;opacity:0.4">km/h</span></div>',
//       },
//       tooltip: {
//         valueSuffix: " km/h",
//       },
//     },
//   ],
// };

const options = {
  colors: ["#49C7DB", "#F79153", "#88C75B"],
  labels: ["Free", "Reduced", "Paid"],
  legend: {
    show: true,
    floating: true,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,

    fontSize: "14px",
    fontFamily: "Helvetica, Arial",
    height: undefined,
    formatter: function (seriesName, opts) {
      return [seriesName + " " + opts.w.globals.series[opts.seriesIndex] + "%"];
    },
    position: "bottom",
    offsetX: 0,
    offsetY: 0,
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 5,
      height: 5,
      strokeWidth: "5px",
      strokeColor: "#fff",
      radius: 0,
      customHTML: undefined,
      onClick: undefined,
      offsetX: 0,
      offsetY: 0,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  plotOptions: {
    radialBar: {
      size: undefined,
      inverseOrder: false,
      startAngle: 0,
      endAngle: 360,
      offsetX: 0,
      offsetY: 0,
      hollow: {
        margin: 9,
        size: "35%",
        background: "transparent",
        image: undefined,
        imageWidth: 500,
        imageHeight: 500,
        imageOffsetX: 0,
        imageOffsetY: 0,
        imageClipped: true,
        position: "front",
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
        },
      },
      track: {
        show: true,
        startAngle: undefined,
        endAngle: undefined,
        background: "#f2f2f2",
        strokeWidth: "100%",
        opacity: 1,
        margin: 25,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 0,
        },
      },
      dataLabels: {
        show: true,
        name: {
          show: false,
          fontSize: "22px",
          fontFamily: undefined,
          color: undefined,
          offsetY: -10,
        },
        value: {
          show: true,
          fontSize: "16px",
          fontFamily: undefined,
          color: undefined,
          offsetY: 6,
          formatter: function (val) {
            return val;
          },
        },
        total: {
          show: true,
          label: "",
          color: "#373d3f",
          formatter: function (w) {
            let total = w.globals.seriesTotals.reduce((a, b) => {
              return a + b;
            }, 0);
            if (total > 0) {
              total = parseFloat(total).toFixed(2);
            }
            return total;
          },
        },
      },
    },
  },
};

const chartOptions3 = {
  chart: {
    height: "300",
    type: "spline",
    inverted: false,
  },
  title: {
    align: "left",
    text: "",
    style: {
      fontSize: "22px",
      color: "#1A1A1A",
      fontWeight: "500",
    },
  },
  tooltip: {
    shared: true,
    // headerFormat: "<b> {point.x}</b>: ",
    // pointFormat: "<b>   {point.y} </b>",
    headerFormat:
      '<span style="font-size:10px;color:{series.color}"><b>{point.key}</b></span>: ',
    // pointFormat:
    //   // '<><td style="color:{series.color};padding:0">{series.name}: </td>' +

    //   '<span style="padding:0"><b>{point.y:.1f} </b></span>',
    footerFormat: "</table>",
    formatter() {
      var output1 = ` <span style="font-size:12px;"><b>${this.x
        }</b></span>: <span style="padding:0"><b>${this.y?.toLocaleString(
          "en-US"
        )}</b></span>`;
      return output1;
    },
    // shared: true,
    useHTML: true,
  },
  subTitle: {
    text: "",
  },
  yAxis: {
    title: {
      text: "",
      color: "#fff",
    },
    lineWidth: 0,
    maxPadding: 0.05,
    gridLineWidth: 1,
    crosshair: true,
    gridLineColor: "#ebebeb",
    labels: {
      style: {
        color: "#80848C",
        fontSize: "14px",
        // fontWeight: "400",
      },
    },
  },

  xAxis: {
    lineWidth: 0,
    gridLineColor: "#D9D9D9",
    gridLineWidth: 0,
    crosshair: true,
    labels: {
      style: {
        color: "#80848C",
        fontSize: "14px",
        // fontWeight: "400",
      },
    },
  },

  legend: {
    layout: "vertical",
    align: "center",
    verticalAlign: "bottom",
    itemStyle: {
      color: "#464E5F",
      fontWeight: "500",
      fontSize: "15px",
    },
  },

  plotOptions: {
    series: {
      label: {
        connectorAllowed: false,
      },
      pointStart: 0,
    },
  },

  // series: chart3,
  series: [
    {
      lineColor: "#88C75B",
      color: "#88C75B",
      name: "Income Range",

      marker: {
        symbol: "circle",
      },
    },
  ],
};
const chartOptions4 = {
  chart: {
    type: "column",
  },
  title: {
    text: "",
    align: "left",
  },
  subtitle: {
    text: "",
    align: "left",
  },
  yAxis: {
    title: {
      text: "",
      color: "#fff",
    },
    lineWidth: 0,
    maxPadding: 0.05,
    gridLineWidth: 1,
    crosshair: true,
    allowDecimals: false,
    gridLineColor: "#ebebeb",
    labels: {
      style: {
        color: "#80848C",
        fontSize: "14px",
        // fontWeight: "400",
      },
    },
  },

  xAxis: {
    lineWidth: 0,
    gridLineColor: "#D9D9D9",
    gridLineWidth: 0,
    crosshair: true,
    labels: {
      style: {
        color: "#80848C",
        fontSize: "14px",
        // fontWeight: "400",
      },
    },
  },
  tooltip: {
    shared: true,
    headerFormat:
      '<span style="font-size:10px;color:{series.color}"><b>{point.key}</b></span>: ',
    // pointFormat:
    //   // '<><td style="color:{series.color};padding:0">{series.name}: </td>' +

    //   '<span style="padding:0"><b>{point.y:.1f} </b></span>',
    footerFormat: "</table>",
    formatter() {
      var output1 = ` <span style="font-size:12px;"><b>${this.x
        }</b></span><br /> <span style="padding:0;color:${this.points[0]?.color
        }"><b>${this.points[0]?.color === "#F79153"
          ? "Pending  : " +
          this.points[0].y?.toLocaleString("en-US")
          : "Complete : " +
          this.points[0].y?.toLocaleString("en-US")
        }</b></span><br /><span style="padding:0;color:${this.points[1]?.color
        }"><b>${this.points[1]?.color === "#F79153"
          ? "Pending  : " +
          this.points[1].y?.toLocaleString("en-US")
          : "Complete : " +
          this.points[1].y?.toLocaleString("en-US")
        }</b></span>`;
      return output1;
    },
    useHTML: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  legend: {
    show: true,
    floating: false,
    showForSingleSeries: true,
    showForNullSeries: true,
    showForZeroSeries: true,

    fontSize: "14px",
    fontFamily: "Helvetica, Arial",
    height: undefined,
    formatter: function (seriesName, opts) {
      return [seriesName + " " + opts.w.globals.series[opts.seriesIndex] + "%"];
    },
    position: "bottom",
    labels: {
      useSeriesColors: false,
    },
    markers: {
      width: 5,
      height: 5,
      strokeWidth: "5px",
      strokeColor: "#fff",
      radius: "50%",
      customHTML: undefined,
      onClick: undefined,
    },
    onItemClick: {
      toggleDataSeries: true,
    },
    onItemHover: {
      highlightDataSeries: true,
    },
  },
  series: [],
};

const CustomLinearProgress = ({ value, color, label }) => {
  return (
    <>
      <div className="progrssBar">
        {label}
        <div className="bar">
          <LinearProgress
            variant="determinate"
            value={value}
            style={{
              width: "100%",
              height: "10px",
              borderRadius: "5px",
              backgroundColor: "#e0e0e0",
            }}
            sx={{
              "& .MuiLinearProgress-bar": {
                backgroundColor: color,
              },
            }}
          />
          <span>{`${Math.round(value)}%`}</span>
        </div>
      </div>
    </>
  );
};
const SurveyAnalytics = (props) => {
  const { acaYearId, distId, acaYearLabel } = props;
  const [surveyCounData, setSurveyCounData] = useState();
  const [surveyChartData, setSurveyChartData] = useState();

  let highchartOptions = {
    chart: {
      type: "pie",
      height: 320,
      custom: {},
      events: {
        render() {
          const chart = this,
            series = chart.series[0];
          let sePercent = series.data
            ?.filter((x) => x?.options?.visible !== false)
            ?.reduce((curr, acc) => curr + acc?.percent, 0);

          chart.setTitle({
            text: parseFloat(sePercent).toFixed(2) + "%",
            align: "center",
            verticalAlign: "middle",
            y: -10,
            style: {
              fontWeight: "bold",
            },
          });
        },
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    title: {
      text: "Survey/Application Type",
      align: "left",
    },
    subtitle: {
      text: "",
    },
    tooltip: {
      headerFormat: "",

      formatter() {
        var output1 = `${this.key} : ${this.point.y?.toLocaleString(
          undefined,
          undefined
        )}</b></span>`;
        return output1;
      },
    },
    legend: {
      enabled: true,
    },
    plotOptions: {
      // pie: {
      //   allowPointSelect: true,
      //   cursor: "pointer",
      //   dataLabels: {
      //     connectorWidth: 0,
      //     enabled: true,
      //     formatter: function () {
      //       return `<b>${this.point.name}</b><br>${this.point.y?.toLocaleString(
      //         undefined,
      //         undefined
      //       )}`;
      //     },
      //     distance: 20,
      //   },
      //   showInLegend: false,
      // },
      pie: {
        colors: ["#FD2254", "#FEA800", "#00B7FE", "#D0D2DA"],
      },
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        borderRadius: 0,
        dataLabels: [
          {
            enabled: true,
            distance: 20,
            format: "{point.name}",
          },
          {
            enabled: true,
            distance: -15,
            format: "{point.y}",
            style: {
              fontSize: "0.9em",
            },
          },
        ],
        showInLegend: true,
      },
    },
    series: [
      {
        colorByPoint: true,
        innerSize: "75%",
        data:
          surveyChartData?.surveyApplicationTypes?.map((x) => {
            return {
              ...x,
              y: x?.counts ?? 0,
              name: x?.title ? x?.title + " (" + x?.percent + "%) " : "",
            };
          }) ?? [],
      },
    ],
  };

  useEffect(() => {
    getSurveyCountData(distId, acaYearId);
    getSurveyChartData(distId, acaYearId);
  }, [distId, acaYearId]);

  const getSurveyCountData = async (id, year) => {
    let sendData = {
      schoolDistrictId: id,
      academicYearId: parseInt(year),
    };
    let seData = {
      url: `/api/SurveyDashboard/GetSurveryDashboardCounts`,
      body: sendData,
    };
    // setOpenCircle(true);
    setSurveyCounData();

    let res = await PostCallApi(seData);
    // setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let de = res.data.payload;
        setSurveyCounData(de);
      }
    }
  };
  const getSurveyChartData = async (id, year) => {
    let sendData = {
      schoolDistrictId: id,
      academicYearId: parseInt(year),
    };
    let seData = {
      url: `/api/SurveyDashboard/GetAllSurveryDashboardCharts`,
      body: sendData,
    };
    // setOpenCircle(true);
    setSurveyChartData();

    let res = await PostCallApi(seData);
    // setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let de = res.data.payload;
        setSurveyChartData(de);
      }
    }
  };

  function formatCompactNumber(number) {
    if (number < 1000) {
      return number;
    } else if (number >= 1000 && number < 1_000_000) {
      return (number / 1000).toFixed(2) + "K";
    } else if (number >= 1_000_000 && number < 1_000_000_000) {
      return (number / 1_000_000).toFixed(2) + "M";
    } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
      return (number / 1_000_000_000).toFixed(2) + "B";
    } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
      return (number / 1_000_000_000_000).toFixed(2) + "T";
    }
  }
  return (
    <div className="surveyAnalytics">
      <div className="row">
        <div className="col-md-9">
          <div className="wht-box">
            <div className="title text-start">
              <h5>Household Survey For {acaYearLabel}</h5>
              <span>By District</span>
            </div>
            <div className="row mt-4">
              <div className="col-md-3">
                <AdminCountBox
                  class="darkPink"
                  image={userIcon}
                  num={
                    surveyCounData?.pending
                      ? formatCompactNumber(surveyCounData?.pending)
                      : 0
                  }
                  name="Pending"
                />
              </div>
              <div className="col-md-3">
                <AdminCountBox
                  class="pink"
                  image={submitted}
                  num={
                    surveyCounData?.submitted
                      ? formatCompactNumber(surveyCounData?.submitted)
                      : 0
                  }
                  name="Submitted"
                />
              </div>
              <div className="col-md-3">
                <AdminCountBox
                  class="green"
                  image={complateIcon}
                  num={
                    surveyCounData?.complete
                      ? formatCompactNumber(surveyCounData?.complete)
                      : 0
                  }
                  name="Completed"
                />
              </div>
              <div className="col-md-3">
                <AdminCountBox
                  per={true}
                  class="blue"
                  image={survey}
                  num={
                    surveyCounData?.surveyCompletionPercentage
                      ? surveyCounData?.surveyCompletionPercentage
                      : 0
                  }
                  name="Survey Summations"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="wht-box">
            <Charts
              series={{
                ...highchartOptions,
                chart: {
                  ...highchartOptions.chart,
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4 MealBenefit">
          <div className="wht-box">
            <div className="title text-start">
              <h5>Meal Benefit</h5>
            </div>
            <Chart
              options={{
                ...options,
                labels:
                  surveyChartData?.mealBenifits?.map((x) => x?.title ?? "") ?? [],
              }}
              series={
                surveyChartData?.mealBenifits?.map((x) => x?.percent ?? 0) ?? []
              }
              type="radialBar"
              height=""
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="wht-box">
            <div className="title text-start mb-3">
              <h5>Average Household Income</h5>
            </div>

            <Charts
              series={{
                ...chartOptions3,
                xAxis: {
                  ...chartOptions3.xAxis,
                  categories: surveyChartData?.averageHouseholdIncome?.map(
                    (x) => x?.title ?? ""
                  ),
                },
                series: [
                  {
                    ...(chartOptions3?.series?.[0] ?? {}),
                    data: surveyChartData?.averageHouseholdIncome?.map((x) => x?.counts ?? 0) ?? [],
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-9">
          <div className="wht-box">
            <div className="title text-start mb-4">
              <h5>Site Wise Survey</h5>
            </div>
            <Charts
              series={{
                ...chartOptions4,
                xAxis: {
                  ...chartOptions4.xAxis,
                  categories: surveyChartData?.siteWiseSurveyStatus?.map(
                    (x) => x?.title ?? ""
                  ) ?? [],
                },
                series: [
                  {
                    color: "#F79153",
                    name: "Pending",
                    data: surveyChartData?.siteWiseSurveyStatus?.map(
                      (x) => x?.pendingcounts ?? 0
                    ) ?? [],
                  },
                  {
                    color: "#88C75B",
                    name: "Complete",
                    data: surveyChartData?.siteWiseSurveyStatus?.map(
                      (x) => x?.completecounts ?? 0
                    ) ?? [],
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="col-md-3 PaymentCycle">
          <div className="wht-box">
            <div className="title text-start">
              <h5>Household Payment Cycle</h5>
            </div>
            <ul>
              <li>
                <CustomLinearProgress
                  value={surveyCounData?.annualIncomeHousholdCounts ?? 0}
                  color="#49C7DB"
                  label="Annually"
                />
              </li>
              <li>
                <CustomLinearProgress
                  value={surveyCounData?.monthlyIncomeHousholdCounts ?? 0}
                  color="#49C7DB"
                  label="Monthly"
                />
              </li>
              <li>
                <CustomLinearProgress
                  value={surveyCounData?.twicePerMonthIncomeHousholdCounts ?? 0}
                  color="#49C7DB"
                  label="Twice Per Month"
                />
              </li>
              <li>
                <CustomLinearProgress
                  value={surveyCounData?.everyTwoWeekIncomeHousholdCounts ?? 0}
                  color="#49C7DB"
                  label="Every Two Weeks"
                />
              </li>
              <li>
                <CustomLinearProgress
                  value={surveyCounData?.weeklyIncomeHousholdCounts ?? 0}
                  color="#49C7DB"
                  label="Weekly"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyAnalytics;
