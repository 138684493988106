import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  styled,
  Button,
  Tooltip,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
// import search_icon from "../../../Assets/SvgImage/search_icon.svg";
import Pagination from "../Paginations/Pagination";
import { CiSearch } from "react-icons/ci";
import "./index.scss";
import Swal from "sweetalert2";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { Config } from "../../../Utils/Config";
import { HiMinusCircle, HiPlusCircle } from "react-icons/hi";
// import MultiImgPopup from "../MultiImgPopup";
// import docs from "../../../Assets/PngImage/docs.png"
// import sheet from "../../../Assets/PngImage/sheet.png"
// import ppt from "../../../Assets/PngImage/ppt.png"
// import pdf from "../../../Assets/PngImage/pdf.png"
// import video from "../../../Assets/PngImage/Video.png"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",
    lineHeight: "inherit",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
  "&.sm_8": {
    fontSize: "8.3px !important",
    padding: "2px !important",
    lineHeight: "inherit",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },

  // ".Mui-active": {
  //   color: "#fff !important",
  //   ".MuiSvgIcon-root": {
  //     color: "#fff !important",
  //   },
  // },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#ffffff",
  // "&:nth-of-type(odd)": {
  //   backgroundColor: "#ffffff",
  // },
  // "&:nth-of-type(even)": {
  //   backgroundColor: "#F1F6FC",
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ListTable(props) {
  const {
    headCells,
    action,
    ListData,
    pageApi,
    getApiData,
    totalRecord,
    dropValue,
    dropValue1,
    setDropValue,
    setDropValue1,
    dropList,
    dropdown,
    dropdown1,
    prdAddAr,
    AddStudents,
    btnTxt,
    actionFirst,
    fileName,
    downloadFile,
    reportDetails,
    dateRangeHide,
    summery,
    anotherTable,
    anotherheadCells,
    landscape,
  } = props;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("prdName");
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [downloadPdf, setDownloadpdf] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  // const [toggleTable, setToggleTable] = useState(false);
  // const [toggleId, setToggleId] = useState("");
  let Time = new Date();
  const back = {
    maxWidth: `${downloadPdf ? (landscape ? "265mm" : "200mm") : "100%"}`,
    // width: "100%",
    // height: "100%",
    // position: "relative",
    // margin: "0",
  };

  useEffect(() => {
    if (ListData) {
      handleCloseView();
      setCurrentPage(1);
    }
    // eslint-disable-next-line
  }, [ListData]);

  const [openView, setOpenView] = useState(false);
  const [rId, setRId] = useState();
  const handleCloseView = () => {
    setOpenView(false);
    setRId();
  };
  const handleOpenView = (r) => {
    setOpenView(true);

    setRId(r?.id);

    if (r?.id) {
      if (r?.id === rId) {
        setOpenView(false);
      }
    }
  };

  const tableRef = React.useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: fileName,
    sheet: "Product",
  });
  useEffect(() => {
    if (downloadExcel) {
      handleDownload();
    }
    // eslint-disable-next-line
  }, [downloadExcel]);

  const handleDownload = () => {
    onDownload();
    setDownloadExcel(false);
  };
  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {downloadPdf || downloadExcel ? (
            ""
          ) : (
            <>
              {actionFirst && action ? (
                <StyledTableCell align="center">
                  <TableSortLabel>Actions</TableSortLabel>
                </StyledTableCell>
              ) : (
                ""
              )}
            </>
          )}
          {headCells
            ?.filter((x) =>
              downloadPdf || downloadExcel ? x?.id !== "surveyDocumentUrl" : x
            )
            ?.map((headCell) => (
              <StyledTableCell
                key={headCell?.id}
                align="left"
                sortDirection={orderBy === headCell?.id ? order : false}
                className={`${
                  downloadPdf &&
                  `pdf_download  ${
                    fileName === "Submitted Surveys" ? "sm_8" : ""
                  }`
                }`}
              >
                <TableSortLabel
                  active={orderBy === headCell?.id}
                  direction={orderBy === headCell?.id ? order : "asc"}
                  onClick={createSortHandler(headCell?.id)}
                >
                  {headCell.label}
                  {orderBy === headCell?.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            ))}
          {downloadPdf || downloadExcel ? (
            ""
          ) : (
            <>
              {actionFirst ? (
                ""
              ) : (
                <>
                  {action ? (
                    <StyledTableCell align="center">
                      <TableSortLabel>Actions</TableSortLabel>
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                </>
              )}
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [searchField, setSearchField] = useState("");
  const formattedSearchResults = ListData?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        // if (typeof value === 'object' && value !== null) {
        //   value = JSON.stringify(value);
        // }
        // value = String(value);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });
  const handlePageChange = (page, no) => {
    if (pageApi) {
      let pno = { page: page, no: no };
      getApiData(pno);
      setCurrentPage(page);
    } else {
      setCurrentPage(page);
    }
  };
  const showReadMore = (title, des) => {
    Swal.fire({
      position: "center",
      width: "50%",
      // icon: "success",
      title: title,
      text: des,
      confirmButtonText: "Close",
      confirmButtonColor: "#E64942",
    });
  };

  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);
  const handleDownloadPdf = async () => {
    const element = tableRef.current;
    let newPdfDate = Time.toLocaleString();
    // return false;
    let doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    if (landscape) {
      doc = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "letter",
        userUnit: "px",
      });
    }
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`${fileName}.pdf`);
        setDownloadpdf(false);
      });
  };
  // const handleToggleOpen = (e) => {
  //   setToggleTable(true);
  //   setToggleId(e);
  //   if (e === toggleId) {
  //     setToggleTable(!toggleTable);
  //   }
  // };
  // const handleToggleClose = () => {
  //   setToggleId();
  //   setToggleTable(false);
  // };
  return (
    <>
      <div className="mb-4 d-flex justify-content-between listdata_control_sec">
        {downloadFile && formattedSearchResults?.length > 0 ? (
          <div>
            {props?.pdfHide ? (
              ""
            ) : (
              <Button
                className="cancel_btn me-3"
                onClick={() => setDownloadpdf(true)}
              >
                PDF
              </Button>
            )}
            <Button
              className="cancel_btn"
              onClick={() => setDownloadExcel(true)}
            >
              Download
            </Button>
          </div>
        ) : (
          ""
        )}
        {dropdown ? (
          <div className="ms-auto">
            <div className="d-flex">
              <label style={{ whiteSpace: "nowrap", margin: "auto" }}>
                {" "}
                Select Overlay
              </label>
              <select
                name=""
                className="form-select ms-2"
                value={dropValue}
                onChange={(e) => setDropValue(e.target.value)}
              >
                {dropList &&
                  dropList?.map((data, i) => (
                    <option value={data?.value} key={i}>
                      {data?.label}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}

        {formattedSearchResults?.length > 0 ? (
          <>
            {props?.selectAllBtn ? (
              <>
                <Button
                  className="theme_btn"
                  onClick={() =>
                    props?.selectingAll(true, formattedSearchResults)
                  }
                >
                  Select All
                </Button>
                <Button
                  className="ms-3 cancel_btn"
                  onClick={() =>
                    props?.selectingAll(false, formattedSearchResults)
                  }
                >
                  Deselect All
                </Button>

                {prdAddAr?.length > 0 ? (
                  <>
                    <Button className="ms-3 theme_btn" onClick={AddStudents}>
                      {btnTxt ? btnTxt : "Generate Barcode"}
                    </Button>
                    {props?.btn2 && (
                      <Button className="ms-3 cancel_btn" onClick={AddStudents}>
                        {props?.btn2Txt ? props?.btn2Txt : "Reject"}
                      </Button>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {dropdown1 ? (
          <div className="ms-auto">
            <div className="d-flex">
              <label style={{ whiteSpace: "nowrap", margin: "auto" }}>
                {" "}
                {props?.dropdownLabel}
              </label>
              <select
                name=""
                className="form-select ms-2"
                value={dropValue1}
                onChange={(e) => setDropValue1(e.target.value)}
              >
                {dropList &&
                  dropList?.map((data, i) => (
                    <option value={data?.value} key={i}>
                      {data?.label}
                    </option>
                  ))}
                {/* {dropList &&
                  dropList?.map((data, i) => (
                  ))} */}
              </select>
            </div>
          </div>
        ) : (
          ""
        )}
        <div
          className={` search_sec ${
            dropdown || dropdown1 ? "ms-3" : "ms-auto"
          }`}
        >
          <div className="search_input w-100">
            <CiSearch className="icon" />
            <input
              onChange={(e) => {
                setSearchField(e.target.value);
                setCurrentPage(1);
              }}
              type="text"
              className="form-control"
              placeholder="Type your search here"
            />
          </div>
        </div>
      </div>
      <div className="mt-3 all_list">
        <Paper className="table-box">
          <TableContainer ref={tableRef} className="" sx={back}>
            {downloadPdf || downloadExcel ? (
              <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                <div className="d-flex">
                  <img
                    src={
                      downloadPdf
                        ? require("../../../Assets/PngImage/Header_logo_1.png")
                        : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
                    }
                    alt=""
                    width={100}
                    height={80}
                  />
                  <div className="ms-3">
                    <label
                      style={{
                        fontSize: "18px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      {fileName}
                    </label>
                    <div
                      className="names"
                      style={{
                        fontSize: "16px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      {reportDetails?.mealService}
                    </div>
                    {dateRangeHide ? (
                      ""
                    ) : (
                      <div
                        className="names"
                        style={{
                          fontSize: "16px",
                          color: "#2c2e35",
                          fontWeight: 600,
                          marginBottom: "7px",
                        }}
                      >
                        {reportDetails?.startDate
                          ? reportDetails?.startDate
                          : ""}
                        {reportDetails?.endDate
                          ? " - " + reportDetails?.endDate
                          : ""}
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    style={{
                      fontSize: "18px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                      width: "250px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {reportDetails?.schName}
                  </label>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {reportDetails?.distName}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {Time.toLocaleString()}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                    }}
                  >
                    {LogDetail?.firstName + " " + LogDetail?.lastName}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                selectAllBtn={props?.selectAllBtn}
              />
              {pageApi ? (
                <TableBody>
                  {stableSort(
                    formattedSearchResults,
                    getComparator(order, orderBy)
                  )?.map((row, index) => {
                    return (
                      <StyledTableRow className="table_body" key={index + 1}>
                        {headCells?.map((column) => {
                          const value = row[column?.id];
                          if (column?.click) {
                            return (
                              <StyledTableCell
                                className={`column_data one ${
                                  downloadPdf &&
                                  `pdf_download ${
                                    fileName === "Submitted Surveys"
                                      ? "sm_8"
                                      : ""
                                  }`
                                }`}
                                key={column?.id}
                                onClick={() =>
                                  showReadMore(row.tlt, row?.mealList)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {column?.format && typeof value === "number"
                                  ? column?.format(value)
                                  : value}
                              </StyledTableCell>
                            );
                          }
                          if (column?.des) {
                            return (
                              <StyledTableCell
                                className={`column_data one ${
                                  downloadPdf &&
                                  `pdf_download ${
                                    fileName === "Submitted Surveys"
                                      ? "sm_8"
                                      : ""
                                  }`
                                }`}
                                key={column?.id}
                                onClick={() =>
                                  showReadMore(row.desTit, row?.description)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {value?.length > 25
                                  ? value?.substring(0, 25) + "..."
                                  : value}
                              </StyledTableCell>
                            );
                          }
                          return (
                            <StyledTableCell
                              className={`column_data  ${
                                downloadPdf &&
                                `pdf_download ${
                                  fileName === "Submitted Surveys" ? "sm_8" : ""
                                }`
                              }`}
                              key={column?.id}
                            >
                              {column?.format && typeof value === "number"
                                ? column?.format(value)
                                : value}
                            </StyledTableCell>
                          );
                        })}
                        {downloadPdf || downloadExcel ? (
                          ""
                        ) : (
                          <>
                            {action ? (
                              <StyledTableCell
                                className="column_data"
                                key={index + 1}
                              >
                                <div className="edit_delete">{row?.Action}</div>
                              </StyledTableCell>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  {stableSort(
                    formattedSearchResults,
                    getComparator(order, orderBy)
                  )
                    ?.slice(
                      (currentPage - 1) * pageSize,
                      (currentPage - 1) * pageSize + pageSize
                    )
                    ?.map((row, index) => {
                      return (
                        <React.Fragment key={index + 1}>
                          <StyledTableRow
                            className={`table_body`}
                            key={index + 1}
                            style={{
                              backgroundColor: row?.color ? row?.color : "",
                            }}
                          >
                            {downloadPdf || downloadExcel ? (
                              ""
                            ) : (
                              <>
                                {actionFirst && action ? (
                                  <StyledTableCell
                                    className={`column_data  ${
                                      downloadPdf && "pdf_download"
                                    }`}
                                    key={index + 1}
                                  >
                                    <div
                                      className={`edit_delete ${
                                        anotherTable
                                          ? `justify-content-${
                                              props?.plusIconCenter
                                                ? "center"
                                                : "start"
                                            }`
                                          : ""
                                      }`}
                                    >
                                      {anotherTable ? (
                                        <>
                                          {row?.subTableList?.length > 0 ? (
                                            row?.id === rId ? (
                                              <Tooltip title="Close Student">
                                                <button
                                                  className="btn_dlt "
                                                  onClick={() =>
                                                    handleCloseView()
                                                  }
                                                >
                                                  <HiMinusCircle
                                                    className="icon"
                                                    fontSize={24}
                                                  />
                                                </button>
                                              </Tooltip>
                                            ) : (
                                              <Tooltip title="View Student">
                                                <button
                                                  className="btn_edit "
                                                  onClick={() =>
                                                    handleOpenView(row)
                                                  }
                                                >
                                                  <HiPlusCircle fontSize={24} />
                                                </button>
                                              </Tooltip>
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {row?.Action}
                                    </div>
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                              </>
                            )}

                            {/* {downloadPdf || downloadExcel ? (
                              ""
                            ) : (
                              <>
                                {actionFirst && action ? (
                                  <StyledTableCell
                                    className={`column_data  ${downloadPdf && "pdf_download"
                                      }`}
                                    key={index + 1}
                                  >
                                    <div
                                      className={`edit_delete ${anotherTable
                                        ? "justify-content-start"
                                        : ""
                                        }`}
                                    >
                                      {anotherTable ? (
                                        <>
                                          {row.houseHoldSurveyId === toggleId &&
                                            toggleTable ? (
                                            <button
                                              className="btn_dlt "
                                              onClick={handleToggleClose}
                                            >
                                              <HiMinusCircle
                                                className="icon"
                                                fontSize={24}
                                              />
                                            </button>
                                          ) : (
                                            <button
                                              className="btn_edit "
                                              onClick={() =>
                                                handleToggleOpen(
                                                  row?.houseHoldSurveyId
                                                )
                                              }
                                            >
                                              <HiPlusCircle fontSize={24} />
                                            </button>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {row?.Action}
                                    </div>
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                              </>
                            )} */}
                            {headCells
                              ?.filter((x) =>
                                downloadPdf || downloadExcel
                                  ? x?.id !== "surveyDocumentUrl"
                                  : x
                              )
                              ?.map((column) => {
                                const value = row[column?.id];
                                if (downloadExcel || downloadPdf) {
                                  if (column?.click) {
                                    return (
                                      <StyledTableCell
                                        className={`column_data one ${
                                          downloadPdf &&
                                          `pdf_download ${
                                            fileName === "Submitted Surveys"
                                              ? "sm_8"
                                              : ""
                                          }`
                                        }`}
                                        key={column?.id}
                                        style={{
                                          cursor: "pointer",
                                          maxWidth: "60px",
                                          wordBreak: "break-all",
                                        }}
                                      >
                                        {row?.mealList}
                                      </StyledTableCell>
                                    );
                                  }
                                  return (
                                    <StyledTableCell
                                      className={`column_data  ${
                                        downloadPdf &&
                                        `pdf_download ${
                                          fileName === "Submitted Surveys"
                                            ? "sm_8"
                                            : ""
                                        }`
                                      }`}
                                      key={column?.id}
                                    >
                                      {column?.format &&
                                      typeof value === "number"
                                        ? column?.format(value)
                                        : value}
                                    </StyledTableCell>
                                  );
                                } else {
                                  if (column?.click) {
                                    return (
                                      <StyledTableCell
                                        className="column_data one"
                                        key={column?.id}
                                        onClick={() =>
                                          showReadMore(row.tlt, row?.mealList)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {column?.format &&
                                        typeof value === "number"
                                          ? column?.format(value)
                                          : value}
                                      </StyledTableCell>
                                    );
                                  }
                                  if (column?.des) {
                                    return (
                                      <StyledTableCell
                                        className={`column_data one ${
                                          downloadPdf && "pdf_download"
                                        }`}
                                        key={column?.id}
                                        onClick={() =>
                                          showReadMore(
                                            row.desTit,
                                            row?.description
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        {value?.length > 25
                                          ? value?.substring(0, 25) + "..."
                                          : value}
                                      </StyledTableCell>
                                    );
                                  }
                                  if (column?.widthFix) {
                                    return (
                                      <StyledTableCell
                                        className="column_data"
                                        key={column?.id}
                                      >
                                        {value ? value?.slice(0, 50) : ""}
                                        {value
                                          ? value?.length > 50 && (
                                              <span
                                                className="description cr"
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  showReadMore(
                                                    column?.label,
                                                    value
                                                  )
                                                }
                                              >
                                                ...read more
                                              </span>
                                            )
                                          : ""}
                                      </StyledTableCell>
                                    );
                                  }
                                  if (column?.isNotes) {
                                    return (
                                      <StyledTableCell
                                        className="column_data"
                                        key={column?.id}
                                        onClick={() => {
                                          if (row?.oldNote?.length > 0) {
                                            props?.handleNotePopup({
                                              ...row,
                                              edit: true,
                                            });
                                          }
                                        }}
                                      >
                                        {value ? value?.slice(0, 20) : ""}
                                        {value
                                          ? value?.length > 20 && (
                                              <span
                                                className="description cr"
                                                style={{ cursor: "pointer" }}
                                              >
                                                ...read more
                                              </span>
                                            )
                                          : ""}
                                      </StyledTableCell>
                                    );
                                  }
                                  return (
                                    <StyledTableCell
                                      className="column_data"
                                      key={column?.id}
                                    >
                                      {column?.format &&
                                      typeof value === "number"
                                        ? column?.format(value)
                                        : value}
                                    </StyledTableCell>
                                  );
                                }
                              })}
                            {downloadPdf || downloadExcel ? (
                              ""
                            ) : (
                              <>
                                {actionFirst ? (
                                  ""
                                ) : (
                                  <>
                                    {action ? (
                                      <StyledTableCell
                                        className="column_data"
                                        key={index + 1}
                                      >
                                        <div className="edit_delete">
                                          {row?.Action}
                                        </div>
                                      </StyledTableCell>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </StyledTableRow>
                          {/* {downloadPdf || downloadExcel ? (
                            <>
                              {anotherTable && (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={16}>
                                    {row.studentList?.length > 0 ? (
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            {anotherheadCells?.map(
                                              (headCell, i) => (
                                                <StyledTableCell
                                                  key={headCell?.id}
                                                  align="left"
                                                  className={`${downloadPdf &&
                                                    `pdf_download  ${fileName ===
                                                      "Submitted Surveys"
                                                      ? "sm_8"
                                                      : ""
                                                    }`
                                                    }`}
                                                >
                                                  {headCell.label}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.studentList?.map((std, i) => (
                                            <StyledTableRow
                                              className="table_body"
                                              key={i + 1}
                                            >
                                              {anotherheadCells?.map(
                                                (column) => {
                                                  const value = std[column?.id];
                                                  return (
                                                    <StyledTableCell
                                                      className={`column_data  ${downloadPdf &&
                                                        `pdf_download ${fileName ===
                                                          "Submitted Surveys"
                                                          ? "sm_8"
                                                          : ""
                                                        }`
                                                        }`}
                                                      key={column?.id}
                                                    >
                                                      {column?.format &&
                                                        typeof value === "number"
                                                        ? column?.format(value)
                                                        : value}
                                                    </StyledTableCell>
                                                  );
                                                }
                                              )}
                                            </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    ) : (
                                      <div className="er_nodata">
                                        No Data Found
                                      </div>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </>
                          ) : (
                            <>
                              {anotherTable &&
                                row.houseHoldSurveyId === toggleId &&
                                toggleTable ? (
                                <StyledTableRow>
                                  <StyledTableCell colSpan={6}>
                                    {row.studentList?.length > 0 ? (
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            {anotherheadCells?.map(
                                              (headCell, i) => (
                                                <StyledTableCell
                                                  key={headCell?.id}
                                                  align="left"
                                                  className={`${downloadPdf &&
                                                    `pdf_download  ${fileName ===
                                                      "Submitted Surveys"
                                                      ? "sm_8"
                                                      : ""
                                                    }`
                                                    }`}
                                                >
                                                  {headCell.label}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.studentList?.map((std, i) => (
                                            <StyledTableRow
                                              className="table_body"
                                              key={i + 1}
                                            >
                                              {anotherheadCells?.map(
                                                (column) => {
                                                  const value = std[column?.id];
                                                  return (
                                                    <StyledTableCell
                                                      className={`column_data  ${downloadPdf &&
                                                        `pdf_download ${fileName ===
                                                          "Submitted Surveys"
                                                          ? "sm_8"
                                                          : ""
                                                        }`
                                                        }`}
                                                      key={column?.id}
                                                    >
                                                      {column?.format &&
                                                        typeof value === "number"
                                                        ? column?.format(value)
                                                        : value}
                                                    </StyledTableCell>
                                                  );
                                                }
                                              )}
                                            </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    ) : (
                                      <div className="er_nodata">
                                        No Data Found
                                      </div>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                ""
                              )}
                            </>
                          )} */}

                          {downloadPdf || downloadExcel ? (
                            <>
                              {anotherTable && (
                                <StyledTableRow>
                                  <StyledTableCell>
                                    {row.subTableList?.length > 0 ? (
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            {anotherheadCells?.map(
                                              (headCell, i) => (
                                                <StyledTableCell
                                                  key={headCell?.id}
                                                  align="left"
                                                  className={`${
                                                    downloadPdf &&
                                                    `pdf_download  ${
                                                      fileName ===
                                                      "Submitted Surveys"
                                                        ? "sm_8"
                                                        : ""
                                                    }`
                                                  }`}
                                                >
                                                  {headCell.label}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.subTableList?.map((std, i) => (
                                            <StyledTableRow
                                              className="table_body"
                                              key={i + 1}
                                            >
                                              {anotherheadCells?.map(
                                                (column) => {
                                                  const value = std[column?.id];
                                                  return (
                                                    <StyledTableCell
                                                      className={`column_data  ${
                                                        downloadPdf &&
                                                        `pdf_download ${
                                                          fileName ===
                                                          "Submitted Surveys"
                                                            ? "sm_8"
                                                            : ""
                                                        }`
                                                      }`}
                                                      key={column?.id}
                                                    >
                                                      {column?.format &&
                                                      typeof value === "number"
                                                        ? column?.format(value)
                                                        : value}
                                                    </StyledTableCell>
                                                  );
                                                }
                                              )}
                                            </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    ) : (
                                      <div className="er_nodata">
                                        No Data Found
                                      </div>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )}
                            </>
                          ) : (
                            <>
                              {openView && row?.id === rId ? (
                                <StyledTableRow>
                                  <StyledTableCell
                                    colSpan={anotherheadCells?.length}
                                  >
                                    {row.subTableList?.length > 0 ? (
                                      <Table>
                                        <TableHead>
                                          <TableRow>
                                            {anotherheadCells?.map(
                                              (headCell, i) => (
                                                <StyledTableCell
                                                  key={headCell?.id}
                                                  align="left"
                                                  className={`${
                                                    downloadPdf &&
                                                    `pdf_download  ${
                                                      fileName ===
                                                      "Submitted Surveys"
                                                        ? "sm_8"
                                                        : ""
                                                    }`
                                                  }`}
                                                >
                                                  {headCell.label}
                                                </StyledTableCell>
                                              )
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {row.subTableList?.map((std, i) => (
                                            <StyledTableRow
                                              className="table_body"
                                              key={i + 1}
                                            >
                                              {anotherheadCells?.map(
                                                (column) => {
                                                  const value = std[column?.id];
                                                  return (
                                                    <StyledTableCell
                                                      className={`column_data  ${
                                                        downloadPdf &&
                                                        `pdf_download ${
                                                          fileName ===
                                                          "Submitted Surveys"
                                                            ? "sm_8"
                                                            : ""
                                                        }`
                                                      }`}
                                                      key={column?.id}
                                                    >
                                                      {column?.format &&
                                                      typeof value === "number"
                                                        ? column?.format(value)
                                                        : value}
                                                    </StyledTableCell>
                                                  );
                                                }
                                              )}
                                            </StyledTableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    ) : (
                                      <div className="er_nodata">
                                        No Data Found
                                      </div>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              )}
            </Table>
            {downloadExcel || downloadPdf ? (
              <>
                {summery ? (
                  <div className="mt-3">
                    <label
                      style={{
                        fontSize: "18px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      Total Submitted: {reportDetails?.totalSubmittedCount}
                    </label>
                    <br />
                    <label
                      style={{
                        fontSize: "18px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      Total Not Submitted:{" "}
                      {reportDetails?.totalNotSubmittedCount}
                    </label>{" "}
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </TableContainer>
          {formattedSearchResults?.length === 0 ? (
            <div className="er_nodata">No Data Found</div>
          ) : (
            <div className="page_nation">
              <div className="rowsPerPageOptions">
                <span className="p_18_text">show</span>
                <select
                  className="pagination_select p_16_text"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value));
                    handlePageChange(1, parseInt(e.target.value));
                    // setCurrentPage(1);
                  }}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  {props.entries1000 ? (
                    ""
                  ) : (
                    <>
                      <option value="2000">2000</option>
                      <option value="5000">5000</option>
                      <option value="10000">10000</option>
                    </>
                  )}
                </select>
                <span className="p_18_text">entries</span>
              </div>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={
                  pageApi ? totalRecord : formattedSearchResults?.length
                }
                pageSize={pageSize}
                onPageChange={(page) => handlePageChange(page, pageSize)}
                // onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
          {downloadExcel || downloadPdf ? (
            ""
          ) : summery ? (
            <div className="mt-3">
              <label
                style={{
                  fontSize: "18px",
                  color: "#2c2e35",
                  fontWeight: 600,
                  marginBottom: "7px",
                }}
              >
                Total Submitted: {reportDetails?.totalSubmittedCount}
              </label>
              <br />
              <label
                style={{
                  fontSize: "18px",
                  color: "#2c2e35",
                  fontWeight: 600,
                  marginBottom: "7px",
                }}
              >
                Total Not Submitted: {reportDetails?.totalNotSubmittedCount}
              </label>{" "}
            </div>
          ) : (
            ""
          )}
        </Paper>
      </div>
    </>
  );
}
export default React.memo(ListTable);
