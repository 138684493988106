import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import ReactDatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSelector } from "react-redux";
import { useBarcode } from "next-barcode";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ChildInformationTab() {
  const [startDate, setStartDate] = useState(new Date());
  const [studId, setStudId] = useState();

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();
  const { inputRef } = useBarcode({
    value: studId,
    options: {
      background: "#ffffff",
    },
  });
  useEffect(() => {
    if (LogDetail) {
      setValue("studentName", LogDetail?.firstName + " " + LogDetail?.lastName);
      setValue("studentCode", LogDetail?.studentId);
      setStudId(LogDetail?.barcode);
      setValue("email", LogDetail?.email);
      setValue("studentBalance", LogDetail?.studentBalance);
      setStartDate(new Date(LogDetail?.birthDate));
    }
    // eslint-disable-next-line
  }, [LogDetail]);
  return (
    <Suspense fallback={renderLoader()}>
      <div className="childinformation-section">
        <form action="">
          <div className="row">
            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Student Name
                  {/* <span className="red-color">*</span> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("studentName", {
                    required: "This field is required",
                  })}
                  disabled
                />
                {errors.studentName && (
                  <span role="alert" className="error_text">
                    {errors.studentName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Email
                  {/* <span className="red-color">*</span>{" "} */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("email", {
                    required: "This field is required",
                  })}
                  disabled
                />
                {errors.email && (
                  <span role="alert" className="error_text">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Account Balance
                  {/* <span className="red-color">*</span>{" "} */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("studentBalance", {
                    required: "This field is required",
                  })}
                  disabled
                />
                {errors.studentBalance && (
                  <span role="alert" className="error_text">
                    {errors.studentBalance.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Date of Birth
                  {/* <span className="red-color">*</span> */}
                </label>
                <ReactDatePicker
                  showIcon
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  disabled
                />
              </div>
            </div>

            <div className="col-md-6 radio-block margin-bottom">
              <FormControl>
                <label>Gender</label>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={LogDetail?.gender}
                >
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                    // selected={LogDetail?.gender === "Female" ? true : false}
                    disabled
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                    // selected={LogDetail?.gender === "Male" ? true : false}
                    disabled
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Barcode
                  {/* <span className="red-color">*</span> */}
                </label>
                <div>
                  <svg ref={inputRef} />
                </div>
              </div>
            </div>
            {/* <div className="col-md-12 text-end mt-3">
              <Button className="cancel_btn me-2">Cancel</Button>
              <Button type="submit" className="theme_btn ">
                Submit
              </Button>
            </div> */}
          </div>
        </form>
      </div>
    </Suspense>
  );
}

export default ChildInformationTab;
