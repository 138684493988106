import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "cashierName", label: "Cashier Name" },
  { id: "endTime", label: "End Time" },
  { id: "status", label: "Status" },
  { id: "cashExpectedAmount", label: "Cash Expected Amount" },
  { id: "cashCollected", label: "Cash Collected" },
  { id: "cashOverOrShort", label: "Cash OverOrShort" },
  { id: "checkExpectedAmount", label: "Check Expected Amount" },
  { id: "totalCheckCollected", label: "Total Check Collected" },
  { id: "checkOverOrShort", label: "Check OverOrShort" },
];
function CashierSessionApproval() {
  const [openCircle, setOpenCircle] = useState(false);
  const [distList, setDistList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [prdList, setPrdList] = useState([]);
  const [prdAddAr, setPrdAddAr] = useState([]);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);
  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            ...sd,
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getSchoolList = async (id) => {
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              ...sd,
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          setSchoolList(dlist?.length > 0 ? dlist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  // function convertDate(str) {
  //   var date = new Date(str?.replace(/-/g, "/")),
  //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //     day = ("0" + date.getDate()).slice(-2);
  //   return [mnth, day, date.getFullYear()].join("/");
  // }

  const CSAList = async (data) => {
    setOpenCircle(true);
    setPrdList([]);
    let sendData = {
      ...data,
      userId: LogDetail?.userId,
    };
    let seData = {
      url: `/api/CashierSessionApproval/GetAllCashierSessionData`,
      body: sendData,
      headers: headers,
    };
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        const SdData = res.data.payload?.map((sd, i) => {
          return {
            ...sd,
            endTime: sd.endTime ? new Date(sd?.endTime).toLocaleString() : "",
            Action: (
              <label className=" personal_detail_label">
                <input
                  type="checkbox"
                  className="login_check"
                  name="Remember Me"
                  checked={false}
                  onChange={(e) =>
                    handleAddProduct(e?.target?.checked, sd?.sessionId)
                  }
                />
                <span className="checkmark"></span>
              </label>
            ),
          };
        });
        setPrdList(SdData);
      } else {
        setOpenCircle(false);
      }
    }
  };

  const handleAddProduct = (val, sessionId) => {
    if (val) {
      setPrdAddAr((oldArray) => [...oldArray, sessionId]);
    } else {
      setPrdAddAr((oldArray) => oldArray?.filter((data) => data !== sessionId));
    }
  };
  const handleSelectAll = (val, list) => {
    if (val) {
      setPrdAddAr(list?.map((x) => x.sessionId));
    } else {
      setPrdAddAr([]);
    }
  };
  useEffect(() => {
    if (prdAddAr?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [prdAddAr]);
  const changetbData = (val) => {
    let newtb = prdList?.map((sd) => {
      return {
        ...sd,
        Action: (
          <label className=" personal_detail_label">
            <input
              type="checkbox"
              className="login_check"
              name="Remember Me"
              checked={
                prdAddAr?.filter((x) => x === sd?.sessionId)?.length > 0
                  ? true
                  : false
              }
              onChange={(e) =>
                handleAddProduct(e?.target?.checked, sd?.sessionId)
              }
            />
            <span className="checkmark"></span>
          </label>
        ),
      };
    });
    setPrdList(newtb);
  };

  const AddStudents = async (data) => {
    const { value: text } = await Swal.fire({
      title: " Remark",
      input: "text",
      //   inputLabel: "Remark",
      inputPlaceholder: "Enter Remark",
    });

    // if (text) {
    //   Swal.fire(`Entered email: ${text}`);
    // }
    let sendData = {
      ...data,
      sessionId: prdAddAr,
      statusChangeByUserId: LogDetail?.userId,
      managerRemark: text,
    };

    setOpenCircle(true);
    let seData = {
      url: "/api/CashierSessionApproval/UpdateCashierSessionStatus",
      body: sendData,
      headers: headers,
    };
    let res = await PostCallApi(seData);

    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        setPrdAddAr([]);
        let sv = {
          schoolDistrictId: getValues("schoolDistrictId"),
          schoolId: getValues("schoolId"),
          startDate: getValues("startDate"),
          endDate: getValues("endDate"),
          mealService: getValues("mealService"),
        };
        CSAList(sv);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setOpenCircle(false);
      }
    }
  };
  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="cashier_session_approval_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">
                    Cashier Session Approval
                  </div>
                </div>
                <form onSubmit={handleSubmit(CSAList)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School</label>
                        <select
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School </option>
                          {schoolList.map((item, i) => (
                            <option key={i} value={item?.value}>
                              {item?.label}
                            </option>
                          ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Start Date <span className="red-color">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("startDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.startDate && (
                          <span role="alert" className="error_text">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          End Date <span className="red-color">*</span>
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          {...register("endDate", {
                            required: "This field is required",
                            valueAsDate: true,
                            validate: (val) => {
                              if (watch("startDate") > val) {
                                return "Your start date can not greater than from end date";
                              }
                            },
                          })}
                        />
                        {errors.endDate && (
                          <span role="alert" className="error_text">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Meal Service</label>
                        <select
                          className="form-select"
                          {...register("mealService", {
                            required: "This field is required",
                          })}
                        >
                          {/* <option value="All">All</option> */}
                          <option value="Breakfast">Breakfast</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.mealService && (
                          <span role="alert" className="error_text">
                            {errors.mealService.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Status</label>
                        <select
                          className="form-select"
                          {...register("status", {
                            // required: "This field is required",
                          })}
                        >
                          <option value="">Select Status</option>
                          <option value="Approve">Approve</option>
                          <option value="Reject">Reject</option>
                        </select>
                        {errors.status && (
                          <span role="alert" className="error_text">
                            {errors.status.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" ms-auto mt-auto col-md-9">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img src={filter} className="me-1" alt="" />
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>

                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                  actionFirst={true}
                  btnTxt={"Approve"}
                  btn2={true}
                  btn2Txt={"Reject"}
                  selectAllBtn={true}
                  prdAddAr={prdAddAr}
                  AddStudents={(e) =>
                    AddStudents({ status: e.target.innerText })
                  }
                  selectingAll={handleSelectAll}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CashierSessionApproval;
