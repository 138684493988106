import React, { Suspense, lazy, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
// import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import "./index.scss";
// import Select from "react-select";
import { PostCallApi } from "../../../../../Action/Action";
// import Swal from "sweetalert2";

const SessionTable = lazy(() => import("./SessionTable/SessionTable"));

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  // { id: "cashier", label: "Cashier" },
  // { id: "status", label: "Status" },
  { id: "cashierName", label: "Cashier Name" },
  { id: "mealServed", label: "Meal Served" },
  { id: "cashExpectedAmount", label: "Cash Expected Amount" },
  { id: "cashCollected", label: "Cash Collected" },
  // { id: "endTime", label: "End Date" },
  { id: "mealService", label: "Service Type" },
  { id: "isMealServiceEnded", label: "Meal Service Ended" },
  { id: "showDate", label: "Date" },
  // { id: "totalAmount", label: "Amount" },
  { id: "cashOverOrShort", label: "Cash Over/Short" },
  { id: "revenueOverOrShort", label: "Revenue Over/Short" },
  { id: "checkExpectedAmount", label: "Check Expected Amount" },
  { id: "totalCheckCollected", label: "Total Check Collected" },
  { id: "checkOverOrShort", label: "Check Over/Short" },
  // { id: "bulkMealEntry", label: "Bulk Meal Entry" },
];
function CashierSession(props) {
  const { district, dateNew } = props;
  const [paymentList, setPaymentList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  useEffect(() => {
    // getDistrictList();
    if (district && dateNew) {
      getPayment();
    }
    // eslint-disable-next-line
  }, [district, dateNew]);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getPayment = async (data) => {
    setPaymentList([]);
    let is_success = true;

    if (is_success) {
      setOpenCircle(!openCircle);
      let sendData = {
        ...data,
        schoolDistrictId: district,
        date: dateNew,
        userId: LogDetail?.userId,
        // schoolDistrictId: district
        //   ? data?.schoolDistrictId
        //   : null,
        // schoolId: schoolSelect?.map((x) => x.value),
        // startDate: data?.startDate ? data?.startDate : null,
        // endDate: data?.endDate ? data?.endDate : null,
        // userId: LogDetail?.userId,
      };

      let seData = {
        url: `/api/Report/GetCashierEndMealSessionReport`,
        body: sendData,
        headers: headers,
      };
      let res = await PostCallApi(seData);

      // Axios.post(`api/Report/GetCashierEndMealSessionReport`, sendData, {
      //   headers,
      // })
      //   .then((res) => {
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          const dList = res.data.payload?.map((x) => {
            let sdate = x?.startTime
              ? new Date(x?.startTime)?.toLocaleString()
              : "";
            let eDate = x?.endTime
              ? new Date(x?.endTime)?.toLocaleTimeString()
              : "";
            let showDate = x?.isMealServiceEnded
              ? sdate + " - " + eDate
              : sdate;

            return {
              ...x,
              add: false,
              endTime: x?.endTime ? new Date(x?.endTime)?.toLocaleString() : "",
              startTime: x?.startTime
                ? new Date(x?.startTime)?.toLocaleString()
                : "",
              showDate: showDate,
              isMealServiceEnded: x?.isMealServiceEnded ? "Close" : "Open",
              cashExpectedAmount: x?.cashExpectedAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashCollected: x?.cashCollected?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashOverOrShort: x?.cashOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              revenueOverOrShort: x?.revenueOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkExpectedAmount: x?.checkExpectedAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              totalCheckCollected: x?.totalCheckCollected?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkOverOrShort: x?.checkOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            };
          });
          setPaymentList(dList);
          setOpenCircle(false);
        } else {
          setOpenCircle(false);
          // Swal.fire({
          //   position: "top-end",
          //   width: 400,
          //   // icon: "success",
          //   text: res?.data?.message
          //     ? res?.data?.message
          //     : "Something went wrong please try again.",
          //   showConfirmButton: false,
          //   timer: 2000,
          //   color: "#ffffff",
          //   background: "red",
          //   showClass: {
          //     popup: "animate__animated animate__fadeInDown",
          //   },
          //   hideClass: {
          //     popup: "animate__animated animate__fadeOutRight",
          //   },
          // });
        }
      } else {
        // Swal.fire({
        //   position: "top-end",
        //   width: 400,
        //   // icon: "success",
        //   text: res?.data?.message
        //     ? res?.data?.message
        //     : "Something went wrong please try again.",
        //   showConfirmButton: false,
        //   timer: 2000,
        //   color: "#ffffff",
        //   background: "red",
        //   showClass: {
        //     popup: "animate__animated animate__fadeInDown",
        //   },
        //   hideClass: {
        //     popup: "animate__animated animate__fadeOutRight",
        //   },
        // });
      }
      // })
      // .catch((e) => {
      //   console.log(e);
      //   setOpenCircle(false);
      // });
    }
  };

  const getPaymentCall = () => {
    getPayment();
  };

  return (
    <>
      {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="cashier_report-page">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Cashier Session </h4>
            <div className="card">
              <div className="card-body">
                <SessionTable
                  headCells={productHead}
                  action={true}
                  ListData={paymentList}
                  fileName="Cashier Session Report"
                  actionFirst={true}
                  setListData={setPaymentList}
                  getPaymentCall={getPaymentCall}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CashierSession;
