import React, { useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import "./index.scss";
import header_logo from "../../../../../../Assets/PngImage/Header_logo_1.png";
import web_profile_img from "../../../../../../Assets/default_profile.jpg";
import profileicon from "../../../../../../Assets/SvgImage/profileicon.svg";
import logouticon from "../../../../../../Assets/SvgImage/logout-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../../../../../reducers/login";
import { Menu, MenuItem } from "@mui/material";
import { AiFillCaretDown } from "react-icons/ai";
import AssignHeadMenu from "./AssignHeadMenu";
import { resetMenu } from "../../../../../../reducers/menu";
import { Config } from "../../../../../../Utils/Config";

function WebAdminNavigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNotification = Boolean(anchorEl);

  let nav = useNavigate();
  let dispatch = useDispatch();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  // const token = useSelector((state) => state.login.LoginDetails.accessToken);

  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };
  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNav = () => {
    // localStorage.clear("expireDate");
    dispatch(LoginUser({ authenticate: false, authenticateStudent: false }));
    dispatch(resetMenu());
    nav("/", { replace: true });
  };

  return (
    <>
      <section className={`student_navigation`}>
        <div className="container-fluid">
          <div className="main_wrapper">
            <Navbar expand="lg" className="nav_bar">
              <div className="mobile_toggle">
                <NavLink to="/web-dashboard">
                  <img
                    src={header_logo}
                    className="header_logo img-fluid"
                    alt="header_logo"
                  />
                </NavLink>
                <div className="mobile-profile">
                  <Nav
                    navbar
                    className="nav_action align-items-center"
                    onClick={handleClick}
                  >
                    <div className="user_d_box">
                      <img src={web_profile_img} alt="" className="me-2" />
                      {/* <SlUser className="icon" /> */}
                      {/* <div className="me-2 f-16-400">User Name</div> */}
                      <AiFillCaretDown />
                    </div>
                  </Nav>
                  <NavbarToggler
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 12H16"
                        stroke="#666666"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 6H21"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 18H21"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </NavbarToggler>
                </div>
              </div>
              <Collapse isOpen={isOpen} navbar className="header_nav">
                <NavLink to="/web-dashboard" className="main_logo">
                  <img
                    src={header_logo}
                    className="header_logo"
                    alt="header_logo"
                  />
                </NavLink>
                {/* <NavigateComponent /> */}
                <AssignHeadMenu />
                <Nav
                  navbar
                  className="nav_action align-items-center"
                  onClick={handleClick}
                >
                  <div className="user_d_box">
                    <img
                      src={
                        LogDetail?.profilePhoto
                          ? `${Config.API_HOST_URL_live}${LogDetail?.profilePhoto}`
                          : web_profile_img
                      }
                      alt="Profile"
                      className="me-2"
                    />
                    {/* <SlUser className="icon" /> */}
                    {/* <div className="me-2 f-16-400">User Name</div> */}
                    <AiFillCaretDown />
                  </div>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </section>
      <Menu
        className="notification_list web_notification"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openNotification}
        onClose={handleClose}
      >
        <MenuItem className="menu-link">
          <Link to="/profile">
            <img src={profileicon} alt="Profile" className="me-2" /> Profile
          </Link>
        </MenuItem>
        <MenuItem className="menu-link" onClick={handleNav}>
          <img src={logouticon} alt="Logout" className="me-2" /> Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default React.memo(WebAdminNavigation);
