import { useState } from "react";
import { Backdrop, Box, CircularProgress, Modal, Button } from "@mui/material";
import { PostCallApi } from "../../../../../Action/Action";
import Pagination from "../../../../../Components/Reusable/Paginations/Pagination";
import "./index.scss";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import {
  SwalMessage,
  SwalSuccess,
} from "../../../../../Components/Reusable/SwalMessage";
import { useLocation } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 800 : "90%",
  maxHeight: "80%",
  overflow: "auto",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

export function SearchMyChildren(props) {
  const { open, setOpen, fields, handleAdd, selectDist } = props;
  const [selectedDate, setSelectedDate] = useState();
  const [studentId, setStudentId] = useState();
  const [oldList, setOldList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecord, setTotalRecord] = useState(0);
  const { pathname } = useLocation();
  function convert(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const handleDateChnage = async (date, stdId, pag) => {
    setOldList([]);
    let p = pathname?.split("/")[1];
    let is_success = true;
    if (p === "submitted-surveys") {
      if (!date) {
        is_success = false;
        await SwalMessage("Please select birth date");
      }
    }
    if (is_success) {
      setOpenCircle(true);

      let passData = {
        pageNumber: pag?.page ? pag?.page : 1,
        pageSize: pag?.no ? pag?.no : 10,
        dob: date ? date : "",
        studentId: stdId ? stdId : "",
        schoolDistrictId: selectDist,
      };

      let apiSend = {
        url: `/api/Survey/GetStudentByDOBandStudentId`,
        body: passData,
      };

      const response = await PostCallApi(apiSend);
      setOpenCircle(false);
      if (response?.status === 200 && response?.data?.data?.success) {
        let crAr = fields?.map((x) => x?.studentGuidId);
        let getStudent = response?.data?.data?.payload
          // ?.filter((c) => !crAr?.includes(c?.studentGuidId))
          ?.map((x, ind) => {
            let pn = passData?.pageNumber - 1;
            return {
              ...x,
              added: crAr?.includes(x?.studentGuidId) ? true : false,
              id: pn * passData?.pageSize + ind + 1,
              birthDate: x?.birthDate
                ? convert(x?.birthDate?.split("T")[0])
                : "",
            };
          });
        setTotalRecord(response?.data?.data?.totalRecords);
        setOldList(getStudent ? getStudent : []);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (page, no) => {
    let pno = { page: page, no: no };
    handleDateChnage(selectedDate, studentId, pno);
    setCurrentPage(page);
  };

  const handleAddForOld = (item) => {
    handleAdd({ ...item, bg: "#e8e8e8" });
    SwalSuccess("Student Added Successfully");
    setOldList(
      oldList?.map((x) => {
        if (x?.studentGuidId === item?.studentGuidId) {
          return {
            ...x,
            added: true,
          };
        }
        return x;
      })
    );
  };

  return (
    open && (
      <>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="student_deatils_modal"
          sx={{ zIndex: "99" }}
        >
          <Box sx={style} className="delete_data_box">
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => 9999 }}
              open={openCircle}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <div
              className="popup_delete_detail_box"
              style={{
                display: "inline-block",
                overflow: "auto",
                height: "100%",
              }}
            >
              <h6 className="d-flex align-items-center">
                <div>Suggestion List</div>
                <Button
                  className="ms-auto cancel_btn"
                  sx={{
                    minWidth: "fit-content",
                    padding: "0.5rem !important",
                  }}
                  onClick={handleClose}
                >
                  <RxCross2 />
                </Button>
              </h6>
              <div className="p-4">
                <div className="row main_div">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Birth Date</label>
                      <input
                        type="date"
                        className="form-control"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e?.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">Student Id</label>
                      <input
                        type="text"
                        placeholder="Enter Student Id"
                        className="form-control"
                        value={studentId}
                        onChange={(e) => setStudentId(e?.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-auto">
                    <div className="form-group">
                      <Button
                        className="theme_btn"
                        onClick={() =>
                          handleDateChnage(selectedDate, studentId, {
                            page: currentPage,
                            no: pageSize,
                          })
                        }
                      >
                        Apply Filter
                      </Button>
                      <Button
                        type="button"
                        className="cancel_btn ms-3"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </div>
                  </div>

                  <div className="  table-responsive">
                    {/* <h5 className="py-3">Suggestion List</h5> */}
                    <table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Date of Birth</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Student ID</th>
                          {/* <th>School District</th> */}
                          <th>School</th>
                          <th>Grade</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {oldList?.length > 0 &&
                          oldList?.map((item, ind) => (
                            <tr key={ind}>
                              <td>{item?.id}</td>
                              <td>{item?.birthDate}</td>
                              <td>{item?.firstName}</td>
                              <td>{item?.lastName}</td>
                              <td>{item?.studentId}</td>
                              {/* <td>{item?.schoolDistrictName}</td> */}
                              <td>{item?.schoolName}</td>
                              <td>{item?.grade}</td>
                              {item?.added === false ? (
                                <td>
                                  <Button
                                    className="theme_btn"
                                    onClick={() => handleAddForOld(item)}
                                    sx={{
                                      minWidth: "fit-content",
                                      padding: "0.5rem !important",
                                    }}
                                  >
                                    <AiOutlinePlus className="icon" />
                                    {/* Add */}
                                  </Button>
                                </td>
                              ) : (
                                <td>
                                  {/* <Button
                                    style={{ cursor: "auto" }}
                                    className="cancel_btn"
                                  >
                                    Added
                                  </Button> */}
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {oldList?.length === 0 ? (
                      <div className="er_nodata">No Data Found</div>
                    ) : (
                      ""
                    )}
                    {oldList?.length > 0 && (
                      <div className="pagi_nation_survey mb-3">
                        <div className="rowsPerPageOptions">
                          <span className="p_18_text">show</span>
                          <select
                            className="pagination_select p_16_text"
                            value={pageSize}
                            onChange={(e) => {
                              setPageSize(parseInt(e.target.value));
                              handlePageChange(1, parseInt(e.target.value));
                              // setCurrentPage(1);
                            }}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                          </select>
                          <span className="p_18_text">entries</span>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={totalRecord}
                          pageSize={pageSize}
                          onPageChange={(page) =>
                            handlePageChange(page, pageSize)
                          }
                        // onPageChange={(page) => setCurrentPage(page)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </>
    )
  );
}
