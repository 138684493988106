import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableSortLabel,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import Pagination from "../../../../../Components/Reusable/Paginations/Pagination";
import Select from "react-select";
import Swal from "sweetalert2";
import {Config} from "../../../../../Utils/Config";
import defaultProfile from "../../../../../Assets/default_profile.jpg";
import ImgPopup from "../../../../../Components/Reusable/ImgPopup";
import CropNewModel from "./CropNewModel";
import uploadimg from "../../../../../Assets/SvgImage/file-upload.svg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const columns = [
  { field: "col7", headerName: "Logo" },
  { field: "col0", headerName: "Product Name" },
  { field: "col6", headerName: "Price" },
  { field: "col1", headerName: "Category" },
  { field: "col2", headerName: "Sub Category" },
  // { field: "col3", headerName: "IOC* Category" },
  // { field: "col4", headerName: "GTIN" },
  // { field: "col5", headerName: "UPC" },
  // { field: "col6", headerName: "Description" },
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  ".Mui-active": {
    color: "#fff !important",
    ".MuiSvgIcon-root": {
      color: "#fff !important",
    },
  },
}));
function CreateMeals() {
  const { id } = useParams();
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [schList, setSchList] = useState();
  const [selectUser, setSelectUser] = useState([]);
  const [prdSelect, setPrdselect] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecord, setTotalRecord] = useState(0);
  const [overlayList, setOverlayList] = useState([]);
  const [overlaySelect, setOverlaySelect] = useState([]);
  const [derror, setDerror] = useState();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("MealImageURL", url);
        setValue("logoUpload1", url.name);
        setCroppedImageUrl(url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  useEffect(() => {
    getSchoolList();
    // getOverListData();
    // GetProductData();
    // eslint-disable-next-line
  }, []);
  const onSubmit = (data) => {
    let is_success = true;
    if (overlaySelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one overlay" });
    }
    if (is_success) {
      setDerror({ err: "" });
      const user = selectUser?.filter((item) => {
        if (item.add === true) {
          return item.productId;
        }
        return 0;
      });
      let sendData = {
        ...data,
        productId: user?.map((item) => item.productId),
        createdBy: LogDetail?.userId,
        overLayId: overlaySelect,
        nameOfDay: data?.nameOfDay
          ? data?.nameOfDay
          : new Date().toLocaleDateString("en-US", { weekday: "long" }),
      };
      setOpenCircle(true);
      Axios.post("/api/Meals/CreateMeals", sendData, {
        headers,
      })
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setOpenCircle(false);
              Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: res.data?.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "#48953e",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
              if (data.MealImageURL) {
                let imgData = {
                  MealId: res.data.payload?.mealId,
                  MealImageURL: data.MealImageURL,
                };
                Axios.post(`api/Meals/SaveMealImage`, imgData, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                    Accept: "*/*",
                  },
                });
              }
              // reset();
              navigate(-1);
            } else {
              setOpenCircle(false);
            }
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };
  useEffect(() => {
    if (schList?.length > 0) {
      if (id) {
        EditMeal(id);
      }
    }
    // eslint-disable-next-line
  }, [schList, id]);

  const [prl, setPrl] = useState(false);
  // const [prOvl, setPrOvl] = useState(false);
  const EditMeal = (id) => {
    Axios.get(
      `/api/Meals/GetMealsDataByID?mealId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let meData = res.data.payload;
            setValue("mealId", meData.mealId);
            setValue("mealTitle", meData.mealTitle);
            setValue("schoolDistrictId", meData.schoolDistrictId);
            setValue("mealType", meData.mealType);
            if (meData?.mealType) {
              setDis(true);
            }
            setValue("nameOfDay", meData.nameOfDay);
            setValue("itemType", meData.itemType);
            setValue("logoUpload1", meData.imageUrl);
            setValue("isReimbursableMeal", meData.isReimbursableMeal);
            setValue("isDefaultIPOStem", meData.isDefaultIpostem);
            setValue("points", meData?.points);
            setValue(
              "mealPrice",
              (Math.round(meData.mealPrice * 100) / 100).toFixed(2)
            );
            getOverListData(
              meData?.schoolDistrictId,
              meData.mealType === "All" ? "all" : meData?.mealType
            );
            setOverlaySelect(meData?.overLayId);
            setPrdselect(meData?.mealTransactionlst);
            setPrl(true);
            // setPrOvl(true);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  // useEffect(() => {
  //   if (overlayList?.length > 0) {
  //     setPrOvl(false);
  //   }
  //   // eslint-disable-next-line
  // }, [overlayList, prOvl]);
  useEffect(() => {
    if (prl) {
      if (prdSelect?.length > 0) {
        if (selectUser?.length) {
          let UList = selectUser?.map((x) => {
            let val = prdSelect?.filter((vx) => vx.productId === x.productId);
            return {
              ...x,
              add: val?.length > 0 ? true : false,
            };
          });
          let allMainCh = true;
          UList?.map((as, i) => {
            if (as.add === false) {
              allMainCh = false;
            }
            return 0;
          });
          if (allMainCh) {
            setCheckBoxVal(true);
          } else {
            setCheckBoxVal(false);
          }
          setSelectUser(UList);
          setPrl(false);
        }
      }
    }
  }, [prdSelect, selectUser, prl]);

  const getOverListData = (id, ms) => {
    if (id && ms) {
      Axios.get(
        `/api/OverLay/GetOverLayDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}&mealService=${ms}`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload?.map((sd, i) => {
                return { label: sd.overlayName, value: sd.overlayId };
              });
              setOverlayList(SdData);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setOverlayList([]);
    }
  };
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("col0");
  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);

    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className="head_fix">
        <TableRow>
          <StyledTableCell>
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                disabled={selectUser?.length === 0 ? true : false}
                checked={checkBoxVal}
                onChange={(e) => {
                  handleAll(e.target.checked);
                  setCheckBoxVal(e.target.checked);
                }}
              />
              <span className="checkmark"></span>
            </label>
            Add
          </StyledTableCell>
          {columns?.map((headCell) => {
            return (
              <StyledTableCell
                key={headCell?.field}
                align="left"
                sortDirection={orderBy === headCell?.field ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell?.field}
                  direction={orderBy === headCell?.field ? order : "asc"}
                  onClick={createSortHandler(headCell?.field)}
                >
                  {headCell.headerName}
                  {orderBy === headCell?.field ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  const getSchoolList = () => {
    let url = `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`;
    Axios.get(url, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            const data = response?.data.payload?.map((item) => {
              return {
                schId: item?.schoolDistrictId,
                schName: item?.districtName,
              };
            });
            setSchList(data);
          }
        }
      })
      .catch((error) => console.log(error));
  };
  const GetProductData = () => {
    setOpenCircle(true);
    let url = `/api/Product/GetALLProductData`;
    let sendData = {
      pageNumber: currentPage ? currentPage : 1,
      pageSize: pageSize ? pageSize : 20,
    };
    Axios.post(url, sendData, { headers })
      .then((response) => {
        if (response.data.data.success) {
          setOpenCircle(false);

          setCheckBoxVal(false);
          const data = response?.data.data.payload?.map((item, i) => {
            return {
              no: i,
              add: false,
              productId: item?.productId,
              col0: <div className="product_name">{item?.productName}</div>,
              col1: item?.category,
              col2: item?.subCategory,
              col3: item?.ioccategory,
              col4: item?.gtin,
              col5: item?.upc,
              // col6: (Math.round(item?.price * 100) / 100).toFixed(2),
              col6: item?.price?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              col7: (
                <ImgPopup
                  img={
                    item?.productImageUrl1
                      ? `${Config.API_HOST_URL_live}${item?.productImageUrl1}`
                      : defaultProfile
                  }
                />
              ),
            };
          });
          setSelectUser(data);
          setTotalRecord(response.data.data.totalRecords);
        } else {
          setOpenCircle(false);
          setSelectUser([]);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const handleAll = (val) => {
    const s = selectUser?.map((item) => {
      return {
        ...item,
        add: val,
      };
    });
    setSelectUser(s);
  };
  const handleMainCheck = (e, index, item) => {
    let allMainCh = true;
    const s = selectUser?.filter((se, i) => se.no !== index);
    let select = {
      ...item,
      no: index,
      add: e,
    };
    let arr = s;
    arr.splice(index, 0, select);
    setSelectUser(arr);
    if (e) {
      arr?.map((as, i) => {
        if (as.add === false) {
          allMainCh = false;
        }
        return 0;
      });
      if (allMainCh) {
        setCheckBoxVal(true);
      } else {
        setCheckBoxVal(false);
      }
    } else {
      setCheckBoxVal(false);
    }
  };
  useEffect(() => {
    if (pageSize) {
      if (currentPage) {
        GetProductData();
      }
    }
    // eslint-disable-next-line
  }, [pageSize, currentPage]);
  const handlePageChange = (page, no) => {
    setCurrentPage(page);
    setPageSize(no);
  };
  const handleIOCMulti = (e) => {
    setOverlaySelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const [dis, setDis] = useState(false);
  const handleCheck = (e) => {
    if (e === "All") {
      setValue("itemType", "Ala Carte");
      setDis(true);
      getOverListData(getValues("schoolDistrictId"), "all");
    } else {
      if (e) {
        setDis(true);
        setValue("itemType", "Meal");
        getOverListData(getValues("schoolDistrictId"), e);
      } else {
        setDis(false);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_meals">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">
                {id ? "Edit" : "Create"} POS Items
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          POS Items Title <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="POS Items Title"
                          {...register("mealTitle", {
                            required: "This field is required",
                          })}
                        />
                        {errors.mealTitle && (
                          <span className="formError errorMssg error_text">
                            {errors.mealTitle.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group ">
                        <label className="form-label">Upload POS Items</label>
                        <div className="upload_img">
                          <input
                            type="text"
                            className="form-control upload_box"
                            placeholder="No file Chosen"
                            readOnly
                            {...register("logoUpload1", {})}
                          />
                          <input
                            type="file"
                            id="school_img_upload_1"
                            accept="image/*"
                            className="form-control"
                            style={{ display: "none" }}
                            onChange={handleFile}
                          />
                          <label
                            htmlFor="school_img_upload_1"
                            className="upload_img_label mb-0 "
                          >
                            <img src={uploadimg} className="img-fluid" alt="" />
                          </label>
                        </div>
                        {errors?.logoUpload && (
                          <span role="alert" className="error_text">
                            {errors.logoUpload.message}
                          </span>
                        )}
                        {src && (
                          <CropNewModel
                            src={src}
                            filename={filename}
                            setCroppedImageUrl={setCroppedImageUrlCallback}
                            openCropModal={openCropModal}
                            setCropModal={setCropModal}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) =>
                              getOverListData(
                                e.target.value,
                                getValues("mealType")
                              ),
                          })}
                        >
                          <option value="">Select School District</option>
                          {schList &&
                            schList?.map((data, i) => (
                              <option value={data?.schId} key={i}>
                                {data?.schName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span className="formError errorMssg error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          POS Service <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("mealType", {
                            required: "This field is required",
                            onChange: (e) => handleCheck(e?.target?.value),
                          })}
                        >
                          <option value="">Select POS Service </option>
                          <option value="All">All</option>
                          <option value="Breakfast">Breakfast</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.mealType && (
                          <span className="formError errorMssg error_text">
                            {errors.mealType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          POS Item <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          disabled={dis}
                          {...register("itemType", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select POS Item </option>
                          <option value="Meal">Meal</option>
                          <option value="Ala Carte">Ala Carte</option>
                        </select>
                        {errors.itemType && (
                          <span className="formError errorMssg error_text">
                            {errors.itemType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Day <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("nameOfDay", {
                            required: "This field is required",
                          })}
                        >
                          <option value="Monday">Monday</option>
                          <option value="Tuesday">Tuesday</option>
                          <option value="Wednesday">Wednesday</option>
                          <option value="Thursday">Thursday</option>
                          <option value="Friday">Friday</option>
                          <option value="Saturday">Saturday</option>
                          <option value="Sunday">Sunday</option>
                        </select>
                        {errors.nameOfDay && (
                          <span className="formError errorMssg error_text">
                            {errors.nameOfDay.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          POS Item Price <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="POS Item Price"
                          {...register("mealPrice", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid ceu the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                            },
                          })}
                        />
                        {errors.mealPrice && (
                          <span className="formError errorMssg error_text">
                            {errors.mealPrice.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Item Points
                        </label>
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          placeholder="Item Points"
                          {...register("points", {
                            valueAsNumber : true
                            // required: "This field is required",
                          })}
                        />
                        {errors.points && (
                          <span className="formError errorMssg error_text">
                            {errors.points.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Overlay <span className="red-color">*</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={overlayList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select Overlay"
                          value={overlayList?.filter((obj) =>
                            overlaySelect?.includes(obj.value)
                          )}
                          onChange={handleIOCMulti}
                          style={{ width: "100%" }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 d-flex gap-4 checkbox_meal">
                      <div className="form-group">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            {...register("isReimbursableMeal")}
                          />
                          <span className="checkmark"> </span>
                          Is Reimbursable Meal{" "}
                        </label>
                      </div>
                      <div className="form-group">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            {...register("isDefaultIPOStem")}
                          />
                          <span className="checkmark"> </span>
                          Is Default POS Items{" "}
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                    </div> */}
                    <div className="table-reponsive table_fix table-box mt-3">
                      <div className="page_nation">
                        <div className="rowsPerPageOptions">
                          <span className="p_18_text">show</span>
                          <select
                            className="pagination_select p_16_text"
                            value={pageSize}
                            onChange={(e) => {
                              setPageSize(parseInt(e.target.value));
                              handlePageChange(1, parseInt(e.target.value));
                            }}
                          >
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="500">500</option>
                          </select>
                          <span className="p_18_text">entries</span>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={totalRecord}
                          pageSize={pageSize}
                          onPageChange={(page) =>
                            handlePageChange(page, pageSize)
                          }
                        />
                      </div>
                      <Table className="table-striped">
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                        />

                        <TableBody>
                          {stableSort(
                            selectUser,
                            getComparator(order, orderBy)
                          )?.map((item, index) => {
                            return (
                              <tr key={item?.no}>
                                <StyledTableCell className="column_data">
                                  <label className="personal_detail_label">
                                    <input
                                      type="checkbox"
                                      className="login_check"
                                      checked={item.add}
                                      onChange={(e) => {
                                        handleMainCheck(
                                          e.target.checked,
                                          item.no,
                                          item
                                        );
                                      }}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </StyledTableCell>
                                {columns?.map((column) => {
                                  const value = item[column?.field];

                                  return (
                                    <StyledTableCell
                                      className="column_data"
                                      key={column?.field}
                                    >
                                      {column?.format &&
                                      typeof value === "number"
                                        ? column?.format(value)
                                        : value}
                                    </StyledTableCell>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                    <div className="col-md-12 text-end mt-3">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateMeals;
