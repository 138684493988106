import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../../../Action/Action";

const ListTable = lazy(() =>
    import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
    import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const academicHead = [
    { id: "academicYear", label: "Academic Year" },
    { id: "isCurrentYear", label: "Is Current Year" },
]

function SchoolAcademicYearList() {
    const [open, setOpen] = useState(false);
    const [academicList, setAcademicList] = useState([]);

    let deleteInfo = useSelector((state) => state.Common?.DeleteData);

    useEffect(() => {
        getAcademicYearList();
        // eslint-disable-next-line
    }, [deleteInfo]);

    const getAcademicYearList = async () => {
        setAcademicList([]);
        setOpen(true);
        let seData = {
            url: `/api/SchoolAcademicYear/GetAllSchoolAcademicYearData`,
        };

        let resp = await GetCallApi(seData);
        setOpen(false);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                const sdData = resp.data.payload?.map((c) => {
                    return {
                        ...c,
                        isCurrentYear: c?.isCurrentYear === true ? "True" : "False",
                        Action: (
                            <>
                                <Link
                                    type="button"
                                    className="btn_edit me-2"
                                    to={`edit/${c?.academicYearId}`}
                                >
                                    <MdEdit fontSize={24} />
                                </Link>
                                <DeletePopup
                                    title={"Delete Academic Year"}
                                    text={c?.academicYear}
                                    url={`/api/SchoolAcademicYear/DeleteSchoolAcademicYearData?academicYearId=${c?.academicYearId}`}
                                />
                            </>
                        ),
                    };
                });
                setAcademicList(sdData);
            } else {
                setOpen(false);
            }
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="schoolAcademicYear_listpage">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="product_box ">
                                    <h4 className="all_title mb-0">School Academic Year List</h4>
                                    <div className="d-flex">
                                        <Link to={"create"} className="theme_btn">
                                            <BsPlusSquareDotted fontSize={24} className="me-3" />
                                            Create
                                        </Link>
                                    </div>
                                </div>
                                <ListTable
                                    headCells={academicHead}
                                    action={true}
                                    ListData={academicList}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default SchoolAcademicYearList;
