import React, { Suspense, lazy, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Typography } from "@mui/material";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import filterIcon from "../../../../../Assets/SvgImage/filter.svg";

const EmailTable = lazy(() => import("./EmailTable"));

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const templateHead = [
    { id: "studentName", label: "Student Name" },
    { id: "gradeId", label: "Grade" },
    { id: "parentName", label: "Parent Name" },
    { id: "email", label: "Parent Email" },
    { id: "primaryPhoneNumber", label: "Parent Phone" },
];

function SendCommunicateEmails() {

    const [prdList, setPrdList] = useState([]);
    const [parentList, setParentList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schoolSelect, setSchoolSelect] = useState([]);
    const [tempList, setTempList] = useState([]);
    const [emailId, setEmailId] = useState([]);
    const [distId, setDistId] = useState([]);
    const [openCircle, setOpenCircle] = React.useState(false);
    const [checkBox, setCheckbox] = useState(false);
    const [open, setOpen] = useState(false);
    const [derror, setDerror] = useState();
    const [derror1, setDerror1] = useState();
    // const [derror2, setDerror2] = useState();
    // const [derror3, setDerror3] = useState();
    const [allergen, setAllergen] = useState([]);
    const [template, setTemplate] = useState("")
    // const [time, setTime] = useState("")
    // const [date, setDate] = useState("")

    const { handleSubmit, register, setValue, formState: { errors } } = useForm()

    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    useEffect(() => {
        getDistrictList();
        getTemplateList();
        getAllergenList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (emailId?.length > 0) {
            changeTbData()
        } else {
            changeTbData()
        }
        // eslint-disable-next-line
    }, [emailId])

    // useEffect(() => {
    //     if (distId) {
    //         if (schoolSelect) {
    //             if (stdType) {
    //                 getCustList()
    //             } else {
    //                 setPrdList([])
    //             }
    //         }
    //     } else {
    //         setPrdList([])
    //     }
    //     // eslint-disable-next-line
    // }, [distId, schoolSelect, stdType])

    const getDistrictList = async () => {
        setParentList([])
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setParentList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getTemplateList = async () => {
        setTempList([])
        let seData1 = {
            url: `/api/Template/GetTemplateList?schoolDistrictId=${LogDetail?.schoolDistrictId}`,
            headers: headers,
        };
        let resp = await GetCallApi(seData1);
        if (resp?.status === 200) {
            if (resp.data.success) {
                const dlist = resp.data.payload?.map((sd) => {
                    return {
                        ...sd,
                    };
                });
                setTempList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getAllergenList = async () => {
        setAllergen([])
        let seData2 = {
            // url: `/api/AllergenGroup/GetAllergenGroupData`,
            url: `/api/Common/GetAllergenGroupList`,
            headers: headers
        }
        let respo = await GetCallApi(seData2)
        if (respo?.status === 200) {
            if (respo.data.success) {
                const alList = respo.data.payload?.map((as) => {
                    return {
                        ...as
                    }
                })
                setAllergen(alList?.length > 0 ? alList : [])
            }
        }
    }

    const getSchoolList = async (id) => {
        setDistId(id)
        setSchoolList([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);
            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    // let uslist = [...dlist];
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 0 ? uslist : []);
                }
            }
        } else {
            setSchoolList([]);
        }
    };

    const handleAddProduct = (val, eId) => {
        if (val) {
            setEmailId((old) => [...old, eId])

        } else {
            setCheckbox(false)
            setEmailId((old) => old?.filter((pre) => pre !== eId))
        }
    }

    const handleCheckALL = (v, data) => {
        if (v) {
            setEmailId(data?.map((d) => d?.num))
            setCheckbox(true)
        } else {
            setEmailId([])
            setCheckbox(false)
        }
    }


    const getCustList = async (data) => {
        setCheckbox(false);
        setPrdList([])
        if (schoolSelect?.length === 0) {
            setDerror({ sch: "This field is required" })
        } else {
            setOpen(true)
            setDerror({ sch: "" })
            let seData = {
                url: `/api/CommunicationStudent/GetCommunicationStudentData`,
                body: {
                    ...data,
                    schoolDistrictId: distId ? distId : "",
                    schoolId: schoolSelect ? schoolSelect?.map((s) => s?.value) : [],
                    allergenGroupId: data?.allergenGroupId ? data?.allergenGroupId : null
                },
                headers: headers,
            };
            let res = await PostCallApi(seData);
            setOpen(false)
            if (res?.status === 200) {
                if (res?.data.success) {
                    let d = res?.data?.payload?.map((x, i) => {
                        return {
                            ...x,
                            num: i + 1,
                            Action: (
                                <label className="personal_detail_label">
                                    <input
                                        type="checkbox"
                                        className="login_check"
                                        name="Remember Me"
                                        checked={false}
                                        onChange={(e) =>
                                            handleAddProduct(e?.target?.checked, i + 1)
                                        }
                                    />
                                    <span className="checkmark"></span>
                                </label>
                            ),
                        };
                    });
                    setPrdList(d);
                }
            }
        }
    };

    const changeTbData = () => {
        let newData = prdList?.map((pd) => {
            return {
                ...pd,
                add: emailId?.filter((x) => x === pd?.num)?.length > 0
                    ? true
                    : false,
                Action: (
                    <label className=" personal_detail_label">
                        <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            checked={
                                emailId?.filter((x) => x === pd?.num)?.length > 0
                                    ? true
                                    : false
                            }
                            onChange={(e) =>
                                handleAddProduct(e?.target?.checked, pd?.num)
                            }
                        />
                        <span className="checkmark"></span>
                    </label>
                )
            }
        })
        setPrdList(newData)
        let ch = true;
        if (ch) {
            let as = newData?.map((x) => {
                if (!x?.add) {
                    return (ch = false);
                }
                return undefined;
            });
            if (ch && newData?.length > 0) {
                setCheckbox(true);
            } else {
                setCheckbox(false);
            }
            ch = as; //for warning remove
        }

    }

    const handleMulti = (e) => {
        if (e) {
            setSchoolSelect(e);
        } else {
            setPrdList([])
        }
    };

    const handleSendEmail = async () => {
        if (template?.length === 0) {
            setDerror1({ temp: "This field is required" })
        } else {
            setDerror1({ temp: "" })
            if (emailId?.length === 0) {
                setDerror({ id: "Select minimum one student" })
            } else {
                setDerror({ id: "" })
                setOpenCircle(true);

                let filteredData = prdList?.filter((pr) => emailId?.includes(pr?.num));

                let sendData = {
                    templateId: template,
                    parentsDatas: filteredData?.map((pId) => {
                        return {
                            parentGuidId: pId?.parentsGuidId,
                            studentGuidId: pId?.studentGuidId,
                        }
                    }),
                    createdBy: LogDetail?.userId,
                    // conforenceDate: date,
                    // conferenceTime: time,
                }

                let apiUrl = {
                    url: `/api/CommunicationStudent/SendEmailForCommunicationStudent`,
                    body: sendData,
                    headers: headers
                }

                const resp = await PostCallApi(apiUrl);
                setOpenCircle(false);
                if (resp?.status === 200) {
                    if (resp.data.success) {
                        Swal.fire({
                            position: "top-end",
                            width: 400,
                            // icon: "success",
                            text: resp.data?.message,
                            showConfirmButton: false,
                            timer: 1500,
                            color: "#ffffff",
                            background: "#48953e",
                            showClass: {
                                popup: "animate__animated animate__fadeInDown",
                            },
                            hideClass: {
                                popup: "animate__animated animate__fadeOutRight",
                            },
                        });
                        setPrdList([])
                        setEmailId([])
                        setSchoolList([])
                        setSchoolSelect([])
                        setTemplate("")
                        // setDate("")
                        // setTime("")
                        setValue("distId", "")
                        setValue("studentType", "")
                        setValue("isDC", "")
                        setValue("showNegativeBalance", "")
                        setValue("allergenGroupId", "")
                        setValue("status", "")
                        setValue("grades", "")
                        setValue("homeRoom", "")
                    } else {
                        Swal.fire({
                            position: "top-end",
                            width: 400,
                            // icon: "success",
                            text: resp?.data?.message
                                ? resp?.data?.message
                                : "Something went wrong please try again.",
                            showConfirmButton: false,
                            timer: 2000,
                            color: "#ffffff",
                            background: "red",
                            showClass: {
                                popup: "animate__animated animate__fadeInDown",
                            },
                            hideClass: {
                                popup: "animate__animated animate__fadeOutRight",
                            },
                        });
                    }
                } else {
                    Swal.fire({
                        position: "top-end",
                        width: 400,
                        // icon: "success",
                        text: resp?.data?.message
                            ? resp?.data?.message
                            : "Something went wrong please try again.",
                        showConfirmButton: false,
                        timer: 2000,
                        color: "#ffffff",
                        background: "red",
                        showClass: {
                            popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                            popup: "animate__animated animate__fadeOutRight",
                        },
                    });
                }
            }
        }
    }

    return (
        <>
            {/* <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openCircle}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop> */}
            <Suspense fallback={renderLoader()}>
                <section className="communicate_list">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(getCustList)}>
                                    <div className="product_box">
                                        <div className="all_title ProductTitle">Send Communication Email</div>
                                    </div>
                                    <div className="row">
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<GridExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography>
                                                    <img src={filterIcon} alt="filter" />
                                                    <span className="filter_by">Filter By</span>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>School District <span className="red-color">*</span></label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("distId", {
                                                                    required: "This field is required",
                                                                    onChange: (e) => {
                                                                        getSchoolList(e.target.value);
                                                                        setSchoolSelect([])
                                                                    }
                                                                })}
                                                            >
                                                                <option value="">Select School District</option>
                                                                {parentList &&
                                                                    parentList?.map((data, i) => (
                                                                        <option key={i} value={data?.value}>
                                                                            {data?.label}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                            {errors?.distId && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.distId.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>School <span className="red-color">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="type"
                                                                options={schoolList}
                                                                className="form-neselect"
                                                                classNamePrefix="select"
                                                                placeholder="Select School"
                                                                value={schoolSelect ? schoolSelect : null}
                                                                onChange={(selected) => {
                                                                    true &&
                                                                        selected.length &&
                                                                        selected.find(
                                                                            (option) => option.value === "all"
                                                                        )
                                                                        ? handleMulti(schoolList.slice(1))
                                                                        : !true
                                                                            ? handleMulti(
                                                                                (selected && selected.value) || null
                                                                            )
                                                                            : handleMulti(selected);
                                                                }}
                                                                style={{ width: "100%" }}
                                                            />
                                                            {derror?.sch && (
                                                                <span role="alert" className="d-block error_text">
                                                                    {derror?.sch}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>Household Type
                                                            </label>
                                                            {/* <span className="red-color">*</span> */}
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("studentType", {
                                                                    // required: "This field is required",
                                                                })}
                                                            >
                                                                <option value="">Select HouseHold</option>
                                                                <option value="All">Both</option>
                                                                <option value="Primary">Primary</option>
                                                                <option value="Secondary">Secondary</option>
                                                            </select>
                                                            {errors?.studentType && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.studentType.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Is Dc
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("isDC", {
                                                                    // required: "This field is required",

                                                                })}
                                                            >
                                                                <option value="">Select Is Dc</option>
                                                                <option value="true">True</option>
                                                                <option value="false">False</option>
                                                            </select>
                                                            {errors.isDC && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.isDC.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Show Negative Balance
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("showNegativeBalance", {
                                                                    // required: "This field is required",
                                                                })}
                                                            >
                                                                <option value="">Select Negative Balance</option>
                                                                <option value="true">True</option>
                                                                <option value="false">False</option>
                                                            </select>
                                                            {errors.showNegativeBalance && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.showNegativeBalance.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Allergen Group
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("allergenGroupId", {
                                                                    // required: "This field is required",
                                                                })}
                                                            >
                                                                <option value="">Select Allergen Group</option>
                                                                {allergen && allergen?.map((a, i) => {
                                                                    return (
                                                                        <option value={a?.allergenGroupId} key={i}>{a?.allergenGroupName}</option>
                                                                    )
                                                                })}
                                                            </select>
                                                            {errors.allergenGroupId && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.allergenGroupId.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Status
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Status"
                                                                {...register("status", {
                                                                    // required: "This field is required",
                                                                })}
                                                            />
                                                            {errors.status && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.status.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Grades
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Grades"
                                                                {...register("grades", {
                                                                    // required: "This field is required",
                                                                })}
                                                            />
                                                            {errors.grades && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.grades.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Home Room
                                                                {/* <span className="red-color">*</span> */}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Home Room"
                                                                {...register("homeRoom", {
                                                                    // required: "This field is required",
                                                                })}
                                                            />
                                                            {errors.homeRoom && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.homeRoom.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                            <div className="ms-auto mt-auto col-md-4">
                                                <div className="form-group text-end filter-img">
                                                    {open ? (
                                                        <Button className="theme_btn text-end me-3">
                                                            Applying Filter{" "}
                                                            <CircularProgress
                                                                sx={{
                                                                    color: "#fff",
                                                                    marginLeft: "0.5rem",
                                                                    height: "23px !important",
                                                                    width: "23px !important",
                                                                }}
                                                            />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className="theme_btn text-end me-3"
                                                            type="submit"
                                                        >
                                                            Apply
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </Accordion>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Template <span className="red-color">*</span></label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    value={template}
                                                    onChange={(e) => setTemplate(e?.target?.value)}
                                                >
                                                    <option value="">Select Template</option>
                                                    {tempList &&
                                                        tempList?.map((t, i) => {
                                                            return (
                                                                <option key={i + 1} value={t?.templateId}>
                                                                    {t?.templateTitle}
                                                                </option>
                                                            );
                                                        })}
                                                </select>
                                                {derror1?.temp && (
                                                    <span role="alert" className="d-block error_text">
                                                        {derror1?.temp}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Date <span className="red-color">*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    value={date}
                                                    onChange={(e) => setDate(e?.target?.value)}
                                                />
                                                {derror2?.date && (
                                                    <span role="alert" className="d-block error_text">
                                                        {derror2?.date}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Time <span className="red-color">*</span>
                                                </label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    value={time}
                                                    onChange={(e) => setTime(e?.target?.value)}
                                                />
                                                {derror3?.time && (
                                                    <span role="alert" className="d-block error_text">
                                                        {derror3?.time}
                                                    </span>
                                                )}
                                            </div>
                                        </div> */}
                                        <div className=" ms-auto mt-auto col-md-6">
                                            <div className="form-group text-end filter-img">
                                                {openCircle ? (
                                                    <Button className="theme_btn text-end">
                                                        Sending Email
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <>
                                                        <Button className="theme_btn text-end" onClick={handleSendEmail}>
                                                            Send Email
                                                        </Button>
                                                        {derror?.id && (
                                                            <span role="alert" className="d-block error_text">
                                                                {derror?.id}
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <EmailTable
                                        headCells={templateHead}
                                        action={true}
                                        actionFirst={true}
                                        ListData={prdList}
                                        setCheckbox={setCheckbox}
                                        checkBox={checkBox}
                                        handleCheckALL={handleCheckALL}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>

    );
}

export default SendCommunicateEmails;
