import React, { Suspense, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import Banner from "../../Assets/PngImage/fig_CROP.png";
import "./index.scss";
import { useForm } from "react-hook-form";
import { MdOutlineEmail } from "react-icons/md";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { AiOutlineLock } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Axios from "../../Utils/AxiosConfig";
import { useDispatch } from "react-redux";
import { LoginUser } from "../../reducers/login";
import { LoginHeadMenuList } from "../../reducers/menu";
import { DashboarPopupOpen } from "../../reducers/Common";
import { FaUserTie, FaHouseUser } from "react-icons/fa";
// import CreateSupportTicket from "./CreateSupportTicket";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function Login() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [openCircle, setOpenCircle] = useState(false);
  const [derror, setDerror] = useState();

  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors },
  } = useForm();

  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();
  let pName = location.pathname;

  // API LOGIN
  const onSubmit = (data) => {
    let sendData = { ...data };
    let pName = location.pathname;
    let url = "";
    if (pName === "/parent-login") {
      url = "/api/ParentLogin/ParentLogin";
    } else {
      url = "/api/UserLogin/DoLogin";
    }
    setOpenCircle(true);
    Axios.post(url, sendData)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let newUserD = {
              ...res.data.payload,
              usPs: sendData?.password,
              authenticate: true,
              authenticateStudent: false,
            };
            if (pName === "/parent-login") {
              newUserD = {
                ...newUserD,
                ...newUserD?.stu,
                authenticate: false,
                authenticateStudent: true,
                accessToken: newUserD?.refreshToken,
              };
              delete newUserD?.stu;
            } else {
              newUserD = {
                ...newUserD,
                authenticate: true,
                authenticateStudent: false,
                accessToken: newUserD?.refreshToken,
              };
              getAllocateMenu(newUserD?.userId, newUserD?.accessToken);
              if (newUserD?.roleId === 1007) {
                dispatch(DashboarPopupOpen(true));
              }
            }
            dispatch(LoginUser(newUserD));
            if (pName === "/parent-login") {
              navigate("/parent-dashboard", { replace: true });
            } else {
            }
            setOpenCircle(false);
          } else {
            setDerror({ g_err: res.data.message });
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  // END API LOGIN
  const getAllocateMenu = (uId, token) => {
    Axios.get(
      `/api/RoleMenuAllocation/GetMenuHierarchical?userId=${uId}&menuType=headerMenu`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            dispatch(LoginHeadMenuList(res.data.payload));

            navigate(
              res.data.payload[0]?.subMenu?.length === 0
                ? res.data.payload[0].menuUrl
                : res.data.payload[0]?.subMenu[0]?.menuUrl,
              { replace: true }
            );
          }
        }
      })
      .catch((e) => console.log(e));
  };

  // const loggedIn = useSelector(
  //   (state) => state.login?.LoginDetails?.authenticate
  // );

  // useEffect(() => {
  //   if (loggedIn) {
  //     if (pName === "/parent-login") {
  //       navigate("/parent-dashboard", { replace: true });
  //     } else {
  //       navigate("/admin-dashboard", { replace: true });
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="login_page">
          <div className="row m-0 h-100">
            <div className="col-md-6 p-0 sidebanner">
              <img src={Banner} alt="" className="img-fluid login_banner" />
            </div>
            <div className="col-md-6 m-auto">
              <form onSubmit={handleSubmit(onSubmit)} className="login_box">
                <div className="log_detail">
                  <div className="head_title">
                    {pName === "/" ? "Login" : "Parent Login"}
                  </div>
                  <div className="login_tabing">
                    <Link className={`${pName === "/" ? "active" : ""}`} to="/">
                      <FaUserTie />
                      Login
                    </Link>
                    <Link
                      className={`${pName === "/parent-login" ? "active" : ""}`}
                      to="/parent-login"
                    >
                      <FaHouseUser />
                      Parent Login
                    </Link>
                  </div>
                  <div className="form-group">
                    <label className="form-label label_with_icon">
                      <MdOutlineEmail
                        color="#31B680"
                        fontSize={24}
                        className="me-2"
                      />
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Email "
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please Enter Valid Email",
                        },
                      })}
                    />
                    {errors.email && (
                      <span role="alert" className="error_text">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form-label label_with_icon">
                      <AiOutlineLock
                        color="#31B680"
                        fontSize={24}
                        className="me-2"
                      />
                      Password
                    </label>
                    <div className="d-flex position-relative align-items-center">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        {...register("password", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        {passwordShown ? (
                          <FiEye color="#31B680" />
                        ) : (
                          <FiEyeOff color="#31B680" />
                        )}
                      </span>
                    </div>
                    {errors.password && (
                      <span role="alert" className="error_text">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    {pName === "/parent-login" ? (
                      <Link
                        className="forgot f-18-400"
                        to="/forgot-password-parent"
                      >
                        Forgot Password
                      </Link>
                    ) : (
                      <Link className="forgot f-18-400" to="/forgot-password">
                        Forgot Password
                      </Link>
                    )}
                  </div>
                  <div className="login_btn text-center">
                    <Button type="submit" className="theme_btn w-100">
                      Login
                    </Button>
                    {/* <CreateSupportTicket test={() => reset()} /> */}
                    {derror?.g_err && (
                      <span role="alert" className="error_text">
                        {derror?.g_err}
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Login;
