import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import "./Chart.scss";

export default function Charts(props) {
  
  return (
    <div className="chart_for_all">
      <HighchartsReact highcharts={Highcharts} options={props?.series} />
    </div>
  );
}
