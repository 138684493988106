import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import jsPDF from "jspdf";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Config } from "../../../../../Utils/Config";
import "./index.scss";
import Select from "react-select";

const SmallListTable = lazy(() =>
    import("../../../../../Components/Reusable/SmallListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const productHead = [
    // { id: "allergenGroupName", label: "Allergen Group Name" },
    // { id: "name", label: "Name" },
    { id: "studentName", label: "Student Name" },
    { id: "schoolDistrictName", label: "School District Name" },
    { id: "schoolName", label: "School Name" },
    { id: "allergenData", label: "Allergen Name", widthFix: 600, },
];
function AllergenGroupStudentReport() {
    const [distList, setDistList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [allergenList, setAllergenList] = useState([]);
    const [openCircle, setOpenCircle] = useState(false);
    const [reportDetails, setReportDetails] = useState();
    const [schoolSelect, setSchoolSelect] = useState([]);
    const [derror, setDerror] = useState();
    const {
        register,
        handleSubmit,
        // watch,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getDistrictList();
        // eslint-disable-next-line
    }, []);

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getDistrictList = async () => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);

        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        ...sd,
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setDistList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getSchoolList = async (id) => {
        setSchoolList([]);
        setSchoolSelect([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);

            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            ...sd,
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 1 ? uslist : []);
                }
            }
        } else {
            setSchoolList([]);
        }
    };
    // function convertDate(str) {
    //     var date = new Date(str?.replace(/-/g, "/")),
    //         mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //         day = ("0" + date.getDate()).slice(-2);
    //     return [mnth, day, date.getFullYear()].join("/");
    // }

    const getPayment = async (data) => {
        setAllergenList([])
        let is_success = true;
        if (schoolSelect?.length === 0) {
            is_success = false;
            setDerror({ err: "Select minimum one school" });
        }
        if (is_success) {
            setDerror({ err: "" });
            setOpenCircle(true);
            let sendData = {
                ...data,
                userId: LogDetail?.userId,
                schoolId: schoolSelect?.map((x) => x.value),
            };
            let distName = distList?.filter(
                (x) => x.value === sendData?.schoolDistrictId
            )[0]?.label;
            let schName =
                schoolSelect?.length === schoolList?.length - 1
                    ? "All"
                    : schoolSelect?.map((x) => x.label).toString();

            // let sdate = data?.startDate ? data?.startDate?.toISOString() : null;
            // let edate = data?.endDate ? data?.endDate?.toISOString() : null;
            let reData = {
                // startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
                // endDate: edate ? convertDate(edate?.split("T")[0]) : null,
                distName: distName,
                schName: schName,
            };
            setReportDetails(reData);

            let seData = {
                url: `/api/Report/GetAllergenGroupReport`,
                body: sendData,
                headers: headers,
            };
            let res = await PostCallApi(seData);
            setOpenCircle(false);

            if (res?.status === 200) {
                if (res.data.success) {
                    const dList = res.data.payload?.map((x) => {
                        return {
                            ...x,
                            data: x?.data?.map((y) => {
                                return {
                                    ...y,
                                    allergenData: y?.allergenData?.join(", ")
                                }
                            }),
                        };
                    });
                    setAllergenList(dList);
                }
            }
        }
    };

    const [downloadPdf, setDownloadpdf] = useState(false);
    const [downloadExcel, setDownloadExcel] = useState(false);

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Allergen Group Student Report",
        sheet: "Report",
    });

    let Time = new Date();
    const back = {
        // maxWidth: `100%`,
        maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
        // width: "100%",
        // height: "100%",
        // position: "relative",
        // margin: "0",
    };

    useEffect(() => {
        if (downloadPdf) {
            handleDownloadPdf();
        }
        // eslint-disable-next-line
    }, [downloadPdf]);
    
    useEffect(() => {
        if (downloadExcel) {
            setTimeout(() => {
                onDownload();
                setDownloadExcel(false)
            }, 500);
        }
        // eslint-disable-next-line
    }, [downloadExcel]);

    const handleDownloadPdf = async () => {
        const element = tableRef.current;
        let newPdfDate = Time.toLocaleString();
        // const doc = new jsPDF("px", "px", "a4");
        const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        // let pWidth = doc.internal.pageSize.width; // 595.28 is the width of a4
        // let srcWidth = element.scrollWidth > 756 ? 756 : element.scrollWidth;
        // let margin = 18; // narrow margin - 1.27 cm (36);
        // let scale = (pWidth - margin * 2) / srcWidth;

        doc
            .html(element, {
                margin: [20, 10, 20, 10],
                autoPaging: "text",
                // html2canvas: { scale: scale },
                html2canvas: { scale: 0.57 },
            })
            .then(() => {
                let totalPages = doc.internal.pages.length - 1;
                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setTextColor(150);
                    doc.text(
                        newPdfDate,
                        doc.internal.pageSize.width / 2,
                        doc.internal.pageSize.getHeight() - 10,
                        {
                            align: "center",
                        }
                    );
                }
                doc.save(`Allergen Group Student Report.pdf`);
                setDownloadpdf(false);
            });
    };

    const handleIOCMulti = (e) => {
        setSchoolSelect(e);
    };

    return (
        <>
            {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
            <section className="student_allergen_report_page">
                <div className="main_wrapper">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="all_title mb-4">Allergen Group Student Report</h4>
                            <form onSubmit={handleSubmit(getPayment)}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> District
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                name=""
                                                className="form-select"
                                                {...register("schoolDistrictId", {
                                                    required: "This field is required",
                                                    onChange: (e) => getSchoolList(e.target.value),
                                                })}
                                            >
                                                <option value="">Select School District</option>
                                                {distList &&
                                                    distList?.map((data, i) => (
                                                        <option key={i} value={data?.value}>
                                                            {data?.label}
                                                        </option>
                                                    ))}
                                            </select>
                                            {errors.schoolDistrictId && (
                                                <span role="alert" className="error_text">
                                                    {errors.schoolDistrictId.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> School
                                                <span className="red-color"> *</span>
                                            </label>
                                            <Select
                                                isMulti
                                                name="type"
                                                options={schoolList}
                                                // closeMenuOnSelect={false}
                                                // hideSelectedOptions={false}
                                                className="form-neselect"
                                                classNamePrefix="select"
                                                placeholder="Select School"
                                                value={schoolSelect ? schoolSelect : null}
                                                onChange={(selected) => {
                                                    true &&
                                                        selected.length &&
                                                        selected.find((option) => option.value === "all")
                                                        ? handleIOCMulti(schoolList.slice(1))
                                                        : !true
                                                            ? handleIOCMulti(
                                                                (selected && selected.value) || null
                                                            )
                                                            : handleIOCMulti(selected);
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            {derror?.err && (
                                                <span role="alert" className="d-block error_text">
                                                    {derror.err}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                Start Date <span className="red-color">*</span>
                                            </label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                {...register("startDate", {
                                                    required: "This field is required",
                                                    valueAsDate: true,
                                                })}
                                            />
                                            {errors.startDate && (
                                                <span role="alert" className="error_text">
                                                    {errors.startDate.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                End Date <span className="red-color">*</span>
                                            </label>

                                            <input
                                                type="date"
                                                className="form-control"
                                                {...register("endDate", {
                                                    required: "This field is required",
                                                    valueAsDate: true,
                                                    validate: (val) => {
                                                        if (watch("startDate") > val) {
                                                            return "Your start date can not greater than from end date";
                                                        }
                                                    },
                                                })}
                                            />
                                            {errors.endDate && (
                                                <span role="alert" className="error_text">
                                                    {errors.endDate.message}
                                                </span>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className=" ms-auto mt-auto col-md-6">
                                        <div className="form-group text-end filter-img">
                                            {allergenList?.length > 0 ? (
                                                <>
                                                    <Button
                                                        className="cancel_btn me-3 text-end"
                                                        type="button"
                                                        onClick={() => setDownloadpdf(true)}
                                                    >
                                                        PDF
                                                    </Button>
                                                    <Button
                                                        className="cancel_btn me-3 text-end"
                                                        type="button"
                                                        onClick={() => setDownloadExcel(true)}
                                                    // onClick={() => onDownload()}
                                                    >
                                                        Download
                                                    </Button>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                            {openCircle ? (
                                                <Button className="theme_btn text-end">
                                                    Applying Filter{" "}
                                                    <CircularProgress
                                                        sx={{
                                                            color: "#fff",
                                                            marginLeft: "0.5rem",
                                                            height: "23px !important",
                                                            width: "23px !important",
                                                        }}
                                                    />
                                                </Button>
                                            ) : (
                                                <Button className="theme_btn text-end" type="submit">
                                                    <img src={filter} className="me-1" alt="" />
                                                    Apply
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Suspense fallback={renderLoader()}>
                                <div ref={tableRef} style={back}>
                                    {allergenList?.length > 0 ? (
                                        <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                                            <div className="d-flex">
                                                <img
                                                    src={
                                                        downloadPdf
                                                            ? require("../../../../../Assets/PngImage/Header_logo_1.png")
                                                            : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
                                                    }
                                                    alt=""
                                                    width={100}
                                                    height={80}
                                                />
                                                <div className="ms-3">
                                                    <label
                                                        style={{
                                                            fontSize: "18px",
                                                            color: "#2c2e35",
                                                            fontWeight: 600,
                                                            marginBottom: "7px",
                                                        }}
                                                    >
                                                        Allergen Group Student Report{" "}
                                                    </label>
                                                    {/* <div
                                                        className="names"
                                                        style={{
                                                            fontSize: "16px",
                                                            color: "#2c2e35",
                                                            fontWeight: 600,
                                                            marginBottom: "7px",
                                                        }}
                                                    >
                                                        {reportDetails?.startDate +
                                                            " - " +
                                                            reportDetails?.endDate}
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    style={{
                                                        fontSize: "18px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                        width: "250px",
                                                        whiteSpace: "pre-line",
                                                    }}
                                                >
                                                    {reportDetails?.schName}
                                                </label>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                    }}
                                                >
                                                    {reportDetails?.distName}
                                                </div>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                    }}
                                                >
                                                    {Time.toLocaleString()}
                                                </div>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {LogDetail?.firstName + " " + LogDetail?.lastName}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {allergenList?.length > 0 ?
                                        allergenList?.map((x, i) => (

                                            <div className="mt-3" key={i}>
                                                <div className="f-18-600">{x?.allergenGroupName}</div>
                                                <SmallListTable
                                                    headCells={productHead}
                                                    ListData={x?.data?.length > 0 ? x?.data : []}
                                                    downloadPdf={downloadPdf}
                                                    downloadExcel={downloadExcel}
                                                />
                                            </div>
                                        ))
                                        :
                                        <div className="text-center f-18-600">
                                            No Data Found
                                        </div>
                                    }
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AllergenGroupStudentReport;
