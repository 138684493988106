import React, { Suspense, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
// import Axios from "../../Utils/AxiosConfig";
import Banner from "../../Assets/PngImage/login_banner.png";
import "./index.scss";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import Axios from "../../Utils/AxiosConfig";
import Swal from "sweetalert2";
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ForgotPassword() {
  const [openCircle, setOpenCircle] = useState(false);
  let location = useLocation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setOpenCircle(true);
    let pName = location.pathname;
    let url = "";
    if (pName === "/forgot-password-parent") {
      url = "/api/ParentLogin/ParentForgotPassword";
    } else {
      url = "/api/UserLogin/ForgotPassword";
    }
    Axios.post(url, data)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            reset();
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="forgot_page">
          <div className="row m-0 h-100">
            <div className="col-md-6 p-0 sidebanner">
              <img src={Banner} alt="" className="img-fluid forgot_banner" />
            </div>
            <div className="col-md-6 m-auto">
              <form onSubmit={handleSubmit(onSubmit)} className="forgot_box">
                <div className="forgot_detail">
                  <div className="head_title">Forgot Password</div>
                  <div className="form-group">
                    <label className="form-label label_with_icon">
                      <HiOutlineMail
                        color="#31B680"
                        fontSize={24}
                        className="me-2"
                      />
                      Email Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Your Email Address"
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Please Enter Valid Email",
                        },
                      })}
                    />
                    {errors.email && (
                      <span role="alert" className="error_text">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                  <div className="text-end">
                    <Link className="forgot f-18-400" to="/">
                      Back to Login
                    </Link>
                  </div>
                  <div className="login_btn">
                    <Button type="submit" className="theme_btn w-100">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>{" "}
      </Suspense>
    </>
  );
}

export default ForgotPassword;
