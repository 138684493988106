import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import WebAdminNavigation from "../Modules/Web/Parent/Shared/WebAdminNavigation/AdminNavigation";

function PrivateRouteParent({ children }) {
  const loggedIn = useSelector(
    (state) => state.login?.LoginDetails?.authenticateStudent
  );

  return loggedIn ? (
    <>
      <WebAdminNavigation />
      {children}
      {/* <Footer /> */}
    </>
  ) : (
    <Navigate to="/" />
  );
}

export default PrivateRouteParent;
