import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function HourlyQTYSoldTab(props) {
  const { tableRef, startDate, endDate, back } = props;
  // const { tableRef, repoData, startDate, endDate, back } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const reporttable = [
    {
      Menuname: "Menu Item",
      Item: "Item-",
      Description: "Description-",
      Time: "00:01-",
      Price: "Price",
      Menuname1: "Brekkie",
      Item1: "BR084",
      Description1: "Brekky hotpot",
      Time1: "1",
      Price1: "15.00",
    },
    {
      Menuname: "Menu Item",
      Item: "Item-",
      Description: "Description-",
      Time: "00:01-",
      Price: "Price",
      Menuname1: "Brekkie",
      Item1: "BR085",
      Description1: "Brisket Benny",
      Time1: "1",
      Price1: "15.00",
    },
    {
      Menuname: "Menu Item",
      Item: "Item-",
      Description: "Description-",
      Time: "00:01-",
      Price: "Price",
      Menuname1: "Burgers",
      Item1: "BG005",
      Description1: "Schnitty Burger",
      Time1: "4",
      Price1: "15.00",
    },
    {
      Menuname: "Menu Item",
      Item: "Item-",
      Description: "Description-",
      Time: "00:01-",
      Price: "Price",
      Menuname1: "Sauce",
      Item1: "SU006",
      Description1: "Honey Mustared",
      Time1: "1",
      Price1: "18.00",
    },
    {
      Menuname: "Menu Item",
      Item: "Item-",
      Description: "Description-",
      Time: "00:01-",
      Price: "Price",
      Menuname1: "Sauce",
      Item1: "SU006",
      Description1: "Honey Mustared",
      Time1: "1",
      Price1: "18.00",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <section className="hourTab-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Hourly QTY Sold report</h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase border-dash pb-4">
          hourly qty sold report
        </h5>
        <div className="report-table">
          {reporttable?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <div className="d-flex justify-content-between">
                <h6 className="">{text?.Menuname}</h6>
                <h6 className="">{text?.Menuname1}</h6>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="">{text?.Item}</h6>
                <h6 className="">{text?.Item1}</h6>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="">{text?.Description}</h6>
                <h6 className="">{text?.Description1}</h6>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="">{text?.Time}</h6>
                <h6 className="">{text?.Time1}</h6>
              </div>
              <div className="d-flex justify-content-between mb-4">
                <h6 className="">{text?.Price}</h6>
                <h6 className="">{text?.Price1}</h6>
              </div>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </section>
    </Suspense>
  );
}

export default HourlyQTYSoldTab;
