import React, { Suspense, useEffect, useState } from "react";
import Charts from "../../../../Components/Reusable/Chart/Charts";
import {
  Backdrop,
  CircularProgress,
  FormGroup,
  Switch,
  Stack,
} from "@mui/material";
import "./index.scss";
import { styled } from "@mui/material/styles";
import Highcharts from "highcharts";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import { AcademicYearListApi } from "../../../../Action/AcademicYear";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

let highchartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    headerFormat: "",
    formatter() {
      var output1 = `${this.point.name}: <b>${this.point.y?.toLocaleString(undefined, undefined)}</b>`
      return output1
    },
    // pointFormat: "{point.name}: <b>{point.y:,.0f}</b>", 
    // pointFormat: "{point.name}: <b>{point.y}</b>",
    // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  // accessibility: {
  //   point: {
  //     valueSuffix: "%",
  //   },
  // },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
      },
      showInLegend: false,
    },
  },
  series: [
    {
      // name: "Brands",
      colorByPoint: true,
      data: [
        {
          name: "Edge",
          y: 18.82,
          color: "#FD2254",
        },
        {
          name: "Firefox",
          y: 14.63,
          color: "#FEA800",
        },
        {
          name: "Other",
          y: 15.28,
          color: "#00B7FE",
        },
        {
          name: "Chrome",
          y: 20.77,
          color: "#D0D2DA",
        },
      ],
    },
  ],
};
let averagehighchartOptions = {
  chart: {
    type: "pie",
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  tooltip: {
    headerFormat: "",
    // pointFormat: "{point.name}: <b>$ {point.y}</b>",
    pointFormat: "{point.name}: <b> {point.y}</b>",
    // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      borderWidth: 2,
      cursor: "pointer",
      dataLabels: {
        enabled: false,
        format: "<b>{point.name}</b><br>{point.percentage}%",
        distance: 20,
      },
    },
  },
  series: [
    {
      // Disable mouse tracking on load, enable after custom animation
      // enableMouseTracking: true,
      animation: {
        duration: 2000,
      },
      innerSize: "45%",

      colorByPoint: true,
      keys: ["y", "name"],
      data: [
        {
          name: "Customer Support",
          color: "#56B828",
          y: 16.3,
        },
        {
          name: "Development",
          color: "#D46E35",
          y: 22.7,
        },
        {
          name: "Sales",
          color: "#3B7BDC",
          y: 19.2,
        },
        {
          name: "Other",
          color: "#15BB93",
          y: 12.6,
        },
      ],
    },
  ],
};

// let bankhighchartOptions = {
//   chart: {
//     plotBackgroundColor: null,
//     plotBorderWidth: null,
//     plotShadow: false,
//     type: "pie",
//   },
//   title: {
//     text: "",
//   },
//   tooltip: {
//     pointFormat: "{point.name}: <b>$ {point.y}</b>",
//     // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
//   },
//   accessibility: {
//     point: {
//       valueSuffix: "%",
//     },
//   },
//   plotOptions: {
//     pie: {
//       allowPointSelect: true,
//       cursor: "pointer",
//       dataLabels: {
//         enabled: false,
//       },
//       showInLegend: false,
//     },
//   },
//   series: [
//     {
//       name: "Brands",
//       colorByPoint: true,
//       data: [
//         {
//           name: "Edge",
//           y: 18.82,
//           color: "#1D45AB",
//         },
//         {
//           name: "Firefox",
//           y: 14.63,
//           color: "#FBCD29",
//         },
//         {
//           name: "Other",
//           y: 15.28,
//           color: "#0E6E93",
//         },
//         {
//           name: "Chrome",
//           y: 20.77,
//           color: "#A52922",
//           sliced: true,
//           selected: true,
//         },
//       ],
//     },
//   ],
// };

let cashhighchartOptions = {
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  xAxis: {
    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    crosshair: true,
    accessibility: {
      description: "Countries",
    },
  },
  yAxis: {
    min: 0,
    title: {
      text: "",
    },
  },
  tooltip: {
    valueSuffix: " ",
    // valueSuffix: " (1000 MT)",
    formatter() {
      var output1 = `<b>${this.point.y?.toLocaleString(undefined, undefined)}</b>`
      return output1
    },
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      showInLegend: false,
    },
  },
  series: [
    {
      name: "Corn",
      color: "#04BFDA",
      data: [406292, 260000, 107000, 220000, 27500, 14500, 210000],
    },
    {
      name: "Wheat",
      color: "#FFA84A",
      data: [51086, 136000, 176000, 201000, 107180, 77000, 156000],
    },
  ],
};

function AdminNewDashboard() {
  const [openCircle, setOpenCircle] = useState(false);
  const [chartData, setChartData] = useState();
  const [distId, setDistId] = useState();
  const [dayCh, setDayCh] = useState(true);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [schoolYear, setSchoolYear] = useState();
  // const [dayDate, setDayDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const [districtList, setDistrictList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getChartData(LogDetail?.schoolDistrictId, true);
    getDistrictList();
    getAcademicYear();
    // eslint-disable-next-line
  }, []);

  const getAcademicYear = async () => {
    let resp = await AcademicYearListApi();
    if (resp?.length > 0) {
      setSchoolYearList(resp);
    }
  }

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,

      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload;
        setDistrictList(dlist?.length > 0 ? dlist : []);
        setDistId(LogDetail?.schoolDistrictId);
      }
    }
  };
  const getChartData = async (sch, date, schYear) => {
    function startOfMonth(date) {
      return new Date(date.getFullYear(), date.getMonth(), 1);
      // return new Date(date.getFullYear(), date.getMonth(), 1, 5, 30);
    }
    let dt = new Date();

    dt = startOfMonth(dt).toString();
    dt = new Date(dt);

    // startDate=${""}&endDate=${""}&
    let sendData = {
      dayDate: date ? null : new Date(),
      startDate: date ? dt : null,
      endDate: date ? new Date() : null,
      schoolDistrictId: sch,
      academicYearId: schYear ? parseInt(schYear) : 0
    };
    let seData = {
      url: `/api/Dashbord/GetAdminDashboard`,
      body: sendData,
      headers: headers,
    };
    setOpenCircle(true);
    setChartData();

    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let de = res.data;
        if (date) {
          de = {
            ...de,
            dailyCashExpected_Deposite:
              de?.dailyCashExpected_Deposite?.length > 0
                ? de?.dailyCashExpected_Deposite?.sort((a, b) => {
                  return Number(a?.day) - Number(b?.day);
                })
                : [],
          };
        }
        setChartData(de);
      }
    }
  };

  const handleChangeDist = async (e, d, y) => {
    setDistId(e);
    setDayCh(d);

    getChartData(e, d, y);
  };
  
  const handleChangeSchoolYear = async (e, d, y) => {
    setSchoolYear(y);
    getChartData(e, d, y);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="admin_newdashboard">
          <div className="main_wrapper">
            <div className="top-content">
              <div className="swith-area">
                <FormGroup>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <div className="switch-text color-grey">Day</div>
                    <AntSwitch
                      defaultChecked
                      inputProps={{ "aria-label": "ant design" }}
                      value={dayCh}
                      onChange={(e) =>
                        handleChangeDist(distId, e.target.checked, schoolYear)
                      }
                    />
                    <div className="switch-text">Month</div>
                  </Stack>
                </FormGroup>
                {/* <div className="ms-3">
                  {dayCh ? (
                    <div className="d-flex">
                      <input
                        type="date"
                        name=""
                        className="form-control"
                        value={dayDate}
                        onChange={(e) => setDayDate(e.target.value)}
                      />{" "}
                      <input
                        type="date"
                        name=""
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  ) : (
                    <input
                      type="date"
                      name=""
                      className="form-control"
                      value={dayDate}
                      onChange={(e) => setDayDate(e.target.value)}
                    />
                  )}
                </div> */}
              </div>

              <div className="sites-area">
                <span className="color-grey me-2">District:</span>
                <div className="form-group">
                  <select
                    name=""
                    className="form-select"
                    value={distId}
                    onChange={(e) => handleChangeDist(e.target.value, dayCh, schoolYear)}
                  >
                    {districtList &&
                      districtList?.map((ds, i) => (
                        <option key={i} value={ds?.schoolDistrictId}>
                          {ds?.districtName}
                        </option>
                      ))}
                  </select>
                </div>
                <span className="color-grey me-2"> School Year: </span>
                <div className="form-group">
                  <select
                    name=""
                    className="form-select"
                    value={schoolYear}
                    onChange={(e) => handleChangeSchoolYear(distId, dayCh, e.target.value)}
                  >
                    <option value="0">Select School Year</option>
                    {schoolYearList &&
                      schoolYearList?.map((data, i) => (
                        <option key={i} value={data?.value}>
                          {data?.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="wht-box mt-5">
              {/* <h5>Meals per Labor Hour</h5> */}
              <h5>Meals Served</h5>
              <div className="row mt-5">
                <div className="col-md-3">
                  <Charts
                    series={{
                      ...highchartOptions,
                      series: [
                        {
                          data: [
                            {
                              y: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Breakfast"
                              )[0]?.totalSchoolSum,
                              name: "Breakfast",
                              color: "#FD2254",
                              sliced: true,
                              selected: true,
                            },
                            {
                              y: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Lunch"
                              )[0]?.totalSchoolSum,
                              name: "Lunch",
                              color: "#FEA800",
                            },
                            {
                              y: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Dinner"
                              )[0]?.totalSchoolSum,
                              name: "Dinner",
                              color: "#00B7FE",
                            },
                            {
                              y: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Snacks"
                              )[0]?.totalSchoolSum,
                              name: "Snacks",
                              color: "#D0D2DA",
                            },
                          ],
                          keys: ["y", "name", "color"],
                        },
                      ],
                    }}
                    highcharts={Highcharts}
                  />
                  <h5 className="text-center">$AVG of all schools</h5>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Breakfast</h5>

                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Breakfast"
                              )[0]?.commonMealPers
                                ? chartData?.mealsPerLaborHour?.orderTypes
                                  ?.filter(
                                    (x) => x.orderTypeName === "Breakfast"
                                  )[0]
                                  ?.commonMealPers?.map((x) => {
                                    return {
                                      y: x?.counts,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3">
                        Meal served :{" "}
                        <span className="color-green">
                          {
                            chartData?.mealsPerLaborHour?.orderTypes?.filter(
                              (x) => x.orderTypeName === "Breakfast"
                            )[0]?.totalSchoolSum?.toLocaleString(undefined, undefined)
                          }{" "}
                        </span>
                        {/* <span className="color-green">200 $ 2500 </span> */}
                      </p>
                    </div>
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Lunch</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Lunch"
                              )[0]?.commonMealPers
                                ? chartData?.mealsPerLaborHour?.orderTypes
                                  ?.filter(
                                    (x) => x.orderTypeName === "Lunch"
                                  )[0]
                                  ?.commonMealPers?.map((x) => {
                                    return {
                                      y: x?.counts,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3">
                        Meal served :{" "}
                        <span className="color-green">
                          {
                            chartData?.mealsPerLaborHour?.orderTypes?.filter(
                              (x) => x.orderTypeName === "Lunch"
                            )[0]?.totalSchoolSum?.toLocaleString(undefined, undefined)
                          }
                        </span>
                      </p>
                    </div>
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Dinner</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Dinner"
                              )[0]?.commonMealPers
                                ? chartData?.mealsPerLaborHour?.orderTypes
                                  ?.filter(
                                    (x) => x.orderTypeName === "Dinner"
                                  )[0]
                                  ?.commonMealPers?.map((x) => {
                                    return {
                                      y: x?.counts,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3">
                        Meal served :
                        <span className="color-green">
                          {
                            chartData?.mealsPerLaborHour?.orderTypes?.filter(
                              (x) => x.orderTypeName === "Dinner"
                            )[0]?.totalSchoolSum?.toLocaleString(undefined, undefined)
                          }
                        </span>
                      </p>
                    </div>
                    <div className="col-lg-3">
                      <h5 className="text-center ">Snacks</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.mealsPerLaborHour?.orderTypes?.filter(
                                (x) => x.orderTypeName === "Snacks"
                              )[0]?.commonMealPers
                                ? chartData?.mealsPerLaborHour?.orderTypes
                                  ?.filter(
                                    (x) => x.orderTypeName === "Snacks"
                                  )[0]
                                  ?.commonMealPers?.map((x) => {
                                    return {
                                      y: x?.counts,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                              keys: ["y", "name"],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3">
                        Meal served :{" "}
                        <span className="color-green">
                          {
                            chartData?.mealsPerLaborHour?.orderTypes?.filter(
                              (x) => x.orderTypeName === "Snacks"
                            )[0]?.totalSchoolSum?.toLocaleString(undefined, undefined)
                          }
                        </span>
                      </p>
                    </div>
                    <p className="color-grey text-center pt-3 fst-italic">
                      This will show reimbursement rates/counts. Each pie slice
                      represents a different school
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="wht-box mt-5">
              {/* <h5>Average Daily Participation</h5> */}
              <h5>Ala-Carte Sales</h5>
              <div className="row mt-5">
                <div className="col-md-3">
                  <Charts
                    series={{
                      ...averagehighchartOptions,
                      tooltip: {
                        headerFormat: "",
                        // pointFormat: "{point.name}: <b>$ {point.y}</b>",
                        // pointFormat: "{point.name}: <b> {point.y}</b>",
                        formatter() {
                          var output1 = `${this.point.name}: <b>${this.point.y?.toLocaleString(undefined, undefined)}</b>`
                          return output1
                        },
                        // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                      },
                      series: [
                        {
                          ...averagehighchartOptions.series[0],
                          data: chartData?.alaCarteSalesModels
                            ? chartData?.alaCarteSalesModels?.map(
                              (x, i) => {
                                return {
                                  y: x?.totalMealPrice,
                                  name: x.mealServiceName,
                                };
                              }
                            )
                            : [],
                          //  [
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Breakfast"
                          //     )[0]?.totalBillAmount,
                          //     name: "Breakfast",
                          //     color: "#56B828",
                          //   },
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Lunch"
                          //     )[0]?.totalBillAmount,
                          //     name: "Lunch",
                          //     color: "#D46E35",
                          //   },
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Dinner"
                          //     )[0]?.totalBillAmount,
                          //     name: "Dinner",
                          //     color: "#3B7BDC",
                          //   },
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Snacks"
                          //     )[0]?.totalBillAmount,
                          //     name: "Snacks",
                          //     color: "#15BB93",
                          //   },
                          // ],
                          keys: ["y", "name", "color"],
                        },
                      ],
                    }}
                    highcharts={Highcharts}
                  />
                  <h5 className="text-center">AVG of all schools</h5>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Breakfast</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.alaCarteSalesModels?.filter(
                                (x) => x.mealServiceName === "Breakfast"
                              )[0]?.alaCarteSubModels
                                ? chartData?.alaCarteSalesModels
                                  ?.filter(
                                    (x) => x.mealServiceName === "Breakfast"
                                  )[0]
                                  ?.alaCarteSubModels?.map((x) => {
                                    return {
                                      y: x?.mealPrice,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.alaCarteSalesModels?.filter(
                            (x) => x.mealServiceName === "Breakfast"
                          )[0]?.totalMealPrice?.toLocaleString(undefined, undefined)
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Lunch</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.alaCarteSalesModels?.filter(
                                (x) => x.mealServiceName === "Lunch"
                              )[0]?.alaCarteSubModels
                                ? chartData?.alaCarteSalesModels
                                  ?.filter(
                                    (x) => x.mealServiceName === "Lunch"
                                  )[0]
                                  ?.alaCarteSubModels?.map((x) => {
                                    return {
                                      y: x?.mealPrice,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.alaCarteSalesModels?.filter(
                            (x) => x.mealServiceName === "Lunch"
                          )[0]?.totalMealPrice?.toLocaleString(undefined, undefined)
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Dinner</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.alaCarteSalesModels?.filter(
                                (x) => x.mealServiceName === "Dinner"
                              )[0]?.alaCarteSubModels
                                ? chartData?.alaCarteSalesModels
                                  ?.filter(
                                    (x) => x.mealServiceName === "Dinner"
                                  )[0]
                                  ?.alaCarteSubModels?.map((x) => {
                                    return {
                                      y: x?.mealPrice,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.alaCarteSalesModels?.filter(
                            (x) => x.mealServiceName === "Dinner"
                          )[0]?.totalMealPrice?.toLocaleString(undefined, undefined)
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <div className="col-lg-3">
                      <h5 className="text-center ">Snacks</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.alaCarteSalesModels?.filter(
                                (x) => x.mealServiceName === "Snacks"
                              )[0]?.alaCarteSubModels
                                ? chartData?.alaCarteSalesModels
                                  ?.filter(
                                    (x) => x.mealServiceName === "Snacks"
                                  )[0]
                                  ?.alaCarteSubModels?.map((x) => {
                                    return {
                                      y: x?.mealPrice,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.alaCarteSalesModels?.filter(
                            (x) => x.mealServiceName === "Snacks"
                          )[0]?.totalMealPrice?.toLocaleString(undefined, undefined)
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <p className="color-grey text-center pt-3 fst-italic">
                      This will show ala-carte sales. Each Pie slice represents
                      a different school
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="wht-box mt-5">
              <h5>Average Daily Participation</h5>
              <div className="row mt-5">
                <div className="col-md-3">
                  <Charts
                    series={{
                      ...averagehighchartOptions,
                      tooltip: {
                        headerFormat: "",
                        // pointFormat: "{point.name}: <b>$ {point.y}</b>",
                        // pointFormat: "{point.name}: <b> {point.y}</b>",
                        formatter() {
                          var output1 = `${this.point.name}: <b>${this.point.y?.toLocaleString(undefined, undefined)}</b>`
                          return output1
                        },
                        // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                      },
                      series: [
                        {
                          ...averagehighchartOptions.series[0],
                          data: chartData?.avgDailyParticipationAllSchool
                            ? chartData?.avgDailyParticipationAllSchool?.map(
                              (x, i) => {
                                return {
                                  y: x?.totalSchoolCount,
                                  name: x.mealServiceName,
                                };
                              }
                            )
                            : [],
                          //  [
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Breakfast"
                          //     )[0]?.totalBillAmount,
                          //     name: "Breakfast",
                          //     color: "#56B828",
                          //   },
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Lunch"
                          //     )[0]?.totalBillAmount,
                          //     name: "Lunch",
                          //     color: "#D46E35",
                          //   },
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Dinner"
                          //     )[0]?.totalBillAmount,
                          //     name: "Dinner",
                          //     color: "#3B7BDC",
                          //   },
                          //   {
                          //     y: chartData?.avgDailyParticipation?.dailyOrders?.filter(
                          //       (x) => x.orderTypeName === "Snacks"
                          //     )[0]?.totalBillAmount,
                          //     name: "Snacks",
                          //     color: "#15BB93",
                          //   },
                          // ],
                          keys: ["y", "name", "color"],
                        },
                      ],
                    }}
                    highcharts={Highcharts}
                  />
                  <h5 className="text-center">AVG of all schools</h5>
                </div>
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Breakfast</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.avgDailyParticipationAllSchool?.filter(
                                (x) => x.mealServiceName === "Breakfast"
                              )[0]?.mealServiceBySchoolSubs
                                ? chartData?.avgDailyParticipationAllSchool
                                  ?.filter(
                                    (x) => x.mealServiceName === "Breakfast"
                                  )[0]
                                  ?.mealServiceBySchoolSubs?.map((x) => {
                                    return {
                                      y: x?.avgDailyParticipationCount,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.avgDailyParticipationAllSchool?.filter(
                            (x) => x.mealServiceName === "Breakfast"
                          )[0]?.totalSchoolCount
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Lunch</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.avgDailyParticipationAllSchool?.filter(
                                (x) => x.mealServiceName === "Lunch"
                              )[0]?.mealServiceBySchoolSubs
                                ? chartData?.avgDailyParticipationAllSchool
                                  ?.filter(
                                    (x) => x.mealServiceName === "Lunch"
                                  )[0]
                                  ?.mealServiceBySchoolSubs?.map((x) => {
                                    return {
                                      y: x?.avgDailyParticipationCount,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.avgDailyParticipationAllSchool?.filter(
                            (x) => x.mealServiceName === "Lunch"
                          )[0]?.totalSchoolCount
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <div className="col-lg-3 border-right">
                      <h5 className="text-center ">Dinner</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.avgDailyParticipationAllSchool?.filter(
                                (x) => x.mealServiceName === "Dinner"
                              )[0]?.mealServiceBySchoolSubs
                                ? chartData?.avgDailyParticipationAllSchool
                                  ?.filter(
                                    (x) => x.mealServiceName === "Dinner"
                                  )[0]
                                  ?.mealServiceBySchoolSubs?.map((x) => {
                                    return {
                                      y: x?.avgDailyParticipationCount,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.avgDailyParticipationAllSchool?.filter(
                            (x) => x.mealServiceName === "Dinner"
                          )[0]?.totalSchoolCount
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <div className="col-lg-3">
                      <h5 className="text-center ">Snacks</h5>
                      <Charts
                        series={{
                          ...highchartOptions,
                          series: [
                            {
                              data: chartData?.avgDailyParticipationAllSchool?.filter(
                                (x) => x.mealServiceName === "Snacks"
                              )[0]?.mealServiceBySchoolSubs
                                ? chartData?.avgDailyParticipationAllSchool
                                  ?.filter(
                                    (x) => x.mealServiceName === "Snacks"
                                  )[0]
                                  ?.mealServiceBySchoolSubs?.map((x) => {
                                    return {
                                      y: x?.avgDailyParticipationCount,
                                      name: x?.schoolName,
                                    };
                                  })
                                : [],
                            },
                          ],
                        }}
                      />
                      <p className="text-center mt-3 color-green mb-0">
                        {/* ${" "} */}
                        {
                          chartData?.avgDailyParticipationAllSchool?.filter(
                            (x) => x.mealServiceName === "Snacks"
                          )[0]?.totalSchoolCount
                        }
                      </p>
                      <p className="text-center mb-0"> Sales Total</p>
                    </div>
                    <p className="color-grey text-center pt-3 fst-italic">
                      This will show Average Daily Participation. Each Pie slice represents
                      a different school
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              {/* <div className="col-md-6 mb-3">
                <div className="wht-box">
                  <h5>Bank Deposit</h5>
                  <Charts
                    series={{
                      ...bankhighchartOptions,
                      series: [
                        {
                          data:
                            chartData?.bankDeposit?.schoolDeposits?.length > 0
                              ? chartData?.bankDeposit?.schoolDeposits?.map(
                                (x, i) => {
                                  if (i === 0) {
                                    return {
                                      name: x?.schoolName,
                                      y: x?.bankAmount,
                                      sliced: true,
                                      selected: true,
                                    };
                                  }
                                  return {
                                    name: x?.schoolName,
                                    y: x?.bankAmount,
                                  };
                                }
                              )
                              : [],
                        },
                      ],
                    }}
                    highcharts={Highcharts}
                  />
                  <h5 className="text-center mt-3">
                    Total Of All Schools Deposited
                  </h5>
                </div>
              </div> */}
              <div className="col-md-3 mb-3">
                <div className="wht-box">
                  <h5 className="text-center">Labor Hours</h5>
                  <Charts
                    series={{
                      ...averagehighchartOptions,
                      tooltip: {
                        headerFormat: "",
                        pointFormat: "{point.name}: <b>{point.y}</b>",
                        // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
                      },
                      series: [
                        {
                          ...averagehighchartOptions.series[0],
                          data:
                            chartData?.laborHour?.length > 0
                              ? chartData?.laborHour?.map(
                                (x) => {
                                  return {
                                    y: parseFloat(x?.totalShiftDurationDecimal),
                                    name: x?.schoolName,
                                  };
                                }
                              )
                              : [],
                        },

                      ],
                    }}
                    highcharts={Highcharts}
                  />
                  <p className="color-grey text-center pt-3 fst-italic mb-0">
                    This should show labor hours by school
                  </p>
                </div>
              </div>
              <div className="col-md-9">
                <div className="wht-box">
                  <h5 className="text-center mb-3">
                    Daily Cash Expected & Deposited
                  </h5>
                  <Charts
                    series={{
                      ...cashhighchartOptions,
                      xAxis: {
                        ...cashhighchartOptions.xAxis,
                        categories:
                          chartData?.dailyCashExpected_Deposite?.length > 0
                            ? dayCh
                              ? chartData?.dailyCashExpected_Deposite?.map(
                                (x) => x.day
                              )
                              : chartData?.dailyCashExpected_Deposite?.map(
                                (x) => x.hour
                              )
                            : [],
                      },
                      series: [
                        {
                          name: "Expected",
                          data:
                            chartData?.dailyCashExpected_Deposite?.length > 0
                              ? chartData?.dailyCashExpected_Deposite?.map(
                                (x) => {
                                  return {
                                    y: x?.cashExpected,
                                    name: x?.day,
                                  };
                                }
                              )
                              : [],
                        },
                        {
                          name: "Deposit",

                          data:
                            chartData?.dailyCashExpected_Deposite?.length > 0
                              ? chartData?.dailyCashExpected_Deposite?.map(
                                (x) => {
                                  return {
                                    y: x?.cashDeposit,
                                    name: x?.day,
                                  };
                                }
                              )
                              : [],
                        },
                      ],
                    }}
                    // series={cashhighchartOptions}
                    highcharts={Highcharts}
                  />
                  <p className="color-grey text-center fst-italic mb-0">
                    This should show daily cash expected / Cash deposited (All
                    Schools combined)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default AdminNewDashboard;
