import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
// import { styled } from "@mui/material/styles";
// import Highcharts from "highcharts";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
// import CashierSessionReport from "../Reports/CashierSessionReport";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import MyDashboard from "./MyDashboard";
import SurveyAnalytics from "./SurveyAnalytics";

const renderLoader = () => <p></p>;

function SessionGraph() {
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [values, setValue] = React.useState("1");
  const [distId, setDistId] = useState();
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [acaYearId, setAcaYearId] = useState("0");
  const [acaYearLabel, setAcaYearLabel] = useState("0");
  const [districtList, setDistrictList] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload;
        setDistrictList(dlist?.length > 0 ? dlist : []);
        setDistId(LogDetail?.schoolDistrictId);
      }
    }
  };
  useEffect(() => {
    getSchoolYear();
    handleChangeDist(distId, acaYearId);
    getDistrictList();
    // eslint-disable-next-line
  }, []);
  const getSchoolYear = async () => {
    let schApi = {
      url: `/api/SchoolAcademicYear/GetAllSchoolAcademicYearData`,
    };

    let resp = await GetCallApi(schApi);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        let allD = resp?.data?.payload?.map((x) => {
          return {
            value: x?.academicYearId,
            label: x?.academicYear,
            curYear: x?.isCurrentYear ? "True" : "False"
          };
        });
        setSchoolYearList(allD);
        let curtY = allD?.filter((c) => c?.curYear === "True")
        setAcaYearId(curtY[0]?.value);
      }
    }
  };

  const handleChangeDist = async (e, y) => {
    setDistId(e);
    setAcaYearId(y);
    setAcaYearLabel(schoolYearList?.find((x) => x?.value === parseInt(y))?.label);
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="session-graph">
          <div className="main_wrapper">
            <TabContext value={values}>
              <div className="top-action-control">
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  className="align-items-center"
                >
                  <Tab
                    value="1"
                    className="tabs_btn my-auto py-1"
                    style={{ minHeight: "auto" }}
                    label="My Dashboard"
                  />
                  <Tab
                    value="2"
                    className="tabs_btn my-auto py-1"
                    style={{ minHeight: "auto" }}
                    label="Survey Analytics"
                  />
                </TabList>
                <div className="select-action d-flex gap-3">
                  <div className="sites-area d-flex gap-2 align-items-center">
                    <span className="color-grey me-2 d-none d-lg-block">District:</span>
                    <div className="form-group m-0">
                      <select
                        name=""
                        className="form-select"
                        value={distId}
                        onChange={(e) =>
                          handleChangeDist(e.target.value, acaYearId)
                        }
                      >
                        {districtList &&
                          districtList?.map((ds, i) => (
                            <option key={i} value={ds?.schoolDistrictId}>
                              {ds?.districtName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  {values === "1" ? (
                    <div className="sites-area d-flex gap-2 align-items-center">
                      <span className="color-grey me-2 d-none d-lg-block">Date:</span>
                      <div className="form-group m-0">
                        <input
                          type="date"
                          name=""
                          className="form-control"
                          defaultValue={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="sites-area d-flex gap-2 align-items-center">
                      <span className="color-grey me-2 d-none d-lg-block">Academic Year</span>
                      <div className="form-group m-0">
                        <select
                          name=""
                          className="form-select"
                          value={acaYearId}
                          onChange={(e) =>
                            handleChangeDist(distId, e.target.value)
                          }
                        >
                          <option value="0">Select Academic Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <TabPanel className="tab_pannel" value="1">
                <MyDashboard curDate={date} distId={distId} />
              </TabPanel>
              <TabPanel className="tab_pannel" value="2">
                <SurveyAnalytics acaYearId={acaYearId} acaYearLabel={acaYearLabel} distId={distId} />
              </TabPanel>
            </TabContext>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SessionGraph;
