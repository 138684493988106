import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import Axios from "../../../../../Utils/AxiosConfig";
import filter from "../../../../../Assets/PngImage/filter.png";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "status", label: "Status" },
  { id: "studentname", label: "Student Name " },
  { id: "studentnumber", label: "Students Number" },
  { id: "schoolname", label: "School Name" },
  { id: "districtname", label: "District Name" },
  { id: "schoolreceives", label: "School Receives" },
  { id: "transctionfree", label: "Transaction Fees" },
  { id: "ssf", label: "Swan Solution Fees" },
  { id: "totalamount", label: "Total Amount" },
  { id: "spr", label: "Stripe Payments Reference" },
  { id: "paymentdate", label: "Payments Date" },
  { id: "paymentdateutc", label: "Payments Date UTC" },
];
function PaymentTab() {
  const [trShow, setTrShow] = useState(true);

  const { register, handleSubmit } = useForm();

  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    PaymentTab();
  }, []);

  const PaymentTab = (data) => {
    // console.log(data)
    setTrShow(true);

    Axios.get("/PaymentTab")
      .then((res) => {
        const SdData = res.data.map((sd, i) => {
          return {
            status: sd?.status,
            studentname: sd?.studentname,
            studentnumber: sd?.studentnumber,
            schoolname: sd?.schoolname,
            districtname: sd?.districtname,
            schoolreceives: sd?.schoolreceives,
            transctionfree: sd?.transctionfree?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            ssf: sd?.ssf?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            totalamount: sd?.totalamount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            spr: sd?.spr,
            paymentdate: sd?.paymentdate,
            paymentdateutc: sd?.paymentdateutc,
          };
        });
        setPrdList(SdData);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Suspense fallback={renderLoader()}>
      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit(PaymentTab)}>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label>Student Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Student Name"
                    {...register("studentName")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Student Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Student Number"
                    {...register("studentNumber")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Payments Reference</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Payments Reference"
                    {...register("paymentRefrence")}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Payment Date</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Payment Date"
                    {...register("paymentDate", {
                      valueAsDate: true,
                    })}
                  />
                  {/* <ReactDatePicker
                showIcon
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              /> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="d-flex justify-content-end text-align-end mt-4 mb-3">
        <Button type="submit" className="theme_btn filter-img">
          <img src={filter} className="me-1 " alt="" /> Apply
        </Button>
      </div>
      {trShow && <ListTable headCells={productHead} ListData={prdList} />}
    </Suspense>
  );
}

export default PaymentTab;
