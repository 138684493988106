// import React, { Suspense, useEffect, useState } from "react";
// import { Backdrop, Button, CircularProgress } from "@mui/material";
// import { useNavigate, useParams } from "react-router-dom";
// import Axios from "../../../../../../Utils/AxiosConfig";
// import "./index.scss";
// import { useFieldArray, useForm } from "react-hook-form";
// import { useSelector } from "react-redux";
// import Swal from "sweetalert2";
// import Select from "react-select";
// import { AcademicYearListApi } from "../../../../../../Action/AcademicYear";
// import { PostCallApi } from "../../../../../../Action/Action";

// const renderLoader = () => (
//     <div className="loader">
//         <CircularProgress sx={{ color: "#86c65a" }} />
//     </div>
// );

// function CreateUserManagment() {
//     const { id } = useParams();

//     const [overlaySelect, setOverlaySelect] = useState([]);
//     const [derror, setDerror] = useState();
//     const [schoolYearList, setSchoolYearList] = useState([]);
//     const [distList, setDistList] = useState([]);
//     const [roleList, setRoleList] = useState([]);
//     const [openCircle, setOpenCircle] = useState(false);
//     const [siteList, setSiteList] = useState();
//     const [schoolList, setSchoolList] = useState();
//     const [userDe, setUserDe] = useState();
//     const [checkbox, setCheckbox] = useState(false);

//     const navigate = useNavigate();
//     const LogDetail = useSelector((state) => state.login.LoginDetails);
//     const token = useSelector((state) => state.login.LoginDetails?.accessToken);

//     const {
//         register,
//         handleSubmit,
//         reset,
//         setValue,
//         watch,
//         control,
//         formState: { errors },
//     } = useForm();

//     const { fields, append, remove } = useFieldArray({
//         control,
//         name: "employeePayRateSub"
//     })

//     const headers = {
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//         Accept: "*/*",
//     };

//     // useEffect(() => {
//     //   if (schoolList) {
//     //     if (id) {
//     //       setValue("schoolId", userDe?.schoolId);
//     //     }
//     //   }
//     //   // eslint-disable-next-line
//     // }, [schoolList, id]);

//     useEffect(() => {
//         if (roleList) {
//             if (id) {
//                 if (roleList?.length > 0) {
//                     setValue("roleId", userDe?.roleId);
//                     // handleRoleName(userDe?.roleId);
//                 }
//             }
//         }
//         // eslint-disable-next-line
//     }, [roleList, id]);

//     const getDistrictList = (useDe) => {
//         setDistList([]);
//         Axios.get(
//             `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
//             { headers }
//         )
//             .then((res) => {
//                 if (res?.status === 200) {
//                     if (res.data.success) {
//                         const dlist = res.data.payload?.map((sd) => {
//                             return {
//                                 schId: sd?.schoolDistrictId,
//                                 Districtname: sd?.districtName,
//                             };
//                         });
//                         setDistList(dlist?.length > 0 ? dlist : []);
//                         if (useDe) {
//                             setTimeout(() => {

//                                 setValue("schoolDistrictId", useDe?.schoolDistrictId);
//                             }, 500);

//                         }
//                     }
//                 }
//             })
//             .catch((e) => console.log(e));
//     };

//     const getRoleList = (sch) => {
//         setRoleList([]);
//         Axios.get(
//             `/api/Roles/GetDistrictWiseRoleData?schoolDistrictId=${sch}`,
//             // `/api/Roles/GetAllRoleData?userId=${LogDetail?.userId}&schoolDistrictId=${sch}`,
//             { headers }
//         )
//             .then((res) => {
//                 if (res?.status === 200) {
//                     if (res.data.success) {
//                         const rList = res?.data?.payload
//                         setRoleList(rList);
//                     }
//                 }
//             })
//             .catch((e) => console.log(e));
//     };

//     const getAcademicYear = async (useDe) => {
//         let resp = await AcademicYearListApi();
//         if (resp?.length > 0) {
//             setSchoolYearList(resp);
//             if (useDe && resp) {
//                 setTimeout(() => {
//                     if (useDe?.employeePayRate?.length === 0) {
//                         append({ academicYearId: 0, payRate: "", payType: "" });
//                     } else {
//                         setValue("employeePayRateSub", useDe?.employeePayRate);
//                     }
//                 }, 500);
//             }
//         }
//     }

//     const getStateList = (useDe) => {
//         setSiteList([]);
//         Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
//             headers,
//         })
//             .then((res) => {
//                 if (res?.status === 200) {
//                     if (res.data.success) {
//                         setSiteList(res.data.payload);
//                         if (useDe) {
//                             setTimeout(() => {
//                                 setValue("state", useDe?.state);
//                             }, 500);
//                         }
//                     }
//                 }
//             })
//             .catch((e) => console.log(e));
//     };

//     const getSchoolList = (id) => {
//         setSchoolList([]);
//         if (id) {
//             Axios.get(
//                 `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
//                 { headers }
//             )
//                 .then((res) => {
//                     if (res?.status === 200) {
//                         if (res.data.success) {
//                             const SdData = res.data.payload?.map((sd, i) => {
//                                 return { label: sd.schoolName, value: sd.schoolId };
//                             });
//                             setSchoolList(SdData);
//                         }
//                     }
//                 })
//                 .catch((e) => console.log(e));
//         } else {
//             setSchoolList([]);
//         }
//     };

//     const onSubmit = (data) => {
//         setOpenCircle(true);
//         let is_success = true;
//         if (overlaySelect?.length === 0) {
//             is_success = false;
//             setDerror({ err: "Select minimum one school" });
//         }
//         let sendData = {
//             ...data,
//             createdBy: LogDetail?.userId,
//             schoolId: overlaySelect,
//         };
//         if (is_success) {
//             Axios.post("/api/User/CreateUser", sendData, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                     "Content-Type": "multipart/form-data",
//                     Accept: "*/*",
//                 },
//             })
//                 .then(async (res) => {
//                     if (res?.status === 200) {
//                         setOpenCircle(false);
//                         if (res.data.success) {
//                             let userD = res?.data?.payload
//                             let empData = {
//                                 userId: userD?.userId,
//                                 createBy: LogDetail?.userId,
//                                 employeePayRateSub: data?.employeePayRateSub?.filter((c) => c?.academicYearId !== null && c?.payRate !== null && c?.payType !== "")?.map((x) => {
//                                     return {
//                                         ...x
//                                     }
//                                 }),
//                             }
//                             let apiUrl = {
//                                 url: `/api/User/SaveEmployeePayRates`,
//                                 body: empData
//                             }

//                             await PostCallApi(apiUrl);
//                             Swal.fire({
//                                 position: "top-end",
//                                 width: 400,
//                                 // icon: "success",
//                                 text: res.data?.message,
//                                 showConfirmButton: false,
//                                 timer: 1500,
//                                 color: "#ffffff",
//                                 background: "#48953e",
//                                 showClass: {
//                                     popup: "animate__animated animate__fadeInDown",
//                                 },
//                                 hideClass: {
//                                     popup: "animate__animated animate__fadeOutRight",
//                                 },
//                             });
//                             reset();
//                             navigate(-1);
//                         }
//                     }
//                 })
//                 .catch((e) => console.log(e));
//         }
//     };

//     const EditUser = (id) => {
//         setOpenCircle(true);
//         Axios.get(`/api/User/GetUserDataById?userId=${id}`, { headers })
//             .then((res) => {
//                 if (res?.status === 200) {
//                     if (res.data.success) {
//                         let useDe = res.data.payload;
//                         setUserDe(useDe);
//                         setValue("userId", useDe?.userId);
//                         setValue("firstName", useDe?.firstName);
//                         setValue("lastName", useDe?.lastName);
//                         setValue("email", useDe?.email);
//                         setValue("mobile", useDe?.mobile);
//                         setValue("jobTitle", useDe?.jobTitle);
//                         setValue("CashierPin", useDe?.cashierPin);
//                         setValue("IsAllowCashierLogin", useDe?.isAllowCashierLogin);
//                         setCheckbox(useDe?.isAllowCashierLogin);
//                         setOverlaySelect(useDe?.schoolId);
//                         getSchoolList(useDe?.schoolDistrictId);
//                         getRoleList(useDe?.schoolDistrictId);
//                         getDistrictList(useDe);
//                         getStateList(useDe);
//                         getAcademicYear(useDe);

//                         setOpenCircle(false);
//                     } else {
//                         setOpenCircle(false);
//                     }
//                 }
//             })
//             .catch((e) => {
//                 setOpenCircle(false);
//                 console.log(e);
//             });
//     };
//     useEffect(() => {
//         if (id) {
//             EditUser(id);
//         } else {
//             append({ academicYearId: 0, payRate: "", payType: "" });
//             getDistrictList();
//             getStateList();
//             getAcademicYear();
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [id]);

//     const handleIOCMulti = (e) => {
//         setOverlaySelect(Array.isArray(e) ? e.map((x) => x.value) : []);
//     };

//     return (
//         <>
//             <Backdrop
//                 sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
//                 open={openCircle}
//             >
//                 <CircularProgress sx={{ color: "#86c65a" }} />
//             </Backdrop>
//             <Suspense fallback={renderLoader()}>
//                 <section className="createUserManage">
//                     <div className="main_wrapper">
//                         <h4 className="all_title mb-4">{id ? "Edit" : "Create"} User</h4>
//                         <div className="card">
//                             <div className="card-body">

//                                 <form
//                                     action=""
//                                     onSubmit={handleSubmit(onSubmit)}
//                                     className="primary-box"
//                                 >
//                                     <div className="row">
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     First Name <span className="red-color">*</span>{" "}
//                                                 </label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="First Name"
//                                                     {...register("firstName", {
//                                                         required: "This field is required",
//                                                     })}
//                                                 />
//                                                 {errors.firstName && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.firstName.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     Last Name <span className="red-color">*</span>{" "}
//                                                 </label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="Last Name"
//                                                     {...register("lastName", {
//                                                         required: "This field is required",
//                                                     })}
//                                                 />
//                                                 {errors.lastName && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.lastName.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     Phone Number <span className="red-color">*</span>{" "}
//                                                 </label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="Phone Number"
//                                                     {...register("mobile", {
//                                                         required: "This field is required",
//                                                         pattern: {
//                                                             value:
//                                                                 /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
//                                                             message: "Enter valid phone number",
//                                                         },
//                                                     })}
//                                                 />
//                                                 {errors.mobile && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.mobile.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     Email <span className="red-color">*</span>{" "}
//                                                 </label>
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="Email"
//                                                     {...register("email", {
//                                                         required: "This field is required",
//                                                         pattern: {
//                                                             value: /\S+@\S+\.\S+/,
//                                                             message:
//                                                                 "Entered value does not match email format",
//                                                         },
//                                                     })}
//                                                 />
//                                                 {errors.email && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.email.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     School District <span className="red-color">*</span>{" "}
//                                                 </label>
//                                                 <select
//                                                     name=""
//                                                     className="form-select"
//                                                     {...register("schoolDistrictId", {
//                                                         required: "This field is required",
//                                                         onChange: (e) => {
//                                                             setValue('roleId', "")
//                                                             getSchoolList(e.target.value);
//                                                             getRoleList(e.target.value);
//                                                         },
//                                                     })}
//                                                 >
//                                                     <option value="">Select School District</option>
//                                                     {distList &&
//                                                         distList?.map((data, i) => (
//                                                             <option key={i} value={data?.schId}>
//                                                                 {data?.Districtname}
//                                                             </option>
//                                                         ))}
//                                                 </select>
//                                                 {errors.schoolDistrictId && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.schoolDistrictId.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         {/* <div className="col-md-6">
//                       <div className="form-group">
//                         <label>
//                           School
//                         </label>
//                         <select
//                           name=""
//                           className="form-select"
//                           {...register("schoolId", {
//                           })}
//                         >
//                           <option value="">Select School </option>
//                           {schoolList &&
//                             schoolList?.map((item, i) => (
//                               <option value={item.schoolId} key={i}>
//                                 {item.schoolName}
//                               </option>
//                             ))}
//                         </select>
//                         {errors.schoolId && (
//                           <span role="alert" className="error_text">
//                             {errors.schoolId.message}
//                           </span>
//                         )}
//                       </div>
//                     </div> */}
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>School <span className="red-color">*</span>{" "}</label>
//                                                 <Select
//                                                     isMulti
//                                                     name="type"
//                                                     options={schoolList}
//                                                     // closeMenuOnSelect={false}
//                                                     // hideSelectedOptions={false}
//                                                     className="form-neselect"
//                                                     classNamePrefix="select"
//                                                     placeholder="Select School"
//                                                     value={schoolList?.filter((obj) =>
//                                                         overlaySelect?.includes(obj.value)
//                                                     )}
//                                                     onChange={handleIOCMulti}
//                                                     style={{ width: "100%" }}
//                                                 />
//                                                 {derror?.err && (
//                                                     <span role="alert" className="d-block error_text">
//                                                         {derror.err}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     {" "}
//                                                     Role Name <span className="red-color">*</span>
//                                                 </label>
//                                                 <select
//                                                     name=""
//                                                     className="form-select"
//                                                     {...register("roleId", {
//                                                         required: {
//                                                             value: true,
//                                                             message: "This field is required",
//                                                         },
//                                                     })}
//                                                 >
//                                                     <option value="">Select Role Name</option>
//                                                     {roleList &&
//                                                         roleList?.map((data, i) => (
//                                                             <option key={i} value={data?.roleId}>
//                                                                 {data?.roleName}
//                                                             </option>
//                                                         ))}
//                                                 </select>
//                                                 {errors.roleId && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.roleId.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     {" "}
//                                                     State <span className="red-color">*</span>
//                                                 </label>
//                                                 <select
//                                                     name=""
//                                                     className="form-select"
//                                                     defaultValue=""
//                                                     {...register("state", {
//                                                         required: "This field is required",
//                                                         valueAsNumber: true,
//                                                     })}
//                                                 >
//                                                     <option value="">Select State</option>
//                                                     {siteList &&
//                                                         siteList?.map((data, i) => (
//                                                             <option key={i} value={data?.stateId}>
//                                                                 {data?.stateName}
//                                                             </option>
//                                                         ))}
//                                                 </select>
//                                                 {errors.state && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.state.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6">
//                                             <div className="form-group">
//                                                 <label>
//                                                     {" "}
//                                                     Job Title <span className="red-color">*</span>
//                                                 </label>
//                                                 <select
//                                                     name=""
//                                                     className="form-select"
//                                                     {...register("jobTitle", {
//                                                         required: {
//                                                             value: true,
//                                                             message: "This field is required",
//                                                         },
//                                                     })}
//                                                 >
//                                                     <option value="">Select Job Title</option>
//                                                     <option value="Admin">Admin</option>
//                                                     <option value="Area Manager">Area Manager</option>
//                                                     <option value="Cashier">Cashier</option>
//                                                     <option value="Director">Director</option>
//                                                     <option value="Dish Washer">Dish Washer</option>
//                                                     <option value="Manager">Manager</option>
//                                                     <option value="Site Manager">Site Manager</option>
//                                                 </select>
//                                                 {errors.jobTitle && (
//                                                     <span role="alert" className="error_text">
//                                                         {errors.jobTitle.message}
//                                                     </span>
//                                                 )}
//                                             </div>
//                                         </div>
//                                         {checkbox ? (
//                                             <div className="col-md-6">
//                                                 <div className="form-group">
//                                                     <label>
//                                                         Cashier Pin <span className="red-color">*</span>{" "}
//                                                     </label>
//                                                     <input
//                                                         type="text"
//                                                         className="form-control"
//                                                         placeholder="Cashier Pin"
//                                                         {...register("CashierPin", {
//                                                             required: "This field is required",
//                                                             pattern: {
//                                                                 value: /^[0-9+-]+$/,
//                                                                 message: "Enter valid number",
//                                                             },
//                                                             minLength: {
//                                                                 value: 4,
//                                                                 message: "Must be 4 Numbers",
//                                                             },
//                                                             maxLength: {
//                                                                 value: 4,
//                                                                 message: "Max 4 Numbers",
//                                                             },
//                                                         })}
//                                                     />
//                                                     {errors.CashierPin && (
//                                                         <span role="alert" className="error_text">
//                                                             {errors.CashierPin.message}
//                                                         </span>
//                                                     )}
//                                                 </div>
//                                             </div>
//                                         ) : (
//                                             ""
//                                         )}
//                                         <div className="col-md-6 d-flex  ms-auto checkbox_meal">
//                                             <div className="form-group">
//                                                 <label className=" personal_detail_label">
//                                                     <input
//                                                         type="checkbox"
//                                                         className="login_check"
//                                                         name="Remember Me"
//                                                         {...register("IsAllowCashierLogin", {
//                                                             onChange: (e) => setCheckbox(e.target.checked),
//                                                         })}
//                                                     />
//                                                     <span className="checkmark"> </span>
//                                                     Allow Login to Cashier Portal{" "}
//                                                 </label>
//                                             </div>
//                                         </div>
//                                         <div className="col-md-12 border-bottom my-4"></div>
//                                         {fields?.map((_, ind) => {
//                                             return (
//                                                 <React.Fragment key={ind}>
//                                                     <div className={`row ${fields?.length - 1 === ind ? "mt-4" : "border-bottom mt-4"}`}>
//                                                         <div className="col-md-3">
//                                                             <div className="form-group">
//                                                                 <label> Academic Year </label>
//                                                                 <select
//                                                                     name=""
//                                                                     className="form-select"
//                                                                     {...register(`employeePayRateSub.${ind}.academicYearId`, {
//                                                                         valueAsNumber: true
//                                                                     })}
//                                                                 >
//                                                                     <option value="0">Select School Year</option>
//                                                                     {schoolYearList &&
//                                                                         schoolYearList?.map((data, i) => (
//                                                                             <option key={i} value={data?.value}>
//                                                                                 {data?.label}
//                                                                             </option>
//                                                                         ))}
//                                                                 </select>
//                                                                 {errors?.employeePayRateSub?.[ind]?.academicYearId && (
//                                                                     <span role="alert" className="error_text">
//                                                                         {errors?.employeePayRateSub?.[ind]?.academicYearId.message}
//                                                                     </span>
//                                                                 )}
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-3">
//                                                             <div className="form-group">
//                                                                 <label>
//                                                                     {" "}
//                                                                     Pay Type
//                                                                 </label>
//                                                                 <select
//                                                                     name=""
//                                                                     className="form-select"
//                                                                     {...register(`employeePayRateSub.${ind}.payType`, {
//                                                                         validate: (val) => {
//                                                                             const academicYearIdAtZero = watch(`employeePayRateSub.0.academicYearId`);
//                                                                             const academicYearIdCurrent = watch(`employeePayRateSub.${ind}.academicYearId`);
//                                                                             if (ind === 0 && academicYearIdAtZero && String(academicYearIdAtZero).trim() !== '' && (!val || String(val).trim() === '')) {
//                                                                                 return "This field is required";
//                                                                             }
//                                                                             if (academicYearIdCurrent && String(academicYearIdCurrent).trim() !== '' && (!val || String(val).trim() === '')) {
//                                                                                 return "This field is required";
//                                                                             }

//                                                                             return true;
//                                                                         },
//                                                                     })}
//                                                                 >
//                                                                     <option value="">Select Pay Type</option>
//                                                                     <option value="fixed">Fixed</option>
//                                                                     <option value="hourly">Hourly</option>
//                                                                 </select>
//                                                                 {errors?.employeePayRateSub?.[ind]?.payType && (
//                                                                     <span role="alert" className="error_text">
//                                                                         {errors?.employeePayRateSub?.[ind]?.payType.message}
//                                                                     </span>
//                                                                 )}
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-3">
//                                                             <div className="form-group">
//                                                                 <label>
//                                                                     Pay Rate
//                                                                 </label>
//                                                                 <input
//                                                                     type="text"
//                                                                     className="form-control"
//                                                                     placeholder="Enter Pay Rate"
//                                                                     {...register(`employeePayRateSub.${ind}.payRate`, {
//                                                                         validate: (val) => {
//                                                                             const academicYearIdAtZero = watch(`employeePayRateSub.0.academicYearId`);
//                                                                             const academicYearIdCurrent = watch(`employeePayRateSub.${ind}.academicYearId`);
//                                                                             if (ind === 0 && academicYearIdAtZero && String(academicYearIdAtZero).trim() !== '' && (!val || String(val).trim() === '')) {
//                                                                                 return "This field is required";
//                                                                             }
//                                                                             if (academicYearIdCurrent && String(academicYearIdCurrent).trim() !== '' && (!val || String(val).trim() === '')) {
//                                                                                 return "This field is required";
//                                                                             }

//                                                                             return true;
//                                                                         },
//                                                                         valueAsNumber: true,
//                                                                         pattern: {
//                                                                             value: /^\d+(\.\d{1,2})?$/,
//                                                                             message:
//                                                                                 "Please enter correct format ex. 0.75 or 75",
//                                                                         },
//                                                                     })}
//                                                                 />
//                                                                 {errors?.employeePayRateSub?.[ind]?.payRate && (
//                                                                     <span role="alert" className="error_text">
//                                                                         {errors?.employeePayRateSub?.[ind]?.payRate.message}
//                                                                     </span>
//                                                                 )}
//                                                             </div>
//                                                         </div>
//                                                         <div className="col-md-3">
//                                                             {ind === 0 ? (
//                                                                 <>
//                                                                     <Button
//                                                                         className="theme_btn"
//                                                                         disabled={
//                                                                             watch(`employeePayRateSub.${0}.academicYearId`) &&
//                                                                                 watch(`employeePayRateSub.${0}.payType`) &&
//                                                                                 watch(`employeePayRateSub.${0}.payRate`) ? false : true
//                                                                         }
//                                                                         onClick={() => append({ academicYearId: 0, payRate: "", payType: "" })}
//                                                                         style={{ marginTop: "40px" }}
//                                                                     >
//                                                                         Add
//                                                                     </Button>
//                                                                 </>
//                                                             ) : (
//                                                                 <>
//                                                                     <Button
//                                                                         type="button"
//                                                                         onClick={() => remove(ind)}
//                                                                         className="delete_btn"
//                                                                         style={{ marginTop: "40px" }}
//                                                                     >
//                                                                         Delete
//                                                                     </Button>
//                                                                 </>
//                                                             )}
//                                                         </div>
//                                                     </div>
//                                                 </React.Fragment>
//                                             )
//                                         })}
//                                         <div className="col-md-12 text-end">
//                                             <Button type="submit" className="theme_btn me-2">
//                                                 Submit
//                                             </Button>
//                                             <Button
//                                                 className="cancel_btn"
//                                                 onClick={() => navigate(-1)}
//                                             >
//                                                 Cancel
//                                             </Button>
//                                         </div>
//                                     </div>
//                                 </form>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </Suspense>
//         </>
//     );
// }

// export default CreateUserManagment;


// new changes


import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
import { AcademicYearListApi } from "../../../../../../Action/AcademicYear";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { SwalMessage } from './../../../../../../Components/Reusable/SwalMessage/index';

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

function CreateUserManagment() {
    const { id } = useParams();

    const [schoolYearList, setSchoolYearList] = useState([]);
    const [distList, setDistList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [openCircle, setOpenCircle] = useState(false);
    const [siteList, setSiteList] = useState();
    const [preSchool, setPreSchool] = useState();
    const [checkbox, setCheckbox] = useState(false);

    const navigate = useNavigate();
    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login.LoginDetails?.accessToken);

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        clearErrors,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "employeePayRateSub"
    })

    const { fields: District, append: append1, remove: remove1 } = useFieldArray({
        control,
        name: "SchoolDatas"
    });

    const getDistrictList = async (useDe) => {
        setDistList([]);
        let seDist = {
            url: `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
        }
        const respo = await GetCallApi(seDist)
        if (respo?.status === 200 && respo?.data.success) {
            const dlist = respo?.data.payload?.map((sd) => {
                return {
                    schId: sd?.schoolDistrictId,
                    Districtname: sd?.districtName,
                    value: sd?.schoolDistrictId,
                    label: sd?.districtName,
                };
            });
            setDistList(dlist?.length > 0 ? dlist : []);
            if (useDe && dlist) {
                setTimeout(() => {
                    if (useDe?.schoolAlloationData?.length > 0) {
                        setValue('SchoolDatas', useDe?.schoolAlloationData?.map(data => ({
                            schoolDistrictId: data?.schoolDistrictId,
                            schoolId: data?.schoolIds?.map((c) => c) ?? []
                        })));
                    } else {
                        append1({ schoolDistrictId: "", schoolId: "" });
                    }
                }, 800);

            }
        }
    }

    const getRoleList = async (useDe) => {
        setRoleList([]);
        let seRole = {
            url: `/api/Roles/GetAllRoleData?userId=${LogDetail?.userId}`
        }
        let res = await GetCallApi(seRole)
        if (res?.status === 200 && res.data.success) {
            const rList = res?.data?.payload
            setRoleList(rList);
            if (useDe && rList) {
                setTimeout(() => {
                    setValue("roleId", useDe?.roleId)
                }, 500);
            }
        } else {
            setRoleList([])
        }
    }

    const getAcademicYear = async (useDe) => {
        let resp = await AcademicYearListApi();
        if (resp?.length > 0) {
            setSchoolYearList(resp);
            if (useDe && resp) {
                setTimeout(() => {
                    if (useDe?.employeePayRate?.length === 0) {
                        append({ academicYearId: 0, payRate: "", payType: "" });
                    } else {
                        setValue("employeePayRateSub", useDe?.employeePayRate);
                    }
                }, 500);
            }
        }
    }

    const getStateList = async (useDe) => {
        setSiteList([]);
        let sendStateData = {
            url: `/api/Common/GetStateList?userId=${LogDetail?.userId}`
        }
        const resp = await GetCallApi(sendStateData);
        if (resp?.status === 200 && resp?.data.success) {
            setSiteList(resp?.data.payload);
            if (useDe && resp?.data.payload) {
                setTimeout(() => {
                    setValue("state", useDe?.state);
                }, 500);
            }
        }
    }

    const getPrevDistrictList = async () => {
        setPreSchool([]);
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
        };
        try {
            let res = await GetCallApi(seData);
            if (res?.status === 200) {
                if (res.data.success) {
                    const districts = res.data.payload;

                    const schoolPromises = districts.map(async (sd) => {
                        setPreSchool([]);
                        const apiUrl = {
                            url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${sd?.schoolDistrictId}&userId=${LogDetail?.userId}`,
                        };
                        const res = await GetCallApi(apiUrl);
                        if (res?.status === 200) {
                            if (res.data.success) {
                                return res.data.payload.map((school) => ({
                                    value: school?.schoolId,
                                    label: school?.schoolName,
                                    schoolDistrictId: sd?.schoolDistrictId,
                                }))
                            }
                        }
                        return [];
                    });

                    const allSchools = (await Promise.all(schoolPromises)).flat();
                    setPreSchool(allSchools);
                    // if (schId && res.data.payload?.length > 0) {
                    //     setTimeout(() => {
                    //         setValue(`schoolId`,
                    //             schId
                    //         );
                    //     }, 500);
                    // }
                }
            }
        } catch (error) {
            console.error("Error fetching school districts or school lists:", error);
        }
    };

    const onSubmit = async (data) => {
        let sendData = {
            ...data,
            createdBy: LogDetail?.userId,
            SchoolDatas: data?.SchoolDatas?.flatMap((x) =>
                x.schoolId.map((school) => ({
                    schoolDistrictId: x.schoolDistrictId,
                    schoolId: school,
                }))
            ),
        };
        setOpenCircle(true);
        let subData = {
            url: `/api/User/CreateUser`,
            body: sendData,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
            },
        }
        const res = await PostCallApi(subData);
        setOpenCircle(false);
        if (res?.status === 200 && res?.data.success) {
            let userD = res?.data?.payload
            let empData = {
                userId: userD?.userId,
                createBy: LogDetail?.userId,
                employeePayRateSub: data?.employeePayRateSub?.filter((c) => c?.academicYearId !== null && c?.payRate !== null && c?.payType !== "")?.map((x) => {
                    return {
                        ...x,
                        payRate: parseInt(x?.payRate)
                    }
                }),
            }
            let apiUrl = {
                url: `/api/User/SaveEmployeePayRates`,
                body: empData
            }

            await PostCallApi(apiUrl);
            Swal.fire({
                position: "top-end",
                width: 400,
                text: res?.data?.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "#48953e",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
            reset();
            navigate(-1);
        } else {
            Swal.fire({
                position: "top-end",
                width: 400,
                text: res?.data?.message || "Something went wrong, please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };

    const EditUser = async (id) => {
        setOpenCircle(true);
        let seEdit = {
            url: `/api/User/GetUserDataById?userId=${id}`
        }
        const res = await GetCallApi(seEdit);
        setOpenCircle(false);
        if (res?.status === 200 && res?.data.success) {
            let useDe = res.data.payload;
            setValue("userId", useDe?.userId);
            setValue("firstName", useDe?.firstName);
            setValue("lastName", useDe?.lastName);
            setValue("email", useDe?.email);
            setValue("mobile", useDe?.mobile);
            setValue("jobTitle", useDe?.jobTitle);
            setValue("CashierPin", useDe?.cashierPin);
            setValue("IsAllowCashierLogin", useDe?.isAllowCashierLogin);
            setCheckbox(useDe?.isAllowCashierLogin);
            getRoleList(useDe);
            getDistrictList(useDe);
            getStateList(useDe);
            getAcademicYear(useDe);
        } else {
            setOpenCircle(false);
        }
    };

    useEffect(() => {
        if (id) {
            getPrevDistrictList();
            EditUser(id);
        } else {
            getPrevDistrictList();
            append({ academicYearId: 0, payRate: "", payType: "" });
            append1({ schoolDistrictId: "", schoolId: "" });
            getDistrictList();
            getStateList();
            getAcademicYear();
            getRoleList();
        }
        // eslint-disable-next-line
    }, [id]);

    const handleIOCMulti = (e, index) => {
        const selectedValues = e?.map(option => option?.value);
        setValue(`SchoolDatas.${index}.schoolId`, selectedValues);
        if (selectedValues.length > 0) {
            clearErrors(`SchoolDatas.${index}.schoolId`);
        }
    };

    const handleDistCheck = (e, index) => {
        setValue(`SchoolDatas.${index}.schoolId`, "")
        const selectedValues = watch(`SchoolDatas`)?.find((x, ind) => x?.schoolDistrictId === e && ind !== index);
        const selectedDist = distList?.find((x) => x?.value === e);
        if (selectedValues) {
            SwalMessage(selectedDist?.label + " already selected you cannot select same district")
            setValue(`SchoolDatas.${index}.schoolDistrictId`, '');
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openCircle}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="createUserManage">
                    <div className="main_wrapper">
                        <h4 className="all_title mb-4">{id ? "Edit" : "Create"} User</h4>
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action=""
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="primary-box"
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    First Name <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    {...register("firstName", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Max 50 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.firstName && (
                                                    <span role="alert" className="error_text">
                                                        {errors.firstName.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Last Name <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    {...register("lastName", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Max 50 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.lastName && (
                                                    <span role="alert" className="error_text">
                                                        {errors.lastName.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Phone Number <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Phone Number"
                                                    {...register("mobile", {
                                                        required: "This field is required",
                                                        pattern: {
                                                            value:
                                                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                                            message: "Enter valid phone number",
                                                        },
                                                    })}
                                                />
                                                {errors.mobile && (
                                                    <span role="alert" className="error_text">
                                                        {errors.mobile.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Email <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    {...register("email", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Max 50 characters",
                                                        },
                                                        pattern: {
                                                            value: /\S+@\S+\.\S+/,
                                                            message:
                                                                "Entered value does not match email format",
                                                        },
                                                    })}
                                                />
                                                {errors.email && (
                                                    <span role="alert" className="error_text">
                                                        {errors.email.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    Role Name <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("roleId", {
                                                        required: {
                                                            value: true,
                                                            message: "This field is required",
                                                        },
                                                    })}
                                                >
                                                    <option value="">Select Role Name</option>
                                                    {roleList &&
                                                        roleList?.map((data, i) => (
                                                            <option key={i} value={data?.roleId}>
                                                                {data?.roleName}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.roleId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.roleId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    State <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    defaultValue=""
                                                    {...register("state", {
                                                        required: "This field is required",
                                                        valueAsNumber: true,
                                                    })}
                                                >
                                                    <option value="">Select State</option>
                                                    {siteList &&
                                                        siteList?.map((data, i) => (
                                                            <option key={i} value={data?.stateId}>
                                                                {data?.stateName}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.state && (
                                                    <span role="alert" className="error_text">
                                                        {errors.state.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    {" "}
                                                    Job Title <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("jobTitle", {
                                                        required: {
                                                            value: true,
                                                            message: "This field is required",
                                                        },
                                                    })}
                                                >
                                                    <option value="">Select Job Title</option>
                                                    <option value="Admin">Admin</option>
                                                    <option value="Area Manager">Area Manager</option>
                                                    <option value="Cashier">Cashier</option>
                                                    <option value="Director">Director</option>
                                                    <option value="Dish Washer">Dish Washer</option>
                                                    <option value="Manager">Manager</option>
                                                    <option value="Site Manager">Site Manager</option>
                                                </select>
                                                {errors.jobTitle && (
                                                    <span role="alert" className="error_text">
                                                        {errors.jobTitle.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        {checkbox ? (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>
                                                        Cashier Pin <span className="red-color">*</span>{" "}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Cashier Pin"
                                                        {...register("CashierPin", {
                                                            required: "This field is required",
                                                            pattern: {
                                                                value: /^[0-9+-]+$/,
                                                                message: "Enter valid number",
                                                            },
                                                            minLength: {
                                                                value: 4,
                                                                message: "Must be 4 Numbers",
                                                            },
                                                            maxLength: {
                                                                value: 4,
                                                                message: "Max 4 Numbers",
                                                            },
                                                        })}
                                                    />
                                                    {errors.CashierPin && (
                                                        <span role="alert" className="error_text">
                                                            {errors.CashierPin.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        <div className="col-md-6 d-flex  ms-auto checkbox_meal">
                                            <div className="form-group">
                                                <label className=" personal_detail_label">
                                                    <input
                                                        type="checkbox"
                                                        className="login_check"
                                                        name="Remember Me"
                                                        {...register("IsAllowCashierLogin", {
                                                            onChange: (e) => setCheckbox(e.target.checked),
                                                        })}
                                                    />
                                                    <span className="checkmark"> </span>
                                                    Allow Login to Cashier Portal{" "}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 border-bottom my-4"></div>
                                        {District?.map((_, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className={`row ${District?.length - 1 === index ? "mt-4" : "border-bottom mt-4"}`}>
                                                        <div className={`row`}>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>
                                                                        School District <span className="red-color">*</span>{" "}
                                                                    </label>

                                                                    <select
                                                                        name=""
                                                                        className="form-select"
                                                                        {...register(`SchoolDatas.${index}.schoolDistrictId`, {
                                                                            required: "This field is required",
                                                                            onChange: (e) => handleDistCheck(e.target.value, index)
                                                                        })}
                                                                    >
                                                                        <option value="">Select School District</option>
                                                                        {distList &&
                                                                            distList?.map((data, i) => (
                                                                                <option key={i} value={data?.schId}>
                                                                                    {data?.Districtname}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                    {errors.SchoolDatas?.[index]?.schoolDistrictId && (
                                                                        <span role="alert" className="error_text">
                                                                            {errors.SchoolDatas?.[index]?.schoolDistrictId.message}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label>School <span className="red-color">*</span>{" "}</label>
                                                                    <Select
                                                                        isMulti
                                                                        name="type"
                                                                        options={preSchool?.filter((c) => c?.schoolDistrictId === watch(`SchoolDatas.${index}.schoolDistrictId`))}
                                                                        className="form-neselect"
                                                                        classNamePrefix="select"
                                                                        placeholder="Select School"
                                                                        {...register(`SchoolDatas.${index}.schoolId`, {
                                                                            required: "This field is required",
                                                                        })}
                                                                        value={preSchool?.filter((c) => c?.schoolDistrictId === watch(`SchoolDatas.${index}.schoolDistrictId`))?.filter((obj) =>
                                                                            watch(`SchoolDatas.${index}.schoolId`)?.includes(obj.value)
                                                                        )}
                                                                        onChange={(e) => handleIOCMulti(e, index)}
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                    {errors.SchoolDatas?.[index]?.schoolId && (
                                                                        <span role="alert" className="error_text">
                                                                            {errors.SchoolDatas?.[index]?.schoolId.message}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                {index === 0 ? (
                                                                    <>
                                                                        <Button
                                                                            className="theme_btn"
                                                                            onClick={() => append1({ schoolDistrictId: "", schoolId: "" })
                                                                            }
                                                                            style={{ marginTop: "40px" }}
                                                                        >
                                                                            Add
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <Button
                                                                            type="button"
                                                                            onClick={() => remove1(index)}
                                                                            className="delete_btn"
                                                                            style={{ marginTop: "40px" }}
                                                                        >
                                                                            Delete
                                                                        </Button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                        <div className="col-md-12 border-bottom my-4"></div>
                                        {fields?.map((_, ind) => {
                                            return (
                                                <React.Fragment key={ind}>
                                                    <div className={`row ${fields?.length - 1 === ind ? "mt-4" : "border-bottom mt-4"}`}>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label> Academic Year </label>
                                                                <select
                                                                    name=""
                                                                    className="form-select"
                                                                    {...register(`employeePayRateSub.${ind}.academicYearId`, {
                                                                        valueAsNumber: true
                                                                    })}
                                                                >
                                                                    <option value="0">Select School Year</option>
                                                                    {schoolYearList &&
                                                                        schoolYearList?.map((data, i) => (
                                                                            <option key={i} value={data?.value}>
                                                                                {data?.label}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                                {errors?.employeePayRateSub?.[ind]?.academicYearId && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.employeePayRateSub?.[ind]?.academicYearId.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>
                                                                    {" "}
                                                                    Pay Type
                                                                </label>
                                                                <select
                                                                    name=""
                                                                    className="form-select"
                                                                    {...register(`employeePayRateSub.${ind}.payType`, {
                                                                        validate: (val) => {
                                                                            const academicYearIdAtZero = watch(`employeePayRateSub.0.academicYearId`);
                                                                            const academicYearIdCurrent = watch(`employeePayRateSub.${ind}.academicYearId`);
                                                                            if (ind === 0 && academicYearIdAtZero && String(academicYearIdAtZero).trim() !== '' && (!val || String(val).trim() === '')) {
                                                                                return "This field is required";
                                                                            }
                                                                            if (academicYearIdCurrent && String(academicYearIdCurrent).trim() !== '' && (!val || String(val).trim() === '')) {
                                                                                return "This field is required";
                                                                            }

                                                                            return true;
                                                                        },
                                                                    })}
                                                                >
                                                                    <option value="">Select Pay Type</option>
                                                                    <option value="fixed">Fixed</option>
                                                                    <option value="hourly">Hourly</option>
                                                                </select>
                                                                {errors?.employeePayRateSub?.[ind]?.payType && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.employeePayRateSub?.[ind]?.payType.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="form-group">
                                                                <label>
                                                                    Pay Rate
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter Pay Rate"
                                                                    {...register(`employeePayRateSub.${ind}.payRate`, {
                                                                        validate: (val) => {
                                                                            const academicYearIdAtZero = watch(`employeePayRateSub.0.academicYearId`);
                                                                            const academicYearIdCurrent = watch(`employeePayRateSub.${ind}.academicYearId`);
                                                                            if (ind === 0 && academicYearIdAtZero && String(academicYearIdAtZero).trim() !== '' && (!val || String(val).trim() === '')) {
                                                                                return "This field is required";
                                                                            }
                                                                            if (academicYearIdCurrent && String(academicYearIdCurrent).trim() !== '' && (!val || String(val).trim() === '')) {
                                                                                return "This field is required";
                                                                            }

                                                                            return true;
                                                                        },
                                                                        pattern: {
                                                                            value: /^\d+(\.\d{1,2})?$/,
                                                                            message:
                                                                                "Please enter correct format ex. 0.75 or 75",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors?.employeePayRateSub?.[ind]?.payRate && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors?.employeePayRateSub?.[ind]?.payRate.message}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            {ind === 0 ? (
                                                                <>
                                                                    <Button
                                                                        className="theme_btn"
                                                                        disabled={
                                                                            watch(`employeePayRateSub.${0}.academicYearId`) &&
                                                                                watch(`employeePayRateSub.${0}.payType`) &&
                                                                                watch(`employeePayRateSub.${0}.payRate`) ? false : true
                                                                        }
                                                                        onClick={() => append({ academicYearId: 0, payRate: "", payType: "" })}
                                                                        style={{ marginTop: "40px" }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        type="button"
                                                                        onClick={() => remove(ind)}
                                                                        className="delete_btn"
                                                                        style={{ marginTop: "40px" }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })}
                                        <div className="col-md-12 text-end">
                                            <Button type="submit" className="theme_btn me-2">
                                                Submit
                                            </Button>
                                            <Button
                                                className="cancel_btn"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default CreateUserManagment;
