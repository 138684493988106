import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    Box,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    styled,
    Button,
    CircularProgress,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import "./index.scss";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import filter from "../../../../../Assets/PngImage/filter.png";
import ApproveEmployeePopup from "../../../../Admin/Pages/SessionGraph/Table/ApproveEmployeePopup";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#F1F6FC",
    },
    "&.pdf_download": {
        fontSize: "9px !important",
        padding: "3px !important",
        lineHeight: "inherit",
        ".MuiTableSortLabel-root": {
            ".MuiSvgIcon-root": { display: "none " },
        },
    },
    "&.sm_8": {
        fontSize: "8.3px !important",
        padding: "2px !important",
        lineHeight: "inherit",
        ".MuiTableSortLabel-root": {
            ".MuiSvgIcon-root": { display: "none " },
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#ffffff",
    },
    "&:nth-of-type(even)": {
        backgroundColor: "#F1F6FC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const headCells = [
    { id: "cashierDate", label: "Cashier Date" },
    { id: "cashierName", label: "Cashier Name" },
    { id: "hours", label: "Hours (HH:MM)" },
    { id: "totalUserAmount", label: "Total Amount" },
    { id: "status", label: "Status" },
];

function ApproveEmpHours() {

    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("prdName");
    const [paymentList, setPaymentList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schDistList, setSchDistList] = useState([]);
    const [schoolSelect, setSchoolSelect] = useState();
    const [checkbox, setCheckbox] = useState(false);
    const [openCircle, setOpenCircle] = useState(false);
    const [distId, setDistId] = useState();
    const [cDate, setDate] = useState();

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const {
        register,
        setValue,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const { fields } = useFieldArray({
        control,
        name: "subModels",
    });

    function convert(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }
    function convert1(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    useEffect(() => {
        getDistrictList();
        // eslint-disable-next-line
    }, []);

    function descendingComparator(a, b, orderBy) {
        if (
            b[orderBy]?.toString()?.toLowerCase() <
            a[orderBy]?.toString()?.toLowerCase()
        ) {
            return -1;
        }
        if (
            b[orderBy]?.toString()?.toLowerCase() >
            a[orderBy]?.toString()?.toLowerCase()
        ) {
            return 1;
        }

        return 0;
    }

    function getComparator(order, orderBy) {
        return order === "desc"
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }
    function stableSort(array, comparator) {
        const stabilizedThis = array?.map((el, index) => [el, index]);
        stabilizedThis?.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis?.map((el) => el[0]);
    }

    function EnhancedTableHead(props) {
        const { order, orderBy } = props
        // const createSortHandler = (property) => (event) => {
        //     onRequestSort(event, property);
        // };

        return (
            <TableHead>
                <TableRow>
                    <StyledTableCell>
                        <TableSortLabel className="svg_hide">
                            <label className=" personal_detail_label">
                                <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    disabled={formattedSearchResults?.length > 0 ? false : true}
                                    checked={checkbox}
                                    onChange={(e) => HandleCheckALl(e.target.checked)}
                                />
                                <span className="checkmark"> </span>
                            </label>
                        </TableSortLabel>
                    </StyledTableCell>
                    {headCells?.map((headCell) => (
                        <StyledTableCell
                            key={headCell?.id}
                            align="left"
                            sortDirection={orderBy === headCell?.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell?.id}
                                direction={orderBy === headCell?.id ? order : "asc"}
                                className="svg_hide"
                            // onClick={createSortHandler(headCell?.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell?.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(["asc", "desc"]).isRequired,
        orderBy: PropTypes.string.isRequired,
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const [searchField] = useState("");
    const formattedSearchResults = fields?.filter((user) => {
        if (searchField) {
            let state = false;
            headCells?.forEach((property) => {
                let value = String(user[property?.id]);
                if (typeof value === "string") {
                    if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
                        state = true;
                    }
                }
            });
            return state;
        } else return user;
    });

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    function convertDate(str) {
        var date = new Date(str?.replace(/-/g, "/")),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }

    const GetHourData = async () => {
        setOpenCircle(true);
        setPaymentList([]);
        setValue("subModels", []);
        if (schoolSelect) {
            let gData = {
                url: `/api/CashierHour/GetAllUserByCashierHour`,
                body: {
                    schoolDistrictId: distId,
                    schoolId: schoolSelect,
                    date: cDate,
                    userId: LogDetail?.userId,
                },
                headers: headers,
            };
            let resp = await PostCallApi(gData);
            setOpenCircle(false);
            if (resp?.status === 200) {
                if (resp.data.success) {
                    const cashList = resp.data.payload
                        ?.filter((x) => x !== null)
                        ?.map((cash) => {
                            return {
                                ...cash,
                                cashierDate: cash?.cashierDate
                                    ? convertDate(cash?.cashierDate?.split("T")[0])
                                    : cDate,
                                add: cash?.status === "Approve" ? true : false,
                            };
                        });
                    setPaymentList(cashList?.length > 0 ? cashList : []);
                    const data = cashList?.map((v) => {
                        return {
                            cashierHoursId: v?.cashierHoursId,
                            cashierDate: v?.cashierDate ? convert(v?.cashierDate) : "",
                            cashierName: v?.userName ? v?.userName : v?.cashierName,
                            hours: v?.hours,
                            status: v?.status ? v?.status : "Pending",
                            userId: v?.userId,
                            add: v?.status === "Approve" ? true : false,
                            totalUserAmount: v?.totalUserAmount
                        };
                    });
                    setValue("subModels", data);
                }
            }
        }
    };

    const HandleCheckALl = (val) => {
        setCheckbox(val);
        let alData = paymentList?.map((x, i) => {
            setValue(`subModels.${i}.add`, val);
            return {
                ...x,
                add: val,
            };
        });
        setPaymentList(alData);
    };

    const handleCheckMain = (val, id) => {
        let alData = paymentList?.map((x) => {
            if (x?.userId === id) {
                return {
                    ...x,
                    add: val,
                };
            }
            return x;
        });
        setPaymentList(alData);
        let ch = true;
        if (val) {
            let as = alData?.map((x) => {
                if (!x?.add) {
                    return (ch = false);
                }
                return undefined;
            });
            if (ch && val) {
                setCheckbox(val);
            } else {
                setCheckbox(false);
            }
            ch = as; //for warning remove
        } else {
            setCheckbox(val);
        }
    };

    const handleSave = async (data) => {
        let sendData = {
            subModels: data.subModels
                ?.filter((x) => x?.add === true)
                ?.map((x) => {
                    return {
                        ...x,
                        cashierDate: x?.cashierDate ? convert1(x?.cashierDate) : "",
                        status: "Approve",
                        schoolId: schoolSelect,
                    };
                }),
            createdBy: LogDetail?.userId,

        };

        let hours = {
            url: `/api/CashierHour/SaveCashierHours`,
            body: sendData,
            headers: headers,
        };
        let res = await PostCallApi(hours);
        if (res?.status === 200) {
            if (res.data.success) {
                GetHourData();
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: res.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            } else {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: res?.data?.message
                        ? res?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: res?.data?.message
                    ? res?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };

    const getSchoolList = async (id) => {
        setSchoolList([]);
        if (id) {
            setDistId(id);
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);

            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    let uslist = [...dlist];

                    setSchoolList(uslist?.length > 0 ? uslist : []);
                }
            }
        }
    };

    const getDistrictList = async () => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,

            headers: headers,
        };

        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setSchDistList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    return (
        <>
            <section className="approve_emp_hours">
                <div className="main_wrapper">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3 my-auto">
                                    <h4 className="all_title mb-4">Approve Employee Hours</h4>
                                </div>
                                <form onSubmit={handleSubmit(GetHourData)}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> District
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("schoolDistrictId", {
                                                        required: "This field is required",
                                                        onChange: (e) => getSchoolList(e.target.value),
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {schDistList &&
                                                        schDistList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.schoolDistrictId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.schoolDistrictId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    School <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("schoolId", {
                                                        onChange: (e) => setSchoolSelect(e.target.value),
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select School </option>
                                                    {schoolList &&
                                                        schoolList?.map((item, i) => (
                                                            <option value={item.value} key={i}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.schoolId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.schoolId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Date <span className="red-color">*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    defaultValue={cDate}
                                                    {...register("startDate", {
                                                        required: "This field is required",
                                                        onChange: (e) => setDate(e.target.value),
                                                        valueAsDate: true,
                                                    })}
                                                />
                                                {errors.startDate && (
                                                    <span role="alert" className="error_text">
                                                        {errors.startDate.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3 ms-auto mt-auto">
                                            <div className="form-group text-end filter-img">
                                                {openCircle ? (
                                                    <Button className="theme_btn text-end">
                                                        Applying Filter{" "}
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <>
                                                        <ApproveEmployeePopup
                                                            schId={schoolSelect}
                                                            cDate={cDate}
                                                            distId={distId}
                                                            GetScheduleCall={GetHourData}
                                                        />
                                                        <Button className="theme_btn ms-3 text-end" type="submit">
                                                            <img src={filter} className="me-1" alt="" />
                                                            Apply
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {paymentList?.filter((x) => x?.add === true)?.length > 0 ? (
                                    <div className="col-md-1 mt-auto ms-2">
                                        <Button
                                            onClick={handleSubmit(handleSave)}
                                            className="theme_btn me-3"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                ) : (
                                    ""
                                )}
                                <div className="mt-4 Emp_hour_all_list">
                                    <Paper className="table-box">
                                        <TableContainer>
                                            <Table>
                                                <EnhancedTableHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                // selectAllBtn={props?.selectAllBtn}
                                                />

                                                <TableBody>
                                                    {stableSort(
                                                        formattedSearchResults,
                                                        getComparator(order, orderBy)
                                                    )?.map((row, index) => {
                                                        return (
                                                            <StyledTableRow className="table_body" key={index + 1}>
                                                                <StyledTableCell key={index + 1}>
                                                                    <label className=" personal_detail_label">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="login_check"
                                                                            name="Remember Me"
                                                                            {...register(`subModels.${index}.add`, {
                                                                                onChange: (e) =>
                                                                                    handleCheckMain(e.target.checked, row?.userId),
                                                                            })}
                                                                        // checked={row?.add}
                                                                        />
                                                                        <span className="checkmark"> </span>
                                                                    </label>
                                                                </StyledTableCell>
                                                                {headCells?.map((column) => {
                                                                    const value = row[column?.id];
                                                                    if (column?.id === "hours") {
                                                                        return (
                                                                            <StyledTableCell key={column?.id}>
                                                                                <div className="col-md-6 form-group m-0">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="text"
                                                                                        {...register(`subModels.${index}.hours`, {
                                                                                            pattern: {
                                                                                                value: /^(?:[01]\d|2[0-3]):(?:[0-5]\d)$/,
                                                                                                // /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/,
                                                                                                message:
                                                                                                    "Please enter a valid time in the format HH:MM",
                                                                                            },
                                                                                        })}
                                                                                    />
                                                                                    {errors.subModels?.[index]?.hours && (
                                                                                        <span role="alert" className="error_text">
                                                                                            {errors.subModels?.[index]?.hours.message}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                            </StyledTableCell>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <StyledTableCell
                                                                            className="column_data"
                                                                            key={column?.id}
                                                                        >
                                                                            {column?.format && typeof value === "number"
                                                                                ? column?.format(value)
                                                                                : value}
                                                                        </StyledTableCell>
                                                                    );
                                                                })}
                                                            </StyledTableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        {formattedSearchResults?.length > 0 ? (
                                            ""
                                        ) : (
                                            <div className="er_nodata">No Data Found</div>
                                        )}
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default React.memo(ApproveEmpHours);
