import React, { Suspense, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ParentInformationTab() {
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const {
    register,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (LogDetail) {
      setValue("studentName", LogDetail?.firstName + " " + LogDetail?.lastName);
      setValue("parentsGuidId", LogDetail?.parentsGuidId);
      setValue("primaryPhoneNumber", LogDetail?.primaryPhoneNumber);
      setValue("alternatePhoneNumber", LogDetail?.alternatePhoneNumber);
      setValue("email", LogDetail?.email);
    }
    // eslint-disable-next-line
  }, [LogDetail]);
  return (
    <Suspense fallback={renderLoader()}>
      <div className="childinformation-section">
        <form action="">
          <div className="row">
            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Parent Name
                  {/* <span className="red-color">*</span> */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("studentName", {
                    required: "This field is required",
                  })}
                  disabled
                />
                {errors.studentName && (
                  <span role="alert" className="error_text">
                    {errors.studentName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Email
                  {/* <span className="red-color">*</span>{" "} */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("email", {
                    required: "This field is required",
                  })}
                  disabled
                />
                {errors.email && (
                  <span role="alert" className="error_text">
                    {errors.email.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Primary PhoneNumber
                  {/* <span className="red-color">*</span>{" "} */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("primaryPhoneNumber", {
                    required: "This field is required",
                  })}
                  disabled
                />
                {errors.primaryPhoneNumber && (
                  <span role="alert" className="error_text">
                    {errors.primaryPhoneNumber.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-6 margin-bottom">
              <div className="form-group">
                <label>
                  Alternate PhoneNumber
                  {/* <span className="red-color">*</span>{" "} */}
                </label>
                <input
                  type="text"
                  className="form-control"
                  {...register("alternatePhoneNumber", {
                    required: "This field is required",
                  })}
                  disabled
                />
                {errors.alternatePhoneNumber && (
                  <span role="alert" className="error_text">
                    {errors.alternatePhoneNumber.message}
                  </span>
                )}
              </div>
            </div>
            {/* <div className="col-md-12 text-end mt-3">
              <Button className="cancel_btn me-2">Cancel</Button>
              <Button type="submit" className="theme_btn ">
                Submit
              </Button>
            </div> */}
          </div>
        </form>
      </div>
    </Suspense>
  );
}

export default ParentInformationTab;
