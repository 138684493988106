import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function DailySalesReportTab(props) {
  const { tableRef, repoData, back, school } = props;
  // const { tableRef, repoData, startDate, endDate, back, school } = props;

  // function mathRound(val) {
  //   return (Math.round(val * 100) / 100).toFixed(2);
  // }
  let Time = new Date();

  return (
    <Suspense fallback={renderLoader()}>
      <div className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <img
              src={
                // downloadPdf
                //   ?
                require("../../../../../Assets/PngImage/Header_logo_1.png")
                // : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
              }
              alt=""
              width={100}
              height={80}
            />
            <div className="ms-2">
              <label
                style={{
                  fontSize: "18px",
                  color: "#2c2e35",
                  fontWeight: 600,
                  marginBottom: "7px",
                }}
              >
                Daily Sales Report{" "}
              </label>
              <div
                className="names"
                style={{
                  fontSize: "16px",
                  color: "#2c2e35",
                  fontWeight: 600,
                  marginBottom: "7px",
                }}
              >
                {school?.startDate ? school?.startDate : ""}
                {school?.endDate ? " - " + school?.endDate : ""}
              </div>
            </div>
          </div>
          <div>
            <label
              style={{
                fontSize: "18px",
                color: "#2c2e35",
                fontWeight: 600,
                marginBottom: "7px",
                width: "250px",
                whiteSpace: "pre-line",
              }}
            >
              {school?.schName}
            </label>
            <div
              className="names"
              style={{
                fontSize: "16px",
                color: "#2c2e35",
                fontWeight: 600,
                marginBottom: "7px",
              }}
            >
              {school?.distName}
            </div>
            <div
              className="names"
              style={{
                fontSize: "16px",
                color: "#2c2e35",
                fontWeight: 600,
                marginBottom: "7px",
              }}
            >
              {Time.toLocaleString()}
            </div>
            <div
              className="names"
              style={{
                fontSize: "16px",
                color: "#2c2e35",
                fontWeight: 600,
              }}
            >
              {school?.Name}
            </div>
          </div>
        </div>
        <div className="border-dash"></div>
        {/* <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">Daily Sales Report</h5> */}
        <div className="report-table mt-3">
          {/* <h6>Cash Audit</h6> */}
          {repoData?.billCount ? (
            <div className={"table-box "}>
              <h6 className="title-width">Bill Count</h6>
              {/* <h6 className="title-width">{mathRound(repoData?.billCount)}</h6> */}
              <h6 className="title-width">{repoData?.billCount?.toLocaleString("en-US", {style: "currency", currency: "USD" })}</h6>
            </div>
          ) : (
            ""
          )}
          {repoData?.avgBill ? (
            <div className={"table-box "}>
              <h6 className="title-width">Average Bill</h6>
              {/* <h6 className="title-width">{mathRound(repoData?.avgBill)}</h6> */}
              <h6 className="title-width">{repoData?.avgBill?.toLocaleString("en-US", { style: "currency", currency: "USD" })}</h6>
            </div>
          ) : (
            ""
          )}
          {repoData?.finalAmount ? (
            <div className={"table-box "}>
              <h6 className="title-width">Final Amount </h6>
              {/* <h6 className="title-width">
                {mathRound(repoData?.finalAmount)}
              </h6> */}
              <h6 className="title-width">{repoData?.finalAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" })}</h6>
            </div>
          ) : (
            ""
          )}

          {/* {reporttable?.map((text, i) => (
            <div
              key={i}
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width">{text?.tilte}</h6>
              <h6 className="title-width">{text?.qty}</h6>
              <h6 className="title-width">{text?.price}</h6>
            </div>
          ))} */}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </div>
    </Suspense>
  );
}

export default DailySalesReportTab;
