import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  LoginHeadList: [],
};

export const menuSlice = createSlice({
  name: "headerMenu",
  initialState,
  reducers: {
    LoginHeadMenuList(state, action) {
      state.LoginHeadList = action.payload;
    },

    resetMenu: () => initialState,
  },
});

export const { LoginHeadMenuList, resetMenu } = menuSlice.actions;

export default menuSlice.reducer;
