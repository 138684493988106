import React, { Suspense, useEffect, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import { Box, Tab, Modal } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import Swal from "sweetalert2";
import EmailHistory from "./EmailHistory";
import BalanceHistory from "./BalanceHistory";
import CustomerSheet from "./CustomerSheet";
import PaymentHistory from "./PaymentHistory";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function EditCreateCustomers() {
  const { id } = useParams();
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [siteList, setSiteList] = useState();

  const [open, setOpen] = useState(false);
  const [value, setValues] = React.useState("1");
  const handleOpen = () => setOpen(true);
  // const [derror, setDerror] = useState();

  const handleClose = () => {
    setOpen(false);
    // setDerror({ err: "" });
  };

  const handleChange = (event, newValue) => {
    setValues(newValue);
  };

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getSchoolList();
    getStateList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (parentList?.length > 0) {
      if (id) {
        getEditedData(id);
      }
    }
    // eslint-disable-next-line
  }, [parentList, id]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getEditedData = async (id) => {
    let sendData = {
      url: `/api/Customer/GetCustomerListById?customerId=${id}`,
      headers: headers,
    };

    let res = await GetCallApi(sendData);
    setOpen(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let data = res?.data?.payload;
        setValue("customerId", id);
        setValue("firstName", data?.firstName);
        setValue("lastName", data?.lastName);
        setValue("studentNumber", data?.studentNumber);
        setValue("headOfHouseholdName", data?.headOfHouseholdName);
        setValue("schoolDistrictId", data?.schoolDistrictId);
        getSchoolList(data?.schoolDistrictId, data?.schoolId);
        setValue("email", data?.email);
        setValue("phone1", data?.phone1);
        setValue("unallocatedBalance", data?.unallocatedBalance);
        setValue("address", data?.address);
        setValue("city", data?.city);
        setValue("st", data?.st);
        setValue("zipCode", data?.zipCode);
      }
    }
  };

  const onSubmit = async (data) => {
    let sendData = {
      ...data,
      createdBy: LogDetail?.userId,
    };
    let seData = {
      url: `/api/Customer/CreateCustomer`,
      body: sendData,
      headers: headers,
    };

    let respo = await PostCallApi(seData);
    if (respo?.status === 200) {
      if (respo.data.success) {
        navigate(-1);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo?.data?.message
            ? respo?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: respo?.data?.message
          ? respo?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  const getStateList = async () => {
    let seData = {
      url: `/api/Common/GetStateList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setSiteList(res.data.payload);
      }
    }
  };
  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setParentList(dlist?.length > 0 ? dlist : []);
      }
    }
  };
  const getSchoolList = async (id, val) => {
    setSchoolList([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          let uslist = [...dlist];

          setSchoolList(uslist?.length > 0 ? uslist : []);
          if (val) {
            setTimeout(() => {
              setValue("schoolId", val);
            }, 500);
          }
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_customer">
          <div className="main_wrapper">
            <div className="d-flex justify-content-end mb-3">
              <Button onClick={handleOpen} className="add-log-btn">
                Add Log
              </Button>
            </div>
            <TabContext value={value}>
              <div className="ProductCategories_hed">
                <div className="all_title ProductTitle">Customer History</div>
                <div className="d-flex justify-content-between align-items-center flex-wrap mb-1">
                  <Box className="tab_group d-flex justify-content-between ">
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                      className="tab_list"
                    >
                      <Tab
                        label="Update Details"
                        value="1"
                        className="tabs_link tab_1"
                      />
                      <Tab
                        label="Email History"
                        value="2"
                        className="tabs_link tab_2"
                      />
                      <Tab
                        label="Balance History"
                        value="3"
                        className="tabs_link tab_3"
                      />
                      <Tab
                        label="Customer Log Sheet"
                        value="4"
                        className="tabs_link tab_4"
                      />
                      <Tab
                        label="Payment History"
                        value="5"
                        className="tabs_link tab_5"
                      />
                    </TabList>
                  </Box>
                </div>
              </div>
              {/* Start tab content */}
              <TabPanel value="1" className="tab_panel p-0">
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              First Name<span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              {...register("firstName", {
                                required: "This field is required",
                              })}
                            />
                            {errors.firstName && (
                              <span className="formError errorMssg error_text">
                                {errors.firstName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Last Name <span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              {...register("lastName", {
                                required: "This field is required",
                              })}
                            />
                            {errors.lastName && (
                              <span className="formError errorMssg error_text">
                                {errors.lastName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Student Number{" "}
                              <span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Student Number"
                              disabled
                              {...register("studentNumber", {
                                required: "This field is required",
                              })}
                            />
                            {errors.studentNumber && (
                              <span className="formError errorMssg error_text">
                                {errors.studentNumber.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              {" "}
                              District <span className="red-color">*</span>
                            </label>
                            <select
                              name=""
                              className="form-select"
                              disabled
                              {...register("schoolDistrictId", {
                                required: "This field is required",
                                onChange: (e) => getSchoolList(e.target.value),
                              })}
                            >
                              <option value="">Select School District</option>
                              {parentList &&
                                parentList?.map((data, i) => (
                                  <option key={i} value={data?.value}>
                                    {data?.label}
                                  </option>
                                ))}
                            </select>
                            {errors.schoolDistrictId && (
                              <span role="alert" className="error_text">
                                {errors.schoolDistrictId.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              School <span className="red-color">*</span>
                            </label>
                            <select
                              name=""
                              className="form-select"
                              disabled
                              {...register("schoolId", {
                                required: "This field is required",
                              })}
                            >
                              <option value="">Search School</option>
                              {schoolList &&
                                schoolList?.map((data, i) => (
                                  <option key={i} value={data?.value}>
                                    {data?.label}
                                  </option>
                                ))}
                            </select>
                            {errors.schoolId && (
                              <span className="formError errorMssg error_text">
                                {errors.schoolId.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Parent Name<span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Parent Name"
                              {...register("headOfHouseholdName", {
                                required: "This field is required",
                              })}
                            />
                            {errors.headOfHouseholdName && (
                              <span className="formError errorMssg error_text">
                                {errors.headOfHouseholdName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Email <span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              {...register("email", {
                                required: "This field is required",
                              })}
                            />
                            {errors.email && (
                              <span className="formError errorMssg error_text">
                                {errors.email.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Phone Number<span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Phone Number"
                              {...register("phone1", {
                                required: "This field is required",
                              })}
                            />
                            {errors.phone1 && (
                              <span className="formError errorMssg error_text">
                                {errors.phone1.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Unallocated Balance{" "}
                              <span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              disabled
                              placeholder="Unallocated Balance"
                              {...register("unallocatedBalance", {
                                required: "This field is required",
                              })}
                            />
                            {errors.unallocatedBalance && (
                              <span className="formError errorMssg error_text">
                                {errors.unallocatedBalance.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Address<span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              {...register("address", {
                                required: "This field is required",
                              })}
                            />
                            {errors.address && (
                              <span className="formError errorMssg error_text">
                                {errors.address.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              City <span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              {...register("city", {
                                required: "This field is required",
                              })}
                            />
                            {errors.city && (
                              <span className="formError errorMssg error_text">
                                {errors.city.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              State <span className="red-color">*</span>
                            </label>
                            <select
                              name=""
                              className="form-select"
                              defaultValue=""
                              {...register("st", {
                                required: "This field is required",
                                // valueAsNumber: true,
                              })}
                            >
                              <option value="">Select State</option>
                              {siteList &&
                                siteList?.map((data, i) => (
                                  <option key={i} value={data?.stateName}>
                                    {/* <option key={i} value={data?.stateId}> */}
                                    {data?.stateName}
                                  </option>
                                ))}
                            </select>
                            {errors.st && (
                              <span role="alert" className="error_text">
                                {errors.st.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              ZipCode <span className="red-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="ZipCode"
                              {...register("zipCode", {
                                required: "This field is required",
                              })}
                            />
                            {errors.zipCode && (
                              <span className="formError errorMssg error_text">
                                {errors.zipCode.message}
                              </span>
                            )}
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Email Criteria <span className="red-color">*</span>
                                                        </label>
                                                        <select
                                                            name=""
                                                            className="form-select"
                                                            {...register("emailCriteria", {
                                                                required: "This field is required",
                                                            })}
                                                        >
                                                            <option value="all">All</option>
                                                            <option value="has emails">Has Emails</option>
                                                            <option value="no emails">No Emails</option>
                                                        </select>
                                                        {errors.emailCriteria && (
                                                            <span className="formError errorMssg error_text">
                                                                {errors.emailCriteria.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label>
                                                            Order by <span className="red-color">*</span>
                                                        </label>
                                                        <select
                                                            name=""
                                                            className="form-select"
                                                            {...register("Orderby", {
                                                                required: "This field is required",
                                                            })}
                                                        >
                                                            <option value="low balance">Low Balance</option>
                                                            <option value="high balance">High Balance</option>
                                                        </select>
                                                        {errors.Orderby && (
                                                            <span className="formError errorMssg error_text">
                                                                {errors.Orderby.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div> */}
                        <div className="col-md-12 text-end mt-3">
                          <Button type="submit" className="theme_btn me-2">
                            Submit
                          </Button>
                          <Button
                            className="cancel_btn"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="2" className="tab_panel p-0">
                <EmailHistory />
              </TabPanel>
              <TabPanel value="3" className="tab_panel p-0">
                <BalanceHistory />
              </TabPanel>
              <TabPanel value="4" className="tab_panel p-0">
                <CustomerSheet />
              </TabPanel>
              <TabPanel value="5" className="tab_panel p-0">
                <PaymentHistory />
              </TabPanel>
            </TabContext>
          </div>
        </section>

        {open && (
          <>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_open_data"
            >
              <Box sx={style} className="open_box">
                <div className="popup_detail_box">
                  <h6>Add Remark</h6>
                  <div className="col-md-12 p-3">
                    <div className="form-group">
                      <label>Remark</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Please add remark..."
                        //     {...register("lastName", {
                        //         required: "This field is required",
                        //     })}
                      />
                    </div>
                  </div>
                  <div className="popup_detail">
                    <button
                      type="button"
                      className="popup_btn save"
                      // onClick={handledelete}
                    >
                      Save Log
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default EditCreateCustomers;
