import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Collapse, Nav, Navbar, NavbarToggler } from "reactstrap";
import "./index.scss";
import header_logo from "../../../../../../Assets/PngImage/Header_logo_1.png";
import web_profile_img from "../../../../../../Assets/default_profile.jpg";
import profileicon from "../../../../../../Assets/SvgImage/profileicon.svg";
import logouticon from "../../../../../../Assets/SvgImage/logout-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { LoginUser } from "../../../../../../reducers/login";
import { Menu, MenuItem } from "@mui/material";
import { AiFillCaretDown } from "react-icons/ai";
import AssignHeadMenu from "./AssignHeadMenu";
import { resetMenu } from "../../../../../../reducers/menu";
import { Config } from "../../../../../../Utils/Config";
import { Badge, Button, Tooltip } from "@mui/material";
import { GetCallApi, PostCallApi } from './../../../../../../Action/Action';

function WebAdminNavigation() {
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNotification = Boolean(anchorEl);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const openNotification1 = Boolean(anchorEl1);
  const [notificationList, setNotificationList] = useState([]);
  const [readNotification, setReadNotification] = useState(false);

  let nav = useNavigate();
  let dispatch = useDispatch();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const handleClick = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick1 = (event) => {
    setAnchorEl1(event?.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleNav = () => {
    // localStorage.clear("expireDate");
    dispatch(LoginUser({ authenticate: false, authenticateStudent: false }));
    dispatch(resetMenu());
    nav("/", { replace: true });
  };

  useEffect(() => {
    getNotification();
    const interval = setInterval(() => getNotification(), 60000);
    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line
  }, [readNotification])

  const getNotification = async () => {
    let apiUrl = {
      url: `/api/Common/GetNotificationByParentId?userId=${LogDetail?.parentsGuidId}`,
      headers: headers
    }

    const resp = await GetCallApi(apiUrl);
    if (resp?.status === 200) {
      if (resp.data.success) {
        let notificationData = resp.data.payload?.filter((c) => c?.isRead !== true)?.map((notify, i) => {
          return {
            ...notify,
            user: notify.user?.firstName + " " + notify.user?.lastName,
            imgUser:
              notify.user?.profilePhoto === undefined ||
                notify.user?.profilePhoto === "" ||
                notify.user?.profilePhoto === null
                ? ""
                : `${Config.API_HOST_URL_live}${notify?.user?.profilePhoto}`,
          };
        });
        setNotificationList(notificationData);
      } else {
        setNotificationList([]);
      }
    }
  };

  const updateNotification = async (list) => {
    let apiData = {
      url: `/api/Common/UpdateNotification?notificationId=${list?.notificationId}`,
      headers: headers
    }
    const response = await PostCallApi(apiData)
    if (response?.status === 200) {
      if (response?.data?.success) {
        setReadNotification(!readNotification)
        handleClose1();
      }
    }
  }

  return (
    <>
      <section className={`parent_navigation`}>
        <div className="container-fluid">
          <div className="main_wrapper">
            <Navbar expand="lg" className="nav_bar">
              <div className="mobile_toggle">
                <NavLink to="/parent-dashboard">
                  <img
                    src={header_logo}
                    className="header_logo img-fluid"
                    alt="header_logo"
                  />
                </NavLink>
                <div className="mobile-profile">
                  <Nav
                    navbar
                    className="nav_action align-items-center"
                    onClick={handleClick}
                  >
                    <div className="user_d_box">
                      <img src={web_profile_img} alt="" className="me-2" />
                      {/* <SlUser className="icon" /> */}
                      {/* <div className="me-2 f-16-400">User Name</div> */}
                      <AiFillCaretDown />
                    </div>
                  </Nav>
                  <NavbarToggler
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 12H16"
                        stroke="#666666"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 6H21"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 18H21"
                        stroke="#fff"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </NavbarToggler>
                </div>
              </div>
              <Collapse isOpen={isOpen} navbar className="header_nav">
                <NavLink to="/parent-dashboard" className="main_logo">
                  <img
                    src={header_logo}
                    className="header_logo"
                    alt="header_logo"
                  />
                </NavLink>
                {/* <NavigateComponent /> */}
                <AssignHeadMenu />
                <Nav
                  navbar
                  className="nav_action align-items-center"
                >
                  {notificationList?.length > 0 ? (
                    <Tooltip title="Notification" placement="top-start" arrow>
                      <div>
                        <Button
                          className="header_search_button"
                          onClick={handleClick1}
                        >
                          <Badge
                            badgeContent={
                              notificationList?.length
                                ? notificationList?.length
                                : 0
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                                stroke="#2C2E35"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                                stroke="#2C2E35"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Badge>
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Notification" placement="top-start" arrow>
                      <div>
                        <Button className="header_search_button">
                          <Badge
                            badgeContent={
                              notificationList?.length
                                ? notificationList?.length
                                : "0"
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                                stroke="#2C2E35"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                                stroke="#2C2E35"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </Badge>
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  <div className="user_d_box"
                    onClick={handleClick}
                  >
                    <img
                      src={
                        LogDetail?.profilePhoto
                          ? `${Config.API_HOST_URL_live}${LogDetail?.profilePhoto}`
                          : web_profile_img
                      }
                      alt="Profile"
                      className="me-2"
                    />
                    {/* <SlUser className="icon" /> */}
                    {/* <div className="me-2 f-16-400">User Name</div> */}
                    <AiFillCaretDown />
                  </div>
                </Nav>
              </Collapse>
            </Navbar>
          </div>
        </div>
      </section>
      <Menu
        className="notification_list web_notification"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={openNotification}
        onClose={handleClose}
      >
        <MenuItem className="menu-link" onClick={handleClose}>
          <Link to="/parent-profile">
            <img src={profileicon} alt="Profile" className="me-2" /> Profile
          </Link>
        </MenuItem>
        <MenuItem className="menu-link" onClick={handleNav}>
          <img src={logouticon} alt="Logout" className="me-2" /> Logout
        </MenuItem>
      </Menu>
      <Menu
        className="notification_menu"
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl1}
        open={openNotification1}
        onClose={handleClose1}
      >
        {notificationList?.map((notifi, i) => {
          return (
            <MenuItem
              key={i}
              onClick={() => updateNotification(notifi)}
            >
              <div className="notification_box">
                <div className="img_box me-3">
                  <img src={notifi?.imgUser} alt="" />
                </div>
                <div className="notification_msg">
                  <div className="f-16-400">{notifi?.user}</div>
                  <div className="f-14-400">{notifi?.notificationText}</div>
                </div>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default React.memo(WebAdminNavigation);
