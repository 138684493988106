import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "schoolDistrictName", label: "School District" },
  { id: "schoolName", label: "School" },
  { id: "mealStatusType", label: "MealStatus Type" },
  { id: "mealType", label: "Meal Type" },
  { id: "rateType", label: "Rate Type" },
  { id: "federalRate", label: "Federal Rate" },
  { id: "stateRate", label: "State Rate" },
  { id: "totalRate", label: "Total Rate" },
  { id: "schoolYear", label: "School Year" },
  { id: "stateName", label: "State" },
];
function ReimbursementRate() {
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const [openCircle, setOpenCircle] = useState(false);

  const [prdList, setPrdList] = useState([]);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    SettingsUserData();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const SettingsUserData = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/ReimbursementRates/GetAllReimbursementRatesData?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);

            const SdData = res.data.payload?.map((sd, i) => {
              return {
                schoolDistrictId: sd?.rr?.schoolDistrictId,
                schoolDistrictName: sd?.schoolDistrictName,
                schoolName: sd?.schoolName,
                mealStatusType: sd?.rr?.mealStatusType,
                mealType: sd?.rr?.mealType,
                rateType: sd?.rr?.rateType,
                federalRate: sd?.rr?.federalRate?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                stateRate: sd?.rr?.stateRate?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                totalRate: sd?.rr?.totalRate?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                schoolYear: sd?.rr?.schoolYear,
                recordId: sd?.rr?.recordId,
                state: sd?.rr?.state,
                stateName: sd?.stateName,
                Action: (
                  <>
                    <Link
                      className="btn_edit me-2"
                      to={`edit/${sd?.rr?.recordId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete User"}
                      text={sd?.schoolDistrictName}
                      url={`/api/ReimbursementRates/DeleteReimbursementRatesData?recordId=${sd?.rr?.recordId}`}
                    />
                  </>
                ),
              };
            });
            setPrdList(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="user_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">
                    ReimbursementRate
                  </div>
                  <div className="d-flex">
                    {/* <Button to={""} className="cancel_btn me-4">
                  Download
                </Button> */}
                    <Link to={"Create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ReimbursementRate;
