import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import "./index.scss";
import Select from "react-select";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import { AcademicYearListApi } from "../../../../../Action/AcademicYear";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  // { id: "cashier", label: "Cashier" },
  { id: "status", label: "Status" },
  { id: "isMealServiceEnded", label: "Meal Service Ended" },
  { id: "startTime", label: "Start Date" },
  { id: "endTime", label: "End Date" },
  { id: "cashierName", label: "Cashier Name" },
  { id: "mealService", label: "Service Type" },
  { id: "mealServed", label: "Meal Served" },
  // { id: "totalAmount", label: "Amount" },
  { id: "cashExpectedAmount", label: "Cash Expected Amount" },
  { id: "cashCollected", label: "Cash Collected" },
  { id: "cashOverOrShort", label: "Cash Over/Short" },
  { id: "revenueOverOrShort", label: "Revenue Over/Short" },
  { id: "checkExpectedAmount", label: "Check Expected Amount" },
  { id: "totalCheckCollected", label: "Total Check Collected" },
  { id: "checkOverOrShort", label: "Check Over/Short" },
  // { id: "bulkMealEntry", label: "Bulk Meal Entry" },
];
function CashierSessionReport() {
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [reportDetails, setReportDetails] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [derror, setDerror] = useState();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getAcademicYear();
    // eslint-disable-next-line
  }, []);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getAcademicYear = async () => {
    let resp = await AcademicYearListApi();
    if (resp?.length > 0) {
      setSchoolYearList(resp);
    }
  }

  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolDistrictId,
                label: sd?.districtName,
              };
            });
            setParentList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = async (id) => {
    setSchoolList([]);
    setSchoolSelect([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,

        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          let uslist = [{ value: "all", label: "Select All" }, ...dlist];

          setSchoolList(uslist?.length > 1 ? uslist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getPayment = async (data) => {
    setPaymentList([]);
    let is_success = true;

    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }
    if (is_success) {
      setDerror({ err: "" });
      setOpenCircle(true);
      let sendData = {
        ...data,
        schoolDistrictId: data?.schoolDistrictId
          ? data?.schoolDistrictId
          : null,
        schoolId: schoolSelect?.map((x) => x.value),
        startDate: data?.startDate ? data?.startDate : null,
        endDate: data?.endDate ? data?.endDate : null,
        userId: LogDetail?.userId,
      };
      let distName = parentList?.filter(
        (x) => x.value === sendData?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();

      let sdate = data?.startDate ? data?.startDate?.toISOString() : null;
      let edate = data?.endDate ? data?.endDate?.toISOString() : null;
      let reData = {
        startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
        endDate: edate ? convertDate(edate?.split("T")[0]) : null,

        distName: distName,
        schName: schName,
        mealService: data?.mealService,
      };
      setReportDetails(reData);
      let seData = {
        url: `/api/Report/GetCashierEndMealAllSession`,
        body: sendData,
        headers: headers,
      };
      let res = await PostCallApi(seData);

      // Axios.post(`api/Report/GetCashierEndMealSessionReport`, sendData, {
      //   headers,
      // })
      //   .then((res) => {
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          const dList = res.data.payload?.map((x) => {
            return {
              ...x,
              endTime: x?.endTime ? new Date(x?.endTime)?.toLocaleString() : "",
              startTime: x?.startTime
                ? new Date(x?.startTime)?.toLocaleString()
                : "",
              isMealServiceEnded: x?.isMealServiceEnded ? "Close" : "Open",
              cashExpectedAmount: x?.cashExpectedAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashCollected: x?.cashCollected?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashOverOrShort: x?.cashOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              revenueOverOrShort: x?.revenueOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkExpectedAmount: x?.checkExpectedAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              totalCheckCollected: x?.totalCheckCollected?.toLocaleString(undefined, undefined),
              checkOverOrShort: x?.checkOverOrShort?.toLocaleString(undefined, undefined),
              Action: (
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`${x?.sessionId}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
              ),
            };
          });
          setPaymentList(dList);
          setOpenCircle(false);
        } else {
          setOpenCircle(false);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res?.data?.message
              ? res?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
      // })
      // .catch((e) => {
      //   console.log(e);
      //   setOpenCircle(false);
      // });
    }
  };
  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };
  return (
    <>
      {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="cashier_report-page">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <h4 className="all_title mb-4">Cashier Session Report </h4>
                <form onSubmit={handleSubmit(getPayment)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {parentList &&
                            parentList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                              selected.length &&
                              selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                                ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                                : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Start Date <span className="red-color">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("startDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.startDate && (
                          <span role="alert" className="error_text">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          End Date <span className="red-color">*</span>
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          {...register("endDate", {
                            required: "This field is required",
                            valueAsDate: true,
                            validate: (val) => {
                              if (watch("startDate") > val) {
                                return "Your start date can not greater than from end date";
                              }
                            },
                          })}
                        />
                        {errors.endDate && (
                          <span role="alert" className="error_text">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School Year </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("academicYearId", {
                            valueAsNumber: true
                          })}
                        >
                          <option value="0">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.academicYearId && (
                          <span role="alert" className="error_text">
                            {errors.academicYearId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img src={filter} className="me-1" alt="" />
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end mb-3 filter-img">
                  <Button className="theme_btn text-end">
                    <img src={filter} className="me-1" />
                    Apply
                  </Button>
                </div> */}
                </form>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={paymentList}
                  downloadFile={true}
                  reportDetails={reportDetails}
                  fileName="Cashier Session Report"
                  pdfHide={true}
                  actionFirst={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CashierSessionReport;
