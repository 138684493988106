import React, { Suspense, lazy, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusCircleDotted } from "react-icons/bs";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const templateHead = [
  //   { id: "Checkmark", label: "Checkmark" },
  { id: "status", label: "Status" },
  { id: "balanceupdated", label: "Balance Updated Manually" },
  { id: "studentName", label: "Student Name" },
  { id: "studentNumber", label: "Student Number" },
  { id: "schoolName", label: "School Name" },
  { id: "districtName", label: "District Name" },
  { id: "schoolreceives", label: "School Receives" },
  { id: "transactionFees", label: "Transaction Fees" },
  { id: "swanSolution", label: "Swan Solution Fees" },
  { id: "totalAmount", label: "Total Amount" },
  { id: "stripePayment", label: "Stripe Payment Reference" },
  { id: "paymentDate", label: "Payment Date" },
  { id: "paymentDateUtc", label: "Payment Date UTC" },
];
function PaymentList() {
  const [openCircle, setOpenCircle] = React.useState(false);
  const [payList, setPayList] = useState([]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    // setValue,
    formState: { errors },
  } = useForm();
  const GetPaymentList = async (data) => {
    let seData = {
      url: `/api/pay/paylist`,
      body: data,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let pyd = res.data.payload;
        setPayList(pyd);
      }
    }
  };
  return (
    <Suspense fallback={renderLoader()}>
      <section className="customer_listpage">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="product_box">
                <div className="all_title ProductTitle">
                  Payment History List
                </div>
                <Link to={"create"} className="theme_btn">
                  <BsPlusCircleDotted fontSize={24} className="me-3" />
                  Create
                </Link>
              </div>
              <form onSubmit={handleSubmit(GetPaymentList)}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Student Number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Number"
                        {...register("studentNumber")}
                      />
                      {errors.studentNumber && (
                        <span className="formError errorMssg error_text">
                          {errors.studentNumber.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>From Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="From Date"
                        {...register("fromdate")}
                      />
                      {errors.fromdate && (
                        <span className="formError errorMssg error_text">
                          {errors.fromdate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>To Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="To Date"
                        {...register("todate")}
                      />
                      {errors.todate && (
                        <span className="formError errorMssg error_text">
                          {errors.todate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Manually Updated <span className="red-color">*</span>
                      </label>
                      <select
                        name=""
                        className="form-select"
                        {...register("manuallyupdated", {
                          required: "This field is required",
                        })}
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      {errors.manuallyupdated && (
                        <span className="formError errorMssg error_text">
                          {errors.manuallyupdated.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" ms-auto mt-auto col-md-3">
                    <div className="form-group text-end filter-img">
                      {openCircle ? (
                        <Button className="theme_btn text-end">
                          Applying Filter{" "}
                          <CircularProgress
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        </Button>
                      ) : (
                        <Button className="theme_btn text-end" type="submit">
                          <img src={filter} className="me-1" alt="" />
                          Apply
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <ListTable
                headCells={templateHead}
                action={false}
                ListData={payList}
              />
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default PaymentList;
