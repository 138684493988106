import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress, Modal } from "@mui/material";
import { MdEdit, MdRemoveRedEye } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { PostCallApi } from "../../../../Action/Action";
// import Swal from "sweetalert2";
import { BsPlusSquareDotted } from "react-icons/bs";

const ListTable = lazy(() =>
  import("../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "noOfPeoeple", label: "Household Size" },
  { id: "academicYear", label: "Academic Year" },
  { id: "benefitsReceived", label: "Benefits Received" },
  { id: "totalActualIncome", label: "Total Actual Income" },
  { id: "paymentCycle", label: "Payment Cycle" },
  { id: "status", label: "Status", color: true },
  // { id: "totalHousholdAnuualIncome", label: "Annual Income" },
  // { id: "apartment", label: "Apartment" },
  // { id: "city", label: "City" },
  // { id: "state", label: "State" },
  { id: "firstName", label: "First Name" },
  { id: "fullName", label: "Full Name" },
  { id: "caseName", label: "Case Name" },
  { id: "caseNumber", label: "Case Number" },
  { id: "emailConfirmationAddress", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "createdOn", label: "Date" },
  { id: "address", label: "Address" },
  { id: "apartment", label: "APT Number" },
  { id: "city", label: "City" },
  { id: "stateName", label: "State" },
];

const anotherHead = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "birthDate", label: "DOB" },
  { id: "studentId", label: "Student ID" },
  { id: "schoolDistrictName", label: "District Name" },
  { id: "schoolName", label: "School Name" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function SubmittedSurvey() {
  // const [derror, setDerror] = useState();
  // const [reportDetails, setReportDetails] = useState();
  // const [userName, setUserName] = useState();
  const [open, setOpen] = useState(false);

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [openCircle, setOpenCircle] = useState(false);

  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    SettingsSiteData();
    // eslint-disable-next-line
  }, []);

  const SettingsSiteData = async () => {
    // setDerror({ err: "" });
    setPrdList([]);

    let sendKey = {
      parentGuidId: LogDetail?.parentsGuidId,
      academicId: 0,
    };

    let seData = {
      url: `/api/Survey/GetHouseHoldSurveryList`,
      body: sendKey,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        const SdData = res.data.payload?.map((sd, i) => {
          return {
            ...sd,
            id: i,
            actualIncome: sd?.actualIncome
              ? sd?.actualIncome?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
              : "",
            subTableList: sd?.studentList
              ?.filter((x) => x.isStudent === true)
              ?.map((std, index) => {
                return {
                  ...std,
                  id: index,
                  birthDate: std?.birthDate
                    ? new Date(std?.birthDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                    : "",
                };
              }),
            createdOn: sd?.createdOn
              ? new Date(sd?.createdOn)?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              : "",
            color:
              sd?.status === "Pending" || sd?.isFosterChild === true
                ? "#f3f3f3"
                : "",
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-2"
                  target="_blank"
                  to={`/submitted-surveys/view/${sd?.houseHoldSurveyId}/${sd?.schoolDistrictId}`}
                >
                  <MdRemoveRedEye fontSize={24} />
                </Link>

                {sd?.status === "Pending" ? (
                  <Link
                    type="button"
                    target="_blank"
                    className="btn_edit me-2"
                    to={`/submitted-surveys/edit/${sd?.houseHoldSurveyId}/${sd?.schoolDistrictId}`}
                  >
                    <MdEdit fontSize={24} />
                  </Link>
                ) : (
                  ""
                )}
              </>
            ),
          };
        });
        setPrdList(SdData);
      } else {
        setOpenCircle(false);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="survey_parent_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <h4 className="all_title mb-0">Submitted Surveys</h4>
                  <div className="d-flex">
                    <Link
                      to={"/submitted-surveys/create"}
                      className="theme_btn"
                    >
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                  actionFirst={true}
                  downloadFile={true}
                  fileName="Submitted Surveys"
                  anotherTable={true}
                  anotherheadCells={anotherHead}
                  pdfHide={true}
                />
              </div>
            </div>
          </div>
        </section>
        {open && (
          <>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  sx={{ color: "#fff", zIndex: () => 9999 }}
                  open={openCircle}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                {/* <div className="popup_delete_detail_box">
                                    <h6>{userName?.fullName}</h6>
                                    <div className="popup_delete_detail">
                                        <p className="delete_text">
                                            Are you sure you want to Approve
                                            <span> {userName?.fullName}</span>
                                        </p>
                                        <button
                                            type="button"
                                            className="popup_btn delete"
                                            onClick={() => handleApprove(userName?.houseHoldSurveyId)}
                                        >
                                            Approve
                                        </button>
                                        <button
                                            type="button"
                                            className="popup_btn cancel"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div> */}
              </Box>
            </Modal>
          </>
        )}
      </Suspense>
    </>
  );
}

export default SubmittedSurvey;
