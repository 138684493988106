import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Config } from "../../../../../Utils/Config";
import Select from "react-select";
import { AcademicYearListApi } from "../../../../../Action/AcademicYear";

const SmallListTable = lazy(() =>
  import("../../../../../Components/Reusable/SmallListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "date", label: "Date" },
  { id: "cashierName", label: "Cashier" },
  { id: "mealSession", label: "Meal Session" },
  { id: "cashExpected", label: "Cash Expected" },
  { id: "cashDeposit", label: "Cash Deposit" },
  { id: "cashOverOrShort", label: "Cash Over/Short" },
  { id: "checkExpected", label: "Check Expected" },
  { id: "checkDeposit", label: "Check Deposit" },
  { id: "checkOverOrShort", label: "Check Over/Short" },
];
function DepositReport() {
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [deposit, setDeposit] = useState([]);
  const [dropdata, setDropData] = useState();
  const [detail, setDetail] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [derror, setDerror] = useState();
  const [downloadPdf, setDownloadpdf] = useState(false);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const tableRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getAcademicYear();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);

  const getAcademicYear = async () => {
    let resp = await AcademicYearListApi();
    if (resp?.length > 0) {
      setSchoolYearList(resp);
    }
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Daily Deposit Report",
    sheet: "Deposit",
  });

  let Time = new Date();

  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
  };

  const handleDownloadPdf = async () => {
    const element = tableRef.current;
    let newPdfDate = Time.toLocaleDateString();
    // return false;
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            { align: "center" }
          );
        }
        doc.save(`Daily Deposit Report.pdf`);
        setDownloadpdf(false);
      });
  };

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setParentList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getSchoolList = async (id) => {
    setSchoolList([]);
    setSchoolSelect([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          let uslist = [{ value: "all", label: "Select All" }, ...dlist];

          setSchoolList(uslist?.length > 1 ? uslist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getDeposit = async (data) => {
    setDeposit([]);
    setDetail();
    let is_success = true;

    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }
    if (is_success) {
      setDerror({ err: "" });
      setOpenCircle(true);
      let sendData = {
        ...data,
        userId: LogDetail?.userId,
        schoolId: schoolSelect?.map((x) => x.value),
      };
      let distName = parentList?.filter(
        (x) => x.value === sendData?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();

      let sdate = data?.startDate ? data?.startDate?.toISOString() : null;
      let edate = data?.endDate ? data?.endDate?.toISOString() : null;
      let dropData = {
        startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
        endDate: edate ? convertDate(edate?.split("T")[0]) : null,
        distName: distName,
        schName: schName,
        schoolDistrictId: data?.schoolDistrictId
          ? data?.schoolDistrictId
          : null,
        schoolId: data?.schoolId ? data?.schoolId : null,
      };
      setDropData(dropData);
      let seData = {
        url: `/api/Report/GetDailyDepositReport`,
        body: sendData,
        headers: headers,
      };
      let res = await PostCallApi(seData);
      setOpenCircle(false);

      if (res?.status === 200) {
        if (res?.data.success) {
          let resVal = res?.data.payload;

          setDetail(resVal);
          const dList = res?.data.payload.endMealSessionList?.map((x) => {
            return {
              ...x,
              date: x.date
                ? new Date(x.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                : "",
              cashDeposit: x?.cashDeposit?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashExpected: x?.cashExpected?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashOverOrShort: x?.cashOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkDeposit: x?.checkDeposit?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkExpected: x?.checkExpected?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkOverOrShort: x?.checkOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
            };
          });
          let newAr = [
            ...dList,
            {
              date: "Total",
              // cashDeposit: resVal?.totalCashDeposite,
              // cashExpected: resVal?.totalCashExpected,
              // cashOverOrShort: resVal?.totalCashOverOrShort,
              // checkExpected: resVal?.totalCheckExpected,
              // checkDeposit: resVal?.totalCheckDeposite,
              // checkOverOrShort: resVal?.totalCheckOverOrShot,

              cashDeposit: resVal?.totalCashDeposite?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashExpected: resVal?.totalCashExpected?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              cashOverOrShort: resVal?.totalCashOverOrShort?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkExpected: resVal?.totalCheckExpected?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkDeposit: resVal?.totalCheckDeposite?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              checkOverOrShort: resVal?.totalCheckOverOrShot?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
              addbr: true,
            },
          ];
          setDeposit(newAr);
        } else {
          setDeposit([]);
          setDetail();
        }
      }
    }
  };
  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };
  return (
    <>
      {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <section className="deposit-page">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <h4 className="all_title mb-4">Daily Deposit Report</h4>
              <form onSubmit={handleSubmit(getDeposit)}>
                <div className="row">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label> District
                        <span className="red-color"> *</span>
                      </label>
                      <select
                        name=""
                        className="form-select"
                        {...register("schoolDistrictId", {
                          required: "This field is required",
                          onChange: (e) => getSchoolList(e.target.value),
                        })}
                      >
                        <option value="">Select School District</option>
                        {parentList &&
                          parentList?.map((data, i) => (
                            <option key={i} value={data?.value}>
                              {data?.label}
                            </option>
                          ))}
                      </select>
                      {errors.schoolDistrictId && (
                        <span role="alert" className="error_text">
                          {errors.schoolDistrictId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label> School
                        <span className="red-color"> *</span>
                      </label>
                      <Select
                        isMulti
                        name="type"
                        options={schoolList}
                        // closeMenuOnSelect={false}
                        // hideSelectedOptions={false}
                        className="form-neselect"
                        classNamePrefix="select"
                        placeholder="Select School"
                        value={schoolSelect ? schoolSelect : null}
                        onChange={(selected) => {
                          true &&
                            selected.length &&
                            selected.find((option) => option.value === "all")
                            ? handleIOCMulti(schoolList.slice(1))
                            : !true
                              ? handleIOCMulti(
                                (selected && selected.value) || null
                              )
                              : handleIOCMulti(selected);
                        }}
                        style={{ width: "100%" }}
                      />
                      {derror?.err && (
                        <span role="alert" className="d-block error_text">
                          {derror.err}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        Start Date <span className="red-color">*</span>{" "}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("startDate", {
                          required: "This field is required",
                          valueAsDate: true,
                        })}
                      />
                      {errors.startDate && (
                        <span role="alert" className="error_text">
                          {errors.startDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>
                        End Date <span className="red-color">*</span>{" "}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("endDate", {
                          required: "This field is required",
                          valueAsDate: true,
                          validate: (val) => {
                            if (watch("startDate") > val) {
                              return "Your start date can not greater than from end date";
                            }
                          },
                        })}
                      />
                      {errors.endDate && (
                        <span role="alert" className="error_text">
                          {errors.endDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label> School Year </label>
                      <select
                        name=""
                        className="form-select"
                        {...register("academicYearId", {
                          valueAsNumber: true
                        })}
                      >
                        <option value="0">Select School Year</option>
                        {schoolYearList &&
                          schoolYearList?.map((data, i) => (
                            <option key={i} value={data?.value}>
                              {data?.label}
                            </option>
                          ))}
                      </select>
                      {errors.academicYearId && (
                        <span role="alert" className="error_text">
                          {errors.academicYearId.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className=" ms-auto mt-auto col-md-4">
                    <div className="form-group text-end filter-img">
                      {dropdata?.scName || dropdata?.distName ? (
                        <>
                          <Button
                            className="cancel_btn me-3 text-end"
                            type="button"
                            onClick={() => setDownloadpdf(true)}
                          >
                            Pdf
                          </Button>
                          <Button
                            className="cancel_btn me-3 text-end"
                            type="button"
                            onClick={() => onDownload()}
                          >
                            Download
                          </Button>
                        </>
                      ) : (
                        ""
                      )}

                      {openCircle ? (
                        <Button className="theme_btn text-end">
                          Applying Filter{" "}
                          <CircularProgress
                            sx={{
                              color: "#fff",
                              marginLeft: "0.5rem",
                              height: "23px !important",
                              width: "23px !important",
                            }}
                          />
                        </Button>
                      ) : (
                        <Button className="theme_btn text-end" type="submit">
                          <img
                            src={filter}
                            className="me-1"
                            alt=""
                            height={23}
                          />
                          Apply
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <Suspense fallback={renderLoader()}>
                <div ref={tableRef} style={back}>
                  {dropdata?.scName || dropdata?.distName ? (
                    <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                      <div className="d-flex">
                        <img
                          src={
                            downloadPdf
                              ? require("../../../../../Assets/PngImage/Header_logo_1.png")
                              : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
                          }
                          alt=""
                          width={100}
                          height={80}
                        />
                        <div className="ms-3">
                          <label
                            style={{
                              fontSize: "18px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            Daily Deposit Report
                          </label>
                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            {dropdata?.startDate + " - " + dropdata?.endDate}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          style={{
                            fontSize: "18px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                            width: "250px",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {dropdata?.schName}
                        </label>
                        <div
                          className="names"
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          {dropdata?.distName}
                        </div>
                        <div
                          className="names"
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          {Time.toLocaleString()}
                        </div>
                        <div
                          className="names"
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                          }}
                        >
                          {LogDetail?.firstName + " " + LogDetail?.lastName}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {dropdata?.scName || dropdata?.distName ? (
                    <>
                      <SmallListTable
                        headCells={productHead}
                        downloadPdf={downloadPdf}
                        ListData={deposit}
                      />
                      <div className="outer_div">
                        <div
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          Total Bank Deposit Amount :{" "}
                          {/* {detail?.totalBankDepositAmount
                            ? detail?.totalBankDepositAmount
                            : ""}  */}
                          {detail?.totalBankDepositAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          Bank Deposit Date:{" "}
                          {detail?.bankDepositDate
                            ? detail?.bankDepositDate
                            : ""}
                        </div>

                        <div
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          Deposit Slip Number :
                          {detail?.depositSlipList
                            ? detail?.depositSlipList.toString()
                            : ""}
                        </div>
                        <div
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          Deposit Notes :
                          {detail?.depositNotes
                            ? detail?.depositNotes.toString()
                            : ""}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Suspense>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DepositReport;
