import React, { Suspense, lazy, useEffect, useState } from "react";
import {
    Backdrop,
    CircularProgress,
} from "@mui/material";
import "./index.scss";
import { GetCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ImgPopup from "../../../../Components/Reusable/ImgPopup";
import defaultProfile from "../../../../Assets/default_profile.jpg";
import { Config } from "../../../../Utils/Config";

const ListTable = lazy(() =>
    import("../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const templateHead = [
    { id: "profilePhoto", label: "Profile" },
    { id: "studentName", label: "Student Name" },
    { id: "schoolDistrictName", label: "District Name" },
    { id: "schoolName", label: "School Name" },
    { id: "grade", label: "Grade" },
    { id: "paymentStatus", label: "Payment Status" },
    { id: "studentBalance", label: "Student Balance" },
];

function StudentList() {
    const [stdList, setStdList] = useState([]);
    const [open, setOpen] = useState(false);


    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    useEffect(() => {
        getStudentList();
        // eslint-disable-next-line
    }, []);

    const getStudentList = async () => {
        setOpen(true)
        let apiUrl = {
            url: `/api/ParentModule/GetStudentListByParentID?parentGuidId=${LogDetail?.parentsGuidId}`,
            headers: headers
        }
        let res = await GetCallApi(apiUrl)
        setOpen(false)
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        ...sd,
                        profilePhoto: (
                            <ImgPopup
                                img={
                                    sd?.profilePhoto
                                        ? `${Config.API_HOST_URL_live}${sd?.profilePhoto}`
                                        : defaultProfile
                                }
                            />
                        ),
                        studentBalance: sd?.studentBalance?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                        Action: (
                            <Link
                                to={`/transfer-money/${sd?.studentGuidId}`}
                                className="add-log-btn ms-2"
                            >
                                Transfer Money
                            </Link>
                        ),
                    }
                });
                setStdList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="student_list">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="product_box">
                                    <div className="all_title ProductTitle">
                                        Student List
                                    </div>
                                </div>
                                <ListTable
                                    headCells={templateHead}
                                    action={true}
                                    ListData={stdList}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default StudentList;
