import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import Select from "react-select";
import { useForm } from "react-hook-form";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 800 : "90%",
  maxHeight: "80%",
  overflow: "auto",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
export default function ApproveEmployeePopup(props) {
  const { GetScheduleCall, distId, schId, cDate } = props;
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [openPopup, setOpenPopup] = useState(false);

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    watch,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const [derror, setDerror] = useState();
  const [breackList, setBreackList] = useState([]);

  const [openCircle, setOpenCircle] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [userSelect, setUserSelect] = React.useState([]);
  const [durTime, setDurTime] = React.useState();
  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpenPopup(true);
    getUserList(schId);
    setValue("startTime", "09:00");
    setValue("endTime", "18:00");
    timeDistance("09:00", "18:00");
  };
  const handleClose = () => {
    setOpenPopup(false);
    setDurTime();
    setUserSelect([]);
    setUserList([]);
    reset();
    setDerror({ err: "" });
  };

  useEffect(() => {
    generateBreakTimeSlots();
    // eslint-disable-next-line
  }, []);

  const getUserList = async (id) => {
    setUserList([]);

    if (id) {
      let seData = {
        url: `/api/School/GetUserDataBySchoolId?schoolId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      setOpenCircle(true);
      let res = await GetCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              ...sd,
              value: sd?.userId,
              label: sd?.userName,
            };
          });
          let uslist = [...dlist];

          setUserList(uslist?.length > 0 ? uslist : []);
        }
      }
    } else {
      setUserList([]);
    }
  };
  const handleIOCMulti = (e) => {
    setUserSelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const AddSchduleUser = async (data) => {
    let is_success = true;
    if (userSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one user" });
    }
    if (is_success) {
      let seData = {
        url: `/api/EmployeeShedualHour/CreateEmployeeShedualHour`,
        body: {
          ...data,
          listUserId: userSelect,
          createdBy: LogDetail?.userId,
          isDiscarded: false,
          isPublished: false,
          startDate: cDate + "T" + data?.startTime,
          endDate: cDate + "T" + data?.endTime,
          shiftDuration: durTime,
          schoolDistrictId: distId,
          schoolId: schId,
          // mealService: mlService,
          publishClass: "#f5803e",
        },
        headers: headers,
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          GetScheduleCall();
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          handleClose();
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res?.data?.message
              ? res?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };
  const generateBreakTimeSlots = () => {
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 0; // start time

    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      times[i] = ("0" + (hh % 24)).slice(-2) + ":" + ("0" + mm).slice(-2); // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }

    setBreackList(times);
    // return breakSlots;
  };
  function timeDistance(start, end) {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0) hours = hours + 24;
    if (start?.length > 1 && end?.length > 1) {
      setDurTime(
        (hours <= 9 ? "0" : "") +
        hours +
        ":" +
        (minutes <= 9 ? "0" : "") +
        minutes
      );
    }
  }

  return (
    <>
      <Button className="theme_btn " onClick={handleOpen}>
        Add
      </Button>

      {openPopup && (
        <>
          <Modal
            open={openPopup}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => 9999 }}
                open={openCircle}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div
                className="popup_delete_detail_box"
                style={{
                  display: "inline-block",
                  overflow: "auto",
                }}
              >
                <h6>Add User</h6>
                <div className="p-4">
                  <form onSubmit={handleSubmit(AddSchduleUser)}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label"> User</label>
                          <Select
                            isMulti
                            name="type"
                            options={userList}
                            // closeMenuOnSelect={false}
                            // hideSelectedOptions={false}
                            className="form-neselect"
                            classNamePrefix="select"
                            placeholder="Select User"
                            value={userList?.filter((obj) =>
                              userSelect?.includes(obj.value)
                            )}
                            onChange={handleIOCMulti}
                            style={{ width: "100%" }}
                          />

                          {derror?.err && (
                            <span role="alert" className="d-block error_text">
                              {derror.err}
                            </span>
                          )}
                        </div>
                      </div>

                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="meeting-time" className="form-label">
                            {" "}
                            Date
                          </label>
                          <input
                            name="meeting-type"
                            className="form-control"
                            type="date"
                            id="meeting-time"
                            defaultValue={`${clickedDate}`}
                            {...register("startDate", {
                              required: "This field is required",
                            })}
                          ></input>
                          {errors.startDate && (
                            <span role="alert" className="error_text">
                              {errors.startDate.message}
                            </span>
                          )}
                        </div>
                      </div> */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="meeting-time" className="form-label">
                            {" "}
                            Start Time
                          </label>
                          <input
                            name="meeting-type"
                            className="form-control"
                            type="time"
                            id="meeting-stime"
                            {...register("startTime", {
                              required: "This field is required",
                              onChange: (e) =>
                                timeDistance(
                                  e.target.value,
                                  getValues("endTime")
                                ),
                            })}
                          ></input>
                          {errors.startTime && (
                            <span role="alert" className="error_text">
                              {errors.startTime.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="meeting" className="form-label">
                            {" "}
                            end Time
                          </label>
                          <input
                            name="meeting-123"
                            className="form-control"
                            type="time"
                            id="meeting"
                            {...register("endTime", {
                              required: "This field is required",
                              onChange: (e) =>
                                timeDistance(
                                  getValues("startTime"),
                                  e.target.value
                                ),
                              validate: (val) => {
                                if (watch("startTime") >= val) {
                                  return "Your end time can not samller than from start time";
                                }
                              },
                            })}
                          ></input>
                          {errors.endTime && (
                            <span role="alert" className="error_text">
                              {errors.endTime.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label"> Break Time</label>
                          <select
                            className="form-select"
                            {...register("breakTime", {
                              required: "This field is required",
                              validate: (val) => {
                                if (durTime <= val) {
                                  return "Your break time can not greater than from total duration";
                                }
                              },
                            })}
                          >
                            {breackList &&
                              breackList?.map((x, i) => (
                                <option key={x} value={x}>
                                  {x}
                                </option>
                              ))}
                          </select>
                          {errors.breakTime && (
                            <span role="alert" className="error_text">
                              {errors.breakTime.message}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label"> Total Duration</label>
                          <input
                            className="form-control"
                            type="text"
                            placeholder="00:00"
                            disabled
                            value={durTime}
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="form-label"> Comment</label>
                          <textarea
                            name=""
                            rows={3}
                            placeholder="Comment"
                            className="form-control"
                            {...register("remark")}
                          // required: "This field is required",
                          ></textarea>
                          {errors.remark && (
                            <span role="alert" className="error_text">
                              {errors.remark.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <Button type="submit" className="theme_btn delete">
                      Submit
                    </Button>
                    <Button
                      type="button"
                      className="cancel_btn ms-3"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </form>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
