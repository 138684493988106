import { GetCallApi } from "./Action";

export const AcademicYearListApi = async () => {
     let schApi = {
      url: `/api/SchoolAcademicYear/GetAllSchoolAcademicYearData`,
    };

    let resp = await GetCallApi(schApi);
    if (resp?.status === 200 && resp?.data?.success) {
        let allD = resp?.data?.payload?.map((x) => {
          return {
            value: x?.academicYearId,
            label: x?.academicYear,
          };
        });
        return allD
    }
} 