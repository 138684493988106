import React, { Suspense, useState } from "react";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Radio,
} from "@mui/material";
import header_logo from "../../../../Assets/PngImage/Header_logo_1.png";
import "./index.scss";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function FamilyIncomeSurvey() {
  const [rows, setRows] = useState([]);

  const addRow = () => {
    setRows([...rows, { id: Date.now() }]);
  };

  const deleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const IncomeChart = [
    {
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
    {
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
    {
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
    {
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
    {
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
    {
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
    {
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
    {
      houseHoldSize: "For each additional household member",
      annul: "26,973",
      monthly: "2,248",
      twicePerMonth: "1,124",
      everyTwoWeeks: "1,038",
      Weekly: "519",
    },
  ];

  const [selectedOption, setSelectedOption] = useState("option1");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="FamilyIncomeSurvey">
          <div className="card">
            <div className="card-body">
              <div className="hed">
                <div className="d-flex">
                  <img
                    src={header_logo}
                    className="header_logo img-fluid"
                    alt="header_logo"
                  />
                  <h2 className="mx-auto">2023-24 Family Income Survey</h2>
                </div>
                <div className="info">
                  <p>Dear Parent/Guardian:</p>
                  <p>
                    This Family Income Survey provides your child's school a way
                    to collect household income information.
                  </p>
                  <p className="fw-bold">
                    This information is important because it helps your child's
                    school receive federal and state funding.
                  </p>
                  <p>
                    Schools receive certain federal and state funding (Learning
                    Assistance Program, teacher incentives, etc.) based on the
                    number of children from households that are at or below the
                    federal poverty level. This form will help us ensure your
                    child receives services they are entitled to.
                  </p>
                  <h5>Part 1: Eligibility</h5>
                  <p>
                    Figure out your total household income. Then look at the
                    income chart below. Find your household size. If your total
                    household income is equal to or less than the amount listed
                    for your household size, check the box.
                  </p>
                </div>
              </div>
              <hr />
              <div className="houseHoldForm">
                <div className="partC">
                  <div className="table">
                    <h4 className="text-center">Income Chart</h4>
                    <h5 className="text-center">
                      Effective from July 1, 2023 through June 30, 2024
                    </h5>
                    <div className="table-responsive">
                      <table className="table table-bordered mt-4">
                        <thead>
                          <tr>
                            <th rowSpan={2}>Check box that applies</th>
                            <th rowSpan={2}>Household Size</th>
                            <th className="text-center" colSpan={7}>
                              How Often Payment Is Received
                            </th>
                          </tr>
                          <tr>
                            <th>Annual</th>
                            <th>Monthly</th>
                            <th>Twice Per Month</th>
                            <th>Every Two Weeks</th>
                            <th>Weekly</th>
                          </tr>
                        </thead>
                        <tbody>
                          {IncomeChart?.map((item, i) => (
                            <tr key={i}>
                              <td>
                                <Radio
                                  value={`option${i + 1}`}
                                  checked={selectedOption === `option${i + 1}`}
                                  onChange={handleOptionChange}
                                  className="p-0"
                                />
                                {/* <input
                                                                type="radio"
                                                                value={`option${i + 1}`}
                                                                checked={selectedOption === `option${i + 1}`}
                                                                onChange={handleOptionChange}
                                                            /> */}
                              </td>
                              <td>
                                {item?.houseHoldSize
                                  ? item?.houseHoldSize
                                  : i + 1}
                              </td>
                              <td>{item?.annul}</td>
                              <td>{item?.monthly}</td>
                              <td>{item?.twicePerMonth}</td>
                              <td>{item?.everyTwoWeeks}</td>
                              <td>{item?.Weekly}</td>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <Radio
                                value={`option9`}
                                checked={selectedOption === `option9`}
                                onChange={handleOptionChange}
                                className="p-0"
                              />
                            </td>
                            <td className="text-start" colSpan={6}>
                              Household does not qualify
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p>
                      <b>HOUSEHOLD :</b> is defined as all persons, including
                      parents, children, grandparents, and all people related or
                      unrelated who live in your home and share living expenses.
                      If you're applying for a household with a foster child,
                      you may include the foster child in the total household
                      size.
                    </p>
                    <p>
                      <b>HOUSEHOLD INCOME :</b> is considered to be any taxable
                      income each household member received before taxes. This
                      includes wages, social security, pension, unemployment,
                      welfare, child support, alimony, and any other cash
                      income. If including a foster child as part of the
                      household, you must also include the foster child's
                      personal income. Do not count foster payments as income.
                    </p>
                  </div>
                </div>
                <div className="partA">
                  <h5>
                    Part 2: Students: Please fill in the following information
                    for all children living with you that are attending school.
                  </h5>
                  <div className="table-responsive">
                    <table className="table table-bordered mt-4">
                      <thead>
                        <tr>
                          <th>Student's Last Name</th>
                          <th>Student's First Name</th>
                          <th>MI</th>
                          <th>Date of Birth</th>
                          <th>School</th>
                          <th>Grade</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <input type="text" className="form-control" />
                          </td>
                          <td>
                            <input type="text" className="form-control" />
                          </td>
                          <td>
                            <input type="text" className="form-control" />
                          </td>
                          <td>
                            <input type="date" className="form-control" />
                          </td>
                          <td>
                            <select className="form-select">
                              <option value="0">Test</option>
                            </select>
                          </td>
                          <td>
                            <select className="form-select">
                              <option value="0">Test</option>
                            </select>
                          </td>
                          <td>
                            <Button className="theme_btn" onClick={addRow}>
                              <AiOutlinePlus className="icon" />
                            </Button>
                          </td>
                        </tr>
                        {rows?.map((row) => (
                          <tr key={row.id}>
                            <td>
                              <input type="text" className="form-control" />
                            </td>
                            <td>
                              <input type="text" className="form-control" />
                            </td>
                            <td>
                              <input type="text" className="form-control" />
                            </td>
                            <td>
                              <input type="date" className="form-control" />
                            </td>
                            <td>
                              <select className="form-select">
                                <option value="0">Test</option>
                              </select>
                            </td>
                            <td>
                              <select className="form-select">
                                <option value="0">Test</option>
                              </select>
                            </td>
                            <td>
                              <Button
                                className="cancel_btn"
                                onClick={() => deleteRow(row.id)}
                              >
                                <AiOutlineClose className="icon" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="partD">
                  <h5>Part 3: Signature</h5>
                  <p>
                    I certify (promise) that all information on this application
                    is true and that all income is reported. I understand that
                    the school will get state and federal funds based on the
                    information I give. I understand that school officials may
                    verify (check) this information. I understand if I purposely
                    give false information that I may be prosecuted. I
                    understand my child's poverty status may ho chared with
                    other allowed by law
                  </p>
                  <div className="row form">
                    <div className="col-md-3 input_div">
                      <label>* First Name</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>* Last Name</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>Email</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>Phone</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>Address</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>APT #</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>City</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>State</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>Zip</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="partB">
                  <h5>Part 4: Other Benefits</h5>
                  <p>
                    Please check the box in front of the programs that you wish
                    to share your child's family income survey status with in
                    order to qualify for a reduction in fees.
                  </p>
                  <div className="benifits mb-4">
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="Event Fees"
                    />
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="Field Trips"
                    />
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="Advanced Placement Tests"
                    />
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="Running Start Books"
                    />
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="Athletic Fees"
                    />
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="Associated Student Body (ASB) Fees"
                    />
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="Pre-College Exams (PSAT/SAT/ACT)"
                    />
                    <FormControlLabel
                      className="benifits_checkbox"
                      required
                      control={<Checkbox />}
                      label="College Application Fees"
                    />
                  </div>
                  <div className="row form">
                    <div className="col-md-3 input_div">
                      <label>First Name</label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="col-md-3 input_div">
                      <label>Last Name</label>
                      <input type="text" className="form-control" />
                    </div>
                  </div>
                </div>
                <div className="footer mt-3">
                  <Button className="theme_btn mb-3">Submit</Button>
                  <p>
                    OSPI provides equal access to all programs and services
                    without discrimination based on sex, race, creed, religion,
                    color, national origin, age, honorably discharged veteran or
                    military status, sexual orientation, gender expression,
                    gender identity, disability, or the use of a trained dog
                    guide or service animal by a person with a disability.
                    Questions and complaints of alleged discrimination should be
                    directed to the Director of the Office of Equity and Civil
                    Rights at 360-725-6162/TTY: 360-664-3631; or P.O. Box 47200,
                    Olympia, WA 98504-7200; or-equity@k12.wa.us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default FamilyIncomeSurvey;
