import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Config } from "../../../../../Utils/Config";
import Select from "react-select";

const SmallListTable = lazy(() =>
    import("../../../../../Components/Reusable/SmallListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const staticMealEquivalents = [
    {
        isTitle : true,
        name: "Meal Equivalents",
    },
    {
        name: "Miscellaneous",
        Current: 9781,
        Previous: 58907
    },
    {
        isTitle: true,
        name: "Meals - Pattern",
    },
    {
        name: "Breakfast",
        Current: 31445,
        Previous: 172623
    },
    {
        name: "Lunch",
        Current: 61305,
        Previous: 363309
    },
    {
        name: "Snack",
        Current: 1558,
        Previous: 10241
    },
    {
        name: "Milk",
        Current: 2381,
        Previous: 14618
    },
    {
        isTotal: true,
        name: "TOTAL MEALS",
        Current: 106470,
        Previous: 619698
    },
    {
        isTitle: true,
        name: "Sales - Cash",
    },
    {
        name: "Breakfast",
        Current: 51.00,
        Rate: 0.000,
        Previous: 290.50,
        PreviousRate: 0.000
    },
    {
        name: "Lunch",
        Current: 1130.25,
        Rate: 0.011,
        Previous: 7870.25,
        PreviousRate: 0.013
    },
    {
        name: "Milk",
        Current: 0.00,
        Rate: 0.000,
        Previous: 0.00,
        PreviousRate: 0.000
    },
    {
        isTitle: true,
        name: "Sales - Government Reimbursement",
        PreviousRate: 0.000
    },
    {
        name: "Federal Reimbursements",
        Current: 335349.04,
        Rate: 3.150,
        Previous: 1900690.76,
        PreviousRate: 3.067
    },
    {
        isTitle: true,
        name: "Sales - Other",
    },
    {
        name: "A La Carte",
        Current: 31222.55,
        Rate: 0.293,
        Previous: 191415.46,
        PreviousRate: 0.309
    },
    {
        name: "Debit Sales",
        Current: 1181.95,
        Rate: 0.011,
        Previous: 6509.92,
        PreviousRate: 0.011
    },
    {
        name: "Special Events",
        Current: 4575.48,
        Rate: 0.043,
        Previous: 24166.96,
        PreviousRate: 0.039
    },
    {
        name: "Other",
        Current: 6008.25,
        Rate: 0.056,
        Previous: 30357.80,
        PreviousRate: 0.049
    },
    {
        isTotal: true,
        name: "TOTAL SALES",
        Current: 379518.52,
        Rate: 3.565,
        Previous: 2161301.65,
        PreviousRate: 3.49
    },
    {
        isTitle: true,
        name: "Cost of Sales"
    },
    {
        isTotal: true,
        name: "TOTAL COST OF SALES",
        Current: 100610.12,
        Rate: 0.94,
        Previous: 585406.98,
        PreviousRate: 0.94
    },
    {
        name: "Salaries & Wages",
        Current: 186658.79,
        Rate: 1.753,
        Previous: 935933.12,
        PreviousRate: 1.510
    },
    {
        name: "Payroll Taxes",
        Current: 26132.23,
        Rate: 0.245,
        Previous: 131030.64,
        PreviousRate: 0.211
    },
    {
        name: "Other Payroll Costs",
        Current: 5435.26,
        Rate: 0.051,
        Previous: 33572.46,
        PreviousRate: 0.054
    },
    {
        name: "Benefits",
        Current: 2975.33,
        Rate: 0.028,
        Previous: 15478.39,
        PreviousRate: 0.025
    },
    {
        isTotal: true,
        name: "TOTAL LABOR",
        Current: 221201.61,
        Rate: 2.08,
        Previous: 1116014.61,
        PreviousRate: 1.80
    },
    {
        isTitle: true,
        name: "Direct Costs",
    },
    {
        name: "Cafeteria Supplies",
        Current: 14090.75,
        Rate: 0.132,
        Previous: 75261.51,
        PreviousRate: 0.121
    },
    {
        name: "Vehicle Expense",
        Current: 0.00,
        Rate: 0.000,
        Previous: 0.00,
        PreviousRate: 0.000
    },
    {
        name: "General Insurance",
        Current: 13283.15,
        Rate: 0.125,
        Previous: 75645.56,
        PreviousRate: 0.122
    },
    {
        name: "Uniforms",
        Current: 0.00,
        Rate: 0.000,
        Previous: 266.19,
        PreviousRate: 0.000
    },
    {
        name: "Office Supplies",
        Current: 81.58,
        Rate: 0.001,
        Previous: 1698.85,
        PreviousRate: 0.003
    },
    {
        name: "Miscellaneous",
        Current: 3584.37,
        Rate: 0.034,
        Previous: 22467.30,
        PreviousRate: 0.036
    },
    {
        name: "Management Fee *",
        Current: 25552.78,
        Rate: 0.240,
        Previous: 148727.46,
        PreviousRate: 0.240
    },
    {
        isTotal: true,
        name: "TOTAL DIRECT EXPENSE",
        Current: 56592.63,
        Rate: 0.53,
        Previous: 324066.87,
        PreviousRate: 0.52
    },
    {
        isTotal: true,
        name: "TOTAL COST & EXPENSE",
        Current: 378404.37,
        Rate: 3.55,
        Previous: 2025488.46,
        PreviousRate: 3.27
    },
    {
        isTotal: true,
        name: "OPERATING PROFIT/(LOSS)",
        Current: 1114.15,
        Rate: 0.01,
        Previous: 135813.18,
        PreviousRate: 1.28
    },
    {
        isTitle: true,
        name: "Inventory",
    },
    {
        isTitle: true,
        name: "Food",
    },
    {
        name: "Opening Inventory",
        Current: 44229.76,
        Previous: 100676.09
    },
    {
        name: "Closing Inventory",
        Current: 43181.43,
        Previous: 43181.43
    },
    {
        isTitle: true,
        name: "Supplies"
    },
    {
        name: "Opening Inventory",
        Current: 27812.13,
        Previous: 33400.02
    },
    {
        name: "Closing Inventory",
        Current: 25472.35,
        Previous: 25472.35
    },
    {
        isTitle : true,
        name: "Inventory Impact",
        Current: 3388.11,
        Previous: 65422.33
    },
    {
        isTitle: true,
        name: "Operating Profit/(Loss)",
        Current: 1114.15,
        Previous: 135813.18
    },
    {
        isTitle: true,
        name: " After Inventory Impact",
        Current: -2273.96,
        Previous: 70390.85
    },
    {
        isTotal: true,
        name: "Commodity Value",
        Current: 29421.31,
        Previous: 163330.23
    }
]

const productHead = [
    { id: "accountName", label: "Accounting Period Ending" },
    // { id: "enrollment", label: "Enrollment" },
    { id: "currentMonth", label: "Current Month" },
    { id: "costMealMonth", label: "(Month) Cost/Meal" },
    { id: "yearToDate", label: "Year To Date" },
    { id: "costMealYear", label: "(Year) Cost/Meal" },
];
function OperatingStatementReport() {
    const [parentList, setParentList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [deposit, setDeposit] = useState([]);
    const [dropdata, setDropData] = useState();
    const [openCircle, setOpenCircle] = useState(false);
    const [schoolSelect, setSchoolSelect] = useState([]);
    const [derror, setDerror] = useState();
    const [downloadPdf, setDownloadpdf] = useState(false);
    const tableRef = useRef(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getDistrictList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (downloadPdf) {
            handleDownloadPdf();
        }
        // eslint-disable-next-line
    }, [downloadPdf]);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Operating Statement Report",
        sheet: "Operating Statement",
    });

    let Time = new Date();

    const back = {
        maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    };

    const handleDownloadPdf = async () => {
        const element = tableRef.current;
        let newPdfDate = Time.toLocaleDateString();
        // return false;
        const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        doc
            .html(element, {
                margin: [20, 10, 20, 10],
                autoPaging: "text",
                html2canvas: { scale: 0.57 },
            })
            .then(() => {
                let totalPages = doc.internal.pages.length - 1;
                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setTextColor(150);
                    doc.text(
                        newPdfDate,
                        doc.internal.pageSize.width / 2,
                        doc.internal.pageSize.getHeight() - 10,
                        { align: "center" }
                    );
                }
                doc.save(
                    `Operating Statement Report.pdf`
                );
                setDownloadpdf(false);
            });
    };

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getDistrictList = async () => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setParentList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getSchoolList = async (id) => {
        setSchoolList([]);
        setSchoolSelect([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);
            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 1 ? uslist : []);
                }
            }
        } else {
            setSchoolList([]);
        }
    };

    const getDeposit = async (data) => {
        setDeposit([]);
        let is_success = true;

        if (schoolSelect?.length === 0) {
            is_success = false;
            setDerror({ err: "Select minimum one school" });
        }
        if (is_success) {
            setDerror({ err: "" });
            setOpenCircle(true);
            let sendData = {
                ...data,
                userId: LogDetail?.userId,
                schoolId: schoolSelect?.map((x) => x.value),
            };
            let distName = parentList?.filter(
                (x) => x.value === sendData?.schoolDistrictId
            )[0]?.label;
            let schName =
                schoolSelect?.length === schoolList?.length - 1
                    ? "All"
                    : schoolSelect?.map((x) => x.label).toString();

            let dropData = {
                distName: distName,
                schName: schName,
                schoolDistrictId: data?.schoolDistrictId
                    ? data?.schoolDistrictId
                    : null,
                schoolId: data?.schoolId ? data?.schoolId : null,
            };
            setDropData(dropData);
            let seData = {
                url: `/api/Report/GetDCRTReport`,
                body: sendData,
                headers: headers,
            };
            let res = await PostCallApi(seData);
            setOpenCircle(false);

            if (res?.status === 200) {
                if (res?.data.success) {
                    const dList = staticMealEquivalents?.map((x) => {
                        return {
                            ...x,
                            accountName: x?.name,
                            currentMonth: x?.Current,
                            yearToDate: x?.Previous,
                            costMealMonth: x?.Rate,
                            costMealYear: x?.PreviousRate
                        };
                    });
                    let newAr = [
                        ...dList,
                        // {
                        //     accountName: "Total Meals: ",
                        //     currentMonth: "106,470",
                        //     costMealMonth: "",
                        //     yearToDate: "619,698",
                        //     costMealYear: "",
                        //     addbr: true,
                        // },
                    ];
                    setDeposit(newAr);
                } else {
                    setDeposit([]);
                }
            }
        }
    };
    const handleIOCMulti = (e) => {
        setSchoolSelect(e);
    };

    const currentYear = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => currentYear - index);
    const months = [
        { name: "January", value: 1 },
        { name: "February", value: 2 },
        { name: "March", value: 3 },
        { name: "April", value: 4 },
        { name: "May", value: 5 },
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
    ];

    return (
        <>
            {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
            <section className="Operating_statement_report">
                <div className="main_wrapper">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="all_title mb-4">Operating Statement Report</h4>
                            <form onSubmit={handleSubmit(getDeposit)}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> District
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                name=""
                                                className="form-select"
                                                {...register("schoolDistrictId", {
                                                    required: "This field is required",
                                                    onChange: (e) => getSchoolList(e.target.value),
                                                })}
                                            >
                                                <option value="">Select School District</option>
                                                {parentList &&
                                                    parentList?.map((data, i) => (
                                                        <option key={i} value={data?.value}>
                                                            {data?.label}
                                                        </option>
                                                    ))}
                                            </select>
                                            {errors.schoolDistrictId && (
                                                <span role="alert" className="error_text">
                                                    {errors.schoolDistrictId.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label> School
                                                <span className="red-color"> *</span>
                                            </label>
                                            <Select
                                                isMulti
                                                name="type"
                                                options={schoolList}
                                                // closeMenuOnSelect={false}
                                                // hideSelectedOptions={false}
                                                className="form-neselect"
                                                classNamePrefix="select"
                                                placeholder="Select School"
                                                value={schoolSelect ? schoolSelect : null}
                                                onChange={(selected) => {
                                                    true &&
                                                        selected.length &&
                                                        selected.find((option) => option.value === "all")
                                                        ? handleIOCMulti(schoolList.slice(1))
                                                        : !true
                                                            ? handleIOCMulti(
                                                                (selected && selected.value) || null
                                                            )
                                                            : handleIOCMulti(selected);
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            {derror?.err && (
                                                <span role="alert" className="d-block error_text">
                                                    {derror.err}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                Year
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("year", {
                                                    required: "This field is required",
                                                })}
                                            >
                                                <option value="">Select Year</option>
                                                {years.map((year) => (
                                                    <option key={year} value={year}>
                                                        {year}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.year && (
                                                <span role="alert" className="error_text">
                                                    {errors.year.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                Month
                                            </label>
                                            <select
                                                className="form-select"
                                                {...register("month", {
                                                    // required: "This field is required",
                                                })}
                                            >
                                                <option value="">Select Month</option>
                                                {months.map((month) => (
                                                    <option key={month.value} value={month.value}>
                                                        {month.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.month && (
                                                <span role="alert" className="error_text">
                                                    {errors.month.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className=" ms-auto mt-auto col-md-4">
                                        <div className="form-group text-end filter-img">
                                            {dropdata?.scName || dropdata?.distName ? (
                                                <>
                                                    <Button
                                                        className="cancel_btn me-3 text-end"
                                                        type="button"
                                                        onClick={() => setDownloadpdf(true)}
                                                    >
                                                        Pdf
                                                    </Button>
                                                    <Button
                                                        className="cancel_btn me-3 text-end"
                                                        type="button"
                                                        onClick={() => onDownload()}
                                                    >
                                                        Download
                                                    </Button>
                                                </>
                                            ) : (
                                                ""
                                            )}

                                            {openCircle ? (
                                                <Button className="theme_btn text-end">
                                                    Applying Filter{" "}
                                                    <CircularProgress
                                                        sx={{
                                                            color: "#fff",
                                                            marginLeft: "0.5rem",
                                                            height: "23px !important",
                                                            width: "23px !important",
                                                        }}
                                                    />
                                                </Button>
                                            ) : (
                                                <Button className="theme_btn text-end" type="submit">
                                                    <img
                                                        src={filter}
                                                        className="me-1"
                                                        alt=""
                                                        height={23}
                                                    />
                                                    Apply
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Suspense fallback={renderLoader()}>
                                <div ref={tableRef} style={back}>
                                    {dropdata?.scName || dropdata?.distName ? (
                                        <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                                            <div className="d-flex">
                                                <img
                                                    src={
                                                        downloadPdf
                                                            ? require("../../../../../Assets/PngImage/Header_logo_1.png")
                                                            : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
                                                    }
                                                    alt=""
                                                    width={100}
                                                    height={80}
                                                />
                                                <div className="ms-3">
                                                    <label
                                                        style={{
                                                            fontSize: "18px",
                                                            color: "#2c2e35",
                                                            fontWeight: 600,
                                                            marginBottom: "7px",
                                                        }}
                                                    >
                                                        Operating Statement Report
                                                    </label>
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    style={{
                                                        fontSize: "18px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                        width: "250px",
                                                        whiteSpace: "pre-line",
                                                    }}
                                                >
                                                    {dropdata?.schName}
                                                </label>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                    }}
                                                >
                                                    {dropdata?.distName}
                                                </div>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                    }}
                                                >
                                                    {Time.toLocaleString()}
                                                </div>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {LogDetail?.firstName + " " + LogDetail?.lastName}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {dropdata?.scName || dropdata?.distName ? (
                                        <>
                                            <SmallListTable
                                                headCells={productHead}
                                                downloadPdf={downloadPdf}
                                                ListData={deposit}
                                            />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OperatingStatementReport;
