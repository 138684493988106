import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

function CreateAcademicYear() {
    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (id) {
            getEditedData(id);
        }
        // eslint-disable-next-line
    }, [id]);

    const getEditedData = async (id) => {
        setOpen(true);

        let sendData = {
            url: `/api/SchoolAcademicYear/GetSchoolAcademicYearDataByID?academicYearId=${id}`,
        };

        let res = await GetCallApi(sendData);
        setOpen(false);
        if (res?.status === 200) {
            if (res.data.success) {
                let editsiteData = res?.data?.payload;
                setValue("academicYearId", id);
                setValue("academicYear", editsiteData?.academicYear);
                setValue("isCurrentYear", editsiteData?.isCurrentYear?.toString());
            }
        }
    };

    const onSubmit = async (data) => {
        setOpen(true);

        let passData = {
            ...data,
            isCurrentYear: data?.isCurrentYear === "true",
            createdBy: LogDetail?.userId,
        };

        let seData = {
            url: `/api/SchoolAcademicYear/CreateSchoolAcademicYear`,
            body: passData,
        };
        let respo = await PostCallApi(seData);
        setOpen(false);
        if (respo?.status === 200) {
            if (respo.data.success) {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
                navigate(-1);
            } else {
                setOpen(false);
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo?.data?.message
                        ? respo?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            setOpen(false);
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: respo?.data?.message
                    ? respo?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="create_academic_year">
                    <div className="main_wrapper">
                        <div className="ProductCategories_hed">
                            <div className="all_title ProductTitle">
                                {id ? "Edit Academic Year" : "Create Academic Year"}
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action=""
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="primary-box"
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Academic Year <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Academic Year"
                                                    {...register("academicYear", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Max 50 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.academicYear && (
                                                    <span role="alert" className="error_text">
                                                        {errors.academicYear.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Is Current Year
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("isCurrentYear", {
                                                        required: "This field is required",

                                                    })}
                                                >
                                                    <option value="">Select Is Current Year</option>
                                                    <option value="true">True</option>
                                                    <option value="false">False</option>
                                                </select>
                                                {errors.isCurrentYear && (
                                                    <span role="alert" className="error_text">
                                                        {errors.isCurrentYear.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-end">
                                            <Button type="submit" className="theme_btn me-2">
                                                Submit
                                            </Button>
                                            <Button
                                                className="cancel_btn"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default CreateAcademicYear;
