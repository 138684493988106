import React, { Suspense, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./index.scss";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { Config } from "../../../../../../Utils/Config";
import uploadimg from "../../../../../../Assets/SvgImage/file-upload.svg";
import { StudentPopup } from "../../../../../Web/Parent/SubmittedSurveyList/Create";
import { IoIosInformationCircle } from "react-icons/io";
import {
  SwalMessage,
  SwalSuccess,
} from "../../../../../../Components/Reusable/SwalMessage";
import { SearchMyChildren } from "../../../../../Web/Parent/SubmittedSurveyList/SearchMyChildren";
import Select from "react-select";
import { customFilter } from "../../../../../../Components/Reusable/CustomFilterForReactSelect";
import NotFound from "../../../../../../Assets/Image_not_available.jpg";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateSurvey() {
  const { id, dId } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [distList, setDistList] = useState([]);
  const [selectDist, setSelectDist] = useState("");
  const [studId, setStudId] = useState();
  const [qiId, setQiId] = useState("");
  const [parentId, setParentId] = useState("");
  const [parents, setParents] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [files, setFiles] = useState();
  const [open, setOpen] = useState(false);
  const [studentGuiId, setStudentGuiId] = useState();
  const [searchMyChildOpen, setSearchMyChildOpen] = useState();

  let nav = useNavigate();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const { fields, append } = useFieldArray({
    control,
    name: "StudentList",
  });

  const { fields: Field1, append: append1 } = useFieldArray({
    control,
    name: "houseHoldList",
  });

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getDistrictList();
    getStateList();
    setValue("surveyBenifitField", "NA");
    if (!id) {
      append1({
        birthDate: "",
        firstName: "",
        lastName: "",
        isStudent: false,
        studentGuidId: null,
        grade: "",
        isFosterChild: false,
        actualIncome: 0,
        paymentCycle: "",
        studentType: "",
        country: "USA",
        removable: true,
        houseHoldIncomeSub: [
          { incomeSource: "", incomeAmount: "", paymentCycle: "" },
        ],
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setSelectDist(dId);
    getSchoolList(dId);
    // eslint-disable-next-line
  }, [id, dId]);

  const getSchoolList = async (dsId) => {
    setSchoolList([]);
    if (dsId) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${dsId}&userId=${LogDetail?.userId}`,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          setSchoolList(dlist?.length > 0 ? dlist : []);
        }
      }
    }
    if (id) {
      getStudentList(id);
    }
  };

  useEffect(() => {
    if (fields || Field1) {
      setValue("NoOfPeoeple", fields?.length + Field1?.length);
    }
    // eslint-disable-next-line
  }, [fields, Field1]);

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const getAllParents = async (dsId) => {
    setParents([]);
    let sendParent = {
      url: `/api/Survey/GetAllParentsDataWithHouseHoldSurvey?schoolDistrictId=${dsId}`,
    };
    const resp = await GetCallApi(sendParent);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        let allD = resp?.data?.payload?.map((x) => {
          return {
            ...x,
            value: x?.parentsGuidId,
            label: x?.parentName,
          };
        });
        setParents(allD ?? []);
      }
    }
  };

  const getStateList = async (sId) => {
    let stateUrl = {
      url: `/api/Common/GetStateList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    const res = await GetCallApi(stateUrl);
    if (res?.status === 200) {
      if (res.data.success) {
        setStateList(res.data.payload);
        if (sId && res.data.payload) {
          setTimeout(() => {
            setValue("state", sId);
          }, 500);
        }
      }
    }
  };

  const getDistrictList = async () => {
    setDistList([]);
    let seData = {
      url: "/api/SchoolDistrict/GetAllSchoolDistrictList",
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            ...sd,
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getStudentList = async (id) => {
    if (id) {
      setOpenCircle(true);
      let apiUrl = {
        url: `/api/Survey/GetHouseHoldSurveryByHouseHoldId?houseHoldId=${id}`,
        headers: headers,
      };
      const res = await GetCallApi(apiUrl);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          let srData = res.data.payload?.hhs;
          setValue(
            "surveyBenifitField",
            srData?.surveyBenifitField
              ? srData?.surveyBenifitField === "FDPIR"
                ? "FDPIR"
                : srData?.surveyBenifitField === "SNAP"
                ? "SNAP"
                : srData?.surveyBenifitField === "TANF"
                ? "TANF"
                : srData?.surveyBenifitField === "NA"
                ? "NA"
                : srData?.surveyBenifitField
              : ""
          );
          setValue("caseName", srData?.caseName);
          setValue("caseNumber", srData?.caseNumber);
          setValue("firstName", srData?.firstName);
          setValue("fullName", srData?.fullName);
          setValue("address", srData?.address);
          setValue("apartment", srData?.apartment);
          setValue("phone", srData?.phone);
          setValue("city", srData?.city);
          getStateList(srData?.state);
          setValue("signatureName", srData?.signature);
          setValue("actualIncome", srData?.actualIncome);
          setValue("NoOfPeoeple", srData?.noOfPeoeple);
          setValue("ssnNumber", srData?.ssnnumber);
          setValue("isSSN", srData?.isSsn);
          setValue("isOtherStateEbtbenifit", srData?.isOtherStateEbtbenifit);
          setValue("isSummerEBT", srData?.isSummerEbt);
          setValue("totalChildIncome", srData?.totalChildIncome);
          setValue("childPaymentCycle", srData?.childPaymentCycle);
          // setValue("notes", srData?.notes);
          setValue("sign", srData?.signature?.length > 0 ? true : false);
          setValue("houseHoldSurveyId", id);
          setValue(
            "emailConfirmationAddress",
            srData?.emailConfirmationAddress
          );
          setValue("isHispanic", srData?.isHispanic?.toString());
          let arV = [];
          if (srData?.isAmericanIndianAlaskaNative) {
            arV.push("isAmericanIndianAlaskaNative");
          }
          if (srData?.isAsian) {
            arV.push("isAsian");
          }
          if (srData?.isBlackAfricanAmerican) {
            arV.push("isBlackAfricanAmerican");
          }
          if (srData?.isNativeHawaiianOtherPacificIslande) {
            arV.push("isNativeHawaiianOtherPacificIslande");
          }
          if (srData?.isWhite) {
            arV.push("isWhite");
          }
          setValue("isRace", arV?.length > 0 ? arV : []);

          setTimeout(() => {
            setValue("paymentCycle", srData?.paymentCycle);
            setValue("totalActualIncome", srData?.totalActualIncome);
          }, 200);
          setValue("documents", res.data.payload?.surveyDocumentUrl);
          setFiles(res.data.payload?.surveyDocumentUrl);
          const dlist = res.data.payload.studentList;
          if (dlist === undefined || dlist?.length === 0) {
            const data = [
              {
                birthDate: "",
                firstName: "",
                lastName: "",
                isStudent: true,
                isFosterChild: false,
                studentGuidId: null,
                grade: "",
                schoolId: "",
                fosterChild: "NA",
                studentType: "",
                country: "USA",
              },
            ];
            append1({
              birthDate: "",
              firstName: "",
              lastName: "",
              isStudent: false,
              studentGuidId: null,
              grade: "",
              isFosterChild: false,
              actualIncome: 0,
              paymentCycle: "",
              studentType: "",
              country: "USA",
              removable: true,
              houseHoldIncomeSub: [
                { incomeSource: "", incomeAmount: "", paymentCycle: "" },
              ],
            });
            setValue("StudentList", data);
          }
          let nr = dlist?.map((std, index) => {
            return {
              birthDate: std?.birthDate?.split("T")[0],
              firstName: std?.firstName,
              lastName: std?.lastName,
              isStudent: std?.isStudent ? true : false,
              studentGuidId: std?.studentGuidId,
              grade: std?.grade,
              schoolId: std?.schoolId ? std?.schoolId : "",
              actualIncome: std?.actualIncome ? std?.actualIncome : 0,
              fosterChild: std?.fosterChild ? std?.fosterChild : "NA",
              isFosterChild: std?.isFosterChild,
              paymentCycle: "",
              studentType: "",
              studentId: std?.studentId,
              country: "USA",
              removable: true,
              houseHoldStudentSurveyId: std?.houseHoldStudentSurveyId,
              houseHoldIncomeSub: std?.houseHoldIncomeList?.map((x, i) => {
                return {
                  incomeAmount: x?.incomeAmount,
                  incomeSource: x?.incomeSource,
                  paymentCycle: x?.paymentCycle,
                };
              }),
            };
          });
          if (nr?.length > 0 || nr !== undefined) {
            nr = [...nr];
            setValue(
              "StudentList",
              nr?.filter((x) => x?.isStudent === true)
            );
            setValue(
              "houseHoldList",
              nr?.filter((x) => x?.isStudent === false)
            );
            handleChoise("val", 0);
          }
          handleActualIncome();
        } else {
          const data = [
            {
              birthDate: "",
              firstName: "",
              lastName: "",
              isStudent: true,
              studentGuidId: "",
              grade: "",
              schoolId: "",
              fosterChild: "NA",
              isFosterChild: false,
              actualIncome: 0,
              paymentCycle: "",
              studentType: "",
              country: "USA",
              removable: true,
            },
          ];
          append1({
            birthDate: "",
            firstName: "",
            lastName: "",
            isStudent: false,
            studentGuidId: null,
            grade: "",
            isFosterChild: false,
            actualIncome: 0,
            paymentCycle: "",
            studentType: "",
            country: "USA",
            removable: true,
            houseHoldIncomeSub: [
              { incomeSource: "", incomeAmount: "", paymentCycle: "" },
            ],
          });
          setValue("StudentList", data);
        }
      }
    }
  };

  const getStudentCreateList = async (pId) => {
    setValue("StudentList", []);
    if (pId) {
      setOpenCircle(true);
      let apiUrl = {
        // url: `/api/Survey/GetHouseHoldSurveryByHouseHoldId?houseHoldId=${id}`,
        url: `/api/ParentModule/GetStudentListByParentID?parentGuidId=${pId}`,
        headers: headers,
      };
      const res = await GetCallApi(apiUrl);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload;
          if (dlist === undefined || dlist?.length === 0) {
            const data = [
              {
                birthDate: "",
                firstName: "",
                lastName: "",
                isStudent: true,
                isFosterChild: false,
                fosterChild: "NA",
                grade: "",
                schoolId: "",
                actualIncome: 0,
                paymentCycle: "",
                studentType: "",
                country: "USA",
              },
            ];
            setValue("StudentList", data);
            setValue("houseHoldList", [
              {
                ...data[0],
                isStudent: false,
                houseHoldIncomeSub: [
                  { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                ],
              },
            ]);
          }
          let nr = dlist
            ?.filter((x) => x?.schoolDistrictId === selectDist)
            ?.map((std, index) => {
              return {
                birthDate: std?.birthDate?.split("T")[0],
                firstName: std?.firstName,
                lastName: std?.lastName,
                isStudent: true,
                studentGuidId: std?.studentGuidId ? std?.studentGuidId : null,
                fosterChild: std?.fosterChild ? std?.fosterChild : "NA",
                grade: std?.grade,
                schoolId: std?.schoolId ? std?.schoolId : "",
                isFosterChild: std?.isFosterChild ? std?.isFosterChild : false,
                studentType: "",
                studentId: std?.studentId,
                country: "USA",
                removable: true,
              };
            });

          if (nr?.length > 0) {
            nr = [...nr];
            setValue("StudentList", nr);
            handleChoise("val", 0);
            handleActualIncome();
          } else {
            const data = [
              {
                birthDate: "",
                firstName: "",
                lastName: "",
                isStudent: true,
                studentGuidId: null,
                grade: "",
                schoolId: "",
                fosterChild: "NA",
                isFosterChild: false,
                actualIncome: "",
                paymentCycle: "",
                studentType: "",
                country: "USA",
                removable: true,
              },
            ];
            setValue("StudentList", data);
            append1({
              birthDate: "",
              firstName: "",
              lastName: "",
              isStudent: false,
              studentGuidId: null,
              grade: "",
              isFosterChild: false,
              actualIncome: 0,
              paymentCycle: "",
              studentType: "",
              country: "USA",
              removable: true,
              houseHoldIncomeSub: [
                { incomeSource: "", incomeAmount: "", paymentCycle: "" },
              ],
            });
          }
        } else {
          const data = [
            {
              birthDate: "",
              firstName: "",
              lastName: "",
              isStudent: true,
              studentGuidId: "",
              grade: "",
              schoolId: "",
              fosterChild: "NA",
              isFosterChild: false,
              actualIncome: 0,
              paymentCycle: "",
              studentType: "",
              country: "USA",
              removable: true,
            },
          ];
          setValue("StudentList", data);
          append1({
            birthDate: "",
            firstName: "",
            lastName: "",
            isStudent: false,
            studentGuidId: null,
            grade: "",
            isFosterChild: false,
            actualIncome: 0,
            paymentCycle: "",
            studentType: "",
            country: "USA",
            removable: true,
            houseHoldIncomeSub: [
              { incomeSource: "", incomeAmount: "", paymentCycle: "" },
            ],
          });
        }
      }
    }
  };

  const handleChoise = (id, index, val) => {
    setStudId(id);
    setQiId(index);
    if (id === studId) {
      setStudId(studId);
    }
    if (index === qiId) {
      setQiId(qiId);
    }
    if (val) {
      setTimeout(() => {
        setValue(`StudentList.${index}.schoolId`, "");
      }, 0);
    }
    if (index === "NA") {
      handleActualIncome();
      setValue("caseName", "");
      setValue("caseNumber", "");
    }
    if (val === "NA") {
      setValue("totalChildIncome");
      // setValue("houseHoldList", []);
    }
  };

  const onSubmit = async (data) => {
    let is_success = true;
    if (data?.StudentList?.length === 0) {
      is_success = false;
      await SwalMessage("Select minimun one as student");
    }

    let radioVal = {};

    if (Array.isArray(data?.isRace)) {
      const validCheckboxNames = [
        "isAmericanIndianAlaskaNative",
        "isAsian",
        "isBlackAfricanAmerican",
        "isNativeHawaiianOtherPacificIslande",
        "isWhite",
      ];
      const filteredRaceKeys = data?.isRace.filter((key) =>
        validCheckboxNames.includes(key)
      );
      filteredRaceKeys.forEach(function (prop, index) {
        radioVal[prop] = true;
      });
    }

    let allstudent = [];
    if (data?.surveyBenifitField === "NA") {
      allstudent = [...data?.StudentList, ...data?.houseHoldList];
    } else {
      allstudent = data?.StudentList;
    }
    if (is_success) {
      setOpenCircle(true);
      let sendData = {
        ...data,
        ...radioVal,
        createdBy: id ? LogDetail?.parentsGuidId : parentId,
        TotalHousholdAnuualIncome: 0,
        parentGuidId: id ? null : parentId,
        isHispanic: data?.isHispanic === "true" ? true : false,
        totalChildIncome: data?.totalChildIncome ? data?.totalChildIncome : 0,
        schoolDistrictId: selectDist ? selectDist : null,
        StudentSurvey: allstudent?.map((x) => {
          return {
            ...x,
            studentGuidId: x.studentGuidId ? x?.studentGuidId : null,
            isFosterChild: x?.isFosterChild ? x?.isFosterChild : false,
            actualIncome: x?.actualIncome ? x?.actualIncome : 0,
            schoolId: x?.schoolId ? x?.schoolId : null,
            schoolDistrictId: selectDist ? selectDist : null,
            houseHoldIncomeSub:
              x?.houseHoldIncomeSub?.length > 0
                ? x?.houseHoldIncomeSub?.map((y) => {
                    return {
                      ...y,
                      incomeAmount: y?.incomeAmount>=0 ? y?.incomeAmount : 0,
                    };
                  })
                : [],
          };
        }),
      };
      let Sedata = new FormData();

      if (data?.StudentList?.length > 0) {
        data?.StudentList?.map((d) => {
          return Sedata.append("StudentSurvey", JSON.stringify(d));
        });
      } else {
        Sedata.append("StudentSurvey", []);
      }
      ["StudentList"].forEach((e) => delete sendData[e]);
      for (var key in sendData) {
        Sedata.append(key, sendData[key]);
      }

      let seData = {
        url: `/api/Survey/CreateHouseholdInformationSurvey`,
        body: sendData,
        headers: headers,
      };

      const res = await PostCallApi(seData);
      if (res?.status === 200 && res.data.success) {
        SwalSuccess(res.data?.message);

        let surveyData = {
          houseHoldSurveyId: res?.data?.payload?.houseHoldSurveyId,
          surveyDocument: files,
          createdBy: id ? LogDetail?.userId : parentId,
        };

        const apiUrls = {
          url: `/api/Survey/SaveSurveyDocument`,
          body: surveyData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        };
        await PostCallApi(apiUrls);

        if (data?.apiCall) {
          let houId = res?.data?.payload?.houseHoldSurveyId;
          let approveData = {
            url: `/api/Survey/ApproveBySchoolDisrtict?houseHoldId=${houId}&userId=${LogDetail?.userId}`,
          };
          await PostCallApi(approveData);
        }

        setOpenCircle(false);
        // reset();
        if (id) {
          window.close();
        } else {
          nav(-1);
        }
      } else {
        setOpenCircle(false);
      }
    }
  };

  const handleParents = (event) => {
    if (event) {
      getStudentCreateList(event);
      setParentId(event);
    } else {
      setParentId("");
    }
  };

  const handleSchDist = (e) => {
    setParentId("");
    if (e) {
      setSelectDist(e);
      getAllParents(e);
      getSchoolList(e);
    } else {
      setSelectDist("");
      setParentId("");
    }
  };

  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setValue("documents", e.target.files[0].name);
      setFiles(e.target.files[0]);
      e.target.value = null;
    }
  };

  const handleStdDetail = (data) => {
    setOpen(true);
    setStudentGuiId(data?.studentGuidId);
  };

  const handleAdd = (item) => {
    append({
      birthDate: convertDate(item?.birthDate),
      firstName: item?.firstName,
      lastName: item?.lastName,
      studentId: item?.studentId,
      isStudent: true,
      studentGuidId: item?.studentGuidId ? item?.studentGuidId : null,
      fosterChild: "NA",
      grade: item?.grade,
      removable: true,
      bg: item?.bg,
      schoolId: item?.schoolId,
      isFosterChild: false,
    });
  };

  const handleActualIncome = () => {
    const studentList = getValues("StudentList");
    const houseHold = getValues("houseHoldList");
    let Allmember = [...studentList, ...houseHold];
    const paymentCycles = Allmember.map((std) => std?.paymentCycle);

    const firstCycle = paymentCycles[0];
    const allCyclesMatch = paymentCycles.every((cycle) => cycle === firstCycle);

    if (allCyclesMatch) {
      const totalMulti = 1;
      const allIncome = Allmember.filter((std) => std?.actualIncome)
        .map((x) => totalMulti * x?.actualIncome)
        .reduce((acc, current) => acc + parseFloat(current), 0);

      setValue("paymentCycle", firstCycle);
      setValue("totalActualIncome", allIncome);
    } else {
      const allIncome1 = Allmember.filter((std) => std?.actualIncome)
        .map((x) => {
          const totalMulti1 =
            x.paymentCycle === "Annual"
              ? 1
              : x.paymentCycle === "Monthly"
              ? 12
              : x.paymentCycle === "TwicePerMonth"
              ? 24
              : x.paymentCycle === "EveryTwoWeeks"
              ? 26
              : x.paymentCycle === "Weekly"
              ? 52
              : 1;

          return x.actualIncome * totalMulti1;
        })
        .reduce((acc, current) => acc + parseFloat(current), 0);

      setValue("paymentCycle", "Annual");
      setValue("totalActualIncome", allIncome1);
    }
  };

  const handleCaseNum = (num) => {
    if (num?.length === 10) {
      setValue("totalActualIncome", 0);
      getValues("StudentList")?.map((_, i) => {
        return (
          setValue(`StudentList.${i}.actualIncome`, 0),
          setValue(`StudentList.${i}.paymentCycle`, "Annual")
        );
      });
      getValues("houseHoldList")?.map((x, i) => {
        return (
          setValue(`houseHoldList.${i}.actualIncome`, 0),
          setValue(`houseHoldList.${i}.paymentCycle`, "Annual")
        );
      });
    } else {
      handleActualIncome();
    }
  };

  const handleCreateRemove = (ind) => {
    let deleted = getValues("StudentList")?.filter((_, i) => i !== ind);
    setValue("StudentList", []);
    setTimeout(() => {
      setValue(
        "StudentList",
        deleted?.map((x) => {
          return {
            ...x,
            birthDate:
              x?.birthDate?.toString() !== ""
                ? new Date(x?.birthDate).toISOString()?.split("T")[0]
                : "",
            actualIncome: x?.actualIncome ? x?.actualIncome : "",
          };
        })
      );
      handleActualIncome();
    }, 100);
  };

  const handleRemove1 = (ind) => {
    let deleted = getValues("houseHoldList")?.filter((_, i) => i !== ind);
    setValue("houseHoldList", []);
    setTimeout(() => {
      setValue(
        "houseHoldList",
        deleted?.map((x) => {
          return {
            ...x,
            birthDate: !isNaN(new Date(x?.birthDate).getTime())
              ? new Date(x?.birthDate).toISOString().split("T")[0]
              : "",
            actualIncome: x?.actualIncome ? x?.actualIncome : "",
          };
        })
      );
      handleActualIncome();
    }, 100);
  };

  const handleAddAnother = (ind) => {
    let deleted = getValues("houseHoldList");
    setTimeout(() => {
      setValue(
        "houseHoldList",
        deleted?.map((x, i) => {
          if (ind === i) {
            let payment =
              x?.houseHoldIncomeSub?.length > 0
                ? [
                    ...x?.houseHoldIncomeSub,
                    { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                  ]
                : [{ incomeSource: "", incomeAmount: "", paymentCycle: "" }];
            return {
              ...x,
              houseHoldIncomeSub: payment,
            };
          }
          return {
            ...x,
          };
        })
      );
    }, 100);
  };

  const handleRemove2 = (ind, index) => {
    let deleted = getValues("houseHoldList")?.map((x, hi) => {
      if (hi === index) {
        return {
          ...x,
          houseHoldIncomeSub: x?.houseHoldIncomeSub?.filter(
            (_, i) => i !== ind
          ),
        };
      }
      return x;
    });
    setTimeout(() => {
      setValue(
        "houseHoldList",
        deleted?.map((x) => {
          return {
            ...x,
          };
        })
      );
      handleActualIncome();
    }, 100);
  };

  const handleActualIncome1 = () => {
    const houseHold = getValues("houseHoldList");
    const child = [
      {
        houseHoldIncomeSub: [
          {
            paymentCycle: getValues("childPaymentCycle"),
            incomeAmount: getValues("totalChildIncome"),
          },
        ],
      },
    ];
    let allIn = [...houseHold, ...child];
    const paymentCycles = allIn.map((std) =>
      std?.houseHoldIncomeSub?.map((x) => x?.paymentCycle)
    );

    const flattenedCycles = paymentCycles.flat().filter(Boolean);
    const firstCycle = flattenedCycles[0];
    const allCyclesMatch = flattenedCycles.every(
      (cycle) => cycle === firstCycle
    );

    if (allCyclesMatch) {
      const totalMulti = 1;

      const allIncome = allIn
        .map((s) => s?.houseHoldIncomeSub)
        .flat()
        .filter((x) => x?.incomeAmount)
        .map((x) => totalMulti * parseFloat(x?.incomeAmount || 0))
        .reduce((acc, current) => acc + current, 0);

      setValue("paymentCycle", firstCycle || "Annual");
      setValue("totalActualIncome", allIncome);
    } else {
      const allIncome1 = allIn
        .map((s) => s?.houseHoldIncomeSub)
        .flat()
        .filter((x) => x?.incomeAmount)
        .map((x) => {
          const totalMulti1 =
            x.paymentCycle === "Annual"
              ? 1
              : x.paymentCycle === "Monthly"
              ? 12
              : x.paymentCycle === "TwicePerMonth"
              ? 24
              : x.paymentCycle === "EveryTwoWeeks"
              ? 26
              : x.paymentCycle === "Weekly"
              ? 52
              : 1;

          return parseFloat(x.incomeAmount || 0) * totalMulti1;
        })
        .reduce((acc, current) => acc + current, 0);

      setValue("paymentCycle", "Annual");
      setValue("totalActualIncome", allIncome1);
    }
  };

  const districtLogo = distList?.filter((x) => x?.value === selectDist)[0]
    ?.districtLogo;

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="HouseHoldMember_create">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-center align-items-center">
                {!id && (
                  <div className="col-md-3 me-2">
                    <div className="form-group">
                      <label className="form-label">Select District</label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={distList}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select District"
                        filterOption={customFilter}
                        value={distList?.filter(
                          (obj) => obj.value === selectDist
                        )}
                        onChange={(e) => handleSchDist(e?.value)}
                      />
                    </div>
                  </div>
                )}
                {!id && selectDist ? (
                  <div className="col-md-3">
                    <div className="form-group">
                      <label className="form-label">
                        Select The Parent For Info Survey
                      </label>
                      <Select
                        isMulti={false}
                        name="colors"
                        options={parents}
                        isClearable={true}
                        className="dropdown-single-select"
                        classNamePrefix="select"
                        placeholder="Select Parent"
                        filterOption={customFilter}
                        value={parents?.filter((obj) => obj.value === parentId)}
                        onChange={(e) => handleParents(e?.value)}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {parentId || id ? (
                <>
                  <div className="hed">
                    <div className="d-flex">
                      <img
                        src={`${Config.API_HOST_URL_live}/${districtLogo}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = NotFound;
                        }}
                        className="header_logo img-fluid"
                        alt="header_logo"
                      />
                      <h2 className="mx-auto">
                        School Meals and Summer EBT Application 2024-2025
                      </h2>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="info">
                        <h5>
                          <b>
                            Step 1. List ALL children, infants, and students up
                            to and including grade 12.
                          </b>
                        </h5>
                        <p>
                          List ALL children in the household. Do not forget to
                          list infants, children attending other schools,
                          children not in school, and children not applying for
                          benefits. This includes children not related to you in
                          your household.
                        </p>
                      </div>
                      <Button
                        className="ms-auto theme_btn"
                        onClick={() => setSearchMyChildOpen(true)}
                      >
                        Search Students
                      </Button>
                    </div>
                  </div>
                  <hr />
                  <div className="houseHoldForm">
                    <form
                      action=""
                      onSubmit={handleSubmit(onSubmit)}
                      className="primary-box"
                    >
                      <div className="partA">
                        <div className="table-responsive">
                          <table className={`table table-bordered ${"mb-3"}`}>
                            <thead>
                              <tr>
                                <th>Date of Birth</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Type</th>
                                <th>Info</th>
                                {getValues(`StudentList`)?.filter(
                                  (x) => x.isStudent === true
                                )?.length > 0 ? (
                                  <>
                                    <th>Student ID</th>
                                    <th>School</th>
                                    <th>Grade</th>
                                  </>
                                ) : (
                                  ""
                                )}
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields
                                ?.filter((x) => x.isStudent === true)
                                ?.map((item, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      backgroundColor: item?.bg ? item?.bg : "",
                                    }}
                                  >
                                    <td>
                                      <input
                                        type="date"
                                        className="form-control"
                                        style={{
                                          minWidth: "135px",
                                          maxWidth: "385px",
                                        }}
                                        {...register(
                                          `StudentList.${index}.birthDate`,
                                          {
                                            required: "This field is required",
                                            // valueAsDate: true,
                                            validate: {
                                              isValidDate: (value) => {
                                                if (value > new Date()) {
                                                  return "You can not enter more than today date";
                                                }
                                              },
                                            },
                                          }
                                        )}
                                      />
                                      {errors.StudentList?.[index]
                                        ?.birthDate && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {
                                            errors.StudentList?.[index]
                                              ?.birthDate.message
                                          }
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name "
                                        {...register(
                                          `StudentList.${index}.firstName`,
                                          {
                                            required: "This field is required",
                                          }
                                        )}
                                      />
                                      {errors.StudentList?.[index]
                                        ?.firstName && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {
                                            errors.StudentList?.[index]
                                              ?.firstName.message
                                          }
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        {...register(
                                          `StudentList.${index}.lastName`,
                                          {
                                            required: "This field is required",
                                          }
                                        )}
                                      />
                                      {errors.StudentList?.[index]
                                        ?.lastName && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {
                                            errors.StudentList?.[index]
                                              ?.lastName.message
                                          }
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <select
                                        name=""
                                        className="form-select"
                                        {...register(
                                          `StudentList.${index}.fosterChild`
                                          // {
                                          //   required: "This field is required",
                                          // }
                                        )}
                                      >
                                        <option value="NA">
                                          NA (Not Applicable)
                                        </option>
                                        <option value="FosterChild">
                                          Foster Child
                                        </option>
                                        <option value="MigrantWorker">
                                          Migrant Worker
                                        </option>
                                        <option value="Runway">Runway</option>
                                        <option value="HomeLess">
                                          HomeLess
                                        </option>
                                      </select>

                                      {errors.houseHoldList?.[index]
                                        ?.fosterChild && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {
                                            errors.houseHoldList?.[index]
                                              ?.fosterChild.message
                                          }
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-center gap-1">
                                        {item?.studentGuidId ? (
                                          <div
                                            className="align-items-center"
                                            onClick={() =>
                                              handleStdDetail(item)
                                            }
                                          >
                                            <IoIosInformationCircle
                                              fontSize={26}
                                              color="#579eff"
                                              cursor="pointer"
                                            />
                                          </div>
                                        ) : (
                                          <div className="invisible">t</div>
                                        )}
                                      </div>
                                    </td>
                                    {getValues(`StudentList`)?.filter(
                                      (x) => x.isStudent === true
                                    )?.length > 0 ? (
                                      getValues(
                                        `StudentList.${index}.isStudent`
                                      ) ? (
                                        <>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Student Id"
                                              style={{
                                                minWidth: "90px",
                                                maxWidth: "90px",
                                              }}
                                              {...register(
                                                `StudentList.${index}.studentId`
                                              )}
                                            />
                                            {errors.StudentList?.[index]
                                              ?.studentId && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.StudentList?.[index]
                                                    ?.studentId.message
                                                }
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {item?.removable ? (
                                              <select
                                                name=""
                                                id=""
                                                className="form-select"
                                                {...register(
                                                  `StudentList.${index}.schoolId`
                                                )}
                                              >
                                                <option value="">
                                                  {" "}
                                                  Select School
                                                </option>
                                                {schoolList &&
                                                  schoolList?.map((ds, i) => (
                                                    <option
                                                      value={ds?.value}
                                                      key={i}
                                                    >
                                                      {ds?.label}
                                                    </option>
                                                  ))}
                                              </select>
                                            ) : (
                                              <>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  disabled={
                                                    item?.removable
                                                      ? false
                                                      : true
                                                  }
                                                  placeholder="Enter School Name "
                                                  {...register(
                                                    `StudentList.${index}.schoolName`,
                                                    {
                                                      // required: "This field is required",
                                                    }
                                                  )}
                                                />
                                                {errors.StudentList?.[index]
                                                  ?.schoolName && (
                                                  <span
                                                    role="alert"
                                                    className="error_text"
                                                  >
                                                    {
                                                      errors.StudentList?.[
                                                        index
                                                      ]?.schoolName.message
                                                    }
                                                  </span>
                                                )}
                                              </>
                                            )}
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Grade "
                                              style={{
                                                minWidth: "50px",
                                                maxWidth: "50px",
                                              }}
                                              {...register(
                                                `StudentList.${index}.grade`,
                                                {
                                                  // required: "This field is required",
                                                }
                                              )}
                                            />
                                            {errors.StudentList?.[index]
                                              ?.grade && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.StudentList?.[index]
                                                    ?.grade.message
                                                }
                                              </span>
                                            )}
                                          </td>
                                        </>
                                      ) : (
                                        <>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </>
                                      )
                                    ) : (
                                      ""
                                    )}
                                    <td>
                                      {index === 0 ? (
                                        <Button
                                          className="theme_btn"
                                          // onClick={addRow}
                                          onClick={() => {
                                            append({
                                              birthDate: "",
                                              firstName: "",
                                              lastName: "",
                                              isStudent: true,
                                              studentGuidId: "",
                                              grade: "",
                                              schoolId: "",
                                              fosterChild: "NA",
                                              isFosterChild: false,
                                              actualIncome: "",
                                              paymentCycle: "",
                                              studentType: "",
                                              country: "USA",
                                              removable: true,
                                            });
                                          }}
                                        >
                                          <AiOutlinePlus className="icon" />
                                        </Button>
                                      ) : item?.removable ? (
                                        <Button
                                          className="cancel_btn"
                                          onClick={() => {
                                            handleCreateRemove(index);
                                          }}
                                        >
                                          <AiOutlineClose className="icon" />
                                        </Button>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="partB">
                        <h5>
                          <b>
                            Step 2. Do any household members (including you)
                            participate in: SNAP, TANF, or FDPIR?
                          </b>
                        </h5>
                        <ul>
                          <li>
                            If anyone in the household receives FDPIR, TANF, or
                            SNAP, check the appropriate box
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                              className="flex-row"
                              value={
                                getValues("surveyBenifitField")
                                  ? getValues("surveyBenifitField")
                                  : "NA"
                              }
                            >
                              <FormControlLabel
                                className="radioBtn"
                                value="FDPIR"
                                label="FDPIR"
                                {...register("surveyBenifitField", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleChoise(
                                      e.target.checked,
                                      e.target.value
                                    ),
                                })}
                                control={<Radio />}
                              />
                              <FormControlLabel
                                className="radioBtn"
                                value="SNAP"
                                label="SNAP"
                                {...register("surveyBenifitField", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleChoise(
                                      e.target.checked,
                                      e.target.value
                                    ),
                                })}
                                control={<Radio />}
                              />
                              <FormControlLabel
                                className="radioBtn"
                                value="TANF"
                                label="TANF"
                                control={<Radio />}
                                {...register("surveyBenifitField", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleChoise(
                                      e.target.checked,
                                      e.target.value
                                    ),
                                })}
                              />
                              <FormControlLabel
                                className="radioBtn"
                                value="NA"
                                label="NA"
                                control={<Radio />}
                                {...register("surveyBenifitField", {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleChoise(
                                      e.target.checked,
                                      e.target.value,
                                      "NA"
                                    ),
                                })}
                              />
                            </RadioGroup>
                            {errors.surveyBenifitField && (
                              <span role="alert" className="error_text">
                                {errors.surveyBenifitField.message}
                              </span>
                            )}
                          </li>
                          <li>
                            If you checked a box, write the name and 10 digit
                            case number of anyone receiving the benefit and{" "}
                            <b>SKIP to Step 4</b>
                          </li>
                        </ul>
                        {getValues("surveyBenifitField") === "TANF" ||
                        getValues("surveyBenifitField") === "FDPIR" ||
                        getValues("surveyBenifitField") === "SNAP" ? (
                          <div className="row form mb-3">
                            <div className="col-md-4 input_div">
                              <label>* Full Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Full Name"
                                {...register(`caseName`, {
                                  required: "This field is required",
                                })}
                              />
                              {errors.caseName && (
                                <span role="alert" className="error_text">
                                  {errors.caseName.message}
                                </span>
                              )}
                            </div>{" "}
                            <div className="col-md-4 input_div">
                              <label>
                                * Please Enter The 10 Digit Case Number (Not EBT
                                Number)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Please Enter The 10 Digit Case Number"
                                {...register(`caseNumber`, {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleCaseNum(e?.target?.value),
                                  minLength: {
                                    value: 10,
                                    message:
                                      "You have to enter minimum 10 character",
                                  },
                                  maxLength: {
                                    value: 10,
                                    message: "You can enter max 10 character",
                                  },
                                })}
                              />
                              {errors.caseNumber && (
                                <span role="alert" className="error_text">
                                  {errors.caseNumber.message}
                                </span>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="partC">
                        {getValues("surveyBenifitField") === "TANF" ||
                        getValues("surveyBenifitField") === "FDPIR" ||
                        getValues("surveyBenifitField") === "SNAP" ? (
                          ""
                        ) : (
                          <>
                            <h5>
                              <b>
                                Step 3. List ALL household members and income
                                for each member (before taxes and deductions)
                              </b>
                            </h5>
                            <p>
                              A. All Adult Household Members (Anyone who is
                              living with you and shares income and expenses,
                              even if not related, including you.)
                            </p>
                            <p>
                              List all Adult Household Members not listed in
                              STEP 1 (including yourself ) even if they do not
                              receive income. For each Household Member listed,
                              if they receive income, report total gross income
                              (before taxes and deductions) for each source in
                              whole dollars (no cents) only. If they do not
                              receive income from any source, write ‘0’. If you
                              enter ‘0’ or leave any fields blank, you are
                              certifying (promising) that there is no income to
                              report.
                            </p>
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead>
                                  <tr style={{ backgroundColor: "#f3f3f3" }}>
                                    <th>Date of Birth</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Income Source</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Field1?.filter(
                                    (x) => x?.isStudent === false
                                  )?.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <tr key={index}>
                                        <td>
                                          <input
                                            type="date"
                                            className="form-control"
                                            style={{
                                              minWidth: "135px",
                                              // maxWidth: "135px",
                                            }}
                                            {...register(
                                              `houseHoldList.${index}.birthDate`,
                                              {
                                                required:
                                                  "This field is required",
                                                valueAsDate: true,
                                                validate: {
                                                  isValidDate: (value) => {
                                                    if (value > new Date()) {
                                                      return "You can not enter more than today date";
                                                    }
                                                  },
                                                },
                                              }
                                            )}
                                          />
                                          {errors.houseHoldList?.[index]
                                            ?.birthDate && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.houseHoldList?.[index]
                                                  ?.birthDate.message
                                              }
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter First Name"
                                            {...register(
                                              `houseHoldList.${index}.firstName`,
                                              {
                                                required:
                                                  "This field is required",
                                              }
                                            )}
                                          />
                                          {errors.houseHoldList?.[index]
                                            ?.firstName && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.houseHoldList?.[index]
                                                  ?.firstName.message
                                              }
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Last Name"
                                            {...register(
                                              `houseHoldList.${index}.lastName`,
                                              {
                                                required:
                                                  "This field is required",
                                              }
                                            )}
                                          />
                                          {errors.houseHoldList?.[index]
                                            ?.lastName && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.houseHoldList?.[index]
                                                  ?.lastName.message
                                              }
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <Button
                                            className="theme_btn"
                                            onClick={() =>
                                              handleAddAnother(index)
                                            }
                                          >
                                            Add Income Source
                                          </Button>
                                        </td>
                                        <td>
                                          {index === 0 ? (
                                            <Button
                                              className="theme_btn"
                                              onClick={() => {
                                                append1({
                                                  birthDate: "",
                                                  firstName: "",
                                                  lastName: "",
                                                  isStudent: false,
                                                  grade: "",
                                                  fosterChild: "",
                                                  actualIncome: "",
                                                  paymentCycle: "",
                                                  studentType: "",
                                                  country: "USA",
                                                  removable: true,
                                                  houseHoldIncomeSub: [
                                                    {
                                                      incomeSource: "",
                                                      incomeAmount: "",
                                                      paymentCycle: "",
                                                    },
                                                  ],
                                                });
                                              }}
                                            >
                                              <AiOutlinePlus className="icon" />
                                            </Button>
                                          ) : item?.removable ? (
                                            <Button
                                              className="cancel_btn"
                                              onClick={() => {
                                                handleRemove1(index);
                                              }}
                                            >
                                              <AiOutlineClose className="icon" />
                                            </Button>
                                          ) : (
                                            ""
                                          )}
                                        </td>
                                      </tr>
                                      <>
                                        {item?.houseHoldIncomeSub?.length >
                                          0 && (
                                          <>
                                            <tr
                                              style={{
                                                backgroundColor: "#d4eed6",
                                              }}
                                            >
                                              <td></td>
                                              <td>Income Source</td>
                                              <td>Actual Income</td>
                                              <td>Payment Cycle</td>
                                              <td></td>
                                            </tr>
                                            {item?.houseHoldIncomeSub?.map(
                                              (_, index1) => (
                                                <tr>
                                                  <td></td>
                                                  <td>
                                                    <select
                                                      name=""
                                                      className="form-select"
                                                      {...register(
                                                        `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeSource`
                                                        // {
                                                        //   required:
                                                        //     "This field is required",
                                                        // }
                                                      )}
                                                    >
                                                      <option value="">
                                                        Select Income
                                                      </option>
                                                      <option value="Work">
                                                        Work
                                                      </option>
                                                      <option value="Public Assistance">
                                                        Public Assistance
                                                      </option>
                                                      <option value="Child Support">
                                                        Child Support
                                                      </option>
                                                      <option value="Alimony">
                                                        Alimony
                                                      </option>
                                                      <option value="Pension">
                                                        Pension
                                                      </option>
                                                      <option value="Retirment">
                                                        Retirment
                                                      </option>
                                                      <option value="Social Security">
                                                        Social Security
                                                      </option>
                                                      <option value="SSI">
                                                        SSI
                                                      </option>
                                                      <option value="VA Benefits">
                                                        VA Benefits
                                                      </option>{" "}
                                                      <option value="Other">
                                                        Other
                                                      </option>
                                                    </select>

                                                    {errors.houseHoldList?.[
                                                      index
                                                    ]?.houseHoldIncomeSub?.[
                                                      index1
                                                    ]?.incomeSource && (
                                                      <span
                                                        role="alert"
                                                        className="error_text"
                                                      >
                                                        {
                                                          errors
                                                            .houseHoldList?.[
                                                            index
                                                          ]
                                                            ?.houseHoldIncomeSub?.[
                                                            index1
                                                          ]?.incomeSource
                                                            .message
                                                        }
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter Actual Income"
                                                      {...register(
                                                        `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeAmount`,
                                                        {
                                                          valueAsNumber: true,
                                                          required:
                                                            "This field is required",
                                                          onChange: () =>
                                                            handleActualIncome1(),
                                                          validate: {
                                                            isValidNumber: (
                                                              value
                                                            ) => {
                                                              let v =
                                                                /^\d+(\.\d{1,2})?$/;
                                                              if (
                                                                !v.test(value)
                                                              ) {
                                                                return "Enter valid Actual Income";
                                                              }
                                                            },
                                                          },
                                                        }
                                                      )}
                                                    />
                                                    {errors.houseHoldList?.[
                                                      index
                                                    ]?.houseHoldIncomeSub?.[
                                                      index1
                                                    ]?.incomeAmount && (
                                                      <span
                                                        role="alert"
                                                        className="error_text"
                                                      >
                                                        {
                                                          errors
                                                            .houseHoldList?.[
                                                            index
                                                          ]
                                                            ?.houseHoldIncomeSub?.[
                                                            index1
                                                          ]?.incomeAmount
                                                            .message
                                                        }
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <select
                                                      name=""
                                                      className="form-select"
                                                      {...register(
                                                        `houseHoldList.${index}.houseHoldIncomeSub.${index1}.paymentCycle`,
                                                        {
                                                          // required:
                                                          //   "This field is required",
                                                          onChange: () =>
                                                            handleActualIncome1(),
                                                          validate: (val) => {
                                                            if (
                                                              watch(
                                                                `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeAmount`
                                                              ) > 0 &&
                                                              val === ""
                                                            ) {
                                                              return "This field is required";
                                                            }
                                                          },
                                                        }
                                                      )}
                                                    >
                                                      <option value="">
                                                        Select Payment Cycle
                                                      </option>
                                                      <option value="Annual">
                                                        Annual
                                                      </option>
                                                      <option value="Monthly">
                                                        Monthly
                                                      </option>
                                                      <option value="TwicePerMonth">
                                                        Twice Per Month
                                                      </option>
                                                      <option value="EveryTwoWeeks">
                                                        Every Two Weeks
                                                      </option>
                                                      <option value="Weekly">
                                                        Weekly
                                                      </option>
                                                    </select>
                                                    {errors.houseHoldList?.[
                                                      index
                                                    ]?.houseHoldIncomeSub?.[
                                                      index1
                                                    ]?.paymentCycle && (
                                                      <span
                                                        role="alert"
                                                        className="error_text"
                                                      >
                                                        {
                                                          errors
                                                            .houseHoldList?.[
                                                            index
                                                          ]
                                                            ?.houseHoldIncomeSub?.[
                                                            index1
                                                          ]?.paymentCycle
                                                            .message
                                                        }
                                                      </span>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {index1 === 0 ? (
                                                      ""
                                                    ) : (
                                                      <Button
                                                        className="cancel_btn"
                                                        onClick={() => {
                                                          handleRemove2(
                                                            index1,
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        <AiOutlineClose className="icon" />
                                                      </Button>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </>
                                        )}
                                      </>
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <h6>
                              B. Child Income Sometimes children in the
                              household earn or receive income. Include the
                              TOTAL income (before taxes and deductions)
                              received by ALL children listed in STEP 1 here.
                            </h6>
                            <div className="row my-3">
                              <div className="col-md-4 input_div">
                                <label>Total Child Income</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Total Child Income"
                                  {...register(`totalChildIncome`, {
                                    // required: "This field is required",
                                    valueAsNumber: true,
                                    onChange: () => handleActualIncome1(),
                                    // validate: {
                                    //   isValidNumber: (value) => {
                                    //     let v = /^\d+(\.\d{1,2})?$/;
                                    //     if (!v.test(value)) {
                                    //       return "Enter valid Actual Income";
                                    //     }
                                    //   },
                                    // },
                                  })}
                                />
                                {errors.totalChildIncome && (
                                  <span role="alert" className="error_text">
                                    {errors.totalChildIncome.message}
                                  </span>
                                )}
                              </div>{" "}
                              <div className="col-md-4 input_div">
                                <label>Child Payment Cycle</label>
                                <select
                                  name=""
                                  className="form-select"
                                  {...register("childPaymentCycle", {
                                    // required: "This field is required",
                                    onChange: () => handleActualIncome1(),

                                    validate: (val) => {
                                      if (
                                        watch(`totalChildIncome`) > 0 &&
                                        val === ""
                                      ) {
                                        return "This field is required";
                                      }
                                    },
                                  })}
                                >
                                  <option value="">Select Payment Cycle</option>
                                  <option value="Annual">Annual</option>
                                  <option value="Monthly">Monthly</option>
                                  <option value="TwicePerMonth">
                                    Twice Per Month
                                  </option>
                                  <option value="EveryTwoWeeks">
                                    Every Two Weeks
                                  </option>
                                  <option value="Weekly">Weekly</option>
                                </select>
                                {errors.childPaymentCycle && (
                                  <span role="alert" className="error_text">
                                    {errors.childPaymentCycle.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <h4 className="text-center text-bg-light py-2">
                          Household Income Range
                        </h4>
                        <div className="row form mb-3">
                          <div className="col-md-4 input_div">
                            <label>* HH Size ( Household Members )</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={
                                getValues("surveyBenifitField") === "TANF" ||
                                getValues("surveyBenifitField") === "FDPIR" ||
                                getValues("surveyBenifitField") === "SNAP"
                                  ? false
                                  : true
                              }
                              placeholder="Enter HH Size ( Household Members )"
                              {...register(`NoOfPeoeple`, {
                                required: "This field is required",
                              })}
                            />
                            {errors.NoOfPeoeple && (
                              <span role="alert" className="error_text">
                                {errors.NoOfPeoeple.message}
                              </span>
                            )}
                          </div>{" "}
                          <div className="col-md-4 input_div">
                            <label>Household Total Income</label>
                            <input
                              type="text"
                              disabled={true}
                              className="form-control"
                              placeholder="Enter Household Total Income"
                              {...register(`totalActualIncome`, {
                                // required: "This field is required",
                                pattern: {
                                  value: /^\d+(\.\d{1,2})?$/,
                                  message: "Enter valid Annual Income",
                                },
                              })}
                            />
                            {errors.totalActualIncome && (
                              <span role="alert" className="error_text">
                                {errors.totalActualIncome.message}
                              </span>
                            )}
                          </div>{" "}
                          <div className="col-md-4 input_div">
                            <label>Payment Cycle</label>
                            <select
                              name=""
                              disabled={true}
                              className="form-select"
                              {...register("paymentCycle", {
                                // required: "This field is required",
                              })}
                            >
                              <option value="">Select Payment Cycle</option>
                              <option value="Annual">Annual</option>
                              <option value="Monthly">Monthly</option>
                              <option value="TwicePerMonth">
                                Twice Per Month
                              </option>
                              <option value="EveryTwoWeeks">
                                Every Two Weeks
                              </option>
                              <option value="Weekly">Weekly</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="partD">
                        <h5>
                          <b>
                            Step 4. Contact information and adult signature.
                          </b>
                        </h5>
                        <p>
                          Please fill this section completely. Required fields
                          are designated by *
                        </p>
                        <div className="row form">
                          <div className="col-md-4 input_div">
                            <label>* Parent/Guardian First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter First Name"
                              {...register(`firstName`, {
                                required: "This field is required",
                              })}
                            />
                            {errors.firstName && (
                              <span role="alert" className="error_text">
                                {errors.firstName.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div">
                            <label>* Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Last Name"
                              {...register(`fullName`, {
                                required: "This field is required",
                              })}
                            />
                            {errors.fullName && (
                              <span role="alert" className="error_text">
                                {errors.fullName.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 mt-4">
                            <label className=" personal_detail_label">
                              <input
                                type="checkbox"
                                className="login_check"
                                {...register(`isSSN`, {
                                  onChange: (e) =>
                                    handleChoise(
                                      e.target.checked,
                                      e.target.checked
                                    ),
                                })}
                              />
                              <span className="checkmark"></span>
                              No SSN
                            </label>
                            {errors.isSSN && (
                              <span role="alert" className="error_text">
                                {errors.isSSN.message}
                              </span>
                            )}{" "}
                          </div>
                          {watch("isSSN") === true ? (
                            ""
                          ) : (
                            <div className="col-md-4 input_div">
                              <label>Last 4 Digit of SSN Number</label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Last 4 Digit of SSN Number"
                                {...register(`ssnNumber`, {
                                  required: "This field is required",
                                  maxLength: {
                                    value: 4,
                                    message: "Enter only 4 digit number",
                                  },
                                })}
                              />
                              {errors.ssnNumber && (
                                <span role="alert" className="error_text">
                                  {errors.ssnNumber.message}
                                </span>
                              )}
                            </div>
                          )}
                          <div className="col-md-4 input_div">
                            <label>* Address</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Address"
                              {...register(`address`, {
                                required: "This field is required",
                              })}
                            />
                            {errors.address && (
                              <span role="alert" className="error_text">
                                {errors.address.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div">
                            <label>APT Number</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Apt Number"
                              {...register(`apartment`, {
                                // required: "This field is required",
                              })}
                            />
                            {errors.apartment && (
                              <span role="alert" className="error_text">
                                {errors.apartment.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div">
                            <label>* Home Phone/Mobile Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Phone"
                              {...register(`phone`, {
                                required: "This field is required",
                                pattern: {
                                  value:
                                    /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                  message: "Enter valid phone number",
                                },
                              })}
                            />
                            {errors.phone && (
                              <span role="alert" className="error_text">
                                {errors.phone.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div">
                            <label>* City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter City"
                              {...register(`city`, {
                                required: "This field is required",
                              })}
                            />
                            {errors.city && (
                              <span role="alert" className="error_text">
                                {errors.city.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div">
                            <label>* State</label>
                            <select
                              name=""
                              className="form-select"
                              {...register("state", {
                                required: "This field is required",
                                valueAsNumber: true,
                              })}
                            >
                              <option value="">Select State</option>
                              {stateList &&
                                stateList?.map((data, i) => (
                                  <option key={i} value={data?.stateId}>
                                    {data?.stateName}
                                  </option>
                                ))}
                            </select>
                            {errors.state && (
                              <span role="alert" className="error_text">
                                {errors.state.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div">
                            <label> Email</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter Email"
                              {...register(`emailConfirmationAddress`, {
                                // required: "This field is required",
                                pattern: {
                                  value: /\S+@\S+\.\S+/,
                                  message:
                                    "Entered value does not match email format",
                                },
                              })}
                            />
                            {errors.emailConfirmationAddress && (
                              <span role="alert" className="error_text">
                                {errors.emailConfirmationAddress.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div">
                            <label className="form-label">
                              Upload Document
                              {/* <span className="red-color"> *</span> */}
                            </label>
                            <div className="upload_img">
                              <input
                                type="text"
                                className="form-control upload_box"
                                placeholder="No file Chosen"
                                readOnly
                                {...register("documents", {
                                  // required: "This field is required",
                                })}
                              />
                              <input
                                type="file"
                                id="school_img_upload_1"
                                accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .tiff, .pdf, .xls, .xlsx"
                                className="form-control"
                                style={{ display: "none" }}
                                onChange={handleFile}
                              />
                              <label
                                htmlFor="school_img_upload_1"
                                className="upload_img_label mb-0"
                              >
                                <img
                                  src={uploadimg}
                                  className="img-fluid"
                                  alt=""
                                />
                              </label>
                            </div>
                            {errors.documents && (
                              <span role="alert" className="error_text">
                                {errors.documents.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-4 input_div mt-2">
                            <label></label>
                            <label className=" personal_detail_label">
                              <input
                                type="checkbox"
                                className="login_check"
                                name="Remember Me"
                                {...register(`isSummerEBT`, {
                                  // required: "This field is required",
                                })}
                              />
                              <span className="checkmark"></span>
                              Check to Opt Out of Summer EBT Benefits.
                            </label>
                            {errors.isSummerEBT && (
                              <span role="alert" className="error_text">
                                {errors.isSummerEBT.message}
                              </span>
                            )}{" "}
                          </div>
                          <div className="my-4">
                            <h5>
                              Optional : Children’s ethnic and racial
                              identities. This information is kept confidential
                              and may be protected by the Privacy Act of 1974
                            </h5>
                            <p>
                              We are required to ask for information about your
                              children’s race and ethnicity. This information is
                              important and helps to make sure we are fully
                              serving our community. Responding to this section
                              is optional and does not affect your children’s
                              eligibility for free or reduced price meals.
                            </p>
                            <div>
                              <div className="row mb-2">
                                <div className="col-md-2">
                                  <label>Ethnicity (check one):</label>
                                </div>
                                <div className="col-md-6">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="isHispanic"
                                      id="hispanic"
                                      value={true}
                                      {...register(`isHispanic`, {
                                        // required: t("RequiredValidation"),
                                        onChange: (e) =>
                                          handleChoise(
                                            e.target.value,
                                            e.target.checked
                                          ),
                                      })}
                                    />
                                    <span className="checkmark"></span>
                                    Hispanic or Latino (A person of Cuban,
                                    Mexican, Puerto Rican, South or Central
                                    American, or other Spanish Culture or
                                    origin, regardless of race)
                                  </label>
                                  {errors.isHispanic && (
                                    <span role="alert" className="error_text">
                                      {errors.isHispanic.message}
                                    </span>
                                  )}
                                </div>

                                <div className="col-md-4">
                                  <label className="personal_detail_label">
                                    <input
                                      type="radio"
                                      className="login_check"
                                      name="isHispanic"
                                      id="notHispanic"
                                      value={false}
                                      {...register(`isHispanic`, {
                                        // required: t("RequiredValidation"),
                                        onChange: (e) =>
                                          handleChoise(
                                            e.target.value,
                                            e.target.checked
                                          ),
                                      })}
                                    />
                                    <span className="checkmark"></span>
                                    Not Hispanic or Latino
                                  </label>
                                  {errors.isHispanic && (
                                    <span role="alert" className="error_text">
                                      {errors.isHispanic.message}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-2">
                                  <label>Race (check one or more):</label>
                                </div>
                                <div className="col-md-2">
                                  <label className=" personal_detail_label">
                                    <input
                                      type="checkbox"
                                      className="login_check"
                                      name="Remember Me"
                                      value={"isAmericanIndianAlaskaNative"}
                                      {...register(`isRace`, {
                                        // required: t("RequiredValidation"),
                                        onChange: (e) =>
                                          handleChoise(
                                            e.target.checked,
                                            e.target.checked
                                          ),
                                      })}
                                    />
                                    <span className="checkmark"></span>
                                    American Indian or Alaska Native
                                  </label>
                                  {errors.isRace && (
                                    <span role="alert" className="error_text">
                                      {errors.isRace.message}
                                    </span>
                                  )}{" "}
                                </div>
                                <div className="col-md-2">
                                  <label className=" personal_detail_label">
                                    <input
                                      type="checkbox"
                                      className="login_check"
                                      value={"isAsian"}
                                      name="Remember Me"
                                      {...register(`isRace`, {
                                        // required: t("RequiredValidation"),
                                        onChange: (e) =>
                                          handleChoise(
                                            e.target.checked,
                                            e.target.checked
                                          ),
                                      })}
                                    />
                                    <span className="checkmark"></span>
                                    Asian
                                  </label>
                                  {errors.isRace && (
                                    <span role="alert" className="error_text">
                                      {errors.isRace.message}
                                    </span>
                                  )}{" "}
                                </div>
                                <div className="col-md-2">
                                  <label className=" personal_detail_label">
                                    <input
                                      type="checkbox"
                                      className="login_check"
                                      name="Remember Me"
                                      value={"isBlackAfricanAmerican"}
                                      {...register(`isRace`, {
                                        // required: t("RequiredValidation"),
                                        onChange: (e) =>
                                          handleChoise(
                                            e.target.checked,
                                            e.target.checked
                                          ),
                                      })}
                                    />
                                    <span className="checkmark"></span>
                                    Black or African American
                                  </label>
                                  {errors.isRace && (
                                    <span role="alert" className="error_text">
                                      {errors.isRace.message}
                                    </span>
                                  )}{" "}
                                </div>
                                <div className="col-md-2">
                                  <label className=" personal_detail_label">
                                    <input
                                      type="checkbox"
                                      className="login_check"
                                      name="Remember Me"
                                      value={
                                        "isNativeHawaiianOtherPacificIslande"
                                      }
                                      {...register(`isRace`, {
                                        // required: t("RequiredValidation"),
                                        onChange: (e) =>
                                          handleChoise(
                                            e.target.checked,
                                            e.target.checked
                                          ),
                                      })}
                                    />
                                    <span className="checkmark"></span>
                                    Native Hawaiian or Other Pacific Islander
                                  </label>
                                  {errors.isRace && (
                                    <span role="alert" className="error_text">
                                      {errors.isRace.message}
                                    </span>
                                  )}{" "}
                                </div>
                                <div className="col-md-2">
                                  <label className=" personal_detail_label">
                                    <input
                                      type="checkbox"
                                      className="login_check"
                                      name="Remember Me"
                                      value={"isWhite"}
                                      {...register(`isRace`, {
                                        // required: t("RequiredValidation"),
                                        onChange: (e) =>
                                          handleChoise(
                                            e.target.checked,
                                            e.target.checked
                                          ),
                                      })}
                                    />
                                    <span className="checkmark"></span>
                                    white
                                  </label>
                                  {errors.isRace && (
                                    <span role="alert" className="error_text">
                                      {errors.isRace.message}
                                    </span>
                                  )}{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <label className=" personal_detail_label">
                              <input
                                type="checkbox"
                                className="login_check"
                                name="Remember Me"
                                {...register(`isOtherStateEbtbenifit`, {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleChoise(
                                      e.target.checked,
                                      e.target.checked
                                    ),
                                })}
                              />
                              <span className="checkmark"></span>I certify that
                              I am not already receiving Summer EBT benefits in
                              another State.
                            </label>
                            {errors.isOtherStateEbtbenifit && (
                              <span role="alert" className="error_text">
                                {errors.isOtherStateEbtbenifit.message}
                              </span>
                            )}{" "}
                          </div>
                          <div className="col-md-9">
                            <label className=" personal_detail_label">
                              <input
                                type="checkbox"
                                className="login_check"
                                name="Remember Me"
                                {...register(`sign`, {
                                  required: "This field is required",
                                  onChange: (e) =>
                                    handleChoise(
                                      e.target.checked,
                                      e.target.checked
                                    ),
                                })}
                              />
                              <span className="checkmark"></span>I certify
                              (promise) that all information on this application
                              is true and that all income is reported. I
                              understand that this information is given in
                              connection with the receipt of Federal funds, and
                              that school official may verify (confirm) the
                              information. I am aware that if I purposely give
                              false information, my children may lose meal
                              and/or Summer EBT benefits, and I may be
                              prosecuted under applicable State and Federal
                              laws.
                            </label>
                            {errors.sign && (
                              <span role="alert" className="error_text">
                                {errors.sign.message}
                              </span>
                            )}{" "}
                          </div>
                          {watch("sign") ? (
                            <div className="col-md-4 input-div mt-2">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Signature Name"
                                {...register(`signatureName`, {
                                  required: "This field is required",
                                })}
                              />
                              {errors.signatureName && (
                                <span role="alert" className="error_text">
                                  {errors.signatureName.message}
                                </span>
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* {id && (
                            <>
                              <div className="col-md-12"></div>
                              <div className="col-md-8 mt-2">
                                <label>Notes</label>
                                <textarea
                                  rows={4}
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Notes"
                                  {...register(`notes`)}
                                />
                              </div>
                            </>
                          )} */}
                        </div>
                      </div>
                      <div className="mt-3 text-end">
                        {openCircle ? (
                          <Button disabled className="theme_btn me-3">
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : id ? (
                          <>
                            <Button
                              type="button"
                              onClick={handleSubmit(async (data) =>
                                onSubmit({ ...data, apiCall: true })
                              )}
                              className="theme_btn me-3"
                            >
                              Final Submit
                            </Button>
                            <Button type="submit" className="theme_btn me-3">
                              Save for Review
                            </Button>
                          </>
                        ) : (
                          <Button type="submit" className="theme_btn me-3">
                            Submit
                          </Button>
                        )}
                        {id ? (
                          <Button
                            className="cancel_btn"
                            onClick={() => window.close()}
                          >
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            className="cancel_btn"
                            onClick={() => nav(-1)}
                          >
                            Cancel
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
        <StudentPopup
          open={open}
          setOpen={setOpen}
          openCircle={openCircle}
          studentGuiId={studentGuiId}
          setStudentGuiId={setStudentGuiId}
          setOpenCircle={setOpenCircle}
        />
        {searchMyChildOpen && (
          <SearchMyChildren
            open={searchMyChildOpen}
            setOpen={setSearchMyChildOpen}
            fields={fields}
            handleAdd={handleAdd}
            selectDist={selectDist}
          />
        )}
      </Suspense>
    </>
  );
}

export default CreateSurvey;
