import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import "./index.scss";
import Select from "react-select";
import { AcademicYearListApi } from "../../../../../Action/AcademicYear";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "reportDate", label: "Date" },
  { id: "mealTitle", label: "Meal Title" },
  { id: "mealType", label: "Meal Type" },
  { id: "quantity", label: "Quantity" },
];

function ItemizationReport() {
  const [distList, setDistList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [reportDetails, setReportDetails] = useState();
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [derror, setDerror] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getAcademicYear();
    // eslint-disable-next-line
  }, []);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getAcademicYear = async () => {
    let resp = await AcademicYearListApi();
    if (resp?.length > 0) {
      setSchoolYearList(resp);
    }
  }

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getSchoolList = async (id) => {
    setSchoolList([]);
    setSchoolSelect([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          let uslist = [{ value: "all", label: "Select All" }, ...dlist];

          setSchoolList(uslist?.length > 1 ? uslist : []);
        } else {
          setSchoolList([]);
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }
  const getPayment = async (data) => {
    setPaymentList([]);
    let is_success = true;
    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }
    if (is_success) {
      setDerror({ err: "" });
      setOpenCircle(true);
      let Usdata = {
        schoolDistrictId: data?.schoolDistrictId
          ? data?.schoolDistrictId
          : null,
        schoolId: schoolSelect?.map((x) => x.value),
        startDate: data?.startDate ? data?.startDate : null,
        endDate: data?.endDate ? data?.endDate : null,
        userId: LogDetail?.userId,
        academicYearId: data?.academicYearId
      };
      let seData = {
        url: "/api/Report/GetItemizationReport",
        body: Usdata,
        headers: headers,
      };
      let distName = distList?.filter(
        (x) => x.value === Usdata?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();

      let sdate = data?.startDate ? data?.startDate?.toISOString() : null;
      let edate = data?.endDate ? data?.endDate?.toISOString() : null;
      let reData = {
        startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
        endDate: edate ? convertDate(edate?.split("T")[0]) : null,
        distName: distName,
        schName: schName,
      };
      setReportDetails(reData);
      let res = await PostCallApi(seData);
      setOpenCircle(false);

      if (res?.status === 200) {
        if (res.data.success) {
          let orData = res.data.payload?.map((x) => {
            return {
              ...x,
              reportDate: x?.reportDate
                ? new Date(x?.reportDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
                : "",
              quantity: x?.quantity?.toLocaleString(undefined, undefined)
            };
          });
          setPaymentList(orData);
        } else {
          setPaymentList([]);
        }
      }
    }
  };
  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };
  return (
    <>
      {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="itemization-page">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <h4 className="all_title mb-4"> Itemization Report</h4>
                <form onSubmit={handleSubmit(getPayment)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          District <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          School <span className="red-color">*</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                              selected.length &&
                              selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                                ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                                : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Date <span className="red-color">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("startDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.startDate && (
                          <span role="alert" className="error_text">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          End Date <span className="red-color">*</span>
                        </label>

                        <input
                          type="date"
                          className="form-control"
                          {...register("endDate", {
                            required: "This field is required",
                            valueAsDate: true,
                            validate: (val) => {
                              if (watch("startDate") > val) {
                                return "Your start date can not greater than from end date";
                              }
                            },
                          })}
                        />
                        {errors.endDate && (
                          <span role="alert" className="error_text">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School Year </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("academicYearId", {
                            valueAsNumber: true
                          })}
                        >
                          <option value="0">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.academicYearId && (
                          <span role="alert" className="error_text">
                            {errors.academicYearId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img src={filter} className="me-1" alt="" />
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <ListTable
                  headCells={productHead}
                  action={false}
                  downloadFile={true}
                  reportDetails={reportDetails}
                  fileName="Itemization Report"
                  ListData={paymentList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ItemizationReport;
