import React, { Suspense, useEffect, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateOverlay() {
  const { id } = useParams();
  const [schList, setSchList] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolList, setSchoolList] = useState();
  const [overDe, setOverDe] = useState();

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getdistList();
    // eslint-disable-next-line
  }, []);

  const getdistList = () => {
    let url = `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`;
    Axios.get(url, { headers })
      .then((response) => {
        if (response.data.success) {
          const data = response?.data.payload?.map((item) => {
            return {
              schId: item?.schoolDistrictId,
              schName: item?.districtName,
            };
          });
          setSchList(data);
        }
      })
      .catch((error) => console.log(error));
  };
  const getSchoolList = (id) => {
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setSchoolList(res.data.payload);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };
  const onSubmit = (data) => {
    let sendData = {
      ...data,
      createdBy: LogDetail?.userId,
    };

    Axios.post("/api/OverLay/CreateOverLay", sendData, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            reset();
            navigate(-1);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (schList?.length > 0) {
      if (id) {
        Edit(id);
      }
    }
    // eslint-disable-next-line
  }, [schList, id]);
  useEffect(() => {
    if (schoolList) {
      if (id) {
        setValue("schoolId", overDe?.schoolId);
      }
    }
    // eslint-disable-next-line
  }, [schoolList, id]);
  const Edit = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/OverLay/GetOverLayDataById?overLayId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let catData = res.data.payload;
            setOverDe(catData);
            setValue("overlayId", catData?.overlayId);
            setValue("overlayName", catData?.overlayName);
            setValue("mealService", catData?.mealService);
            // setValue("type", catData?.type);
            setValue("schoolDistrictId", catData?.schoolDistrictId);
            getSchoolList(catData?.schoolDistrictId);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_meals">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">Create Overlay</div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Overlay Name <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Overlay Name"
                          {...register("overlayName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.overlayName && (
                          <span className="formError errorMssg error_text">
                            {errors.overlayName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Meal Service <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("mealService", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Meal Service </option>
                          <option value="Breakfast">Breakfast</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.mealService && (
                          <span className="formError errorMssg error_text">
                            {errors.mealService.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Overlay Type <span className="red-color">*</span>
                        </label>

                        <select
                          name=""
                          className="form-select"
                          {...register("type", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Type</option>
                          <option value="Overlay">Overlay</option>
                          <option value="Serving Line">Serving Line</option>
                        </select>
                        {errors.type && <span className="formError errorMssg error_text">{errors.type.message}</span>}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          disabled={id ? true : false}
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {schList &&
                            schList?.map((data, i) => (
                              <option value={data?.schId} key={i}>
                                {data?.schName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span className="formError errorMssg error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          disabled={id ? true : false}
                          className="form-select"
                          {...register("schoolId", {
                            // required: "This field is required",
                          })}
                        >
                          <option value="">Select School </option>
                          {schoolList &&
                            schoolList?.map((item, i) => (
                              <option value={item.schoolId} key={i}>
                                {item.schoolName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end mt-3">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateOverlay;
