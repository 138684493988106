import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateSiteManagment() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openCircle, setOpenCircle] = useState(false);
  const [distList, setDistList] = useState([]);
  const [siteList, setSiteList] = useState();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getStateList();
    if (distList?.length > 0) {
      if (id) {
        EditSettingsSite(id);
      }
    }
    // eslint-disable-next-line
  }, [distList, id]);
  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                schId: sd?.schoolDistrictId,
                Districtname: sd?.districtName,
              };
            });
            setDistList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    setOpenCircle(true);
    Axios.post("/api/School/CreateSchool", sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const EditSettingsSite = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/School/GetSchoolDataById?schoolId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let editsiteData = res.data.payload;
            setValue("schoolDistrictId", editsiteData?.schoolDistrictId);
            setValue("schoolId", editsiteData?.schoolId);
            setValue("schoolName", editsiteData?.schoolName);
            setValue("schoolCode", editsiteData?.schoolCode);
            setValue("servingLine", editsiteData?.servingLine);
            setValue("address", editsiteData?.address);
            setValue("phoneNumber", editsiteData?.phoneNumber);
            setValue("email", editsiteData?.email);
            setValue("city", editsiteData?.city);
            setValue("state", editsiteData?.state);
            setValue("zip", editsiteData?.zip);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setSiteList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createprimarytab-page">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Site</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Site Code <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Site Code "
                          {...register("schoolCode", {
                            required: "This field is required",
                          })}
                        />
                        {errors.schoolCode && (
                          <span role="alert" className="error_text">
                            {errors.schoolCode.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Site Name <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Site Name"
                          {...register("schoolName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.schoolName && (
                          <span role="alert" className="error_text">
                            {errors.schoolName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.schId}>
                                {data?.Districtname}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Number Serving Lines{" "}
                          <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Number Serving Lines"
                          {...register("servingLine", {
                            required: "This field is required",
                          })}
                        />
                        {errors.servingLine && (
                          <span role="alert" className="error_text">
                            {errors.servingLine.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          State <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          defaultValue=""
                          {...register("state", {
                            required: "This field is required",
                            valueAsNumber: true,
                          })}
                        >
                          <option value="">Select State</option>
                          {siteList &&
                            siteList?.map((data, i) => (
                              <option key={i} value={data?.stateId}>
                                {data?.stateName}
                              </option>
                            ))}
                        </select>
                        {errors.state && (
                          <span role="alert" className="error_text">
                            {errors.state.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>City</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="city "
                          {...register("city")}
                        />
                        {errors.city && (
                          <span role="alert" className="error_text">
                            {errors.city.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Address</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address "
                          {...register("address")}
                        />
                        {errors.address && (
                          <span role="alert" className="error_text">
                            {errors.address.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Zip</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="zip "
                          {...register("zip")}
                        />
                        {errors.zip && (
                          <span role="alert" className="error_text">
                            {errors.zip.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Area <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Area"
                          {...register("area", {
                            required: "This field is required",
                            pattern: {
                              message: "area",
                            },
                          })}
                        />
                        {errors.area && (
                          <span role="alert" className="error_text">
                            {errors.area.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Manager Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Manager Name "
                          {...register("mname", {
                            required: "This field is required",
                            pattern: {
                              message: "mname",
                            },
                          })}
                        />
                        {errors.mname && (
                          <span role="alert" className="error_text">
                            {errors.mname.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          {...register("phoneNumber", {
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        {errors.phoneNumber && (
                          <span role="alert" className="error_text">
                            {errors.phoneNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Fax <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Fax "
                          {...register("fax", {
                            required: "This field is required",
                            pattern: {
                              message: "fax",
                            },
                          })}
                        />
                        {errors.fax && (
                          <span role="alert" className="error_text">
                            {errors.fax.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label> Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateSiteManagment;
