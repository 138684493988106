import React, { Suspense, lazy, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);


function SecondnaryTab() {

  const productHead = [
    { id: "address", label: "Address" },
    { id: "apt", label: "APT" },
    { id: "city", label: "City" },
    { id: "state", label: "State" },
    { id: "zip", label: "Zip" },
    { id: "pname", label: "Parent Name" },
    { id: "email", label: "Email" },
  ];

  let deleteInfo = useSelector((state) => state.Common?.DeleteData); useForm();
  // const [prdList, setPrdList] = useState([
  //   {
  //     address: "the menrin martin chaty",
  //     apt: "-",
  //     city: "Ahmedabad",
  //     state: "Ahmedabad",
  //     zip: "14253658",
  //     pname: "Preent Name",
  //     email: "abc@gamil",
  //     Action: (
  //       <>
  //         <Link
  //           type="button"
  //           className="btn_edit me-2"
  //           // to={`/product-manage/list/create/${1}`}
  //           to={`/createsecondnaryTab/create`}
  //           // onClick={() => routeChange(c?.companyId)}
  //         >
  //           <MdEdit fontSize={24} />
  //         </Link>
  //         <DeletePopup title={"Delete Product"} text={"product"} url={``} />
  //       </>
  //     ),
  //   },
  //   {
  //     address: "the menrin martin chaty",
  //     apt: "-",
  //     city: "Ahmedabad",
  //     state: "Ahmedabad",
  //     zip: "14253658",
  //     pname: "Preent Name",
  //     email: "abc@gamil",
  //     Action: (
  //       <>
  //         <Link
  //           type="button"
  //           className="btn_edit me-2"
  //           // to={`/product-manage/list/create/${1}`}
  //           to={`/createsecondnaryTab/create`}
  //           // onClick={() => routeChange(c?.companyId)}
  //         >
  //           <MdEdit fontSize={24} />
  //         </Link>
  //         <DeletePopup title={"Delete Product"} text={"product"} url={``} />
  //       </>
  //     ),
  //   },
  //   {
  //     address: "the menrin martin chaty",
  //     apt: "-",
  //     city: "Ahmedabad",
  //     state: "Ahmedabad",
  //     zip: "14253658",
  //     pname: "Preent Name",
  //     email: "abc@gamil",
  //     Action: (
  //       <>
  //         <Link
  //           type="button"
  //           className="btn_edit me-2"
  //           // to={`/product-manage/list/create/${1}`}
  //           to={`/createsecondnaryTab/create`}
  //           // onClick={() => routeChange(c?.companyId)}
  //         >
  //           <MdEdit fontSize={24} />
  //         </Link>
  //         <DeletePopup title={"Delete Product"} text={"product"} url={``} />
  //       </>
  //     ),
  //   },
  //   {
  //     address: "the menrin martin chaty",
  //     apt: "-",
  //     city: "Ahmedabad",
  //     state: "Ahmedabad",
  //     zip: "14253658",
  //     pname: "Preent Name",
  //     email: "abc@gamil",
  //     Action: (
  //       <>
  //         <Link
  //           type="button"
  //           className="btn_edit me-2"
  //           // to={`/product-manage/list/create/${1}`}
  //           to={`/createsecondnaryTab/create`}
  //           // onClick={() => routeChange(c?.companyId)}
  //         >
  //           <MdEdit fontSize={24} />
  //         </Link>
  //         <DeletePopup title={"Delete Product"} text={"product"} url={``} />
  //       </>
  //     ),
  //   },
  // ]);

  const [prdList, setPrdList] = useState([])
  useEffect(() => {
    HouseholdSecondaryTabData();
  }, [deleteInfo]);

  const HouseholdSecondaryTabData = () => {
    Axios.get('/HouseholdSecondaryTab')
      .then((res) => {
        const SdData = res.data.map((sd, i) => {
          return {
            address: sd?.address,
            apt: sd?.apt,
            city: sd?.city,
            state: sd?.state,
            zip: sd?.zip,
            pname: sd?.pname,
            email: sd?.email,
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`/createsecondnaryTab/edit/${sd?.id}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
                <DeletePopup
                  title={"Delete Product"}
                  text={sd?.pname}
                  url={`/HouseholdSecondaryTab/${sd?.id}`}
                />
              </>
            ),
          }
        });
        setPrdList(SdData);
      })
      .catch((e) => console.log(e));
  }

  return (
    <Suspense fallback={renderLoader()}>
      <ListTable headCells={productHead} action={true} ListData={prdList} />
    </Suspense>
  );
}

export default SecondnaryTab;
