import React, { Suspense, useCallback, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import "./index.scss";
import profilebg from "../../../../Assets/PngImage/profile-bg.jpg";
import userprofile from "../../../../Assets/PngImage/userprofile.png";
import camera from "../../../../Assets/SvgImage/camera-icon.svg";
import ChildInformationTab from "../Profile/ChildInformationTab";
import HouseholdInformationTab from "../Profile/HouseholdInformationTab";
import LoginDetailsTab from "../Profile/LoginDetailsTab";
import { useDispatch, useSelector } from "react-redux";
import CropModal from "../../../../Components/Reusable/CropModel";
import Axios from "../../../../Utils/AxiosConfig";
import { LoginUser } from "../../../../reducers/login";
import { Config } from "../../../../Utils/Config";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function Profile() {
  const [openCircle, setOpenCircle] = useState(false);

  const [values, setValues] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };
  let dispatch = useDispatch();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const profileUpload = (img) => {
    let sendData = {
      profilePhoto: img,
      studentGuidId: LogDetail?.studentGuidId,
    };
    setOpenCircle(true);
    Axios.post(
      `/api/Student/AddStudentProfilePhotoById?studentGuidId=${LogDetail?.studentGuidId}`,
      sendData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            dispatch(
              LoginUser({
                ...LogDetail,
                profilePhoto: res.data.payload.profilePhoto,
              })
            );
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const CoverUpload = (img) => {
    let sendData = {
      coverPhoto: img,
      studentGuidId: LogDetail?.studentGuidId,
    };
    setOpenCircle(true);
    Axios.post(
      `/api/Student/AddStudentCoverPhotoById?studentGuidId=${LogDetail?.studentGuidId}`,
      sendData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            dispatch(
              LoginUser({
                ...LogDetail,
                coverPhoto: res.data.payload.coverPhoto,
              })
            );
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        // setValue("ProductImageUrl1", url);
        profileUpload(url);
        setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };
  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1, setCroppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        CoverUpload(url);
        setCroppedImageUrl1(URL.createObjectURL(url));
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );
  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="profile-page">
          <div className="main_wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="all_title mb-5">Profile</div>
                <div className="profile-bg">
                  <div className="pr-bg">
                    <img
                      src={
                        LogDetail?.coverPhoto
                          ? `${Config.API_HOST_URL_live}${LogDetail?.coverPhoto}`
                          : profilebg
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <input
                    type="file"
                    id="uscp"
                    accept="image/*"
                    className="form-control"
                    style={{ display: "none" }}
                    onChange={handleFile1}
                  />
                  <label htmlFor="uscp" className="upload_img_label mb-0 ">
                    <img src={camera} alt="camera Img" className="camera-img" />
                  </label>
                  {src1 && (
                    <CropModal
                      src={src1}
                      filename={filename1}
                      setCroppedImageUrl={setCroppedImageUrlCallback1}
                      openCropModal={openCropModal1}
                      setCropModal={setCropModal1}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Start Profile Section */}
        <section className="profile-section">
          <div className="main_wrapper">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="profile-img">
                  <div className="user-img-block">
                    <div className="user-img">
                      <img
                        src={
                          LogDetail?.profilePhoto
                            ? `${Config.API_HOST_URL_live}${LogDetail?.profilePhoto}`
                            : userprofile
                        }
                        alt="Profile Img"
                        className="img-fluid"
                      />
                    </div>
                    <input
                      type="file"
                      id="uspp"
                      accept="image/*"
                      className="form-control"
                      style={{ display: "none" }}
                      onChange={handleFile}
                    />
                    <label htmlFor="uspp" className="upload_img_label mb-0 ">
                      <img
                        src={camera}
                        alt="camera Img"
                        className="camera-img"
                      />
                    </label>
                  </div>
                  {src && (
                    <CropModal
                      src={src}
                      filename={filename}
                      setCroppedImageUrl={setCroppedImageUrlCallback}
                      openCropModal={openCropModal}
                      setCropModal={setCropModal}
                    />
                  )}
                  <div>
                    <h5>{LogDetail?.firstName + " " + LogDetail?.lastName}</h5>
                    <p>From {LogDetail?.districtName}</p>
                  </div>
                </div>
                <div className="profile-tab">
                  <TabContext value={values}>
                    <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
                      <Box className="tab_group d-flex justify-content-between">
                        <TabList
                          onChange={handleChangeTab}
                          aria-label="lab API tabs example"
                          className="tab_list"
                        >
                          <Tab
                            label="Child Information"
                            value="1"
                            className="tabs_link tab_1"
                          />
                          <Tab
                            label="Household Information"
                            value="2"
                            className="tabs_link tab_2"
                          />
                          <Tab
                            label="Login Details"
                            value="3"
                            className="tabs_link tab_3"
                          />
                        </TabList>
                      </Box>
                    </div>
                    <div className="alltabcontent">
                      <TabPanel value="1" className="tab_panel p-0">
                        <ChildInformationTab />
                      </TabPanel>
                      <TabPanel value="2" className="tab_panel p-0">
                        <HouseholdInformationTab />
                      </TabPanel>
                      <TabPanel value="3" className="tab_panel p-0">
                        <LoginDetailsTab />
                      </TabPanel>
                    </div>
                  </TabContext>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Profile Section */}
      </Suspense>
    </>
  );
}

export default Profile;
