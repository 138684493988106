import React from "react";
import {
  SwalError,
  SwalSuccess,
} from "../../../../../Components/Reusable/SwalMessage";

import { useForm } from "react-hook-form";
import { PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import {
  Button,
  CircularProgress,
  Modal,
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 600 : "90%",
  maxHeight: "80%",
  overflow: "auto",
  background: "#ffffff",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#ffffff",

  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AddNotes(props) {
  const { openPopup, handleClosePopup, houseHoldId } = props;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [noteCircle, setNoteCircle] = React.useState(false);
  const [noteRemove, setNoteRemove] = React.useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const handleAddNotes = async (data) => {
    setNoteCircle(true);

    let nt = houseHoldId?.oldNote?.length > 0 ? houseHoldId?.oldNote : null;
    let sendNote = {
      houseHoldSurveyId: houseHoldId?.houseHoldSurveyId,
      notesDatas: houseHoldId?.edit
        ? houseHoldId?.oldNote?.filter(
            (x) => !noteRemove?.includes(x?.houseHoldSurveyNotesId)
          )
        : [
            ...(nt ?? ""),
            {
              houseHoldSurveyNotesId: "00000000-0000-0000-0000-000000000000",
              notes: data?.notes,
            },
          ],
      createdBy: LogDetail?.userId,
    };
    let apiUrl = {
      url: `/api/Survey/UpdateHouseHoldSurveyNote`,
      body: sendNote,
    };
    const respo = await PostCallApi(apiUrl);
    setNoteCircle(false);
    if (respo?.status === 200 && respo?.data?.success) {
      reset();
      setNoteRemove([]);
      SwalSuccess(respo?.data?.message);
      let sd = respo?.data?.payload;
      handleClosePopup(
        { ...sd, edit: houseHoldId?.edit },
        houseHoldId?.edit ? sendNote?.notesDatas : []
      );
    } else {
      reset();
      SwalError(respo?.data?.message);
      handleClosePopup();
    }
  };
  const handleRemoveNote = (x) => {
    setNoteRemove([...noteRemove, x]);
  };
  return (
    <Modal
      open={openPopup}
      onClose={() => handleClosePopup()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="toggle_delete_data"
    >
      <Box sx={style} className="districtList_box">
        <div className="districtList_details">
          <h6>Notes</h6>
          <div className="inner_sec">
            <form
              action=""
              className="mb-3"
              onSubmit={handleSubmit(handleAddNotes)}
            >
              {houseHoldId?.edit ? (
                <Paper className="table-box mb-3">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell>No</StyledTableCell>
                          <StyledTableCell>Notes</StyledTableCell>
                          <StyledTableCell>Date</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {houseHoldId?.oldNote
                          ?.filter(
                            (x) =>
                              !noteRemove?.includes(x?.houseHoldSurveyNotesId)
                          )
                          ?.map((x, i) => {
                            return (
                              <StyledTableRow key={i}>
                                <StyledTableCell>{i + 1}</StyledTableCell>
                                <StyledTableCell>{x?.notes}</StyledTableCell>
                                <StyledTableCell>
                                  {x?.createdOn
                                    ? new Date(x?.createdOn)?.toLocaleString(
                                        "en-us",
                                        {
                                          year: "2-digit",
                                          month: "2-digit",
                                          day: "2-digit",
                                          hour: "2-digit",
                                          hour12: true,
                                          minute: "2-digit",
                                        }
                                      )
                                    : ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Button
                                    sx={{
                                      borderRadius: "5px",
                                      padding: "0 0.5rem",
                                      background: "transparent",
                                      fontSize: "20px",
                                      color: "#606060",
                                      border: 0,
                                      lineHeight: "18px",
                                      ".icon": {
                                        color: "red",
                                      },
                                    }}
                                    onClick={() =>
                                      handleRemoveNote(
                                        x?.houseHoldSurveyNotesId
                                      )
                                    }
                                  >
                                    <MdDelete className="icon" fontSize={24} />
                                  </Button>
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Add Notes</label>
                      <textarea
                        rows={4}
                        type="text"
                        className="form-control"
                        placeholder="Enter Notes"
                        {...register(`notes`, {
                          required: "This field is required",
                        })}
                      />
                      {errors.notes && (
                        <span role="alert" className="error_text">
                          {errors.notes.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center justify-content-center">
                {noteCircle ? (
                  <Button disabled className="popup_btn delete me-3">
                    <CircularProgress
                      sx={{
                        color: "#fff",
                        marginLeft: "0.5rem",
                        height: "35px !important",
                        width: "35px !important",
                      }}
                    />
                  </Button>
                ) : houseHoldId?.edit ? (
                  <Button
                    type="button"
                    className="popup_btn delete"
                    onClick={handleAddNotes}
                  >
                    Save
                  </Button>
                ) : (
                  <Button type="submit" className="popup_btn delete">
                    Add
                  </Button>
                )}
                <button
                  type="button"
                  className="popup_btn cancel"
                  onClick={() => {
                    reset();
                    setNoteRemove([]);
                    handleClosePopup();
                  }}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default AddNotes;
