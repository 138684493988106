import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";


const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

function Transfer() {

    const { id } = useParams()
    let nav = useNavigate()

    const [openCircle, setOpenCircle] = useState(false);
    const [stdList, setStdList] = useState([]);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getStudentList();
        getIdwiseList();
        // eslint-disable-next-line
    }, []);

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getIdwiseList = async () => {
        let apiUrl = {
            url: `/api/Parents/GetStudentBalanceById?studentId=${id}`,
            headers: headers
        }
        let res = await GetCallApi(apiUrl)
        if (res?.status === 200) {
            if (res.data.success) {
                const studentList = res.data.payload
                setValue("studentId", studentList?.firstName + " " + studentList?.lastName)
                setValue("studentBalance", studentList?.studentBalance)
            }
        }
    };

    const getStudentList = async () => {
        let apiUrl = {
            url: `/api/ParentModule/GetStudentListByParentID?parentGuidId=${LogDetail?.parentsGuidId}`,
            headers: headers
        }
        let res = await GetCallApi(apiUrl)
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.filter((c) => c?.studentGuidId !== id)?.map((sd) => ({
                    value: sd?.studentGuidId,
                    label: sd?.studentName,
                }));
                setStdList(dlist?.length > 0 ? dlist : []);
            }
        }
    };
    const onSubmit = async (data) => {
        setOpenCircle(true)
        let seData = {
            ...data,
            studentId: id,
            amount: parseFloat(data?.amount),
            parentId: LogDetail?.parentsGuidId,
        }

        let apiData = {
            url: `/api/Parents/ParentTransferMoney`,
            body: seData,
            headers: headers
        }

        const resp = await PostCallApi(apiData)
        setOpenCircle(false)
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: resp.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
                nav(-1)
            } else {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: resp?.data?.message
                        ? resp?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: resp?.data?.message
                    ? resp?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    }

    return (
        <>
            <Backdrop
                open={openCircle}
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="transfer-page">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="all_title mb-4">Transfer Money</h4>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Student
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Student Name"
                                                    {...register(`studentId`, {
                                                    })}
                                                />
                                                {errors?.studentId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.studentId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Available Balance
                                                </label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Available Balance"
                                                    {...register(`studentBalance`, {
                                                    })}
                                                />
                                                {errors.studentBalance && (
                                                    <span role="alert" className="error_text">
                                                        {errors.studentBalance.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Student Transfer Monney
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    placeholder="Select Student Transfer Monney"
                                                    {...register("transferStudentId", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select Student</option>
                                                    {stdList.map((item, i) => (
                                                        <option key={i} value={item?.value}>
                                                            {item?.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors?.transferStudentId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.transferStudentId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Transfer Balance
                                                    <span className="red-color"> *</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Transfer Balance"
                                                    {...register(`amount`, {
                                                        required: "This field is required",
                                                        pattern: {
                                                            value: /^\d+(\.\d{1,2})?$/,
                                                            message:
                                                                "Enter valid transfer balance value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                                                        },
                                                        validate: (val) => {
                                                            if (watch("studentBalance") < val) {
                                                                return "You can not enter max balance of available balance";
                                                            }
                                                        },
                                                    })}
                                                />
                                                {errors.amount && (
                                                    <span role="alert" className="error_text">
                                                        {errors.amount.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className=" ms-auto mt-auto col-md-3">
                                            <div className="form-group text-end filter-img">
                                                <Button
                                                    className="cancel_btn me-3"
                                                    onClick={() => nav(-1)}
                                                >
                                                    Cancel
                                                </Button>
                                                {openCircle ? (
                                                    <Button className="theme_btn text-end">
                                                        Applying Filter{" "}
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="theme_btn text-end" type="submit">
                                                        <img src={filter} className="me-1" alt="" />
                                                        Apply
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default Transfer;
