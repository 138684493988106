import React, { Suspense, lazy, useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import { GetCallApi } from "../../../../../Action/Action";
import ToggleButton from "../../../../../Components/Reusable/ToggleButton";
import { useForm } from "react-hook-form";

const ListTable = lazy(() =>
    import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => <p></p>;

const mealpointHead = [
    { id: "schoolDistrictName", label: "School District" },
    { id: "mealType", label: "Meal Service" },
    { id: "points", label: "Points" },
    { id: "type", label: "Credit Type" },
    { id: "freeMealAlaCarte", label: "FreeMeal/AlaCarte" },
    { id: "createdOn", label: "Created On" },
];

function MealPointList() {
    const [mealPointList, setMealPointList] = useState([]);

    const {
        handleSubmit,
    } = useForm();

    let deleteInfo = useSelector((state) => state.Common?.DeleteData);

    useEffect(() => {
        GetMealPoint();
        // eslint-disable-next-line
    }, [deleteInfo]);

    const GetMealPoint = async (data) => {
        setMealPointList([]);

        let seData = {
            url: `/api/MealPoint/GetAllMealPointData?creditType=${data?.creditType ? data?.creditType : ""}`,
        };

        let resp = await GetCallApi(seData);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                const sdData = resp.data.payload?.map((c) => {
                    return {
                        ...c,
                        createdOn: c?.createdOn
                            ? new Date(c?.createdOn)?.toLocaleDateString()
                            : "",
                        isActive: c?.isActive === true ? "True" : "False",
                        Action: (
                            <>
                                {c?.isActive &&
                                    <Link
                                        type="button"
                                        className="btn_edit me-2"
                                        to={`edit/${c?.mealPointId}`}
                                    >
                                        <MdEdit fontSize={24} />
                                    </Link>
                                }
                                <ToggleButton
                                    checked={c?.isActive === true ? "1" : "0"}
                                    title={"Category status"}
                                    text={c?.categoryName}
                                    url={`/api/MealPoint/DeleteMealPointData?mealPointId=${c?.mealPointId}`}
                                />
                            </>
                        ),
                    };
                });
                setMealPointList(sdData ?? []);
            }
        }
    };

    return (
        <>
            <Suspense fallback={renderLoader()}>
                <section className="mealpoint_listpage">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="all_title mb-4">Meal Point List</h4>
                                <form onSubmit={handleSubmit(GetMealPoint)}>
                                    <div className="row">
                                        {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Credit Type
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"`
                                                    {...register("creditType", {
                                                    })}
                                                >
                                                    <option value="">Select Credit Type </option>
                                                    <option value="Point Accumulation">Point Accumulation</option>
                                                    <option value="Point Redemption">Point Redemption</option>
                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="ms-auto mt-auto col-md-6">
                                            <div className="form-group filter_box filter-img">
                                                {/* {openCircle ? (
                                                    <Button className="theme_btn text-end">
                                                        Applying Filter{" "}
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="theme_btn text-end" type="submit">
                                                        <img src={filter} className="me-1" alt="" />
                                                        Apply
                                                    </Button>
                                                )} */}
                                                <Link to={"create"} className="theme_btn ms-3">
                                                    <BsPlusSquareDotted fontSize={24} className="me-3" />
                                                    Create
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <ListTable
                                    headCells={mealpointHead}
                                    action={true}
                                    ListData={mealPointList}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default MealPointList;
