import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
// import userprofileimg from "../../../../../Assets/SvgImage/userprofileimg.svg";
// import john from "../../../../../Assets/SvgImage/john-profile.svg";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function HouseholdInformationTab() {
  const [openCircle, setOpenCircle] = useState(false);
  const [parentList, setParentList] = useState([]);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    GetStudentInfo();
    // eslint-disable-next-line
  }, []);
  const GetStudentInfo = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/ParentModule/GetStudentListByParentID?parentGuidId=${LogDetail?.parentsGuidId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setParentList(res.data.payload);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="parent-household-section">
          <div className="row">
            {parentList?.map((prt, i) => (
              <div className="col-lg-6 col-md-12 mb-3" key={i}>
                <div className="parent-household-box">
                  <div className="user-title">
                    <h3>{prt?.studentName} </h3>
                  </div>
                  <ul>
                    <li>
                      <p>Number :</p>
                      <span>{prt?.phone}</span>
                    </li>
                    <li>
                      <p>Email :</p>
                      <span>{prt?.email}</span>
                    </li>
                    <li>
                      <p>Birth Date : </p>
                      <span>
                        {" "}
                        {prt?.birthDate
                          ? new Date(prt?.birthDate)?.toLocaleDateString()
                          : ""}
                      </span>
                    </li>
                    <li>
                      <p>Student Balance :</p>
                      <span>{prt?.studentBalance}</span>
                    </li>
                    <li>
                      <p>School District :</p>
                      <span>{prt?.schoolDistrictName}</span>
                    </li>
                    <li>
                      <p>School :</p>
                      <span>{prt?.schoolName}</span>
                    </li>
                    <li>
                      <p>Payment Status :</p>
                      <span>{prt?.paymentStatus}</span>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default HouseholdInformationTab;
