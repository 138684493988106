import React, { Suspense, useEffect, useRef, useState } from "react";
import {
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableHead,
    TableRow,
    styled,
} from "@mui/material";
import "./index.scss";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useDownloadExcel } from "react-export-table-to-excel";
import { Config } from "../../../../../Utils/Config";
import Select from "react-select";

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "transperent",
    },
    "&.pdf_download": {
        fontSize: "9px !important",
        padding: "3px !important",
        lineHeight: "inherit",
        ".MuiTableSortLabel-root": {
            ".MuiSvgIcon-root": { display: "none " },
        },
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    // "&:last-child td, &:last-child th": {
    //   border: 0,
    // },
    // "&.pdf_download": {
    //   borderTop: "0px",
    //   borderBottom: "0.5px",
    // },
}));

function MonthEndReport() {
    const [parentList, setParentList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [monthEnd, setMonthEnd] = useState([]);
    const [dropdata, setDropData] = useState();
    const [openCircle, setOpenCircle] = useState(false);
    const [schoolSelect, setSchoolSelect] = useState([]);
    const [derror, setDerror] = useState();
    const [downloadPdf, setDownloadpdf] = useState(false);
    const tableRef = useRef(null);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getDistrictList();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (downloadPdf) {
            handleDownloadPdf();
        }
        // eslint-disable-next-line
    }, [downloadPdf]);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: "Month End Paperwork Report",
        sheet: "Month End PsperWork",
    });

    let Time = new Date();

    const back = {
        maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    };

    const handleDownloadPdf = async () => {
        const element = tableRef.current;
        let newPdfDate = Time.toLocaleDateString();
        // return false;
        const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
        doc
            .html(element, {
                margin: [20, 10, 20, 10],
                autoPaging: "text",
                html2canvas: { scale: 0.57 },
            })
            .then(() => {
                let totalPages = doc.internal.pages.length - 1;
                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i);
                    doc.setFontSize(10);
                    doc.setTextColor(150);
                    doc.text(
                        newPdfDate,
                        doc.internal.pageSize.width / 2,
                        doc.internal.pageSize.getHeight() - 10,
                        { align: "center" }
                    );
                }
                doc.save(
                    `Month End Paperwork Report.pdf`
                );
                setDownloadpdf(false);
            });
    };

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getDistrictList = async () => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setParentList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getSchoolList = async (id) => {
        setSchoolList([]);
        setSchoolSelect([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);
            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 1 ? uslist : []);
                }
            }
        } else {
            setSchoolList([]);
        }
    };
    function mathRound(val) {
        return (Math.round(val * 100) / 100).toFixed(2);
    }

    const getDeposit = async (data) => {
        setMonthEnd([]);
        let is_success = true;

        if (schoolSelect?.length === 0) {
            is_success = false;
            setDerror({ err: "Select minimum one school" });
        }
        if (is_success) {
            setDerror({ err: "" });
            setOpenCircle(true);
            let sendData = {
                ...data,
                userId: LogDetail?.userId,
                schoolId: schoolSelect?.map((x) => x.value),
            };
            let distName = parentList?.filter(
                (x) => x.value === sendData?.schoolDistrictId
            )[0]?.label;
            let schName =
                schoolSelect?.length === schoolList?.length - 1
                    ? "All"
                    : schoolSelect?.map((x) => x.label).toString();

            let dropData = {
                distName: distName,
                schName: schName,
                schoolDistrictId: data?.schoolDistrictId
                    ? data?.schoolDistrictId
                    : null,
                schoolId: data?.schoolId ? data?.schoolId : null,
            };
            setDropData(dropData);
            let seData = {
                url: `/api/Report/GetDCRTReport`,
                body: sendData,
                headers: headers,
            };
            let res = await PostCallApi(seData);
            setOpenCircle(false);

            if (res?.status === 200) {
                if (res?.data.success) {
                    let resVal = res?.data.payload;

                    const dList = res?.data.payload.dcrtReportSchoolList?.map((x) => {
                        return {
                            ...x,
                            dcrtCount: x?.dcrtCount?.toLocaleString(undefined, undefined),
                            dcrtPercentage: mathRound(x?.dcrtPercentage) + "%",
                            freeClaim: mathRound(x?.freeClaim) + "%",
                            paidClaim: mathRound(x?.paidClaim) + "%",
                        };
                    });
                    let newAr = [
                        ...dList,
                        {
                            schoolCode: "Summary: ",
                            schoolName: "",
                            enrollment: resVal?.totalEnrollment,
                            dcrtCount: resVal?.totalDCRTCount?.toLocaleString(
                                undefined,
                                undefined
                            ),
                            dcrtPercentage: mathRound(resVal?.totalDCRTPercentage) + "%",
                            freeClaim: mathRound(resVal?.totalFreeClaim) + "%",
                            paidClaim: mathRound(resVal?.totalPaidClaim) + "%",
                            addbr: true,
                        },
                    ];
                    setMonthEnd(newAr);
                } else {
                    setMonthEnd([]);
                }
            }
        }
    };
    const handleIOCMulti = (e) => {
        setSchoolSelect(e);
    };
    return (
        <>
            {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
            <section className="month_end_page">
                <div className="main_wrapper">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="all_title mb-4">Month End Paperwork Report</h4>
                            <form onSubmit={handleSubmit(getDeposit)}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {" "}
                                                District
                                                <span className="red-color"> *</span>
                                            </label>
                                            <select
                                                name=""
                                                className="form-select"
                                                {...register("schoolDistrictId", {
                                                    required: "This field is required",
                                                    onChange: (e) => getSchoolList(e.target.value),
                                                })}
                                            >
                                                <option value="">Select School District</option>
                                                {parentList &&
                                                    parentList?.map((data, i) => (
                                                        <option key={i} value={data?.value}>
                                                            {data?.label}
                                                        </option>
                                                    ))}
                                            </select>
                                            {errors.schoolDistrictId && (
                                                <span role="alert" className="error_text">
                                                    {errors.schoolDistrictId.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {" "}
                                                School
                                                <span className="red-color"> *</span>
                                            </label>
                                            <Select
                                                isMulti
                                                name="type"
                                                options={schoolList}
                                                // closeMenuOnSelect={false}
                                                // hideSelectedOptions={false}
                                                className="form-neselect"
                                                classNamePrefix="select"
                                                placeholder="Select School"
                                                value={schoolSelect ? schoolSelect : null}
                                                onChange={(selected) => {
                                                    true &&
                                                        selected.length &&
                                                        selected.find((option) => option.value === "all")
                                                        ? handleIOCMulti(schoolList.slice(1))
                                                        : !true
                                                            ? handleIOCMulti(
                                                                (selected && selected.value) || null
                                                            )
                                                            : handleIOCMulti(selected);
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            {derror?.err && (
                                                <span role="alert" className="d-block error_text">
                                                    {derror.err}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>
                                                {" "}
                                                Month
                                                <span className="red-color"> *</span>
                                            </label>
                                            <input
                                                type="month"
                                                className="form-control"
                                                placeholder="Select Month Year"
                                                {...register("monthYear", {
                                                    required: "This field is required",
                                                    // onChange: (e) => console.log(e?.target?.value)
                                                })}
                                            />
                                            {errors.monthYear && (
                                                <span role="alert" className="error_text">
                                                    {errors.monthYear.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className=" ms-auto mt-auto col-md-3">
                                        <div className="form-group text-end filter-img">
                                            {!openCircle ? (
                                                dropdata?.scName || dropdata?.distName ? (
                                                    <>
                                                        <Button
                                                            className="cancel_btn me-3 text-end"
                                                            type="button"
                                                            onClick={() => setDownloadpdf(true)}
                                                        >
                                                            Pdf
                                                        </Button>
                                                        <Button
                                                            className="cancel_btn me-3 text-end"
                                                            type="button"
                                                            onClick={() => onDownload()}
                                                        >
                                                            Download
                                                        </Button>
                                                    </>
                                                ) : (
                                                    ""
                                                )
                                            ) : (
                                                ""
                                            )}

                                            {openCircle ? (
                                                <Button className="theme_btn text-end">
                                                    Applying Filter{" "}
                                                    <CircularProgress
                                                        sx={{
                                                            color: "#fff",
                                                            marginLeft: "0.5rem",
                                                            height: "23px !important",
                                                            width: "23px !important",
                                                        }}
                                                    />
                                                </Button>
                                            ) : (
                                                <Button className="theme_btn text-end" type="submit">
                                                    <img
                                                        src={filter}
                                                        className="me-1"
                                                        alt=""
                                                        height={23}
                                                    />
                                                    Apply
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Suspense fallback={renderLoader()}>
                                <div ref={tableRef} style={back}>
                                    {dropdata?.scName || dropdata?.distName ? (
                                        <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img
                                                    src={
                                                        downloadPdf
                                                            ? require("../../../../../Assets/PngImage/Header_logo_1.png")
                                                            : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
                                                    }
                                                    alt=""
                                                    width={100}
                                                    height={80}
                                                />
                                                <div className="ms-3">
                                                    <label
                                                        style={{
                                                            fontSize: "18px",
                                                            color: "#2c2e35",
                                                            fontWeight: 600,
                                                            // marginBottom: "7px",
                                                        }}
                                                    >
                                                        Month End Paperwork Report
                                                    </label>
                                                </div>
                                            </div>
                                            <div>
                                                <label
                                                    style={{
                                                        fontSize: "18px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                        width: "250px",
                                                        whiteSpace: "pre-line",
                                                    }}
                                                >
                                                    {dropdata?.schName}
                                                </label>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                    }}
                                                >
                                                    {dropdata?.distName}
                                                </div>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                        marginBottom: "7px",
                                                    }}
                                                >
                                                    {Time.toLocaleString()}
                                                </div>
                                                <div
                                                    className="names"
                                                    style={{
                                                        fontSize: "16px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {LogDetail?.firstName + " " + LogDetail?.lastName}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {monthEnd && monthEnd?.length > 0 ? (
                                        <>
                                            <p>Dear Mr. Somick,</p>
                                            <p>
                                                Attached is the Month End Paperwork for{" "}
                                                <b>9/30/2022.</b>
                                            </p>
                                            <p>
                                                The return to the district for the month is{" "}
                                                <b>$3,370.01.</b>
                                            </p>
                                            <p>
                                                The return to the district for the Current Year is{" "}
                                                <b>$3,370.01.</b>
                                            </p>
                                            <p>
                                                Total Revenue for the month is <b>$231,377.85.</b>
                                            </p>
                                            <p>
                                                The Expenses for the current month are:{" "}
                                                <b>$228,007.84.</b>
                                            </p>
                                            <p>
                                                The Commodity Value used for the month is:{" "}
                                                <b>$30,945.99</b>
                                            </p>
                                            <div className="first_table">
                                                <Table
                                                    className={`mb-0 table-bordered`}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Commodity
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Value
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <>
                                                            {[...Array(5)?.keys()]?.map((x, ind) => (
                                                                <StyledTableRow key={ind}>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        NJ Food Distribution Commodities
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        $15,860.71
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </>
                                                        {/* <TableRow className="all_list">
                                                        <StyledTableCell
                                                            className="er_nodata m-0 p-5"
                                                            colSpan={17}
                                                        >
                                                            No Data Found
                                                        </StyledTableCell>
                                                    </TableRow> */}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            <p className="my-3">
                                                Our Year to Date Commodity Value Used is:{" "}
                                                <b>$30,945.99</b>
                                            </p>
                                            <p>Management Fee Calculations</p>
                                            <div className="second_table">
                                                <Table
                                                    className={`mb-0 table-bordered`}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Meal Type
                                                            </StyledTableCell>

                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Count
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <>
                                                            {[...Array(7)?.keys()]?.map((x, ind) => (
                                                                <StyledTableRow key={ind}>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        Breakfast Meals
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        17,638
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </>
                                                        {/* <TableRow className="all_list">
                                                        <StyledTableCell
                                                            className="er_nodata m-0 p-5"
                                                            colSpan={17}
                                                        >
                                                            No Data Found
                                                        </StyledTableCell>
                                                    </TableRow> */}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            <p className="my-3">CPM</p>
                                            <p>
                                                Fee as per Contract: <b>$0.240</b>
                                            </p>
                                            <p>
                                                Fee Charged: <b>$0.240 $16,196.85</b>
                                            </p>
                                            <p>
                                                The Undersigned FSMC representative certifies the
                                                following:
                                                <ul>
                                                    <li>
                                                        That all costs included on the Operating Statement
                                                        are allowable costs.
                                                    </li>
                                                    <li>
                                                        That all Discounts, Rebates and Credits are reported
                                                        and certified by the FSMC using information from the
                                                        agencies listed above.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>Certified by:</p>
                                            <p>Name/Title:</p>
                                            <p>Signature:</p>
                                            <div className="third_table">
                                                <Table
                                                    className={`mb-0 table-bordered`}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Amount Billed
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Invoice #
                                                            </StyledTableCell>{" "}
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Paid by Check #
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <>
                                                            {[...Array(4)?.keys()]?.map((x, ind) => (
                                                                <StyledTableRow key={ind}>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        Meal Program
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        083122
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        $228,007.84
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </>
                                                        {/* <TableRow className="all_list">
                                                        <StyledTableCell
                                                            className="er_nodata m-0 p-5"
                                                            colSpan={17}
                                                        >
                                                            No Data Found
                                                        </StyledTableCell>
                                                    </TableRow> */}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            <div className="fourth_table mt-3">
                                                <Table
                                                    className={`mb-0 table-bordered`}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Reviewed By
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Name
                                                            </StyledTableCell>{" "}
                                                            <StyledTableCell
                                                                className={`${downloadPdf && "pdf_download"}`}
                                                            >
                                                                Date
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <>
                                                            {[...Array(4)?.keys()]?.map((x, ind) => (
                                                                <StyledTableRow key={ind}>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        Meal Program
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        083122
                                                                    </StyledTableCell>
                                                                    <StyledTableCell
                                                                        className={`${downloadPdf && "pdf_download"
                                                                            }`}
                                                                    >
                                                                        $228,007.84
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </>
                                                        {/* <TableRow className="all_list">
                                                            <StyledTableCell
                                                                className="er_nodata m-0 p-5"
                                                                colSpan={17}
                                                            >
                                                                No Data Found
                                                            </StyledTableCell>
                                                        </TableRow> */}
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default MonthEndReport;
