import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  styled,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { visuallyHidden } from "@mui/utils";
import Pagination from "../../../../../../Components/Reusable/Paginations/Pagination";
import { CiSearch } from "react-icons/ci";
import "./index.scss";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F1F6FC",
  },
  "&.sm_8": {
    fontSize: "8.3px !important",
    padding: "2px !important",
    lineHeight: "inherit",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#F1F6FC",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function AllergenTable(props) {
  const {
    headCells,
    action,
    ListData,
    pageApi,
    totalRecord,
    actionFirst,
    setCheckbox,
    checkBox,
    handleCheckALL,
  } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("prdName");

  function descendingComparator(a, b, orderBy) {
    if (
      b[orderBy]?.toString()?.toLowerCase() <
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return -1;
    }
    if (
      b[orderBy]?.toString()?.toLowerCase() >
      a[orderBy]?.toString()?.toLowerCase()
    ) {
      return 1;
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <>
            {actionFirst && action ? (
              <StyledTableCell align="center">
                <TableSortLabel className="svg_hide">
                  <label className=" personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      checked={checkBox}
                      onChange={(e) =>
                        handleCheckALL(e.target.checked, formattedSearchResults)
                      }
                    />
                    <span className="checkmark"> </span>
                  </label>
                </TableSortLabel>
              </StyledTableCell>
            ) : (
              ""
            )}
          </>
          {headCells?.map((headCell) => (
            <StyledTableCell
              key={headCell?.id}
              align="left"
              sortDirection={orderBy === headCell?.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={orderBy === headCell?.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </StyledTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [searchField, setSearchField] = useState("");
  const formattedSearchResults = ListData?.filter((user) => {
    if (searchField) {
      let state = false;
      headCells?.forEach((property) => {
        let value = String(user[property?.id]);
        if (typeof value === "string") {
          if (value?.toLowerCase()?.includes(searchField.toLowerCase())) {
            state = true;
          }
        }
      });
      return state;
    } else return user;
  });
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="mb-4 d-flex justify-content-end allergen_table">
        <div className="row mt-4 d-flex justify-content-end">
          <div className={`search_sec ms-auto`}>
            <div className="search_input w-100">
              <CiSearch className="icon" />
              <input
                onChange={(e) => {
                  setSearchField(e.target.value);
                  setCurrentPage(1);
                }}
                type="text"
                className="form-control"
                placeholder="Type your search here"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 allergen_product">
        <Paper className="table-box">
          <TableContainer>
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                selectAllBtn={props?.selectAllBtn}
              />
              <TableBody>
                {stableSort(
                  formattedSearchResults,
                  getComparator(order, orderBy)
                )
                  ?.slice(
                    (currentPage - 1) * pageSize,
                    (currentPage - 1) * pageSize + pageSize
                  )
                  .map((row, index) => {
                    return (
                      <React.Fragment key={index + 1}>
                        <StyledTableRow className="table_body" key={index + 1}>
                          {actionFirst ? (
                            <>
                              {action ? (
                                <StyledTableCell
                                  className="column_data"
                                  key={index + 1}
                                >
                                  <div className="edit_delete">
                                    {row?.Action}
                                  </div>
                                </StyledTableCell>
                              ) : (
                                ""
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          {headCells?.map((column) => {
                            const value = row[column?.id];
                            if (column?.widthFix) {
                              return (
                                <StyledTableCell
                                  className="column_data"
                                  key={column?.id}
                                >
                                  {value ? value?.slice(0, 50) : ""}
                                </StyledTableCell>
                              );
                            }
                            return (
                              <StyledTableCell
                                className="column_data"
                                key={column?.id}
                              >
                                {value}
                              </StyledTableCell>
                            );
                          })}
                        </StyledTableRow>
                      </React.Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {formattedSearchResults?.length === 0 ? (
            <div className="er_nodata">No Data Found</div>
          ) : (
            <div className="page_nation">
              <div className="rowsPerPageOptions">
                <span className="p_18_text">show</span>
                <select
                  className="pagination_select p_16_text"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(parseInt(e.target.value));
                    handlePageChange(1, parseInt(e.target.value));
                    setCheckbox(false);
                    // setCurrentPage(1);
                  }}
                >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  {props.entries1000 ? (
                    ""
                  ) : (
                    <>
                      <option value="2000">2000</option>
                      <option value="5000">5000</option>
                      <option value="10000">10000</option>
                    </>
                  )}
                </select>
                <span className="p_18_text">entries</span>
              </div>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                totalCount={
                  pageApi ? totalRecord : formattedSearchResults?.length
                }
                pageSize={pageSize}
                onPageChange={(page) => {
                  handlePageChange(page, pageSize);
                  setCheckbox(false);
                }}
              />
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}
export default React.memo(AllergenTable);
