import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import ListTable from "../../../../../Components/Reusable/ListTable";
import "./index.scss";
import { PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";
import DeletePopup from "../../../../../Components/Reusable/DeletePopup";
import ImgPopup from "../../../../../Components/Reusable/ImgPopup";
import { Config } from "../../../../../Utils/Config";
import default_image from "../../../../../Assets/default_profile.jpg"
import { useForm } from 'react-hook-form';
import filter from "../../../../../Assets/PngImage/filter.png";


const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);
const financeVendorHead = [
    { id: "logoImage", label: "Logo" },
    { id: "vendorName", label: "Vendor Name" },
    { id: "vendorCategoryName", label: "Vendor Category" },
    { id: "address", label: "Address" },
    { id: "city", label: "City" },
    { id: "state", label: "State" },
    { id: "zipCode", label: "Zip Code" },
];

const FinanceVendorList = () => {
    const [financeVendorList, setFinanceVendorList] = useState([]);
    const [distList, setDistList] = useState([]);
    const [open, setOpen] = useState(false);


    let deleteInfo = useSelector((state) => state.Common?.DeleteData);
    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const {
        handleSubmit,
        register,
        setValue,
        getValues,
    } = useForm();

    useEffect(() => {
        const districtList = LogDetail?.districtClasses?.map((x) => {
            return {
                value: x?.schoolDistrictId,
                label: x?.schoolDistrictName,
            }
        })
        setDistList(districtList ?? []);
        setTimeout(() => {
            setValue("dist", districtList[0]?.value);
        }, 1000);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setTimeout(() => {
            getFinanceVendor({ dist: getValues('dist') });
        }, 1500)
        // eslint-disable-next-line 
    }, [deleteInfo]);

    const getFinanceVendor = async (data) => {
        setFinanceVendorList([]);
        setOpen(true);

        let sendBody = {
            schoolDistrictId: data?.dist ? data?.dist : "00000000-0000-0000-0000-000000000000",
            schoolId: []
        }

        let seData = {
            url: `/api/FinanceVendor/GetFinanceVendorsData`,
            body : sendBody
        };

        let resp = await PostCallApi(seData);
        setOpen(false);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                const sdData = resp.data.payload?.map((c) => {
                    return {
                        ...c,
                        logoImage: (<ImgPopup img={
                            c?.logoImage
                                ? `${Config.API_HOST_URL_live}${c?.logoImage}`
                                : default_image} />),
                        Action: (
                            <>
                                <Link
                                    type="button"
                                    className="btn_edit me-2"
                                    to={`edit/${c?.vendorId}/${c?.paymentRemittanceInformation[0]?.paymentRemittanceInformationId}`}
                                >
                                    <MdEdit fontSize={24} />
                                </Link>
                                {/* <ToggleButton
                                    checked={c?.isActive === true ? "1" : "0"}
                                    title={"Category status"}
                                    text={c?.categoryName}
                                    url={`/api/FinanceVendorCategory/DeleteFinanceVendorCategory?vendorCategoryId=${c?.vendorCategoryId}`}
                                /> */}
                                <DeletePopup
                                    title={"Delete Finance Vendor"}
                                    text={c?.vendorName}
                                    url={`/api/FinanceVendor/DeleteFinanceVendor?vendorId=${c?.vendorId}`}
                                />
                            </>
                        ),
                    };
                });
                setFinanceVendorList(sdData);
            } else {
                setOpen(false);
            }
        }
    };


    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="finance_income_category_listpage">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="product_box ">
                                    <h4 className="all_title mb-0">Finance Vendor List</h4>
                                    <div className="d-flex">
                                        <Link to={"create"} className="theme_btn">
                                            <BsPlusSquareDotted fontSize={24} className="me-3" />
                                            Create
                                        </Link>
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(getFinanceVendor)}>
                                    <div className="row mb-3">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> District
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("dist", {
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {distList &&
                                                        distList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="ms-auto mt-auto col-md-4">
                                            <div className="form-group text-end filter-img">
                                                {open ? (
                                                    <Button className="theme_btn text-end">
                                                        Applying Filter{" "}
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="theme_btn text-end" type="submit">
                                                        <img
                                                            src={filter}
                                                            className="me-1"
                                                            alt=""
                                                            height={23}
                                                        />
                                                        Apply
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <ListTable
                                    headCells={financeVendorHead}
                                    action={true}
                                    ListData={financeVendorList}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    )
}

export default FinanceVendorList