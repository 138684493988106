import React, { Suspense, lazy, useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Backdrop,
    Button,
    CircularProgress,
    Typography,
} from "@mui/material";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import filterIcon from "../../../../Assets/SvgImage/filter.svg";
import { Link } from "react-router-dom";
import { Config } from "../../../../Utils/Config";
import Swal from "sweetalert2";

const ListTable = lazy(() =>
    import("../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const templateHead = [
    { id: "studentName", label: "Student Name" },
    { id: "gradeId", label: "Grade" },
    { id: "parentName", label: "Parent Name" },
    { id: "email", label: "Email" },
    { id: "primaryPhoneNumber", label: "Parent Phone" },
    // { id: "imageUrl", label: "Student Document" },
];

function StudentDocuments() {
    const [prdList, setPrdList] = useState([]);
    const [parentList, setParentList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [schoolSelect, setSchoolSelect] = useState([]);
    const [distId, setDistId] = useState([]);
    const [openCircle, setOpenCircle] = useState(false);
    const [open, setOpen] = useState(false);
    const [derror, setDerror] = useState();
    const [allergen, setAllergen] = useState([]);

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    useEffect(() => {
        getDistrictList();
        getAllergenList()
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {   
    //     if (emailId?.length > 0) {
    //         changeTbData();
    //     } else {
    //         changeTbData();
    //     }
    //     // eslint-disable-next-line
    // }, [emailId]);

    const getAllergenList = async () => {
        setAllergen([]);
        let seData2 = {
            url: `/api/AllergenGroup/GetAllergenGroupData`,
            headers: headers,
        };
        let respo = await GetCallApi(seData2);
        if (respo?.status === 200) {
            if (respo.data.success) {
                const alList = respo.data.payload?.map((as) => {
                    return {
                        ...as,
                    };
                });
                setAllergen(alList?.length > 0 ? alList : []);
            }
        }
    };

    const getDistrictList = async () => {
        setParentList([]);
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictListByParents?parentsGuidId=${LogDetail?.parentsGuidId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);
        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setParentList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getSchoolList = async (id) => {
        setDistId(id);
        setSchoolList([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${"38bc40e8-0c3d-4b71-9b85-35d53ae57316"}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);
            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    // let uslist = [...dlist];
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 0 ? uslist : []);
                }
            }
        } else {
            setSchoolList([]);
        }
    };

    const handleDownload = async (sId) => {
        setOpenCircle(true)
        let apiUrl = {
            url: `/api/ParentDownloadDocument/DownloadStudentDocumentByParents?studentId=${sId}`,
            headers: headers
        }

        const resp = await PostCallApi(apiUrl)
        setOpenCircle(false)
        if (resp?.status === 200) {
            if (resp.data.success) {
                let downloadUrl = `${Config.API_HOST_URL_live}${resp.data?.payload}`;
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'studentdocuments.zip');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: resp?.data?.message
                        ? resp?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        }
        else {
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: resp?.data?.message
                    ? resp?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    }

    const getCustList = async (data) => {
        setPrdList([]);
        if (schoolSelect?.length === 0) {
            setDerror({ sch: "This field is required" });
        } else {
            setOpen(true);
            setDerror({ sch: "" });
            let seData = {
                url: `/api/ParentDownloadDocument/GetStudentDataByParents`,
                body: {
                    ...data,
                    schoolDistrictId: distId ? distId : "",
                    schoolId: schoolSelect ? schoolSelect?.map((s) => s?.value) : [],
                    allergenGroupId: data?.allergenGroupId ? data?.allergenGroupId : null
                },
                headers: headers,
            };
            let res = await PostCallApi(seData);
            setOpen(false);
            if (res?.status === 200) {
                if (res?.data.success) {
                    let d = res?.data?.payload?.map((x, i) => {
                        return {
                            ...x,
                            Action: (
                                <Link
                                    className="add-log-btn ms-2"
                                    onClick={() => handleDownload(x?.studentGuidId)}
                                >
                                    Download
                                </Link>
                            ),
                        };
                    });
                    setPrdList(d);
                }
            }
        }
    };

    const handleMulti = (e) => {
        if (e) {
            setSchoolSelect(e);
        } else {
            setPrdList([]);
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openCircle}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="document_list">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(getCustList)}>
                                    <div className="product_box">
                                        <div className="all_title ProductTitle">
                                            Student Document Download
                                        </div>
                                    </div>
                                    <div className="row">
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<GridExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"
                                            >
                                                <Typography>
                                                    <img src={filterIcon} alt="filter" />
                                                    <span className="filter_by">Filter By</span>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                School District
                                                                <span className="red-color"> *</span>
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("distId", {
                                                                    required: "This field is required",
                                                                    onChange: (e) => {
                                                                        getSchoolList(e.target.value);
                                                                        setSchoolSelect([]);
                                                                    },
                                                                })}
                                                            >
                                                                <option value="">Select School District</option>
                                                                {parentList &&
                                                                    parentList?.map((data, i) => (
                                                                        <option key={i} value={data?.value}>
                                                                            {data?.label}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                            {errors?.distId && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.distId.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                School <span className="red-color">*</span>
                                                            </label>
                                                            <Select
                                                                isMulti
                                                                name="type"
                                                                options={schoolList}
                                                                className="form-neselect"
                                                                classNamePrefix="select"
                                                                placeholder="Select School"
                                                                value={schoolSelect ? schoolSelect : null}
                                                                onChange={(selected) => {
                                                                    true &&
                                                                        selected.length &&
                                                                        selected.find(
                                                                            (option) => option.value === "all"
                                                                        )
                                                                        ? handleMulti(schoolList.slice(1))
                                                                        : !true
                                                                            ? handleMulti(
                                                                                (selected && selected.value) || null
                                                                            )
                                                                            : handleMulti(selected);
                                                                }}
                                                                style={{ width: "100%" }}
                                                            />
                                                            {derror?.sch && (
                                                                <span
                                                                    role="alert"
                                                                    className="d-block error_text"
                                                                >
                                                                    {derror?.sch}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Household Type{" "}
                                                                {/* <span className="red-color">*</span> */}
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("studentType", {
                                                                    // required: "This field is required",
                                                                })}
                                                            >
                                                                <option value="">Select HouseHold</option>
                                                                <option value="All">Both</option>
                                                                <option value="Primary">Primary</option>
                                                                <option value="Secondary">Secondary</option>
                                                            </select>
                                                            {errors?.studentType && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.studentType.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Is Dc
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("isDC", {
                                                                    // required: "This field is required",
                                                                })}
                                                            >
                                                                <option value="">Select Is Dc</option>
                                                                <option value="true">True</option>
                                                                <option value="false">False</option>
                                                            </select>
                                                            {errors.isDC && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.isDC.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                show Negative Balance
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("showNegativeBalance", {
                                                                    // required: "This field is required",
                                                                })}
                                                            >
                                                                <option value="">
                                                                    Select Negative Balance
                                                                </option>
                                                                <option value="true">True</option>
                                                                <option value="false">False</option>
                                                            </select>
                                                            {errors.showNegativeBalance && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.showNegativeBalance.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Allergen Group
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <select
                                                                name=""
                                                                className="form-select"
                                                                {...register("allergenGroupId", {
                                                                    // required: "This field is required",
                                                                })}
                                                            >
                                                                <option value="">Select Allergen Group</option>
                                                                {allergen &&
                                                                    allergen?.map((a, i) => {
                                                                        return (
                                                                            <option
                                                                                key={i}
                                                                                value={a?.allergenGroupId}
                                                                            >
                                                                                {a?.allergenGroup}
                                                                            </option>
                                                                        );
                                                                    })}
                                                            </select>
                                                            {errors.allergenGroupId && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.allergenGroupId.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Status
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Status"
                                                                {...register("status", {
                                                                    // required: "This field is required",
                                                                })}
                                                            />
                                                            {errors.status && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.status.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Grades
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Grades"
                                                                {...register("grades", {
                                                                    // required: "This field is required",
                                                                })}
                                                            />
                                                            {errors.grades && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.grades.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>
                                                                Home Room
                                                                {/* <span className="red-color"> *</span> */}
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Home Room"
                                                                {...register("homeRoom", {
                                                                    // required: "This field is required",
                                                                })}
                                                            />
                                                            {errors.homeRoom && (
                                                                <span role="alert" className="error_text">
                                                                    {errors.homeRoom.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                            <div className="ms-auto mt-auto col-md-4">
                                                <div className="form-group text-end filter-img">
                                                    {open ? (
                                                        <Button className="theme_btn text-end me-3">
                                                            Applying Filter{" "}
                                                            <CircularProgress
                                                                sx={{
                                                                    color: "#fff",
                                                                    marginLeft: "0.5rem",
                                                                    height: "23px !important",
                                                                    width: "23px !important",
                                                                }}
                                                            />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            className="theme_btn text-end me-3"
                                                            type="submit"
                                                        >
                                                            Apply
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </Accordion>
                                    </div>
                                    <ListTable
                                        headCells={templateHead}
                                        action={true}
                                        ListData={prdList}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default StudentDocuments;
