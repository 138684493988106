import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";
import ToggleButton from "../../../../../Components/Reusable/ToggleButton";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
// const DeletePopup = lazy(() =>
//   import("../../../../../Components/Reusable/DeletePopup")
// );
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "menuName", label: "Menu Name" },
  { id: "menuType", label: "Menu Type" },
  { id: "parentMenuName", label: "Parent Menu Name" },
  { id: "menuUrl", label: "Menu Url" },
  { id: "sequenceNo", label: "Sequence No" },
  { id: "description", label: "Description" },
];
function Menu() {
  const [tbData, setTbData] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    GetMenuList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const GetMenuList = () => {
    setOpenCircle(true);
    Axios.get(`/api/Menu/GetAllMenuList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload
              // ?.filter((x) => x.isActive === true)
              ?.map((sd, i) => {
                return {
                  ...sd,
                  Action: (
                    <>
                      <Link
                        type="button"
                        className="btn_edit me-2"
                        to={`edit/${sd?.menuId}`}
                      >
                        <MdEdit fontSize={24} />
                      </Link>
                      <ToggleButton
                        checked={sd?.isActive === true ? "1" : "0"}
                        title={"Delete Menu"}
                        text={sd?.menuName}
                        url={`/api/Menu/DeleteMenu?MenuId=${sd?.menuId}`}
                      />
                    </>
                  ),
                };
              });
            setTbData(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {/* <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
      </div> */}
        <section className="menu_listpage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">Menu List</div>
                  <div className="d-flex">
                    <Link to={"Create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={tbData}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Menu;
