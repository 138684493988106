import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import filterIcon from "../../../../../Assets/SvgImage/filter.svg";
import uploadimg from "../../../../../Assets/SvgImage/file-upload.svg";
import { VscTrash } from "react-icons/vsc";
import { v4 } from "uuid";

const EmailTable = lazy(() =>
  import("../../Settings/SendCommunicateEmails/EmailTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const templateHead = [
  { id: "studentName", label: "Student Name" },
  { id: "gradeId", label: "Grade" },
  { id: "parentName", label: "Parent Name" },
  { id: "email", label: "Parent Email" },
  { id: "primaryPhoneNumber", label: "Parent Phone" },
];

function UploadDocuments() {
  const [prdList, setPrdList] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [emailId, setEmailId] = useState([]);
  const [distId, setDistId] = useState([]);
  const [openCircle, setOpenCircle] = React.useState(false);
  const [checkBox, setCheckbox] = useState(false);
  const [open, setOpen] = useState(false);
  const [derror, setDerror] = useState();
  const [derrorFile, setDerrorFile] = useState();
  const [derror2, setDerror2] = useState();
  const [allergen, setAllergen] = useState([]);
  const [date, setDate] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileSize, setFileSize] = useState(0);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm();

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getDistrictList();
    // getTemplateList();
    getAllergenList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (emailId?.length > 0) {
      changeTbData();
    } else {
      changeTbData();
    }
    // eslint-disable-next-line
  }, [emailId]);

  const getDistrictList = async () => {
    setParentList([]);
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setParentList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getAllergenList = async () => {
    setAllergen([]);
    let seData2 = {
      url: `/api/AllergenGroup/GetAllergenGroupData`,
      headers: headers,
    };
    let respo = await GetCallApi(seData2);
    if (respo?.status === 200) {
      if (respo.data.success) {
        const alList = respo.data.payload?.map((as) => {
          return {
            ...as,
          };
        });
        setAllergen(alList?.length > 0 ? alList : []);
      }
    }
  };

  const getSchoolList = async (id) => {
    setDistId(id);
    setSchoolList([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);
      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          // let uslist = [...dlist];
          let uslist = [{ value: "all", label: "Select All" }, ...dlist];

          setSchoolList(uslist?.length > 0 ? uslist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };

  const handleAddProduct = (val, eId) => {
    if (val) {
      setEmailId((old) => [...old, eId]);
    } else {
      setCheckbox(false);
      setEmailId((old) => old?.filter((pre) => pre !== eId));
    }
  };

  const handleCheckALL = (v, data) => {
    if (v) {
      setEmailId(data?.map((d) => d?.num));
      setCheckbox(true);
    } else {
      setEmailId([]);
      setCheckbox(false);
    }
  };

  const getCustList = async (data) => {
    setCheckbox(false);
    setPrdList([]);
    if (schoolSelect?.length === 0) {
      setDerror({ sch: "This field is required" });
    } else {
      setOpen(true);
      setDerror({ sch: "" });
      let seData = {
        url: `/api/CommunicationStudent/GetCommunicationStudentData`,
        body: {
          ...data,
          schoolDistrictId: distId ? distId : "",
          schoolId: schoolSelect ? schoolSelect?.map((s) => s?.value) : [],
          allergenGroupId: data?.allergenGroupId ? data?.allergenGroupId : null
        },
        headers: headers,
      };
      let res = await PostCallApi(seData);
      setOpen(false);
      if (res?.status === 200) {
        if (res?.data.success) {
          let d = res?.data?.payload?.map((x, i) => {
            return {
              ...x,
              num: i + 1,
              Action: (
                <label className="personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleAddProduct(e?.target?.checked, i + 1)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              ),
            };
          });
          setPrdList(d);
        }
      }
    }
  };

  const changeTbData = () => {
    let newData = prdList?.map((pd) => {
      return {
        ...pd,
        add: emailId?.filter((x) => x === pd?.num)?.length > 0 ? true : false,
        Action: (
          <label className=" personal_detail_label">
            <input
              type="checkbox"
              className="login_check"
              name="Remember Me"
              checked={
                emailId?.filter((x) => x === pd?.num)?.length > 0 ? true : false
              }
              onChange={(e) => handleAddProduct(e?.target?.checked, pd?.num)}
            />
            <span className="checkmark"></span>
          </label>
        ),
      };
    });
    setPrdList(newData);
    let ch = true;
    if (ch) {
      let as = newData?.map((x) => {
        if (!x?.add) {
          return (ch = false);
        }
        return undefined;
      });
      if (ch && newData?.length > 0) {
        setCheckbox(true);
      } else {
        setCheckbox(false);
      }
      ch = as; //for warning remove
    }
  };

  const handleMulti = (e) => {
    if (e) {
      setSchoolSelect(e);
    } else {
      setPrdList([]);
    }
  };

  const handleSendEmail = async (data) => {
    if (date?.length === 0) {
      setDerror2({ date: "This field is required" });
      setDerror({ id: "" })
    } else if (selectedFiles?.length === 0) {
      setDerror2({ date: "" });
      setDerror({ id: "" })
      setDerrorFile("")
      setImageError("This field is required")
    } else {
      setDerror2({ date: "" });
      setDerror({ id: "" })
      setImageError("")
      if (emailId?.length === 0) {
        setDerrorFile("")
        setDerror({ id: "Select minimum one student" });
      } else {
        setDerror({ id: "" });
        setOpenCircle(true);

        let filteredData = prdList?.filter((pr) => emailId?.includes(pr?.num));
        const formData = new FormData();
        selectedFiles.map((f) => (
          formData.append(`UploadFiles`, f?.file)
        ));
        filteredData.forEach((file) => {
          formData.append(`StudentIds`, file?.studentGuidId);
        });
        formData.append(`ExpiryDate`, date);
        formData.append(`CreatedBy`, LogDetail?.userId);

        let apiUrl = {
          url: `/api/StudentDocumentGallery/SaveStudentUploadDocument`,
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
          },
        };

        const resp = await PostCallApi(apiUrl);
        setOpenCircle(false);
        if (resp?.status === 200) {
          if (resp.data.success) {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: resp.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            setPrdList([]);
            setEmailId([]);
            setSchoolList([]);
            setSchoolSelect([]);
            setSelectedFiles([])
            setValue("distId", "");
            setValue("studentType", "");
          } else {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: resp?.data?.message
                ? resp?.data?.message
                : "Something went wrong please try again.",
              showConfirmButton: false,
              timer: 2000,
              color: "#ffffff",
              background: "red",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          }
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: resp?.data?.message
              ? resp?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      }
    }
  };

  const handleFile = (e) => {
    let is_succes = true;
    let total = fileSize ? fileSize : 0;
    let imar = [];
    let newImageUrls = [];
    const chooseFiles = Array.prototype.slice.call(e.target?.files);
    if (e.target.files?.length > 5) {
      setDerrorFile("Please Select Only 5 Document");
      setImageError("")
      return false;
    } else {
      chooseFiles?.filter((file) => {
        let url = file.name?.split(".")
        total = total + file.size;
        if (url[url?.length - 1] === "zip") {
          setDerrorFile(`You can not upload zip file`);
          is_succes = false
        }
        if (total > 50242880 && is_succes === true) {
          setDerrorFile(`Select less than 50mb Files`);
          is_succes = false;
        } else if (file.size > 50242880 && is_succes === true) {
          setDerrorFile(`Select less than 50mb Files`);
          is_succes = false;
        } else if (is_succes === true) {
          setDerrorFile("")
          imar.push(file);
          newImageUrls.push(file);
        }
        return 0;
      });
    }
    if (is_succes && imar?.length > 0) {
      setImageError("")
      newImageUrls = newImageUrls?.map((x, i) => {
        return {
          id: v4(),
          file: newImageUrls[i],
          imagePath: x,
        };
      });
      setFileSize(total)
      setSelectedFiles((old) => [...old, ...newImageUrls]);
      e.target.files = null;
    }
  }

  const handleDelete = (imgId) => {
    let minus = imgId?.file?.size
    setSelectedFiles(selectedFiles?.filter((d) => d?.id !== imgId?.id))
    setFileSize(fileSize - minus)
  }

  return (
    <>
      {/* <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openCircle}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="gallery_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(getCustList)}>
                  <div className="product_box">
                    <div className="all_title ProductTitle">
                      Document Gallery
                    </div>
                  </div>
                  <div className="row">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>
                          <img src={filterIcon} alt="filter" />
                          <span className="filter_by">Filter By</span>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                School District
                                <span className="red-color"> *</span>
                              </label>
                              <select
                                name=""
                                className="form-select"
                                {...register("distId", {
                                  required: "This field is required",
                                  onChange: (e) => {
                                    getSchoolList(e.target.value);
                                    setSchoolSelect([]);
                                  },
                                })}
                              >
                                <option value="">Select School District</option>
                                {parentList &&
                                  parentList?.map((data, i) => (
                                    <option key={i} value={data?.value}>
                                      {data?.label}
                                    </option>
                                  ))}
                              </select>
                              {errors?.distId && (
                                <span role="alert" className="error_text">
                                  {errors.distId.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                School <span className="red-color">*</span>
                              </label>
                              <Select
                                isMulti
                                name="type"
                                options={schoolList}
                                className="form-neselect"
                                classNamePrefix="select"
                                placeholder="Select School"
                                value={schoolSelect ? schoolSelect : null}
                                onChange={(selected) => {
                                  true &&
                                    selected.length &&
                                    selected.find(
                                      (option) => option.value === "all"
                                    )
                                    ? handleMulti(schoolList.slice(1))
                                    : !true
                                      ? handleMulti(
                                        (selected && selected.value) || null
                                      )
                                      : handleMulti(selected);
                                }}
                                style={{ width: "100%" }}
                              />
                              {derror?.sch && (
                                <span
                                  role="alert"
                                  className="d-block error_text"
                                >
                                  {derror?.sch}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Household Type{" "}
                                {/* <span className="red-color">*</span> */}
                              </label>
                              <select
                                name=""
                                className="form-select"
                                {...register("studentType", {
                                  // required: "This field is required",
                                })}
                              >
                                <option value="">Select HouseHold</option>
                                <option value="All">Both</option>
                                <option value="Primary">Primary</option>
                                <option value="Secondary">Secondary</option>
                              </select>
                              {errors?.studentType && (
                                <span role="alert" className="error_text">
                                  {errors.studentType.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Is Dc
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <select
                                name=""
                                className="form-select"
                                {...register("isDC", {
                                  // required: "This field is required",
                                })}
                              >
                                <option value="">Select Is Dc</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                              </select>
                              {errors.isDC && (
                                <span role="alert" className="error_text">
                                  {errors.isDC.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                show Negative Balance
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <select
                                name=""
                                className="form-select"
                                {...register("showNegativeBalance", {
                                  // required: "This field is required",
                                })}
                              >
                                <option value="">
                                  Select Negative Balance
                                </option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                              </select>
                              {errors.showNegativeBalance && (
                                <span role="alert" className="error_text">
                                  {errors.showNegativeBalance.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Allergen Group
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <select
                                name=""
                                className="form-select"
                                {...register("allergenGroupId", {
                                  // required: "This field is required",
                                })}
                              >
                                <option value="">Select Allergen Group</option>
                                {allergen &&
                                  allergen?.map((a, i) => {
                                    return (
                                      <option
                                        key={i}
                                        value={a?.allergenGroupId}
                                      >
                                        {a?.allergenGroup}
                                      </option>
                                    );
                                  })}
                              </select>
                              {errors.allergenGroupId && (
                                <span role="alert" className="error_text">
                                  {errors.allergenGroupId.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Status
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Status"
                                {...register("status", {
                                  // required: "This field is required",
                                })}
                              />
                              {errors.status && (
                                <span role="alert" className="error_text">
                                  {errors.status.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Grades
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Grades"
                                {...register("grades", {
                                  // required: "This field is required",
                                })}
                              />
                              {errors.grades && (
                                <span role="alert" className="error_text">
                                  {errors.grades.message}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>
                                Home Room
                                {/* <span className="red-color"> *</span> */}
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Home Room"
                                {...register("homeRoom", {
                                  // required: "This field is required",
                                })}
                              />
                              {errors.homeRoom && (
                                <span role="alert" className="error_text">
                                  {errors.homeRoom.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                      <div className="ms-auto mt-auto col-md-4">
                        <div className="form-group text-end filter-img">
                          {open ? (
                            <Button className="theme_btn text-end me-3">
                              Applying Filter{" "}
                              <CircularProgress
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            </Button>
                          ) : (
                            <Button
                              className="theme_btn text-end me-3"
                              type="submit"
                            >
                              Apply
                            </Button>
                          )}
                        </div>
                      </div>
                    </Accordion>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>
                          Expiry Date <span className="red-color">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => setDate(e?.target?.value)}
                        // {...register("date", {
                        //     required: "This field is required",
                        //     valueAsDate: true,
                        // })}
                        />
                        {derror2?.date && (
                          <span role="alert" className="d-block error_text">
                            {derror2?.date}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group ">
                        <label className="form-label">Upload Document
                          <span className="red-color"> *</span>
                        </label>
                        <div className="upload_img">
                          <input
                            type="text"
                            className="form-control upload_box"
                            placeholder="No file Chosen"
                            readOnly
                            {...register("documents", {})}
                          />
                          <input
                            type="file"
                            id="school_img_upload_1"
                            multiple
                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*, video/*"
                            // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className="form-control"
                            style={{ display: "none" }}
                            onChange={handleFile}
                          />
                          <label
                            htmlFor="school_img_upload_1"
                            className="upload_img_label mb-0"
                          >
                            <img src={uploadimg} className="img-fluid" alt="" />
                          </label>
                        </div>
                        {imageError && (
                          <span role="alert" className="error_text">
                            {imageError}
                          </span>
                        )}
                        {derrorFile && (
                          <span role="alert" className="error_text">
                            {derrorFile}
                          </span>
                        )}
                        {/* {selectedFiles &&
                          selectedFiles?.map((file, i) => (
                            <div className="d-flex">
                            <img src={file?.name} alt="" />
                            </div>
                          ))} */}
                      </div>
                    </div>
                    <div className="ms-auto col-md-4" style={{ marginTop: "40px" }}>
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Uploading
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <>
                            <Button
                              className="theme_btn text-end"
                              onClick={handleSendEmail}
                            >
                              Upload
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="text-end">
                      {derror?.id && (
                        <span role="alert" className="error_text">
                          {derror?.id}
                        </span>
                      )}
                    </div>
                    <div className="col-md-12">
                      {selectedFiles?.length > 0 ?
                        <div className="row">
                          {selectedFiles?.filter((mat, no) => {
                            let url = mat.file.name?.split(".")
                            if (url[url?.length - 1] === "doc" ||
                              url[url?.length - 1] === "docx" ||
                              url[url?.length - 1] === "pptx" ||
                              url[url?.length - 1] === "xls" ||
                              url[url?.length - 1] === "xlsx") {
                              return mat
                            }
                            return null;
                          })?.length > 0 ?
                            <>
                              <div className="title">Doc/Xls :</div>
                              {selectedFiles?.filter((mat, no) => {
                                let url = mat.file.name?.split(".")
                                if (url[url?.length - 1] === "doc" ||
                                  url[url?.length - 1] === "docx" ||
                                  url[url?.length - 1] === "pptx" ||
                                  url[url?.length - 1] === "xls" ||
                                  url[url?.length - 1] === "xlsx") {
                                  return mat
                                }
                                return null;
                              })?.filter((y) => y !== null)?.map((mat, no) => (
                                <div className="col-md-3" key={no}>
                                  <div className="mat_box_main mb-3">
                                    <div className="num me-3">{no + 1}</div>
                                    <div className="text_data">
                                      {mat?.file?.name ? mat?.file?.name : ""}
                                    </div>
                                    <button
                                      className="btn_dlt "
                                      type="button"
                                      onClick={() => handleDelete(mat)}
                                    >
                                      <VscTrash className="icon" />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                            : ""
                          }
                          {selectedFiles?.filter((mat, no) => {
                            let url = mat.file.name?.split(".")
                            if (url[url?.length - 1] === "mp4") {
                              return mat
                            }
                            return null;
                          })?.length > 0 ?
                            <>
                              <div className="title">Video :</div>
                              {selectedFiles?.filter((mat, no) => {
                                let url = mat.file.name?.split(".")
                                if (url[url?.length - 1] === "mp4") {
                                  return mat
                                }
                                return null;
                              })?.filter((y) => y !== null)?.map((mat, no) => (
                                <div className="col-md-3" key={no}>
                                  <div className="mat_box_main mb-3">
                                    <div className="num me-3">{no + 1}</div>
                                    <div className="text_data">
                                      {mat?.file?.name ? mat?.file?.name : ""}
                                    </div>
                                    <button
                                      className="btn_dlt "
                                      type="button"
                                      onClick={() => handleDelete(mat)}
                                    >
                                      <VscTrash className="icon" />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                            : ""
                          }
                          {selectedFiles?.filter((mat, no) => {
                            let url = mat.file.name?.split(".")
                            if (url[url?.length - 1] === "jpg" ||
                              url[url?.length - 1] === "jpeg" ||
                              url[url?.length - 1] === "svg" ||
                              url[url?.length - 1] === "webp" ||
                              url[url?.length - 1] === "png") {
                              return mat
                            }
                            return null;
                          })?.length > 0 ?
                            <>
                              <div className="title">Image :</div>
                              {selectedFiles?.filter((mat, no) => {
                                let url = mat.file.name?.split(".")
                                if (url[url?.length - 1] === "jpg" ||
                                  url[url?.length - 1] === "jpeg" ||
                                  url[url?.length - 1] === "svg" ||
                                  url[url?.length - 1] === "webp" ||
                                  url[url?.length - 1] === "png") {
                                  return mat
                                }
                                return null;
                              })?.filter((y) => y !== null)?.map((mat, no) => (
                                <div className="col-md-3" key={no}>
                                  <div className="mat_box_main mb-3">
                                    <div className="num me-3">{no + 1}</div>
                                    <div className="text_data">
                                      {mat?.file?.name ? mat?.file?.name : ""}
                                    </div>
                                    <button
                                      className="btn_dlt "
                                      type="button"
                                      onClick={() => handleDelete(mat)}
                                    >
                                      <VscTrash className="icon" />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                            : ""
                          }
                          {selectedFiles?.filter((mat, no) => {
                            let url = mat.file.name?.split(".")
                            if (url[url?.length - 1] === "pdf") {
                              return mat
                            }
                            return null;
                          })?.length > 0 ?
                            <>
                              <div className="title">Pdf :</div>
                              {selectedFiles?.filter((mat, no) => {
                                let url = mat.file.name?.split(".")
                                if (url[url?.length - 1] === "pdf") {
                                  return mat
                                }
                                return null;
                              })?.filter((y) => y !== null)?.map((mat, no) => (
                                <div className="col-md-3" key={no}>
                                  <div className="mat_box_main mb-3">
                                    <div className="num me-3">{no + 1}</div>
                                    <div className="text_data">
                                      {mat?.file?.name ? mat?.file?.name : ""}
                                    </div>
                                    <button
                                      className="btn_dlt "
                                      type="button"
                                      onClick={() => handleDelete(mat)}
                                    >
                                      <VscTrash className="icon" />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </>
                            : ""
                          }
                        </div>
                        : ""}
                    </div>
                  </div>
                  <EmailTable
                    headCells={templateHead}
                    action={true}
                    actionFirst={true}
                    ListData={prdList}
                    setCheckbox={setCheckbox}
                    checkBox={checkBox}
                    handleCheckALL={handleCheckALL}
                  />
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default UploadDocuments;
