import React, { Suspense, useEffect, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import Swal from "sweetalert2";

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

function CreateCustomers() {
    const { id } = useParams();
    const [open, setOpen] = useState(false);

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (id) {
            getEditedData(id)
        }
        // eslint-disable-next-line
    }, [])

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getEditedData = async (id) => {
        setOpen(true);

        let sendData = {
            url: ``,
            headers: headers
        }

        let res = await GetCallApi(sendData)
        setOpen(false);
        if (res?.status === 200) {
            if (res.data.success) {
                let data = res?.data?.payload
                setValue("serviceProvider", data?.serviceProvider)
                setValue("server", data?.server)
                setValue("smtpPort", data?.smtpPort)
                setValue("Username", data?.Username)
                setValue("Password", data?.Password)
                setValue("apiKey", data?.apiKey)
                setValue("clientId", data?.clientId)

            }
        }
    }

    const onSubmit = async (data) => {
        let sendData = {
            ...data,
            createdBy: LogDetail?.userId,
        };
        let seData = {
            url: `/api/SmtpConfiguration/CreateSmtp`,
            body: sendData,
            headers: headers
        }

        let respo = await PostCallApi(seData)
        if (respo?.status === 200) {
            if (respo.data.success) {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="create_customer">
                    <div className="main_wrapper">
                        <div className="ProductCategories_hed">
                            <div className="all_title ProductTitle">Create Customers</div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    School <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("school", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Search School</option>
                                                    <option value="">Search School</option>
                                                </select>
                                                {errors.school && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.school.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Student Number <span className="red-color">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Student Number"
                                                    {...register("studentNumber", {
                                                        required: "This field is required",
                                                    })}
                                                />
                                                {errors.studentNumber && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.studentNumber.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    First Name<span className="red-color">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name"
                                                    {...register("firstName", {
                                                        required: "This field is required",
                                                    })}
                                                />
                                                {errors.firstName && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.firstName.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Last Name <span className="red-color">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name"
                                                    {...register("lastName", {
                                                        required: "This field is required",
                                                    })}
                                                />
                                                {errors.lastName && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.lastName.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Email Criteria <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("emailCriteria", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="all">All</option>
                                                    <option value="has emails">Has Emails</option>
                                                    <option value="no emails">No Emails</option>
                                                </select>
                                                {errors.emailCriteria && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.emailCriteria.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>
                                                    Order by <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("Orderby", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="low balance">Low Balance</option>
                                                    <option value="high balance">High Balance</option>
                                                </select>
                                                {errors.Orderby && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.Orderby.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-end mt-3">
                                            <Button type="submit" className="theme_btn me-2">
                                                Submit
                                            </Button>
                                            <Button
                                                className="cancel_btn"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default CreateCustomers;
