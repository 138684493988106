import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Axios from "../../../../../../../Utils/AxiosConfig";
import uploadimg from "../../../../../../../Assets/SvgImage/file-upload.svg";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { Backdrop, CircularProgress, Button } from "@mui/material";

function CreateParent(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const [openCircle, setOpenCircle] = useState(false);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    setOpenCircle(true);

    Axios.post("/api/Parents/CreateParents", sendData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            props.setParentId(res.data.payload.parentsGuidId);
            props.setValues("2");
            // reset();
            // navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (id) {
      EditParentTab(id);
    }
    // eslint-disable-next-line
  }, [id]);
  const EditParentTab = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/Parents/GetParentsDataByID?parentGuidId=${id}&userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let edData = res.data.payload;
            setValue("parentsGuidId", edData?.parentsGuidId);
            setValue("firstName", edData?.firstName);
            setValue("lastName", edData?.lastName);
            setValue("primaryPhoneNumber", edData?.primaryPhoneNumber);
            setValue("workPhoneNumber", edData?.workPhoneNumber);
            setValue("password", edData?.password);
            setValue("email", edData?.email);
            setValue("address", edData?.address);
            setValue("parentsType", edData?.parentsType);
            setValue("address", edData?.address);
            setValue("FamilyCode", edData?.familyCode);
            setValue("FamilyCodeImage", edData?.familyCodeImage);
            setValue("coverUpload1", edData?.familyCodeImage);
            // setValue("apt", editprimaryData?.apt);
            // setValue("city", editprimaryData?.city);
            // setValue("state", editprimaryData?.state);
            // setValue("zip", editprimaryData?.zip);
            // setValue("preferred", editprimaryData?.preferred);
            // setValue("pname", editprimaryData?.pname);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setValue("coverUpload1", e.target.files[0].name);
      setValue("FamilyCodeImage", e.target.files[0]);
      e.target.value = null;
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <section className="createprimarytab-page">
        {/* <div className="main_wrapper"> */}
        {/* <h4 className="all_title mb-4">Parent Information</h4> */}
        <div className="card">
          <div className="card-body">
            <form
              action=""
              onSubmit={handleSubmit(onSubmit)}
              className="primary-box"
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      {...register("firstName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.firstName && (
                      <span role="alert" className="error_text">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      {...register("lastName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.lastName && (
                      <span role="alert" className="error_text">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Primary Phone Number <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Primary Phone Number"
                      {...register("primaryPhoneNumber", {
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          message: "Enter valid phone number",
                        },
                      })}
                    />
                    {errors.primaryPhoneNumber && (
                      <span role="alert" className="error_text">
                        {errors.primaryPhoneNumber.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Work Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Work Phone Number"
                      {...register("workPhoneNumber", {
                        // required: "This field is required",
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          message: "Enter valid phone number",
                        },
                      })}
                    />
                    {errors.workPhoneNumber && (
                      <span role="alert" className="error_text">
                        {errors.workPhoneNumber.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Email <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      {...register("email", {
                        required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                    />
                    {errors.email && (
                      <span role="alert" className="error_text">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Password <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      {...register("password", {
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Must be 8 characters",
                        },
                        maxLength: {
                          value: 15,
                          message: "Max 15 characters",
                        },
                      })}
                    />
                    {errors.password && (
                      <span role="alert" className="error_text">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Address <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      {...register("address", {
                        required: "This field is required",
                      })}
                    />
                    {errors.address && (
                      <span role="alert" className="error_text">
                        {errors.address.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Parent Type <span className="red-color">*</span>{" "}
                    </label>
                    <div className="radio_button">
                      <input
                        type="radio"
                        id="prm"
                        {...register("parentsType", {
                          required: "This field is required",
                        })}
                        value="Primary"
                        defaultChecked
                      />{" "}
                      <label htmlFor="prm" className="me-3">
                        Primary
                      </label>
                      <input
                        type="radio"
                        id="scd"
                        // className="form-control"
                        {...register("parentsType", {
                          required: "This field is required",
                        })}
                        value="Secondary"
                      />
                      <label htmlFor="scd">Secondary</label>
                    </div>
                    {errors.address && (
                      <span role="alert" className="error_text">
                        {errors.address.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Family Code <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Family Code"
                      {...register("FamilyCode", {
                        required: "This field is required",
                      })}
                    />
                    {errors.FamilyCode && (
                      <span role="alert" className="error_text">
                        {errors.FamilyCode.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Family Code Image/PDF <span className="red-color">*</span>{" "}
                    </label>
                    <div className="upload_img">
                      <input
                        type="text"
                        className="form-control upload_box"
                        placeholder="No file Chosen"
                        readOnly
                        {...register("coverUpload1", {
                          required: "This field is required",
                        })}
                      />
                      <input
                        type="file"
                        id="school_img_upload_2"
                        accept="image/*"
                        className="form-control"
                        style={{ display: "none" }}
                        onChange={handleFile}
                      />
                      <label
                        htmlFor="school_img_upload_2"
                        className="upload_img_label mb-0 "
                      >
                        <img src={uploadimg} className="img-fluid" alt="" />
                      </label>
                    </div>
                    {errors.coverUpload1 && (
                      <span role="alert" className="error_text">
                        {errors.coverUpload1.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  <Button type="submit" className="theme_btn me-2">
                    Next
                  </Button>
                  <Button className="cancel_btn" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* </div> */}
      </section>
    </>
  );
}

export default CreateParent;
