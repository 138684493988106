import React, { Suspense, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Config } from "../../../../Utils/Config";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { GetCallApi } from './../../../../Action/Action';
import ChangeLanguage from "../../../../locales/ChangeLang";
import { useTranslation } from "react-i18next";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ShowPreviousSurveyData() {
  const { id, dId } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [studId, setStudId] = useState();
  const [qiId, setQiId] = useState("");
  const [payDetail, setPayDetail] = useState(false);
  const [distLogo, setDistLogo] = useState([]);

  const { t } = useTranslation();

  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const { fields } = useFieldArray({
    control,
    name: "StudentList",
  });

  const { fields: Field1 } = useFieldArray({
    control,
    name: "houseHoldList",
  });

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (!payDetail) {
      getStateList();
      getStudentList(id);
    }
    // eslint-disable-next-line
  }, [payDetail]);

  useEffect(() => {
    getDistrictLogo(dId);
    // eslint-disable-next-line
  }, [dId]);

  const getDistrictLogo = async (distId) => {
    if (distId) {
      let seData = {
        url: `/api/SchoolDistrict/GetDistrictLogoById?schoolDistrictId=${distId}`,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload
          setDistLogo(dlist?.length > 0 ? dlist : []);
        }
      }
    }
  };

  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setStateList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getStudentList = (id) => {
    if (id) {
      setOpenCircle(true);
      Axios.get(
        `/api/Survey/GetHouseHoldSurveryByHouseHoldId?houseHoldId=${id}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            setOpenCircle(false);
            if (res.data.success) {
              let srData = res.data.payload.hhs;
              setValue(
                "benefitsReceived",
                srData?.benefitsReceived
                  ? srData?.benefitsReceived === "F"
                    ? "FDPIR"
                    : srData?.benefitsReceived === "S"
                      ? "SNAP"
                      : srData?.benefitsReceived === "T"
                        ? "TANF"
                        : srData?.benefitsReceived === "N"
                          ? "NA"
                          : srData?.benefitsReceived
                  : ""
              );
              setValue("caseName", srData?.caseName);
              setValue("caseNumber", srData?.caseNumber);
              setValue("firstName", srData?.firstName);
              setValue("fullName", srData?.fullName);
              setValue("address", srData?.address);
              setValue("apartment", srData?.apartment);
              setValue("phone", srData?.phone);
              setValue("city", srData?.city);
              setValue("state", srData?.state);
              setValue("signatureName", srData?.signature);
              setValue("actualIncome", srData?.actualIncome);
              setValue("paymentCycle", srData?.paymentCycle);
              setValue("ssnNumber", srData?.ssnnumber);
              setValue("isSSN", srData?.isSsn);
              setValue("NoOfPeoeple", srData?.noOfPeoeple);
              setValue("isOtherStateEbtbenifit", srData?.isOtherStateEbtbenifit);
              setValue("isSummerEBT", srData?.isSummerEbt);
              setValue("sign", srData?.signature?.length > 0 ? true : false);
              setValue(
                "emailConfirmationAddress",
                srData?.emailConfirmationAddress
              );
              setValue("totalActualIncome", srData?.totalActualIncome);
              // if (srData?.benefitsReceived === "NA") {
              //   let so = IncomeChart?.map((val, i) => {
              //     if (
              //       parseInt(val?.annul?.split(",").join("")) ===
              //       srData?.totalHousholdAnuualIncome
              //     ) {
              //       return { ...val, seo: `option${i + 1}` };
              //     }
              //     return val;
              //   });
              //   so = so?.filter((x) => x.seo)[0]?.seo;

              //   setSelectedOption(so);
              // }
              const dlist = res.data.payload.studentList;
              // getDistrictLogo(srData?.hhs?.schoolDistrictId ? srData?.hhs?.schoolDistrictId : dlist?.find((x) => x?.isStudent === true)?.schoolDistrictId)
              if (dlist === undefined || dlist?.length === 0) {
                const data = [
                  {
                    birthDate: "",
                    firstName: "",
                    lastName: "",
                    isStudent: true,
                    studentGuidId: null,
                    grade: "",
                    isFosterChild: false,
                    // schoolId: "",
                    // schoolDistrictId: "",
                    actualIncome: 0,
                    paymentCycle: "",
                    studentType: "",
                    country: "USA",
                  },
                ];
                setValue("StudentList", data);
                setValue("houseHoldList", [{ ...data[0], isStudent: false }]);
              }
              let nr = dlist?.map((std, index) => {
                return {
                  birthDate: std?.birthDate?.split("T")[0],
                  // firstName: std?.studentName?.split(" ")[0],
                  // lastName: std?.studentName?.split(" ")[1],
                  firstName: std?.firstName,
                  lastName: std?.lastName,
                  isStudent: std?.isStudent,
                  studentGuidId: std?.studentGuidId ? std?.studentGuidId : null,
                  fosterChild: std?.fosterChild ? std?.fosterChild : "",
                  grade: std?.grade,
                  // schoolId: std?.schoolId,
                  // schoolDistrictId: std?.schoolDistrictId,
                  schoolName: std?.schoolName,
                  // schoolDistrictName: std?.schoolDistrictName,
                  isFosterChild: std?.isFosterChild ? std?.isFosterChild : false,
                  actualIncome: std?.actualIncome ? std?.actualIncome : 0,
                  paymentCycle: std?.paymentCycle,
                  studentType: "",
                  studentId: std?.studentId,
                  country: "USA",
                  removable: false,
                };
              });
              if (nr?.length > 0 || nr !== undefined) {
                nr = [...nr];
                setValue("StudentList", nr?.filter((x) => x?.isStudent === true));
                setValue("houseHoldList", nr?.filter((x) => x?.isStudent === false));
                handleChoise("val", 0);
              }
            } else {
              const data = [
                {
                  birthDate: "",
                  firstName: "",
                  lastName: "",
                  isStudent: true,
                  studentGuidId: null,
                  grade: "",
                  // schoolId: "",
                  // schoolDistrictId: "",
                  isFosterChild: false,
                  actualIncome: 0,
                  paymentCycle: "",
                  studentType: "",
                  country: "USA",
                  removable: true,
                },
              ];
              setValue("StudentList", data);
            }
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };

  const handleChoise = (id, index) => {
    setStudId(id);
    setQiId(index);
    if (id === studId) {
      setStudId(studId);
    }
    if (index === qiId) {
      setQiId(qiId);
    }
  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {payDetail ? (
          <section className="payment_success">
            <div className="main_wrapper">
              <div className="payment_inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="success_message_box">
                      <BsFillPatchCheckFill className="icon" />
                      <div>
                        <h1 className="m-0">Form Submitted Successful</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row paymentAndUserInfo mt-4">
                  <div className="col-md-12 text-center">
                    <Button
                      className="theme_btn mt-3"
                      onClick={() => setPayDetail(false)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="show_pre_data">
            <div className="card">
              <div className="card-body">
                <div className="hed">
                  <div className="d-flex">
                    {distLogo ?
                      <img
                        src={`${Config.API_HOST_URL_live}/${distLogo[0]}`}
                        className="header_logo img-fluid"
                        alt=""
                      />
                      : <div></div>}
                    <h2 className="mx-auto">
                      {t('SurveyTitle')}
                    </h2>
                    <ChangeLanguage />
                  </div>
                  <div className="info">
                    <h5>
                      {t('PartATitle')}
                    </h5>
                    <p>
                      {t('PartADesc')}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="houseHoldForm">
                  <form action="" className="primary-box">
                    <div className="partA">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>{t('TableHead3')}</th>
                              <th>{t('TableHead4')}</th>
                              <th>{t('TableHead5')}</th>
                              {/* <th>{t('TableHead1')}</th> */}
                              <th>{t('TableHead2')}</th>
                              {getValues(`StudentList`)?.filter(
                                (x) => x.isStudent === true
                              )?.length > 0 ? (
                                <>
                                  <th>{t('TableHead6')}</th>
                                  {/* <th>SchoolDistrict</th> */}
                                  <th>{t('TableHead10')}</th>
                                  <th>{t('TableHead7')} </th>
                                  {/* <th>Type</th> */}
                                </>
                              ) : (
                                ""
                              )}
                              <th>{t('TableHead8')}</th>
                              <th>{t('TableHead9')}</th>
                              {/* <th></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {fields
                              ?.filter((x) => x?.isStudent === true)
                              ?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="date"
                                      className="form-control"
                                      style={{
                                        minWidth: "135px",
                                        maxWidth: "135px",
                                      }}
                                      disabled={true}
                                      {...register(
                                        `StudentList.${index}.birthDate`,
                                        {
                                          required: "This field is required",
                                          valueAsDate: true,
                                        }
                                      )}
                                    />
                                    {errors.StudentList?.[index]?.birthDate && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.birthDate
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t('placeholderFirstName')}
                                      disabled={true}
                                      {...register(
                                        `StudentList.${index}.firstName`,
                                        {
                                          required: "This field is required",
                                        }
                                      )}
                                    />
                                    {errors.StudentList?.[index]?.firstName && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.firstName
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t('placeholderLastName')}
                                      readOnly
                                      disabled={true}
                                      {...register(
                                        `StudentList.${index}.lastName`,
                                        {
                                          required: "This field is required",
                                        }
                                      )}
                                    />
                                    {errors.StudentList?.[index]?.lastName && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.lastName
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  {/* <td>
                                    <label
                                      className=" personal_detail_label"
                                      style={{ cursor: "default" }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="login_check"
                                        name="Remember Me"
                                        readOnly
                                        disabled={true}
                                        {...register(
                                          `StudentList.${index}.isStudent`
                                        )}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                    {errors.StudentList?.[index]?.isStudent && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.isStudent
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td> */}
                                  <td>
                                    {/* <label className=" personal_detail_label"
                                      style={{ cursor: "default" }}
                                    >
                                      <input
                                        type="checkbox"
                                        className="login_check"
                                        readOnly
                                        disabled={true}
                                        name="Remember Me"
                                        {...register(
                                          `StudentList.${index}.isFosterChild`,
                                        )}
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                    {errors.StudentList?.[index]?.isFosterChild && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.isFosterChild
                                            .message
                                        }
                                      </span>
                                    )} */}
                                    <select
                                      disabled={true}
                                      name=""
                                      className="form-select"
                                      {...register(
                                        `StudentList.${index}.fosterChild`,
                                        {
                                          required:
                                            t("RequiredValidation"),
                                        }
                                      )}
                                    >
                                      <option value="">
                                        {t("TypeDrop")}
                                      </option>
                                      <option value="FosterChild">
                                        {t("TypeDropVal1")}
                                      </option>
                                      <option value="MigrantWorker">
                                        {t("TypeDropVal2")}
                                      </option>
                                      <option value="Runway">
                                        {t("TypeDropVal3")}
                                      </option>
                                      <option value="HomeLess">
                                        {t("TypeDropVal4")}
                                      </option>
                                    </select>
                                    {errors.StudentList?.[index]
                                      ?.fosterChild && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {
                                            errors.StudentList?.[index]
                                              ?.fosterChild.message
                                          }
                                        </span>
                                      )}
                                  </td>
                                  {getValues(`StudentList`)?.filter(
                                    (x) => x.isStudent === true
                                  )?.length > 0 ? (
                                    getValues(
                                      `StudentList.${index}.isStudent`
                                    ) ? (
                                      <>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('placeholderStudentId')}
                                            disabled={true}
                                            style={{
                                              minWidth: "90px",
                                              maxWidth: "90px",
                                            }}
                                            readOnly
                                            {...register(
                                              `StudentList.${index}.studentId`,
                                              {
                                                // required: "This field is required",
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]
                                            ?.studentId && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.StudentList?.[index]
                                                    ?.studentId.message
                                                }
                                              </span>
                                            )}
                                        </td>
                                        {/* <td>
                                      {item?.removable ? (
                                        ""
                                      ) : (
                                        <>
                                          {" "}
                                          <input
                                            type="text"
                                            className="form-control"
                                            readOnly
                                            disabled={true}
                                            placeholder="Enter School District Name "
                                            {...register(
                                              `StudentList.${index}.schoolDistrictName`,
                                              {
                                                // required: "This field is required",
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]
                                            ?.schoolDistrictName && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.StudentList?.[index]
                                                    ?.schoolDistrictName.message
                                                }
                                              </span>
                                            )}
                                        </>
                                      )}
                                    </td>*/}
                                        <td>
                                          {item?.removable ? (
                                            ""
                                          ) : (
                                            <>
                                              <input
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                                placeholder={t("DefaultSchoolName")}
                                                {...register(
                                                  `StudentList.${index}.schoolName`,
                                                  {
                                                    // required: "This field is required",
                                                  }
                                                )}
                                              />
                                              {errors.StudentList?.[index]
                                                ?.schoolName && (
                                                  <span
                                                    role="alert"
                                                    className="error_text"
                                                  >
                                                    {
                                                      errors.StudentList?.[index]
                                                        ?.schoolName.message
                                                    }
                                                  </span>
                                                )}
                                            </>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t('placeholderGrade')}
                                            readOnly
                                            disabled={true}
                                            style={{
                                              minWidth: "50px",
                                              maxWidth: "50px",
                                            }}
                                            {...register(
                                              `StudentList.${index}.grade`,
                                              {
                                                // required: "This field is required",
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]?.grade && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.StudentList?.[index]?.grade
                                                  .message
                                              }
                                            </span>
                                          )}
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td></td>
                                        {/* <td></td>*/}
                                        <td></td>
                                        <td></td>
                                      </>
                                    )
                                  ) : (
                                    ""
                                  )}
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      disabled={true}
                                      placeholder={t('placeholderActualIncome')}
                                      {...register(
                                        `StudentList.${index}.actualIncome`,
                                        {
                                          valueAsNumber: true,
                                          // required:
                                          //   "This field is required",
                                          pattern: {
                                            value: /^\d+(\.\d{1,2})?$/,
                                            message:
                                              "Enter valid Actual Income",
                                          },
                                        }
                                      )}
                                    />
                                    {errors.StudentList?.[index]
                                      ?.actualIncome && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {
                                            errors.StudentList?.[index]
                                              ?.actualIncome.message
                                          }
                                        </span>
                                      )}
                                  </td>
                                  <td>
                                    <select
                                      name=""
                                      className="form-select"
                                      disabled={true}
                                      {...register(
                                        `StudentList.${index}.paymentCycle`,
                                        // {
                                        //   required:
                                        //     "This field is required",
                                        // }
                                      )}
                                    >
                                      <option value="">
                                        {t('PayCycleDrop')}
                                      </option>
                                      <option value="Annual">
                                        {t('PayCycleDropVal1')}
                                      </option>
                                      <option value="Monthly">
                                        {t('PayCycleDropVal2')}
                                      </option>
                                      <option value="TwicePerMonth">
                                        {t('PayCycleDropVal3')}
                                      </option>
                                      <option value="EveryTwoWeeks">
                                        {t('PayCycleDropVal4')}
                                      </option>
                                      <option value="Weekly">
                                        {t('PayCycleDropVal5')}
                                      </option>
                                    </select>
                                    {errors.StudentList?.[index]
                                      ?.paymentCycle && (
                                        <span
                                          role="alert"
                                          className="error_text"
                                        >
                                          {
                                            errors.StudentList?.[index]
                                              ?.paymentCycle.message
                                          }
                                        </span>
                                      )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="partB">
                      <h5>{t('PartBTitle')}</h5>
                      <ul>
                        <li>
                          {t('PartBDesc1')}
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            className="flex-row"
                            disabled={true}
                            value={
                              getValues("benefitsReceived")
                                ? getValues("benefitsReceived")
                                : ""
                            }
                          >
                            <FormControlLabel
                              className="radioBtn"
                              value="FDPIR"
                              label={t('RadioName1')}
                              disabled={true}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              className="radioBtn"
                              value="SNAP"
                              label={t('RadioName2')}
                              disabled={true}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              className="radioBtn"
                              value="TANF"
                              label={t('RadioName3')}
                              disabled={true}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              className="radioBtn"
                              value="NA"
                              label={t('RadioName4')}
                              disabled={true}
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </li>
                        <li>
                          {t('PartBDesc2')}{" "}
                          <b>{t('SkipPart')}</b>
                        </li>
                      </ul>
                      {getValues("benefitsReceived") === "TANF" ||
                        getValues("benefitsReceived") === "FDPIR" ||
                        getValues("benefitsReceived") === "SNAP" ? (
                        <div className="row form mb-3">
                          <div className="col-md-4 input_div">
                            <label>{t('RadioSelectInput1')}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t('RadioSelectPlaceholder1')}
                              disabled={true}
                              {...register(`caseName`, {
                                required: "This field is required",
                              })}
                            />
                            {errors.caseName && (
                              <span role="alert" className="error_text">
                                {errors.caseName.message}
                              </span>
                            )}
                          </div>{" "}
                          <div className="col-md-4 input_div">
                            <label>{t('RadioSelectInput2')}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t('RadioSelectPlaceholder2')}
                              disabled={true}
                              {...register(`caseNumber`, {
                                required: "This field is required",
                                minLength: {
                                  value: 10,
                                  message:
                                    "You have to enter minimum 10 number",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "You can enter max 10 number",
                                },
                              })}
                            />
                            {errors.caseNumber && (
                              <span role="alert" className="error_text">
                                {errors.caseNumber.message}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="partC">
                      <h5>
                        {t('PartCTitle')}
                      </h5>
                      <p>
                        {t('PartCDesc1')}
                      </p>
                      <p>
                        {t('PartCDesc2')}
                      </p>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>{t("TableHead3")}</th>
                              <th>{t("TableHead4")}</th>
                              <th>{t("TableHead5")}</th>
                              <th>{t("TableHead11")}</th>
                              <th>{t("TableHead8")}</th>
                              <th>{t("TableHead9")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Field1?.filter(
                              (x) => x?.isStudent === false
                            )?.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  <input
                                    disabled={true}
                                    type="date"
                                    className="form-control"
                                    style={{
                                      minWidth: "135px",
                                      maxWidth: "135px",
                                    }}
                                    {...register(
                                      `houseHoldList.${index}.birthDate`,
                                      {
                                        required:
                                          t("RequiredValidation"),
                                        valueAsDate: true,
                                      }
                                    )}
                                  />
                                  {errors.houseHoldList?.[index]
                                    ?.birthDate && (
                                      <span
                                        role="alert"
                                        className="error_text"
                                      >
                                        {
                                          errors.houseHoldList?.[index]
                                            ?.birthDate.message
                                        }
                                      </span>
                                    )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    placeholder={t(
                                      "placeholderFirstName"
                                    )}
                                    {...register(
                                      `houseHoldList.${index}.firstName`,
                                      {
                                        required:
                                          t("RequiredValidation"),
                                      }
                                    )}
                                  />
                                  {errors.houseHoldList?.[index]
                                    ?.firstName && (
                                      <span
                                        role="alert"
                                        className="error_text"
                                      >
                                        {
                                          errors.houseHoldList?.[index]
                                            ?.firstName.message
                                        }
                                      </span>
                                    )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    placeholder={t(
                                      "placeholderLastName"
                                    )}
                                    {...register(
                                      `houseHoldList.${index}.lastName`,
                                      {
                                        required:
                                          t("RequiredValidation"),
                                      }
                                    )}
                                  />
                                  {errors.houseHoldList?.[index]
                                    ?.lastName && (
                                      <span
                                        role="alert"
                                        className="error_text"
                                      >
                                        {
                                          errors.houseHoldList?.[index]
                                            ?.lastName.message
                                        }
                                      </span>
                                    )}
                                </td>

                                <td>
                                  <select
                                    name=""
                                    disabled={true}
                                    className="form-select"
                                    {...register(
                                      `houseHoldList.${index}.fosterChild`,
                                      {
                                        required:
                                          t("RequiredValidation"),
                                      }
                                    )}
                                  >
                                    <option value="">
                                      {t("SeIn")}
                                    </option>
                                    <option value="Work">
                                      {t("Work")}
                                    </option>
                                    <option value="Public Assistance">
                                      {t("PublicAssistance")}
                                    </option>
                                    <option value="Child Support">
                                      {t("ChildSupport")}
                                    </option>
                                    <option value="Alimony">
                                      {t("Alimony")}
                                    </option>
                                    <option value="Pension">
                                      {t("Pension")}
                                    </option>
                                    <option value="Retirment">
                                      {t("Retirment")}
                                    </option>
                                    <option value="Social Security">
                                      {t("SocialSecurity")}
                                    </option>
                                    <option value="SSI">
                                      {t("SSI")}
                                    </option>
                                    <option value="VA Benefits">
                                      {t("VABenefits")}
                                    </option>{" "}
                                    <option value="Other">
                                      {t("Other")}
                                    </option>
                                  </select>

                                  {errors.houseHoldList?.[index]
                                    ?.fosterChild && (
                                      <span
                                        role="alert"
                                        className="error_text"
                                      >
                                        {
                                          errors.houseHoldList?.[index]
                                            ?.fosterChild.message
                                        }
                                      </span>
                                    )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    disabled={true}
                                    className="form-control"
                                    placeholder={t(
                                      "placeholderActualIncome"
                                    )}
                                    {...register(
                                      `houseHoldList.${index}.actualIncome`,
                                      {
                                        valueAsNumber: true,
                                        pattern: {
                                          value: /^\d+(\.\d{1,2})?$/,
                                          message:
                                            t("EnterActualIncome"),
                                        },
                                      }
                                    )}
                                  />
                                  {errors.houseHoldList?.[index]
                                    ?.actualIncome && (
                                      <span
                                        role="alert"
                                        className="error_text"
                                      >
                                        {
                                          errors.houseHoldList?.[index]
                                            ?.actualIncome.message
                                        }
                                      </span>
                                    )}
                                </td>
                                <td>
                                  <select
                                    name=""
                                    disabled={true}
                                    className="form-select"
                                    {...register(
                                      `houseHoldList.${index}.paymentCycle`,
                                    )}
                                  >
                                    <option value="">
                                      {t("PayCycleDrop")}
                                    </option>
                                    <option value="Annual">
                                      {t("PayCycleDropVal1")}
                                    </option>
                                    <option value="Monthly">
                                      {t("PayCycleDropVal2")}
                                    </option>
                                    <option value="TwicePerMonth">
                                      {t("PayCycleDropVal3")}
                                    </option>
                                    <option value="EveryTwoWeeks">
                                      {t("PayCycleDropVal4")}
                                    </option>
                                    <option value="Weekly">
                                      {t("PayCycleDropVal5")}
                                    </option>
                                  </select>
                                  {errors.houseHoldList?.[index]
                                    ?.paymentCycle && (
                                      <span
                                        role="alert"
                                        className="error_text"
                                      >
                                        {
                                          errors.houseHoldList?.[index]
                                            ?.paymentCycle.message
                                        }
                                      </span>
                                    )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <h4 className="text-center text-bg-light py-2">
                        {t('FormHeading')}
                      </h4>
                      {/* <div className="table table-responsive">
                        <table className="table table-bordered mt-4">
                          <thead>
                            <tr>
                              <th rowSpan={2}>Check box that applies</th>
                              <th rowSpan={2}>Household Size</th>
                              <th className="text-center" colSpan={7}>
                                How Often Payment Is Received
                              </th>
                            </tr>
                            <tr>
                              <th>Annual</th>
                              <th>Monthly</th>
                              <th>Twice Per Month</th>
                              <th>Every Two Weeks</th>
                              <th>Weekly</th>
                            </tr>
                          </thead>
                          <tbody>
                            {IncomeChart?.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <Radio
                                    disabled={true}
                                    value={`option${i + 1}`}
                                    checked={
                                      selectedOption === `option${i + 1}`
                                    }
                                    onChange={handleOptionChange}
                                    className="p-0"
                                  />
                              
                                </td>
                                <td>
                                  {item?.houseHoldSize
                                    ? item?.houseHoldSize
                                    : i + 1}
                                </td>
                                <td>{item?.annul}</td>
                                <td>{item?.monthly}</td>
                                <td>{item?.twicePerMonth}</td>
                                <td>{item?.everyTwoWeeks}</td>
                                <td>{item?.Weekly}</td>
                              </tr>
                            ))}
                            <tr>
                              <td>
                                <Radio
                                  value={`option10`}
                                  disabled={true}
                                  checked={selectedOption === `option10`}
                                  onChange={handleOptionChange}
                                  className="p-0"
                                />
                              </td>
                              <td className="text-start" colSpan={6}>
                                Household does not qualify
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div> */}

                      <div className="row form mb-3">
                        <div className="col-md-4 input_div">
                          <label>{t('HhSize')}</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            placeholder={t('HhSizePlaceholder')}
                            {...register(`NoOfPeoeple`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.NoOfPeoeple && (
                            <span role="alert" className="error_text">
                              {errors.NoOfPeoeple.message}
                            </span>
                          )}
                        </div>{" "}
                        <div className="col-md-4 input_div">
                          <label>{t('TotalAnnual')}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('TotalAnnualPlaceholder')}
                            disabled={true}
                            {...register(`totalActualIncome`, {
                              // required: "This field is required",
                            })}
                          />
                          {errors.totalActualIncome && (
                            <span role="alert" className="error_text">
                              {errors.totalActualIncome.message}
                            </span>
                          )}
                        </div>{" "}
                        <div className="col-md-4 input_div">
                          <label>{t('PaymentCycleLabel')}</label>
                          <select
                            name=""
                            className="form-select"
                            disabled={true}
                            {...register("paymentCycle", {
                              // required: "This field is required",
                            })}
                          >
                            <option value="">{t('PaymentCycleDrop1')}</option>
                            <option value="Annual">{t('PaymentCycleDrop2')}</option>
                            <option value="Monthly">{t('PaymentCycleDrop3')}</option>
                            <option value="TwicePerMonth">
                              {t('PaymentCycleDrop4')}
                            </option>
                            <option value="EveryTwoWeeks">
                              {t('PaymentCycleDrop5')}
                            </option>
                            <option value="Weekly">{t('PaymentCycleDrop6')}</option>
                          </select>
                          {errors.paymentCycle && (
                            <span role="alert" className="error_text">
                              {errors.paymentCycle.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="partD">
                      <h5>{t('PartDTitle')}</h5>
                      <p>
                        {t('PartDDesc')}
                      </p>
                      <div className="row form">
                        {/* <div className="col-md-4 input_div">
                                            <label>Guardian SSN (last 4 digits)</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                {...register(`FirstName`, {
                                                    required: "This field is required",
                                                })}
                                            />
                                            {errors.FirstName && (
                                                <span role="alert" className="error_text">
                                                    {errors.FirstName.message}
                                                </span>
                                            )}
                                        </div> */}
                        <div className="col-md-4 input_div">
                          <label>{t('ParentFirstName')}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('ParentFirstNamePlaceholder')}
                            disabled={true}
                            {...register(`firstName`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.firstName && (
                            <span role="alert" className="error_text">
                              {errors.firstName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t('ParentFullName')}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('ParentFullNamePlaceholder')}
                            disabled={true}
                            {...register(`fullName`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.fullName && (
                            <span role="alert" className="error_text">
                              {errors.fullName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 mt-4">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`isSSN`, {
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("NoSSN")}
                          </label>
                          {errors.isSSN && (
                            <span role="alert" className="error_text">
                              {errors.isSSN.message}
                            </span>
                          )}{" "}
                        </div>
                        {watch("isSSN") === true ? (
                          ""
                        ) : (
                          <div className="col-md-4 input-div">
                            <label>{t("SSNNumber")}</label>
                            <input
                              type="number"
                              disabled={true}
                              className="form-control"
                              placeholder={t("placeholderSSNNumber")}
                              {...register(`ssnNumber`, {
                                required: t("RequiredValidation"),
                              })}
                            />
                            {errors.ssnNumber && (
                              <span role="alert" className="error_text">
                                {errors.ssnNumber.message}
                              </span>
                            )}
                          </div>
                        )}
                        <div className="col-md-4 input_div">
                          <label>{t('ParentAddress')}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('ParentAddressPlaceholder')}
                            disabled={true}
                            {...register(`address`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.address && (
                            <span role="alert" className="error_text">
                              {errors.address.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t('ParentAPTNumber')}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('ParentAPTPlaceholder')}
                            disabled={true}
                            {...register(`apartment`, {
                              // required: "This field is required",
                            })}
                          />
                          {errors.apartment && (
                            <span role="alert" className="error_text">
                              {errors.apartment.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t('ParentHomeMobile')}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('ParentHomeMobilePlaceholder')}
                            disabled={true}
                            {...register(`phone`, {
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          {errors.phone && (
                            <span role="alert" className="error_text">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t('ParentCity')}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t('ParentCityPlaceholder')}
                            disabled={true}
                            {...register(`city`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.city && (
                            <span role="alert" className="error_text">
                              {errors.city.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t('ParentState')}</label>
                          <select
                            name=""
                            className="form-select"
                            disabled={true}
                            {...register("state", {
                              required: "This field is required",
                              valueAsNumber: true,
                            })}
                          >
                            <option value="">{t('ParentStatePlaceholder')}</option>
                            {stateList &&
                              stateList?.map((data, i) => (
                                <option key={i} value={data?.stateId}>
                                  {data?.stateName}
                                </option>
                              ))}
                          </select>
                          {errors.state && (
                            <span role="alert" className="error_text">
                              {errors.state.message}
                            </span>
                          )}
                        </div>
                        {/* <div className="col-md-4 input_div">
                                            <label>* Zip</label>
                                            <input type="text" className="form-control" />
                                        </div> */}
                        {/* <div className="col-md-4 input_div">
                                            <label>* Work Phone</label>
                                            <input type="text" className="form-control" />
                                        </div> */}
                        <div className="col-md-4 input_div">
                          <label> {t('ParentEmail')}</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t('ParentEmailPlaceholder')}
                            disabled={true}
                            {...register(`emailConfirmationAddress`, {
                              // required: "This field is required",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message:
                                  "Entered value does not match email format",
                              },
                            })}
                          />
                          {errors.emailConfirmationAddress && (
                            <span role="alert" className="error_text">
                              {errors.emailConfirmationAddress.message}
                            </span>
                          )}
                        </div>
                        {/* <div className="col-md-4 input-div mt-2">
                          <label>{t('SignLabel')}</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={true}
                            placeholder={t('CheckBoxForCorrectInfoAfterShowBox')}
                            {...register(`signatureName`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.signatureName && (
                            <span role="alert" className="error_text">
                              {errors.signatureName.message}
                            </span>
                          )}
                        </div> */}
                        <div className="col-md-9">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`sign`, {
                                required: t("RequiredValidation"),
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("FirstCheckBox")}
                          </label>
                          {errors.sign && (
                            <span role="alert" className="error_text">
                              {errors.sign.message}
                            </span>
                          )}{" "}
                        </div>

                        {watch("sign") ? (
                          <div className="col-md-4 input-div mt-2">
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder={t(
                                "CheckBoxForCorrectInfoAfterShowBox"
                              )}
                              {...register(`signatureName`, {
                                required: t("RequiredValidation"),
                              })}
                            />
                            {errors.signatureName && (
                              <span role="alert" className="error_text">
                                {errors.signatureName.message}
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="col-md-9">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`isOtherStateEbtbenifit`, {
                                required: t("RequiredValidation"),
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("CheckBoxForCorrectInfo")}
                          </label>
                          {errors.isOtherStateEbtbenifit && (
                            <span role="alert" className="error_text">
                              {errors.isOtherStateEbtbenifit.message}
                            </span>
                          )}{" "}
                        </div>
                        <div className="col-md-9">
                          <label className="personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`isSummerEBT`, {
                                required: t("RequiredValidation"),
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("CheckBoxForCorrectInfo1")}
                          </label>
                          {errors.sign && (
                            <span role="alert" className="error_text">
                              {errors.sign.message}
                            </span>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-3 text-end">
                      <Button className="cancel_btn" onClick={() => window.close()}>
                        {t('BackBtn')}
                      </Button>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </section>
        )
        }
      </Suspense >
    </>
  );
}

export default ShowPreviousSurveyData;
