import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
// import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
// import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { Config } from "../../../../../Utils/Config";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
// import filter from "../../../../../Assets/PngImage/filter.png";
import Select from "react-select";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
// const studentHeadList = [
//   { id: "offsiteStudentName", label: "Offsite Student Name" },
//   { id: "enrolledSchool", label: "Enrolled School" },
//   { id: "status", label: "Status" },
// ];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transperent",
  },
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",
    lineHeight: "inherit",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
  // "&.pdf_download": {
  //   borderTop: "0px",
  //   borderBottom: "0.5px",
  // },
}));
function EditCheck(props) {
  const { cDate, distId } = props;
  //   const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [prdList, setPrdList] = useState([]);
  //   const [openCircle, setOpenCircle] = useState(false);
  const [openCircleNew, setOpenCircleNew] = useState(false);
  //   const [reportDetails, setReportDetails] = useState();
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [derror, setDerror] = useState();
  const [mlService, setMlService] = useState("Breakfast");

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  //   const {
  //     register,
  //     handleSubmit,
  //     formState: { errors },
  //   } = useForm();

  useEffect(() => {
    if (distId && cDate) {
      getSchoolList(distId);
    }
    // eslint-disable-next-line
  }, [distId, cDate]);

  //   const getDistrictList = async () => {
  //     let seData = {
  //       url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
  //       headers: headers,
  //     };
  //     let res = await GetCallApi(seData);

  //     if (res?.status === 200) {
  //       if (res.data.success) {
  //         const dlist = res.data.payload?.map((sd) => {
  //           return {
  //             value: sd?.schoolDistrictId,
  //             label: sd?.districtName,
  //           };
  //         });
  //         setDistrictList(dlist?.length > 0 ? dlist : []);
  //       }
  //     }
  //   };

  const getSchoolList = async (id) => {
    setPrdList([]);
    setSchoolList([]);
    setSchoolSelect([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          let uslist = [{ value: "all", label: "Select All" }, ...dlist];

          setSchoolList(uslist?.length > 1 ? uslist : []);
          setSchoolSelect(dlist);
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  //   function convertDate(str) {
  //     var date = new Date(str?.replace(/-/g, "/")),
  //       mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  //       day = ("0" + date.getDate()).slice(-2);
  //     return [mnth, day, date.getFullYear()].join("/");
  //   }
  useEffect(() => {
    onSubmit();
    // eslint-disable-next-line
  }, [schoolSelect, mlService]);

  const onSubmit = async (data) => {
    setPrdList([]);
    let is_success = true;
    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }
    if (is_success) {
      setDerror({ err: "" });
      //   setOpenCircle(true);

      let sendData = {
        // ...data,
        // schoolDistrictId: data?.schoolDistrictId
        //   ? data?.schoolDistrictId
        //   : null,
        schoolDistrictId: distId,
        schoolId: schoolSelect?.map((x) => x.value),
        date: cDate,
        mealService: mlService,
        // startDate: data?.startDate ? data?.startDate : null,
        // endDate: data?.endDate ? data?.endDate : null,
        userId: LogDetail?.userId,
      };
      //   let distName = districtList?.filter(
      //     (x) => x.value === sendData?.schoolDistrictId
      //   )[0]?.label;
      //   let schName =
      //     schoolSelect?.length === schoolList?.length - 1
      //       ? "All"
      //       : schoolSelect?.map((x) => x.label).toString();

      //   let sdate = data?.startDate ? data?.startDate?.toISOString() : null;
      //   let edate = data?.endDate ? data?.endDate?.toISOString() : null;
      //   let reData = {
      //     startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
      //     endDate: edate ? convertDate(edate?.split("T")[0]) : null,
      //     distName: distName,
      //     schName: schName,
      //     mealService: data?.mealService,
      //   };
      //   setReportDetails(reData);
      let seData = {
        url: `/api/Report/GetDailyParticipationReport`,
        body: sendData,
        headers: headers,
      };
      let res = await PostCallApi(seData);

      //   setOpenCircle(false);

      if (res?.status === 200) {
        if (res.data.success) {
          const SdData = res.data.payload;
          setPrdList(SdData);
        } else {
          setPrdList([]);
        }
      }
    }
  };

  const [downloadPdf, setDownloadpdf] = useState(false);

  const tableRef = useRef(null);
  //   const { onDownload } = useDownloadExcel({
  //     currentTableRef: tableRef.current,
  //     filename: "Edit Check Report",
  //     sheet: "Meal",
  //   });
  let Time = new Date();
  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    // width: "100%",
    // height: "100%",
    // position: "relative",
    // margin: "0",
  };
  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);
  const handleDownloadPdf = async () => {
    const element = tableRef.current;
    let newPdfDate = Time.toLocaleString();
    // return false;
    setOpenCircleNew(true);
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`Check Report.pdf`);
        setDownloadpdf(false);
        setOpenCircleNew(false);
      });
  };
  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };
  return (
    <>
      <Backdrop
        open={openCircleNew}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="edit_check_dash_Page">
          <form>
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <div className="row">
              <div className="col-md-3 my-auto">
                <h4 className="all_title ">Daily Meal Participation</h4>
              </div>
              <div className="col-md-3 ms-auto">
                <div className="form-group">
                  <label>Meal Service</label>
                  <select
                    className="form-select"
                    value={mlService}
                    onChange={(e) => setMlService(e.target.value)}
                  >
                    {/* <option value="All">All</option> */}
                    <option value="Breakfast">Breakfast</option>
                    <option value="Lunch">Lunch</option>
                    <option value="Dinner">Dinner</option>
                    <option value="Snacks">Snacks</option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 ">
                <div className="form-group">
                  <label> School</label>
                  <Select
                    isMulti
                    name="type"
                    options={schoolList}
                    // closeMenuOnSelect={false}
                    // hideSelectedOptions={false}
                    className="form-neselect"
                    classNamePrefix="select"
                    placeholder="Select School"
                    value={schoolSelect ? schoolSelect : null}
                    onChange={(selected) => {
                      true &&
                      selected.length &&
                      selected.find((option) => option.value === "all")
                        ? handleIOCMulti(schoolList.slice(1))
                        : !true
                        ? handleIOCMulti((selected && selected.value) || null)
                        : handleIOCMulti(selected);
                    }}
                    style={{ width: "100%" }}
                  />

                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                </div>
              </div>
              {/* <div className="col-md-9 text-end">
                {prdList?.allcountData?.length > 0 ? (
                  <>
                    <Button
                      className="cancel_btn me-3 text-end"
                      type="button"
                      onClick={() => setDownloadpdf(true)}
                    >
                      Pdf
                    </Button>
                    <Button
                      className="cancel_btn me-3 text-end"
                      type="button"
                      onClick={() => onDownload()}
                    >
                      Download
                    </Button>
                  </>
                ) : (
                  ""
                )}
                {openCircle ? (
                  <Button className="theme_btn text-end">
                    Applying Filter{" "}
                    <CircularProgress
                      sx={{
                        color: "#fff",
                        marginLeft: "0.5rem",
                        height: "23px !important",
                        width: "23px !important",
                      }}
                    />
                  </Button>
                ) : (
                  <Button className="theme_btn text-end" type="submit">
                    <img src={filter} className="me-1" alt="" height={23} />
                    Apply
                  </Button>
                )}
              </div> */}
            </div>
          </form>
          <Paper className="table-box mt-3">
            <TableContainer ref={tableRef} style={back}>
              {/* <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                <div className="d-flex">
                  <img
                    src={
                      downloadPdf
                        ? require("../../../../../Assets/PngImage/Header_logo_1.png")
                        : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
                    }
                    alt=""
                    width={100}
                    height={80}
                  />
                  <div className="ms-3">
                    <label
                      style={{
                        fontSize: "18px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      Edit Check Report{" "}
                    </label>
                    <div
                      className="names"
                      style={{
                        fontSize: "16px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      {reportDetails?.mealService}
                    </div>
                    <div
                      className="names"
                      style={{
                        fontSize: "16px",
                        color: "#2c2e35",
                        fontWeight: 600,
                        marginBottom: "7px",
                      }}
                    >
                      {reportDetails?.startDate ? reportDetails?.startDate : ""}
                      {reportDetails?.endDate
                        ? " - " + reportDetails?.endDate
                        : ""}
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    style={{
                      fontSize: "18px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                      width: "250px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {reportDetails?.schName}
                  </label>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {reportDetails?.distName}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                      marginBottom: "7px",
                    }}
                  >
                    {Time.toLocaleString()}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                    }}
                  >
                    {LogDetail?.firstName + " " + LogDetail?.lastName}
                  </div>
                  <div
                    className="names"
                    style={{
                      fontSize: "16px",
                      color: "#2c2e35",
                      fontWeight: 600,
                    }}
                  >
                    {prdList?.avgAttendanceFactor ? (
                      <>
                        {" "}
                        Average Attendance Factor :{" "}
                        {prdList?.avgAttendanceFactor}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div> */}

              <Table className={`mb-0 ${!downloadPdf && "table-bordered"}`}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      rowSpan={2}
                      className={` ${
                        downloadPdf && "pdf_download align-bottom"
                      }`}
                    >
                      Serving Date
                    </StyledTableCell>

                    <StyledTableCell
                      className={`text-center ${downloadPdf && "pdf_download"}`}
                      colSpan={4}
                    >
                      Free
                    </StyledTableCell>
                    <StyledTableCell
                      className={`text-center ${downloadPdf && "pdf_download"}`}
                      colSpan={4}
                    >
                      Reduced
                    </StyledTableCell>
                    <StyledTableCell
                      className={`text-center ${downloadPdf && "pdf_download"}`}
                      colSpan={4}
                    >
                      Paid
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={4}
                      className={`${
                        downloadPdf && "pdf_download align-bottom"
                      }`}
                    >
                      Total
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    {[...Array(4).keys()]?.map((r, i) => (
                      <React.Fragment key={i}>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Claim
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Percentage %
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Exist
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Notes
                        </StyledTableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prdList?.allcountData?.length > 0 ? (
                    <>
                      {prdList?.allcountData?.map((data, i) => (
                        // <>
                        <StyledTableRow key={i}>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.servingDate}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.freeCounts?.claim}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.freeCounts?.percentage}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.freeCounts?.exist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.freeCounts?.notes}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.reducedCounts?.claim}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.reducedCounts?.percentage}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.reducedCounts?.exist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.reducedCounts?.notes}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.paidCounts?.claim}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.paidCounts?.percentage}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.paidCounts?.exist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.paidCounts?.notes}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.total?.claim}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.total?.percentage}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.total?.exist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {data?.total?.notes}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                      <StyledTableRow>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Total
                        </StyledTableCell>

                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalFreeCounts?.claim}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalFreeCounts?.percentage}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalFreeCounts?.exist}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalFreeCounts?.notes}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalReducedCounts?.claim}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalReducedCounts?.percentage}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalReducedCounts?.exist}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalReducedCounts?.notes}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalPaidCounts?.claim}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalPaidCounts?.percentage}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalPaidCounts?.exist}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalPaidCounts?.notes}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalCounts?.claim}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalCounts?.percentage}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalCounts?.exist}
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          {prdList?.totalCounts?.notes}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ) : (
                    <TableRow className="all_list">
                      <StyledTableCell
                        className="er_nodata m-0 p-5"
                        colSpan={17}
                      >
                        No Data Found
                      </StyledTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {/* <div className="mt-5">
                <label
                  style={{
                    fontSize: "18px",
                    color: "#2c2e35",
                    fontWeight: 600,
                    marginBottom: "7px",
                  }}
                >
                  Summary for Reported month
                </label>
              </div>
              <Table className={` ${!downloadPdf && "table-bordered"}`}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      rowSpan={2}
                      className={` ${
                        downloadPdf && "pdf_download align-bottom"
                      }`}
                    >
                      Active Days
                    </StyledTableCell>
                 
                    <StyledTableCell
                      className={`text-center ${downloadPdf && "pdf_download"}`}
                      colSpan={3}
                    >
                      Free
                    </StyledTableCell>
                    <StyledTableCell
                      className={`text-center ${downloadPdf && "pdf_download"}`}
                      colSpan={3}
                    >
                      Reduced
                    </StyledTableCell>
                    <StyledTableCell
                      className={`text-center ${downloadPdf && "pdf_download"}`}
                      colSpan={3}
                    >
                      Paid
                    </StyledTableCell>
                    <StyledTableCell
                      colSpan={3}
                      className={`${
                        downloadPdf && "pdf_download align-bottom"
                      }`}
                    >
                      Total
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    {[...Array(4).keys()]?.map((r, i) => (
                      <React.Fragment key={i}>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Avg Claim
                        </StyledTableCell>

                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Avg Exist
                        </StyledTableCell>
                        <StyledTableCell
                          className={`${downloadPdf && "pdf_download"}`}
                        >
                          Notes
                        </StyledTableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.activeDays}
                    </StyledTableCell>
                 
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.freeAvg?.avgClaim}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.freeAvg?.avgExist}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.freeAvg?.notes}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.reducedAvg?.avgClaim}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.reducedAvg?.avgExist}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.reducedAvg?.notes}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.paidAvg?.avgClaim}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.paidAvg?.avgExist}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.paidAvg?.notes}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.totalAvg?.avgClaim}
                    </StyledTableCell>

                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.totalAvg?.avgExist}
                    </StyledTableCell>
                    <StyledTableCell
                      className={`${downloadPdf && "pdf_download"}`}
                    >
                      {prdList?.totalAvg?.notes}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table> */}
            </TableContainer>
          </Paper>
        </section>
      </Suspense>
    </>
  );
}

export default EditCheck;
