import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { MdEdit } from "react-icons/md";
import DeletePopup from "../../../../../Components/Reusable/DeletePopup";
import { useSelector } from "react-redux";
// import Axios from "../../../../../Utils/AxiosConfig";
import { GetCallApi } from "../../../../../Action/Action";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const templateHead = [
  { id: "templateTitle", label: "Template Name" },
  { id: "notificationType", label: " Notification Type" },
  { id: "templateLanguage", label: "Language" },
  { id: "fieldsUsedNotes", label: "Template Type" },
  { id: "subject", label: "Subject" },
  // { id: "status", label: "Status" },
];
function Template() {
  const [open, setOpen] = useState(false);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const [prdList, setPrdList] = useState([]);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getTempList();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const getTempList = async () => {
    setPrdList([]);
    setOpen(true);
    let seData = {
      url: `/api/Template/GetTemplateList?schoolDistrictId=${LogDetail?.schoolDistrictId}`,
      headers: headers,
    };

    let resp = await GetCallApi(seData);
    setOpen(false);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        const sdData = resp.data.payload?.map((tem) => {
          return {
            ...tem,
            Action: (
              <>
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`edit/${tem?.templateId}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
                <DeletePopup
                  title={"Delete Template"}
                  text={tem?.templateTitle}
                  url={`/api/Template/DeleteTemplateById?emailTemplateId=${tem?.templateId}`}
                />
              </>
            ),
          };
        });
        setPrdList(sdData);
      } else {
        setOpen(false);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="template_listpage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box">
                  <div className="all_title ProductTitle">Template List</div>
                  <Link to={"create"} className="theme_btn">
                    <BsPlusSquareDotted fontSize={24} className="me-3" />
                    Create
                  </Link>
                </div>
                <ListTable
                  headCells={templateHead}
                  action={true}
                  ListData={prdList}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default Template;
