import React from "react";
import "./index.scss";

function AdminCountBox(props) {
  return (
    <div className={`countBox ${props?.class}`}>
      <img src={props?.image} alt="" />
      <div className="m_num">
        {props?.num}
        {props?.per ? "%" : ""} <span>{props?.per ? "complete" : ""}</span>
      </div>
      <div className="m_name">{props?.name}</div>
    </div>
  );
}

export default AdminCountBox;
