import React, { Suspense, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import "./index.scss";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import { useFieldArray, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { GetCallApi } from "../../../../Action/Action";
import { Config } from "../../../../Utils/Config";
import { BsFillPatchCheckFill } from "react-icons/bs";
import ChangeLanguage from "../../../../locales/ChangeLang";
import { useTranslation } from "react-i18next";
import NotFound from "../../../../Assets/Image_not_available.jpg";
import { FaChevronDown } from "react-icons/fa";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function HouseHoldMemberInformation() {
  const { id, dId } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [distLogo, setDistLogo] = useState("");
  const [payDetail, setPayDetail] = useState(false);
  const [agree, setAgree] = useState(false);
  const [directForm, setDirectForm] = useState(false);
  const [firstFormData, setFirstFormData] = useState([]);

  const Lang = useSelector((state) => state.language.lang);

  useEffect(() => {
    let language =
      Lang === "en"
        ? "English"
        : Lang === "hi"
        ? "Hindi"
        : Lang === "sp"
        ? "Spanish"
        : Lang === "ch"
        ? "Chinese"
        : "English";
    getDistrictSurveyTemplate(language);
    // eslint-disable-next-line
  }, [Lang]);

  const getDistrictSurveyTemplate = async (lang) => {
    setOpenCircle(true);
    let apiUrl = {
      url: `/api/SchoolDistrict/GetDistrictSurveyTemplate?schoolDistrictId=${dId}&surveyTemplateType=${"SurveyIncomeGuidLine"}&language=${lang}`,
    };
    let resp = await GetCallApi(apiUrl);
    if (resp?.status === 200 && resp?.data?.success) {
      let tempData = resp?.data?.payload;
      setFirstFormData(tempData ?? []);
      setDistLogo(tempData?.districtLogo ?? "");
      setOpenCircle(false);
    } else {
      setDirectForm(true);
      setOpenCircle(false);
    }
  };

  useEffect(() => {
    if (directForm) {
      getDistrictList(dId);
    }
    // eslint-disable-next-line
  }, [dId]);

  const getDistrictList = async (distId) => {
    if (distId) {
      let seData = {
        url: `/api/SchoolDistrict/GetDistrictLogoById?schoolDistrictId=${distId}`,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload;
          setDistLogo(dlist ? dlist[0] : "");
        }
      }
    }
  };
  const ManageForm = (props) => {
    const { payDetail, setPayDetail, Lang } = props;

    const [openCircle, setOpenCircle] = useState(false);
    const [studId, setStudId] = useState();
    const [qiId, setQiId] = useState("");
    const [schoolList, setSchoolList] = useState([]);
    const [instructionData, setInstructionData] = useState();
    const [stateList, setStateList] = useState([]);

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);
    const { t } = useTranslation();

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    };

    const {
      register,
      control,
      handleSubmit,
      reset,
      setValue,
      getValues,
      clearErrors,
      watch,
      formState: { errors },
    } = useForm();

    const { fields, append } = useFieldArray({
      control,
      name: "StudentList",
    });

    const { fields: Field1, append: append1 } = useFieldArray({
      control,
      name: "houseHoldList",
    });

    useEffect(() => {
      setValue("surveyBenifitField", "NA");
      // eslint-disable-next-line
    }, []);
    useEffect(() => {
      if (Lang) {
        let language =
          Lang === "en"
            ? "English"
            : Lang === "hi"
            ? "Hindi"
            : Lang === "sp"
            ? "Spanish"
            : Lang === "ch"
            ? "Chinese"
            : "English";
        clearErrors();
        getDistrictInstruction(language);
      }
      // eslint-disable-next-line
    }, [Lang]);

    useEffect(() => {
      if (!payDetail) {
        getStateList();
        setValue("actualIncome", 0);
        if (id === "C719C1E4-240B-438A-98DB-00431260F330") {
          const data = [
            {
              birthDate: "",
              firstName: "Test",
              lastName: "Student",
              isStudent: true,
              isFosterChild: false,
              fosterChild: "NA",
              grade: "",
              schoolId: "",
              studentType: "",
              country: "USA",
            },
          ];
          setValue("StudentList", data);
          append1({
            birthDate: "",
            firstName: "",
            lastName: "",
            isStudent: false,
            studentGuidId: null,
            grade: "",
            isFosterChild: false,
            actualIncome: 0,
            paymentCycle: "",
            studentType: "",
            country: "USA",
            removable: true,
            houseHoldIncomeSub: [
              { incomeSource: "", incomeAmount: "", paymentCycle: "" },
            ],
          });
        }
        setValue("surveyBenifitField", "NA");
      }
      // eslint-disable-next-line
    }, [payDetail]);

    useEffect(() => {
      if (dId) {
        getSchoolList(dId);
      }
      // eslint-disable-next-line
    }, [dId]);

    const getDistrictInstruction = async (lang) => {
      let apiUrl = {
        url: `/api/SchoolDistrict/GetDistrictSurveyTemplate?schoolDistrictId=${dId}&surveyTemplateType=${"Instruction"}&language=${lang}`,
      };
      let resp = await GetCallApi(apiUrl);
      if (resp?.status === 200 && resp?.data?.success) {
        let tempData = resp?.data?.payload;
        setInstructionData(tempData ?? []);
      }
    };

    useEffect(() => {
      if (fields || Field1) {
        setValue("NoOfPeoeple", fields?.length + Field1?.length);
      }
      // eslint-disable-next-line
    }, [fields, Field1]);

    const getStateList = () => {
      Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
        headers,
      })
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setStateList(res.data.payload);
            }
          }
        })
        .catch((e) => console.log(e));
    };

    const getSchoolList = async (dsId) => {
      setSchoolList([]);
      if (dsId) {
        let seData = {
          url: `/api/School/GetSchoolListBySchoolDistrictIdWithoutToken?schoolDistrictId=${dsId}`,
          headers: headers,
        };
        let res = await GetCallApi(seData);

        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolId,
                label: sd?.schoolName,
              };
            });
            setSchoolList(dlist?.length > 0 ? dlist : []);
          }
        }
      }
      if (id !== "C719C1E4-240B-438A-98DB-00431260F330") {
        getStudentList(id);
      }
    };

    const getStudentList = (id) => {
      Axios.get(
        `/api/ParentModule/GetStudentListByParentID?parentGuidId=${id}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const dlist = res.data.payload;
              if (dlist === undefined || dlist?.length === 0) {
                const data = [
                  {
                    birthDate: "",
                    firstName: "",
                    lastName: "",
                    isStudent: true,
                    isFosterChild: false,
                    studentGuidId: null,
                    fosterChild: "NA",
                    grade: "",
                    schoolId: "",
                    studentType: "",
                    country: "USA",
                  },
                ];
                setValue("StudentList", data);
                setValue("houseHoldList", [
                  {
                    ...data[0],
                    isStudent: false,
                    houseHoldIncomeSub: [
                      { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                    ],
                  },
                ]);
              }
              let nr = dlist?.map((std, index) => {
                return {
                  birthDate: std?.birthDate?.split("T")[0],
                  firstName: std?.firstName,
                  lastName: std?.lastName,
                  isStudent: true,
                  studentGuidId: std?.studentGuidId ? std?.studentGuidId : null,
                  fosterChild: std?.fosterChild ? std?.fosterChild : "NA",
                  grade: std?.grade,
                  schoolId: std?.schoolId ? std?.schoolId : "",
                  isFosterChild: std?.isFosterChild
                    ? std?.isFosterChild
                    : false,
                  studentType: "",
                  studentId: std?.studentId,
                  country: "USA",
                  removable: true,
                };
              });
              if (nr?.length > 0 || nr !== undefined) {
                setValue("StudentList", nr);
                append1({
                  birthDate: "",
                  firstName: "",
                  lastName: "",
                  isStudent: false,
                  studentGuidId: null,
                  grade: "",
                  isFosterChild: false,
                  actualIncome: 0,
                  paymentCycle: "",
                  studentType: "",
                  country: "USA",
                  removable: true,
                  houseHoldIncomeSub: [
                    { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                  ],
                });
                handleChoise("val", 0);
              }
              handleActualIncome();
            } else {
              const data = [
                {
                  birthDate: "",
                  firstName: "",
                  lastName: "",
                  isStudent: true,
                  studentGuidId: null,
                  fosterChild: "NA",
                  grade: "",
                  schoolId: "",
                  isFosterChild: false,
                  studentType: "",
                  country: "USA",
                  removable: true,
                },
              ];
              setValue("StudentList", data);
              append1({
                birthDate: "",
                firstName: "",
                lastName: "",
                isStudent: false,
                studentGuidId: null,
                grade: "",
                isFosterChild: false,
                actualIncome: 0,
                paymentCycle: "",
                studentType: "",
                country: "USA",
                removable: true,
                houseHoldIncomeSub: [
                  { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                ],
              });
            }
          }
        })
        .catch((e) => console.log(e));
    };

    const handleChoise = (id, index, name) => {
      setStudId(id);
      setQiId(index);
      if (id === studId) {
        setStudId(studId);
      }
      if (index === qiId) {
        setQiId(qiId);
      }
      if (index === "NA") {
        handleActualIncome();
        setValue("caseName", "");
        setValue("caseNumber", "");
      }
      if (name === "surveyBenifitField") {
        setValue("totalChildIncome");
        setValue("houseHoldList", []);
        setTimeout(() => {
          append1({
            birthDate: "",
            firstName: "",
            lastName: "",
            isStudent: false,
            studentGuidId: null,
            grade: "",
            isFosterChild: false,
            actualIncome: 0,
            paymentCycle: "",
            studentType: "",
            country: "USA",
            removable: true,
            houseHoldIncomeSub: [
              { incomeSource: "", incomeAmount: "", paymentCycle: "" },
            ],
          });
        }, 200);
      }
    };

    const onSubmit = (data) => {
      let radioVal = {};
      setOpenCircle(true);

      if (Array.isArray(data?.isRace)) {
        const validCheckboxNames = [
          "isAmericanIndianAlaskaNative",
          "isAsian",
          "isBlackAfricanAmerican",
          "isNativeHawaiianOtherPacificIslande",
          "isWhite",
        ];
        const filteredRaceKeys = data?.isRace.filter((key) =>
          validCheckboxNames.includes(key)
        );
        filteredRaceKeys.forEach(function (prop, index) {
          radioVal[prop] = true;
        });
      }

      let allstudent = [];
      if (data?.surveyBenifitField === "NA") {
        allstudent = [...data?.StudentList, ...data?.houseHoldList];
      } else {
        allstudent = data?.StudentList;
      }
      let sendData = {
        ...data,
        ...radioVal,
        createdBy: LogDetail?.parentsGuidId ? LogDetail?.parentsGuidId : id,
        TotalHousholdAnuualIncome: 0,
        isHispanic:
          data?.isHispanic === "true"
            ? true
            : data?.isHispanic === "false"
            ? false
            : null,
        totalChildIncome: data?.totalChildIncome ? data?.totalChildIncome : 0,
        schoolDistrictId: dId ? dId : null,
        StudentSurvey: allstudent?.map((x) => {
          return {
            ...x,
            studentGuidId: x.studentGuidId ? x?.studentGuidId : null,
            isFosterChild: x?.isFosterChild ? x?.isFosterChild : false,
            actualIncome: x?.actualIncome ? x?.actualIncome : 0,
            schoolId: x?.schoolId ? x?.schoolId : null,
            schoolDistrictId: dId ? dId : null,
            houseHoldIncomeSub:
              x?.houseHoldIncomeSub?.length > 0
                ? x?.houseHoldIncomeSub?.map((y) => {
                    return {
                      ...y,
                      incomeAmount: y?.incomeAmount >= 0 ? y?.incomeAmount : 0,
                    };
                  })
                : [],
          };
        }),
      };
      let Sedata = new FormData();

      if (data?.StudentList?.length > 0) {
        data?.StudentList?.map((d) => {
          return Sedata.append("StudentSurvey", JSON.stringify(d));
        });
      } else {
        Sedata.append("StudentSurvey", []);
      }
      ["StudentList"].forEach((e) => delete sendData[e]);
      for (var key in sendData) {
        Sedata.append(key, sendData[key]);
      }
      Axios.post("/api/Survey/CreateHouseholdInformationSurvey", sendData, {
        headers,
      })
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setPayDetail(true);
              Swal.fire({
                position: "top-end",
                width: 400,
                text: res.data?.message,
                showConfirmButton: false,
                timer: 1500,
                color: "#ffffff",
                background: "#48953e",
                showClass: {
                  popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutRight",
                },
              });
              setOpenCircle(false);
              reset();
            } else {
              setOpenCircle(false);
            }
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    };

    const handleActualIncome = () => {
      const studentList = getValues("StudentList");
      const houseHold = getValues("houseHoldList");
      let Allmember = [...houseHold, ...studentList];
      const paymentCycles = Allmember.map((std) => std?.paymentCycle);

      const firstCycle = paymentCycles[0];
      const allCyclesMatch = paymentCycles.every(
        (cycle) => cycle === firstCycle
      );

      if (allCyclesMatch) {
        const totalMulti = 1;
        const allIncome = Allmember.filter((std) => std?.actualIncome)
          .map((x) => totalMulti * x?.actualIncome)
          .reduce((acc, current) => acc + parseFloat(current), 0);

        setValue("paymentCycle", firstCycle);
        setValue("totalActualIncome", allIncome);
      } else {
        const allIncome1 = Allmember.filter((std) => std?.actualIncome)
          .map((x) => {
            const totalMulti1 =
              x.paymentCycle === "Annual"
                ? 1
                : x.paymentCycle === "Monthly"
                ? 12
                : x.paymentCycle === "TwicePerMonth"
                ? 24
                : x.paymentCycle === "EveryTwoWeeks"
                ? 26
                : x.paymentCycle === "Weekly"
                ? 52
                : 1;

            return x.actualIncome * totalMulti1;
          })
          .reduce((acc, current) => acc + parseFloat(current), 0);

        setValue("paymentCycle", "Annual");
        setValue("totalActualIncome", allIncome1);
      }
    };

    const handleActualIncome1 = () => {
      const houseHold = getValues("houseHoldList");
      const child = [
        {
          houseHoldIncomeSub: [
            {
              paymentCycle: getValues("childPaymentCycle"),
              incomeAmount: getValues("totalChildIncome"),
            },
          ],
        },
      ];
      let allIn = [...houseHold, ...child];
      const paymentCycles = allIn.map((std) =>
        std?.houseHoldIncomeSub?.map((x) => x?.paymentCycle)
      );

      const flattenedCycles = paymentCycles.flat().filter(Boolean);
      const firstCycle = flattenedCycles[0];
      const allCyclesMatch = flattenedCycles.every(
        (cycle) => cycle === firstCycle
      );

      if (allCyclesMatch) {
        const totalMulti = 1;

        const allIncome = allIn
          .map((s) => s?.houseHoldIncomeSub)
          .flat()
          .filter((x) => x?.incomeAmount)
          .map((x) => totalMulti * parseFloat(x?.incomeAmount || 0))
          .reduce((acc, current) => acc + current, 0);

        setValue("paymentCycle", firstCycle || "Annual");
        setValue("totalActualIncome", allIncome);
      } else {
        const allIncome1 = allIn
          .map((s) => s?.houseHoldIncomeSub)
          .flat()
          .filter((x) => x?.incomeAmount)
          .map((x) => {
            const totalMulti1 =
              x.paymentCycle === "Annual"
                ? 1
                : x.paymentCycle === "Monthly"
                ? 12
                : x.paymentCycle === "TwicePerMonth"
                ? 24
                : x.paymentCycle === "EveryTwoWeeks"
                ? 26
                : x.paymentCycle === "Weekly"
                ? 52
                : 1;

            return parseFloat(x.incomeAmount || 0) * totalMulti1;
          })
          .reduce((acc, current) => acc + current, 0);

        setValue("paymentCycle", "Annual");
        setValue("totalActualIncome", allIncome1);
      }
    };

    const handleCaseNum = (num) => {
      if (num?.length === 10) {
        setValue("totalActualIncome", 0);
        getValues("StudentList")?.map((x, i) => {
          return (
            setValue(`StudentList.${i}.actualIncome`, 0),
            setValue(`StudentList.${i}.paymentCycle`, "Annual")
          );
        });
        getValues("houseHoldList")?.map((x, i) => {
          return (
            setValue(`houseHoldList.${i}.actualIncome`, 0),
            setValue(`houseHoldList.${i}.paymentCycle`, "Annual")
          );
        });
      } else {
        handleActualIncome();
      }
    };

    const handleRemove = (ind) => {
      let deleted = getValues("StudentList")?.filter((_, i) => i !== ind);
      setValue("StudentList", []);
      setTimeout(() => {
        setValue(
          "StudentList",
          deleted?.map((x) => {
            return {
              ...x,
              birthDate: !isNaN(new Date(x?.birthDate).getTime())
                ? new Date(x?.birthDate).toISOString().split("T")[0]
                : "",
              actualIncome: x?.actualIncome ? x?.actualIncome : "",
            };
          })
        );
        handleActualIncome();
      }, 100);
    };

    const handleRemove1 = (ind) => {
      let deleted = getValues("houseHoldList")?.filter((_, i) => i !== ind);
      setValue("houseHoldList", []);
      setTimeout(() => {
        setValue(
          "houseHoldList",
          deleted?.map((x) => {
            return {
              ...x,
              birthDate: !isNaN(new Date(x?.birthDate).getTime())
                ? new Date(x?.birthDate).toISOString().split("T")[0]
                : "",
              actualIncome: x?.actualIncome ? x?.actualIncome : "",
            };
          })
        );
        handleActualIncome();
      }, 100);
    };

    const handleRemove2 = (ind, index) => {
      let deleted = getValues("houseHoldList")?.map((x, hi) => {
        if (hi === index) {
          return {
            ...x,
            houseHoldIncomeSub: x?.houseHoldIncomeSub?.filter(
              (_, i) => i !== ind
            ),
          };
        }
        return x;
      });
      setTimeout(() => {
        setValue(
          "houseHoldList",
          deleted?.map((x) => {
            return {
              ...x,
            };
          })
        );
        handleActualIncome();
      }, 100);
    };

    const handleAddAnother = (ind) => {
      let deleted = getValues("houseHoldList");
      setTimeout(() => {
        setValue(
          "houseHoldList",
          deleted?.map((x, i) => {
            if (ind === i) {
              let payment =
                x?.houseHoldIncomeSub?.length > 0
                  ? [
                      ...x?.houseHoldIncomeSub,
                      { incomeSource: "", incomeAmount: "", paymentCycle: "" },
                    ]
                  : [{ incomeSource: "", incomeAmount: "", paymentCycle: "" }];
              return {
                ...x,
                houseHoldIncomeSub: payment,
              };
            }
            return {
              ...x,
            };
          })
        );
      }, 100);
    };

    const InsPop = () => {
      Swal.fire({
        position: "center",
        width: "90%",
        html: instructionData?.templates?.description,
        showConfirmButton: true,
        color: "#ffffff",
        background: "#48953e",
      });
    };

    return (
      <>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openCircle}
        >
          <CircularProgress sx={{ color: "#86c65a" }} />
        </Backdrop>
        <div className="hed">
          <div className="d-flex">
            {distLogo ? (
              <img
                src={`${Config.API_HOST_URL_live}/${distLogo}`}
                className="header_logo img-fluid"
                alt=""
              />
            ) : (
              <div></div>
            )}
            <h2 className="mx-auto">{t("SurveyTitle")}</h2>
            <ChangeLanguage />
          </div>
          <div className="info">
            <h5>
              <b>{t("PartATitle")}</b>
            </h5>
            <div className="d-flex">
              <p>{t("PartADesc")}</p>
              {instructionData?.templates?.description?.length > 0 ? (
                <Button className="theme_btn ms-auto" onClick={InsPop}>
                  {t("Instruction")}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <hr />
        <div className="houseHoldForm">
          <form
            action=""
            onSubmit={handleSubmit(onSubmit)}
            className="primary-box"
          >
            <div className="partA">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>{t("TableHead3")}</th>
                      <th>{t("TableHead4")}</th>
                      <th>{t("TableHead5")}</th>
                      <th>{t("TableHead2")}</th>
                      {getValues(`StudentList`)?.filter(
                        (x) => x.isStudent === true
                      )?.length > 0 ? (
                        <>
                          <th>{t("TableHead6")}</th>
                          <th>{t("TableHead10")} </th>
                          <th>{t("TableHead7")} </th>
                        </>
                      ) : (
                        ""
                      )}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields
                      ?.filter((x) => x.isStudent === true)
                      ?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="date"
                              className="form-control"
                              style={{
                                minWidth: "135px",
                                // maxWidth: "15px",
                              }}
                              {...register(`StudentList.${index}.birthDate`, {
                                required: t("RequiredValidation"),
                                valueAsDate: true,
                                validate: {
                                  isValidDate: (value) => {
                                    if (value > new Date()) {
                                      return t("DateValidation");
                                    }
                                  },
                                },
                              })}
                            />
                            {errors.StudentList?.[index]?.birthDate && (
                              <span role="alert" className="error_text">
                                {errors.StudentList?.[index]?.birthDate.message}
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("placeholderFirstName")}
                              {...register(`StudentList.${index}.firstName`, {
                                required: t("RequiredValidation"),
                              })}
                            />
                            {errors.StudentList?.[index]?.firstName && (
                              <span role="alert" className="error_text">
                                {errors.StudentList?.[index]?.firstName.message}
                              </span>
                            )}
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("placeholderLastName")}
                              {...register(`StudentList.${index}.lastName`, {
                                required: t("RequiredValidation"),
                              })}
                            />
                            {errors.StudentList?.[index]?.lastName && (
                              <span role="alert" className="error_text">
                                {errors.StudentList?.[index]?.lastName.message}
                              </span>
                            )}
                          </td>
                          <td>
                            <select
                              name=""
                              className="form-select"
                              {...register(`StudentList.${index}.fosterChild`)}
                            >
                              <option value="NA">{t("TypeDropVal5")}</option>
                              <option value="FosterChild">
                                {t("TypeDropVal1")}
                              </option>
                              <option value="MigrantWorker">
                                {t("TypeDropVal2")}
                              </option>
                              <option value="Runway">
                                {t("TypeDropVal3")}
                              </option>
                              <option value="HomeLess">
                                {t("TypeDropVal4")}
                              </option>
                            </select>
                            {errors.StudentList?.[index]?.fosterChild && (
                              <span role="alert" className="error_text">
                                {
                                  errors.StudentList?.[index]?.fosterChild
                                    .message
                                }
                              </span>
                            )}
                          </td>
                          {getValues(`StudentList`)?.filter(
                            (x) => x.isStudent === true
                          )?.length > 0 ? (
                            getValues(`StudentList.${index}.isStudent`) ? (
                              <>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("placeholderStudentId")}
                                    style={{
                                      minWidth: "90px",
                                      maxWidth: "90px",
                                    }}
                                    {...register(
                                      `StudentList.${index}.studentId`
                                    )}
                                  />
                                  {errors.StudentList?.[index]?.studentId && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.StudentList?.[index]?.studentId
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <select
                                    name=""
                                    id=""
                                    className="form-select"
                                    {...register(
                                      `StudentList.${index}.schoolId`
                                    )}
                                  >
                                    <option value="">
                                      {" "}
                                      {t("placeholderSchool")}
                                    </option>
                                    {schoolList &&
                                      schoolList?.map((ds, i) => (
                                        <option value={ds?.value} key={i}>
                                          {ds?.label}
                                        </option>
                                      ))}
                                  </select>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("placeholderGrade")}
                                    style={{
                                      minWidth: "50px",
                                      maxWidth: "50px",
                                    }}
                                    {...register(`StudentList.${index}.grade`, {
                                      // required: "This field is required",
                                    })}
                                  />
                                  {errors.StudentList?.[index]?.grade && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.StudentList?.[index]?.grade
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                              </>
                            ) : (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            )
                          ) : (
                            ""
                          )}
                          <td>
                            {index === 0 ? (
                              <Button
                                className="theme_btn"
                                onClick={() => {
                                  append({
                                    birthDate: "",
                                    firstName: "",
                                    lastName: "",
                                    isStudent: true,
                                    grade: "",
                                    schoolId: "",
                                    fosterChild: "NA",
                                    actualIncome: "",
                                    paymentCycle: "",
                                    studentType: "",
                                    country: "USA",
                                    removable: true,
                                  });
                                }}
                              >
                                <AiOutlinePlus className="icon" />
                              </Button>
                            ) : item?.removable ? (
                              <Button
                                className="cancel_btn"
                                onClick={() => {
                                  handleRemove(index);
                                }}
                              >
                                <AiOutlineClose className="icon" />
                              </Button>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="partB">
              <h5>
                <b>{t("PartBTitle")}</b>
              </h5>
              <ul>
                <li>
                  {t("PartBDesc1")}

                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    className="flex-row"
                    value={
                      getValues("surveyBenifitField")
                        ? getValues("surveyBenifitField")
                        : "NA"
                    }
                  >
                    <FormControlLabel
                      className="radioBtn"
                      value="FDPIR"
                      label={t("RadioName1")}
                      {...register("surveyBenifitField", {
                        onChange: (e) =>
                          handleChoise(e.target.checked, e.target.value),
                      })}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      className="radioBtn"
                      value="SNAP"
                      label={t("RadioName2")}
                      {...register("surveyBenifitField", {
                        onChange: (e) =>
                          handleChoise(e.target.checked, e.target.value),
                      })}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      className="radioBtn"
                      value="TANF"
                      label={t("RadioName3")}
                      {...register("surveyBenifitField", {
                        onChange: (e) =>
                          handleChoise(e.target.checked, e.target.value),
                      })}
                      control={<Radio />}
                    />
                    <FormControlLabel
                      className="radioBtn"
                      value="NA"
                      label={t("RadioName4")}
                      control={<Radio />}
                      {...register("surveyBenifitField", {
                        onChange: (e) =>
                          handleChoise(
                            e.target.checked,
                            e.target.value,
                            "surveyBenifitField"
                          ),
                      })}
                    />
                  </RadioGroup>
                  {errors.surveyBenifitField && (
                    <span role="alert" className="error_text">
                      {errors.surveyBenifitField.message}
                    </span>
                  )}
                </li>
                <li>
                  {t("PartBDesc2")} <b>{t("SkipPart")}</b>
                </li>
              </ul>
              {getValues("surveyBenifitField") === "TANF" ||
              getValues("surveyBenifitField") === "FDPIR" ||
              getValues("surveyBenifitField") === "SNAP" ? (
                <div className="row form mb-3">
                  <div className="col-md-4 input_div">
                    <label>{t("RadioSelectInput1")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("RadioSelectPlaceholder1")}
                      {...register(`caseName`, {
                        required: t("RequiredValidation"),
                      })}
                    />
                    {errors.caseName && (
                      <span role="alert" className="error_text">
                        {errors.caseName.message}
                      </span>
                    )}
                  </div>{" "}
                  <div className="col-md-4 input_div">
                    <label>{t("RadioSelectInput2")}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("RadioSelectPlaceholder2")}
                      {...register(`caseNumber`, {
                        required: t("RequiredValidation"),
                        onChange: (e) => handleCaseNum(e?.target?.value),
                        minLength: {
                          value: 10,
                          message: t("MinChar"),
                        },
                        maxLength: {
                          value: 10,
                          message: t("MaxChar"),
                        },
                      })}
                    />
                    {errors.caseNumber && (
                      <span role="alert" className="error_text">
                        {errors.caseNumber.message}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="partC">
              {getValues("surveyBenifitField") === "TANF" ||
              getValues("surveyBenifitField") === "FDPIR" ||
              getValues("surveyBenifitField") === "SNAP" ? (
                ""
              ) : (
                <>
                  <h5>
                    <b>{t("PartCTitle")}</b>
                  </h5>
                  <h6>{t("PartCDesc1")}</h6>
                  <p>{t("PartCDesc2")}</p>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr style={{ backgroundColor: "#f3f3f3" }}>
                          <th>{t("TableHead3")}</th>
                          <th>{t("TableHead4")}</th>
                          <th>{t("TableHead5")}</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {Field1?.filter((x) => x?.isStudent === false)?.map(
                          (item, index) => (
                            <React.Fragment key={index}>
                              <tr key={index}>
                                <td>
                                  <input
                                    type="date"
                                    className="form-control"
                                    style={{
                                      minWidth: "135px",
                                      // maxWidth: "135px",
                                    }}
                                    {...register(
                                      `houseHoldList.${index}.birthDate`,
                                      {
                                        required: t("RequiredValidation"),
                                        valueAsDate: true,
                                        validate: {
                                          isValidDate: (value) => {
                                            if (value > new Date()) {
                                              return t("DateValidation");
                                            }
                                          },
                                        },
                                      }
                                    )}
                                  />
                                  {errors.houseHoldList?.[index]?.birthDate && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.houseHoldList?.[index]?.birthDate
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("placeholderFirstName")}
                                    {...register(
                                      `houseHoldList.${index}.firstName`,
                                      {
                                        required: t("RequiredValidation"),
                                      }
                                    )}
                                  />
                                  {errors.houseHoldList?.[index]?.firstName && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.houseHoldList?.[index]?.firstName
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={t("placeholderLastName")}
                                    {...register(
                                      `houseHoldList.${index}.lastName`,
                                      {
                                        required: t("RequiredValidation"),
                                      }
                                    )}
                                  />
                                  {errors.houseHoldList?.[index]?.lastName && (
                                    <span role="alert" className="error_text">
                                      {
                                        errors.houseHoldList?.[index]?.lastName
                                          .message
                                      }
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <Button
                                    className="theme_btn"
                                    onClick={() => handleAddAnother(index)}
                                  >
                                    {t("AddIncomeBtn")}
                                  </Button>
                                </td>
                                <td>
                                  {index === 0 ? (
                                    <Button
                                      className="theme_btn"
                                      // onClick={addRow}
                                      onClick={() => {
                                        append1({
                                          birthDate: "",
                                          firstName: "",
                                          lastName: "",
                                          isStudent: false,
                                          grade: "",
                                          fosterChild: "",
                                          actualIncome: "",
                                          paymentCycle: "",
                                          studentType: "",
                                          country: "USA",
                                          removable: true,
                                          houseHoldIncomeSub: [
                                            {
                                              incomeSource: "",
                                              incomeAmount: "",
                                              paymentCycle: "",
                                            },
                                          ],
                                        });
                                      }}
                                    >
                                      <AiOutlinePlus className="icon" />
                                    </Button>
                                  ) : item?.removable ? (
                                    <Button
                                      className="cancel_btn"
                                      onClick={() => {
                                        handleRemove1(index);
                                      }}
                                    >
                                      <AiOutlineClose className="icon" />
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                              <>
                                {item?.houseHoldIncomeSub?.length > 0 && (
                                  <React.Fragment>
                                    <tr
                                      style={{
                                        backgroundColor: "#d4eed6",
                                      }}
                                    >
                                      <td></td>
                                      <td>{t("TableHead11")}</td>
                                      <td>{t("TableHead8")}</td>
                                      <td>{t("TableHead9")}</td>
                                      <td></td>
                                    </tr>
                                    {item?.houseHoldIncomeSub?.map(
                                      (_, index1) => (
                                        <tr key={index1}>
                                          <td></td>
                                          <td>
                                            <select
                                              name=""
                                              className="form-select"
                                              {...register(
                                                `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeSource`
                                                // {
                                                //   required:
                                                //     t("RequiredValidation"),
                                                // }
                                              )}
                                            >
                                              <option value="">
                                                {t("SeIn")}
                                              </option>
                                              <option value="Work">
                                                {t("Work")}
                                              </option>
                                              <option value="Public Assistance">
                                                {t("PublicAssistance")}
                                              </option>
                                              <option value="Child Support">
                                                {t("ChildSupport")}
                                              </option>
                                              <option value="Alimony">
                                                {t("Alimony")}
                                              </option>
                                              <option value="Pension">
                                                {t("Pension")}
                                              </option>
                                              <option value="Retirment">
                                                {t("Retirment")}
                                              </option>
                                              <option value="Social Security">
                                                {t("SocialSecurity")}
                                              </option>
                                              <option value="SSI">
                                                {t("SSI")}
                                              </option>
                                              <option value="VA Benefits">
                                                {t("VABenefits")}
                                              </option>{" "}
                                              <option value="Other">
                                                {t("Other")}
                                              </option>
                                            </select>

                                            {errors.houseHoldList?.[index]
                                              ?.houseHoldIncomeSub?.[index1]
                                              ?.incomeSource && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.houseHoldList?.[index]
                                                    ?.houseHoldIncomeSub?.[
                                                    index1
                                                  ]?.incomeSource.message
                                                }
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder={t(
                                                "placeholderActualIncome"
                                              )}
                                              {...register(
                                                `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeAmount`,
                                                {
                                                  valueAsNumber: true,
                                                  required: t(
                                                    "RequiredValidation"
                                                  ),
                                                  onChange: () =>
                                                    handleActualIncome1(),
                                                  validate: {
                                                    isValidNumber: (value) => {
                                                      let v = /^\d+(\.\d{1,2})?$/;
                                                      if (!v.test(value)) {
                                                        return t(
                                                          "EnterActualIncome"
                                                        );
                                                      }
                                                    },
                                                  },
                                                }
                                              )}
                                            />
                                            {errors.houseHoldList?.[index]
                                              ?.houseHoldIncomeSub?.[index1]
                                              ?.incomeAmount && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.houseHoldList?.[index]
                                                    ?.houseHoldIncomeSub?.[
                                                    index1
                                                  ]?.incomeAmount.message
                                                }
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            <select
                                              name=""
                                              className="form-select"
                                              {...register(
                                                `houseHoldList.${index}.houseHoldIncomeSub.${index1}.paymentCycle`,
                                                {
                                                  // required: t("RequiredValidation"),
                                                  onChange: () =>
                                                    handleActualIncome1(),
                                                  validate: (val) => {
                                                    if (
                                                      watch(
                                                        `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeAmount`
                                                      ) > 0 &&
                                                      val === ""
                                                    ) {
                                                      return t(
                                                        "RequiredValidation"
                                                      );
                                                    }
                                                  },
                                                }
                                              )}
                                            >
                                              <option value="">
                                                {t("PayCycleDrop")}
                                              </option>
                                              <option value="Annual">
                                                {t("PayCycleDropVal1")}
                                              </option>
                                              <option value="Monthly">
                                                {t("PayCycleDropVal2")}
                                              </option>
                                              <option value="TwicePerMonth">
                                                {t("PayCycleDropVal3")}
                                              </option>
                                              <option value="EveryTwoWeeks">
                                                {t("PayCycleDropVal4")}
                                              </option>
                                              <option value="Weekly">
                                                {t("PayCycleDropVal5")}
                                              </option>
                                            </select>
                                            {errors.houseHoldList?.[index]
                                              ?.houseHoldIncomeSub?.[index1]
                                              ?.paymentCycle && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.houseHoldList?.[index]
                                                    ?.houseHoldIncomeSub?.[
                                                    index1
                                                  ]?.paymentCycle.message
                                                }
                                              </span>
                                            )}
                                          </td>
                                          <td>
                                            {index1 === 0 ? (
                                              ""
                                            ) : (
                                              <Button
                                                className="cancel_btn"
                                                onClick={() => {
                                                  handleRemove2(index1, index);
                                                }}
                                              >
                                                <AiOutlineClose className="icon" />
                                              </Button>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </React.Fragment>
                                )}
                              </>
                            </React.Fragment>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                  <h6>{t("Part3b")}</h6>
                  <div className="row my-3">
                    <div className="col-md-4 input_div">
                      <label>{t("TotalChildAnnual")}</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={""}
                        placeholder={t("TotalChildAnnualPlaceholder")}
                        {...register(`totalChildIncome`, {
                          // required: t("RequiredValidation"),
                          valueAsNumber: true,
                          onChange: () => handleActualIncome1(),
                          // validate: {
                          //   isValidNumber: (value) => {
                          //     let v = /^\d+(\.\d{1,2})?$/;
                          //     if (!v.test(value)) {
                          //       return t("EnterActualIncome");
                          //     }
                          //   },
                          // },
                        })}
                      />
                      {errors.totalChildIncome && (
                        <span role="alert" className="error_text">
                          {errors.totalChildIncome.message}
                        </span>
                      )}
                    </div>{" "}
                    <div className="col-md-4 input_div">
                      <label>{t("ChildPaymentCycleLabel")}</label>
                      <select
                        name=""
                        className="form-select"
                        {...register("childPaymentCycle", {
                          // required: t("RequiredValidation"),
                          onChange: () => handleActualIncome1(),
                          validate: (val) => {
                            if (watch(`totalChildIncome`) > 0 && val === "") {
                              return t("RequiredValidation");
                            }
                          },
                        })}
                      >
                        <option value="">{t("ChildPaymentCycleDrop1")}</option>
                        <option value="Annual">{t("PaymentCycleDrop2")}</option>
                        <option value="Monthly">
                          {t("PaymentCycleDrop3")}
                        </option>
                        <option value="TwicePerMonth">
                          {t("PaymentCycleDrop4")}
                        </option>
                        <option value="EveryTwoWeeks">
                          {t("PaymentCycleDrop5")}
                        </option>
                        <option value="Weekly">{t("PaymentCycleDrop6")}</option>
                      </select>
                      {errors.childPaymentCycle && (
                        <span role="alert" className="error_text">
                          {errors.childPaymentCycle.message}
                        </span>
                      )}
                    </div>
                  </div>
                </>
              )}
              <h4 className="text-center text-bg-light py-2">
                {t("FormHeading")}
              </h4>

              <div className="row form mb-3">
                <div className="col-md-4 input_div">
                  <label>{t("HhSize")}</label>
                  <input
                    type="text"
                    disabled={
                      getValues("surveyBenifitField") === "TANF" ||
                      getValues("surveyBenifitField") === "FDPIR" ||
                      getValues("surveyBenifitField") === "SNAP"
                        ? false
                        : true
                    }
                    className="form-control"
                    placeholder={t("HhSizePlaceholder")}
                    {...register(`NoOfPeoeple`, {
                      required: t("RequiredValidation"),
                    })}
                  />
                  {errors.NoOfPeoeple && (
                    <span role="alert" className="error_text">
                      {errors.NoOfPeoeple.message}
                    </span>
                  )}
                </div>{" "}
                <div className="col-md-4 input_div">
                  <label>{t("TotalAnnual")}</label>
                  <input
                    type="text"
                    disabled={true}
                    className="form-control"
                    placeholder={t("TotalAnnualPlaceholder")}
                    {...register(`totalActualIncome`, {
                      // required: t("RequiredValidation"),
                    })}
                  />
                  {errors.totalActualIncome && (
                    <span role="alert" className="error_text">
                      {errors.totalActualIncome.message}
                    </span>
                  )}
                </div>{" "}
                <div className="col-md-4 input_div">
                  <label>{t("PaymentCycleLabel")}</label>
                  <select
                    name=""
                    disabled={true}
                    className="form-select"
                    {...register("paymentCycle", {})}
                  >
                    <option value="">{t("PaymentCycleDrop1")}</option>
                    <option value="Annual">{t("PaymentCycleDrop2")}</option>
                    <option value="Monthly">{t("PaymentCycleDrop3")}</option>
                    <option value="TwicePerMonth">
                      {t("PaymentCycleDrop4")}
                    </option>
                    <option value="EveryTwoWeeks">
                      {t("PaymentCycleDrop5")}
                    </option>
                    <option value="Weekly">{t("PaymentCycleDrop6")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="partD">
              <h5>
                <b>{t("PartDTitle")}</b>
              </h5>
              <p>{t("PartDDesc")}</p>
              <div className="row form">
                <div className="col-md-4 input_div">
                  <label>{t("ParentFirstName")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("ParentFirstNamePlaceholder")}
                    {...register(`firstName`, {
                      required: t("RequiredValidation"),
                    })}
                  />
                  {errors.firstName && (
                    <span role="alert" className="error_text">
                      {errors.firstName.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 input_div">
                  <label>* {t("TableHead5")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("placeholderLastName")}
                    {...register(`fullName`, {
                      required: t("RequiredValidation"),
                    })}
                  />
                  {errors.fullName && (
                    <span role="alert" className="error_text">
                      {errors.fullName.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 mt-4">
                  <label className=" personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      {...register(`isSSN`, {
                        onChange: (e) =>
                          handleChoise(e.target.checked, e.target.checked),
                      })}
                    />
                    <span className="checkmark"></span>
                    {t("NoSSN")}
                  </label>
                  {errors.isSSN && (
                    <span role="alert" className="error_text">
                      {errors.isSSN.message}
                    </span>
                  )}{" "}
                </div>
                {watch("isSSN") === true ? (
                  ""
                ) : (
                  <div className="col-md-4 input_div">
                    <label>{t("SSNNumber")}</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={t("placeholderSSNNumber")}
                      {...register(`ssnNumber`, {
                        required: t("RequiredValidation"),
                        maxLength: {
                          value: 4,
                          message: t("SSNNumberValidation"),
                        },
                        minLength: {
                          value: 4,
                          message: t("SSNNumberValidation"),
                        },
                      })}
                    />
                    {errors.ssnNumber && (
                      <span role="alert" className="error_text">
                        {errors.ssnNumber.message}
                      </span>
                    )}
                  </div>
                )}
                <div className="col-md-4 input_div">
                  <label>{t("ParentAddress")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("ParentAddressPlaceholder")}
                    {...register(`address`, {
                      required: t("RequiredValidation"),
                    })}
                  />
                  {errors.address && (
                    <span role="alert" className="error_text">
                      {errors.address.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 input_div">
                  <label>{t("ParentAPTNumber")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("ParentAPTPlaceholder")}
                    {...register(`apartment`, {
                      // required: t("RequiredValidation"),
                    })}
                  />
                  {errors.apartment && (
                    <span role="alert" className="error_text">
                      {errors.apartment.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 input_div">
                  <label>{t("ParentHomeMobile")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("ParentHomeMobilePlaceholder")}
                    {...register(`phone`, {
                      required: t("RequiredValidation"),
                      pattern: {
                        value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                        message: t("ValidPhone"),
                      },
                    })}
                  />
                  {errors.phone && (
                    <span role="alert" className="error_text">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 input_div">
                  <label>{t("ParentCity")}</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("ParentCityPlaceholder")}
                    {...register(`city`, {
                      required: t("RequiredValidation"),
                    })}
                  />
                  {errors.city && (
                    <span role="alert" className="error_text">
                      {errors.city.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 input_div">
                  <label>{t("ParentState")}</label>
                  <select
                    name=""
                    className="form-select"
                    {...register("state", {
                      required: t("RequiredValidation"),
                      valueAsNumber: true,
                    })}
                  >
                    <option value="">{t("ParentStatePlaceholder")}</option>
                    {stateList &&
                      stateList?.map((data, i) => (
                        <option key={i} value={data?.stateId}>
                          {data?.stateName}
                        </option>
                      ))}
                  </select>
                  {errors.state && (
                    <span role="alert" className="error_text">
                      {errors.state.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 input_div">
                  <label> {t("ParentEmail")}</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t("ParentEmailPlaceholder")}
                    {...register(`emailConfirmationAddress`, {
                      // required: "This field is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("EmailFormat"),
                      },
                    })}
                  />
                  {errors.emailConfirmationAddress && (
                    <span role="alert" className="error_text">
                      {errors.emailConfirmationAddress.message}
                    </span>
                  )}
                </div>
                <div className="col-md-4 input_div mt-2">
                  <label></label>
                  <label className=" personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      {...register(`isSummerEBT`, {
                        // required: t("RequiredValidation"),
                      })}
                    />
                    <span className="checkmark"></span>
                    {t("CheckBoxForCorrectInfo1")}
                  </label>
                  {errors.isSummerEBT && (
                    <span role="alert" className="error_text">
                      {errors.isSummerEBT.message}
                    </span>
                  )}{" "}
                </div>
                <div className="my-4">
                  <h5>{t("OptionalTitle")}</h5>
                  <p>{t("OptionalDesc")}</p>
                  <div>
                    <div className="row mb-2">
                      <div className="col-md-2">
                        <label>{t("EthnicityLabel")}</label>
                      </div>
                      <div className="col-md-6">
                        <label className="personal_detail_label">
                          <input
                            type="radio"
                            className="login_check"
                            name="isHispanic"
                            id="hispanic"
                            value={true}
                            {...register(`isHispanic`, {
                              // required: t("RequiredValidation"),
                              onChange: (e) =>
                                handleChoise(
                                  e.target.checked,
                                  e.target.checked
                                ),
                            })}
                          />
                          <span className="checkmark"></span>
                          {t("Hispa1")}
                        </label>
                        {errors.isHispanic && (
                          <span role="alert" className="error_text">
                            {errors.isHispanic.message}
                          </span>
                        )}
                      </div>

                      <div className="col-md-4">
                        <label className="personal_detail_label">
                          <input
                            type="radio"
                            className="login_check"
                            name="isHispanic"
                            id="notHispanic"
                            value={false}
                            {...register(`isHispanic`, {
                              // required: t("RequiredValidation"),
                              onChange: (e) =>
                                handleChoise(
                                  e.target.checked,
                                  e.target.checked
                                ),
                            })}
                          />
                          <span className="checkmark"></span>
                          {t("NotHispa")}
                        </label>
                        {errors.isHispanic && (
                          <span role="alert" className="error_text">
                            {errors.isHispanic.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-2">
                        <label>{t("RaceLabel")}</label>
                      </div>
                      <div className="col-md-2">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            value={"isAmericanIndianAlaskaNative"}
                            {...register(`isRace`, {
                              // required: t("RequiredValidation"),
                              onChange: (e) =>
                                handleChoise(
                                  e.target.checked,
                                  e.target.checked
                                ),
                            })}
                          />
                          <span className="checkmark"></span>
                          {t("fisrtRace")}
                        </label>
                        {errors.isRace && (
                          <span role="alert" className="error_text">
                            {errors.isRace.message}
                          </span>
                        )}{" "}
                      </div>
                      <div className="col-md-2">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            value={"isAsian"}
                            name="Remember Me"
                            {...register(`isRace`, {
                              // required: t("RequiredValidation"),
                              onChange: (e) =>
                                handleChoise(
                                  e.target.checked,
                                  e.target.checked
                                ),
                            })}
                          />
                          <span className="checkmark"></span>
                          {t("SecondRace")}
                        </label>
                        {errors.isRace && (
                          <span role="alert" className="error_text">
                            {errors.isRace.message}
                          </span>
                        )}{" "}
                      </div>
                      <div className="col-md-2">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            value={"isBlackAfricanAmerican"}
                            {...register(`isRace`, {
                              // required: t("RequiredValidation"),
                              onChange: (e) =>
                                handleChoise(
                                  e.target.checked,
                                  e.target.checked
                                ),
                            })}
                          />
                          <span className="checkmark"></span>
                          {t("thirdRace")}
                        </label>
                        {errors.isRace && (
                          <span role="alert" className="error_text">
                            {errors.isRace.message}
                          </span>
                        )}{" "}
                      </div>
                      <div className="col-md-2">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            value={"isNativeHawaiianOtherPacificIslande"}
                            {...register(`isRace`, {
                              // required: t("RequiredValidation"),
                              onChange: (e) =>
                                handleChoise(
                                  e.target.checked,
                                  e.target.checked
                                ),
                            })}
                          />
                          <span className="checkmark"></span>
                          {t("fourRace")}
                        </label>
                        {errors.isRace && (
                          <span role="alert" className="error_text">
                            {errors.isRace.message}
                          </span>
                        )}{" "}
                      </div>
                      <div className="col-md-2">
                        <label className=" personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            value={"isWhite"}
                            {...register(`isRace`, {
                              // required: t("RequiredValidation"),
                              onChange: (e) =>
                                handleChoise(
                                  e.target.checked,
                                  e.target.checked
                                ),
                            })}
                          />
                          <span className="checkmark"></span>
                          {t("whiteName")}
                        </label>
                        {errors.isRace && (
                          <span role="alert" className="error_text">
                            {errors.isRace.message}
                          </span>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  <label className=" personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      {...register(`isOtherStateEbtbenifit`, {
                        required: t("RequiredValidation"),
                        onChange: (e) =>
                          handleChoise(e.target.checked, e.target.checked),
                      })}
                    />
                    <span className="checkmark"></span>
                    {t("CheckBoxForCorrectInfo")}
                  </label>
                  {errors.isOtherStateEbtbenifit && (
                    <span role="alert" className="error_text">
                      {errors.isOtherStateEbtbenifit.message}
                    </span>
                  )}{" "}
                </div>
                <div className="col-md-9">
                  <label className=" personal_detail_label">
                    <input
                      type="checkbox"
                      className="login_check"
                      name="Remember Me"
                      {...register(`sign`, {
                        required: t("RequiredValidation"),
                        onChange: (e) =>
                          handleChoise(e.target.checked, e.target.checked),
                      })}
                    />
                    <span className="checkmark"></span>
                    {t("FirstCheckBox")}
                  </label>
                  {errors.sign && (
                    <span role="alert" className="error_text">
                      {errors.sign.message}
                    </span>
                  )}{" "}
                </div>
                {watch("sign") ? (
                  <div className="col-md-4 input-div mt-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("CheckBoxForCorrectInfoAfterShowBox")}
                      {...register(`signatureName`, {
                        required: t("RequiredValidation"),
                      })}
                    />
                    {errors.signatureName && (
                      <span role="alert" className="error_text">
                        {errors.signatureName.message}
                      </span>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-3 text-end">
              {openCircle ? (
                <Button disabled className="theme_btn">
                  {/* {t("SubmitBtn")} */}
                  <CircularProgress
                    sx={{
                      color: "#fff",
                      marginLeft: "0.5rem",
                      height: "23px !important",
                      width: "23px !important",
                    }}
                  />
                </Button>
              ) : (
                <Button type="submit" className="theme_btn">
                  {t("SubmitBtn")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </>
    );
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {payDetail ? (
          <section className="payment_success">
            <div className="main_wrapper">
              <div className="payment_inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="success_message_box">
                      <BsFillPatchCheckFill className="icon" />
                      <div>
                        <h1 className="m-0">
                          Your Survey Submitted to Server.
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="paymentAndUserInfo d-flex mt-4 justify-content-center gap-3">
                  <div className="text-center">
                    <Button
                      className="cancel_btn mt-3"
                      onClick={() => window.location.reload()}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="HouseHoldMemberInformation">
            <div className="card">
              <div className="card-body">
                {firstFormData?.templates?.description?.length > 0 ? (
                  <>
                    {!agree && (
                      <div className="form_guideline">
                        <div className="image_with_dropdow">
                          {firstFormData?.templates?.logo ? (
                            <img
                              src={`${Config.API_HOST_URL_live}/${firstFormData?.templates?.logo}`}
                              height={150}
                              width={150}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = NotFound;
                              }}
                              className="img-fluid"
                              alt=""
                            />
                          ) : (
                            <div></div>
                          )}
                          <div className="align-items-end col-md-3 d-flex flex-column">
                            <Tooltip title="Go to Form" placement="top" open={true} className="blink-tooltip" arrow>
                              <Button
                                className="blink"
                                onClick={scrollToBottom}
                              >
                                <FaChevronDown />
                              </Button>
                            </Tooltip>
                            <div className="form-group w-100">
                              <label>Language</label>
                              <select
                                name=""
                                className="form-select"
                                onChange={(e) =>
                                  getDistrictSurveyTemplate(e?.target?.value)
                                }
                              >
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                                <option value="Spanish">Spanish</option>
                                <option value="Chinese">Chinese</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div
                          dangerouslySetInnerHTML={{
                            __html: firstFormData?.templates?.description,
                          }}
                        />

                        <div className="col-md-12 d-flex justify-content-center">
                          <Button
                            className="theme_btn"
                            onClick={() => setAgree(true)}
                          >
                            Agree & Continue to Survey
                          </Button>
                        </div>
                      </div>
                    )}
                  </>
                ) : directForm ? (
                  <ManageForm
                    setOpenCircle={setOpenCircle}
                    payDetail={payDetail}
                    setPayDetail={setPayDetail}
                    Lang={Lang}
                  />
                ) : (
                  ""
                )}
                <>
                  {agree ? (
                    <>
                      <ManageForm
                        setOpenCircle={setOpenCircle}
                        payDetail={payDetail}
                        setPayDetail={setPayDetail}
                        Lang={Lang}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              </div>
            </div>
          </section>
        )}
      </Suspense>
    </>
  );
}

export default HouseHoldMemberInformation;
