import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDownloadExcel } from "react-export-table-to-excel";
import jsPDF from "jspdf";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Config } from "../../../../../Utils/Config";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import filter from "../../../../../Assets/PngImage/filter.png";
import Select from "react-select";
import { BiSolidMinusSquare, BiSolidPlusSquare } from "react-icons/bi";
import { Link } from "react-router-dom";
import { MdEdit } from "react-icons/md";
import DeletePopup from "../../../../../Components/Reusable/DeletePopup";
import EditReport from "./EditReport";
import { AcademicYearListApi } from "../../../../../Action/AcademicYear";
// import CancelPopup from "../../../../../Components/Reusable/CancelPopup";
// import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transperent",
  },
  "&.pdf_download": {
    fontSize: "9px !important",
    padding: "3px !important",
    lineHeight: "inherit",
    ".MuiTableSortLabel-root": {
      ".MuiSvgIcon-root": { display: "none " },
    },
  },
}));

const sx = {
  backgroundColor: '#e4e4e4',
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0,
  // },
  // "&.pdf_download": {
  //   borderTop: "0px",
  //   borderBottom: "0.5px",
  // },
}));

function CheckReport() {
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [prdList, setPrdList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [openCircleNew, setOpenCircleNew] = useState(false);
  const [reportDetails, setReportDetails] = useState();
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [derror, setDerror] = useState();
  const [collapse, setCollapse] = useState(false);
  const [selected, setSelected] = useState();
  const [dataSend, setDataSend] = useState();
  const [open, setOpen] = useState(false);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [servingList, setServingList] = useState([]);
  const [userList, setUserList] = useState();
  const [overDe, setOverDe] = useState();
  const [singleId, setSingleId] = useState();
  const [schoolYearList, setSchoolYearList] = useState([]);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCloseView = () => {
    setCollapse(false);
    setSelected();
  };

  const handleOpenView = (id) => {
    setCollapse(true);
    setSelected(id);
    if (id) {
      if (selected === id) {
        setCollapse(false);
      }
    }
  }
  useEffect(() => {
    getDistrictList();
    getAcademicYear();
    // eslint-disable-next-line
  }, [])

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  const getAcademicYear = async () => {
    let resp = await AcademicYearListApi();
    if (resp?.length > 0) {
      setSchoolYearList(resp);
    }
  }

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistrictList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getSchoolList = async (id) => {
    // setPrdList([]);
    setSchoolList([]);
    setSchoolSelect([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          let uslist = [{ value: "all", label: "Select All" }, ...dlist];

          setSchoolList(uslist?.length > 1 ? uslist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };

  const onSubmit = async (data) => {
    setPrdList([]);
    let is_success = true;
    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }
    if (is_success) {
      setDerror({ err: "" });
      setOpenCircle(true);
      let sendData = {
        ...data,
        schoolDistrictId: data?.schoolDistrictId
          ? data?.schoolDistrictId
          : null,
        schoolId: schoolSelect?.map((x) => x.value),

        startDate: data?.startDate ? data?.startDate : null,
        endDate: data?.endDate ? data?.endDate : null,
        userId: LogDetail?.userId,
      };
      setDataSend(sendData)
      let distName = districtList?.filter(
        (x) => x.value === sendData?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();

      let sdate = data?.startDate ? data?.startDate?.toISOString() : null;
      let edate = data?.endDate ? data?.endDate?.toISOString() : null;
      let reData = {
        startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
        endDate: edate ? convertDate(edate?.split("T")[0]) : null,
        distName: distName,
        schName: schName,
        mealService: data?.mealService,
      };
      setReportDetails(reData);
      let seData = {
        url: `/api/Report/GetCheckReportData`,
        body: sendData,
        headers: headers,
      };
      let res = await PostCallApi(seData);

      setOpenCircle(false);

      if (res?.status === 200) {
        if (res.data.success) {
          const SdData = res.data.payload;
          setPrdList(SdData);
          setOpenCircle(false);
          setSelected()
        } else {
          setPrdList([]);
          setOpenCircle(false);
        }
      }
    }
  };

  const [downloadPdf, setDownloadpdf] = useState(false);

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Edit Check Report",
    sheet: "Meal",
  });

  useEffect(() => {
    if (downloadExcel) {
      onDownload()
      setDownloadExcel(false)
    }
    // eslint-disable-next-line
  }, [downloadExcel])

  let Time = new Date();

  const back = {
    maxWidth: `${downloadPdf ? "200mm" : "100%"}`,
    // width: "100%",
    // height: "100%",
    // position: "relative",
    // margin: "0",
  };

  const handleDownloadPdf = async () => {
    const element = tableRef.current;
    let newPdfDate = Time.toLocaleString();
    // return false;
    setOpenCircleNew(true);
    const doc = new jsPDF({ unit: "px", format: "letter", userUnit: "px" });
    doc
      .html(element, {
        margin: [20, 10, 20, 10],
        autoPaging: "text",
        html2canvas: { scale: 0.57 },
      })
      .then(() => {
        let totalPages = doc.internal.pages.length - 1;
        for (let i = 1; i <= totalPages; i++) {
          doc.setPage(i);
          doc.setFontSize(10);
          doc.setTextColor(150);
          doc.text(
            newPdfDate,
            doc.internal.pageSize.width / 2,
            doc.internal.pageSize.getHeight() - 10,
            {
              align: "center",
            }
          );
        }
        doc.save(`Check Report.pdf`);
        setDownloadpdf(false);
        setOpenCircleNew(false);
      });
  };


  useEffect(() => {
    if (downloadPdf) {
      handleDownloadPdf();
    }
    // eslint-disable-next-line
  }, [downloadPdf]);


  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };

  const handleOpen = (bId) => {
    if (bId) {
      setOpen(true);
      setSingleId(bId)
    } else {
      setOpen(true)
    }
  };

  const GetServingList = async (district, sch) => {
    if (district && sch) {
      let seData = {
        url: `/api/ServingLine/GetServingLineDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userId=${LogDetail?.userId}`,
        headers: headers
      }
      const res = await GetCallApi(seData)
      if (res?.status === 200) {
        if (res.data.success) {
          const SdData = res.data.payload;
          setServingList(SdData);
        }
      }
    } else {
      setServingList([]);
    }
  };

  const GetCashierList = async (district, sch) => {
    setUserList([]);
    if (district && sch) {
      let apiData = {
        url: `/api/User/GetUserDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userType=Cashier`,
        headers: headers
      }
      const resp = await GetCallApi(apiData)
      if (resp?.status === 200) {
        if (resp.data.success) {
          const SdData = resp.data.payload?.map((sd, i) => {
            return {
              name: sd?.name,
              userId: sd?.userId,
            };
          });
          setUserList(SdData?.length > 0 ? SdData : []);
        } else {
          setUserList([]);
        }
      }
    } else {
      setUserList([]);
    }
  };

  // const showReadMore = (title, des) => {
  //   Swal.fire({
  //     position: "center",
  //     width: "50%",
  //     // icon: "success",
  //     title: title,
  //     text: des,
  //     confirmButtonText: "Close",
  //     confirmButtonColor: "#E64942",
  //   });
  // };

  const handleNewTab = (sDate) => {
    setTimeout(() => {
      localStorage.setItem('allD', JSON.stringify({ ...dataSend, school: schoolSelect, serDate: sDate }));
    }, 0);
    window.open('/reports/transaction-log', "_blank");
  }

  return (
    <>
      <Backdrop
        open={openCircleNew}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="checkReportPage">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <h4 className="all_title mb-4">Edit Check </h4>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {districtList &&
                            districtList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                              selected.length &&
                              selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                                ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                                : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />

                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                        {/* <select
                          className="form-select"
                          {...register("schoolId", {
                            required: "This field is required",
                            // onChange: (e) =>
                            //   GetData(e.target.value),
                          })}
                        >
                          <option value="">Select School </option>
                          {schoolList &&
                            schoolList?.map((item, i) => (
                              <option key={i} value={item?.value}>
                                {item?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolId && (
                          <span role="alert" className="error_text">
                            {errors.schoolId.message}
                          </span>
                        )} */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Start Date
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("startDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.startDate && (
                          <span role="alert" className="error_text">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>End Date
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          {...register("endDate", {
                            required: "This field is required",
                            valueAsDate: true,
                          })}
                        />
                        {errors.endDate && (
                          <span role="alert" className="error_text">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Meal Service</label>
                        <select
                          className="form-select"
                          {...register("mealService", {
                            required: "This field is required",
                          })}
                        >
                          {/* <option value="All">All</option> */}
                          <option value="Breakfast">Breakfast</option>
                          <option value="Lunch">Lunch</option>
                          <option value="Dinner">Dinner</option>
                          <option value="Snacks">Snacks</option>
                        </select>
                        {errors.mealService && (
                          <span role="alert" className="error_text">
                            {errors.mealService.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School Year </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("academicYearId", {
                            valueAsNumber: true
                          })}
                        >
                          <option value="0">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.academicYearId && (
                          <span role="alert" className="error_text">
                            {errors.academicYearId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mt-auto text-end">
                      <div className="form-group">

                        {prdList?.allcountData?.length > 0 ? (
                          <>
                            <Button
                              className="cancel_btn me-3 text-end"
                              type="button"
                              onClick={() => setDownloadpdf(true)}
                            >
                              Pdf
                            </Button>
                            <Button
                              className="cancel_btn me-3 text-end"
                              type="button"
                              onClick={() => setDownloadExcel(true)}
                            >
                              Download
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img
                              src={filter}
                              className="me-1"
                              alt=""
                              height={23}
                            />
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <Paper className="table-box mt-3">
                  <TableContainer ref={tableRef} style={back}>
                    <div className="logo_img_pdf d-flex justify-content-between align-items-center mb-5">
                      <div className="d-flex">
                        <img
                          src={
                            downloadPdf
                              ? require("../../../../../Assets/PngImage/Header_logo_1.png")
                              : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
                          }
                          alt=""
                          width={100}
                          height={80}
                        />
                        <div className="ms-3">
                          <label
                            style={{
                              fontSize: "18px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            Edit Check Report{" "}
                          </label>
                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            {reportDetails?.mealService}
                          </div>
                          <div
                            className="names"
                            style={{
                              fontSize: "16px",
                              color: "#2c2e35",
                              fontWeight: 600,
                              marginBottom: "7px",
                            }}
                          >
                            {reportDetails?.startDate
                              ? reportDetails?.startDate
                              : ""}
                            {reportDetails?.endDate
                              ? " - " + reportDetails?.endDate
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          style={{
                            fontSize: "18px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                            width: "250px",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {reportDetails?.schName}
                        </label>
                        <div
                          className="names"
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          {reportDetails?.distName}
                        </div>
                        <div
                          className="names"
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                            marginBottom: "7px",
                          }}
                        >
                          {Time.toLocaleString()}
                        </div>
                        <div
                          className="names"
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                          }}
                        >
                          {LogDetail?.firstName + " " + LogDetail?.lastName}
                        </div>
                        <div
                          className="names"
                          style={{
                            fontSize: "16px",
                            color: "#2c2e35",
                            fontWeight: 600,
                          }}
                        >
                          {prdList?.avgAttendanceFactor ? (
                            <>
                              {" "}
                              Average Attendance Factor :{" "}
                              {prdList?.avgAttendanceFactor}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <Table
                      className={`mb-0 ${!downloadPdf && "table-bordered"}`}
                    >
                      {/* {i === 0 ? (
                      ) : (
                        ""
                      )} */}
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            rowSpan={2}
                            className={` ${downloadPdf && "pdf_download align-bottom"
                              }`}
                          >
                            Serving Date
                          </StyledTableCell>
                          {/* <StyledTableCell
                            rowSpan={2}
                            className={`${
                              downloadPdf && "pdf_download align-bottom"
                            }`}
                          >
                            Serving Period
                          </StyledTableCell> */}
                          <StyledTableCell
                            className={`text-center ${downloadPdf && "pdf_download"
                              }`}
                            colSpan={4}
                          >
                            Free
                          </StyledTableCell>
                          <StyledTableCell
                            className={`text-center ${downloadPdf && "pdf_download"
                              }`}
                            colSpan={4}
                          >
                            Reduced
                          </StyledTableCell>
                          <StyledTableCell
                            className={`text-center ${downloadPdf && "pdf_download"
                              }`}
                            colSpan={4}
                          >
                            Paid
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={4}
                            className={`${downloadPdf && "pdf_download align-bottom"
                              }`}
                          >
                            Total
                          </StyledTableCell>
                          {downloadPdf || downloadExcel ? "" :
                            <StyledTableCell
                              rowSpan={2}
                              className={`${downloadPdf && "pdf_download align-bottom"
                                }`}
                            >
                              Action
                            </StyledTableCell>
                          }
                        </TableRow>
                        <TableRow>
                          {[...Array(4).keys()]?.map((r, i) => (
                            <React.Fragment key={i}>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Claim
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Percentage %
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Exist
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Notes
                              </StyledTableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {prdList?.allcountData?.length > 0 ? (
                          <>
                            {prdList?.allcountData?.map((data, i) => (
                              // <>
                              <React.Fragment key={i}>
                                <StyledTableRow style={data.freeCounts?.percentage > 100 ? sx : data?.paidCounts?.percentage > 100 ? sx : data?.reducedCounts?.percentage > 100 ? sx : data?.total?.percentage > 100 ? sx : {}}>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {convertDate(data?.servingDate?.split("T")[0])}
                                  </StyledTableCell>
                                  {/* <StyledTableCell
                                  className={`${downloadPdf && "pdf_download"}`}
                                >
                                  {data?.servingPeriod}
                                </StyledTableCell> */}
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.freeCounts?.claim?.toLocaleString(undefined, undefined)}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.freeCounts?.percentage}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.freeCounts?.exist}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.freeCounts?.notes}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.reducedCounts?.claim?.toLocaleString(undefined, undefined)}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.reducedCounts?.percentage}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.reducedCounts?.exist}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.reducedCounts?.notes}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.paidCounts?.claim?.toLocaleString(undefined, undefined)}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.paidCounts?.percentage}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.paidCounts?.exist}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.paidCounts?.notes}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.total?.claim?.toLocaleString(undefined, undefined)}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.total?.percentage}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.total?.exist}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={`${downloadPdf && "pdf_download"}`}
                                  >
                                    {data?.total?.notes}
                                  </StyledTableCell>
                                  {downloadPdf || downloadExcel ? "" :
                                    <StyledTableCell
                                      className={`${downloadPdf && "pdf_download"}`}
                                    >
                                      {/* {data?.bulkEntryData?.length > 0 || data?.transactionLogData?.length > 0 ? */}
                                      <div className="d-flex">
                                        {selected === i + 1 ?
                                          <div
                                            style={{ cursor: "pointer" }}
                                            className="me-2"
                                            onClick={() => handleCloseView()}
                                          >
                                            <BiSolidMinusSquare
                                              fontSize={34}
                                              color="#d91f2d"
                                            />
                                          </div>
                                          :
                                          <div
                                            style={{ cursor: "pointer" }}
                                            className="me-2"
                                            onClick={() =>
                                              handleOpenView(i + 1)
                                            }
                                          >
                                            <BiSolidPlusSquare
                                              fontSize={34}
                                              color="#73c04d"
                                            />
                                          </div>
                                        }
                                        <Button
                                          style={{
                                            border: "1px solid #31b680",
                                            borderRadius: "4px",
                                            fontWeight: "400",
                                            fontSize: "15px",
                                            lineHeight: "6px",
                                            color: "#fff",
                                            textAlign: "center",
                                            padding: "12px 10px",
                                            textTransform: "unset",
                                            background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                                          }}
                                          onClick={() => handleNewTab(data?.servingDate)}
                                        >
                                          Transaction
                                        </Button>
                                      </div>
                                      {/* } */}
                                    </StyledTableCell>
                                  }
                                </StyledTableRow>

                                {
                                  downloadPdf || downloadExcel ?
                                    data?.bulkEntryData?.length > 0 ?
                                      <>
                                        <StyledTableRow>
                                          <StyledTableCell colSpan={17}>
                                            <label className="text-start"
                                              style={{
                                                fontSize: "14px",
                                                color: "#2c2e35",
                                                fontWeight: 600,
                                              }}
                                            >Bulk Entry</label>
                                            <Table
                                              className={`mb-0 ${!downloadPdf && "table-bordered"}`}
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <StyledTableCell
                                                    className={` ${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    School Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Cashier Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Price
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Meal Count
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Payment Status
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Entry Type
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Date
                                                  </StyledTableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {data?.bulkEntryData?.length > 0 ?
                                                  data?.bulkEntryData?.map((bulk, index) => (
                                                    <StyledTableRow key={index}>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.schoolName}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.cashierName}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.price?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.mealCount.toLocaleString(undefined, undefined)}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.paymentStatus}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.entryType}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {convertDate(bulk?.date?.split("T")[0])}
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  ))
                                                  :
                                                  <TableRow className="all_list">
                                                    <StyledTableCell
                                                      className="er_nodata m-0 p-1"
                                                      colSpan={17}
                                                      style={{
                                                        fontSize: "12px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      No Data Found
                                                    </StyledTableCell>
                                                  </TableRow>
                                                }
                                              </TableBody>
                                            </Table>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        {/* <StyledTableRow >
                                          <StyledTableCell colSpan={17}>
                                            <label className="text-start"
                                              style={{
                                                fontSize: "14px",
                                                color: "#2c2e35",
                                                fontWeight: 600,
                                              }}
                                            >Transaction Log</label>
                                            <Table
                                              className={`mb-0 ${!downloadPdf && "table-bordered"}`}
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <StyledTableCell
                                                    className={` ${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Student Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Meal Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Status
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Reimbursable Meal Qty
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Transaction Amount
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Serving Line
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Cashier
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Order Date
                                                  </StyledTableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {data?.transactionLogData?.length > 0 ?
                                                  data?.transactionLogData?.map((tra, ind) => (
                                                    <StyledTableRow key={ind}>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {tra?.studentName}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        <div className=""
                                                          style={{ width: "150px", wordBreak: "break-all" }}>
                                                          {tra?.mealTransactionlst
                                                            ?.map((ml, i) => ml?.mealName)
                                                            ?.toString()}
                                                        </div>
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {tra?.status}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {tra?.reimbursableMealQty?.toLocaleString(undefined, undefined)}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {tra?.transactionAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {tra?.servingLineName}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {tra?.cashierName}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {convertDate(tra?.orderDate?.split("T")[0])}
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  ))
                                                  :
                                                  <TableRow className="all_list">
                                                    <StyledTableCell
                                                      className="er_nodata m-0 p-1"
                                                      colSpan={17}
                                                      style={{
                                                        fontSize: "12px",
                                                        color: "#2c2e35",
                                                        fontWeight: 600,
                                                      }}
                                                    >
                                                      No Data Found
                                                    </StyledTableCell>
                                                  </TableRow>
                                                }
                                              </TableBody>
                                            </Table>
                                          </StyledTableCell>
                                        </StyledTableRow> */}
                                      </>
                                      : ""
                                    : collapse && selected === i + 1 ?
                                      <>
                                        <StyledTableRow>
                                          <StyledTableCell colSpan={18}>
                                            <h5 className="text-start">Bulk Entry</h5>
                                            <Table
                                              className={`mb-0 ${!downloadPdf && "table-bordered"}`}
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <StyledTableCell
                                                    colSpan={1}
                                                    className={` ${downloadPdf && "pdf_download align-bottom"
                                                      }`}
                                                  >
                                                    School Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`text-center ${downloadPdf && "pdf_download"
                                                      }`}
                                                    colSpan={1}
                                                  >
                                                    Cashier Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`text-center ${downloadPdf && "pdf_download"
                                                      }`}
                                                    colSpan={1}
                                                  >
                                                    Price
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`text-center ${downloadPdf && "pdf_download"
                                                      }`}
                                                    colSpan={1}
                                                  >
                                                    Meal Count
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    colSpan={1}
                                                    className={`${downloadPdf && "pdf_download align-bottom"
                                                      }`}
                                                  >
                                                    Payment Status
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    colSpan={1}
                                                    className={`${downloadPdf && "pdf_download align-bottom"
                                                      }`}
                                                  >
                                                    Entry Type
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    colSpan={1}
                                                    className={`${downloadPdf && "pdf_download align-bottom"
                                                      }`}
                                                  >
                                                    Date
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    rowSpan={1}
                                                    className={`${downloadPdf && "pdf_download align-bottom"
                                                      }`}
                                                  >
                                                    {data?.bulkEntryData?.length > 0 ?
                                                      "Action"
                                                      :
                                                      <Button
                                                        style={{
                                                          border: "1px solid #31b680",
                                                          borderRadius: "4px",
                                                          fontWeight: "400",
                                                          fontSize: "15px",
                                                          lineHeight: "6px",
                                                          color: "#fff",
                                                          textAlign: "center",
                                                          padding: "12px 10px",
                                                          textTransform: "unset",
                                                          background: "linear-gradient(90deg, #1EBEC8 0%, #86C65A 100%)",
                                                        }}
                                                        className="me-2"
                                                        onClick={() => handleOpen()}
                                                      >
                                                        Add
                                                      </Button>
                                                    }
                                                  </StyledTableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {data?.bulkEntryData?.length > 0 ?
                                                  data?.bulkEntryData?.map((bulk, index) => (
                                                    <StyledTableRow key={index}>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.schoolName}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.cashierName}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.price?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.mealCount.toLocaleString(undefined, undefined)}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.paymentStatus}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {bulk?.entryType}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        {convertDate(bulk?.date?.split("T")[0])}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        className={`${downloadPdf && "pdf_download"}`}
                                                      >
                                                        <>
                                                          <Link
                                                            type="button"
                                                            className="btn_edit me-2"
                                                            // to={`edit/${bulk?.bulkEntryId}`}
                                                            onClick={() => handleOpen(bulk?.bulkEntryId)}
                                                          >
                                                            <MdEdit fontSize={24} />
                                                          </Link>
                                                          <DeletePopup
                                                            title={"Delete Bulk Entry"}
                                                            text={bulk?.cashierName + "'s Bulk Entry"}
                                                            url={`/api/BulkMeal/DeleteBulkMealCountsData?bulkMealCountId=${bulk?.bulkEntryId}`}
                                                            CallBack={true}
                                                            CallBackAPi={() => onSubmit(dataSend)}
                                                          />
                                                        </>
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  ))
                                                  :
                                                  <TableRow className="all_list">
                                                    <StyledTableCell
                                                      className="er_nodata m-0 p-4"
                                                      colSpan={18}
                                                    >
                                                      No Data Found
                                                    </StyledTableCell>
                                                  </TableRow>
                                                }
                                              </TableBody>
                                            </Table>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                        {/* <StyledTableRow key={i}>
                                          <StyledTableCell colSpan={18}>
                                            <h5 className="text-start">Transaction Log</h5>
                                            <Table
                                              className={`mb-0 ${!downloadPdf && "table-bordered"}`}
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <StyledTableCell
                                                    className={` ${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Student Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Meal Name
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Status
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Reimbursable Meal Qty
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download"
                                                      }`}
                                                  >
                                                    Transaction Amount
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Serving Line
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Cashier
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    className={`${downloadPdf && "pdf_download "
                                                      }`}
                                                  >
                                                    Order Date
                                                  </StyledTableCell>
                                                  <StyledTableCell
                                                    rowSpan={1}
                                                    className={`${downloadPdf && "pdf_download align-bottom"
                                                      }`}
                                                  >
                                                    Action
                                                  </StyledTableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {data?.transactionLogData?.length > 0 ?
                                                  data?.transactionLogData?.map((tra, index) => {

                                                    let ml = tra?.mealTransactionlst?.map((ml) => ml?.mealName)
                                                    const mealName = ml?.toString();
                                                    const displayText = mealName.slice(0, 25);
                                                    const isLong = mealName.length > 25;

                                                    return (
                                                      <StyledTableRow key={index}>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {tra?.studentName}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          <div>
                                                            {displayText}
                                                            {isLong && (
                                                              <span
                                                                className="description cr"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => showReadMore("Meal Name", mealName)}
                                                              >
                                                                ...read more
                                                              </span>
                                                            )}
                                                          </div>
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {tra?.status}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {tra?.reimbursableMealQty?.toLocaleString(undefined, undefined)}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {tra?.transactionAmount?.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {tra?.servingLineName}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {tra?.cashierName}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {convertDate(tra?.orderDate?.split("T")[0])}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                          className={`${downloadPdf && "pdf_download"}`}
                                                        >
                                                          {tra?.status === "Complete" ? (
                                                            <CancelPopup
                                                              delteIcon={false}
                                                              color={true}
                                                              deletetext={"Void"}
                                                              title={"Cancel Student Meal"}
                                                              callapi={() => onSubmit(dataSend)}
                                                              text={tra?.studentName + " order"}
                                                              url={`/api/MealOrder/VoidMealOrderData?mealOrderId=${tra?.mealOrderId}&createdBy=${LogDetail?.userId}`}
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </StyledTableCell>
                                                      </StyledTableRow>
                                                    )
                                                  })
                                                  :
                                                  <TableRow className="all_list">
                                                    <StyledTableCell
                                                      className="er_nodata m-0 p-4"
                                                      colSpan={18}
                                                    >
                                                      No Data Found
                                                    </StyledTableCell>
                                                  </TableRow>
                                                }
                                              </TableBody>
                                            </Table>
                                          </StyledTableCell>
                                        </StyledTableRow> */}
                                      </>
                                      : ""
                                }

                              </React.Fragment>
                              // {/* {data?.studentLists?.length > 0 ? (
                              //   <StyledTableRow>
                              //     <StyledTableCell colSpan={15} className="p-0">
                              //       <Table
                              //         className="table table-bordered inner-table"
                              //         style={{ backgroundColor: "#f7f7f7" }}
                              //       >
                              //         <TableRow
                              //           className={`${
                              //             downloadPdf && "pdf_download"
                              //           }`}
                              //         >
                              //           {studentHeadList?.map((item, x) => (
                              //             <StyledTableCell
                              //               colSpan={5}
                              //               key={x}
                              //               className={`text-center fw-bold ${
                              //                 downloadPdf && "pdf_download"
                              //               }`}
                              //             >
                              //               {item?.label}
                              //             </StyledTableCell>
                              //           ))}
                              //         </TableRow>
                              //         {data?.studentLists?.map((item, s) => (
                              //           <StyledTableRow
                              //             key={s}
                              //             className={`${
                              //               downloadPdf && "pdf_download"
                              //             }`}
                              //           >
                              //             <StyledTableCell
                              //               colSpan={5}
                              //               className={`text-center
                              //               ${downloadPdf && "pdf_download"}`}
                              //             >
                              //               {item?.offsiteStudentName
                              //                 ? item?.offsiteStudentName
                              //                 : "No Data"}
                              //             </StyledTableCell>
                              //             <StyledTableCell
                              //               colSpan={5}
                              //               className={`text-center
                              //               ${downloadPdf && "pdf_download"}`}
                              //             >
                              //               {item?.enrolledSchool
                              //                 ? item?.enrolledSchool
                              //                 : "No Data"}
                              //             </StyledTableCell>
                              //             <StyledTableCell
                              //               colSpan={5}
                              //               className={`text-center
                              //               ${downloadPdf && "pdf_download"}`}
                              //             >
                              //               {item?.status
                              //                 ? item?.status
                              //                 : "No Data"}
                              //             </StyledTableCell>
                              //           </StyledTableRow>
                              //         ))}
                              //       </Table>
                              //     </StyledTableCell>
                              //   </StyledTableRow>
                              // ) : (
                              //   ""
                              // )} */}
                              // </>
                            ))}
                            <StyledTableRow>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Total
                              </StyledTableCell>
                              {/* <StyledTableCell
                                  className={`${downloadPdf && "pdf_download"}`}
                                >
                                  {data?.servingPeriod}
                                </StyledTableCell> */}
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalFreeCounts?.claim?.toLocaleString(undefined, undefined)}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalFreeCounts?.percentage}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalFreeCounts?.exist}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalFreeCounts?.notes}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalReducedCounts?.claim?.toLocaleString(undefined, undefined)}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalReducedCounts?.percentage}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalReducedCounts?.exist}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalReducedCounts?.notes}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalPaidCounts?.claim?.toLocaleString(undefined, undefined)}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalPaidCounts?.percentage}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalPaidCounts?.exist}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalPaidCounts?.notes}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalCounts?.claim?.toLocaleString(undefined, undefined)}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalCounts?.percentage}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalCounts?.exist}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                {prdList?.totalCounts?.notes}
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                              </StyledTableCell>
                            </StyledTableRow>
                          </>
                        ) : (
                          <TableRow className="all_list">
                            <StyledTableCell
                              className="er_nodata m-0 p-5"
                              colSpan={18}
                            >
                              No Data Found
                            </StyledTableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <div className="mt-5">
                      <label
                        style={{
                          fontSize: "18px",
                          color: "#2c2e35",
                          fontWeight: 600,
                          marginBottom: "7px",
                        }}
                      >
                        Summary for Reported month
                      </label>
                    </div>
                    <Table className={` ${!downloadPdf && "table-bordered"}`}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            rowSpan={2}
                            className={` ${downloadPdf && "pdf_download align-bottom"
                              }`}
                          >
                            Active Days
                          </StyledTableCell>
                          {/* <StyledTableCell
                            rowSpan={2}
                            className={`${
                              downloadPdf && "pdf_download align-bottom"
                            }`}
                          >
                            Serving Period
                          </StyledTableCell> */}
                          <StyledTableCell
                            className={`text-center ${downloadPdf && "pdf_download"
                              }`}
                            colSpan={3}
                          >
                            Free
                          </StyledTableCell>
                          <StyledTableCell
                            className={`text-center ${downloadPdf && "pdf_download"
                              }`}
                            colSpan={3}
                          >
                            Reduced
                          </StyledTableCell>
                          <StyledTableCell
                            className={`text-center ${downloadPdf && "pdf_download"
                              }`}
                            colSpan={3}
                          >
                            Paid
                          </StyledTableCell>
                          <StyledTableCell
                            colSpan={3}
                            className={`${downloadPdf && "pdf_download align-bottom"
                              }`}
                          >
                            Total
                          </StyledTableCell>
                        </TableRow>
                        <TableRow>
                          {[...Array(4).keys()]?.map((r, i) => (
                            <React.Fragment key={i}>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Avg Claim
                              </StyledTableCell>

                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Avg Exist
                              </StyledTableCell>
                              <StyledTableCell
                                className={`${downloadPdf && "pdf_download"}`}
                              >
                                Notes
                              </StyledTableCell>
                            </React.Fragment>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.activeDays}
                          </StyledTableCell>
                          {/* <StyledTableCell
                                  className={`${downloadPdf && "pdf_download"}`}
                                >
                                  {data?.servingPeriod}
                                </StyledTableCell> */}
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.freeAvg?.avgClaim?.toLocaleString(undefined, undefined)}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.freeAvg?.avgExist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.freeAvg?.notes}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.reducedAvg?.avgClaim?.toLocaleString(undefined, undefined)}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.reducedAvg?.avgExist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.reducedAvg?.notes}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.paidAvg?.avgClaim?.toLocaleString(undefined, undefined)}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.paidAvg?.avgExist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.paidAvg?.notes}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.totalAvg?.avgClaim?.toLocaleString(undefined, undefined)}
                          </StyledTableCell>

                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.totalAvg?.avgExist}
                          </StyledTableCell>
                          <StyledTableCell
                            className={`${downloadPdf && "pdf_download"}`}
                          >
                            {prdList?.totalAvg?.notes}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
          </div>
        </section>
        <EditReport
          dataSend={{ ...dataSend, date: prdList?.allcountData ? prdList?.allcountData[selected - 1]?.servingDate : "" }}
          setOpen={setOpen}
          open={open}
          overDe={overDe}
          servingList={servingList}
          userList={userList}
          setOverDe={setOverDe}
          GetServingList={GetServingList}
          GetCashierList={GetCashierList}
          onSubmit={onSubmit}
          setOpenCircle={setOpenCircle}
          singleId={singleId}
          setSingleId={setSingleId}
        />
      </Suspense>
    </>
  );
}

export default CheckReport;
