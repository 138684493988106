import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function BillReportDetailsTab(props) {
  const { tableRef,  startDate, endDate, back } = props;
  // const { tableRef, repoData, startDate, endDate, back } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const billingdetail = [
    {
      Bill: "TA-37-20230425-1",
      Table: "0 PAX : 0",
      Cashier: "Riyaz Ahmed",
      Date: "04/25 13:00",
    },
  ];

  const reporttable = [
    {
      Item: "Egg(1)",
      Title: "Riyaz Ahmed",
      Time: "13:00",
      QTY: "1",
      Price: "$2",
    },
    {
      Item: "Beef Chori",
      Title: "Riyaz Ahmed",
      Time: "13:00",
      QTY: "1",
      Price: "$4",
    },
    {
      Item: "zo (1)",
      Title: "",
      Time: "",
      QTY: "",
      Price: "",
    },
    {},
    {
      Item: "Beef Chori",
      Title: "Riyaz Ahmed",
      Time: "13:00",
      QTY: "1",
      Price: "$4",
    },
    {
      Item: "zo (1)",
      Title: "",
      Time: "",
      QTY: "",
      Price: "",
    },
    {
      Item: "Beef Chori",
      Title: "Riyaz Ahmed",
      Time: "13:00",
      QTY: "1",
      Price: "$4",
    },
    {
      Item: "zo (1)",
      Title: "",
      Time: "",
      QTY: "",
      Price: "",
    },
    {
      Item: "Beef Chori",
      Title: "Riyaz Ahmed",
      Time: "13:00",
      QTY: "1",
      Price: "$4",
    },
  ];

  const billingtable = [
    {
      subtotal: "$16",
      discount: "$0",
      tax: "$1.45",
      receivedcash: "$635",
      changedcash: "$614",
      Date: "04/25  13:00",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <section className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Bill Report </h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase border-dash pb-3">Bill Report</h5>
        <div className="billing-details">
          {billingdetail?.map((text, i) => (
            <div key={i} disablePadding>
              <h6>Bill# : {text?.Bill}</h6>
              <h6>Table : {text?.Table}</h6>
              <h6>Cashier : {text?.Cashier}</h6>
              <h6>Date : {text?.Date}</h6>
            </div>
          ))}
        </div>
        <div className="report-table">
          {reporttable?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width">{text?.Item}</h6>
              <h6 className="title-width">{text?.Title}</h6>
              <h6 className="title-width">{text?.Time}</h6>
              <h6 className="title-width-bill">{text?.QTY}</h6>
              <h6 className="title-width">{text?.Price}</h6>
            </div>
          ))}

          <div className="billing-table">
            {billingtable?.map((text, i) => (
              <div key={i} disablePadding>
                <div className="d-flex justify-content-between">
                  <h6>Sub-total :</h6>
                  <h6>{text?.subtotal}</h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6>Discount :</h6>
                  <h6>{text?.discount}</h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6>Tax :</h6>
                  <h6>{text?.tax}</h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6>Received Cash :</h6>
                  <h6>{text?.receivedcash}</h6>
                </div>
                <div className="d-flex justify-content-between">
                  <h6>Changed Cash :</h6>
                  <h6>{text?.changedcash}</h6>
                </div>
                <h6>{text?.Date}</h6>
              </div>
            ))}
          </div>
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </section>
    </Suspense>
  );
}

export default BillReportDetailsTab;
