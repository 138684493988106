import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import "./index.scss";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import Select from "react-select";
import { Link } from "react-router-dom";
import { MdRemoveRedEye } from "react-icons/md";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  // { id: "cashier", label: "Cashier" },
  { id: "actualIncome", label: "Actual Income" },
  { id: "gradeID", label: "Grade" },
  { id: "isDC", label: "IsDC" },
  { id: "matchType", label: "Match Type" },
  { id: "noOfPeoeple", label: "No Of Peoeple" },
  { id: "academicYear", label: "Academic Year" },
  { id: "siteName", label: "Site Name" },
  { id: "siteCode", label: "Site Code" },
  { id: "studentID", label: "Student Id" },
  { id: "studentName", label: "Student Name" },
  { id: "familyCode", label: "Family Code" },
  { id: "paymentCycle", label: "Payment Cycle" },
  { id: "paymentStatus", label: "Payment Status" },
  { id: "parentName", label: "Parent Name" },
  { id: "parentPhone", label: "Phone" },
  { id: "parentEmail", label: "Email" },
  { id: "parentCompleteAddress", label: "Address", click: true },
  { id: "parentCity", label: "City" },
  { id: "parentState", label: "State" },
  { id: "parentZip", label: "Zip" },
  // { id: "submitStatus", label: "Status" },
];
function SurveyReport() {
  const [parentList, setParentList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [reportDetails, setReportDetails] = useState();
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolSelect, setSchoolSelect] = useState([]);
  const [derror, setDerror] = useState();
  const [schoolYearList, setSchoolYearList] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getDistrictList();
    getSchoolYear();
    // eslint-disable-next-line
  }, []);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                value: sd?.schoolDistrictId,
                label: sd?.districtName,
              };
            });
            setParentList(dlist?.length > 0 ? dlist : []);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = (id) => {
    setPaymentList([]);
    setSchoolList([]);
    setSchoolSelect([]);
    setValue("schoolId", "");
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const dlist = res.data.payload?.map((sd) => {
                return {
                  value: sd?.schoolId,
                  label: sd?.schoolName,
                };
              });
              let uslist = [{ value: "all", label: "Select All" }, ...dlist];

              setSchoolList(uslist?.length > 1 ? uslist : []);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };

  const getSchoolYear = async () => {
    let schApi = {
      url: `/api/SchoolAcademicYear/GetAllSchoolAcademicYearData`,
    };

    let resp = await GetCallApi(schApi);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        let allD = resp?.data?.payload?.map((x) => {
          return {
            value: x?.academicYearId,
            label: x?.academicYear,
          };
        });
        setSchoolYearList(allD);
      }
    }
  };
  const getPayment = async (data) => {
    setPaymentList([]);
    setReportDetails();
    let is_success = true;
    if (schoolSelect?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one school" });
    }
    if (is_success) {
      setDerror({ err: "" });
      setOpenCircle(true);
      let sendData = {
        ...data,
        schoolDistrictId: data?.schoolDistrictId
          ? data?.schoolDistrictId
          : null,
        schoolId: schoolSelect?.map((x) => x.value),
        userId: LogDetail?.userId,
      };
      let distName = parentList?.filter(
        (x) => x.value === sendData?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();

      let reData = {
        distName: distName,
        schName: schName,
      };
      let sdata = {
        url: `/api/Report/GetFamilyIncomeSurveyStatus`,
        body: sendData,
        headers: headers,
      };
      let res = await PostCallApi(sdata);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          reData = {
            ...reData,
            totalNotSubmittedCount: res.data.totalNotSubmittedCount,
            totalSubmittedCount: res.data.totalSubmittedCount,
          };
          setReportDetails(reData);

          const dList = res.data.payload?.map((x) => {
            return {
              ...x,
              isDC: x?.isDC ? "True" : "False",
              tlt: "Parent Address",
              mealList: x?.parentCompleteAddress,
              actualIncome: parseFloat(x?.actualIncome)?.toLocaleString(
                "en-US",
                { style: "currency", currency: "USD" }
              ),
              // mealList: ad,
              parentCompleteAddress:
                x?.parentCompleteAddress?.length > 25
                  ? x?.parentCompleteAddress?.substring(0, 25) + "..."
                  : x?.parentCompleteAddress,
              Action: (
                <>
                  <Link
                    className="btn_edit "
                    to={`${x?.incomeSurveyLink}`}
                    target="_blank"
                  >
                    <MdRemoveRedEye fontSize={24} />
                  </Link>
                </>
              ),
            };
          });
          setPaymentList(dList);
        } else {
          setPaymentList([]);
        }
      }
    }
  };
  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };
  return (
    <>
      {/* <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="survey-payments-page">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <h4 className="all_title mb-4">House Hold Survey</h4>
                <form onSubmit={handleSubmit(getPayment)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          {" "}
                          District
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {parentList &&
                            parentList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          {" "}
                          School
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                            selected.length &&
                            selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                              ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                              : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School Year </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("academicYearId",{
                            valueAsNumber : true
                          })}
                        >
                          <option value="0">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.academicYearId && (
                          <span role="alert" className="error_text">
                            {errors.academicYearId.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img src={filter} className="me-1" alt="" />
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-end mb-3 filter-img">
                  <Button className="theme_btn text-end">
                    <img src={filter} className="me-1" />
                    Apply
                  </Button>
                </div> */}
                </form>
                <ListTable
                  headCells={productHead}
                  action={true}
                  actionFirst={true}
                  reportDetails={reportDetails}
                  downloadFile={true}
                  dateRangeHide={true}
                  summery={false}
                  fileName="House Hold Survey Report"
                  ListData={paymentList}
                  landscape={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default SurveyReport;
