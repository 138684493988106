import React, { Suspense, useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import Select from "react-select"
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import Swal from "sweetalert2";
// import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { MdDelete } from "react-icons/md";
// import { BsPlusSquareDotted } from "react-icons/bs";

// import JoditEditor from 'jodit-react';
import { Editor } from "@tinymce/tinymce-react";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateTemplate() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  // const [editorValue, setEditorValue] = useState("");
  const [derror, setDrr] = useState();
  const [require, setRequire] = useState([]);
  const [selectRequire, setSelectRequire] = useState([]);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const editorRef = useRef(null);
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    // control,
    formState: { errors },
  } = useForm();

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: "requireParameter",
  // });

  useEffect(() => {
    if (id) {
      getEditedData(id);
    }
    getParameter()
    // else {
    //   append({ name: "" });
    // }
    // eslint-disable-next-line
  }, []);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getEditedData = async (id) => {
    setOpen(true);

    let sendData = {
      url: `/api/Template/GetTemplateDataById?templateId=${id}`,
      headers: headers,
    };

    let res = await GetCallApi(sendData);
    setOpen(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let data = res?.data?.payload;
        // setEditorValue(data?.body);
        setValue("fieldsUsedNotes", data?.fieldsUsedNotes);
        setValue("templateLanguage", data?.templateLanguage);
        setValue("notificationType", data?.notificationType);
        setValue("templateTitle", data?.templateTitle);
        setValue("subject", data?.subject);
        setSelectRequire(data?.requireParameter?.split(",")?.map((p) => {
          return {
            value: p,
            label: p
          }
        }))
        if (editorRef.current && data?.body) {
          editorRef.current.setContent(data.body);
        }
        // let rqp = data?.requireParameter?.split(",");
        // if (rqp?.length > 0) {
        //   setValue(
        //     "requireParameter",
        //     rqp?.map((x) => {
        //       return { name: x };
        //     })
        //   );
        // } else {
        //   append({ name: "" });
        // }
      }
    }
  };

  const getParameter = async () => {
    let sendData = {
      url: `/api/Common/GetTemplateRequiredParameter`,
      headers: headers
    }
    const resp = await GetCallApi(sendData)
    if (resp?.status === 200) {
      if (resp.data.success) {
        let list = resp.data.payload?.map((c) => {
          return {
            value: c?.name,
            label: c?.name
          }
        })
        let allList = [{ value: "all", label: "Select All" }, ...list]
        setRequire(allList)
      }
    }
  }

  const onSubmit = async (data) => {
    if (selectRequire?.length > 0) {
      setDrr({ req: "" })
      if (editorRef.current && editorRef.current.getContent() !== "") {
        let tempBody = editorRef.current.getContent();
        setDrr({ err: "" });
        setOpen(true);
        let gData;
        if (id) {
          gData = {
            ...data,
            createdBy: LogDetail?.userId,
            isActive: LogDetail?.isActive,
            body: tempBody,
            templateId: id,
            schoolDistrictId: LogDetail?.schoolDistrictId,
            requireParameter: selectRequire?.map((x) => x.label),
          };
        } else {
          gData = {
            ...data,
            createdBy: LogDetail?.userId,
            isActive: LogDetail?.isActive,
            body: tempBody,
            schoolDistrictId: LogDetail?.schoolDistrictId,
            requireParameter: selectRequire?.map((x) => x.label),
          };
        }

        let seData = {
          url: `/api/Template/CreateTemplate`,
          body: gData,
          headers: headers,
        };

        let respo = await PostCallApi(seData);
        setOpen(false);
        if (respo?.status === 200) {
          if (respo.data.success) {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: respo.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpen(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: respo?.data?.message
                ? respo?.data?.message
                : "Something went wrong please try again.",
              showConfirmButton: false,
              timer: 2000,
              color: "#ffffff",
              background: "red",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          }
        } else {
          setOpen(false);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: respo?.data?.message
              ? respo?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        setDrr({ err: "This field is required" });
      }
    } else {
      setDrr({ req: "This field is required" });
    }
  };

  const handleMulti = (e) => {
    setSelectRequire(e)
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_temp">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">
                {id ? "Edit Template" : "Create Template"}
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Template Title<span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Template Title"
                          {...register("templateTitle", {
                            required: "This field is required",
                          })}
                        />
                        {errors.templateTitle && (
                          <span className="formError errorMssg error_text">
                            {errors.templateTitle.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Template For
                          {/* <span className="red-color">*</span> */}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("fieldsUsedNotes", {
                            // required: "This field is required",
                          })}
                        >
                          {/* <option value="">Select Template</option> */}
                          <option value="campaign">Campaign</option>
                          <option value="paymentlink">Payment Link</option>
                          <option value="paymentsuccess">
                            Payment Success
                          </option>
                        </select>
                        {/* {errors.fieldsUsedNotes && (
                          <span role="alert" className="error_text">
                            {errors.fieldsUsedNotes.message}
                          </span>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Language
                          {/* <span className="red-color">*</span> */}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("templateLanguage", {
                            // required: "This field is required",
                          })}
                        >
                          {/* <option value="">Select Language</option> */}
                          <option value="english">English</option>
                          <option value="spanish">Spanish</option>
                          <option value="bilingual">Bilingual</option>
                        </select>
                        {/* {errors.templateLanguage && (
                          <span role="alert" className="error_text">
                            {errors.templateLanguage.message}
                          </span>
                        )} */}
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Notification Type
                          {/* <span className="red-color">*</span> */}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("notificationType", {
                            // required: "This field is required",
                          })}
                        >
                          {/* <option value="">Select Notification Type</option> */}
                          <option value="email">Email</option>
                          <option value="text">Text</option>
                        </select>
                        {/* {errors.notificationType && (
                          <span role="alert" className="error_text">
                            {errors.notificationType.message}
                          </span>
                        )} */}
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Subject <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                          {...register("subject", {
                            required: "This field is required",
                          })}
                        />
                        {errors.subject && (
                          <span className="formError errorMssg error_text">
                            {errors.subject.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* {fields?.map((x, index) => (
                      <div className="col-md-6" key={index}>
                        <div className="form-group">
                          <div className="d-flex justify-content-between">
                            <label>
                              Require Parameter{" "}
                              <span className="red-color">*</span>
                            </label>
                            {index === 0 ? (
                              <button
                                className="p-0 border-0"
                                style={{
                                  background: "transparent",
                                }}
                                onClick={() => append({ name: "" })}
                              >
                                <BsPlusSquareDotted
                                  fontSize={24}
                                  color={"#80c242"}
                                />
                              </button>
                            ) : (
                              <button
                                className="p-0 border-0"
                                style={{
                                  background: "transparent",
                                }}
                                onClick={() => remove(index)}
                              >
                                <MdDelete
                                  className="icon"
                                  fontSize={24}
                                  color={"#80c242"}
                                />
                              </button>
                            )}
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Subject"
                            {...register(`requireParameter.${index}.name`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.requireParameter?.[index]?.name && (
                            <span role="alert" className="error_text">
                              {errors.requireParameter?.[index]?.name.message}
                            </span>
                          )}
                        </div>
                      </div>
                    ))} */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Require Parameter
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={require}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select Require Parameter"
                          value={selectRequire ? selectRequire : null}
                          onChange={(selected) => {
                            true &&
                              selected.length &&
                              selected.find(
                                (option) => option.value === "all"
                              )
                              ? handleMulti(require.slice(1))
                              : !true
                                ? handleMulti(
                                  (selected && selected.value) || null
                                )
                                : handleMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.req && (
                          <span role="alert" className="d-block error_text">
                            {derror?.req}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Template Body <span className="red-color">*</span>{" "}
                        </label>
                        {/* <ReactQuill
                          value={editorValue}
                          onChange={(value) => setEditorValue(value)}
                          modules={{
                            toolbar: [
                              ["bold", "italic", "underline", "strike"], // toggled buttons
                              ["blockquote", "code-block"],

                              [{ header: 1 }, { header: 2 }], // custom button values
                              [{ list: "ordered" }, { list: "bullet" }],
                              [{ script: "sub" }, { script: "super" }], // superscript/subscript
                              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                              [{ direction: "rtl" }], // text direction

                              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],

                              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                              [{ font: [] }],
                              [{ align: [] }],

                              ["link", "image", "video"],
                              ["clean"],
                            ],
                          }}
                          theme="snow"
                        /> */}
                        {/* <JoditEditor
                          ref={editorRef}
                          config={{
                            placeholder: "",
                            statusbar: false,
                            removeButtons: ['about', 'preview', 'speechRecognize', 'ai-assistant', 'ai-commands', 'spellcheck', 'classSpan', 'eraser'],
                            //'cut', 'copy', 'outdent', 'fontsize', 'paragraph', 'undo', 'redo'
                          }}
                          // ref={editor}
                          value={editorValue}
                        // onChange={(value) => handleChangeEditor(value)}
                        /> */}

                        <Editor
                          apiKey="fqcxbtig1ej9urvytsii6mcw5dq31tym7f0fyt72h11lgpd8"
                          onInit={(_evt, editor) => (editorRef.current = editor)}
                          initialValue=""
                          init={{
                            height: 500,
                            menubar: false,
                            branding: false, // Removes "Powered by TinyMCE" branding
                            statusbar: false, // Removes the status bar
                            plugins: "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code wordcount Templates"
                            ,
                            toolbar:
                              "undo Redo | blocks | " +
                              "Bold | Underline |Italic | Forecolor |Select all	 | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | source code | Switch to or from fullscreen mode |media | link image",

                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />

                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end mt-3">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateTemplate;
