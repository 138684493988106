import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import AllergenTable from "../AllergenTable";
import "./index.scss";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const globalHead = [
  {
    id: "id",
    label: "#",
  },
  {
    id: "allergenName",
    label: "Allergen Name",
  },
  {
    id: "allergenGroup",
    label: "Allergen Group Name",
  },
];

function CreateAllergen() {
  const [prdList, setPrdList] = useState([]);
  const [prodId, setProdId] = useState([]);
  const [openCircle, setOpenCircle] = React.useState(false);
  const [openData, setOpenData] = React.useState(false);
  const [checkBox, setCheckbox] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [der, setDer] = useState();

  const { id } = useParams();
  const navigate = useNavigate();

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (prodId?.length > 0) {
      changeTbData();
    } else {
      changeTbData();
    }
    // eslint-disable-next-line
  }, [prodId]);

  useEffect(() => {
    getAllergenList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (openData) {
      if (id) {
        allergenEdit(id);
        setOpenData(false)
      }
    }
    // eslint-disable-next-line
  }, [id, openData]);

  const handleAddProduct = (val, eId) => {
    if (val) {
      setProdId((old) => [...old, eId]);
    } else {
      setCheckbox(false);
      setProdId((old) => old?.filter((pre) => pre !== eId));
    }
  };

  const handleCheckALL = (v, data) => {
    if (v) {
      setProdId(data?.map((d) => d?.allergenId));
      setCheckbox(true);
    } else {
      setProdId([]);
      setCheckbox(false);
    }
  };

  const getAllergenList = async () => {
    setOpenCircle(true);
    // setPrdList([]);

    let seData = {
      url: `/api/Common/GetAllergenList`,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data?.success) {
        let d = res?.data?.payload?.map((x, i) => {
          return {
            ...x,
            id: i + 1,
            Action: (
              <label className="personal_detail_label">
                <input
                  type="checkbox"
                  className="login_check"
                  name="Remember Me"
                  checked={false}
                  onChange={(e) =>
                    handleAddProduct(e?.target?.checked, x?.allergenId)
                  }
                />
                <span className="checkmark"></span>
              </label>
            ),
          };
        });
        setPrdList(d);
        setTotalRecord(d?.length);
        setOpenData(true)
      }
    }
  };

  const changeTbData = () => {
    let newData = prdList &&
      prdList?.map((pd) => {
        return {
          ...pd,
          add:
            prodId?.filter((x) => x === pd?.allergenId)?.length > 0
              ? true
              : false,
          Action: (
            <label className="personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  prodId?.filter((x) => x === pd?.allergenId)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleAddProduct(e?.target?.checked, pd?.allergenId)
                }
              />
              <span className="checkmark"></span>
            </label>
          ),
        };
      });
    setPrdList(newData);
    let ch = true;
    if (ch) {
      let as = newData?.map((x) => {
        if (!x?.add) {
          return (ch = false);
        }
        return undefined;
      });
      if (ch && newData?.length > 0) {
        setCheckbox(true);
      } else {
        setCheckbox(false);
      }
      ch = as; //for warning remove
    }
  };

  const onSubmit = async (data) => {
    if (prodId?.length === 0) {
      setDer("Select minimum one allergen")
    } else {
      setDer("")
      let sendData = {
        name: data?.allergenGroupName ? data?.allergenGroupName : null,
        allergenGroupId: id,
        allergenId: prodId,
        createdBy: LogDetail?.userId,
      };

      let alldata = {
        url: `/api/AllergenGroup/CreateAllergenGroup`,
        body: sendData,
        headers: headers,
      };
      setOpenCircle(true);

      let res = await PostCallApi(alldata);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          reset();
          navigate(-1);
        } else {
          setOpenCircle(false);
        }
      } else {
        setOpenCircle(false);
      }
    }
  };

  const allergenEdit = async () => {
    setOpenCircle(true);
    let seData = {
      url: `/api/AllergenGroup/GetAllergenGroupDataById?allergenGroupId=${id}`,
      headers: headers
    }
    const resp = await GetCallApi(seData)
    setOpenCircle(false);
    if (resp?.status === 200) {
      if (resp.data.success) {
        let allergenData = resp.data?.payload;
        let selectedId = allergenData[0]?.allergens?.map((d) => d?.allergenId)
        let newId = prdList?.filter((x) => selectedId?.includes(x?.allergenId))
        let notId = prdList?.filter((x) => !selectedId?.includes(x?.allergenId))
        const updatedData = [...newId, ...notId]
        setPrdList(updatedData?.map((x, i) => {
          return {
            ...x,
            id: i + 1
          }
        }))
        setProdId(allergenData[0]?.allergens?.map((d) => d?.allergenId));
        setValue("allergenGroupName", allergenData[0]?.allergenGroupName);
      } else {
        setOpenCircle(false);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="aller_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box">
                  <div className="all_title ProductTitle">
                    {id ? "Edit" : "Create"} Allergen Group
                  </div>
                </div>
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          Allergen Group Name
                          <span className="red-color"> *</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Allergen Group Name"
                          {...register("allergenGroupName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.allergenGroupName && (
                          <span role="alert" className="error_text">
                            {errors.allergenGroupName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="ms-auto mt-auto col-md-6">
                      <div className="form-group text-end filter-img">
                        <Button
                          className="theme_btn text-end me-3"
                          type="submit"
                        >
                          Submit
                        </Button>
                        <Button
                          className="cancel_btn text-end"
                          onClick={() => navigate(-1)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="text-end">
                  {der && (
                    <span role="alert" className="error_text">
                      {der}
                    </span>
                  )}
                </div>
                <AllergenTable
                  headCells={globalHead}
                  action={true}
                  actionFirst={true}
                  ListData={prdList}
                  setCheckbox={setCheckbox}
                  checkBox={checkBox}
                  handleCheckALL={handleCheckALL}
                  totalRecord={totalRecord}
                  pageApi={false}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateAllergen;
