import React from "react";
import { Box, Modal } from "@mui/material";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import { DeleteDataFor } from "../../../reducers/Common";
import Axios from "../../../Utils/AxiosConfig";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";

export default function CancelPopup(props) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const dispatch = useDispatch();
  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [openCircle, setOpenCircle] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handledelete = (data) => {
    setOpenCircle(true);
    let urlV = props?.url + "&remarks=" + data?.remark;
    Axios.delete(urlV, { headers })
      .then((responce) => {
        if (responce.status === 200) {
          if (responce.data.success) {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: responce.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            setOpenCircle(false);
            dispatch(DeleteDataFor(deleteInfo ? false : true));
            handleClose(false);
            props.callapi();
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((error) => {
        setOpenCircle(false);
        console.log(error);
      });
  };

  return (
    <>
      {props?.delteIcon ? (
        <button className="btn_dlt " onClick={handleOpen}>
          <MdDelete className="icon" fontSize={24} />
        </button>
      ) : (
        <button className={`btn_dlt text ${props?.color ? "backColor" : ""}`} onClick={handleOpen}>
          {props.deletetext}
        </button>
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>{props?.title}</h6>
                <div className="popup_delete_detail">
                  <p className="delete_text">
                    Are you sure you want to void
                    <span> {props?.text} ?</span>
                  </p>
                  <div className="form-group">
                    <label>
                      Enter Remark <span className="red-color">*</span>
                    </label>
                    <textarea
                      rows={5}
                      type="text"
                      className="form-control"
                      placeholder="Enter Remark"
                      {...register("remark", {
                        required: "This field is required",
                      })}
                    />
                    {errors.remark && (
                      <span role="alert" className="error_text">
                        {errors.remark.message}
                      </span>
                    )}
                  </div>
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handleSubmit(handledelete)}
                  >
                    Ok
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
