import Swal from "sweetalert2";

export const SwalSuccess = (mes) => {
  Swal.fire({
    position: "top-end",
    width: 400,
    // icon: "success",
    text: mes ? mes : "Successfully",
    showConfirmButton: false,
    timer: 1500,
    color: "#ffffff",
    background: "#48953e",
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutRight",
    },
  });
};

export const SwalError = (mes) => {
  Swal.fire({
    position: "top-end",
    width: 400,
    // icon: "success",
    text: mes ? mes : "Something went wrong please try again later",
    showConfirmButton: false,
    timer: 1500,
    color: "#ffffff",
    background: "red",
    showClass: {
      popup: "animate__animated animate__fadeInDown",
    },
    hideClass: {
      popup: "animate__animated animate__fadeOutRight",
    },
  });
};
export const SwalMessage = (mes) => {
  return Swal.fire({
    position: "top-center",
    width: 400,
    text: mes,
    confirmButtonColor: "#808080",
  });
};
