import React, { Suspense } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "./index.scss";
import ocps from "../../../../Assets/PngImage/ocps-logo.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function UserProfile() {
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="student-allergenform-page">
          <div className="main_wrapper">
            <div className="ocsp-logo">
              <img src={ocps} alt="Orange Country Public School" />
            </div>
            <h5 className="text-end">Diet Order Form</h5>
            <p className="pt-3">
              OCPS Food & Nutrition Services is committed to the mission and
              vision of our organization. We aim to serve nutritious meals to
              all children, including those having medically diagnosed or
              special dietary needs.
              <br />
              By completing this dietary request form, you are acknowledging the
              following:
            </p>
            <div className="allergenform-box">
              <div className="grey-box">
                <p>
                  our child/student has a dietary need for special meal
                  accommodations including food allergies. Only Section 1 of
                  this form should be completed and signed by the parents. (NOT
                  FOOD PREFERENCES)
                </p>
                <p>
                  Special Dietary needs and requests, including those related to
                  general health concerns, personal preferences, and moral or
                  religious beliefs are not disabilities and cannot be
                  accommodated.
                </p>
                <p>
                  When a food modification is necessary because of a medical
                  disability a State Licensed Healthcare Professional must
                  complete and sign Section 2 of this form.
                </p>
              </div>
              <div className="student-details-block">
                <form>
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <FormGroup>
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Student is Head Start"
                        />
                      </FormGroup>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Student ID</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Student First Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Student Last Name</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>DOB</label>
                        <input type="date" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Enrolled School</label>
                        <select name="" className="form-select">
                          <option value=""></option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Teacher Name/Home Room</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Home Address</label>
                        <textarea
                          rows="1"
                          class="form-control"
                          placeholder=""
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Apt</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>City</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>State</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label>Zip</label>
                        <input type="text" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="meal-checkbox">
                    <h6>Meals eaten at school:</h6>
                    <FormGroup className="meal-group">
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Breakfast"
                      />
                      <FormControlLabel control={<Checkbox />} label="Lunch" />
                      <FormControlLabel control={<Checkbox />} label="Snack" />
                      <FormControlLabel control={<Checkbox />} label="Supper" />
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Does not consume school meals"
                      />
                    </FormGroup>
                  </div>
                  <div className="student-radio-group">
                    <RadioGroup className="rado-group">
                      <label>
                        Does your child/student have food allergies?
                      </label>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <RadioGroup className="rado-group">
                      <label>
                        Are the food allergies severe or life threatening?
                      </label>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    <RadioGroup className="rado-group">
                      <label>
                        Does your child/student have a medical disability that
                        limits at least one major life activity and require meal
                        modifications outside of the traditional federal meal
                        program?
                      </label>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </div>
                  <div className="parents-details-box">
                    <div className="row">
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label>Parent/Guardian Name</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label>Physician Name</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label>Physician Phone</label>
                          <input type="text" className="form-control" />
                        </div>
                      </div>
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          <label>Parent/Guardian Signature</label>
                          <input
                            type="text"
                            className="form-control parent-sign"
                            placeholder="Parent/Guardian Signature"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      The official parent/guardian of the child above do hereby
                      consent to the exchange of pertinent dietary information
                      between the physician and school as needed. All
                      information will be kept confidential
                    </p>
                  </div>
                  <div className="food-block-box">
                    <p>
                      If your child has a dietary restriction that is not
                      related to food allergens (ie. Diabetes, Renal Disease, GI
                      Problems) or if your child has a severe food
                      allergy/medical disability your physician must complete
                      the section 2 form linked below.
                      <b>
                        {" "}
                        Please check the box of any food allergies or
                        intolerances your child has from the list:
                      </b>
                    </p>
                    <RadioGroup className="radio-group">
                      <FormControlLabel
                        value="milk"
                        control={<Radio />}
                        label="Milk and Dairy Products"
                      />
                      <FormControlLabel
                        value="fluidmilk"
                        control={<Radio />}
                        label="Fluid Milk Only"
                      />
                      <FormControlLabel
                        value="na"
                        control={<Radio />}
                        label="NA"
                      />
                    </RadioGroup>
                    <div className="milk-checkbox">
                      <FormGroup className="meal-group">
                        <FormControlLabel control={<Checkbox />} label="Egg" />
                        <FormControlLabel control={<Checkbox />} label="Soy" />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Peanut"
                        />
                        <FormControlLabel control={<Checkbox />} label="Fish" />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Sesame"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Wheat / Gluten"
                        />
                        <FormControlLabel control={<Checkbox />} label="Corn" />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Tree Nuts"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Shellfish"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Fruit"
                        />
                        <FormControlLabel
                          control={<Checkbox />}
                          label="Other"
                        />
                      </FormGroup>
                    </div>
                    <p>
                      ** If your child has any other food allergy or medical
                      condition, such substitution may only be made on a
                      case-by-case basis when supported by a diet modification
                      form signed by a recognized medical authority such as a
                      physician, physician’s assistant or nurse practitioner.
                      (Complete Section 2). If already completed you may attach
                      it here:
                    </p>
                    <div className="attechment-block">
                      <Button type="submit" className="grey-btn">
                        Section 2 form (completed by Doctor)
                      </Button>
                      <div>
                        <Button className="submit-btn" type="submit">
                          Attach Section 2
                        </Button>
                        <Button className="attach-btn" type="submit">
                          View Attachmnent
                        </Button>
                      </div>
                      <textarea
                        rows="3"
                        class="form-control"
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </form>
              </div>
              <div className="declration-block">
                <FormGroup className="">
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Students should pick up all special meals from the designated area, line, or FNS personnel that displays or wears the yellow apron."
                  />
                </FormGroup>
                <div className="row mt-2">
                  <div className="col-md-3 mb-2">
                    <input type="text" className="form-control border-input" />
                    <label>Parent/Guardian Name</label>
                  </div>
                  <div className="col-md-3 mb-2">
                    <input type="text" className="form-control border-input" />
                    <label>Parent/Guardian Phone</label>
                  </div>
                  <div className="col-md-3 mb-2">
                    <input type="text" className="form-control border-input" />
                    <label>Parent/Guardian Email</label>
                  </div>
                  <div className="col-md-3 mb-2">
                    <input
                      type="text"
                      className="form-control parent-sign"
                      placeholder="Parent/Guardian Signature"
                    />
                    <label>Parent/Guardian Signature</label>
                  </div>
                </div>
                <div className="bottom-text">
                  <p>Revised 09/25/2023</p>
                  <p className="form-number">OCPS0207FNS</p>
                </div>
                <div className="btn-group">
                  <Button className="submit-btn me-3" type="submit">
                    Submit
                  </Button>
                  <Button className="reset-btn" type="submit">
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default UserProfile;
