import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, Box, Modal } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import closesquare from "../../../../../Assets/PngImage/closesquare.png";
import defaultProfile from "../../../../../Assets/default_profile.jpg";
import { useForm } from "react-hook-form";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Config } from "../../../../../Utils/Config";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const ImgPopup = lazy(() =>
  import("../../../../../Components/Reusable/ImgPopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const productHead = [
  { id: "profilePhoto", label: "Profile" },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "birthDate", label: "DOB" },
  { id: "gradeId", label: "Grade" },
  { id: "school", label: "School/Site" },
  { id: "email", label: "Email" },
  { id: "studentBalance", label: "Balance" },
  { id: "homeroom", label: "Homeroom" },
  { id: "ethnicity", label: "Ethnicity" },
  { id: "foster", label: "Foster" },
];
function StudentTab() {
  const [studDetail, setStudDetail] = useState(false);
  const [parentList, setParentList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [open, setOpen] = React.useState(false);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [prdList, setPrdList] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const handleOpen = (e) => {
    setOpen(true);
    setStudDetail(e);
    setValue("studentGuidId", e.studentGuidId);
    setValue("studentId", e.studentGuidId);
    setValue("studentName", e.firstName + " " + e.lastName);
    getParentList(e.studentGuidId);
    if (e?.studentGuidId === studDetail?.studentGuidId) {
      setStudDetail(studDetail);
    }
  };
  const getParentList = (id) => {
    Axios.get(
      `/api/Student/GetParentsDataByStudentId?studentGuidId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setParentList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const handleClose = () => setOpen(false);

  const onSubmit = (data) => {
    setOpenCircle(true);

    Axios.post(`/api/Parents/SaveCommunicationHistory`, data, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            setOpen(false);
            reset();
            setStudDetail();
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  useEffect(() => {
    StudentTabCreate();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const StudentTabCreate = () => {
    setOpenCircle(true);
    Axios.get(`/api/Student/GetAllStudentData?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload?.map((sd, i) => {
              return {
                profilePhoto: (
                  <ImgPopup
                    img={
                      sd?.profilePhoto
                        ? `${Config.API_HOST_URL_live}${sd?.profilePhoto}`
                        : defaultProfile
                    }
                  />
                ),
                address: sd?.address,
                birthDate: sd?.birthDate,
                city: sd?.city,
                country: sd?.country,
                email: sd?.email,
                firstName: sd?.firstName,
                lastName: sd?.lastName,
                gradeId: sd?.gradeId,
                joiningDate: sd?.joiningDate,
                phone: sd?.phone,
                school: sd?.school,
                schoolDistrict: sd?.schoolDistrict,
                state: sd?.state,
                studentGuidId: sd?.studentGuidId,
                studentId: sd?.studentId,
                studentNumber: sd?.studentNumber,
                zipCode: sd?.zipCode,
                homeroom: sd?.homeroom,
                ethnicity: sd?.ethnicity,
                // studentBalance: sd?.studentBalance
                //   ? (Math.round(sd?.studentBalance * 100) / 100).toFixed(2)
                //   : "",
                studentBalance: sd?.studentBalance?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                foster: sd?.foster === true ? "True" : "False",
                isActive: "true",
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`/createstudent/edit/${sd?.studentGuidId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Student"}
                      text={sd?.firstName + " " + sd?.lastName}
                      url={`/api/Student/DeleteStudentData?studentGuidId=${sd?.studentGuidId}`}
                    />
                    <Link
                      className="add-log-btn ms-2"
                      onClick={() => handleOpen(sd)}
                    >
                      Add Log
                    </Link>
                  </>
                ),
              };
            });
            setPrdList(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <ListTable headCells={productHead} action={true} ListData={prdList} />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="modal-pop"
        >
          <Box sx={style}>
            <div className="addlog-popup">
              <div className="add-text">
                <h5 className="title">Add Log</h5>
                <Link to="" onClick={handleClose}>
                  <img src={closesquare} alt="" />
                </Link>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Student Id</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        {...register("studentId")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Student Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        {...register("studentName")}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Parent</label>
                      <select
                        className="form-select"
                        {...register("parentsGuidId", {
                          required: "This field is required",
                        })}
                      >
                        <option value="">Select Parent</option>
                        {parentList &&
                          parentList?.map((parent, i) => (
                            <option value={parent?.parentsGuidId} key={i}>
                              {parent.parentName}
                            </option>
                          ))}
                      </select>
                    </div>
                    {errors.parentsGuidId && (
                      <span role="alert" className="error_text">
                        {errors.parentsGuidId.message}
                      </span>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        placeholder="your log here"
                        rows="5"
                        {...register("message", {
                          required: "This field is required",
                        })}
                      ></textarea>
                      {errors.message && (
                        <span role="alert" className="error_text">
                          {errors.message.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 text-end">
                    <Button type="submit" className="theme_btn me-2">
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </Box>
        </Modal>
      </Suspense>
    </>
  );
}

export default StudentTab;
