import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
// import Axios from "../../Utils/AxiosConfig";
import Banner from "../../Assets/PngImage/login_banner.png";
import "./index.scss";
import { useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TfiLock } from "react-icons/tfi";
import Swal from "sweetalert2";
import Axios from "../../Utils/AxiosConfig";
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function ResetPassword() {
  const [openCircle, setOpenCircle] = useState(false);

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const { email } = useParams();
  let location = useLocation();
  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (email) {
      let em = email.split("&email=");
      setValue("email", em[1].toLowerCase());
      setValue("encrypetedKey", em[0].split("encryptedkey=")[1]);
    }
    // eslint-disable-next-line
  }, [email]);
  const onSubmit = (data) => {
    setOpenCircle(true);
    let pName = location.pathname?.split("/")[1];
    let url = "";
    if (pName === "reset-password-parent") {
      url = "/api/ParentLogin/ParentResetPassword";
    } else {
      url = "/api/UserLogin/UserResetPassword";
    }
    Axios.post(url, data)
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            reset();

            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            if (pName === "reset-password-parent") {
              navigate("/parent-login", { replace: true });
            } else {
              navigate("/", { replace: true });
            }
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="forgot_page">
          <div className="row m-0 h-100">
            <div className="col-md-6 p-0 sidebanner">
              <img src={Banner} alt="" className="img-fluid forgot_banner" />
            </div>
            <div className="col-md-6 m-auto">
              <form onSubmit={handleSubmit(onSubmit)} className="forgot_box">
                <div className="forgot_detail">
                  <div className="head_title">Reset Password</div>
                  <div className="form-group ">
                    <label className="form-label label_with_icon">
                      <TfiLock color="#31B680" fontSize={24} className="me-2" />
                      Password
                    </label>
                    <div className="d-flex position-relative align-items-center">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        {...register("newPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown(!passwordShown)}
                      >
                        {passwordShown ? (
                          <FiEye color="#31B680" />
                        ) : (
                          <FiEyeOff color="#31B680" />
                        )}
                      </span>
                    </div>
                    {errors.newPassword && (
                      <span role="alert" className="error_text">
                        {errors.newPassword.message}
                      </span>
                    )}
                  </div>
                  <div className="form-group  position-relative">
                    <label className="form-label label_with_icon">
                      <TfiLock color="#31B680" fontSize={24} className="me-2" />
                      Confirm Password
                    </label>
                    <div className="d-flex position-relative align-items-center">
                      <input
                        type={passwordShown1 ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        {...register("confirmPassword", {
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                          minLength: {
                            value: 8,
                            message: "Must be 8 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max 15 characters",
                          },
                          validate: (val) => {
                            if (watch("newPassword") !== val) {
                              return "Your passwords do no match";
                            }
                          },
                        })}
                      />
                      <span
                        className="eye"
                        onClick={() => setPasswordShown1(!passwordShown1)}
                      >
                        {passwordShown1 ? (
                          <FiEye color="#31B680" />
                        ) : (
                          <FiEyeOff color="#31B680" />
                        )}
                      </span>
                    </div>
                    {errors.confirmPassword && (
                      <span role="alert" className="error_text">
                        {errors.confirmPassword.message}
                      </span>
                    )}
                  </div>
                  <div className="text-end">
                    <Link className="forgot f-18-400" to="/">
                      Back to Login
                    </Link>
                  </div>
                  <div className="login_btn">
                    <Button type="submit" className="theme_btn w-100">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>{" "}
      </Suspense>
    </>
  );
}

export default ResetPassword;
