import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
// import { MdRemoveRedEye } from "react-icons/md";
import "./index.scss";
// import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import Select from 'react-select';
import filter from "../../../../../Assets/PngImage/filter.png";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const anotherHead = [
  { id: "paymentStatus", label: "Eligibility" },
  { id: "homeroom", label: "HomeRoom" },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "birthDate", label: "DOB" },
  { id: "studentId", label: "Student ID" },
  { id: "schoolDistrictName", label: "District Name" },
  { id: "schoolName", label: "School Name" },
];
function EligibilityReport() {
  const [distList, setDistList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [derror, setDerror] = useState();
  const [reportDetails, setReportDetails] = useState();
  const [schoolSelect, setSchoolSelect] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [openCircle, setOpenCircle] = useState(false);

  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
        if (dlist?.length === 1) {
          getSchoolList(dlist[0]?.value);
          setTimeout(() => {
            setValue("schoolDistrictId", dlist[0]?.value);
          }, 500);
        }
      }
    }
  };

  const getSchoolList = async (id) => {
    setSchoolList([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });

          let uslist = [{ value: "all", label: "Select All" }, ...dlist];
          setSchoolList(uslist?.length > 1 ? uslist : []);
        } else {
          setSchoolList([]);
        }
      }
    } else {
      setSchoolList([]);
    }
  };

  const SettingsSiteData = async (data) => {
    // setDerror({ err: "" });
    setPrdList([]);
    if (schoolSelect?.length === 0) {
      setDerror({ err: "Select minimum one school" });
    } else {
      setDerror({ err: "" });
      let sendData = {
        ...data,
        studentId: data?.studentId ? data?.studentId : null,
        studentName: data?.studentName ? data?.studentName : null,
        schoolDistrictId: data?.schoolDistrictId ? data?.schoolDistrictId : null,
        schoolIds: schoolSelect ? schoolSelect?.map((x) => x?.value) : null,
      };
      let distName = distList?.filter(
        (x) => x.value === sendData?.schoolDistrictId
      )[0]?.label;
      let schName =
        schoolSelect?.length === schoolList?.length - 1
          ? "All"
          : schoolSelect?.map((x) => x.label).toString();
      let reData = {
        distName: distName,
        schName: schName,
      };
      setReportDetails(reData);

      let seData = {
        url: `/api/Survey/GetAllStudentsListWithEligibilityStatus`,
        body: sendData,
        headers: headers,
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);

      if (res?.status === 200) {
        if (res.data.success) {
          setOpenCircle(false);
          const SdData = res.data.payload?.map((sd, i) => {
            return {
              ...sd,
            };
          });
          setPrdList(SdData);
        } else {
          // setDerror({ err: res.data.message });
          setOpenCircle(false);
        }
      }
    };
  }

  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        {/* <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
      </div> */}
        <section className="EligibilityReport_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <h4 className="all_title mb-0">Eligibility Report</h4>
                </div>
                <form onSubmit={handleSubmit(SettingsSiteData)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label> School</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolId", {
                            // required: "This field is required",
                          })}
                        >
                          <option value="">Select School District</option>
                          {schoolList &&
                            schoolList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School
                          <span className="red-color"> *</span>
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                              selected.length &&
                              selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                                ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                                : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Student ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student ID"
                          {...register("studentId")}
                        />
                        {errors.studentId && (
                          <span role="alert" className="error_text">
                            {errors.studentId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Student Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Name"
                          {...register("studentName")}
                        />
                        {errors.studentName && (
                          <span role="alert" className="error_text">
                            {errors.studentName.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img src={filter} className="me-1" alt="" />
                            Apply
                          </Button>
                        )}
                        {/* {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )} */}
                      </div>
                    </div>
                  </div>
                </form>
                <ListTable
                  headCells={anotherHead}
                  action={false}
                  ListData={prdList}
                  downloadFile={true}
                  reportDetails={reportDetails}
                  fileName="Eligibility Report"
                  entries1000={false}
                  pdfHide={true}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default EligibilityReport;
