import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

const translationEN = await fetch('/locales/English/Translate.json').then((res) => res.json());
// import translationEN from './locales/English/Translate.json'
const translationCH = await fetch("/locales/Chinese/Translate.json").then((res) => res.json());
const translationHI = await fetch("/locales/Hindi/Translate.json").then((res) => res.json());
const translationSP = await fetch("/locales/Spanish/Translate.json").then((res) => res.json());


//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    ch: {
        translation: translationCH,
    },
    hi: {
        translation: translationHI,
    },
    sp: {
        translation: translationSP,
    },
};
let lang = localStorage.getItem('Language');

//i18N Initialization

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang === undefined ? "en" : lang, //default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;