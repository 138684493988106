import React, { Suspense, lazy, useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Modal,
  Tooltip,
} from "@mui/material";
import { MdEdit, MdRemoveRedEye, MdAddCall, MdCallEnd } from "react-icons/md";
import { TbNotes } from "react-icons/tb";
import "./index.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import filter from "../../../../../Assets/PngImage/filter.png";
import Swal from "sweetalert2";
import { BsPlusSquareDotted } from "react-icons/bs";
import doc from "../../../../../Assets/PngImage/docs.png";
import pdf from "../../../../../Assets/PngImage/pdf.png";
import ppt from "../../../../../Assets/PngImage/ppt.png";
import sheet from "../../../../../Assets/PngImage/sheet.png";
import no_image from "../../../../../Assets/no_image.png";
import { Config } from "../../../../../Utils/Config";
import {
  SwalError,
  SwalSuccess,
} from "../../../../../Components/Reusable/SwalMessage";
import AddNotes from "./AddNotes";
import Select from "react-select";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "surveyDocumentUrl", label: "Doc" },
  { id: "NoteString", label: "Notes", isNotes: true },
  { id: "noOfPeoeple", label: "HH Size" },
  { id: "academicYear", label: "Academic Year" },
  { id: "benefitsReceived", label: "Benefits" },
  { id: "surveyBenifitField", label: "Type" },
  { id: "totalActualIncome", label: "Total Actual Income" },
  { id: "paymentCycle", label: "Payment Cycle" },
  { id: "status", label: "Status", color: true, blueColor: true },
  // { id: "totalHousholdAnuualIncome", label: "Annual Income" },
  // { id: "apartment", label: "Apartment" },
  // { id: "city", label: "City" },
  // { id: "state", label: "State" },
  { id: "firstName", label: "First Name" },
  { id: "fullName", label: "Last Name" },
  { id: "caseName", label: "Case Name" },
  { id: "caseNumber", label: "Case Number" },
  { id: "emailConfirmationAddress", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "createdOn", label: "Date" },
  { id: "address", label: "Address" },
  { id: "apartment", label: "APT Number" },
  { id: "city", label: "City" },
  { id: "stateName", label: "State" },
];

const anotherHead = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "birthDate", label: "DOB" },
  { id: "studentId", label: "Student ID" },
  { id: "schoolDistrictName", label: "District Name" },
  { id: "schoolName", label: "School Name" },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

function SubmittedSurveys() {
  const [distList, setDistList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [derror, setDerror] = useState();
  const [reportDetails, setReportDetails] = useState();
  const [userName, setUserName] = useState();
  const [open, setOpen] = useState(false);
  const [oldData, setOldData] = useState({});
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [mainCircle, setMainCircle] = React.useState(false);
  const [houseHoldId, setHouseHoldId] = React.useState("");
  const [schoolSelect, setSchoolSelect] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const [prdList, setPrdList] = useState([]);
  useEffect(() => {
    getDistrictList();
    getSchoolYear();
    // eslint-disable-next-line
  }, []);

  const handleOpenPopup = (sd) => {
    setOpenPopup(true);
    setHouseHoldId(sd);
  };

  const handleClosePopup = (sd, isEdit) => {
    setOpenPopup(false);
    setDerror("");
    setHouseHoldId("");
    if (sd) {
      setPrdList((prevPrdList) =>
        prevPrdList.map((x) => {
          if (x?.houseHoldSurveyId === sd?.houseHoldSurveyId) {
            let nsv = sd?.edit ? isEdit : [...x?.oldNote, sd];

            return {
              ...x,
              oldNote: nsv,
              NoteString: nsv?.map((x) => x?.notes)?.join(", "),
            };
          }
          return x;
        })
      );

      // SettingsSiteData({
      //   ...oldData,
      //   academicId: getValues("academicId"),
      //   status: getValues("status") ? getValues("status") : "",
      //   studentId: "",
      //   studentName: "",
      // });
    }
  };

  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
        if (dlist?.length === 1) {
          getSchoolList(dlist[0]?.value);
          setTimeout(() => {
            setValue("schoolDistrictId", dlist[0]?.value);
          }, 500);
        }
      }
    }
  };

  const getSchoolList = async (id) => {
    setSchoolList([]);
    if (id) {
      let seData = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });

          let uslist = [{ value: "all", label: "Select All" }, ...dlist];
          setSchoolList(uslist?.length > 1 ? uslist : []);
        } else {
          setSchoolList([]);
        }
      }
    } else {
      setSchoolList([]);
    }
  };

  const getSchoolYear = async () => {
    let schApi = {
      url: `/api/SchoolAcademicYear/GetAllSchoolAcademicYearData`,
    };

    let resp = await GetCallApi(schApi);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        let allD = resp?.data?.payload?.map((x) => {
          return {
            value: x?.academicYearId,
            label: x?.academicYear,
          };
        });
        setSchoolYearList(allD);
        setTimeout(() => {
          let aId = resp?.data?.payload?.find((x) => x?.isCurrentYear === true)
            ? resp?.data?.payload?.find((x) => x?.isCurrentYear === true)
                ?.academicYearId
            : 0;

          setValue("academicId", aId);
          SettingsSiteData({
            academicId: aId,
            status: "",
            studentId: "",
            studentName: "",
          });
        }, 500);
      }
    }
  };

  const SettingsSiteData = async (data) => {
    // setDerror({ err: "" });
    setPrdList([]);
    let sendData = {
      ...data,
      schoolDistrictId: data?.schoolDistrictId ? data?.schoolDistrictId : null,
      schoolIds: schoolSelect ? schoolSelect?.map((x) => x?.value) : null,
      parentGuidId: "00000000-0000-0000-0000-000000000000",
    };
    setOldData(sendData);
    let distName = distList?.filter(
      (x) => x.value === sendData?.schoolDistrictId
    )[0]?.label;
    let schName =
      schoolSelect?.length === schoolList?.length - 1
        ? "All"
        : schoolSelect?.map((x) => x.label).toString();
    let reData = {
      distName: distName,
      schName: schName,
    };
    setReportDetails(reData);

    let seData = {
      url: `/api/Survey/GetHouseHoldSurveryList`,
      body: sendData,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        const SdData = res.data.payload?.map((sd, i) => {
          let url = sd?.surveyDocumentUrl?.split(".");
          url = sd?.surveyDocumentUrl?.length > 0 ? url[url?.length - 1] : "";
          let showIcon =
            url === "doc" || url === "docx"
              ? doc
              : url === "pdf"
              ? pdf
              : url === "pptx"
              ? ppt
              : url === "xls" || url === "xlsx"
              ? sheet
              : url === "jpg" ||
                url === "png" ||
                url === "jpeg" ||
                url === "webp"
              ? Config?.API_HOST_URL_live + sd?.surveyDocumentUrl
              : "";
          return {
            ...sd,
            id: i,
            oldNote: sd?.notes,
            NoteString: sd?.notes?.map((x) => x?.notes)?.join(", "),
            totalActualIncome:
              sd?.totalActualIncome >= 0
                ? sd?.totalActualIncome?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                : "",
            subTableList: sd?.studentList
              ?.filter((x) => x.isStudent === true)
              ?.map((std, ind) => {
                return {
                  ...std,
                  id: ind,
                  birthDate: std?.birthDate
                    ? new Date(std?.birthDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })
                    : "",
                };
              }),
            createdOn: sd?.createdOn
              ? new Date(sd?.createdOn)?.toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              : "",
            // color: sd?.notes?.length
            //   ? "#d2f1f6"
            //   : sd?.status === "Pending" || sd?.isFosterChild === true
            //     ? "#f3f3f3"
            //     : "",
            // blueColor: sd?.notes?.length > 0 ? "lightBlue" : "",
            color: sd?.isRequiredCall
              ? "#d2f1f6"
              : sd?.status === "Pending" || sd?.isFosterChild === true
              ? "#f3f3f3"
              : "",
            surveyDocumentUrl: showIcon ? (
              <img
                onClick={() => handleOpen(sd?.surveyDocumentUrl)}
                src={showIcon}
                alt=""
                style={{ cursor: "pointer" }}
                className="img-fluid"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = no_image;
                }}
                width={30}
                height={30}
              />
            ) : (
              "NA"
              // <img
              //   src={no_image}
              //   alt=""
              //   style={{ cursor: "not-allowed" }}
              //   className="img-fluid"
              //   width={30}
              //   height={30}
              // />
            ),
            Action: (
              <>
                <Tooltip title="Add Note">
                  <Button
                    type="button"
                    className="btn_edit me-2"
                    onClick={() => handleOpenPopup(sd)}
                    style={{
                      color: "#80c242",
                      background: "transparent",
                      minWidth: "max-content",
                    }}
                  >
                    <TbNotes fontSize={24} />
                  </Button>
                </Tooltip>
                {sd?.isRequiredCall ? (
                  <Tooltip title="Parent Call Required">
                    <Button
                      type="button"
                      className="btn_edit me-2"
                      onClick={() =>
                        handleSendCall(sd?.houseHoldSurveyId, false)
                      }
                      style={{
                        color: "#80c242",
                        background: "transparent",
                        minWidth: "max-content",
                      }}
                    >
                      <MdCallEnd fontSize={24} color="red" />
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Flag For Call">
                    <Button
                      type="button"
                      className="btn_edit me-2"
                      onClick={() =>
                        handleSendCall(sd?.houseHoldSurveyId, true)
                      }
                      style={{
                        color: "#80c242",
                        background: "transparent",
                        minWidth: "max-content",
                      }}
                    >
                      <MdAddCall fontSize={24} />
                    </Button>
                  </Tooltip>
                )}
                <Tooltip title="View Survey">
                  <Link
                    type="button"
                    className="btn_edit me-2"
                    target="_blank"
                    to={`/reports/hhmi/view/${sd?.houseHoldSurveyId}/${sd?.schoolDistrictId}`}
                  >
                    <MdRemoveRedEye fontSize={24} />
                  </Link>
                </Tooltip>
                {sd?.status !== "Completed" ? (
                  <Link
                    type="button"
                    className="add-log-btn me-2"
                    onClick={() => handlePopup(sd)}
                  >
                    Approve
                  </Link>
                ) : (
                  ""
                )}
                {sd?.status === "Pending" ? (
                  <Tooltip title="Edit Survey">
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      target="_blank"
                      to={`/reports/hhmi/edit/${sd?.houseHoldSurveyId}/${sd?.schoolDistrictId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                  </Tooltip>
                ) : (
                  ""
                )}
              </>
            ),
          };
        });
        setPrdList(SdData);
      } else {
        // setDerror({ err: res.data.message });
        setOpenCircle(false);
      }
    }
  };

  const handlePopup = (list) => {
    setOpen(true);
    setUserName(list);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = async (hId) => {
    setOpenCircle(true);
    let seData = {
      url: `/api/Survey/ApproveBySchoolDisrtict?houseHoldId=${hId}&userId=${LogDetail?.userId}`,
      headers: headers,
    };

    const resp = await PostCallApi(seData);
    setOpenCircle(false);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: resp.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        handleClose();
        SettingsSiteData(oldData);
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: resp?.data?.message
            ? resp?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: resp?.data?.message
          ? resp?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };

  const handleOpen = (docs) => {
    window.open(`${Config?.API_HOST_URL_live}${docs}`, "_blank");
  };

  const handleSendCall = async (houseId, val) => {
    if (houseId) {
      setMainCircle(true);
      let sendNote = {
        houseHoldSurveyId: houseId,
        isRequiredCall: val,
        createdBy: LogDetail?.userId,
      };
      let apiUrl = {
        url: `/api/Survey/UpdateRequiredCall`,
        body: sendNote,
      };
      const re = await PostCallApi(apiUrl);
      setMainCircle(false);
      if (re?.status === 200 && re?.data?.success) {
        let sd = re?.data?.payload;
        setPrdList((prevPrdList) =>
          prevPrdList.map((x) => {
            if (x?.houseHoldSurveyId === houseId) {
              return {
                // id: i + 1,
                ...x,
                color: sd?.isRequiredCall
                  ? "#d2f1f6"
                  : sd?.status === "Pending" || sd?.isFosterChild === true
                  ? "#f3f3f3"
                  : "",

                Action: (
                  <>
                    <Tooltip title="Add Note">
                      <Button
                        type="button"
                        className="btn_edit me-2"
                        onClick={() => handleOpenPopup(sd)}
                        style={{
                          color: "#80c242",
                          background: "transparent",
                          minWidth: "max-content",
                        }}
                      >
                        <TbNotes fontSize={24} />
                      </Button>
                    </Tooltip>
                    {sd?.isRequiredCall ? (
                      <Tooltip title="Parent Call Required">
                        <Button
                          type="button"
                          className="btn_edit me-2"
                          onClick={() =>
                            handleSendCall(sd?.houseHoldSurveyId, false)
                          }
                          style={{
                            color: "#80c242",
                            background: "transparent",
                            minWidth: "max-content",
                          }}
                        >
                          <MdCallEnd fontSize={24} color="red" />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Flag For Call">
                        <Button
                          type="button"
                          className="btn_edit me-2"
                          onClick={() =>
                            handleSendCall(sd?.houseHoldSurveyId, true)
                          }
                          style={{
                            color: "#80c242",
                            background: "transparent",
                            minWidth: "max-content",
                          }}
                        >
                          <MdAddCall fontSize={24} />
                        </Button>
                      </Tooltip>
                    )}
                    <Tooltip title="View Survey">
                      <Link
                        type="button"
                        className="btn_edit me-2"
                        target="_blank"
                        to={`/reports/hhmi/view/${sd?.houseHoldSurveyId}/${sd?.schoolDistrictId}`}
                      >
                        <MdRemoveRedEye fontSize={24} />
                      </Link>
                    </Tooltip>
                    {sd?.status !== "Completed" ? (
                      <Link
                        type="button"
                        className="add-log-btn me-2"
                        onClick={() => handlePopup(sd)}
                      >
                        Approve
                      </Link>
                    ) : (
                      ""
                    )}
                    {sd?.status === "Pending" ? (
                      <Tooltip title="Edit Survey">
                        <Link
                          type="button"
                          className="btn_edit me-2"
                          target="_blank"
                          to={`/reports/hhmi/edit/${sd?.houseHoldSurveyId}/${sd?.schoolDistrictId}`}
                        >
                          <MdEdit fontSize={24} />
                        </Link>
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </>
                ),
              };
            }
            return x;
          })
        );
        SwalSuccess(re?.data?.message);
        // SettingsSiteData({
        //   ...oldData,
        //   academicId: getValues('academicId'),
        //   status: "",
        //   studentId: "",
        //   studentName: "",
        // });
      } else {
        SwalError(re?.data?.message);
        setMainCircle(false);
        // SettingsSiteData({
        //   ...oldData,
        //   academicId: getValues('academicId'),
        //   status: "",
        //   studentId: "",
        //   studentName: "",
        // });
      }
    }
  };

  const handleIOCMulti = (e) => {
    setSchoolSelect(e);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={mainCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {/* <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
      </div> */}
        <section className="survey_sub_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <h4 className="all_title mb-0">Submitted Surveys</h4>
                  <div className="d-flex">
                    <Link to={"/reports/hhmi/create"} className="theme_btn">
                      <BsPlusSquareDotted fontSize={24} className="me-3" />
                      Create
                    </Link>
                  </div>
                </div>
                <form onSubmit={handleSubmit(SettingsSiteData)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolDistrictId", {
                            // required: "This field is required",
                            onChange: (e) => getSchoolList(e.target.value),
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          {" "}
                          School
                          {/* <span className="red-color"> *</span> */}
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolSelect ? schoolSelect : null}
                          onChange={(selected) => {
                            true &&
                            selected.length &&
                            selected.find((option) => option.value === "all")
                              ? handleIOCMulti(schoolList.slice(1))
                              : !true
                              ? handleIOCMulti(
                                  (selected && selected.value) || null
                                )
                              : handleIOCMulti(selected);
                          }}
                          style={{ width: "100%" }}
                        />
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group">
                        <label> School</label>
                        <select
                          name=""
                          className="form-select"
                          {...register("schoolId", {
                            // required: "This field is required",
                          })}
                        >
                          <option value="">Select School District</option>
                          {schoolList &&
                            schoolList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> School Year </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("academicId", {
                            valueAsNumber: true,
                          })}
                        >
                          <option value="0">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.academicId && (
                          <span role="alert" className="error_text">
                            {errors.academicId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Student ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student ID"
                          {...register("studentId")}
                        />
                        {errors.studentId && (
                          <span role="alert" className="error_text">
                            {errors.studentId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Student Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Name"
                          {...register("studentName")}
                        />
                        {errors.studentName && (
                          <span role="alert" className="error_text">
                            {errors.studentName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>Status</label>
                        <select
                          type="text"
                          className="form-select"
                          {...register("status")}
                        >
                          <option value="">All</option>
                          <option value="Completed">Completed</option>
                          <option value="Pending">Pending</option>
                          <option value="Submited">Submited</option>
                        </select>
                        {errors.status && (
                          <span role="alert" className="error_text">
                            {errors.status.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        <Button
                          className="theme_btn text-end"
                          disabled={openCircle}
                          type="submit"
                        >
                          {openCircle ? (
                            <>
                              Applying Filter{" "}
                              <CircularProgress
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <img src={filter} className="me-1" alt="" />
                              Apply
                            </>
                          )}
                        </Button>
                        {derror?.err && (
                          <span role="alert" className="d-block error_text">
                            {derror.err}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                  actionFirst={true}
                  downloadFile={true}
                  reportDetails={reportDetails}
                  fileName="Submitted Surveys"
                  anotherTable={true}
                  anotherheadCells={anotherHead}
                  pdfHide={true}
                  handleNotePopup={(val) => handleOpenPopup(val)}
                />
              </div>
            </div>
          </div>
        </section>
        {open && (
          <>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              className="toggle_delete_data"
            >
              <Box sx={style} className="delete_data_box">
                <Backdrop
                  sx={{ color: "#fff", zIndex: () => 9999 }}
                  open={openCircle}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <div className="popup_delete_detail_box">
                  <h6>{userName?.fullName}</h6>
                  <div className="popup_delete_detail">
                    <p className="delete_text">
                      Are you sure you want to Approve
                      <span> {userName?.fullName}</span>
                    </p>
                    <button
                      type="button"
                      className="popup_btn delete"
                      onClick={() => handleApprove(userName?.houseHoldSurveyId)}
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      className="popup_btn cancel"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Box>
            </Modal>
          </>
        )}
        <AddNotes
          openPopup={openPopup}
          handleClosePopup={handleClosePopup}
          houseHoldId={houseHoldId}
        />
      </Suspense>
    </>
  );
}

export default SubmittedSurveys;
