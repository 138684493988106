import React, { useCallback, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import uploadimg from "../../../../../../Assets/SvgImage/file-upload.svg";
import Axios from "../../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import CropModal from "../../../../../../Components/Reusable/CropModel";

function ProductUploadForm() {
  const { id } = useParams();
  const [pathName, setPathName] = useState(false);
  const [catId, setCatId] = useState("");
  const [catList, setCatList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  let location = useLocation();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const onSubmit = (data) => {
    let PdData = {
      ...data,
      createdBy: LogDetail?.userId,
    };
    setOpenCircle(true);
    Axios.post(`/api/Product/CreateProduct`, PdData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            if (data?.ProductImageUrl1) {
              let imgData = {
                ProductId: res.data.payload?.productId,
                ProductImageUrl1: data.ProductImageUrl1,
                ProductImageUrl2: data.ProductImageUrl2,
              };
              Axios.post(`/api/Product/SaveProductImages`, imgData, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                  Accept: "*/*",
                },
              });
            }
            navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    let pName = location.pathname?.split("/")[3];
    if (pName === "view") {
      setPathName(true);
    } else {
      setPathName(false);
    }
    GetCategoryList();
    GetSubCategoryList();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (catList) {
      if (subCatList) {
        if (id) {
          setOpenCircle(true);
          Axios.get(
            `/api/Product/GetProductDataByID?ProductId=${id}&userId=${LogDetail?.userId}`,
            {
              headers,
            }
          )
            .then((res) => {
              if (res?.status === 200) {
                if (res.data.success) {
                  let pdDetail = res.data.payload;
                  setValue("productId", pdDetail?.productId);
                  setValue("productName", pdDetail?.productName);
                  setValue("price", pdDetail?.price);
                  setValue("categoryId", pdDetail?.categoryId);
                  setValue("subCategoryId", pdDetail?.subCategoryId);
                  setValue("description", pdDetail?.description);
                  setValue("logoUpload1", pdDetail?.productImageUrl1);

                  setOpenCircle(false);
                } else {
                  setOpenCircle(false);
                }
              }
            })
            .catch((e) => {
              setOpenCircle(false);
              console.log(e);
            });
        }
      }
    }
    // eslint-disable-next-line
  }, [id, catList, subCatList]);

  const GetCategoryList = () => {
    Axios.get(
      `/api/ProductCategory/GetAllProductCategoryData?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let comList = res?.data?.payload?.map((c, i) => {
              return {
                catId: c?.productCategoryId,
                catName: c?.productCategoryName,
              };
            });
            setCatList(comList);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const GetSubCategoryList = () => {
    Axios.get(
      `/api/ProductSubCategory/GetAllProductSubCategoryData?userId=${LogDetail?.userId}`,
      {
        headers,
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let comList = res?.data?.payload?.map((c, i) => {
              return {
                subCatName: c?.productSubCategoryName,
                cagId: c?.productCategoryId,
                subCatId: c?.productSubCategoryId,
              };
            });
            setSubCatList(comList);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const subCatFilter = subCatList?.filter((sb) => {
    if (catId) {
      return sb?.cagId === parseInt(catId);
    } else return sb;
  });

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("ProductImageUrl1", url);
        setValue("logoUpload1", url.name);
        setCroppedImageUrl(url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <section className="create_product">
        <div className="main_wrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="Productupload_hed mb-3">
                <div className="all_title ProductTitle">
                  {pathName
                    ? "Product Details"
                    : id
                    ? "Edit Products"
                    : "Create Products"}
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card">
                  <div className="card-body">
                    <div className="input_add_box">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group  ">
                            <label className="form-label">Product Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Product Name"
                              disabled={pathName}
                              {...register("productName", {
                                required: "This field is required",
                              })}
                            />

                            {errors.productName && (
                              <span role="alert" className="error_text">
                                {errors.productName.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group  ">
                            <label className="form-label">Price</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Price"
                              disabled={pathName}
                              {...register("price", {
                                required: "This field is required",
                                pattern: {
                                  value: /^\d+(\.\d{1,2})?$/,
                                  message:
                                    "Enter valid Number the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 75",
                                },
                              })}
                            />
                            {errors.price && (
                              <span role="alert" className="error_text">
                                {errors.price.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group  ">
                            <label className="form-label">
                              Select Category
                            </label>
                            <select
                              className={`form-select`}
                              aria-label="Default select example"
                              disabled={pathName}
                              {...register("categoryId", {
                                required: "This field is required",
                                valueAsNumber: true,
                                onChange: (e) => setCatId(e.target.value),
                              })}
                            >
                              <option value="">Select Category</option>
                              {catList &&
                                catList?.map((mn, i) => (
                                  <option value={mn?.catId} key={i}>
                                    {mn?.catName}
                                  </option>
                                ))}
                            </select>
                            {errors.categoryId && (
                              <span role="alert" className="error_text">
                                {errors.categoryId.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group  ">
                            <label className="form-label">
                              Select Sub Category
                            </label>
                            <select
                              className={`form-select`}
                              aria-label="Default select example"
                              disabled={pathName}
                              {...register("subCategoryId", {
                                required: "This field is required",
                                valueAsNumber: true,
                              })}
                            >
                              <option value="">Select Sub Category</option>
                              {subCatFilter &&
                                subCatFilter?.map((mn, i) => (
                                  <option value={mn?.subCatId} key={i}>
                                    {mn?.subCatName}
                                  </option>
                                ))}
                            </select>
                            {errors.subCategoryId && (
                              <span role="alert" className="error_text">
                                {errors.subCategoryId.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label className="form-label">Upload Logo</label>
                            <div className="upload_img">
                              <input
                                type="text"
                                className="form-control upload_box"
                                placeholder="No file Chosen"
                                readOnly
                                {...register("logoUpload1", {})}
                              />
                              <input
                                type="file"
                                id="school_img_upload_1"
                                accept="image/*"
                                className="form-control"
                                style={{ display: "none" }}
                                onChange={handleFile}
                              />
                              <label
                                htmlFor="school_img_upload_1"
                                className="upload_img_label mb-0 "
                              >
                                <img
                                  src={uploadimg}
                                  className="img-fluid"
                                  alt=""
                                />
                              </label>
                            </div>
                            {errors?.logoUpload && (
                              <span role="alert" className="error_text">
                                {errors.logoUpload.message}
                              </span>
                            )}
                            {src && (
                              <CropModal
                                src={src}
                                filename={filename}
                                setCroppedImageUrl={setCroppedImageUrlCallback}
                                openCropModal={openCropModal}
                                setCropModal={setCropModal}
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group ">
                            <label className="form-label">Description</label>
                            <textarea
                              rows={5}
                              className="form-control"
                              placeholder="Enter Description"
                              disabled={pathName}
                              {...register("description")}
                            />
                            {errors.description && (
                              <span role="alert" className="error_text">
                                {errors.description.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 ">
                          <div className="w-auto form-group filter_btn mt-auto text-end">
                            <Button
                              className="cancel_btn me-3"
                              onClick={() => navigate(-1)}
                            >
                              {pathName ? "Back" : "Cancel"}
                            </Button>
                            {pathName ? (
                              ""
                            ) : (
                              <Button className="theme_btn" type="submit">
                                {id ? "Update" : "Save"}
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductUploadForm;
