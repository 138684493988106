import React, { Suspense, useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import Axios from "../../../../Utils/AxiosConfig";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Config } from "../../../../Utils/Config";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { GetCallApi } from "./../../../../Action/Action";
import ChangeLanguage from "../../../../locales/ChangeLang";
import { useTranslation } from "react-i18next";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
// const IncomeChart = [
//   {
//     annul: "26,973",
//     monthly: "2,248",
//     twicePerMonth: "1,124",
//     everyTwoWeeks: "1,038",
//     Weekly: "519",
//   },
//   {
//     annul: "36,482",
//     monthly: "3,041",
//     twicePerMonth: "1,521",
//     everyTwoWeeks: "1,404",
//     Weekly: "702",
//   },
//   {
//     annul: "45,991",
//     monthly: "3,833",
//     twicePerMonth: "1,769",
//     everyTwoWeeks: "1,769",
//     Weekly: "855",
//   },
//   {
//     annul: "55,500",
//     monthly: "4,625",
//     twicePerMonth: "2,313",
//     everyTwoWeeks: "2,135",
//     Weekly: "1,068",
//   },
//   {
//     annul: "65,009",
//     monthly: "5,418",
//     twicePerMonth: "2,709",
//     everyTwoWeeks: "2,501",
//     Weekly: "1,251",
//   },
//   {
//     annul: "74,518",
//     monthly: "6,210",
//     twicePerMonth: "3,105",
//     everyTwoWeeks: "2,867",
//     Weekly: "1,434",
//   },
//   {
//     annul: "84,027",
//     monthly: "7,003",
//     twicePerMonth: "3,502",
//     everyTwoWeeks: "3,232",
//     Weekly: "1,616",
//   },
//   {
//     annul: "93,536",
//     monthly: "7,795",
//     twicePerMonth: "3,898",
//     everyTwoWeeks: "3,598",
//     Weekly: "1,799",
//     houseHoldSize: " >8 OR 8",
//   },
//   {
//     houseHoldSize: "For each additional household member",
//     annul: "9,509",
//     monthly: "793",
//     twicePerMonth: "397",
//     everyTwoWeeks: "366",
//     Weekly: "183",
//   },
// ];

function HouseHoldMemberInformationShow() {
  const { id, dId } = useParams();
  const [openCircle, setOpenCircle] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [studId, setStudId] = useState();
  const [qiId, setQiId] = useState("");
  const [payDetail, setPayDetail] = useState(false);
  const [distLogo, setDistLogo] = useState([]);

  const { t } = useTranslation();

  const {
    register,
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm();
  const { fields } = useFieldArray({
    control,
    name: "StudentList",
  });

  const { fields: Field1 } = useFieldArray({
    control,
    name: "houseHoldList",
  });

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    if (!payDetail) {
      getStateList();
      getStudentList(id);
    }
    // eslint-disable-next-line
  }, [payDetail]);

  useEffect(() => {
    getDistrictLogo(dId);
    // eslint-disable-next-line
  }, [dId]);

  const getDistrictLogo = async (distId) => {
    if (distId) {
      let seData = {
        url: `/api/SchoolDistrict/GetDistrictLogoById?schoolDistrictId=${distId}`,
      };
      let res = await GetCallApi(seData);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload;
          setDistLogo(dlist?.length > 0 ? dlist : []);
        }
      }
    }
  };

  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setStateList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getStudentList = (id) => {
    if (id) {
      setOpenCircle(true);
      Axios.get(
        `/api/Survey/GetHouseHoldSurveryByHouseHoldId?houseHoldId=${id}`,
        {
          headers,
        }
      )
        .then((res) => {
          if (res?.status === 200) {
            setOpenCircle(false);
            if (res.data.success) {
              let srData = res.data.payload.hhs;
              setValue(
                "surveyBenifitField",
                srData?.surveyBenifitField
                  ? srData?.surveyBenifitField === "F"
                    ? "FDPIR"
                    : srData?.surveyBenifitField === "S"
                      ? "SNAP"
                      : srData?.surveyBenifitField === "T"
                        ? "TANF"
                        : srData?.surveyBenifitField === "N"
                          ? "NA"
                          : srData?.surveyBenifitField
                  : ""
              );
              setValue("caseName", srData?.caseName);
              setValue("caseNumber", srData?.caseNumber);
              setValue("firstName", srData?.firstName);
              setValue("fullName", srData?.fullName);
              setValue("address", srData?.address);
              setValue("apartment", srData?.apartment);
              setValue("phone", srData?.phone);
              setValue("city", srData?.city);
              setValue("state", srData?.state);
              setValue("signatureName", srData?.signature);
              setValue("actualIncome", srData?.actualIncome);
              setValue("ssnNumber", srData?.ssnnumber);
              setValue("isSSN", srData?.isSsn);
              setValue("NoOfPeoeple", srData?.noOfPeoeple);
              // setValue("notes", srData?.notes);
              setValue("isHispanic", srData?.isHispanic?.toString());
              setValue(
                "isOtherStateEbtbenifit",
                srData?.isOtherStateEbtbenifit
              );
              setValue("isSummerEBT", srData?.isSummerEbt);
              setValue("totalChildIncome", srData?.totalChildIncome);
              setValue("childPaymentCycle", srData?.childPaymentCycle);
              setValue("sign", srData?.signature?.length > 0 ? true : false);
              let arV = [];
              if (srData?.isAmericanIndianAlaskaNative) {
                arV.push("isAmericanIndianAlaskaNative");
              }
              if (srData?.isAsian) {
                arV.push("isAsian");
              }
              if (srData?.isBlackAfricanAmerican) {
                arV.push("isBlackAfricanAmerican");
              }
              if (srData?.isNativeHawaiianOtherPacificIslande) {
                arV.push("isNativeHawaiianOtherPacificIslande");
              }
              if (srData?.isWhite) {
                arV.push("isWhite");
              }
              setValue("isRace", arV?.length > 0 ? arV : []);
              setValue(
                "emailConfirmationAddress",
                srData?.emailConfirmationAddress
              );
              setTimeout(() => {
                setValue("paymentCycle", srData?.paymentCycle);
                setValue("totalActualIncome", srData?.totalActualIncome);
              }, 200);
              const dlist = res.data.payload.studentList;
              if (dlist === undefined || dlist?.length === 0) {
                const data = [
                  {
                    birthDate: "",
                    firstName: "",
                    lastName: "",
                    isStudent: true,
                    studentGuidId: null,
                    grade: "",
                    isFosterChild: false,
                    schoolId: "",
                    fosterChild: "NA",
                    actualIncome: 0,
                    paymentCycle: "",
                    studentType: "",
                    country: "USA",
                  },
                ];
                setValue("StudentList", data);
                setValue("houseHoldList", [{ ...data[0], isStudent: false }]);
              }
              let nr = dlist?.map((std, index) => {
                return {
                  birthDate: std?.birthDate?.split("T")[0],
                  // firstName: std?.studentName?.split(" ")[0],
                  // lastName: std?.studentName?.split(" ")[1],
                  firstName: std?.firstName,
                  lastName: std?.lastName,
                  isStudent: std?.isStudent,
                  studentGuidId: std?.studentGuidId ? std?.studentGuidId : null,
                  fosterChild: std?.fosterChild ? std?.fosterChild : "NA",
                  grade: std?.grade,
                  schoolId: std?.schoolId,
                  schoolName: std?.schoolName ? std?.schoolName : "",
                  isFosterChild: std?.isFosterChild
                    ? std?.isFosterChild
                    : false,
                  actualIncome: std?.actualIncome ? std?.actualIncome : 0,
                  paymentCycle: std?.paymentCycle,
                  studentType: "",
                  studentId: std?.studentId,
                  country: "USA",
                  removable: false,
                  houseHoldIncomeSub: std?.houseHoldIncomeList?.map((x, i) => {
                    return {
                      incomeAmount: x?.incomeAmount,
                      incomeSource: x?.incomeSource,
                      paymentCycle: x?.paymentCycle,
                    };
                  }),
                };
              });
              if (nr?.length > 0 || nr !== undefined) {
                nr = [...nr];
                setValue(
                  "StudentList",
                  nr?.filter((x) => x?.isStudent === true)
                );
                setValue(
                  "houseHoldList",
                  nr?.filter((x) => x?.isStudent === false)
                );
                handleChoise("val", 0);
              }
            } else {
              const data = [
                {
                  birthDate: "",
                  firstName: "",
                  lastName: "",
                  isStudent: true,
                  studentGuidId: null,
                  grade: "",
                  schoolId: "",
                  fosterChild: "NA",
                  isFosterChild: false,
                  studentType: "",
                  country: "USA",
                  removable: true,
                },
              ];
              setValue("StudentList", data);
            }
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };

  const handleChoise = (id, index) => {
    setStudId(id);
    setQiId(index);
    if (id === studId) {
      setStudId(studId);
    }
    if (index === qiId) {
      setQiId(qiId);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        {payDetail ? (
          <section className="payment_success">
            <div className="main_wrapper">
              <div className="payment_inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="success_message_box">
                      <BsFillPatchCheckFill className="icon" />
                      <div>
                        <h1 className="m-0">Form Submitted Successful</h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row paymentAndUserInfo mt-4">
                  <div className="col-md-12 text-center">
                    <Button
                      className="theme_btn mt-3"
                      onClick={() => setPayDetail(false)}
                    >
                      Back
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="HouseHoldMemberInformation_show">
            <div className="card">
              <div className="card-body">
                <div className="hed">
                  <div className="d-flex">
                    {distLogo ? (
                      <img
                        src={`${Config.API_HOST_URL_live}/${distLogo[0]}`}
                        className="header_logo img-fluid"
                        alt=""
                      />
                    ) : (
                      <div></div>
                    )}
                    <h2 className="mx-auto">{t("SurveyTitle")}</h2>
                    <ChangeLanguage />
                  </div>
                  <div className="info">
                    <h5>
                      <b>{t("PartATitle")}</b>
                    </h5>
                    <p>{t("PartADesc")}</p>
                  </div>
                </div>
                <hr />
                <div className="houseHoldForm">
                  <form action="" className="primary-box">
                    <div className="partA">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>{t("TableHead3")}</th>
                              <th>{t("TableHead4")}</th>
                              <th>{t("TableHead5")}</th>
                              <th>{t("TableHead2")}</th>
                              {getValues(`StudentList`)?.filter(
                                (x) => x.isStudent === true
                              )?.length > 0 ? (
                                <>
                                  <th>{t("TableHead6")}</th>
                                  <th>{t("TableHead10")}</th>
                                  <th>{t("TableHead7")} </th>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {fields
                              ?.filter((x) => x?.isStudent === true)
                              ?.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="date"
                                      className="form-control"
                                      style={{
                                        minWidth: "135px",
                                        maxWidth: "135px",
                                      }}
                                      disabled={true}
                                      {...register(
                                        `StudentList.${index}.birthDate`,
                                        {
                                          required: "This field is required",
                                          valueAsDate: true,
                                        }
                                      )}
                                    />
                                    {errors.StudentList?.[index]?.birthDate && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.birthDate
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("placeholderFirstName")}
                                      disabled={true}
                                      {...register(
                                        `StudentList.${index}.firstName`,
                                        {
                                          required: "This field is required",
                                        }
                                      )}
                                    />
                                    {errors.StudentList?.[index]?.firstName && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.firstName
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder={t("placeholderLastName")}
                                      readOnly
                                      disabled={true}
                                      {...register(
                                        `StudentList.${index}.lastName`,
                                        {
                                          required: "This field is required",
                                        }
                                      )}
                                    />
                                    {errors.StudentList?.[index]?.lastName && (
                                      <span role="alert" className="error_text">
                                        {
                                          errors.StudentList?.[index]?.lastName
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <select
                                      disabled={true}
                                      name=""
                                      className="form-select"
                                      {...register(
                                        `StudentList.${index}.fosterChild`,
                                        {
                                          required: t("RequiredValidation"),
                                        }
                                      )}
                                    >
                                      <option value="NA">
                                        {t("TypeDropVal5")}
                                      </option>
                                      <option value="FosterChild">
                                        {t("TypeDropVal1")}
                                      </option>
                                      <option value="MigrantWorker">
                                        {t("TypeDropVal2")}
                                      </option>
                                      <option value="Runway">
                                        {t("TypeDropVal3")}
                                      </option>
                                      <option value="HomeLess">
                                        {t("TypeDropVal4")}
                                      </option>
                                    </select>
                                    {errors.StudentList?.[index]
                                      ?.fosterChild && (
                                        <span role="alert" className="error_text">
                                          {
                                            errors.StudentList?.[index]
                                              ?.fosterChild.message
                                          }
                                        </span>
                                      )}
                                  </td>
                                  {getValues(`StudentList`)?.filter(
                                    (x) => x.isStudent === true
                                  )?.length > 0 ? (
                                    getValues(
                                      `StudentList.${index}.isStudent`
                                    ) ? (
                                      <>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t(
                                              "placeholderStudentId"
                                            )}
                                            disabled={true}
                                            style={{
                                              minWidth: "90px",
                                              maxWidth: "90px",
                                            }}
                                            readOnly
                                            {...register(
                                              `StudentList.${index}.studentId`,
                                              {
                                                // required: "This field is required",
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]
                                            ?.studentId && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.StudentList?.[index]
                                                    ?.studentId.message
                                                }
                                              </span>
                                            )}
                                        </td>
                                        <td>
                                          {item?.removable ? (
                                            ""
                                          ) : (
                                            <>
                                              <input
                                                type="text"
                                                className="form-control"
                                                disabled={true}
                                                placeholder={t(
                                                  "DefaultSchoolName"
                                                )}
                                                {...register(
                                                  `StudentList.${index}.schoolName`,
                                                  {
                                                    // required: "This field is required",
                                                  }
                                                )}
                                              />
                                              {errors.StudentList?.[index]
                                                ?.schoolName && (
                                                  <span
                                                    role="alert"
                                                    className="error_text"
                                                  >
                                                    {
                                                      errors.StudentList?.[index]
                                                        ?.schoolName.message
                                                    }
                                                  </span>
                                                )}
                                            </>
                                          )}
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("placeholderGrade")}
                                            readOnly
                                            disabled={true}
                                            style={{
                                              minWidth: "50px",
                                              maxWidth: "50px",
                                            }}
                                            {...register(
                                              `StudentList.${index}.grade`,
                                              {
                                                // required: "This field is required",
                                              }
                                            )}
                                          />
                                          {errors.StudentList?.[index]
                                            ?.grade && (
                                              <span
                                                role="alert"
                                                className="error_text"
                                              >
                                                {
                                                  errors.StudentList?.[index]
                                                    ?.grade.message
                                                }
                                              </span>
                                            )}
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                      </>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="partB">
                      <h5>
                        <b>{t("PartBTitle")}</b>
                      </h5>
                      <ul>
                        <li>
                          {t("PartBDesc1")}
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            className="flex-row"
                            disabled={true}
                            value={
                              getValues("surveyBenifitField")
                                ? getValues("surveyBenifitField")
                                : ""
                            }
                          >
                            <FormControlLabel
                              className="radioBtn"
                              value="FDPIR"
                              label={t("RadioName1")}
                              disabled={true}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              className="radioBtn"
                              value="SNAP"
                              label={t("RadioName2")}
                              disabled={true}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              className="radioBtn"
                              value="TANF"
                              label={t("RadioName3")}
                              disabled={true}
                              control={<Radio />}
                            />
                            <FormControlLabel
                              className="radioBtn"
                              value="NA"
                              label={t("RadioName4")}
                              disabled={true}
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </li>
                        <li>
                          {t("PartBDesc2")} <b>{t("SkipPart")}</b>
                        </li>
                      </ul>
                      {getValues("surveyBenifitField") === "TANF" ||
                        getValues("surveyBenifitField") === "FDPIR" ||
                        getValues("surveyBenifitField") === "SNAP" ? (
                        <div className="row form mb-3">
                          <div className="col-md-4 input_div">
                            <label>{t("RadioSelectInput1")}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("RadioSelectPlaceholder1")}
                              disabled={true}
                              {...register(`caseName`, {
                                required: "This field is required",
                              })}
                            />
                            {errors.caseName && (
                              <span role="alert" className="error_text">
                                {errors.caseName.message}
                              </span>
                            )}
                          </div>{" "}
                          <div className="col-md-4 input_div">
                            <label>{t("RadioSelectInput2")}</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={t("RadioSelectPlaceholder2")}
                              disabled={true}
                              {...register(`caseNumber`, {
                                required: "This field is required",
                                minLength: {
                                  value: 10,
                                  message:
                                    "You have to enter minimum 10 number",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "You can enter max 10 number",
                                },
                              })}
                            />
                            {errors.caseNumber && (
                              <span role="alert" className="error_text">
                                {errors.caseNumber.message}
                              </span>
                            )}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="partC">
                      {getValues("surveyBenifitField") === "TANF" ||
                        getValues("surveyBenifitField") === "FDPIR" ||
                        getValues("surveyBenifitField") === "SNAP" ? (
                        ""
                      ) : (
                        <>
                          <h5>
                            <b>{t("PartCTitle")}</b>
                          </h5>
                          <h6>{t("PartCDesc1")}</h6>
                          <p>{t("PartCDesc2")}</p>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr style={{ backgroundColor: "#f3f3f3" }}>
                                  <th>{t("TableHead3")}</th>
                                  <th>{t("TableHead4")}</th>
                                  <th>{t("TableHead5")}</th>
                                  {Field1?.filter(
                                    (x) => x?.isStudent === false
                                  )?.filter(
                                    (x) => x?.houseHoldIncomeSub?.length > 0
                                  )?.length > 0 ? (
                                    <th></th>
                                  ) : (
                                    ""
                                  )}
                                  {/* {Field1?.filter(
                                    (x) => x?.isStudent === false
                                  )?.map(
                                    (item, index) =>
                                      item?.houseHoldIncomeSub?.length > 0 && (
                                        <th></th>
                                      )
                                  )} */}
                                </tr>
                              </thead>
                              <tbody>
                                {Field1?.filter(
                                  (x) => x?.isStudent === false
                                )?.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <tr key={index}>
                                      <td>
                                        <input
                                          type="date"
                                          className="form-control"
                                          disabled={true}
                                          style={{
                                            minWidth: "135px",
                                            // maxWidth: "135px",
                                          }}
                                          {...register(
                                            `houseHoldList.${index}.birthDate`,
                                            {
                                              required: t("RequiredValidation"),
                                              valueAsDate: true,
                                              validate: {
                                                isValidDate: (value) => {
                                                  if (value > new Date()) {
                                                    return t("DateValidation");
                                                  }
                                                },
                                              },
                                            }
                                          )}
                                        />
                                        {errors.houseHoldList?.[index]
                                          ?.birthDate && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.houseHoldList?.[index]
                                                  ?.birthDate.message
                                              }
                                            </span>
                                          )}
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          disabled={true}
                                          placeholder={t(
                                            "placeholderFirstName"
                                          )}
                                          {...register(
                                            `houseHoldList.${index}.firstName`,
                                            {
                                              required: t("RequiredValidation"),
                                            }
                                          )}
                                        />
                                        {errors.houseHoldList?.[index]
                                          ?.firstName && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.houseHoldList?.[index]
                                                  ?.firstName.message
                                              }
                                            </span>
                                          )}
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          disabled={true}
                                          placeholder={t("placeholderLastName")}
                                          {...register(
                                            `houseHoldList.${index}.lastName`,
                                            {
                                              required: t("RequiredValidation"),
                                            }
                                          )}
                                        />
                                        {errors.houseHoldList?.[index]
                                          ?.lastName && (
                                            <span
                                              role="alert"
                                              className="error_text"
                                            >
                                              {
                                                errors.houseHoldList?.[index]
                                                  ?.lastName.message
                                              }
                                            </span>
                                          )}
                                      </td>
                                      {item?.houseHoldIncomeSub?.length > 0 && (
                                        <td></td>
                                      )}
                                    </tr>
                                    <>
                                      {item?.houseHoldIncomeSub?.length > 0 && (
                                        <>
                                          <tr
                                            style={{
                                              backgroundColor: "#d4eed6",
                                            }}
                                          >
                                            <td></td>
                                            <td>{t("TableHead11")}</td>
                                            <td>{t("TableHead8")}</td>
                                            <td>{t("TableHead9")}</td>
                                            {/* <td></td> */}
                                          </tr>
                                          {item?.houseHoldIncomeSub?.map(
                                            (_, index1) => (
                                              <tr>
                                                <td></td>
                                                <td>
                                                  <select
                                                    disabled={true}
                                                    name=""
                                                    className="form-select"
                                                    {...register(
                                                      `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeSource`,
                                                      {
                                                        required:
                                                          t(
                                                            "RequiredValidation"
                                                          ),
                                                      }
                                                    )}
                                                  >
                                                    <option value="">
                                                      {t("SeIn")}
                                                    </option>
                                                    <option value="Work">
                                                      {t("Work")}
                                                    </option>
                                                    <option value="Public Assistance">
                                                      {t("PublicAssistance")}
                                                    </option>
                                                    <option value="Child Support">
                                                      {t("ChildSupport")}
                                                    </option>
                                                    <option value="Alimony">
                                                      {t("Alimony")}
                                                    </option>
                                                    <option value="Pension">
                                                      {t("Pension")}
                                                    </option>
                                                    <option value="Retirment">
                                                      {t("Retirment")}
                                                    </option>
                                                    <option value="Social Security">
                                                      {t("SocialSecurity")}
                                                    </option>
                                                    <option value="SSI">
                                                      {t("SSI")}
                                                    </option>
                                                    <option value="VA Benefits">
                                                      {t("VABenefits")}
                                                    </option>{" "}
                                                    <option value="Other">
                                                      {t("Other")}
                                                    </option>
                                                  </select>

                                                  {errors.houseHoldList?.[index]
                                                    ?.houseHoldIncomeSub?.[
                                                    index1
                                                  ]?.incomeSource && (
                                                      <span
                                                        role="alert"
                                                        className="error_text"
                                                      >
                                                        {
                                                          errors.houseHoldList?.[
                                                            index
                                                          ]?.houseHoldIncomeSub?.[
                                                            index1
                                                          ]?.incomeSource.message
                                                        }
                                                      </span>
                                                    )}
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    disabled={true}
                                                    className="form-control"
                                                    placeholder={t(
                                                      "placeholderActualIncome"
                                                    )}
                                                    {...register(
                                                      `houseHoldList.${index}.houseHoldIncomeSub.${index1}.incomeAmount`,
                                                      {
                                                        valueAsNumber: true,
                                                        // required: t("RequiredValidation"),
                                                        pattern: {
                                                          value:
                                                            /^\d+(\.\d{1,2})?$/,
                                                          message:
                                                            t(
                                                              "EnterActualIncome"
                                                            ),
                                                        },
                                                      }
                                                    )}
                                                  />
                                                  {errors.houseHoldList?.[index]
                                                    ?.houseHoldIncomeSub?.[
                                                    index1
                                                  ]?.incomeAmount && (
                                                      <span
                                                        role="alert"
                                                        className="error_text"
                                                      >
                                                        {
                                                          errors.houseHoldList?.[
                                                            index
                                                          ]?.houseHoldIncomeSub?.[
                                                            index1
                                                          ]?.incomeAmount.message
                                                        }
                                                      </span>
                                                    )}
                                                </td>
                                                <td>
                                                  <select
                                                    name=""
                                                    disabled={true}
                                                    className="form-select"
                                                    {...register(
                                                      `houseHoldList.${index}.houseHoldIncomeSub.${index1}.paymentCycle`
                                                    )}
                                                  >
                                                    <option value="">
                                                      {t("PayCycleDrop")}
                                                    </option>
                                                    <option value="Annual">
                                                      {t("PayCycleDropVal1")}
                                                    </option>
                                                    <option value="Monthly">
                                                      {t("PayCycleDropVal2")}
                                                    </option>
                                                    <option value="TwicePerMonth">
                                                      {t("PayCycleDropVal3")}
                                                    </option>
                                                    <option value="EveryTwoWeeks">
                                                      {t("PayCycleDropVal4")}
                                                    </option>
                                                    <option value="Weekly">
                                                      {t("PayCycleDropVal5")}
                                                    </option>
                                                  </select>
                                                  {errors.houseHoldList?.[index]
                                                    ?.houseHoldIncomeSub?.[
                                                    index1
                                                  ]?.paymentCycle && (
                                                      <span
                                                        role="alert"
                                                        className="error_text"
                                                      >
                                                        {
                                                          errors.houseHoldList?.[
                                                            index
                                                          ]?.houseHoldIncomeSub?.[
                                                            index1
                                                          ]?.paymentCycle.message
                                                        }
                                                      </span>
                                                    )}
                                                </td>
                                                {/* <td></td> */}
                                              </tr>
                                            )
                                          )}
                                        </>
                                      )}
                                    </>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <h6>{t("Part3b")}</h6>
                          <div className="row my-3">
                            <div className="col-md-4 input_div">
                              <label>{t("TotalChildAnnual")}</label>
                              <input
                                type="text"
                                disabled={true}
                                className="form-control"
                                placeholder={t("TotalChildAnnualPlaceholder")}
                                {...register(`totalChildIncome`, {
                                  required: t("RequiredValidation"),
                                  valueAsNumber: true,
                                })}
                              />
                              {errors.totalChildIncome && (
                                <span role="alert" className="error_text">
                                  {errors.totalChildIncome.message}
                                </span>
                              )}
                            </div>{" "}
                            <div className="col-md-4 input_div">
                              <label>{t("ChildPaymentCycleLabel")}</label>
                              <select
                                name=""
                                disabled={true}
                                className="form-select"
                                {...register("childPaymentCycle", {
                                  required: t("RequiredValidation"),
                                })}
                              >
                                <option value="">
                                  {t("ChildPaymentCycleDrop1")}
                                </option>
                                <option value="Annual">
                                  {t("PaymentCycleDrop2")}
                                </option>
                                <option value="Monthly">
                                  {t("PaymentCycleDrop3")}
                                </option>
                                <option value="TwicePerMonth">
                                  {t("PaymentCycleDrop4")}
                                </option>
                                <option value="EveryTwoWeeks">
                                  {t("PaymentCycleDrop5")}
                                </option>
                                <option value="Weekly">
                                  {t("PaymentCycleDrop6")}
                                </option>
                              </select>
                              {errors.childPaymentCycle && (
                                <span role="alert" className="error_text">
                                  {errors.childPaymentCycle.message}
                                </span>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                      <h4 className="text-center text-bg-light py-2">
                        {t("FormHeading")}
                      </h4>

                      <div className="row form mb-3">
                        <div className="col-md-4 input_div">
                          <label>{t("HhSize")}</label>
                          <input
                            type="text"
                            disabled={true}
                            className="form-control"
                            placeholder={t("HhSizePlaceholder")}
                            {...register(`NoOfPeoeple`, {
                              required: t("RequiredValidation"),
                            })}
                          />
                          {errors.NoOfPeoeple && (
                            <span role="alert" className="error_text">
                              {errors.NoOfPeoeple.message}
                            </span>
                          )}
                        </div>{" "}
                        <div className="col-md-4 input_div">
                          <label>{t("TotalAnnual")}</label>
                          <input
                            type="text"
                            disabled={true}
                            className="form-control"
                            placeholder={t("TotalAnnualPlaceholder")}
                            {...register(`totalActualIncome`, {
                              // required: t("RequiredValidation"),
                            })}
                          />
                          {errors.totalActualIncome && (
                            <span role="alert" className="error_text">
                              {errors.totalActualIncome.message}
                            </span>
                          )}
                        </div>{" "}
                        <div className="col-md-4 input_div">
                          <label>{t("PaymentCycleLabel")}</label>
                          <select
                            name=""
                            disabled={true}
                            className="form-select"
                            {...register("paymentCycle", {})}
                          >
                            <option value="">{t("PaymentCycleDrop1")}</option>
                            <option value="Annual">
                              {t("PaymentCycleDrop2")}
                            </option>
                            <option value="Monthly">
                              {t("PaymentCycleDrop3")}
                            </option>
                            <option value="TwicePerMonth">
                              {t("PaymentCycleDrop4")}
                            </option>
                            <option value="EveryTwoWeeks">
                              {t("PaymentCycleDrop5")}
                            </option>
                            <option value="Weekly">
                              {t("PaymentCycleDrop6")}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="partD">
                      <h5>
                        <b>{t("PartDTitle")}</b>
                      </h5>
                      <p>{t("PartDDesc")}</p>
                      <div className="row form">
                        <div className="col-md-4 input_div">
                          <label>{t("ParentFirstName")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("ParentFirstNamePlaceholder")}
                            disabled={true}
                            {...register(`firstName`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.firstName && (
                            <span role="alert" className="error_text">
                              {errors.firstName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t("TableHead5")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("placeholderLastName")}
                            disabled={true}
                            {...register(`fullName`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.fullName && (
                            <span role="alert" className="error_text">
                              {errors.fullName.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 mt-4">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`isSSN`, {
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("NoSSN")}
                          </label>
                          {errors.isSSN && (
                            <span role="alert" className="error_text">
                              {errors.isSSN.message}
                            </span>
                          )}{" "}
                        </div>
                        {watch("isSSN") === true ? (
                          ""
                        ) : (
                          <div className="col-md-4 input_div">
                            <label>{t("SSNNumber")}</label>
                            <input
                              type="number"
                              disabled={true}
                              className="form-control"
                              placeholder={t("placeholderSSNNumber")}
                              {...register(`ssnNumber`, {
                                required: t("RequiredValidation"),
                              })}
                            />
                            {errors.ssnNumber && (
                              <span role="alert" className="error_text">
                                {errors.ssnNumber.message}
                              </span>
                            )}
                          </div>
                        )}
                        <div className="col-md-4 input_div">
                          <label>{t("ParentAddress")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("ParentAddressPlaceholder")}
                            disabled={true}
                            {...register(`address`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.address && (
                            <span role="alert" className="error_text">
                              {errors.address.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t("ParentAPTNumber")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("ParentAPTPlaceholder")}
                            disabled={true}
                            {...register(`apartment`, {
                              // required: "This field is required",
                            })}
                          />
                          {errors.apartment && (
                            <span role="alert" className="error_text">
                              {errors.apartment.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t("ParentHomeMobile")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("ParentHomeMobilePlaceholder")}
                            disabled={true}
                            {...register(`phone`, {
                              required: "This field is required",
                              pattern: {
                                value:
                                  /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                                message: "Enter valid phone number",
                              },
                            })}
                          />
                          {errors.phone && (
                            <span role="alert" className="error_text">
                              {errors.phone.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t("ParentCity")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={t("ParentCityPlaceholder")}
                            disabled={true}
                            {...register(`city`, {
                              required: "This field is required",
                            })}
                          />
                          {errors.city && (
                            <span role="alert" className="error_text">
                              {errors.city.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label>{t("ParentState")}</label>
                          <select
                            name=""
                            className="form-select"
                            disabled={true}
                            {...register("state", {
                              required: "This field is required",
                              valueAsNumber: true,
                            })}
                          >
                            <option value="">
                              {t("ParentStatePlaceholder")}
                            </option>
                            {stateList &&
                              stateList?.map((data, i) => (
                                <option key={i} value={data?.stateId}>
                                  {data?.stateName}
                                </option>
                              ))}
                          </select>
                          {errors.state && (
                            <span role="alert" className="error_text">
                              {errors.state.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div">
                          <label> {t("ParentEmail")}</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={t("ParentEmailPlaceholder")}
                            disabled={true}
                            {...register(`emailConfirmationAddress`, {
                              // required: "This field is required",
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message:
                                  "Entered value does not match email format",
                              },
                            })}
                          />
                          {errors.emailConfirmationAddress && (
                            <span role="alert" className="error_text">
                              {errors.emailConfirmationAddress.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-4 input_div mt-2">
                          <label></label>
                          <label className="personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`isSummerEBT`, {
                                required: t("RequiredValidation"),
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("CheckBoxForCorrectInfo1")}
                          </label>
                          {errors.sign && (
                            <span role="alert" className="error_text">
                              {errors.sign.message}
                            </span>
                          )}{" "}
                        </div>
                        <div className="my-4">
                          <h5>{t("OptionalTitle")}</h5>
                          <p>{t("OptionalDesc")}</p>
                          <div>
                            <div className="row mb-2">
                              <div className="col-md-2">
                                <label>{t("EthnicityLabel")}</label>
                              </div>
                              <div className="col-md-6">
                                <label className="personal_detail_label">
                                  <input
                                    type="radio"
                                    disabled={true}
                                    className="login_check"
                                    name="isHispanic"
                                    id="hispanic"
                                    value={true}
                                    {...register(`isHispanic`, {
                                      // required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.value,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("Hispa1")}
                                </label>
                                {errors.isHispanic && (
                                  <span role="alert" className="error_text">
                                    {errors.isHispanic.message}
                                  </span>
                                )}
                              </div>

                              <div className="col-md-4">
                                <label className="personal_detail_label">
                                  <input
                                    type="radio"
                                    disabled={true}
                                    className="login_check"
                                    name="isHispanic"
                                    id="notHispanic"
                                    value={false}
                                    {...register(`isHispanic`, {
                                      // required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.value,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("NotHispa")}
                                </label>
                                {errors.isHispanic && (
                                  <span role="alert" className="error_text">
                                    {errors.isHispanic.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-2">
                                <label>{t("RaceLabel")}</label>
                              </div>
                              <div className="col-md-2">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    disabled={true}
                                    value={"isAmericanIndianAlaskaNative"}
                                    {...register(`isRace`, {
                                      // required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("fisrtRace")}
                                </label>
                                {errors.isRace && (
                                  <span role="alert" className="error_text">
                                    {errors.isRace.message}
                                  </span>
                                )}{" "}
                              </div>
                              <div className="col-md-2">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    disabled={true}
                                    value={"isAsian"}
                                    name="Remember Me"
                                    {...register(`isRace`, {
                                      // required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("SecondRace")}
                                </label>
                                {errors.isRace && (
                                  <span role="alert" className="error_text">
                                    {errors.isRace.message}
                                  </span>
                                )}{" "}
                              </div>
                              <div className="col-md-2">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    disabled={true}
                                    value={"isBlackAfricanAmerican"}
                                    {...register(`isRace`, {
                                      // required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("thirdRace")}
                                </label>
                                {errors.isRace && (
                                  <span role="alert" className="error_text">
                                    {errors.isRace.message}
                                  </span>
                                )}{" "}
                              </div>
                              <div className="col-md-2">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    disabled={true}
                                    value={
                                      "isNativeHawaiianOtherPacificIslande"
                                    }
                                    {...register(`isRace`, {
                                      // required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("fourRace")}
                                </label>
                                {errors.isRace && (
                                  <span role="alert" className="error_text">
                                    {errors.isRace.message}
                                  </span>
                                )}{" "}
                              </div>
                              <div className="col-md-2">
                                <label className=" personal_detail_label">
                                  <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    disabled={true}
                                    value={"isWhite"}
                                    {...register(`isRace`, {
                                      // required: t("RequiredValidation"),
                                      onChange: (e) =>
                                        handleChoise(
                                          e.target.checked,
                                          e.target.checked
                                        ),
                                    })}
                                  />
                                  <span className="checkmark"></span>
                                  {t("whiteName")}
                                </label>
                                {errors.isRace && (
                                  <span role="alert" className="error_text">
                                    {errors.isRace.message}
                                  </span>
                                )}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`isOtherStateEbtbenifit`, {
                                required: t("RequiredValidation"),
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("CheckBoxForCorrectInfo")}
                          </label>
                          {errors.isOtherStateEbtbenifit && (
                            <span role="alert" className="error_text">
                              {errors.isOtherStateEbtbenifit.message}
                            </span>
                          )}{" "}
                        </div>
                        <div className="col-md-9">
                          <label className=" personal_detail_label">
                            <input
                              type="checkbox"
                              disabled={true}
                              className="login_check"
                              name="Remember Me"
                              {...register(`sign`, {
                                required: t("RequiredValidation"),
                                onChange: (e) =>
                                  handleChoise(
                                    e.target.checked,
                                    e.target.checked
                                  ),
                              })}
                            />
                            <span className="checkmark"></span>
                            {t("FirstCheckBox")}
                          </label>
                          {errors.sign && (
                            <span role="alert" className="error_text">
                              {errors.sign.message}
                            </span>
                          )}{" "}
                        </div>
                        {watch("sign") ? (
                          <div className="col-md-4 input-div mt-2">
                            <input
                              disabled={true}
                              type="text"
                              className="form-control"
                              placeholder={t(
                                "CheckBoxForCorrectInfoAfterShowBox"
                              )}
                              {...register(`signatureName`, {
                                required: t("RequiredValidation"),
                              })}
                            />
                            {errors.signatureName && (
                              <span role="alert" className="error_text">
                                {errors.signatureName.message}
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {/*<>
                          <div className="col-md-12"></div>
                          <div className="col-md-8 mt-2">
                            <label>Notes</label>
                              <textarea
                                rows={4}
                                disabled={true}
                                type="text"
                                className="form-control"
                                placeholder="Enter Notes"
                                {...register(`notes`)}
                              />
                          </div>
                        </>*/}
                      </div>
                    </div>
                    <div className="mt-3 text-end">
                      <Button
                        className="cancel_btn"
                        onClick={() => window.close()}
                      >
                        {t("BackBtn")}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        )}
      </Suspense>
    </>
  );
}

export default HouseHoldMemberInformationShow;
