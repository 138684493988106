import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function DistrictManagement() {
  return (
    <Suspense fallback={renderLoader()}>
      <section>
        <div>Settings</div>
      </section>
    </Suspense>
  );
}

export default DistrictManagement;
