import React, { Suspense, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import signcalendar from "../../../../Assets/SvgImage/signcalendar.svg";
import wednesday from "../../../../Assets/SvgImage/wednesday.svg";
import calendar from "../../../../Assets/SvgImage/calenda-plus.svg";
import today from "../../../../Assets/SvgImage/today.svg";
import Charts from "../../../../Components/Reusable/Chart/Charts";
import CountBox from "./CountBox";
import { useSelector } from "react-redux";
import approve from "../../../../Assets/SvgImage/approve.svg";
import reject from "../../../../Assets/SvgImage/reject.svg";
import { GetCallApi } from "../../../../Action/Action";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function ParentDashboard() {
  const [selected, setSelected] = useState(0);
  const [stdList, setStdList] = useState([]);

  const [allStudentMealsCounts, setAllStudentMealsCounts] = useState();
  const [StudentDailyMealCount, setStudentDailyMealCount] = useState();
  const [studentMonthlyPayments, setStudentMonthlyPayments] = useState([]);
  const [pointsData, setPointsData] = useState({});
  const [overTimeData, setOverTimeData] = useState([]);

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const chartOptions3 = {
    chart: {
      type: "spline",
      inverted: false,
    },
    title: {
      align: "left",
      text: "",
      style: {
        fontSize: "22px",
        color: "#1A1A1A",
        fontWeight: "500",
      },
    },
    subTitle: {
      text: "",
    },
    yAxis: {
      title: {
        text: "",
        color: "#fff",
      },
      lineWidth: 0,
      maxPadding: 0.05,
      gridLineWidth: 1,
      crosshair: true,
      gridLineColor: "#ebebeb",
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },

    xAxis: {
      lineWidth: 0,
      gridLineColor: "#D9D9D9",
      gridLineWidth: 2,
      crosshair: true,
      categories: StudentDailyMealCount?.map(
        (item, i) => (item.date ? convertDate(item?.date?.split("T")[0]) : "")
        // x.date ? convertDate(x?.date?.split("T")[0]) : ""
      ),
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "13px",
          fontWeight: "500",
        },
      },
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#464E5F",
        fontWeight: "500",
        fontSize: "15px",
      },
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    // series: chart3,
    series: [
      {
        lineColor: "#67C587",
        color: "#67C587",
        name: "Breakfast",
        data: StudentDailyMealCount?.map((item, i) => item?.breakfastCount),
      },
      {
        lineColor: "#49C7DB",
        color: "#49C7DB",
        name: "Lunch",
        data: StudentDailyMealCount?.map((item, i) => item?.lunchCount),
      },
      {
        lineColor: "#F79153",
        color: "#F79153",
        name: "Dinner",
        data: StudentDailyMealCount?.map((item, i) => item?.dinnerCount),
      },
    ],
  };

  const chartOptions4 = {
    chart: {
      type: "column",
      height: 485 + "px",
    },
    title: {
      align: "left",
      text: "Monthly Payments",
      margin: 50,
      style: {
        fontSize: "22px",
        color: "#1A1A1A",
        fontWeight: "500",
      },
    },
    xAxis: {
      lineWidth: 1,
      lineColor: "#464E5F",
      categories: [
        "Jan",
        "Feb",
        "March",
        "April",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ],
      // categories: studentMonthlyPayments?.map((item, i) => (
      //   item.date ? convertDate(item?.date?.split("T")[0]) : ""
      //   // x.date ? convertDate(x?.date?.split("T")[0]) : ""
      // )),
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
      crosshair: true,
    },
    yAxis: {
      lineWidth: 1,
      lineColor: "#464E5F",
      min: 0,
      title: {
        text: "Payments",
        margin: 30,
        style: {
          color: "#464E5F",
          fontWeight: "500",
          fontSize: "17px",
        },
      },
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
      gridLineColor: "transparent",
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#464E5F",
        fontWeight: "500",
        fontSize: "15px",
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Months",
        data: studentMonthlyPayments,
        color: "#31B680",
      },
    ],
  };

  const chartOptions2 = {
    chart: {
      type: "spline",
      inverted: false,
    },
    title: {
      align: "left",
      text: "",
      style: {
        fontSize: "22px",
        color: "#1A1A1A",
        fontWeight: "500",
      },
    },
    subTitle: {
      text: "",
    },
    yAxis: {
      title: {
        text: "",
        color: "#fff",
      },
      lineWidth: 0,
      maxPadding: 0.05,
      gridLineWidth: 1,
      crosshair: true,
      gridLineColor: "#ebebeb",
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },

    xAxis: {
      lineWidth: 0,
      gridLineColor: "#D9D9D9",
      gridLineWidth: 2,
      crosshair: true,
      categories: overTimeData?.map(
        (item, i) => (item.date ? convertDate(item?.date?.split("T")[0]) : "")
        // x.date ? convertDate(x?.date?.split("T")[0]) : ""
      ),
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "13px",
          fontWeight: "500",
        },
      },
    },

    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#464E5F",
        fontWeight: "500",
        fontSize: "15px",
      },
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: 0,
      },
    },

    // series: chart3,
    series: [
      {
        lineColor: "#67C587",
        color: "#67C587",
        name: "Breakfast",
        data: overTimeData?.map((item, i) => item?.breakfastCount),
      },
      {
        lineColor: "#49C7DB",
        color: "#49C7DB",
        name: "Lunch",
        data: overTimeData?.map((item, i) => item?.lunchCount),
      },
      {
        lineColor: "#F79153",
        color: "#F79153",
        name: "Dinner",
        data: overTimeData?.map((item, i) => item?.dinnerCount),
      },
      {
        lineColor: "#544fc5",
        color: "#544fc5",
        name: "Snacks",
        data: overTimeData?.map((item, i) => item?.snacksCount),
      },
      {
        lineColor: "#000000",
        color: "#000000",
        name: "All Count",
        data: overTimeData?.map((item, i) => item?.allCount),
      },
    ],
  };

  let highchartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.name}: {point.y} </b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        showInLegend: true,
        colorByPoint: true,
        data: [
          {
            name: "Total Point",
            y: pointsData?.totalPoint,
          },
          {
            name: "Point Earned Today",
            y: pointsData?.pointEarnedToday,
          },
          {
            name: "Point Spent Today",
            y: pointsData.pointSpentToday,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    getStudentList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selected) {
      GetAllStudentMealsCounts(selected);
      GetStudentDailyMealCount(selected);
      GetStudentMonthlyPayments(selected);
      GetAllPointsSummary(selected);
      GetAllOverTime(selected);
    } else {
      setAllStudentMealsCounts();
      setStudentDailyMealCount([]);
      setStudentMonthlyPayments([]);
      setPointsData({});
      setOverTimeData([]);
    }
    // eslint-disable-next-line
  }, [selected]);

  const GetAllStudentMealsCounts = async (id) => {
    setAllStudentMealsCounts();
    let stdUrl = {
      url: `/api/StudentDashboard/GetAllStudentMealsCounts?studentGuidId=${id}&ParentGuiId=${LogDetail?.parentsGuidId}`,
    };
    const res = await GetCallApi(stdUrl);
    if (res?.status === 200) {
      if (res.data.success) {
        setAllStudentMealsCounts(res.data.payload);
      } else {
        setAllStudentMealsCounts();
      }
    }
  };

  const GetStudentDailyMealCount = async (id) => {
    setStudentDailyMealCount([]);
    let dailyUrl = {
      url: `/api/StudentDashboard/GetStudentDailyMealCount?studentGuidId=${id}`,
    };
    const res = await GetCallApi(dailyUrl);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload;
        setStudentDailyMealCount(dlist ?? []);
      }
    }
  };

  const GetStudentMonthlyPayments = async (id) => {
    setStudentMonthlyPayments([]);
    let monthUrl = {
      url: `/api/StudentDashboard/GetStudentMonthlyPayments?studentGuidId=${id}`,
    };
    const res = await GetCallApi(monthUrl);
    if (res?.status === 200) {
      if (res.data.success) {
        setStudentMonthlyPayments(res.data.payload);
      } else {
        setStudentMonthlyPayments();
      }
    }
  };

  const GetAllPointsSummary = async (id) => {
    setPointsData({});
    let apiUrl = {
      url: `/api/StudentDashboard/GetStudentPointSummary?studentGuidId=${id}`,
    };
    const resp = await GetCallApi(apiUrl);
    if (resp?.status === 200) {
      if (resp.data.success) {
        setPointsData(resp.data.payload ?? {});
      }
    }
  };

  const GetAllOverTime = async (id) => {
    setOverTimeData([]);
    let overTimeUrl = {
      url: `/api/StudentDashboard/GetStudentDailyMealPointCount?studentGuidId=${id}`,
    };
    const resp = await GetCallApi(overTimeUrl);
    if (resp?.status === 200) {
      if (resp.data.success) {
        setOverTimeData(resp.data.payload ?? []);
      }
    }
  };

  const getStudentList = async () => {
    let seUrl = {
      url: `/api/ParentModule/GetStudentListByParentID?parentGuidId=${LogDetail?.parentsGuidId}`,
    };
    let res = await GetCallApi(seUrl);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.studentGuidId,
            label: sd?.studentName,
          };
        });
        setStdList(dlist?.length > 0 ? dlist : []);
        setSelected(dlist[0]?.value);
      }
    }
  };

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [mnth, day, date.getFullYear()].join("/");
  }

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <section className="parent_dashboard">
          <div className="main_wrapper">
            <div className="hed_top d-flex align-items-center justify-content-between">
              <div className="all_title">Dashboard</div>
              <div className="form-group m-0">
                <select
                  className="form-select"
                  value={selected}
                  onChange={(e) => setSelected(e.target.value)}
                >
                  {/* <option value="">Select Student</option> */}
                  {stdList.map((item, i) => (
                    <option key={i} value={item?.value}>
                      {item?.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="admin_main_box mt-4">
              <div className="row">
                <div className="col-md-12">
                  <div className="meal_main h-100">
                    {/* <div className="row h-100"> */}
                    <div className="d-flex gap-4">
                      <CountBox
                        image={wednesday}
                        name="Weekly Meals Counts"
                        num={
                          allStudentMealsCounts?.weeklyMealsCounts
                            ? allStudentMealsCounts?.weeklyMealsCounts
                            : 0
                        }
                      />
                      <CountBox
                        image={calendar}
                        name="Monthly Meals Counts"
                        num={
                          allStudentMealsCounts?.montlyMealsCounts
                            ? allStudentMealsCounts?.montlyMealsCounts
                            : 0
                        }
                      />
                      <CountBox
                        image={signcalendar}
                        name="Weekly Cost"
                        num={
                          allStudentMealsCounts?.weeklyCost
                            ? allStudentMealsCounts?.weeklyCost
                            : 0
                        }
                      />
                      <CountBox
                        image={today}
                        name="Monthly Cost"
                        num={
                          allStudentMealsCounts?.monthlyCost
                            ? allStudentMealsCounts?.monthlyCost
                            : 0
                        }
                      />
                      <CountBox
                        image={
                          allStudentMealsCounts?.isHouseHoldSurvey === true
                            ? approve
                            : reject
                        }
                        name="Submitted Survey"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-3">
                  <div className="chart_box_1 box__shadow pb-0">
                    <div className="chart_hed mb-3">
                      <div className="d-flex align-items-center justify-content-between ">
                        <h4>Daily Meal Count</h4>
                        {/* <p className="m-0">1-March-2023, 30-March-2023</p> */}
                      </div>
                      {/* <div className="sales_count">
                        <h6>Months</h6>
                      </div> */}
                    </div>
                    <Charts series={chartOptions3} />
                  </div>
                </div>
                <div className="col-md-7 mt-3">
                  <div className="chart_box_1 box__shadow ">
                    <Charts series={chartOptions4} />
                  </div>
                </div>
                <div className="col-md-5 mt-3">
                  <div className="chart_box_1 box__shadow pb-0">
                    <div className="chart_hed mb-3">
                      <div className="d-flex justify-content-center">
                        <h4>Student Points Summary</h4>
                      </div>

                      <Charts
                        series={{
                          ...highchartOptions,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-7 mt-3">
                  <div className="chart_box_1 box__shadow pb-0">
                    <div className="chart_hed mb-3">
                      <div className="d-flex align-items-center justify-content-between ">
                        <h4>Daily Meal Point Count</h4>
                      </div>
                    </div>
                    <Charts series={chartOptions2} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ParentDashboard;
