import React, { useCallback, Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";
// import file from "../../../../Assets/PngImage/profile_img.png";
import editicon from "../../../../Assets/PngImage/edit-icon.png";
import CropModal from "../../../../Components/Reusable/CropModel";
import { Config } from "../../../../Utils/Config";
import web_profile_img from "../../../../Assets/default_profile.jpg";
import { LoginUser } from "../../../../reducers/login";

// import Demo from "./newCrop";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function UserProfile() {
  // const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  function handleChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  }
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("ProfileImage", url);
        setValue("profileImage12", url.name);
        setCroppedImageUrl(URL.createObjectURL(url));
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );

  const [distList, setDistList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [schoolList, setSchoolList] = useState();
  const [overlaySelect, setOverlaySelect] = useState([]);

  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const headMenu = useSelector((state) => state.headerMenu.LoginHeadList);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  let dispatch = useDispatch();
  const onSubmit = (data) => {
    let sendData = {
      ...data,
      createdBy: LogDetail?.userId,
      schoolId: overlaySelect,
    };
    setOpenCircle(true);
    Axios.post("/api/User/UpdateUserProfile", sendData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let userData = res.data.payload;
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            let sendData = {
              ...LogDetail,
              firstName: userData?.firstName,
              lastName: userData?.lastName,
              email: userData?.email,
              mobile: userData?.mobile,
              profileImage: userData?.profileImage,
            };
            dispatch(LoginUser(sendData));
            setCroppedImageUrl();
            GetAllUserData();
            reset();
            // navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  useEffect(() => {
    getStateList();
    getDistrictList();
    getSchoolList(LogDetail?.schoolDistrictId);
    GetAllUserData();
    // eslint-disable-next-line
  }, []);
  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let stlist = res.data.payload?.filter(
              (x) => x.stateId === LogDetail?.state
            )[0];
            setValue("stateName", stlist?.stateName);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const getDistrictList = () => {
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            const dlist = res.data.payload?.map((sd) => {
              return {
                schId: sd?.schoolDistrictId,
                districtName: sd?.districtName,
              };
            });
            let schList = dlist?.filter(
              (x) => x?.schId === LogDetail?.schoolDistrictId
            );
            setDistList(schList);
            setTimeout(() => {
              setValue("schoolDistrictId", LogDetail?.schoolDistrictId);
            }, 500);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const GetAllUserData = () => {
    setOpenCircle(true);
    Axios.get(`/api/User/GetUserDataById?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          setOpenCircle(false);
          let catData = res.data.payload;
          setValue("firstName", catData?.firstName);
          setValue("lastName", catData?.lastName);
          setValue("mobile", catData?.mobile);
          setValue("email", catData?.email);
          setValue("profileImage", catData?.profileImage);
          setOverlaySelect(catData?.schoolId);
          // setValue("schoolDistrictName", catData?.schoolDistrictName);
          setValue("roleName", catData?.roleName);
          setValue("roleId", catData?.roleId);
          setValue("state", catData?.state);

          setValue("userId", catData?.userId);
        } else {
          setOpenCircle(false);
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const getSchoolList = (id) => {
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              const SdData = res.data.payload?.map((sd, i) => {
                return { label: sd.schoolName, value: sd.schoolId };
              });
              setSchoolList(SdData);
            }
          }
        })
        .catch((e) => console.log(e));
    } else {
      setSchoolList([]);
    }
  };

  const handleIOCMulti = (e) => {
    setOverlaySelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const [src, setSrc] = useState(null);
  // const [src1, setSrc1] = useState(null);

  // const handleImage = async (event) => {
  //   setSrc(URL.createObjectURL(event.target.files[0]));
  //   setSrc1(event.target.files[0]);
  // };
  // const showCroppedImage = useCallback((url) => {
  //   if (url) {
  //     setSrc(false);
  //     setValue("ProfileImage", url);
  //     setCroppedImageUrl(URL.createObjectURL(url));

  //     // HandleCoverPhoto(croppedImage);
  //   }
  // }, []);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createUserProfile">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">User Profile</h4>
            <div className="card">
              <div className="card-body">
                {/* <Demo /> */}
                {src && (
                  <CropModal
                    src={src}
                    filename={filename}
                    setCroppedImageUrl={setCroppedImageUrlCallback}
                    openCropModal={openCropModal}
                    setCropModal={setCropModal}
                  />
                )}
                {/* {src && (
                  <NewCrop
                    src={src}
                    src1={src1}
                    filename={filename}
                    setCroppedImageUrl={showCroppedImage}
                    setSrc={setSrc}
                  />
                )} */}
                <form
                  action=""
                  className="primary-box"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="user-profile">
                        <img
                          src={
                            croppedImageUrl
                              ? croppedImageUrl
                              : LogDetail?.profileImage
                              ? `${Config.API_HOST_URL_live}${LogDetail?.profileImage}`
                              : web_profile_img
                          }
                          alt="Profile"
                          className="img-fluid"
                        />
                        <input
                          type="text"
                          className="form-control upload_box"
                          placeholder="No file Chosen"
                          readOnly
                          style={{ display: "none" }}
                          {...register("profileImage12", {})}
                        />
                        <input
                          type="file"
                          id="file"
                          accept="image/*"
                          className="form-control"
                          style={{ display: "none" }}
                          // onChange={handleImage}
                          onChange={handleChange}
                        />
                        <label htmlFor="file" className="edit-icon">
                          <img
                            src={editicon}
                            alt="Edit Icon"
                            title="Edit Icon"
                          />
                        </label>
                      </div>
                      {errors?.profileImage && (
                        <span role="alert" className="error_text">
                          {errors.profileImage.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          First Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          {...register("firstName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.firstName && (
                          <span role="alert" className="error_text">
                            {errors.firstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Last Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          {...register("lastName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.lastName && (
                          <span role="alert" className="error_text">
                            {errors.lastName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Phone Number <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          {...register("mobile", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        {errors.mobile && (
                          <span role="alert" className="error_text">
                            {errors.mobile.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Email <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School District <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          disabled
                          {...register("schoolDistrictId", {
                            required: "This field is required",
                            onChange: (e) => {
                              getSchoolList(e.target.value);
                            },
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.schId}>
                                {data?.districtName}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          School <span className="red-color">*</span>{" "}
                        </label>
                        <Select
                          isMulti
                          name="type"
                          options={schoolList}
                          isDisabled
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select School"
                          value={schoolList?.filter((obj) =>
                            overlaySelect?.includes(obj.value)
                          )}
                          onChange={handleIOCMulti}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Role Name <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          disabled
                          {...register("roleName", {
                            required: "This field is required",
                            pattern: {
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                        />
                        {errors.roleName && (
                          <span role="alert" className="error_text">
                            {errors.roleName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          State <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          disabled
                          {...register("stateName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.stateName && (
                          <span role="alert" className="error_text">
                            {errors.stateName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Set Profile
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(headMenu[0]?.menuUrl)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default UserProfile;
