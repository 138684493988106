import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
// import userprofileimg from "../../../../../Assets/SvgImage/userprofileimg.svg";
// import john from "../../../../../Assets/SvgImage/john-profile.svg";
import Axios from "../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function HouseholdInformationTab() {
  const [openCircle, setOpenCircle] = useState(false);
  const [parentList, setParentList] = useState([]);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    GetParentInfo();
    // eslint-disable-next-line
  }, []);
  const GetParentInfo = () => {
    setOpenCircle(true);
    Axios.get(
      `/api/Student/GetStudentProfileParentInfromation?studentId=${LogDetail?.studentGuidId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setParentList(res.data.payload);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="household-section">
          <div className="row">
            {parentList?.map((prt, i) => (
              <div className="col-lg-6 col-md-12 " key={i}>
                <div className="household-box">
                  <div className="user-profile-block">
                    <div className="profile-content">
                      <h3>{prt?.parentName} </h3>
                      {/* <p>Mother </p> */}
                    </div>
                    {/* <img src={userprofileimg} alt="Profile" /> */}
                  </div>
                  <ul>
                    <li>
                      <p>Address :</p>
                      <span>{prt?.address}</span>
                    </li>
                    <li>
                      <p>Number :</p>
                      <span>{prt?.primaryPhoneNumber}</span>
                    </li>{" "}
                    <li>
                      <p>Alternative Number : </p>
                      <span> {prt?.alternatePhoneNumber}</span>
                    </li>
                    <li>
                      <p>Email :</p>
                      <span>{prt?.email}</span>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
            {/* <div className="col-lg-6 col-md-12 first-block">
              <div className="household-box">
                <div className="user-profile-block">
                  <div className="profile-content">
                    <h3>Marry Deny </h3>
                    <p>Mother </p>
                  </div>
                  <img src={userprofileimg} alt="Profile" />
                </div>
                <ul>
                  <li>
                    <p>Address :</p>
                    <span>
                      12-B teriocada near higway georgia. 142568,georgia
                    </span>
                  </li>
                  <li>
                    <p>Number :</p>
                    <span>1425689725</span>
                  </li>
                  <li>
                    <p>Email :</p>
                    <span>Marry@gmail.com</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="household-box">
                <div className="user-profile-block">
                  <div className="profile-content">
                    <h3>John Deny</h3>
                    <p>Father</p>
                  </div>
                  <img src={john} alt="Profile" />
                </div>
                <ul>
                  <li>
                    <p>Address :</p>
                    <span>
                      12-B teriocada near higway georgia. 142568,georgia
                    </span>
                  </li>
                  <li>
                    <p>Number :</p>
                    <span>1425689725</span>
                  </li>
                  <li>
                    <p>Email :</p>
                    <span>JohnDeny@gmail.com</span>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </Suspense>
    </>
  );
}

export default HouseholdInformationTab;
