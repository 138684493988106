import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
// import { useSelector } from "react-redux";
// import { Config } from "../../../../../Utils/Config";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function DailySalesReportTab(props) {
  const { tableRef, repoData, startDate, endDate, back, school } = props;

  return (
    <Suspense fallback={renderLoader()}>
      <section className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <img
              src={
                // downloadPdf
                //   ?
                require("../../../../../Assets/PngImage/Header_logo_1.png")
                // : `${Config.API_HOST_URL_live}/Files/Logo/logo.png`
              }
              alt=""
              width={100}
              height={80}
            />
            <div className="ms-2">
              <h5>Daily Meal Report</h5>
              <p>
                {startDate?.toLocaleDateString() +
                  " - " +
                  endDate?.toLocaleDateString()}
              </p>
            </div>
          </div>
          <div>
            <h5>{school}</h5>
            <p className="mb-0">{school?.address}</p>
            <p>
              {school?.city} {school?.zip ? ", " + school?.zip : ""}
            </p>
          </div>
        </div>

        {/* <div className="border-dash"></div> */}
        {/* <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">Daily Meal Report</h5> */}
        <div className="report-table">
          {/* <h6>Cash Audit</h6> */}
          <div className={"table-box cash-border"}>
            <h6 className="title-width">Date</h6>
            <h6 className="title-width">Meal Service</h6>
            <h6 className="title-width">Ala Carte</h6>
            <h6 className="title-width">Reimbursable Meal Count</h6>
          </div>
          {repoData?.map((text, i) => (
            <div key={i} className={"table-box "}>
              <h6 className="title-width">
                {new Date(text?.date).toLocaleDateString()}
              </h6>
              <h6 className="title-width">{text?.mealService}</h6>
              <h6 className="title-width">{text?.mealCount}</h6>
              <h6 className="title-width">{text?.reimbersibleCount}</h6>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </section>
    </Suspense>
  );
}

export default DailySalesReportTab;
