import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import {
    GetCallApi,
    PostCallApi,
} from "../../../../../../Action/Action";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

function CreateExpenceSubCategory() {

    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [distList, setDistList] = useState([]);
    const [expCategoryList, setExpCategoryList] = useState([]);
    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();


    useEffect(() => {
        if (id) {
            getEditedData(id);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        const districtList = LogDetail?.districtClasses?.map((x) => {
            return {
                value: x?.schoolDistrictId,
                label: x?.schoolDistrictName,
            }
        })
        setDistList(districtList ?? []);
        if (!id) {
            setTimeout(() => {
                setValue("schoolDistrictId", districtList[0]?.value);
                if (!id) {
                    getExpCategory("", districtList[0]?.value);
                }
            }, 1000);
        }
        // eslint-disable-next-line
    }, []);


    const getEditedData = async (id) => {
        setOpen(true);

        let sendData = {
            url: `/api/ExpenceSubCategory/GetExpenceSubCategoryDataByID?expenceCategoryId=${id}`,
        };

        let res = await GetCallApi(sendData);
        setOpen(false);
        if (res?.status === 200) {
            if (res?.data.success) {
                let editsiteData = res?.data?.payload;
                setValue("schoolDistrictId", editsiteData?.schoolDistrictId);
                setValue("expenceSubCategoryName", editsiteData?.expenceSubCategoryName);
                setValue("expenceSubCategoryNumber", editsiteData?.expenceSubCategoryNumber);
                setValue("sequenceNo", editsiteData?.sequenceNo);
                setValue('expenceSubCategoryId', id)
                getExpCategory(editsiteData?.expenceCategoryId, editsiteData?.schoolDistrictId);
            }
        }
    };

    const getExpCategory = async (expId, scId) => {
        let expUrl = {
            url: `/api/ExpenceCategory/GetAllExpenceCategoryData?active=${'true'}`,
            body: {
                schoolDistrictId: scId ? scId : "00000000-0000-0000-0000-000000000000",
                schoolId: []
            }
        }
        let resp = await PostCallApi(expUrl);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                let mapped = resp?.data?.payload?.map((x) => {
                    return {
                        value: x?.expenceCategoryId,
                        label: x?.expenceCategoryName
                    }
                })
                setExpCategoryList(mapped ? mapped : []);
                if (expId) {
                    setTimeout(() => {
                        setValue('expenceCategoryId', expId)
                    }, 300);
                }
            }
        }
    }

    const onSubmit = async (data) => {
        setOpen(true);

        let expData = {
            ...data,
            expenceSubCategoryNumber: data?.expenceSubCategoryNumber?.toString() ? data?.expenceSubCategoryNumber?.toString() : "",
            createdBy: LogDetail?.userId
        }

        let seData = {
            url: `/api/ExpenceSubCategory/CreateExpenceSubCategory`,
            body: expData,
        };
        let respo = await PostCallApi(seData);
        setOpen(false);
        if (respo?.status === 200) {
            if (respo.data.success) {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
                navigate(-1)
            } else {
                setOpen(false);
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo?.data?.message
                        ? respo?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            setOpen(false);
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: respo?.data?.message
                    ? respo?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="create_ExpenceSubCategory">
                    <div className="main_wrapper">
                        <div className="ProductCategories_hed">
                            <div className="all_title ProductTitle">
                                {id ? "Edit" : "Create"} Expense Sub Category
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action=""
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="primary-box"
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    District <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register(`schoolDistrictId`, {
                                                        required: "This field is required",
                                                        onChange: (e) => getExpCategory("", e.target.value)
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {distList &&
                                                        distList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors?.schoolDistrictId && (
                                                    <span role="alert" className="error_text">
                                                        {errors?.schoolDistrictId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Expense Category <span className="red-color">*</span>{" "}
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("expenceCategoryId", {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select Expense Category</option>
                                                    {expCategoryList?.map((item, i) => (
                                                        <option value={item.value} key={i}>
                                                            {item.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.expenceCategoryId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.expenceCategoryId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Expense Sub Category Name <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Expense Sub Category Name"
                                                    {...register("expenceSubCategoryName", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 150,
                                                            message: "Max 150 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.expenceSubCategoryName && (
                                                    <span role="alert" className="error_text">
                                                        {errors.expenceSubCategoryName.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Expense Sub Category Number <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Expense Sub Category Number"
                                                    {...register("expenceSubCategoryNumber", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 150,
                                                            message: "Max 150 characters",
                                                        },
                                                    })}
                                                />
                                                {errors.expenceSubCategoryNumber && (
                                                    <span role="alert" className="error_text">
                                                        {errors.expenceSubCategoryNumber.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Sequence No <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min={1}
                                                    placeholder="Sequence No"
                                                    {...register("sequenceNo", {
                                                        required: "This field is required",
                                                        valueAsNumber: true,
                                                    })}
                                                />
                                                {errors.sequenceNo && (
                                                    <span role="alert" className="error_text">
                                                        {errors.sequenceNo.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-12 text-end">
                                            <Button type="submit" className="theme_btn me-2">
                                                Submit
                                            </Button>
                                            <Button
                                                className="cancel_btn"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default CreateExpenceSubCategory;
