import React from "react";
import "./index.scss";
import { Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Fade from "@mui/material/Fade";
function SmallPop({
  anchorEl,
  open,
  handleClose,
  className,
  optionsMenu,
  megamenu,
  wideSubMenu,
}) {
  let location = useLocation();
  let nav = useNavigate();
  const handleName = (name) => {
    nav(name, { replace: true });
    handleClose();
  };
  const handleclosepop = () => {
    handleClose();
  };
  return (
    <>
      {open ? (
        <Menu
          className={`${className} ${megamenu?megamenu:"blockreport_box"} ${wideSubMenu?"wideSubMenu":""}`}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleclosepop}
          TransitionComponent={Fade}
        >
          {optionsMenu?.map((option, i) => (
            <MenuItem
              key={i + 1}
              onClick={() => handleName(option?.menuUrl)}
              className={`${location.menuUrl === option.menuUrl ? "ac" : ""}`}
            >
              {/* {option.menuIcon ? (
                <i className={`icon fas fa-${option.menuIcon}`}></i>
              ) : (
                <i className="icon fas fa-bars"></i>
              )} */}
              <div className="bac">
                {option?.menuName} <span>{option?.description}</span>
              </div>
            </MenuItem>
          ))}
        </Menu>
      ) : (
        ""
      )}
    </>
  );
}

export default React.memo(SmallPop);
