import React, { Suspense, useEffect, useState } from "react";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { GetCallApi, PostCallApi } from "../../../../../../Action/Action";
import Swal from "sweetalert2";
import { FiEye, FiEyeOff } from "react-icons/fi";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateSmtpConfig() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  let navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (id) {
      getEditedData(id);
    }
    // eslint-disable-next-line
  }, []);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const getEditedData = async (id) => {
    setOpen(true);

    let sendData = {
      url: `/api/SmtpConfiguration/GetSmtpDataById?SmtpId=${id}`,
      headers: headers,
    };

    let res = await GetCallApi(sendData);
    setOpen(false);
    if (res?.status === 200) {
      if (res.data.success) {
        let data = res?.data?.payload;
        setValue("serviceProvider", data?.serviceProvider);
        setValue("smtpServer", data?.smtpServer);
        setValue("port", data?.port);
        setValue("username", data?.username);
        setValue("password", data?.password);
        setValue("apiKey", data?.apiKey);
        setValue("clientId", data?.clientId);
      }
    }
  };

  const onSubmit = async (data) => {
    setOpen(true);
    let sendData;
    if (id) {
      sendData = {
        ...data,
        clientId: data?.clientId ? data?.clientId : null,
        createdBy: LogDetail?.userId,
        smtpid: id,
        schoolDistrictId: LogDetail?.schoolDistrictId,
      };
    } else {
      sendData = {
        ...data,
        clientId: data?.clientId ? data?.clientId : null,
        createdBy: LogDetail?.userId,
        schoolDistrictId: LogDetail?.schoolDistrictId,
      };
    }
    let seData = {
      url: `/api/SmtpConfiguration/CreateSmtp`,
      body: sendData,
      headers: headers,
    };

    let respo = await PostCallApi(seData);
    setOpen(false);
    if (respo?.status === 200) {
      if (respo.data.success) {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        reset();
        navigate(-1);
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo?.data?.message
            ? respo?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setOpen(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: respo?.data?.message
          ? respo?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="create_smtp">
          <div className="main_wrapper">
            <div className="ProductCategories_hed">
              <div className="all_title ProductTitle">
                {id ? "Edit Smtp Configurations" : "Create Smtp Configurations"}
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Service Provider <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Service Provider"
                          {...register("serviceProvider", {
                            required: "This field is required",
                          })}
                        />
                        {errors.serviceProvider && (
                          <span className="formError errorMssg error_text">
                            {errors.serviceProvider.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Server <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Server Name"
                          {...register("smtpServer", {
                            required: "This field is required",
                          })}
                        />
                        {errors.smtpServer && (
                          <span className="formError errorMssg error_text">
                            {errors.smtpServer.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          SMTP Port <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="SMTP Port"
                          {...register("port", {
                            required: "This field is required",
                          })}
                        />
                        {errors.port && (
                          <span className="formError errorMssg error_text">
                            {errors.port.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          User Name <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="User Name"
                          {...register("username", {
                            required: "This field is required",
                          })}
                        />
                        {errors.username && (
                          <span className="formError errorMssg error_text">
                            {errors.username.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Password <span className="red-color">*</span>
                        </label>
                        <div className="d-flex position-relative align-items-center">
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            {...register("password", {
                              required: {
                                value: true,
                                message: "This field is required",
                              },
                              minLength: {
                                value: 8,
                                message: "Must be 8 characters",
                              },
                              maxLength: {
                                value: 15,
                                message: "Max 15 characters",
                              },
                            })}
                          />

                          <span
                            className="eye"
                            onClick={() => setPasswordShown(!passwordShown)}
                          >
                            {passwordShown ? (
                              <FiEye color="#31B680" />
                            ) : (
                              <FiEyeOff color="#31B680" />
                            )}
                          </span>
                        </div>
                        {errors.password && (
                          <span className="formError errorMssg error_text">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          API Key
                          {/* <span className="red-color">*</span> */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="API Key"
                          {...register("apiKey", {
                            // required: "This field is required",
                          })}
                        />
                        {/* {errors.apiKey && (
                                                    <span className="formError errorMssg error_text">
                                                        {errors.apiKey.message}
                                                    </span>
                                                )} */}
                      </div>
                    </div>
                    {id ? (
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Client Id
                            {/* <span className="red-color">*</span> */}
                          </label>
                          <input
                            type="text"
                            disabled
                            className="form-control"
                            placeholder="Client Id"
                            {...register("clientId")}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-12 text-end mt-3">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateSmtpConfig;
