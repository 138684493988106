import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Modal } from "@mui/material";
import uploadimg from "../../../../../../../Assets/SvgImage/file-upload.svg";
import { useForm } from 'react-hook-form';
import "./index.scss";
import { useSelector } from 'react-redux';
import { GetCallApi } from '../../../../../../../Action/Action';
import CropModal from '../../../../../../../Components/Reusable/CropModel';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxHeight: "80%",
    overflow: "auto",
    background: "#ffffff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
};

function ProdPopUp(props) {

    const { openPopup, setOpenPopup, prodId, setProdId, catList, prodEdit, setCatList, sendAllProduct, updateProduct } = props

    const { register, handleSubmit, setValue, clearErrors, formState: { errors }, } = useForm();

    const LogDetail = useSelector((state) => state.login.LoginDetails);

    useEffect(() => {
        GetCategoryList();
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        clearErrors();
        setProdId('');
        setOpenPopup(false);
    };

    useEffect(() => {
        if (prodEdit) {
            if (prodEdit?.ProductId) {
                setValue("ProductId", prodEdit?.ProductId);
            }
            setValue("ProductName", prodEdit?.ProductName);
            setValue("CategoryId", prodEdit?.CategoryId);
            setValue("Quantity", prodEdit?.Quantity);
            setValue("Price", prodEdit?.Price);
            setValue("ProductImageUrl", prodEdit?.ProductImageUrl);
            setValue("ProductImage", prodEdit?.ProductImage);
            setValue("ProductImageUrlShow", prodEdit?.ProductImageUrlShow);
            setValue("Description", prodEdit?.Description);
        }
        // eslint-disable-next-line
    }, [prodEdit]);

    const GetCategoryList = async () => {
        let apUrl = {
            url: `/api/ProductCategory/GetAllProductCategoryData?userId=${LogDetail?.userId}`
        }
        const res = await GetCallApi(apUrl)
        if (res?.status === 200) {
            if (res.data.success) {
                let comList = res?.data?.payload?.map((c, i) => {
                    return {
                        catId: c?.productCategoryId,
                        catName: c?.productCategoryName,
                    };
                });
                setCatList(comList);
            }
        }
    };

    const AddProduct = (data) => {
        if (prodId !== null && prodId !== undefined && prodId !== "") {
            updateProduct(prodId, { ...data });
            handleClose();
        } else {
            sendAllProduct({ ...data })
            handleClose();
        }
    }

    const [src, setSrc] = useState(null);
    const [filename, setFileName] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [openCropModal, setCropModal] = useState(false);

    const setCroppedImageUrlCallback = useCallback(
        (url) => {
            if (url) {
                setValue("ProductImageUrl", url.name);
                setValue("ProductImageUrlShow", URL.createObjectURL(url));
                setValue("ProductImage", url);
                setCroppedImageUrl(url);
                setSrc(null);
            } else {
                setSrc(null);
            }
        },
        // eslint-disable-next-line
        [croppedImageUrl]
    );

    const handleProductImg = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            setFileName(e.target.files[0].name);
            reader.addEventListener(
                "load",
                () => {
                    setSrc(reader.result);
                    setCropModal(true);
                },
                false
            );
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null;
        }
    };

    return (
        openPopup && (
            <>
                {src && (
                    <CropModal
                        src={src}
                        filename={filename}
                        setCroppedImageUrl={setCroppedImageUrlCallback}
                        openCropModal={openCropModal}
                        setCropModal={setCropModal}
                    />
                )}
                <Modal
                    open={openPopup}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="product_manage_popup"
                >
                    <Box sx={style} className="delete_data_box">
                        <div
                            className="popup_delete_detail_box"
                            style={{
                                display: "inline-block",
                                overflow: "auto",
                            }}
                        >
                            <div className="p-4">
                                <div className="product_box mb-3">
                                    <div className="all_title ProductTitle">
                                        {prodId !== null && prodId !== undefined && prodId !== "" ? "Edit" : "Create"} Product
                                    </div>
                                </div>
                                <form onSubmit={handleSubmit(AddProduct)}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Product Name <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Product Name"
                                                    {...register("ProductName", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 1000,
                                                            message: "Max 1000 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.ProductName && (
                                                    <span role="alert" className="error_text">
                                                        {errors.ProductName.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group  ">
                                                <label className="form-label">
                                                    Select Category <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    className={`form-select`}
                                                    aria-label="Default select example"
                                                    {...register("CategoryId", {
                                                        required: "This field is required",
                                                        valueAsNumber: true,
                                                    })}
                                                >
                                                    <option value="">Select Category</option>
                                                    {catList &&
                                                        catList?.map((mn, i) => (
                                                            <option value={mn?.catId} key={i}>
                                                                {mn?.catName}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.CategoryId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.CategoryId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Quantity <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Quantity"
                                                    {...register("Quantity", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Max 50 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.Quantity && (
                                                    <span role="alert" className="error_text">
                                                        {errors.Quantity.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Unit Price <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Unit Price"
                                                    {...register("Price", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 50,
                                                            message: "Max 50 characters",
                                                        },
                                                        pattern: {
                                                            value: /^\d+(\.\d{1,2})?$/,
                                                            message:
                                                                "Please enter correct format  ex. 0.75 or 12",
                                                        },
                                                        // pattern: {
                                                        //     value: /^[^\s]+(\s+[^\s]+)*$/,
                                                        //     message: "Starting and Ending Space not allowed",
                                                        // },
                                                    })}
                                                />
                                                {errors.Price && (
                                                    <span role="alert" className="error_text">
                                                        {errors.Price.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Product Image  <span className="red-color">*</span></label>
                                                <div className="upload_product_logo">
                                                    <input
                                                        type="text"
                                                        className="form-control upload_box"
                                                        placeholder="No file Chosen"
                                                        readOnly
                                                        {...register("ProductImageUrl", {
                                                            required: "This field is required",
                                                        })}
                                                    />
                                                    <input
                                                        type="file"
                                                        id="_upload_1"
                                                        accept="image/*"
                                                        className="form-control"
                                                        style={{ display: "none" }}
                                                        {...register("ProductImage")}
                                                        onChange={handleProductImg}
                                                    />
                                                    <label
                                                        htmlFor="_upload_1"
                                                        className="upload_img_label mb-0 "
                                                    >
                                                        <img
                                                            src={uploadimg}
                                                            className="img-fluid"
                                                            alt=""
                                                        />
                                                    </label>
                                                </div>
                                                {errors.ProductImageUrl && (
                                                    <span role="alert" className="error_text">
                                                        {errors.ProductImageUrl.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            <div className="form-group">
                                                <label>
                                                    Product Description <span className="red-color">*</span>{" "}
                                                </label>
                                                <textarea
                                                    rows={2}
                                                    className="form-control"
                                                    placeholder="Product Description"
                                                    {...register("Description", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 255,
                                                            message:
                                                                "Description cannot exceed 255 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.Description && (
                                                    <span role="alert" className="error_text">
                                                        {errors.Description.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-end mt-3">
                                        <Button type="submit" className="theme_btn">
                                            {prodId !== null && prodId !== undefined && prodId !== "" ? "Edit" : "Submit"}
                                        </Button>
                                        <Button
                                            type="button"
                                            className="cancel_btn mx-3"
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </>
        )
    )
}

export default ProdPopUp