import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress, Button } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetCallApi } from "../../../../../../../Action/Action";

const ListTable = lazy(() =>
  import("../../../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "status", label: "Status" },
  { id: "balanceupdated", label: "Balance Updated Manually" },
  { id: "studentName", label: "Student Name" },
  { id: "studentNumber", label: "Student Number" },
  { id: "schoolName", label: "School Name" },
  { id: "districtName", label: "District Name" },
  { id: "schoolreceives", label: "School Receives" },
  { id: "transactionFees", label: "Transaction Fees" },
  { id: "swanSolution", label: "Swan Solution Fees" },
  { id: "totalAmount", label: "Total Amount" },
  { id: "stripePayment", label: "Stripe Payment Reference" },
  { id: "paymentDate", label: "Payment Date" },
  { id: "paymentDateUtc", label: "Payment Date UTC" },
];
function BalanceHistory(props) {
  const { id } = useParams();
  const [openCircle, setOpenCircle] = useState(false);

  const [payhistory, setPayhistory] = useState([]);
  // const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getPayhis();
    // eslint-disable-next-line
  }, []);
  const getPayhis = async () => {
    let seData = {
      url: `/api/payment/paymentHistory?customerId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data?.success) {
        let pyh = res.data.payload;
        setPayhistory(pyh);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="mb-40">
          <Button className="theme_btn">Excel</Button>
        </div>
        <ListTable headCells={productHead} ListData={payhistory} />
      </Suspense>
    </>
  );
}

export default BalanceHistory;
