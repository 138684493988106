import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
// import Axios from "../../../../../Utils/AxiosConfig";
// import { Box, Modal } from "@mui/material";
// import Select from "react-select";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { GetCallApi } from "../../../../../Action/Action";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 600,
//   background: "#ffffff",
//   display: "flex",
//   justifyContent: "center",
//   flexDirection: "column",
// };
const productHead = [
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "schoolDistrictName", label: "School District" },
  { id: "roleName", label: "Role Name" },
  { id: "stateName", label: "State Name" },
  { id: "jobTitle", label: "Job Title" },
  { id: "email", label: "Email" },
  { id: "mobile", label: "Phone" },
  { id: "schoolName", label: "School Name" },
];
function UserManagement() {
  // const [open, setOpen] = React.useState(false);
  // const [parentList, setParentList] = useState([]);
  // const [parentsGuidId, setParentsGuidId] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  // const [usId, setUsId] = useState();

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  const [openCircle, setOpenCircle] = useState(false);


  const [prdList, setPrdList] = useState([]);
  // const handleOpen = (e) => {
  //   setOpen(true);
  //   setUsId(e);
  //   getSelectedDistrictList(e);
  // };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const handleClose = () => {
  //   setOpen(false);
  //   setUsId();
  //   setParentsGuidId([]);
  // };

  // const handleIOCMulti = (e) => {
  //   setParentsGuidId(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  // const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  // const headers = {
  //   Authorization: `Bearer ${token}`,
  //   "Content-Type": "application/json",
  //   Accept: "*/*",
  // };

  useEffect(() => {
    // SettingsUserData();
    // getDistrictList();
    roleWiseSchool();
    onSubmit();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const roleWiseSchool = async () => {
    let apiUrl = {
      url: LogDetail?.roleId === 1 ? `/api/School/GetSchoolList` : `/api/School/GetSchoolAssignedDataByUserId?userId=${LogDetail?.userId}`,
    }
    let resp = await GetCallApi(apiUrl);
    if (resp?.status === 200 && resp?.data?.success) {
      let allList = resp?.data?.payload?.map((x) => {
        return {
          value: x?.schoolId,
          label: x?.schoolName
        }
      })
      setSchoolList(allList ?? [])
    }
  }

  // const SettingsUserData = () => {
  //   setOpenCircle(true);
  //   Axios.get(`/api/User/GetUserList?userId=${LogDetail?.userId}`, { headers })
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res.data.success) {
  //           setOpenCircle(false);

  //           const SdData = res.data.payload?.map((sd, i) => {
  //             return {
  //               ...sd,
  //               // joinDate: sd?.joinDate,
  //               Action: (
  //                 <>
  //                   {sd?.roleName === "Area Manager" ? (
  //                     <Link
  //                       type="button"
  //                       className="btn_edit me-2"
  //                       onClick={() => handleOpen(sd?.userId)}
  //                     >
  //                       <BsPlusSquareDotted fontSize={24} className="me-3" />
  //                     </Link>
  //                   ) : (
  //                     ""
  //                   )}
  //                   <Link
  //                     type="button"
  //                     className="btn_edit me-2"
  //                     to={`edit/${sd?.userId}`}
  //                   >
  //                     <MdEdit fontSize={24} />
  //                   </Link>
  //                   <DeletePopup
  //                     title={"Delete User"}
  //                     text={sd?.firstName + " " + sd?.lastName}
  //                     url={`/api/User/DeleteUser?userId=${sd?.userId}`}
  //                   />
  //                 </>
  //               ),
  //             };
  //           });
  //           setPrdList(SdData);
  //         } else {
  //           setOpenCircle(false);
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       setOpenCircle(false);
  //       console.log(e);
  //     });
  // };

  // const getDistrictList = () => {
  //   Axios.get(
  //     `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
  //     { headers }
  //   )
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res.data.success) {
  //           const dlist = res.data.payload?.map((sd) => {
  //             return {
  //               value: sd?.schoolDistrictId,
  //               label: sd?.districtName,
  //             };
  //           });
  //           setParentList(dlist?.length > 0 ? dlist : []);
  //         }
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };
  // const getSelectedDistrictList = (e) => {
  //   Axios.get(
  //     `/api/UserDistrictAllocation/GetUserDistrictAllocationDataUserWise?userId=${e}`,
  //     { headers }
  //   )
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res.data.success) {
  //           const dlist = res.data.payload?.map((sd) => sd?.schoolDistrictId);
  //           setParentsGuidId(dlist);
  //         }
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };
  // const handleAdd = () => {
  //   let sdData = {
  //     userId: usId,
  //     schoolDistrictId: parentsGuidId,
  //     isAllocated: true,
  //     createdBy: LogDetail?.userId,
  //   };
  //   Axios.post(
  //     `/api/UserDistrictAllocation/CreateUserDistrictAllocation`,
  //     sdData,
  //     { headers }
  //   )
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res.data.success) {
  //           handleClose();

  //           // setParentList(dlist?.length > 0 ? dlist : []);
  //         }
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  const onSubmit = async (data) => {
    setPrdList([]);
    setOpenCircle(true);
    let apiUrl = {
      url: `/api/User/GetUserList?userId=${LogDetail?.userId}&schoolId=${data?.school ? data?.school : ''}`
    }
    const res = await GetCallApi(apiUrl);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res.data.success) {
        const SdData = res.data.payload?.map((sd, i) => {
          return {
            ...sd,
            Action: (
              <>
                {/* {sd?.roleName === "Area Manager" ? (
                  <Link
                    type="button"
                    className="btn_edit me-2"
                    onClick={() => handleOpen(sd?.userId)}
                  >
                    <BsPlusSquareDotted fontSize={24} className="me-3" />
                  </Link>
                ) : (
                  ""
                )} */}
                <Link
                  type="button"
                  className="btn_edit me-2"
                  to={`edit/${sd?.userId}`}
                >
                  <MdEdit fontSize={24} />
                </Link>
                <DeletePopup
                  title={"Delete User"}
                  text={sd?.firstName + " " + sd?.lastName}
                  url={`/api/User/DeleteUser?userId=${sd?.userId}`}
                />
              </>
            ),
          };
        });
        setPrdList(SdData);
      } else {
        setOpenCircle(false);
      }
    }
  }

  return (
    <>
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop> */}
      <Suspense fallback={renderLoader()}>
        <section className="user_list_filter">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="all_title mb-4">User List</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label>
                          School
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("school", {
                          })}
                        >
                          <option value="">Select School</option>
                          {schoolList &&
                            schoolList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                        {errors.schoolDistrictId && (
                          <span role="alert" className="error_text">
                            {errors.schoolDistrictId.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className=" ms-auto mt-auto col-md-3">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end me-2">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}

                            />
                          </Button>
                        ) : (
                          <>
                            <Button className="theme_btn text-end me-3" type="submit">
                              <img src={filter} className="me-1" alt="" />
                              Apply
                            </Button>
                          </>
                        )}
                        <Link to={"Create"} className="theme_btn">
                          <BsPlusSquareDotted fontSize={24} className="me-3" />
                          Create
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                />
              </div>
            </div>
          </div>
        </section>
        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="toggle_delete_data"
        >
          <Box sx={style} className="districtList_box">
            <div className="districtList_details">
              <h6>Select District</h6>
              <div className="inner_sec">
                <form action="" className="mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>District Name</label>
                        <Select
                          isMulti
                          name="type"
                          options={parentList}
                          // closeMenuOnSelect={false}
                          // hideSelectedOptions={false}
                          className="form-neselect"
                          classNamePrefix="select"
                          placeholder="Select District"
                          value={parentList?.filter((obj) =>
                            parentsGuidId?.includes(obj.value)
                          )}
                          onChange={handleIOCMulti}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
                <div className="d-flex align-items-center justify-content-center">
                  <button
                    type="button"
                    className="popup_btn delete"
                    onClick={handleAdd}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="popup_btn cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Box>
        </Modal> */}
      </Suspense>
    </>
  );
}

export default UserManagement;
