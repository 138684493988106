import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateManufacturer() {
  const [openCircle, setOpenCircle] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login.LoginDetails?.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (id) {
      EditManufacture(id);
    }

    // eslint-disable-next-line
  }, [id]);

  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };

    Axios.post("/api/Manufacturer/CreateManufacturer", sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const EditManufacture = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/Manufacturer/GetManufacturerDataByID?ManufacturerId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let useDe = res.data.payload;
            setValue("manufacturerId", useDe?.manufacturerId);
            // setValue("firstName", useDe?.firstName);
            // setValue("lastName", useDe?.lastName);
            setValue("phoneNumber", useDe?.phoneNumber);
            setValue("manufacturerName", useDe?.manufacturer1);
            setValue("glnnumber", useDe?.glnnumber);
            setValue("email", useDe?.email);
            // setValue("password", useDe?.password);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createprimarytab-page">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Manufacturer</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          First Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          {...register("firstName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.firstName && (
                          <span role="alert" className="error_text">
                            {errors.firstName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Last Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          {...register("lastName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.lastName && (
                          <span role="alert" className="error_text">
                            {errors.lastName.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Manufacturer Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Manufacturer Name"
                          {...register("manufacturerName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.manufacturerName && (
                          <span role="alert" className="error_text">
                            {errors.manufacturerName.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">
                          GLN Number <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="GLN number"
                          {...register("glnnumber", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            pattern: {
                              // value:
                              //   /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              value: /^[0-9+-]+$/,
                              message: "Enter Valid Number",
                            },
                            minLength: {
                              value: 13,
                              message: "Require GLN Number Minimum 13 Digits",
                            },
                          })}
                        />
                        {errors.glnnumber && (
                          <span role="alert" className="error_text">
                            {errors.glnnumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                          {...register("phoneNumber", {
                            // required: "This field is required",
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        {errors.phoneNumber && (
                          <span role="alert" className="error_text">
                            {errors.phoneNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          {...register("email", {
                            // required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Password <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          {...register("password", {
                            required: {
                              value: true,
                              message: "This field is required",
                            },
                            minLength: {
                              value: 8,
                              message: "Must be 8 characters",
                            },
                            maxLength: {
                              value: 15,
                              message: "Max 15 characters",
                            },
                          })}
                        />
                        {errors.password && (
                          <span role="alert" className="error_text">
                            {errors.password.message}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateManufacturer;
