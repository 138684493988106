import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";

const ListTable = lazy(() =>
    import("../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const productHead = [
    { id: "parentName", label: "Parent Name" },
    { id: "studentName", label: "Student Name" },
    { id: "message", label: "Remark" },
    { id: "createdon", label: "Created On" },
];
function CommunicationHistory() {
    const [openCircle, setOpenCircle] = useState(false);

    const [prdList, setPrdList] = useState([]);
    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };
    useEffect(() => {
        if (LogDetail?.parentsGuidId) {
            HouseholdCommunicationTabData(LogDetail?.parentsGuidId);
        }
        // eslint-disable-next-line
    }, [LogDetail?.parentsGuidId]);

    const HouseholdCommunicationTabData = (id) => {
        setOpenCircle(true);
        Axios.get(
            `/api/Parents/GetCommunicationHistoryByParentId?parentGuidId=${id}`,
            { headers }
        )
            .then((res) => {
                if (res?.status === 200) {
                    if (res.data.success) {
                        setOpenCircle(false);
                        const SdData = res.data.payload?.map((sd, i) => {
                            return {
                                parentName: sd?.parentName,
                                studentName: sd?.studentName,
                                message: sd?.message,
                                createdon: sd?.dateOfCommunication,
                            };
                        });
                        setPrdList(SdData);
                    } else {
                        setOpenCircle(false);
                    }
                }
            })
            .catch((e) => {
                setOpenCircle(false);
                console.log(e);
            });
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openCircle}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="parent_list ">
                    <div className="main_wrapper">
                        <div className="all_title ProductTitle">Communication History</div>
                        <div className="white-box">
                            <ListTable headCells={productHead} ListData={prdList} />
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default CommunicationHistory;
