import React, { lazy, useEffect, useState } from "react";
import "./index.scss";
import Charts from "../../../../Components/Reusable/Chart/Charts";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { useSelector } from "react-redux";
import CashierSession from "./CashierDashboardReport/CashierSession";
import EditCheck from "./EditCheck";

const CashierTable = lazy(() => import("./Table/CashierTable"));

let highchartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: "pie",
  },
  title: {
    text: "",
  },
  tooltip: {
    headerFormat: "",
    // pointFormat: "{point.name}: <b>{point.y:.0f}</b>",
    formatter() {
      var output1 = `${this.point.y?.toLocaleString(
        undefined,
        undefined
      )}</b></span>`;
      return output1;
    },
    // pointFormat: "{point.name}: <b>{point.percentage:.2f}%</b>",
    // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
  },
  // accessibility: {
  //   point: {
  //     valueSuffix: "%",
  //   },
  // },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      dataLabels: {
        connectorWidth: 0,
        enabled: true,
        formatter: function () {
          return `<b>${this.point.name}</b><br>${this.point.y?.toLocaleString(
            undefined,
            undefined
          )}`;
        },
        distance: 20,
      },
      showInLegend: false,
    },
  },
  series: [
    {
      // name: "Brands",
      colorByPoint: true,
      data: [
        {
          name: "Edge",
          y: 18.82,
          color: "#FD2254",
        },
        {
          name: "Firefox",
          y: 14.63,
          color: "#FEA800",
        },
        {
          name: "Other",
          y: 15.28,
          color: "#00B7FE",
        },
        {
          name: "Chrome",
          y: 20.77,
          color: "#D0D2DA",
        },
      ],
    },
  ],
};

const MyDashboard = (props) => {
  const { curDate, distId } = props;
  const [chartData, setChartData] = useState();
  const [chart1, setChart1] = useState([]);

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  let newDate = new Date().toISOString().substr(0, 10);

  useEffect(() => {
    GetParticipationDailyCount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (distId && curDate) {
      getChartData();
    }
    // eslint-disable-next-line
  }, [distId, curDate]);

  const getChartData = async (sch, date) => {
    let sendData = {
      selectDate: curDate,
      schoolDistrictId: distId,
    };
    let seData = {
      url: `/api/Dashbord/GetMealsSoldChart`,
      body: sendData,
      headers: headers,
    };
    setChartData();

    let res = await PostCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        let de = res.data.payload;

        setChartData(de);
      }
    }
  };

  const GetParticipationDailyCount = async () => {
    let seData = {
      url: `/api/Dashbord/GetParticipationDailyCount?userId=${LogDetail?.userId}`,
      headers: headers,
    };

    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        setChart1(res.data.payload);
      } else {
      }
    }
  };

  const chartOptions1 = {
    chart: {
      type: "column",
      height: 485 + "px",
    },
    title: {
      align: "left",
      text: "Site Transaction Volume",
      margin: 50,
      style: {
        fontSize: "22px",
        color: "#1A1A1A",
        fontWeight: "500",
      },
    },
    xAxis: {
      lineWidth: 1,
      lineColor: "#464E5F",
      categories: chart1?.map((x) => x?.siteName),
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
      crosshair: true,
    },
    yAxis: {
      lineWidth: 1,
      lineColor: "#464E5F",
      min: 0,
      title: {
        text: "",
        margin: 30,
        style: {
          color: "#464E5F",
          fontWeight: "500",
          fontSize: "17px",
        },
      },
      labels: {
        style: {
          color: "#464E5F",
          fontSize: "15px",
          fontWeight: "500",
        },
      },
      gridLineColor: "transparent",
    },
    legend: {
      layout: "vertical",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: {
        color: "#464E5F",
        fontWeight: "500",
        fontSize: "15px",
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:10px;color:{series.color}"><b>{point.key}</b></span>: ',
      // pointFormat:
      //   // '<><td style="color:{series.color};padding:0">{series.name}: </td>' +

      //   '<span style="padding:0"><b>{point.y:.1f} </b></span>',
      footerFormat: "</table>",
      formatter() {
        var output1 = ` <span style="font-size:10px;color:${
          this.series.color
        }"><b>${
          this.x
        }</b></span>: <span style="padding:0"><b>${this.point.y?.toLocaleString(
          "en-US",
          { style: "currency", currency: "USD" }
        )}</b></span>`;
        return output1;
      },
      // shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "School Name",
        data: chart1?.map((x) => x?.counts),
        color: "#49C7DB",
      },
      // {
      //   name: "Reimbursable Meal",
      //   data: totalMealsDaily?.map((item, i) => item?.reme),
      //   color: "#31B680",
      // },
    ],
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-xl-4 col-lg-4 col-12 mb-3 mb-lg-0">
          <div className="wht-box">
            <h5 className="text-center ">Meals Sold</h5>

            <Charts
              series={{
                ...highchartOptions,
                series: [
                  {
                    data:
                      chartData?.length > 0
                        ? chartData?.map((x) => {
                            return {
                              y: x?.counts,
                              name: x?.mealService,
                            };
                          })
                        : [],
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="col-xl-8 col-lg-8 col-12">
          <div className="wht-box">
            <Charts series={chartOptions1} />
          </div>
        </div>
      </div>

      <div className="wht-box mt-3">
        <CashierSession
          dateNew={curDate ? curDate : newDate}
          district={distId}
        />
      </div>

      <div className="wht-box mt-3">
        <CashierTable
          fileName="Cashier Session Report"
          cDate={curDate ? curDate : newDate}
          distId={distId}
        />
      </div>

      <div className="wht-box mt-3">
        <EditCheck cDate={curDate ? curDate : newDate} distId={distId} />
      </div>
    </>
  );
};

export default MyDashboard;
