import Axios from "../Utils/AxiosConfig";

export const GetCallApi = async (data) => {
  try {
    const response = await Axios.get(data?.url, {
      headers: data?.headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
export const PostCallApi = async (data) => {
  try {
    const response = await Axios.post(data?.url, data.body, {
      headers: data?.headers,
    });
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
export const DeleteCallApi = async (data) => {
  try {
    const response = await Axios.delete(data?.url, { headers: data?.headers });
    return response;
  } catch (error) {
    console.log(error);
    return error?.response;
  }
};
