import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GetCallApi } from "../../../../../../../Action/Action";

const ListTable = lazy(() =>
  import("../../../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "templateName", label: "Template Name" },
  { id: "school", label: "School" },
  { id: "customerName", label: "Customer Name" },
  { id: "createdby", label: "CreatedBy" },
  { id: "createdon", label: "CreatedOn" },
];
function EmailHistory(props) {
  const { id } = useParams();
  const [openCircle, setOpenCircle] = useState(false);

  const [emailhistory, setEmailhistory] = useState([]);

  // const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getPayhis();
    // eslint-disable-next-line
  }, []);
  const getPayhis = async () => {
    let seData = {
      url: `/api/payment/paymentHistory?customerId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await GetCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data?.success) {
        let eml = res.data.payload;
        setEmailhistory(eml);
      }
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <ListTable headCells={productHead} ListData={emailhistory} />
      </Suspense>
    </>
  );
}

export default EmailHistory;
