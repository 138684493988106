import React, { useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
//import { SetHomeRoom, SetMealTypeFor } from "../../../reducers/Common";

function EditCashierSessionReport(props) {
  const { id } = useParams();
  const [cashCheckData, setCashCheckData] = useState([]);
  const [mealService, setMealService] = useState();
  const [overShortCash, setOverShortCash] = useState(0);
  const [totalCheckAm, setTotalCheckAm] = useState(0);
  const [overShortCheck, setOverShortCheck] = useState(0);
  // const [productivity, setProductivity] = useState();
  const [checkSelectList, setCheckSelectList] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [openCircle, setOpenCircle] = React.useState(false);
  function mathRound(val) {
    return (Math.round(val * 100) / 100).toFixed(2);
  }
  useEffect(() => {
    if (id) {
      EditMealSession(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const EditMealSession = async (id) => {
    let sedata = {
      url: `/api/CashierSessionApproval/GetCashierSessionDataBySessionId?SessionId=${id}`,
      body: {},
      headers: headers,
    };
    setOpenCircle(true);

    let res = await PostCallApi(sedata);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let SdData = res.data.payload[0];

        if (!SdData?.isMealServiceEnded) {
          let secal = {
            url: "/api/DepositApproval/GetCashAndCheckCollectionOfEndMealSession",
            body: {
              ...SdData,
              cashierSessionId: SdData?.sessionId,
            },
            headers: headers,
          };
          let newRes = await PostCallApi(secal);
          if (newRes?.status === 200) {
            if (newRes?.data.success) {
              let se = newRes.data.payload;
              SdData = {
                ...se,
                ...SdData,
                cashExpectedAmount: se?.cashTotal,
                mealServed: se?.mealsServed,
              };
              setCashCheckData(SdData);

              setValue(
                "mealServed",
                SdData?.mealServed ? SdData?.mealServed : 0
              );
              setValue(
                "cashExpectedAmount",
                SdData?.cashExpectedAmount ? SdData?.cashExpectedAmount : 0
              );
              setValue("mealService", SdData?.mealService);

              setMealService(SdData?.mealService);
              setValue("cashCollected", SdData?.cashCollected);
              setOverShortCash(
                SdData?.cashOverOrShort ? SdData?.cashOverOrShort : 0
              );
              setOverShortCheck(
                SdData?.checkOverOrShort ? SdData?.checkOverOrShort : 0
              );
              setValue(
                "mealServed",
                SdData?.mealServed ? SdData?.mealServed : 0
              );
              setValue(
                "cashExpectedAmount",
                SdData?.cashExpectedAmount ? SdData?.cashExpectedAmount : 0
              );
              let am = SdData?.checkList
                ?.filter((x) => x.checkStatus === true)
                ?.map((x) => x.amount);

              am = am?.reduce(
                (acc, current) => parseFloat(acc) + parseFloat(current),
                0
              );
              setTotalCheckAm(am);
              setCheckSelectList(SdData?.checkList);
            }
          }
        } else {
          setCashCheckData(SdData);

          setValue("mealService", SdData?.mealService);

          setMealService(SdData?.mealService);
          setValue("cashCollected", SdData?.cashCollected);
          setOverShortCash(
            SdData?.cashOverOrShort ? SdData?.cashOverOrShort : 0
          );
          setOverShortCheck(
            SdData?.checkOverOrShort ? SdData?.checkOverOrShort : 0
          );
          setValue("mealServed", SdData?.mealServed ? SdData?.mealServed : 0);
          setValue(
            "cashExpectedAmount",
            SdData?.cashExpectedAmount ? SdData?.cashExpectedAmount : 0
          );
          let am = SdData?.checkList
            ?.filter((x) => x.checkStatus === true)
            ?.map((x) => x.amount);

          am = am?.reduce(
            (acc, current) => parseFloat(acc) + parseFloat(current),
            0
          );
          setTotalCheckAm(am);
          setCheckSelectList(SdData?.checkList);
        }

        // setCashCheckCollection(SdData?.cashCollected);
      } else {
        setOpenCircle(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setOpenCircle(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: res?.data?.message
          ? res?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 1500,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  const onSubmit = async (data, edml) => {
    let sendData = {
      ...cashCheckData,
    };
    sendData = {
      ...sendData,
      ...data,
      isMealServiceEnded: true,
      userId: cashCheckData?.userId,
      createdBy: LogDetail?.userId,
      checkList: checkSelectList,
      bulkMealEntry: 0,
      cashOverOrShort: overShortCash,

      mealService: mealService,
      checkExpectedAmount: cashCheckData?.checkExpectedAmount,
      totalCheckCollected: totalCheckAm,
      checkOverOrShort: overShortCheck,
      revenueOverOrShort:
        parseFloat(overShortCash) + parseFloat(overShortCheck),
    };
    let seData = {
      url: edml
        ? "/api/UserLogin/UpdateCashierSessions"
        : "/api/UserLogin/UpdateCashierEndMealSessionDate",
      body: sendData,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        reset();

        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
        navigate(-1);
      } else {
        setOpenCircle(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      setOpenCircle(false);
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: res?.data?.message
          ? res?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  const setCashCheckCollection = (value, cea) => {
    // setTotalAmountCollect(event.target.value);
    if (value) {
      let os = parseFloat(cea ? cea : 0) - parseFloat(value);
      setOverShortCash(mathRound(os));
    } else {
      setOverShortCash(cea ? cea : 0);
    }
  };
  // const handleCheckAmount = (e, d) => {
  //   if (e) {
  //     let ad = totalCheckAm + d?.amount;
  //     ad = mathRound(ad);
  //     ad = parseFloat(ad);
  //     setTotalCheckAm(ad);
  //     let s = overShortCheck - d?.amount;
  //     s = mathRound(s);
  //     s = parseFloat(s);
  //     setOverShortCheck(s);
  //   } else {
  //     let ad = totalCheckAm - d?.amount;
  //     ad = mathRound(ad);
  //     ad = parseFloat(ad);
  //     setTotalCheckAm(ad);
  //     let s = overShortCheck + d?.amount;
  //     s = mathRound(s);
  //     s = parseFloat(s);
  //     setOverShortCheck(s);
  //   }
  //   setCheckSelectList((old) =>
  //     old?.map((x) => {
  //       if (x.mealOrderPaymentId === d?.mealOrderPaymentId) {
  //         return { ...x, checkStatus: e };
  //       }
  //       return x;
  //     })
  //   );
  // };

  // Service type popup
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn_success",
      cancelButton: "btn btn_success",
    },
    buttonsStyling: true,
  });
  const handleMeal = (e, d) => {
    if (e) {
      swalWithBootstrapButtons
        .fire({
          position: "center",
          width: "50%",
          // icon: "warning",
          title: `Alert`,
          text: `If you change the Meal Service here, it will change all order with this session Meal Service. Do you still want to change the Meal Servicer ?`,
          // text: `Are you sure you want to change Service type ${d}  to  ${e}  ? `,
          showCancelButton: true,
          cancelButtonColor: "#E64942",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
          confirmButtonColor: "#31b680",
        })
        .then((result) => {
          if (result.isConfirmed) {
            // setValue("mealService", e);
            setMealService(e);
          } else {
            setMealService(d);
          }
        });
    } else {
    }
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="cashier_report-page">
        <div className="main_wrapper">
          <h4 className="all_title mb-4">Edit Cashier Session Report</h4>
          <div className="card">
            <div className="card-body">
              <form className="mt-3">
                {/* <form className="mt-3" onSubmit={handleSubmit(onSubmit)}> */}
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cashier Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="cashierName"
                        disabled={true}
                        value={
                          cashCheckData?.cashierName
                            ? cashCheckData?.cashierName
                            : ""
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label>Date</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        placeholder="Date"
                        disabled={true}
                        value={cashCheckData?.endTime}
                      />
                    </div>
                  </div> */}

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Service Type</label>
                      <select
                        className="form-select"
                        value={mealService}
                        // {...register("mealService", {
                        //   required: "This field is required",

                        onChange={(e) =>
                          handleMeal(e.target.value, mealService)
                        }
                        // })}
                      >
                        <option value="Breakfast">Breakfast</option>
                        <option value="Lunch">Lunch</option>
                        <option value="Dinner">Dinner</option>
                        <option value="Snacks">Snacks</option>
                      </select>
                      {/* {errors.mealService && (
                        <span className="formError errorMssg error_text">
                          {errors.mealService.message}
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Meal Served</label>
                      <input
                        type="number"
                        className="form-control"
                        disabled={true}
                        placeholder="Meal Served"
                        {...register("mealServed", {
                          required: "This field is required",
                        })}
                      />
                      {errors.mealServed && (
                        <span className="formError errorMssg error_text">
                          {errors.mealServed.message}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cash Expected Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled={true}
                        placeholder="Expected Amount"
                        {...register("cashExpectedAmount", {
                          required: "This field is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message:
                              "Enter valid Number the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 75",
                          },
                          onChange: (e) =>
                            setCashCheckCollection(
                              getValues("cashCollected"),
                              e.target.value
                            ),
                        })}
                      />
                      {errors.cashExpectedAmount && (
                        <span className="formError errorMssg error_text">
                          {errors.cashExpectedAmount.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cash Collected</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Cash Collected"
                        min="1"
                        {...register("cashCollected", {
                          required: "This field is required",
                          pattern: {
                            value: /^\d+(\.\d{1,2})?$/,
                            message:
                              "Enter valid Number the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 75",
                          },
                          onChange: (e) =>
                            setCashCheckCollection(
                              e.target.value,
                              getValues("cashExpectedAmount")
                            ),
                        })}
                      />
                      {errors.cashCollected && (
                        <span className="formError errorMssg error_text">
                          {errors.cashCollected.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Cash Over/Short</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Over/Short"
                        disabled={true}
                        value={overShortCash}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Check Expected Amount</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Check Expected Amount"
                        disabled={true}
                        value={
                          cashCheckData?.checkExpectedAmount
                            ? cashCheckData?.checkExpectedAmount
                            : 0
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label className="d-flex justify-content-between">
                        Total Check Collected :
                        <b>
                          <span className="right">{totalCheckAm}</span>
                        </b>
                      </label>
                      <div className="he_fix">
                        {checkSelectList?.map((item, i) => (
                          <div
                            className={`d-flex justify-content-between ${
                              checkSelectList?.length === i + 1 ? "" : "mb-3"
                            }`}
                            key={i}
                          >
                            <label className="personal_detail_label">
                              <input
                                type="checkbox"
                                className="login_check"
                                name="select all"
                                disabled
                                checked={item.checkStatus ? true : false}
                                // onChange={(e) =>
                                //   handleCheckAmount(e?.target?.checked, item)
                                // }
                              />
                              <span className="checkmark"></span>
                              {item?.checkNumber}
                            </label>
                            {item?.amount}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Check Over/Short</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" Checks Over/Short"
                        disabled={true}
                        value={overShortCheck}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Revenue Over/Short</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Revenue"
                        disabled
                        value={
                          parseFloat(overShortCash) + parseFloat(overShortCheck)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Start Time</label>
                      <input
                        type="datetime-local"
                        className="form-control"
                        placeholder="Date"
                        disabled={true}
                        value={
                          cashCheckData?.startTime
                            ? cashCheckData?.startTime
                            : ""
                        }
                      />
                    </div>
                  </div>
                  {cashCheckData?.isMealServiceEnded ? (
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>End Time</label>
                        <input
                          type="datetime-local"
                          className="form-control"
                          placeholder="Date"
                          name="meeting-time"
                          disabled={true}
                          value={
                            cashCheckData?.endTime ? cashCheckData?.endTime : ""
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label>Total Hours </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Total Hours"
                        {...register("totalHours", {
                          required: "This field is required",
                          max: {
                            value: 24,
                            message: "you can enter max 24",
                          },
                          pattern: {
                            // value:
                            //   /^([01]\d|2[0-3]):([0-5]\d|5[0-9]):([0-5]\d)$/,
                            value: /^\d+(\.\d{1,2})?$/,
                            message: "Enter valid total hour",
                          },
                          onChange: (e) =>
                            setProductivity(
                              e.target.value !== "" || e.target.value !== "0"
                                ? mathRound(
                                    cashCheckData?.mealServed /
                                      parseFloat(e.target.value)
                                  )
                                : 0
                            ),
                        })}
                      />
                      {errors.totalHours && (
                        <span role="alert" className="error_text">
                          {errors.totalHours.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Productivity</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Productivity"
                        disabled={true}
                        value={productivity ? productivity : 0}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-12 text-end mt-3">
                    {/* <Button type="submit" className="theme_btn me-2">
                      Approve
                    </Button> */}
                    {cashCheckData?.isMealServiceEnded === false ? (
                      <Button
                        onClick={handleSubmit(
                          async (data) => await onSubmit(data, true)
                        )}
                        className="theme_btn me-2"
                      >
                        End Meal Session
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      onClick={handleSubmit(
                        async (data) => await onSubmit(data, false)
                      )}
                      type="submit"
                      className="theme_btn me-2"
                    >
                      Submit
                    </Button>
                    <Button className="cancel_btn" onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EditCashierSessionReport;
