import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import paymentBanner from "../../../../Assets/SvgImage/payment-banner.jpg";
import "./index.scss";
import { BsFillPatchCheckFill } from "react-icons/bs";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
function PaymentSuccess() {
  return (
    <Suspense fallback={renderLoader()}>
      <section className="payment_success">
        <img src={paymentBanner} className="img-fluid" alt="" />
        <div className="main_wrapper">
          <div className="payment_inner">
            <div className="row">
              <div className="col-md-12">
                <div className="success_message_box">
                  <BsFillPatchCheckFill className="icon" />
                  <div>
                    <h1 className="m-0">Payment Successful</h1>
                    <p>We will share payment receipt on registered email.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row paymentAndUserInfo mt-4">
              <div className="col-md-6">
                <ul>
                  <li>
                    Payment Reference Id : <span>POS1234567</span>
                  </li>
                  <li>
                    Paid Amount : <span>$ 1250.00</span>
                  </li>
                  <li>
                    Payment Date : <span>17 Aug 2023</span>
                  </li>
                  {/* <li>Payment Status : <span>$ 1250.00</span></li> */}
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>
                    Name : <span>Yash Patel</span>
                  </li>
                  <li>
                    Email : <span>yash023@gmail.com</span>
                  </li>
                  <li>
                    Phone : <span>1234567890</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default PaymentSuccess;
