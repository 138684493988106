import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress, Box, Modal } from "@mui/material";
// import ReactDatePicker from "react-datepicker";
import Axios from "../../../../../../Utils/AxiosConfig";
import uploadimg from "../../../../../../Assets/SvgImage/file-upload.svg";
import Select from "react-select";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useBarcode } from "next-barcode";
import CropModal from "../../../../../../Components/Reusable/CropModel";
import Webcam from "react-webcam";
import { GetCallApi } from "../../../../../../Action/Action";
// const WebcamComponent = () => <Webcam />;
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user",
};
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
function CreateStudentTab(props) {
  // const [startDate, setStartDate] = useState(new Date());
  // const [joinDate, setJoinDate] = useState(new Date());
  // const [schoollist, setschoollist] = useState([]);
  const [parentList, setParentList] = useState([]);
  const [parentsGuidId, setParentsGuidId] = useState([]);
  // const [allergenList, setAllergenList] = useState([]);
  // const [allergenSelect, setAllergenSelect] = useState([]);
  const [schoolDistrictList, setschoolDistrictList] = useState([]);
  const [allerGroupList, setAllerGroupList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [stateList, setStateList] = useState();
  const [schoolList, setSchoolList] = useState();
  const [studId, setStudId] = useState();
  const [derror, setDerror] = useState();

  const { id } = useParams();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  let myRef = React.useRef(null);

  // useEffect(() => {
  //   if (myRef.current) {
  //     myRef.current.inputRef.style.minWidth = "140px";
  //     myRef.current.inputRef.placeholder = "Select Parent";
  //   }
  //   // eslint-disable-next-line
  // }, [myRef]);
  const handleIOCMulti = (e) => {
    setParentsGuidId(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  // const handleAllergenMulti = (e) => {
  //   setAllergenSelect(Array.isArray(e) ? e.map((x) => x.value) : []);
  // };
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    GetSchoolDistrictData();
    GetStudentPrimaryData();
    getStateList();
    // GetAllergenData();
    setStudId(generateId(15));
    GetSingleAllergenList();
    // eslint-disable-next-line
  }, []);

  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setStateList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getSchoolList = (id) => {
    if (id) {
      Axios.get(
        `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        { headers }
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              setSchoolList(res.data.payload);
            }
          }
        })
        .catch((e) => console.log(e));
    }
  };

  const GetSingleAllergenList = async () => {
    let apiUrl = {
      url: `/api/Common/GetAllergenGroupList`,
      headers: headers,
    };

    const respo = await GetCallApi(apiUrl);
    if (respo?.status === 200) {
      if (respo?.data?.success) {
        let dlList = respo?.data?.payload?.map((x) => {
          return { ...x };
        });
        setAllerGroupList(dlList);
      }
    }
  };

  useEffect(() => {
    if (parentList) {
      if (schoolDistrictList) {
        if (stateList) {
          if (id) {
            EditStudentTab(id);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [parentList, schoolDistrictList, stateList, id]);

  useEffect(() => {
    if (schoolList?.length > 0) {
      if (id) {
        EditStudentTab(id, true);
      }
    }
    // eslint-disable-next-line
  }, [schoolList, id]);

  const GetStudentPrimaryData = () => {
    Axios.get(`/api/Parents/GetAllParentsData?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          let prList = res.data.payload?.map((sd) => {
            return {
              // ...sd,
              value: sd?.parentsGuidId,
              label: sd?.firstName + " " + sd?.lastName,
            };
          });
          setParentList(prList);
        } else {
          setParentList([]);
        }
      })
      .catch((e) => console.log(e));
  };

  const GetSchoolDistrictData = () => {
    Axios.get(
      `/api/SchoolDistrict/GetAllSchoolDistrictList?userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          setschoolDistrictList(res.data.payload);
        } else {
          setschoolDistrictList([]);
        }
      })
      .catch((e) => console.log(e));
  };

  // const GetAllergenData = () => {
  //   Axios.get(
  //     `/api/Student/GetAllStudentAllergenList?userId=${LogDetail?.userId}`,
  //     { headers }
  //   )
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         let allist = res.data.payload?.map((x) => {
  //           return {
  //             value: x?.allergenId,
  //             label: x?.allergenName + " - " + x?.allergenGroup,
  //           };
  //         });
  //         setAllergenList(allist);
  //       } else {
  //         setAllergenList([]);
  //       }
  //     })
  //     .catch((e) => console.log(e));
  // };

  const [picture, setPicture] = useState("");
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const pictureSrc = webcamRef.current.getScreenshot();
    setPicture(pictureSrc);
  });

  const [openTake, setOpenTake] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const { inputRef } = useBarcode({
    value: studId,
    options: {
      background: "#ffffff",
    },
  });

  const handleOpenTake = () => setOpenTake(true);
  const handleCloseTake = () => {
    setOpenTake(false);
    setValue("ProductImageUrl1", "");
    setValue("logoUpload1", "");
    setPicture("");
  };

  const handleProfileAdd = () => {
    let addpc = dataURLtoFile(picture, "profilePicture.jpeg");
    setValue("ProductImageUrl1", addpc);
    setValue("logoUpload1", "profilePicture.jpeg");
    setOpenTake(false);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function convertDate(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const onSubmit = (data) => {
    let is_success = true;
    if (parentsGuidId?.length === 0) {
      is_success = false;
      setDerror({ err: "Select minimum one parent" });
    }
    if (is_success) {
      setDerror({ err: "" });
      var s = new XMLSerializer().serializeToString(inputRef?.current);
      // Remove any characters outside the Latin1 range
      var decoded = unescape(encodeURIComponent(s));
      // Now we can use btoa to convert the svg to base64
      var base64 = btoa(decoded);
      var imgSource = `data:image/svg+xml;base64,${base64}`;

      //set url value to a element's href attribute.

      let brImgs = dataURLtoFile(imgSource, "barcode.svg");
      let sendData = {
        ...data,
        createdBy: LogDetail?.userId,
        parentGuidId: parentsGuidId,
        Barcode: studId,
        AllergenList: null,
        // birthDate: startDate ? convertDate(startDate) : "",
        // joiningDate: joinDate ? convertDate(joinDate) : "",
        BarcodeImage: brImgs,
      };
      setOpenCircle(true);
      Axios.post("/api/Student/CreateStudent", sendData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      })
        .then((res) => {
          if (res?.status === 200) {
            if (res.data.success) {
              if (data?.ProductImageUrl1) {
                let sendDatap = {
                  profilePhoto: data?.ProductImageUrl1,
                };
                Axios.post(
                  `/api/Student/AddStudentProfilePhotoById?studentGuidId=${res.data.payload?.studentGuidId}`,
                  sendDatap,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "multipart/form-data",
                      Accept: "*/*",
                    },
                  }
                );
              }
              if (data?.ProductImageUrl2) {
                let sendDataC = {
                  coverPhoto: data?.ProductImageUrl2,
                };
                Axios.post(
                  `/api/Student/AddStudentCoverPhotoById?studentGuidId=${res.data.payload?.studentGuidId}`,
                  sendDataC,
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "multipart/form-data",
                      Accept: "*/*",
                    },
                  }
                );
              }
              setOpenCircle(false);
              reset();
              navigate(-1);
            } else {
              setOpenCircle(false);
            }
          }
        })
        .catch((e) => {
          setOpenCircle(false);
          console.log(e);
        });
    }
  };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("ProductImageUrl1", url);
        setValue("logoUpload1", url.name);
        setCroppedImageUrl(url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );

  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  const [src1, setSrc1] = useState(null);
  const [filename1, setFileName1] = useState(null);
  const [croppedImageUrl1, setCroppedImageUrl1] = useState(null);
  const [openCropModal1, setCropModal1] = useState(false);
  const setCroppedImageUrlCallback1 = useCallback(
    (url) => {
      if (url) {
        setValue("ProductImageUrl2", url);
        setValue("coverUpload1", url.name);
        setCroppedImageUrl1(url);
        setSrc1(null);
      } else {
        setSrc1(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl1]
  );

  const handleFile1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName1(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc1(reader.result);
          setCropModal1(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  const EditStudentTab = (id, site) => {
    setOpenCircle(true);
    Axios.get(
      `/api/Student/GetStudentDataByID?studentGuidId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            let editstudentData = res.data.payload;
            if (site) {
              setValue("schoolId", editstudentData?.schoolId);
            } else {
              // setValue("id", editstudentData?.studentGuidId);
              setValue("studentGuidId", editstudentData?.studentGuidId);
              // getAlSe(editstudentData?.studentGuidId);
              setValue("studentId", editstudentData?.studentId);
              setValue("logoUpload1", editstudentData?.profilePhoto);
              setValue("coverUpload1", editstudentData?.coverPhoto);
              if (editstudentData?.barcode) {
                setStudId(editstudentData?.barcode);
              }
              setValue("schoolDistrictId", editstudentData?.schoolDistrictId);
              getSchoolList(editstudentData?.schoolDistrictId);
              setValue("studentNumber", editstudentData?.studentNumber);
              setValue("firstName", editstudentData?.firstName);
              setValue("lastName", editstudentData?.lastName);
              setValue("gradeId", editstudentData?.gradeId);
              setValue("paymentStatus", editstudentData?.paymentStatus);
              setValue("homeroom", editstudentData?.homeroom);
              setValue("colorOfSkin", editstudentData?.colorOfSkin);
              setValue("foster", editstudentData?.foster);
              setValue("IsDc", editstudentData?.isDC);
              // let d = editstudentData?.birthDate
              //   ? editstudentData?.birthDate
              //   : "";
              // let jd = editstudentData?.joiningDate
              //   ? editstudentData?.joiningDate
              //   : "";
              setValue('birthDate', convertDate(editstudentData?.birthDate))
              // if (d) {
              // setStartDate(new Date(d));
              // }
              // if (jd) {
              //   setJoinDate(new Date(jd));
              // }
              setValue('joiningDate', convertDate(editstudentData?.joiningDate))
              setValue("phone", editstudentData?.phone);
              setValue("email", editstudentData?.email);
              setValue("password", editstudentData?.password);
              setValue("address", editstudentData?.address);
              setValue("city", editstudentData?.city);
              setValue("state", editstudentData?.state);
              setValue("country", editstudentData?.country);
              setValue("zipCode", editstudentData?.zipCode);
              setValue("gender", editstudentData?.gender);
              setValue("StudentBalance", editstudentData?.studentBalance);
              setValue("AllergenGroupId", editstudentData?.allergenGroupId);
              // setValue("parentGuidId", editstudentData?.parentGuidId);
              if (editstudentData?.parentsStudentList?.length > 0) {
                setParentsGuidId(
                  editstudentData?.parentsStudentList?.map(
                    (x) => x.parentsGuidId
                  )
                );
              }
            }
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  // const getAlSe = (sId) => {
  //   Axios.get(
  //     `/api/Student/GetStudentAllergenByID?studentGuidId=${sId}&userId=${LogDetail?.userId}`,
  //     { headers }
  //   )
  //     .then((res) => {
  //       if (res?.status === 200) {
  //         if (res.data.success) {
  //           setAllergenSelect(res.data.payload?.map((x) => x.allergenId));
  //         } else {
  //           setAllergenSelect([]);
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  function generateId(len) {
    var arr = new Uint8Array((len || 40) / 2);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join("");
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0");
  }
  // console.log(inputRef.current);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <div className="card">
          <div className="card-body">
            <form action="" onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter First Name"
                      {...register("firstName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.firstName && (
                      <span role="alert" className="error_text">
                        {errors.firstName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Last Name"
                      {...register("lastName", {
                        required: "This field is required",
                      })}
                    />
                    {errors.lastName && (
                      <span role="alert" className="error_text">
                        {errors.lastName.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Date of Birth <span className="red-color">*</span>
                    </label>
                    {/* <ReactDatePicker
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    /> */}
                    <input
                      type="date"
                      className="form-control"
                      {...register("birthDate", {
                        required: "This field is required",
                      })}
                    />
                    {errors.birthDate && (
                      <span role="alert" className="error_text">
                        {errors.birthDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Joining Date <span className="red-color">*</span>
                    </label>
                    {/* <ReactDatePicker
                      showIcon
                      selected={joinDate}
                      onChange={(date) => setJoinDate(date)}
                    /> */}
                    <input
                      type="date"
                      className="form-control"
                      {...register("joiningDate", {
                        required: "This field is required",
                      })}
                    />
                    {errors.joiningDate && (
                      <span role="alert" className="error_text">
                        {errors.joiningDate.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Grade<span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Grade"
                      {...register("gradeId", {
                        required: "This field is required",
                      })}
                    />
                    {errors.gradeId && (
                      <span role="alert" className="error_text">
                        {errors.gradeId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      School District <span className="red-color">*</span>
                    </label>
                    <select
                      name=""
                      className="form-select"
                      {...register("schoolDistrictId", {
                        required: "This field is required",
                        onChange: (e) => getSchoolList(e.target.value),
                      })}
                    >
                      <option value="">Select School District</option>
                      {schoolDistrictList?.map((item, i) => (
                        <option value={item.schoolDistrictId} key={i}>
                          {item.districtName}
                        </option>
                      ))}
                    </select>
                    {errors.schoolDistrictId && (
                      <span role="alert" className="error_text">
                        {errors.schoolDistrictId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      School <span className="red-color">*</span>
                    </label>
                    <select
                      name=""
                      className="form-select"
                      {...register("schoolId", {
                        required: "This field is required",
                      })}
                    >
                      <option value="">Select School </option>
                      {schoolList &&
                        schoolList?.map((item, i) => (
                          <option value={item.schoolId} key={i}>
                            {item.schoolName}
                          </option>
                        ))}
                    </select>
                    {errors.schoolId && (
                      <span role="alert" className="error_text">
                        {errors.schoolId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Primary house hold <span className="red-color">*</span>
                    </label>
                    <Select
                      ref={myRef}
                      isMulti
                      name="type"
                      options={parentList}
                      // closeMenuOnSelect={false}
                      // hideSelectedOptions={false}
                      className="form-neselect"
                      classNamePrefix="select"
                      placeholder="Select Parent"
                      value={parentList?.filter((obj) =>
                        parentsGuidId?.includes(obj.value)
                      )}
                      onChange={handleIOCMulti}
                      style={{ width: "100%" }}
                    />
                    {derror?.err && (
                      <span role="alert" className="d-block error_text">
                        {derror.err}
                      </span>
                    )}
                    {/* <select
                      name=""
                      className="form-select"
                      {...register("parentsGuidId", {
                        required: true,
                      })}
                    >
                      <option value="">Select primary house hold</option>
                      {primarylist?.map((item, i) => (
                        <option value={item.parentsGuidId} key={i}>
                          {item.firstName + " " + item.lastName}
                        </option>
                      ))}
                    </select> */}
                    {/* {errors.parentsGuidId && (
                      <span role="alert" className="error_text">
                        {errors.parentsGuidId.message}
                      </span>
                    )} */}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Account Balance<span className="red-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account Balance"
                      {...register("StudentBalance", {
                        required: "This field is required",
                        pattern: {
                          value: /^\d+(\.\d{1,2})?$/,
                          message:
                            "Enter valid balance you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                        },
                      })}
                    />
                    {errors.StudentBalance && (
                      <span role="alert" className="error_text">
                        {errors.StudentBalance.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Allergen 's
                    </label>
                    <Select
                      isMulti
                      name="type"
                      options={allergenList}
                      className="form-neselect"
                      classNamePrefix="select"
                      placeholder="Select Allergen"
                      value={allergenList?.filter((obj) =>
                        allergenSelect?.includes(obj.value)
                      )}
                      onChange={handleAllergenMulti}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Allergen Group</label>
                    <select
                      name=""
                      className="form-select"
                      {...register("AllergenGroupId", {
                        // required: "This field is required",
                      })}
                    >
                      <option value="">Select Allergen Group</option>
                      {allerGroupList &&
                        allerGroupList?.map((x, ind) => {
                          return (
                            <option value={x?.allergenGroupId} key={ind}>
                              {x?.allergenGroupName}
                            </option>
                          );
                        })}
                    </select>
                    {errors.AllergenGroupId && (
                      <span role="alert" className="error_text">
                        {errors.AllergenGroupId.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label">Upload Profile</label>
                    <div className="upload_img">
                      <input
                        type="text"
                        className="form-control upload_box"
                        placeholder="No file Chosen"
                        readOnly
                        {...register("logoUpload1", {})}
                      />
                      <input
                        type="file"
                        id="school_img_upload_1"
                        accept="image/*"
                        className="form-control"
                        style={{ display: "none" }}
                        onChange={handleFile}
                      />
                      <label
                        htmlFor="school_img_upload_1"
                        className="upload_img_label mb-0 "
                      >
                        <img src={uploadimg} className="img-fluid" alt="" />
                      </label>
                    </div>

                    {errors?.logoUpload && (
                      <span role="alert" className="error_text">
                        {errors.logoUpload.message}
                      </span>
                    )}
                    {src && (
                      <CropModal
                        src={src}
                        filename={filename}
                        setCroppedImageUrl={setCroppedImageUrlCallback}
                        openCropModal={openCropModal}
                        setCropModal={setCropModal}
                      />
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-3">
                  <div className="form-group ">
                    <label className="form-label">Take Profile Picture</label>
                    <div className="upload_img">
                      <Button className="theme_btn" onClick={handleOpenTake}>
                        Take
                      </Button>
                    </div>
                  </div>
                </div>{" "}
                <div className="col-md-6">
                  <div className="form-group ">
                    <label className="form-label">Upload Cover Image</label>
                    <div className="upload_img">
                      <input
                        type="text"
                        className="form-control upload_box"
                        placeholder="No file Chosen"
                        readOnly
                        {...register("coverUpload1", {})}
                      />
                      <input
                        type="file"
                        id="school_img_upload_2"
                        accept="image/*"
                        className="form-control"
                        style={{ display: "none" }}
                        onChange={handleFile1}
                      />
                      <label
                        htmlFor="school_img_upload_2"
                        className="upload_img_label mb-0 "
                      >
                        <img src={uploadimg} className="img-fluid" alt="" />
                      </label>
                    </div>
                    {errors?.logoUpload && (
                      <span role="alert" className="error_text">
                        {errors.logoUpload.message}
                      </span>
                    )}
                    {src1 && (
                      <CropModal
                        src={src1}
                        filename={filename1}
                        setCroppedImageUrl={setCroppedImageUrlCallback1}
                        openCropModal={openCropModal1}
                        setCropModal={setCropModal1}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Email
                      {/* <span className="red-color">*</span>{" "} */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Email "
                      {...register("email", {
                        // required: "This field is required",
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                      })}
                    />
                    {errors.email && (
                      <span role="alert" className="error_text">
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Password <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter password"
                      {...register("password", {
                        required: "This field is required",
                        pattern: {
                          message: "Enter password",
                        },
                      })}
                    />
                    {errors.password && (
                      <span role="alert" className="error_text">
                        {errors.password.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Phone Number <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone Number"
                      {...register("phone", {
                        required: "This field is required",
                        pattern: {
                          value:
                            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                          message: "Enter valid phone number",
                        },
                      })}
                    />
                    {errors.phone && (
                      <span role="alert" className="error_text">
                        {errors.phone.message}
                      </span>
                    )}
                  </div>
                </div>{" "}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Student Id <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Student Id"
                      {...register("studentNumber", {
                        required: "This field is required",
                      })}
                    />
                    {errors.studentNumber && (
                      <span role="alert" className="error_text">
                        {errors.studentNumber.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Student Id <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Student Number"
                      {...register("studentNumber", {
                        required: "This field is required",
                      })}
                    />
                    {errors.studentNumber && (
                      <span role="alert" className="error_text">
                        {errors.studentNumber.message}
                      </span>
                    )}
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter address"
                      {...register("address", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.address && (
                      <span role="alert" className="error_text">
                        {errors.address.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Payment Status
                      {/* <span className="red-color">*</span> */}
                    </label>
                    <select
                      name=""
                      className="form-select"
                      {...register("paymentStatus", {
                        // required: "This field is required",
                      })}
                    >
                      <option value="">Select Payment Status</option>
                      <option value="F">FREE</option>
                      <option value="P">PAID</option>
                      <option value="R">REDUCED</option>
                      <option value="FOSTER CHILD">FOSTER CHILD</option>
                      <option value="SNAP">SNAP</option>
                      <option value="TANF">TANF</option>
                    </select>
                    {errors.paymentStatus && (
                      <span role="alert" className="error_text">
                        {errors.paymentStatus.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Is Dc
                      {/* <span className="red-color">*</span> */}
                    </label>
                    <select
                      name=""
                      className="form-select"
                      {...register("IsDc", {
                        // required: "This field is required",
                      })}
                    >
                      <option value="">Select Is Dc</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                    {errors.IsDc && (
                      <span role="alert" className="error_text">
                        {errors.IsDc.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {" "}
                      State
                      {/* <span className="red-color">*</span> */}
                    </label>
                    <select
                      name=""
                      className="form-select"
                      {...register("state", {
                        // required: "This field is required",
                        // valueAsNumber: true,
                      })}
                    >
                      <option value="">Select State</option>
                      {stateList &&
                        stateList?.map((data, i) => (
                          <option key={i} value={data?.stateId}>
                            {data?.stateName}
                          </option>
                        ))}
                    </select>
                    {errors.state && (
                      <span role="alert" className="error_text">
                        {errors.state.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Country
                      {/* <span className="red-color">*</span> */}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter country"
                      {...register("country", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.country && (
                      <span role="alert" className="error_text">
                        {errors.country.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter city"
                      {...register("city", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.city && (
                      <span role="alert" className="error_text">
                        {errors.city.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter zipCode"
                      {...register("zipCode", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.zipCode && (
                      <span role="alert" className="error_text">
                        {errors.zipCode.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Homeroom</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Homeroom"
                      {...register("homeroom", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.homeroom && (
                      <span role="alert" className="error_text">
                        {errors.homeroom.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Ethnicity</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Ethnicity"
                      {...register("ethnicity", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.ethnicity && (
                      <span role="alert" className="error_text">
                        {errors.ethnicity.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Foster
                      {/* <span className="red-color">*</span> */}
                    </label>
                    <select
                      name=""
                      className="form-select"
                      {...register("foster", {
                        // required: "This field is required",
                      })}
                    >
                      <option value="">Select Foster</option>
                      <option value="true">True</option>
                      <option value="false">False</option>
                    </select>
                    {errors.foster && (
                      <span role="alert" className="error_text">
                        {errors.foster.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>Foster</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Color Of Skin"
                      {...register("foster", {
                        // required: "This field is required",
                      })}
                    />
                    {errors.foster && (
                      <span role="alert" className="error_text">
                        {errors.foster.message}
                      </span>
                    )}
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Gender</label>
                    <div className="radio_button">
                      <input
                        type="radio"
                        id="fml"
                        {...register("gender", {
                          required: "This field is required",
                        })}
                        value="Female"
                        defaultChecked
                      />{" "}
                      <label htmlFor="fml" className="me-3">
                        Female
                      </label>
                      <input
                        type="radio"
                        id="ml"
                        // className="form-control"
                        {...register("gender", {
                          required: "This field is required",
                        })}
                        value="Male"
                      />
                      <label htmlFor="ml">Male</label>
                    </div>
                    {errors.gender && (
                      <span role="alert" className="error_text">
                        {errors.gender.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Barcode</label>
                    <div>
                      <svg ref={inputRef} />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-end">
                  <Button type="submit" className="theme_btn me-2">
                    Submit
                  </Button>
                  <Button className="cancel_btn" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Suspense>
      {openTake && (
        <>
          <Modal
            open={openTake}
            onClose={handleCloseTake}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <div className="popup_delete_detail_box">
                <h6>Capture Profile Picture</h6>
                <div className="popup_delete_detail">
                  <div className="mb-3">
                    {picture === "" ? (
                      <Webcam
                        audio={false}
                        height={400}
                        ref={webcamRef}
                        width={400}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    ) : (
                      <img src={picture} alt="" />
                    )}
                  </div>

                  {derror?.err && (
                    <span role="alert" className="d-block error_text">
                      {derror.err}
                    </span>
                  )}
                  {picture !== "" ? (
                    <>
                      {picture === null ? (
                        ""
                      ) : (
                        <>
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setPicture("");
                            }}
                            className="cancel_btn"
                          >
                            Retake
                          </Button>
                          <Button
                            type="button"
                            className="theme_btn ms-2"
                            onClick={handleProfileAdd}
                          >
                            Add
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        capture();
                      }}
                      className="theme_btn"
                    >
                      Capture
                    </Button>
                  )}

                  <Button
                    type="button"
                    className="cancel_btn ms-2"
                    onClick={handleCloseTake}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default CreateStudentTab;
