import React, { Suspense, lazy, useEffect, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import "./index.scss";
import Select from "react-select";
import { AcademicYearListApi } from "../../../../../Action/AcademicYear";

const ListTable = lazy(() =>
    import("../../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);
const productHead = [
    { id: "studentName", label: "Student Name" },
    { id: "email", label: "Email" },
    { id: "schoolName", label: "School" },
    { id: "gradeId", label: "Grade Id" },
    { id: "totalPoints", label: "Total Points" },
    { id: "createdOn", label: "createdOn" },
];
const anotherHead = [
    { id: "mealService", label: "Meal Service" },
    { id: "points", label: "Points" },
    { id: "transactionType", label: "Transaction Type" },
    { id: "transactionDate", label: "Transaction Date" },
]

function MealPointsHistoryReport() {
    const [distList, setDistList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [mealList, setMealList] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [openCircle, setOpenCircle] = useState(false);
    const [reportDetails, setReportDetails] = useState();
    const [schoolSelect, setSchoolSelect] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [derror, setDerror] = useState();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getDistrictList();
        getAllStudentList();
        getAcademicYear();
        // eslint-disable-next-line
    }, []);

    const LogDetail = useSelector((state) => state.login.LoginDetails);
    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    const getAcademicYear = async () => {
        let resp = await AcademicYearListApi();
        if (resp?.length > 0) {
            setSchoolYearList(resp);
        }
    }

    const getDistrictList = async () => {
        let seData = {
            url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
            headers: headers,
        };
        let res = await GetCallApi(seData);

        if (res?.status === 200) {
            if (res.data.success) {
                const dlist = res.data.payload?.map((sd) => {
                    return {
                        value: sd?.schoolDistrictId,
                        label: sd?.districtName,
                    };
                });
                setDistList(dlist?.length > 0 ? dlist : []);
            }
        }
    };

    const getSchoolList = async (id) => {
        setSchoolList([]);
        setSchoolSelect([]);
        if (id) {
            let seData = {
                url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
                headers: headers,
            };
            let res = await GetCallApi(seData);

            if (res?.status === 200) {
                if (res.data.success) {
                    const dlist = res.data.payload?.map((sd) => {
                        return {
                            value: sd?.schoolId,
                            label: sd?.schoolName,
                        };
                    });
                    let uslist = [{ value: "all", label: "Select All" }, ...dlist];

                    setSchoolList(uslist?.length > 1 ? uslist : []);
                } else {
                    setSchoolList([]);
                }
            }
        } else {
            setSchoolList([]);
        }
    };

    // function convertDate(str) {
    //     var date = new Date(str?.replace(/-/g, "/")),
    //         mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //         day = ("0" + date.getDate()).slice(-2);
    //     return [mnth, day, date.getFullYear()].join("/");
    // }

    const getAllStudentList = async () => {
        let stdUrl = {
            url: `/api/Student/GetAllStudentData?userId=${LogDetail?.userId}`
        }
        const resp = await GetCallApi(stdUrl);
        if (resp?.status === 200) {
            if (resp?.data?.success) {
                const stdData = resp?.data?.payload?.map((x) => {
                    return {
                        value: x?.studentGuidId,
                        label: x?.firstName + " " + x?.lastName
                    }
                })
                setStudentList(stdData ?? []);
            }
        }
    }

    const onSubmit = async (data) => {
        setMealList([]);
        let is_success = true;
        if (schoolSelect?.length === 0) {
            is_success = false;
            setDerror({ err: "Select minimum one school" });
        }
        if (is_success) {
            setDerror({ err: "" });
            setOpenCircle(true);
            let Usdata = {
                ...data,
                studentGuidId: data?.studentGuidId ? data?.studentGuidId : null,
                // startDate: data?.startDate !== "Invalid Date" ? data?.startDate : null,
                // endDate: data?.endDate !== "Invalid Date" ? data?.endDate : null,
                lstSchoolId: schoolSelect?.map((x) => x.value),
            };
            let seData = {
                url: "/api/Report/GetMealHistoryReport",
                body: Usdata,
                headers: headers,
            };
            let distName = distList?.filter(
                (x) => x.value === Usdata?.schoolDistrictId
            )[0]?.label;
            let schName =
                schoolSelect?.length === schoolList?.length - 1
                    ? "All"
                    : schoolSelect?.map((x) => x.label).toString();

            // let sdate = data?.startDate?.toString() !== "Invalid Date" ? data?.startDate?.toISOString() : null;
            // let edate = data?.endDate?.toString() !== "Invalid Date" ? data?.endDate?.toISOString() : null;
            let reData = {
                // startDate: sdate ? convertDate(sdate?.split("T")[0]) : null,
                // endDate: edate ? convertDate(edate?.split("T")[0]) : null,
                distName: distName,
                schName: schName,
            };
            setReportDetails(reData);
            let res = await PostCallApi(seData);
            setOpenCircle(false);

            if (res?.status === 200) {
                if (res.data.success) {
                    let orData = res.data.payload?.map((x, ind) => {
                        return {
                            ...x,
                            id: ind,
                            createdOn: x?.createdOn
                                ? new Date(x?.createdOn)?.toLocaleDateString()
                                : "",
                            subTableList:
                                x?.studentMalPointTrana?.length > 0
                                    ? x?.studentMalPointTrana?.map((c, index) => {
                                        return {
                                            ...c,
                                            id: index,
                                            transactionDate: c?.transactionDate ? new Date(c?.transactionDate).toLocaleDateString("en-US", {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                            })
                                                : ""
                                        };
                                    })
                                    : "",
                        };
                    });
                    setMealList(orData);
                } else {
                    setMealList([]);
                }
            }
        }
    };

    const handleIOCMulti = (e) => {
        setSchoolSelect(e);
    };

    return (
        <>
            <Suspense fallback={renderLoader()}>
                <section className="meal_history_page">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="all_title mb-4"> Meal Points History Report</h4>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    District <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("schoolDistrictId", {
                                                        required: "This field is required",
                                                        onChange: (e) => getSchoolList(e.target.value),
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {distList &&
                                                        distList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.schoolDistrictId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.schoolDistrictId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    School <span className="red-color">*</span>
                                                </label>
                                                <Select
                                                    isMulti
                                                    name="type"
                                                    options={schoolList}
                                                    // closeMenuOnSelect={false}
                                                    // hideSelectedOptions={false}
                                                    className="form-neselect"
                                                    classNamePrefix="select"
                                                    placeholder="Select School"
                                                    value={schoolSelect ? schoolSelect : null}
                                                    onChange={(selected) => {
                                                        true &&
                                                            selected.length &&
                                                            selected.find((option) => option.value === "all")
                                                            ? handleIOCMulti(schoolList.slice(1))
                                                            : !true
                                                                ? handleIOCMulti(
                                                                    (selected && selected.value) || null
                                                                )
                                                                : handleIOCMulti(selected);
                                                    }}
                                                    style={{ width: "100%" }}
                                                />
                                                {derror?.err && (
                                                    <span role="alert" className="d-block error_text">
                                                        {derror.err}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Student
                                                    {/* <span className="red-color">*</span> */}
                                                </label>

                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("studentGuidId", {
                                                        // required: "This field is required",
                                                    })}>
                                                    <option value="">Select Student</option>
                                                    {studentList && studentList?.map((x, ind) => (
                                                        <option value={x?.value} key={ind}>{x?.label}</option>
                                                    ))}
                                                </select>
                                                {errors.studentGuidId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.studentGuidId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    Start Date
                                                    {/* <span className="red-color">*</span> }
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    {...register("startDate", {
                                                        // required: "This field is required",
                                                        valueAsDate: true,
                                                    })}
                                                />
                                                {errors.startDate && (
                                                    <span role="alert" className="error_text">
                                                        {errors.startDate.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="form-group">
                                                <label>
                                                    End Date
                                                    {/* <span className="red-color">*</span> }
                                                </label>

                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    {...register("endDate", {
                                                        // required: "This field is required",
                                                        valueAsDate: true,
                                                        validate: (val) => {
                                                            if (watch("startDate") > val) {
                                                                return "Your start date can not greater than from end date";
                                                            }
                                                        },
                                                    })}
                                                />
                                                {errors.endDate && (
                                                    <span role="alert" className="error_text">
                                                        {errors.endDate.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div> */}
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label> School Year </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register("academicYearId",{
                                                        valueAsNumber : true
                                                    })}
                                                >
                                                    <option value="0">Select School Year</option>
                                                    {schoolYearList &&
                                                        schoolYearList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors.academicYearId && (
                                                    <span role="alert" className="error_text">
                                                        {errors.academicYearId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className=" ms-auto mt-auto col-md-3">
                                            <div className="form-group text-end filter-img">
                                                {openCircle ? (
                                                    <Button className="theme_btn text-end">
                                                        Applying Filter{" "}
                                                        <CircularProgress
                                                            sx={{
                                                                color: "#fff",
                                                                marginLeft: "0.5rem",
                                                                height: "23px !important",
                                                                width: "23px !important",
                                                            }}
                                                        />
                                                    </Button>
                                                ) : (
                                                    <Button className="theme_btn text-end" type="submit">
                                                        <img src={filter} className="me-1" alt="" />
                                                        Apply
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <ListTable
                                    headCells={productHead}
                                    action={true}
                                    actionFirst={true}
                                    downloadFile={true}
                                    reportDetails={reportDetails}
                                    fileName="Meal Points History Report"
                                    ListData={mealList}
                                    anotherTable={true}
                                    anotherheadCells={anotherHead}
                                    plusIconCenter={true}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default MealPointsHistoryReport;
