import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function VoidReportTab(props) {
  const { tableRef, back, startDate, endDate } = props;
  // const { tableRef, back, repoData, startDate, endDate } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const reporttable = [
    {
      bill: "No",
      hour: "Item Name",
      qty: "QTY",
      amount: "Price",
      classset: "border-dash",
    },
    {
      bill: "1",
      hour: "**Kids Bolognese",
      qty: "2",
      amount: "12.00",
    },
    {
      bill: "2",
      hour: "1/2 Acocado",
      qty: "1",
      amount: "3.00",
    },
    {
      bill: "3",
      hour: "Arancini Balls ",
      qty: "1",
      amount: "13.00",
    },
    {
      bill: "4",
      hour: "Asahi Beer",
      qty: "3",
      amount: "4.00",
    },
    {
      bill: "5",
      hour: "Banana Smoothie",
      qty: "13",
      amount: "9.00",
    },
    {
      bill: "6",
      hour: "Banana Smoothie",
      qty: "4",
      amount: "9.00",
    },
    {
      bill: "7",
      hour: "Banana Smoothie",
      qty: "99",
      amount: "20.00",
    },
    {
      bill: "8",
      hour: "BBQ Brisk Burgers",
      qty: "9",
      amount: "29.00",
    },
    {
      bill: "9",
      hour: "Schnitty",
      qty: "8",
      amount: "56.00",
    },
    {
      bill: "10",
      hour: "Chicken crepes",
      qty: "10",
      amount: "56.00",
    },
    {
      bill: "11",
      hour: "Sticky Date",
      qty: "9",
      amount: "87.00",
    },
    {
      bill: "Total",
      hour: "",
      qty: "",
      amount: "$700.00",
      classset: "cash-border",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <div className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Void Report</h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">top 25 sales</h5>
        <h5 className="text-uppercase">store order</h5>
        <div className="report-table">
          {reporttable?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              {/* <div key={i} className={text?.classset === cashborder ? cashborder : borderdash}> */}
              <h6 className="title-width-bill">{text?.bill}</h6>
              <h6 className="title-width text-start">{text?.hour}</h6>
              <h6 className="title-width">{text?.qty}</h6>
              <h6 className="title-width">{text?.amount}</h6>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </div>
    </Suspense>
  );
}

export default VoidReportTab;
