import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import "./index.scss";
import { PostCallApi } from "../../../../../Action/Action";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import MultiImgPopup from "../../../../../Components/Reusable/MultiImgPopup";

const ProductTable = lazy(() => import("./ProductTable/index"));

const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);

const globalHead = [
    {
        id: "id",
        label: "#",
    },
    {
        id: "productImage",
        label: "Product Image",
    },
    {
        id: "productName",
        label: "Product Name",
    },
    {
        id: "categoryName",
        label: "Category Name",
    },
    {
        id: "subCategory1Name",
        label: "Sub Category1 Name",
    },
    {
        id: "subCategory2Name",
        label: "Sub Category2 Name",
    },
    {
        id: "glnnumber",
        label: "GLN Number",
    },
    {
        id: "ingredients",
        label: "Ingredients",
        widthFix: 600,
    },
    {
        id: "productDescription",
        label: "Product Description",
        widthFix: 600,
    },
    {
        id: "manufacturer",
        label: "Manufacturer",
    },
    {
        id: "brandName",
        label: "Brand Name",
    },
    {
        id: "vendor",
        label: "Vendor",
    },
    {
        id: "gtin",
        label: "GTIN",
    },
    {
        id: "upc",
        label: "UPC",
    },
    {
        id: "storageTypeName",
        label: "StorageType Name",
    },
];

function GlobalProductData() {
    const [prdList, setPrdList] = useState([]);
    const [prodId, setProdId] = useState([]);
    const [openCircle, setOpenCircle] = React.useState(false);
    const [checkBox, setCheckbox] = useState(false);
    const [totalRecord, setTotalRecord] = useState(0);
    const [filterData, setFilterData] = useState();

    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const token = useSelector((state) => state.login?.LoginDetails.accessToken);

    const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
    };

    useEffect(() => {
        if (prodId?.length > 0) {
            changeTbData();
        } else {
            changeTbData();
        }
        // eslint-disable-next-line
    }, [prodId]);

    useEffect(() => {
        applyFilter({
            page: 1,
            pageSize: 20
        })
        // eslint-disable-next-line
    }, [])

    const handleAddProduct = (val, eId) => {
        if (val) {
            setProdId((old) => [...old, eId]);
        } else {
            setCheckbox(false);
            setProdId((old) => old?.filter((pre) => pre !== eId));
        }
    };

    const handleCheckALL = (v, data) => {
        if (v) {
            setProdId(data?.map((d) => d?.productId));
            setCheckbox(true);
        } else {
            setProdId([]);
            setCheckbox(false);
        }
    };

    const applyFilter = async (d) => {
        setFilterData(d)
        setOpenCircle(true)
        setPrdList([]);

        let sdData = {
            pageNumber: d?.page ? d?.page : 1,
            pageSize: d?.pageSize ? d?.pageSize : 20,
            category: d?.category ? d?.category : "",
            subCategory: d?.subCategory ? d?.subCategory : "",
            manufacturer: d?.manufacturer ? d?.manufacturer : "",
            brand: d?.brand ? d?.brand : "",
            gtinNumber: d?.gtinNumber ? d?.gtinNumber : "",
        }

        let seData = {
            url: `/api/GlobalProduct/GetGlobalProductByGTINNo`,
            body: sdData,
            headers: headers,
        };

        let res = await PostCallApi(seData);
        setOpenCircle(false)
        if (res?.status === 200) {
            if (res?.data.data.success) {
                let d = res?.data?.data?.payload?.map((x, i) => {
                    let pn = sdData?.pageNumber - 1
                    return {
                        ...x,
                        id: pn * sdData.pageSize + i + 1,
                        productImage: <MultiImgPopup img={x?.image} imgAr={x?.image ? x?.image?.split(";") : ""} />,
                        Action: (
                            <label className="personal_detail_label">
                                <input
                                    type="checkbox"
                                    className="login_check"
                                    name="Remember Me"
                                    checked={false}
                                    onChange={(e) =>
                                        handleAddProduct(e?.target?.checked, x?.productId)
                                    }
                                />
                                <span className="checkmark"></span>
                            </label>
                        ),
                    };
                });
                setPrdList(d);
                setProdId([])
                setTotalRecord(res?.data?.data?.totalRecords ? res?.data?.data?.totalRecords : 0);
            }
        }
    };

    const changeTbData = () => {
        let newData = prdList?.map((pd) => {
            return {
                ...pd,
                add:
                    prodId?.filter((x) => x === pd?.productId)?.length > 0 ? true : false,
                Action: (
                    <label className="personal_detail_label">
                        <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            checked={
                                prodId?.filter((x) => x === pd?.productId)?.length > 0
                                    ? true
                                    : false
                            }
                            onChange={(e) =>
                                handleAddProduct(e?.target?.checked, pd?.productId)
                            }
                        />
                        <span className="checkmark"></span>
                    </label>
                ),
            };
        });
        setPrdList(newData);
        let ch = true;
        if (ch) {
            let as = newData?.map((x) => {
                if (!x?.add) {
                    return (ch = false);
                }
                return undefined;
            });
            if (ch && newData?.length > 0) {
                setCheckbox(true);
            } else {
                setCheckbox(false);
            }
            ch = as; //for warning remove
        }
    };

    const productApprove = async () => {
        setOpenCircle(true);

        let apiUrl = {
            url: `/api/GlobalProduct/ApproveOneWorldSyncProduct?userId=${LogDetail?.userId}`,
            body: prodId,
            headers: headers,
        };

        const resp = await PostCallApi(apiUrl);
        setOpenCircle(false);
        if (resp?.status === 200) {
            if (resp.data.success) {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: resp.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
                // setPrdList([]);
                const sdData = {
                    page: 1,
                    pageSize: 20
                }
                applyFilter(sdData)
                setProdId([]);
            } else {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: resp?.data?.message
                        ? resp?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: resp?.data?.message
                    ? resp?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };

    const clearFilter = () => {
        applyFilter()
        setProdId([])
    };

    const getApiData = (e) => {
        const sdData = {
            ...filterData,
            page: e?.page,
            pageSize: e?.no
        }
        applyFilter(sdData)
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openCircle}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="global_list">
                    <div className="main_wrapper">
                        <div className="card">
                            <div className="card-body">
                                <div className="product_box">
                                    <div className="all_title ProductTitle">
                                        Global Product Data
                                    </div>
                                </div>
                                <ProductTable
                                    headCells={globalHead}
                                    action={true}
                                    actionFirst={true}
                                    ListData={prdList}
                                    setCheckbox={setCheckbox}
                                    checkBox={checkBox}
                                    handleCheckALL={handleCheckALL}
                                    productApprove={productApprove}
                                    clearFilter={clearFilter}
                                    applyFilter={(e) => applyFilter(e)}
                                    prodId={prodId}
                                    totalRecord={totalRecord}
                                    pageApi={true}
                                    getApiData={(e) => getApiData(e)}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    );
}

export default GlobalProductData;
