import React, { useEffect } from "react";
import { Box, Button, Modal } from "@mui/material";
import "./index.scss";
import { useSelector } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import { PostCallApi } from "../../../../Action/Action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth > 768 ? 800 : "90%",

  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};
export default function CopySchedulePopup(props) {
  const { GetScheduleCall, dateSe, scheduleList } = props;
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  const [weekList, setWeekList] = React.useState([]);
  const [openCircle, setOpenCircle] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getFiveWeek();
    }
    // eslint-disable-next-line
  }, [open]);
  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const getFiveWeek = async () => {
    let Week = [];

    let se = [...Array(5).keys()]?.map(async (x, i) => {
      var date;
      if (i === 0) {
        date = new Date(dateSe?.endDate);
      } else {
        date = Week[i - 1]?.endDate1;
      }
      var nextWeekStart = date.getDate() - date.getDay() + 7;

      var nextWeekFrom1 = new Date(date.setDate(nextWeekStart));
      var nextWeekFrom = new Date(
        date.setDate(nextWeekStart)
      ).toLocaleDateString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      var nextWeekEnd = date.getDate() - date.getDay() + 6;
      var nextWeekTo1 = new Date(date.setDate(nextWeekEnd));
      var nextWeekTo = new Date(nextWeekTo1).toLocaleDateString("en-us", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
      let sv = {
        id: i + 1,
        add: false,
        startDate: nextWeekFrom,
        endDate: nextWeekTo,
        startDate1: nextWeekFrom1,
        endDate1: nextWeekTo1,
      };
      return Week.push(sv);
    });
    setWeekList(Week);
    Week = se; //for se remove warning
  };

  const AddSchduleUser = async () => {
    let is_success = true;

    if (is_success) {
      let seData = {
        url: `/api/EmployeeShedualHour/SaveCopySchedual`,
        body: {
          employeeShedualHoursId: scheduleList
            ?.filter((x) => x?.isPublished !== true)
            ?.filter((x) => x?.isCopyShift !== true)
            ?.map((x) => x?.employeeShedualHoursId),
          createdBy: LogDetail?.userId,
          date: weekList
            ?.filter((x) => x?.add === true)
            ?.map((x) => {
              return {
                startDate: convertDate(x.startDate),
                endDate: convertDate(x?.endDate),
              };
            }),
        },
        headers: headers,
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res.data.success) {
          GetScheduleCall();
          handleClose();

          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: res?.data?.message
              ? res?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };
  const handleAddWeek = (val, id) => {
    let newAr = weekList?.map((x) => {
      if (x?.id === id) {
        return { ...x, add: val };
      }
      return x;
    });
    setWeekList(newAr);
  };

  return (
    <>
      <Button className="theme_btn me-3" onClick={handleOpen}>
        Copy Schedule
      </Button>

      {open && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="toggle_delete_data"
          >
            <Box sx={style} className="delete_data_box">
              <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => 9999 }}
                open={openCircle}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div className="popup_delete_detail_box">
                <h6>Copy Schedule</h6>
                <div className="p-4">
                  <form>
                    <div className="row">
                      {weekList?.map((wk, i) => (
                        <div className="col-md-12" key={i}>
                          <div className="form-group">
                            <label className=" personal_detail_label">
                              <input
                                type="checkbox"
                                className="login_check"
                                name="Remember Me"
                                checked={wk?.add}
                                onChange={(e) =>
                                  handleAddWeek(e?.target?.checked, wk?.id)
                                }
                              />
                              <span className="checkmark"></span>
                              {wk?.startDate + " - " + wk?.endDate}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>

                    <Button
                      type="button"
                      onClick={AddSchduleUser}
                      className="theme_btn delete"
                    >
                      Submit
                    </Button>
                    <Button
                      type="button"
                      className="cancel_btn ms-3"
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </form>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
