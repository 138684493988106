import * as React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';
import './index.scss';

export default function TablePaginationDemo(props) {
    const { pageNum, allPage, setPageNum } = props;

    const handleChangePage = (newPage) => {
        setPageNum(newPage);
    };

    return (
        <Box className="custom_page_btn">
            <Typography className='m-2'>
                Page {pageNum} of {allPage}
            </Typography>
            <IconButton
                onClick={() => handleChangePage(pageNum - 1)}
                disabled={pageNum <= 1}
                className={`iconButton left ${pageNum <= 1 ? 'disabled' : ''} me-2`}
            >
                <VscChevronLeft className="icon" />
            </IconButton>
            <IconButton
                onClick={() => handleChangePage(pageNum + 1)}
                disabled={pageNum >= allPage}
                className={`iconButton right ${pageNum >= allPage ? 'disabled' : ''}`}
            >
                <VscChevronRight className="icon" />
            </IconButton>
        </Box>
    );
}
