import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function SalesmixReportTab(props) {
  const { tableRef, startDate, endDate, back } = props;
  // const { tableRef, repoData, startDate, endDate, back } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const reporttable = [
    {
      Item: "Item",
      QTY: "QTY",
      Amount: "Amount",
      Amt: "Amt%",
      classset: "cash-border",
    },
    {
      Item: "[Beer]",
      QTY: "",
      Amount: "",
      Amt: "",
    },
    {
      Item: "Asahi Beer",
      QTY: "2",
      Amount: "8.92",
      Amt: "2.11",
    },
    {
      Item: "Asahi Beer",
      QTY: "2",
      Amount: "31.22",
      Amt: "7.40",
    },
    {
      Item: "Corona",
      QTY: "9",
      Amount: "8.00",
      Amt: "1.90",
    },
    {
      Item: "Corona",
      QTY: "7",
      Amount: "28.00",
      Amt: "6.25",
    },
    {
      Item: "Corona",
      QTY: "36",
      Amount: "144.00",
      Amt: "6.64",
    },
    {
      Item: "Corona",
      QTY: "1",
      Amount: "3.00",
      Amt: "34.25",
    },
    {
      Item: "Hahn premi Light",
      QTY: "2",
      Amount: "12.00",
      Amt: "1.42",
    },
    {
      Item: "Smrsby Apple cdr",
      QTY: "4",
      Amount: "20",
      Amt: "2.11",
    },
    {
      Item: "Total",
      QTY: "102",
      Amount: "260.00",
      Amt: "0.17",
    },
    {
      Item: "[Box Specials]",
      QTY: "",
      Amount: "",
      Amt: "",
    },
    {
      Item: "Corona",
      QTY: "36",
      Amount: "144.00",
      Amt: "6.64",
    },
    {
      Item: "Corona",
      QTY: "1",
      Amount: "3.00",
      Amt: "34.25",
    },
    {
      Item: "Hahn premi Light",
      QTY: "2",
      Amount: "12.00",
      Amt: "1.42",
    },
    {
      Item: "Smrsby Apple cdr",
      QTY: "4",
      Amount: "20",
      Amt: "2.11",
    },
    {
      Item: "Grand Total",
      QTY: "",
      Amount: "12458963.25",
      Amt: "$0.00",
      classset: "cash-border",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <section className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Sales Mix Report</h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">Sales Mix Report</h5>
        <div className="report-table">
          {reporttable?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width">{text?.Item}</h6>
              <h6 className="title-width">{text?.QTY}</h6>
              <h6 className="title-width">{text?.Amount}</h6>
              <h6 className="title-width">{text?.Amt}</h6>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </section>
    </Suspense>
  );
}

export default SalesmixReportTab;
