import React, { useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import Smallpop from "../../../../../../../Components/Reusable/Smallpop";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";

function AssignHeadMenu() {
  const [pathname, setPathname] = useState();

  const [options, setOptions] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setPathname(location.pathname);
    }
  }, [location]);
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const MenuList = useSelector((state) => state.headerMenu?.LoginHeadList);
  const MenuList = [
    {
      pathname: "/parent-dashboard",
      menuName: "Dashboard",
    },
    {
      pathname: "/parent-meal-information",
      menuName: "Meal Information",
    },
    {
      pathname: "/parent-payment-history",
      menuName: "Payments History",
    },
    {
      pathname: "/parent-make-payment",
      menuName: "Make Payment",
    },
    {
      pathname: "/submitted-surveys-list",
      menuName: "Info. Survey",
    },
    {
      pathname: "/communication-history",
      menuName: "Communication History",
    },
    {
      pathname: "/student-list",
      menuName: "Students",
      subMenu: [
        {
          menuUrl: "/student-list",
          menuName: "Student List",
        },
        {
          menuUrl: "/student-document",
          menuName: "Student Document Download",
        },
        {
          menuUrl: "/student-document-upload",
          menuName: "Student Document Upload",
        },
      ]
    },
  ];

  const MenuName = useSelector((state) => state.setting?.MenuName);
  let navigate = useNavigate();
  const showSub = (event, item) => {
    if (item?.subMenu?.length === 0) {
      navigate(item?.url, { replace: true });
    } else if (item?.subMenu !== undefined) {
      setAnchorEl(event?.currentTarget);
      setOptions(item?.subMenu);
    } else {
      setAnchorEl();
      setOptions();
    }
  };

  return (
    <Nav
      navbar
      className="header_item"
      style={{
        display: "flex",
        listStyle: "none",
        justifyContent: "space-around",
      }}
    >
      {MenuList?.map((item, i) => (
        <React.Fragment key={i}>
          {item?.subMenu?.length > 0 ? (
            <NavItem
              className={`header_active nav-item ${item.subMenu?.find((data) => data?.menuName === MenuName)
                  ? `linear ${MenuName} `
                  : ""
                }`}
              key={i}
            >
              <NavLink
                className={`header_link ${item.subMenu?.find((data) => data?.pathname === pathname)
                    ? "ac"
                    : ""
                  }`}
                onClick={(e) => showSub(e, item)}
                to={`${item?.subMenu?.length > 0 ? "" : item.pathname}`}
              >
                {item.menuName}
                {item?.subMenu?.length > 0 ? (
                  <i className="fas fa-chevron-down icon"></i>
                ) : (
                  ""
                )}
              </NavLink>
            </NavItem>
          ) : (
            <NavItem
              className={`header_active nav-item ${item.subMenu?.find((data) => data?.menuName === MenuName)
                  ? `linear ${MenuName} `
                  : ""
                }`}
              key={i}
              onClick={handleClose}
            >
              <NavLink
                className={`header_link ${pathname === item.pathname ? "ac" : ""
                  }`}
                to={`${item.pathname}`}
              >
                {item.menuName}
              </NavLink>
            </NavItem>
          )}
        </React.Fragment>
      ))}
      <Smallpop
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        optionsMenu={options}
        megamenu={options?.length >= 4 ? "megamenu" : ""}
        className={`Menu_icon_settings`}
      />
    </Nav>
  );
}

export default React.memo(AssignHeadMenu);
