import React, { Suspense } from "react";
import { Box, Tab } from "@mui/material";
import { CircularProgress } from "@mui/material";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import StudentTab from "../Students/StudentTab";
// import EmailhistoryTab from "../Students/EmailhistoryTab";
// import BalancehistoryTab from "../Students/BalancehistoryTab";
import ComunicationhistoryTab from "../Students/ComunicationhistoryTab";
import PaymentTab from "../Students/PaymentTab";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function Students() {
  const [values, setValues] = React.useState("1");
  const handleChangeTab = (event, newValue) => {
    setValues(newValue);
  };

  return (
    <Suspense fallback={renderLoader()}>
      {/* <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
      </div> */}
      <section className="students">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="student_detail_box">
                <TabContext value={values}>
                  <div className="d-flex justify-content-between align-items-center flex-wrap mb-4">
                    <Box className="tab_group d-flex justify-content-between">
                      <TabList
                        onChange={handleChangeTab}
                        aria-label="lab API tabs example"
                        className="tab_list"
                      >
                        <Tab
                          label="Student"
                          value="1"
                          className="tabs_link tab_1"
                        />
                        {/* <Tab
                      label="Email History"
                      value="2"
                      className="tabs_link tab_2"
                    />
                    <Tab
                      label="Balance History"
                      value="3"
                      className="tabs_link tab_3"
                    /> */}
                        <Tab
                          label="Communication History"
                          value="4"
                          className="tabs_link tab_4"
                        />
                        <Tab
                          label="Payment History"
                          value="5"
                          className="tabs_link tab_5"
                        />
                      </TabList>
                    </Box>
                    <div className="create-btn">
                      {values === "1" ? (
                        <Link
                          to={"/createstudent/create"}
                          className="theme_btn"
                        >
                          <BsPlusSquareDotted fontSize={24} className="me-3" />
                          Create
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="alltabcontent">
                    <TabPanel value="1" className="tab_panel p-0">
                      <StudentTab />
                    </TabPanel>
                    {/* <TabPanel value="2" className="tab_panel p-0">
                  <EmailhistoryTab />
                </TabPanel>
                <TabPanel value="3" className="tab_panel p-0">
                  <BalancehistoryTab />
                </TabPanel> */}
                    <TabPanel value="4" className="tab_panel p-0">
                      <ComunicationhistoryTab />
                    </TabPanel>
                    <TabPanel value="5" className="tab_panel p-0">
                      <PaymentTab />
                    </TabPanel>
                  </div>
                </TabContext>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

export default Students;
