import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import Swal from "sweetalert2";
import "./index.scss";
import filter from "../../../../../Assets/PngImage/filter.png";
import { useForm } from "react-hook-form";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

const productHead = [
  { id: "isSurveySMSsend", label: "isSurveySMSsend" },
  { id: "domain", label: "Domain" },
  { id: "alias", label: "Alias" },
  { id: "tinyURL", label: "TinyURL" },
  { id: "tags", label: "TagsURL" },
  { id: "tinyURLPhone", label: "TinyURLPhone" },
  { id: "familyCode", label: "Family Code" },
  { id: "parentName", label: "Parent Name" },
  { id: "parentPhone", label: "Parent Phone" },
  { id: "parentEmail", label: "Parent Email" },
  { id: "message", label: "Message", des: true },
  { id: "address", label: "Address" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
];

function ParentSendText() {
  const [openCircle, setOpenCircle] = useState(false);
  const [open, setOpen] = useState(false);
  // const [overlaySelect, setOverlaySelect] = useState("All");

  // const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [prdList, setPrdList] = useState([]);
  const [prdAddAr, setPrdAddAr] = useState([]);
  const [distList, setDistList] = useState([]);

  const {
    register,
    handleSubmit,
    getValues,
  } = useForm();

  // useEffect(() => {
  //   if (overlaySelect) {
  //     GetListParentSending();
  //   }
  //   // eslint-disable-next-line
  // }, [overlaySelect]);

  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, [])

  const getDistrictList = async () => {
    setDistList([]);
    let seData = {
      url: "/api/SchoolDistrict/GetAllSchoolDistrictList",
      headers: headers,
    };
    let res = await GetCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            ...sd,
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const GetListParentSending = async (data) => {
    setOpenCircle(true);
    setPrdList([])
    let seData = {
      url: `/api/Parents/GetParentForSurveyList?isMessage=${data?.msg}&schoolDistrictId=${data?.dist}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    setOpenCircle(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        const SdData = res.data.payload?.map((sd, i) => {
          return {
            ...sd,
            isSurveySMSsend: sd?.isSurveySMSsend ? "True" : "False",
            desTit: "Message",
            description: sd?.message,
            Action: (
              <>
                <label className=" personal_detail_label">
                  <input
                    type="checkbox"
                    className="login_check"
                    name="Remember Me"
                    checked={false}
                    onChange={(e) =>
                      handleAddProduct(e?.target?.checked, sd?.familyCode)
                    }
                  />
                  <span className="checkmark"></span>
                </label>
              </>
            ),
          };
        });
        setPrdList(SdData);
      } else {
        setOpenCircle(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };

  const handleAddProduct = (val, familyCode) => {
    if (val) {
      setPrdAddAr((oldArray) => [...oldArray, familyCode]);
    } else {
      setPrdAddAr((oldArray) =>
        oldArray?.filter((data) => data !== familyCode)
      );
    }
  };
  const handleSelectAll = (val, list) => {
    if (val) {
      setPrdAddAr(list?.map((x) => x.familyCode));
    } else {
      setPrdAddAr([]);
    }
  };
  useEffect(() => {
    if (prdAddAr?.length > 0) {
      changetbData();
    } else {
      changetbData();
    }
    // eslint-disable-next-line
  }, [prdAddAr]);
  const changetbData = (val) => {
    let newtb = prdList?.map((sd) => {
      return {
        ...sd,
        Action: (
          <>
            <label className=" personal_detail_label">
              <input
                type="checkbox"
                className="login_check"
                name="Remember Me"
                checked={
                  prdAddAr?.filter((x) => x === sd?.familyCode)?.length > 0
                    ? true
                    : false
                }
                onChange={(e) =>
                  handleAddProduct(e?.target?.checked, sd?.familyCode)
                }
              />
              <span className="checkmark"></span>
            </label>
          </>
        ),
      };
    });
    setPrdList(newtb);
  };

  const AddStudents = async () => {
    let sendData = prdList
      ?.filter((x) => prdAddAr?.includes(x?.familyCode))
      ?.map((x) => {
        return {
          parentGuidId: x?.familyCode,
          mobileNumber: x?.parentPhone,
          messageBody: x?.message,
          isMessageSend: false,
        };
      });
    let seData = {
      url: "/api/Parents/SendSurveySMSToUser",
      body: sendData,
      headers: headers,
    };

    setOpen(true);
    let res = await PostCallApi(seData);
    setOpen(false);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpen(false);
        setPrdAddAr([]);

        GetListParentSending({ msg: "All", dist: getValues('dist')});
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setOpen(false);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="parent_send_text_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <h4 className="all_title mb-4">
                  Household Send Text List
                </h4>
                <form onSubmit={handleSubmit(GetListParentSending)}>
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> District
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("dist", {
                          })}
                        >
                          <option value="">Select School District</option>
                          {distList &&
                            distList?.map((data, i) => (
                              <option key={i} value={data?.value}>
                                {data?.label}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label> Is Msg Send
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("msg", {
                          })}
                        >
                          <option value="All">All</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="ms-auto mt-auto col-md-4">
                      <div className="form-group text-end filter-img">
                        {openCircle ? (
                          <Button className="theme_btn text-end">
                            Applying Filter{" "}
                            <CircularProgress
                              sx={{
                                color: "#fff",
                                marginLeft: "0.5rem",
                                height: "23px !important",
                                width: "23px !important",
                              }}
                            />
                          </Button>
                        ) : (
                          <Button className="theme_btn text-end" type="submit">
                            <img
                              src={filter}
                              className="me-1"
                              alt=""
                              height={23}
                            />
                            Apply
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                  actionFirst={true}
                  btnTxt={"Send Text"}
                  selectAllBtn={true}
                  prdAddAr={prdAddAr}
                  AddStudents={AddStudents}
                  selectingAll={handleSelectAll}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ParentSendText;
