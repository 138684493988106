import { Backdrop, Box, Button, CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GetCallApi, PostCallApi } from "../../../../../Action/Action";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  background: "#ffffff",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  p: 4,
};
function EditBulkPopup(props) {
  const { id, callapi } = props;
  const [userList, setUserList] = useState();
  const [servingList, setServingList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [overDe, setOverDe] = useState();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm();
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const [openCircle, setOpenCircle] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    getDistrictList();
    setTimeout(() => {
      if (id) {
        EditBulkMealData(id);
      }
    }, 500);
  };
  const handleClose = () => setOpen(false);

  const getDistrictList = async () => {
    let sedata = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(sedata);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.schoolDistrictId,
            label: sd?.districtName,
          };
        });
        setDistrictList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getSchoolList = async (id) => {
    if (id) {
      let sedata = {
        url: `/api/School/GetSchoolDataBySchoolDistrictId?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(sedata);

      if (res?.status === 200) {
        if (res.data.success) {
          const dlist = res.data.payload?.map((sd) => {
            return {
              value: sd?.schoolId,
              label: sd?.schoolName,
            };
          });
          setSchoolList(dlist?.length > 0 ? dlist : []);
        }
      }
    } else {
      setSchoolList([]);
    }
  };
  const GetCashierList = async (district, sch) => {
    setUserList([]);
    if (district && sch) {
      let sedata = {
        url: `/api/User/GetUserDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userType=Cashier`,
        headers: headers,
      };
      let res = await GetCallApi(sedata);

      if (res?.status === 200) {
        if (res.data.success) {
          const SdData = res.data.payload?.map((sd, i) => {
            return {
              name: sd?.name,
              userId: sd?.userId,
            };
          });
          setUserList(SdData?.length > 0 ? SdData : []);
        } else {
          setUserList([]);
        }
      }
    } else {
      setUserList([]);
    }
  };

  const GetServingList = async (district, sch) => {
    if (district && sch) {
      let sedata = {
        url: `/api/ServingLine/GetServingLineDataBySchoolDistrictId?schoolDistrictId=${district}&schoolId=${sch}&userId=${LogDetail?.userId}`,
        headers: headers,
      };
      let res = await GetCallApi(sedata);

      if (res?.status === 200) {
        if (res.data.success) {
          const SdData = res.data.payload;
          setServingList(SdData);
        }
      }
    } else {
      setServingList([]);
    }
  };
  const handleshowDrop = (e, ml) => {
    if (e) {
      GetServingList(getValues("schoolDistrictId"), e);
      GetCashierList(getValues("schoolDistrictId"), e);
    } else {
    }
  };
  useEffect(() => {
    if (schoolList) {
      if (overDe) {
        setValue("schoolId", overDe?.bmc?.schoolId);
      }
      // if(id){
      // }
    }
    // eslint-disable-next-line
  }, [schoolList, overDe]);
  useEffect(() => {
    if (servingList) {
      if (overDe) {
        setValue("servingLineId", overDe?.bmc?.servingLineId);
      }
      // if(id){
      // }
    }
    // eslint-disable-next-line
  }, [servingList, overDe]);
  useEffect(() => {
    if (userList) {
      if (overDe) {
        setValue("cashierUserID", overDe?.bmc?.cashierUserId);
      }
      // if(id){
      // }
    }
    // eslint-disable-next-line
  }, [userList, overDe]);

  const EditBulkMealData = async (id) => {
    let sedata = {
      url: `/api/BulkMeal/GetBulkMealCountsDataByID?bulkMealCountId=${id}`,
      headers: headers,
    };
    setOpenCircle(true);

    let res = await GetCallApi(sedata);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        let meData = res.data.payload;
        setOverDe(meData);
        setValue("bulkMealCountId", meData?.bmc?.bulkMealCountId);
        setValue("schoolDistrictId", meData?.bmc?.schoolDistrictId);
        getSchoolList(meData?.bmc?.schoolDistrictId);
        GetServingList(meData?.bmc?.schoolDistrictId, meData?.bmc?.schoolId);
        GetCashierList(meData?.bmc?.schoolDistrictId, meData?.bmc?.schoolId);
        setValue("mealService", meData?.bmc?.mealService);
        setValue(
          "date",
          meData?.bmc?.date ? meData?.bmc?.date?.split("T")[0] : ""
        );
        setValue("mealCount", meData?.bmc?.mealCount);
        setValue("paymentStatus", meData?.bmc?.paymentStatus);
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };
  const onSubmit = async (data) => {
    let sendData = {
      ...data,
      price: 0,
      createdBy: LogDetail?.userId,
    };
    let seData = {
      url: "/api/BulkMeal/CreateBulkMealCounts",
      body: sendData,
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);

    if (res?.status === 200) {
      if (res.data.success) {
        setOpenCircle(false);
        reset();
        setOverDe();
        handleClose();
        callapi();

        // navigate(-1);
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        setOpenCircle(false);
      }
    } else {
      setOpenCircle(false);
    }
  };

  return (
    <>
      <button className="btn_dlt " onClick={handleOpen}>
        <MdEdit className="icon" fontSize={24} />
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 9999 }}
        open={openCircle}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="toggle_delete_data"
      >
        <Box sx={style} className="delete_data_box">
          <div className="product_box ">
            <div className="all_title ProductTitle">Edit Bulk Entry</div>
          </div>
          <form className="mt-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label> District</label>
                  <select
                    name=""
                    className="form-select"
                    {...register("schoolDistrictId", {
                      required: "This field is required",
                      onChange: (e) => getSchoolList(e.target.value),
                    })}
                  >
                    <option value="">Select School District</option>
                    {districtList &&
                      districtList?.map((data, i) => (
                        <option key={i} value={data?.value}>
                          {data?.label}
                        </option>
                      ))}
                  </select>
                  {errors.schoolDistrictId && (
                    <span role="alert" className="error_text">
                      {errors.schoolDistrictId.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label> School</label>
                  <select
                    className="form-select"
                    {...register("schoolId", {
                      required: "This field is required",
                      onChange: (e) => handleshowDrop(e.target.value),
                    })}
                  >
                    <option value="">Select School </option>
                    {schoolList.map((item, i) => (
                      <option key={i} value={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                  </select>
                  {errors.schoolId && (
                    <span role="alert" className="error_text">
                      {errors.schoolId.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label> Serving Line</label>
                  <select
                    name=""
                    className="form-select"
                    {...register("servingLineId", {
                      required: "This field is required",
                      valueAsNumber: true,
                    })}
                  >
                    <option value="">Select Serving Line</option>
                    {servingList &&
                      servingList?.map((data, i) => (
                        <option key={i} value={data?.servingLineId}>
                          {data?.servingLineName}
                        </option>
                      ))}
                  </select>
                  {errors.servingLineId && (
                    <span role="alert" className="error_text">
                      {errors.servingLineId.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label> Meal Service</label>
                  <select
                    className="form-select"
                    {...register("mealService", {
                      required: "This field is required",
                    })}
                  >
                    <option value="">Select Meal Service </option>
                    <option value="Breakfast">Breakfast</option>
                    <option value="Dinner">Dinner</option>
                    <option value="Lunch">Lunch</option>
                    <option value="Snacks">Snacks</option>
                  </select>
                  {errors.mealService && (
                    <span role="alert" className="error_text">
                      {errors.mealService.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Meal Count<span className="red-color">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Meal Count"
                    min="1"
                    {...register("mealCount", {
                      required: "This field is required",
                      valueAsNumber: true,
                    })}
                  />
                  {errors.mealCount && (
                    <span className="formError errorMssg error_text">
                      {errors.mealCount.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Meal Date<span className="red-color">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Meal Date"
                    {...register("date", {
                      required: "This field is required",
                    })}
                  />
                  {errors.date && (
                    <span className="formError errorMssg error_text">
                      {errors.date.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label> Cashier</label>
                  <select
                    name=""
                    className="form-select"
                    {...register("cashierUserID", {
                      required: "This field is required",
                    })}
                  >
                    <option value="">Select Cashier</option>
                    {userList &&
                      userList?.map((data, i) => (
                        <option key={i} value={data?.userId}>
                          {data?.name}
                        </option>
                      ))}
                  </select>
                  {errors.cashierUserID && (
                    <span role="alert" className="error_text">
                      {errors.cashierUserID.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label> Payment Type</label>
                  <select
                    name=""
                    className="form-select"
                    {...register("paymentStatus", {
                      required: "This field is required",
                    })}
                  >
                    <option value="">Select Payment Type</option>
                    <option value="F">FREE</option>
                    <option value="P">PAID</option>
                    <option value="R">REDUCED</option>
                    <option value="FOSTER CHILD">FOSTER CHILD</option>
                    <option value="SNAP">SNAP</option>
                    <option value="TNAF">TNAF</option>
                  </select>
                  {errors.paymentStatus && (
                    <span role="alert" className="error_text">
                      {errors.paymentStatus.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-12 text-end mt-3">
                <Button type="submit" className="theme_btn me-2">
                  Submit
                </Button>
                <Button className="cancel_btn" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default EditBulkPopup;
