import { Backdrop, Button, CircularProgress } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom'
import { GetCallApi, PostCallApi } from '../../../../../../Action/Action';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';


const renderLoader = () => (
    <div className="loader">
        <CircularProgress sx={{ color: "#86c65a" }} />
    </div>
);
const CreateFinanceVendorCat = () => {
    const navigate = useNavigate();
    const [open, setOpen] = useState();
    const [distList, setDistList] = useState([]);

    const { id } = useParams();

    const LogDetail = useSelector((state) => state.login.LoginDetails);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    //    get expense edit data
    useEffect(() => {
        if (id) {
            getEditedData(id);
        }
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        const districtList = LogDetail?.districtClasses?.map((x) => {
            return {
                value: x?.schoolDistrictId,
                label: x?.schoolDistrictName,
            }
        })
        setDistList(districtList ?? []);
        if (!id) {
            setTimeout(() => {
                setValue("schoolDistrictId", districtList[0]?.value);
            }, 1000);
        }
        // eslint-disable-next-line
    }, []);

    // create / Edit expence finance  
    const onSubmit = async (data) => {
        setOpen(true);

        let expData = {
            ...data,
            createdBy: LogDetail?.userId,
        }

        let seData = {
            url: `/api/FinanceVendorCategory/CreateFinanceVendorCategory`,
            body: expData,
        };
        let respo = await PostCallApi(seData);
        setOpen(false);
        if (respo?.status === 200) {
            if (respo.data.success) {
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo.data?.message,
                    showConfirmButton: false,
                    timer: 1500,
                    color: "#ffffff",
                    background: "#48953e",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
                navigate(-1)
            } else {
                setOpen(false);
                Swal.fire({
                    position: "top-end",
                    width: 400,
                    // icon: "success",
                    text: respo?.data?.message
                        ? respo?.data?.message
                        : "Something went wrong please try again.",
                    showConfirmButton: false,
                    timer: 2000,
                    color: "#ffffff",
                    background: "red",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown",
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutRight",
                    },
                });
            }
        } else {
            setOpen(false);
            Swal.fire({
                position: "top-end",
                width: 400,
                // icon: "success",
                text: respo?.data?.message
                    ? respo?.data?.message
                    : "Something went wrong please try again.",
                showConfirmButton: false,
                timer: 2000,
                color: "#ffffff",
                background: "red",
                showClass: {
                    popup: "animate__animated animate__fadeInDown",
                },
                hideClass: {
                    popup: "animate__animated animate__fadeOutRight",
                },
            });
        }
    };



    const getEditedData = async (id) => {
        setOpen(true);

        let sendData = {
            url: `/api/FinanceVendorCategory/GetFinanceVendorCategoryDataById?vendorCategoryId=${id}`,
        };

        let res = await GetCallApi(sendData);
        setOpen(false);
        if (res?.status === 200) {
            if (res.data.success) {
                let editsiteData = res?.data?.payload;
                setValue("schoolDistrictId", editsiteData?.schoolDistrictId);
                setValue("categoryName", editsiteData?.categoryName);
                setValue("vendorCategoryId", editsiteData?.vendorCategoryId);
                setValue("sequenceNo", editsiteData?.sequenceNo);
            }
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress sx={{ color: "#86c65a" }} />
            </Backdrop>
            <Suspense fallback={renderLoader()}>
                <section className="create_ExpenceCategory">
                    <div className="main_wrapper">
                        <div className="ProductCategories_hed">
                            <div className="all_title ProductTitle">
                                {id ? "Edit" : "Create"} Finance Vendor Category
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <form
                                    action=""
                                    onSubmit={handleSubmit(onSubmit)}
                                    className="primary-box"
                                >
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    District <span className="red-color">*</span>
                                                </label>
                                                <select
                                                    name=""
                                                    className="form-select"
                                                    {...register(`schoolDistrictId`, {
                                                        required: "This field is required",
                                                    })}
                                                >
                                                    <option value="">Select School District</option>
                                                    {distList &&
                                                        distList?.map((data, i) => (
                                                            <option key={i} value={data?.value}>
                                                                {data?.label}
                                                            </option>
                                                        ))}
                                                </select>
                                                {errors?.schoolDistrictId && (
                                                    <span role="alert" className="error_text">
                                                        {errors?.schoolDistrictId.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Category   <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Category   "
                                                    {...register("categoryName", {
                                                        required: "This field is required",
                                                        maxLength: {
                                                            value: 255,
                                                            message: "Max 255 characters",
                                                        },
                                                        pattern: {
                                                            value: /^[^\s]+(\s+[^\s]+)*$/,
                                                            message: "Starting and Ending Space not allowed",
                                                        },
                                                    })}
                                                />
                                                {errors.categoryName && (
                                                    <span role="alert" className="error_text">
                                                        {errors.categoryName.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>
                                                    Sequence No <span className="red-color">*</span>{" "}
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    min={1}
                                                    placeholder="Sequence No"
                                                    {...register("sequenceNo", {
                                                        required: "This field is required",
                                                        valueAsNumber: true,
                                                    })}
                                                />
                                                {errors.sequenceNo && (
                                                    <span role="alert" className="error_text">
                                                        {errors.sequenceNo.message}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-md-12 text-end">
                                            <Button type="submit" className="theme_btn me-2">
                                                Submit
                                            </Button>
                                            <Button
                                                className="cancel_btn"
                                                onClick={() => navigate(-1)}
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Suspense>
        </>
    )
}

export default CreateFinanceVendorCat