import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import filter from "../../../../Assets/PngImage/filter.png";
import Axios from "../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";

const ListTable = lazy(() =>
  import("../../../../Components/Reusable/ListTable")
);

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "status", label: "Status" },
  { id: "studentName", label: "Student Name" },
  { id: "studentNumber", label: "Students Id" },
  { id: "schoolName", label: "School Name" },
  { id: "districtName", label: "District Name" },
  { id: "schoolFees", label: "School Receives" },
  { id: "fees", label: "Fees" },
  // { id: "swanFees", label: "Swan Fees" },
  { id: "totalAmount", label: "Total Amount" },
  { id: "paymentReference", label: "Payments Ref" },
  { id: "paymentDate", label: "Date" },
  { id: "dateUtc", label: "Date UTC" },
];
function PaymentHistory() {
  const { register, handleSubmit } = useForm();
  const [openCircle, setOpenCircle] = useState(false);

  const [paymentList, setPaymentList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    PaymentTab();
    // eslint-disable-next-line
  }, []);

  const PaymentTab = (data) => {
    let sendData = {
      paymentReference: data?.paymentReference ? data?.paymentReference : "",
      paymentDate: data?.paymentDate ? data?.paymentDate : null,
      studentGuidId: LogDetail?.studentGuidId,
    };
    setPaymentList([]);
    setOpenCircle(true);
    Axios.post("/api/StudentPayment/StudentPaymentHistory", sendData, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let sdData = res.data.payload?.map((x) => {
              return {
                ...x,
                // fees: (
                //   Math.round((x?.stripeFees + x?.swanFees) * 100) / 100
                // ).toFixed(2),
                // totalAmount: (
                //   Math.round(
                //     (x?.stripeFees + x?.swanFees + x?.schoolFees) * 100
                //   ) / 100
                // ).toFixed(2),
                // schoolFees: (Math.round(x?.schoolFees * 100) / 100).toFixed(2),
                fees: (x?.stripeFees + x?.swanFees)?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                totalAmount: (x?.stripeFees + x?.swanFees + x?.schoolFees)?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                schoolFees: x?.schoolFees?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                paymentDate: x?.paymentDate
                  ? new Date(x?.paymentDate).toLocaleString()
                  : "",
                dateUtc: x?.dateUtc
                  ? new Date(x?.dateUtc).toLocaleString()
                  : "",
              };
            });
            setPaymentList(sdData);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);

            setPaymentList([]);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="payment-history-page">
          <div className="main_wrapper">
            <div className="product_box ">
              <div className="all_title ProductTitle">Payment History</div>
            </div>
            <div className="white-box">
              <form onSubmit={handleSubmit(PaymentTab)}>
                <div className="row">
                  {/* <div className="col-md-3">
                  <div className="form-group">
                    <label>Student Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Student Name"
                      {...register("studentName")}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>Student Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Student Number"
                      {...register("studentNumber")}
                    />
                  </div>
                </div> */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Payments Reference</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Payments Reference"
                        {...register("paymentReference")}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Payment Date</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Payment Date"
                        {...register("paymentDate", {
                          valueAsDate: true,
                        })}
                      />
                      {/* <ReactDatePicker
                        showIcon
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      /> */}
                    </div>
                  </div>
                  <div className="col-md-6 my-auto text-end">
                    <Button type="submit" className="theme_btn filter-img">
                      <img src={filter} className="me-1 " alt="" /> Apply
                    </Button>
                  </div>
                </div>
              </form>
              <ListTable headCells={productHead} ListData={paymentList} />
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default PaymentHistory;
