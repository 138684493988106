import React, { Suspense, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "../../../../../../Utils/AxiosConfig";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreateMenu() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openCircle, setOpenCircle] = useState(false);
  const [tbData, setTbData] = useState([]);
  const [val, setVal] = useState("");

  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    GetMenuList();
    // eslint-disable-next-line
  }, []);
  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId };
    setOpenCircle(true);
    Axios.post("/api/Menu/CreateMenu", sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (id) {
      EditRoleManagment(id);
    }
    // eslint-disable-next-line
  }, [id]);
  const EditRoleManagment = (id) => {
    setOpenCircle(true);
    Axios.get(`/api/Menu/EditMenu?MenuId=${id}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let sdDetail = res.data.payload;
            setValue("menuId", sdDetail?.menuId);
            setValue("menuName", sdDetail?.menuName);
            setValue("menuUrl", sdDetail?.menuUrl);
            setValue("menuType", sdDetail?.menuType);
            setValue("description", sdDetail?.description);
            setValue("parentMenuId", sdDetail?.parentMenuId);
            setValue("sequenceNo", sdDetail?.sequenceNo);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const GetMenuList = () => {
    setOpenCircle(true);
    Axios.get(`/api/Menu/GetAllMenuList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            const SdData = res.data.payload
              ?.filter((x) => x.isActive === true)
              ?.filter((x) => x?.menuType === "headerMenu")
              ?.map((sd, i) => {
                return {
                  menuId: sd?.menuId,
                  menuName: sd?.menuName,
                  parentMenuId: sd?.parentMenuId,
                  parentMenuName: sd?.parentMenuName,
                };
              });
            setTbData(SdData);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const handleChange = (val) => {
    setVal(val)
    setValue("parentMenuId", 0)
  }

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createprimarytab-page">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">Menu</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Menu Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Menu Name"
                          {...register("menuName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.menuName && (
                          <span role="alert" className="error_text">
                            {errors.menuName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Menu Type <span className="red-color">*</span>{" "}
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("menuType", {
                            required: "This field is required",
                          })}
                          onChange={(e) => handleChange(e.target.value)}
                        >
                          <option value="">Select Menu Type</option>
                          <option value="headerMenu">Header Menu</option>
                          <option value="headerSubMenu">Header Sub Menu</option>
                        </select>
                        {errors.menuType && (
                          <span role="alert" className="error_text">
                            {errors.menuType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Menu Url <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Menu Url"
                          {...register("menuUrl", {
                            required: "This field is required",
                          })}
                        />
                        {errors.menuUrl && (
                          <span role="alert" className="error_text">
                            {errors.menuUrl.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Parent Menu</label>
                        <select
                          name=""
                          className="form-select"
                          disabled={val === "headerMenu" ? true : false}
                          {...register("parentMenuId")}
                        >
                          <option value="0">Select Parent Menu</option>
                          {tbData &&
                            tbData?.map((data, i) => (
                              <option key={i} value={data?.menuId}>
                                {data?.menuName}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Sequence No <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Sequence No"
                          {...register("sequenceNo", {
                            required: "This field is required",
                            valueAsNumber: true,
                          })}
                        />
                        {errors.sequenceNo && (
                          <span role="alert" className="error_text">
                            {errors.sequenceNo.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Description</label>
                        <textarea
                          className="form-control"
                          rows={5}
                          placeholder="Enter description"
                          {...register("description")}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreateMenu;
