import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./index.scss";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetCallApi, PostCallApi } from "../../../../Action/Action";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import AddSchedulePopup from "./AddSchedulePopup";
import Swal from "sweetalert2";
import EditSchedulePopup from "./EditSchedulePopup";
import CopySchedulePopup from "./CopySchedulePopup";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
export default function FullCalendarComponent() {
  // const handleDateClick = (arg) => {
  //   alert(arg.dateStr);
  // };
  let calendarRef = React.useRef();
  const [openCircle, setOpenCircle] = useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openDetail, setOpenDetail] = React.useState();
  const [districtList, setDistrictList] = useState([]);
  const [distId, setDistId] = useState();

  const [dateSe, setDateSe] = useState();
  const [scheduleList, setScheduleList] = useState([]);
  const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getDistrictList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (distId) {
      getScheduleData(calendarRef?.current?.calendar);
    }
    // eslint-disable-next-line
  }, [distId]);

  function convertDate(str) {
    var date = new Date(str?.replace(/-/g, "/")),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  const getScheduleData = async (ds) => {
    setOpenDetail();
    let newD = ds?.view?.title?.split(" – ");
    let tls =
      newD[0]?.length > 6 ? newD[0] : newD[0] + ", " + newD[1]?.split(", ")[1];
    tls = new Date(tls).toLocaleDateString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    tls = convertDate(tls);

    let tle =
      newD[1]?.length > 10 ? newD[1] : newD[0]?.split(" ")[0] + " " + newD[1];
    tle = new Date(tle).toLocaleDateString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    tle = convertDate(tle);
    // console.log(ds.view.title, newD, tls, tle);
    // let newD = ds?.view?.title;
    // let ns = ds?.start?.toUTCString();
    // let ne = ds?.end?.toUTCString();
    let ns = tls;
    let ne = tle;
    setDateSe({
      startDate: ns,
      endDate: ne,
    });
    // console.log(ns, ne);
    // const calendarAPI = calendarRef?.current?.getApi();

    // let s = getDaysOfWeek(calendarAPI);
    setScheduleList([]);
    if (distId) {
      let seData = {
        url: `/api/EmployeeShedualHour/GetAllEmployeeShedualHourList`,
        body: {
          startDate: ns,
          endDate: ne,
          schoolDistrictId: distId,
        },
        headers: headers,
      };
      setOpenCircle(true);
      let res = await PostCallApi(seData);
      setOpenCircle(false);
      if (res?.status === 200) {
        if (res?.data.success) {
          let schedule = res.data.payload?.map((x) => {
            let sdate = x?.startDate
              ? new Date(x?.startDate).toLocaleTimeString("en-us", {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
              })
              : "";
            let edate = x?.endDate
              ? new Date(x?.endDate).toLocaleTimeString("en-us", {
                hour12: true,
                hour: "2-digit",
                minute: "2-digit",
              })
              : "";
            return {
              ...x,
              startDate: sdate,
              endDate: edate,
              backgroundColor: x?.publishClass ? x?.publishClass : "#f5803e",
              title: x?.jobTitle?x?.jobTitle:"",
              date: x?.endDate ? x?.endDate?.split("T")[0] : "",
            };
          });
          setScheduleList(schedule);
        }
      }
    }
  };
  // const handleNextClick = () => {
  //   const calendarAPI = calendarRef?.current?.getApi();

  //   calendarAPI?.next();

  //   getScheduleData();
  // };
  // const handleTodayClick = () => {
  //   const calendarAPI = calendarRef?.current?.getApi();

  //   calendarAPI?.today();

  //   getScheduleData();
  // };
  // const handleprevClick = () => {
  //   const calendarAPI = calendarRef?.current?.getApi();
  //   calendarAPI?.prev();

  //   getScheduleData();
  // };
  // function getDaysOfWeek(calendar) {
  //   if (!calendar) return;
  //   let startDayWeek = calendar.view.activeStart;
  //   let endDayWeek = calendar.view.activeEnd;

  //   var firstDay = new Date(startDayWeek);
  //   var lastDay = new Date(endDayWeek);

  //   // let currentDayOfMonth = firstDay.getDate();
  //   // currentDayOfMonth =
  //   //   currentDayOfMonth > 9 ? currentDayOfMonth : "0" + currentDayOfMonth;
  //   // let currentMonth = firstDay.getMonth(); // Be careful! January is 0, not 1
  //   // currentMonth =
  //   //   currentMonth + 1 > 9 ? currentMonth + 1 : "0" + (currentMonth + 1);
  //   // const currentYear = firstDay.getFullYear();
  //   // const dateString =
  //   //   currentYear + "-" + currentMonth + "-" + currentDayOfMonth;
  //   // let currentDayOfMonthEnd = lastDay.getDate();
  //   // currentDayOfMonthEnd =
  //   //   currentDayOfMonthEnd > 9
  //   //     ? currentDayOfMonthEnd
  //   //     : "0" + currentDayOfMonthEnd;
  //   // let currentMonthEnd = lastDay.getMonth(); // Be careful! January is 0, not 1
  //   // currentMonthEnd =
  //   //   currentMonthEnd + 1 > 9
  //   //     ? currentMonthEnd + 1
  //   //     : "0" + (currentMonthEnd + 1);
  //   // const currentYearEnd = lastDay.getFullYear();
  //   // const dateStringEnd =
  //   //   currentYearEnd + "-" + currentMonthEnd + "-" + currentDayOfMonthEnd;

  //   let sv = {
  //     startDate: firstDay,
  //     endDate: lastDay,
  //     // startDate: firstDay,
  //     // endDate: lastDay,
  //   };
  //   return sv;
  // }
  const publishShift = async () => {
    // console.log(scheduleList?.filter((x) => x?.isPublished !== true));
    let seData = {
      url: "/api/EmployeeShedualHour/SendMailPublishAllShift",
      body: {
        employeeShedualHoursId: scheduleList
          ?.filter((x) => x?.isPublished !== true)
          ?.map((x) => x?.employeeShedualHoursId),
        publishClass: "#198754",
        createdBy: LogDetail?.userId,
      },
      headers: headers,
    };
    setOpenCircle(true);
    let res = await PostCallApi(seData);
    setOpenCircle(false);
    if (res?.status === 200) {
      if (res?.data.success) {
        const calendarAPI = calendarRef?.current?.getApi();

        getScheduleData(calendarAPI);

        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res.data?.message,
          showConfirmButton: false,
          timer: 1500,
          color: "#ffffff",
          background: "#48953e",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: res?.data?.message
            ? res?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    } else {
      Swal.fire({
        position: "top-end",
        width: 400,
        // icon: "success",
        text: res?.data?.message
          ? res?.data?.message
          : "Something went wrong please try again.",
        showConfirmButton: false,
        timer: 2000,
        color: "#ffffff",
        background: "red",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutRight",
        },
      });
    }
  };
  const GetScheduleCall = () => {
    const calendarAPI = calendarRef?.current?.getApi();

    getScheduleData(calendarAPI);
  };
  const HandleEdit = (e) => {
    if (e?.extendedProps?.isPublished !== true) {
      setOpenDetail(e?.extendedProps);
      setOpenEdit(true);
    }
  };
  const getDistrictList = async () => {
    let seData = {
      url: `/api/SchoolDistrict/GetSchoolDistrictList?userId=${LogDetail?.userId}`,
      headers: headers,
    };
    let res = await GetCallApi(seData);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload;
        setDistrictList(dlist?.length > 0 ? dlist : []);
        setDistId(dlist[0]?.schoolDistrictId);
      }
    }
  };
  const [openPopup, setOpenPopup] = useState(false);
  const [clickedDate, setClickedDate] = useState();
  function handleDateClick(info) {
    setOpenPopup(true);
    const clDate = info?.date;
    const nextDay = new Date(clDate);
    nextDay.setDate(nextDay.getDate() + 1);
    setClickedDate(nextDay?.toISOString().substring(0, 16)?.split("T")[0]);
  }
  return (
    <Suspense fallback={renderLoader()}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <section className="schedule-view">
        <div className="main_wrapper">
          <div className="card">
            <div className="card-body">
              <div className="main_head_box mb-4">
                <div className="all_title ">
                  <h2>Schedule Employee Hours</h2>
                </div>
                <div className="d-flex">
                  {scheduleList
                    ?.filter((x) => x?.isPublished !== true)
                    ?.filter((x) => x?.isCopyShift !== true)?.length > 0 ? (
                    <CopySchedulePopup
                      dateSe={dateSe}
                      scheduleList={scheduleList}
                      GetScheduleCall={GetScheduleCall}
                    />
                  ) : (
                    ""
                  )}
                  <AddSchedulePopup
                    GetScheduleCall={GetScheduleCall}
                    setOpenPopup={setOpenPopup}
                    openPopup={openPopup}
                    clickedDate={clickedDate}
                    distId={distId}
                    setClickedDate={setClickedDate}
                  />
                  <EditSchedulePopup
                    GetScheduleCall={GetScheduleCall}
                    open={openEdit}
                    distId={distId}
                    openDetail={openDetail}
                    setOpen={setOpenEdit}
                  />
                  {scheduleList?.filter((x) => x?.isPublished !== true)
                    ?.length > 0 ? (
                    <Button className="theme_btn " onClick={publishShift}>
                      Publish{" "}
                      {
                        scheduleList?.filter((x) => x?.isPublished !== true)
                          ?.length
                      }{" "}
                      Shift
                    </Button>
                  ) : (
                    <Button className="theme_btn ">All Shift Publish</Button>
                  )}
                  <div className="form-group ms-3 mb-0">
                    <select
                      name=""
                      className="form-select h-100"
                      value={distId}
                      onChange={(e) => setDistId(e.target.value)}
                    >
                      <option value="">Select School District</option>
                      {districtList &&
                        districtList?.map((ds, i) => (
                          <option key={i} value={ds?.schoolDistrictId}>
                            {ds?.districtName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>

              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridWeek"
                weekends={true}
                eventClick={(e) => HandleEdit(e.event)}
                datesSet={(dateinfo) => getScheduleData(dateinfo)}
                dateClick={handleDateClick}
                // dateClick={(e) => handleDateClick(e)}
                // headerToolbar={{
                //   right: "today prev,next",
                //   left: "prev,next",
                //   center: "title",
                //   right: "dayGridWeek,dayGridDay",
                // }}

                events={scheduleList}
                // events={[
                //   { title: "event 1", date: "2024-05-07" },
                //   { title: "event 2", date: "2024-05-17" },
                // ]}
                eventContent={renderEventContent}
              // customButtons={{
              //   next: {
              //     click: handleNextClick,
              //   },
              //   prev: {
              //     click: handleprevClick,
              //   },
              //   today: {
              //     text: "Today",
              //     click: handleTodayClick,
              //   },
              // }}
              />
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}

function renderEventContent(eventInfo) {
  // console.log(eventInfo);
  return (
    <div className="em_detail">
      {/* <b>{eventInfo.timeText}</b> */}
      <h5>{eventInfo.event?.extendedProps.userName}</h5>
      <h5>{eventInfo.event?.extendedProps.schoolName}</h5>
      <h5>
        {eventInfo?.event?.title}
        {/* - {eventInfo.event?.extendedProps?.mealService} */}
      </h5>
      <h5>
        {eventInfo.event?.extendedProps.startDate +
          " - " +
          eventInfo.event?.extendedProps.endDate}
      </h5>
      <h5>
        {"[" + eventInfo?.event?.extendedProps?.shiftDuration + "]"}[HH:MM]
      </h5>
    </div>
  );
}
