import React, { Suspense, lazy, useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { MdEdit } from "react-icons/md";
import "./index.scss";
import { Link } from "react-router-dom";
import { BsPlusSquareDotted } from "react-icons/bs";
import { useSelector } from "react-redux";
import Axios from "../../../../../Utils/AxiosConfig";
// import { Config } from "../../../../../Utils/Config";
// import defaultProfile from "../../../../../Assets/default_profile.jpg";
import MultiImgPopup from "../../../../../Components/Reusable/MultiImgPopup";

const ListTable = lazy(() =>
  import("../../../../../Components/Reusable/ListTable")
);
const DeletePopup = lazy(() =>
  import("../../../../../Components/Reusable/DeletePopup")
);
const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);
const productHead = [
  { id: "logo", label: "Logo" },
  { id: "productName", label: "Product Name", click: true },
  // { id: "manufactureName", label: "Manufacturer Name" },
  // { id: "distributorName", label: "Distributor Name" },
  { id: "price", label: "Price" },
  { id: "category", label: "Category" },
  { id: "subCategory", label: "Sub Category" },
  { id: "description", label: "Description", des: true },
  // { id: "gtin", label: "GTIN" },
  // { id: "upc", label: "UPC" },
];

function ProductList() {
  const [prdList, setPrdList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);

  let deleteInfo = useSelector((state) => state.Common?.DeleteData);
  // const LogDetail = useSelector((state) => state.login.LoginDetails);
  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    ProductListData();
    // eslint-disable-next-line
  }, [deleteInfo]);

  const ProductListData = (e) => {
    setOpenCircle(true);
    let sendData = {
      pageNumber: e?.page ? e.page : 1,
      pageSize: e?.no ? e.no : 20,
    };
    Axios.post(`/api/Product/GetALLProductData`, sendData, { headers })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.data.success) {
            setOpenCircle(false);
            const SdData = res.data.data.payload?.map((sd, i) => {
              return {
                logo: (
                  <MultiImgPopup img={sd?.productImageUrl1} imgAr={sd?.productImageUrl1 ? sd?.productImageUrl1?.split(";") : ""} />
                ),
                // productName: (
                //   <div className="product_name">{sd?.productName}</div>
                // ),
                mealList: sd?.productName,
                productName:
                  sd?.productName?.length > 25
                    ? sd?.productName?.substring(0, 25) + "..."
                    : sd?.productName,
                tlt: "Product Name",
                manufactureName: sd?.manufactureName,
                distributorName: sd?.distributorName,
                // price: (Math.round(sd?.price * 100) / 100).toFixed(2),
                price: sd?.price?.toLocaleString("en-US", { style: "currency", currency: "USD" }),
                gtin: sd?.gtin,
                upc: sd?.upc,
                category: sd?.category,
                subCategory: sd?.subCategory,
                description: sd?.description,
                desTit: "Description",
                Action: (
                  <>
                    <Link
                      type="button"
                      className="btn_edit me-2"
                      to={`edit/${sd?.productId}`}
                    >
                      <MdEdit fontSize={24} />
                    </Link>
                    <DeletePopup
                      title={"Delete Product"}
                      text={sd?.productName}
                      url={`/api/Product/DeleteProductData?ProductId=${sd?.productId}`}
                    />
                  </>
                ),
              };
            });
            setPrdList(SdData);
            setTotalRecord(res.data.data.totalRecords);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };

  const getApiData = (e) => {
    ProductListData(e);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>

      <Suspense fallback={renderLoader()}>
        <section className="product_listpage listing-product">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <div className="product_box ">
                  <div className="all_title ProductTitle">Product List</div>
                  <Link to={"create"} className="theme_btn">
                    <BsPlusSquareDotted fontSize={24} className="me-3" />
                    Create
                  </Link>
                </div>
                <ListTable
                  headCells={productHead}
                  action={true}
                  ListData={prdList}
                  pageApi={true}
                  totalRecord={totalRecord}
                  getApiData={(e) => getApiData(e)}
                />
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default ProductList;
