import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Backdrop, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import Axios from "../../../../../../Utils/AxiosConfig";
import "./index.scss";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { GetCallApi } from "../../../../../../Action/Action";
import uploadimg from "../../../../../../Assets/SvgImage/file-upload.svg";
import CropNewModel from "../../../Meals/CreateMeals/CropNewModel";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function CreatedistrictSeeting() {
  const [siteList, setSiteList] = useState();
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [openCircle, setOpenCircle] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };
  useEffect(() => {
    getStateList();
    getSchoolYear();
    // eslint-disable-next-line
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const getStateList = () => {
    Axios.get(`/api/Common/GetStateList?userId=${LogDetail?.userId}`, {
      headers,
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setSiteList(res.data.payload);
          }
        }
      })
      .catch((e) => console.log(e));
  };
  const onSubmit = (data) => {
    let sendData = { ...data, createdBy: LogDetail?.userId, country: "USA", DistrictLogo: data?.DistrictLogo };
    setOpenCircle(true);
    Axios.post("/api/SchoolDistrict/CreateSchoolDistrict", sendData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    })
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            setOpenCircle(false);
            Swal.fire({
              position: "top-end",
              width: 400,
              // icon: "success",
              text: res.data?.message,
              showConfirmButton: false,
              timer: 1500,
              color: "#ffffff",
              background: "#48953e",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutRight",
              },
            });
            reset();
            navigate(-1);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  useEffect(() => {
    if (siteList?.length > 0) {
      if (id) {
        EditDistrictManagment(id);
      }
    }
    // eslint-disable-next-line
  }, [id, siteList]);
  const EditDistrictManagment = (id) => {
    setOpenCircle(true);
    Axios.get(
      `/api/SchoolDistrict/GetSchoolDistrictByID?schoolDistrictId=${id}&userId=${LogDetail?.userId}`,
      { headers }
    )
      .then((res) => {
        if (res?.status === 200) {
          if (res.data.success) {
            let sdDetail = res.data.payload;
            setValue("schoolDistrictId", sdDetail?.schoolDistrictId);
            setValue("state", sdDetail?.state);
            setValue("districtName", sdDetail?.districtName);
            setValue("email", sdDetail?.email);
            setValue("phoneNumber", sdDetail?.phoneNumber);
            setValue("siteCode", sdDetail?.siteCode);
            setValue("city", sdDetail?.city);
            setValue("academicYearId", sdDetail?.academicYearId);
            setValue("rateType", sdDetail?.rateType);
            setValue("attendanceFactor", sdDetail?.attendanceFactor);
            setValue("IsEnableCashierPortal", sdDetail?.isEnableCashierPortal);
            setValue("logoName", sdDetail?.districtLogo);
            setOpenCircle(false);
          } else {
            setOpenCircle(false);
          }
        }
      })
      .catch((e) => {
        setOpenCircle(false);
        console.log(e);
      });
  };
  const getSchoolYear = async () => {
    // function convertYear(str) {
    //   var date = new Date(str);
    //   return date.getFullYear();
    // }
    // Date.prototype.addYears = function (n) {
    //   var now = new Date();
    //   return new Date(now?.setFullYear(now?.getFullYear() + n));
    // };
    // let schY = [...Array(5).keys()]?.map((item, i) => {
    //   let td = convertYear(new Date().addYears(5 - (i + 2)));

    //   td = td.toString().slice(2);
    //   let ny = convertYear(new Date().addYears(5 - (i + 1)));
    //   ny = ny.toString().slice(2);

    //   return {
    //     id: td + "-" + ny,
    //     year:
    //       convertYear(new Date().addYears(5 - (i + 2))) +
    //       "-" +
    //       convertYear(new Date().addYears(5 - (i + 1))),
    //   };
    // });
    // schY.reverse();


    let schApi = {
      url: `/api/SchoolAcademicYear/GetAllSchoolAcademicYearData`
    }

    let resp = await GetCallApi(schApi);
    if (resp?.status === 200) {
      if (resp?.data?.success) {
        let allD = resp?.data?.payload?.map((x) => {
          return {
            id: x?.academicYearId,
            year: x?.academicYear
          }
        })
        setSchoolYearList(allD);
      }
    }


    // let ny = [...Array(6)].map((a, b) => {
    //   let td = new Date().getFullYear() + b;
    //   td = td.toString().slice(2);
    //   let ny = new Date().getFullYear() + b + 1;
    //   ny = ny.toString().slice(2);

    //   return {
    //     id: td + "-" + ny,
    //     year:
    //       new Date().getFullYear() +
    //       b +
    //       "-" +
    //       (new Date().getFullYear() + b + 1),
    //   };
    // });
    // setSchoolYearList(ny);
  };

  const [src, setSrc] = useState(null);
  const [filename, setFileName] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [openCropModal, setCropModal] = useState(false);
  const setCroppedImageUrlCallback = useCallback(
    (url) => {
      if (url) {
        setValue("DistrictLogo", url);
        setValue("logoName", url.name);
        setCroppedImageUrl(url);
        setSrc(null);
      } else {
        setSrc(null);
      }
    },
    // eslint-disable-next-line
    [croppedImageUrl]
  );
  const handleFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setFileName(e.target.files[0].name);
      reader.addEventListener(
        "load",
        () => {
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
    }
  };

  return (
    <>
      <Backdrop
        open={openCircle}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="createprimarytab-page">
          <div className="main_wrapper">
            <h4 className="all_title mb-4">District Management</h4>
            <div className="card">
              <div className="card-body">
                <form
                  action=""
                  onSubmit={handleSubmit(onSubmit)}
                  className="primary-box"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          District Name <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="District Name"
                          {...register("districtName", {
                            required: "This field is required",
                          })}
                        />
                        {errors.districtName && (
                          <span role="alert" className="error_text">
                            {errors.districtName.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Site Code <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Site Code"
                          {...register("siteCode", {
                            required: "This field is required",
                          })}
                        />
                        {errors.siteCode && (
                          <span role="alert" className="error_text">
                            {errors.siteCode.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          City <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter City"
                          {...register("city", {
                            required: "This field is required",
                          })}
                        />
                        {errors.city && (
                          <span role="alert" className="error_text">
                            {errors.city.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Attendence factor last year{" "}
                          <span className="red-color">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Attendence factor last year"
                          {...register("attendanceFactor", {
                            required: "This field is required",
                            pattern: {
                              value: /^\d+(\.\d{1,2})?$/,
                              message:
                                "Enter valid ceu the value you have entered is not in correct format. please enter correct format  ex. 0.75 or 12",
                            },
                            validate: (val) => {
                              if (100 < val) {
                                return "You can not enter more than 100";
                              }
                            },
                          })}
                        />
                        {errors.attendanceFactor && (
                          <span role="alert" className="error_text">
                            {errors.attendanceFactor.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Phone <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone"
                          {...register("phoneNumber", {
                            required: "This field is required",
                            pattern: {
                              value:
                                /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                              // value: /^[0-9+-]+$/,
                              message: "Enter valid phone number",
                            },
                          })}
                        />
                        {errors.phoneNumber && (
                          <span role="alert" className="error_text">
                            {errors.phoneNumber.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          Email <span className="red-color">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email"
                          {...register("email", {
                            required: "This field is required",
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                        />
                        {errors.email && (
                          <span role="alert" className="error_text">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          State <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          defaultValue=""
                          {...register("state", {
                            required: "This field is required",
                            valueAsNumber: true,
                          })}
                        >
                          <option value="">Select State</option>
                          {siteList &&
                            siteList?.map((data, i) => (
                              <option key={i} value={data?.stateId}>
                                {data?.stateName}
                              </option>
                            ))}
                        </select>
                        {errors.state && (
                          <span role="alert" className="error_text">
                            {errors.state.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          School Year <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          defaultValue=""
                          {...register("academicYearId", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select School Year</option>
                          {schoolYearList &&
                            schoolYearList?.map((data, i) => (
                              <option key={i} value={data?.id}>
                                {data?.year}
                              </option>
                            ))}
                        </select>
                        {errors.academicYearId && (
                          <span role="alert" className="error_text">
                            {errors.academicYearId.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>
                          {" "}
                          Rate Type <span className="red-color">*</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("rateType", {
                            required: "This field is required",
                          })}
                        >
                          <option value="">Select Rate Type</option>
                          <option value="HIGH">HIGH</option>
                          <option value="REG">REG</option>
                        </select>
                        {errors.rateType && (
                          <span role="alert" className="error_text">
                            {errors.rateType.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label">District Logo</label>
                        <div className="upload_district_logo">
                          <input
                            type="text"
                            className="form-control upload_box"
                            placeholder="No file Chosen"
                            readOnly
                            {...register("logoName", {
                              // required: "This field is required",
                            })}
                          />
                          <input
                            type="file"
                            id="vendor_logo"
                            accept="image/*"
                            className="form-control"
                            style={{ display: "none" }}
                            onChange={handleFile}
                          />
                          <label
                            htmlFor="vendor_logo"
                            className="upload_img_label mb-0 "
                          >
                            <img src={uploadimg} className="img-fluid" alt="" />
                          </label>
                        </div>
                        {/* {errors.LogoImage && (
                          <span role="alert" className="error_text">
                            {errors.LogoImage.message}
                          </span>
                        )} */}
                        {src && (
                          <CropNewModel
                            src={src}
                            filename={filename}
                            setCroppedImageUrl={setCroppedImageUrlCallback}
                            openCropModal={openCropModal}
                            setCropModal={setCropModal}
                            maxHeight={"400"}
                            maxWidth={"400"}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group checkbox_meal">
                        <label className="personal_detail_label">
                          <input
                            type="checkbox"
                            className="login_check"
                            name="Remember Me"
                            {...register("IsEnableCashierPortal")}
                          />
                          <span className="checkmark"> </span>
                          Is Enable Cashier Portal  {" "}
                        </label>
                      </div>
                    </div>
                    {/* <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      District Users <span className="red-color">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="District Users"
                      {...register("districtUser", {
                        required: "This field is required",
                        pattern: {
                          message: "districtUser",
                        },
                      })}
                    />
                    {errors.districtUser && (
                      <span role="alert" className="error_text">
                        {errors.districtUser.message}
                      </span>
                    )}
                  </div>
                </div> */}
                    <div className="col-md-12 text-end">
                      <Button type="submit" className="theme_btn me-2">
                        Submit
                      </Button>
                      {/* <Button className="cancel_btn" onClick={()=>navigate("/district-setting",{replace:true})}>Cancel</Button> */}
                      <Button
                        className="cancel_btn"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default CreatedistrictSeeting;
