import React, { Suspense } from "react";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

const renderLoader = () => (
  <div className="loader">
    <CircularProgress sx={{ color: "#86c65a" }} />
  </div>
);

function HourlySalaryReportTab(props) {
  const { tableRef, startDate, endDate, back } = props;
  // const { tableRef, repoData, startDate, endDate, back } = props;
  const logSchoolDetail = useSelector(
    (state) => state.login.LoginDetails?.logSchoolDetail
  );
  const reporttable = [
    {
      hour: "Hour",
      bill: "Bill",
      amount: "Amount",
      amt: "Amt%",
      classset: "cash-border",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "00:00-00:30",
      bill: "0",
      amount: "0.00",
      amt: "0.00",
    },
    {
      hour: "Total",
      bill: "",
      amount: "",
      amt: "$0.00",
      classset: "cash-border",
    },
  ];

  return (
    <Suspense fallback={renderLoader()}>
      <div className="dailySalesReport-page" ref={tableRef} style={back}>
        <div className="d-flex justify-content-between">
          <div>
            <h5>Half Hour Sales Report</h5>
            <p>
              {startDate?.toLocaleDateString() +
                " - " +
                endDate?.toLocaleDateString()}
            </p>
          </div>
          <div>
            <h5>{logSchoolDetail?.schoolName}</h5>
            <p className="mb-0">{logSchoolDetail?.address}</p>
            <p>
              {logSchoolDetail?.city}{" "}
              {logSchoolDetail?.zip ? ", " + logSchoolDetail?.zip : ""}
            </p>
          </div>
        </div>
        <div className="border-dash"></div>
        <h6 className="mt-3">POS</h6>
        <h5 className="text-uppercase">Hourly Salary Report</h5>
        <div className="report-table">
          {reporttable?.map((text, i) => (
            <div
              key={i}
              disablePadding
              className={text?.classset ? "table-box cash-border" : "table-box"}
            >
              <h6 className="title-width">{text?.hour}</h6>
              <h6 className="title-width">{text?.bill}</h6>
              <h6 className="title-width">{text?.amount}</h6>
              <h6 className="title-width">{text?.amt}</h6>
            </div>
          ))}
          <h6 className="text-center endreport-border">
            ------End Report-------
          </h6>
        </div>
      </div>
    </Suspense>
  );
}

export default HourlySalaryReportTab;
