import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import "./index.scss";
import { useFieldArray, useForm } from "react-hook-form";
import { BsPlusSquareDotted } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import uploadimg from "../../../../Assets/SvgImage/file-upload.svg";
import {
  DeleteCallApi,
  GetCallApi,
  PostCallApi,
} from "../../../../Action/Action";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

const renderLoader = () => <p></p>;

function StudentDocumentUpload() {
  const [studentId, setStudentId] = useState();
  const [open, setOpen] = useState(false);
  // const [box, setBox] = useState(false);
  // const [passwordShown, setPasswordShown] = useState(false);
  const [stdList, setStdList] = useState([]);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "document",
  });

  const LogDetail = useSelector((state) => state.login.LoginDetails);

  const token = useSelector((state) => state.login?.LoginDetails.accessToken);

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "*/*",
  };

  useEffect(() => {
    getStudentList();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //     const data = [
  //         {
  //             fileName: "",
  //             docImage: "",
  //         }
  //     ]
  //     setValue("document", data)
  //     setValue(`document.${0}.docImage`, "")
  //     setValue(`document.${0}.fileName`, "")
  //     // eslint-disable-next-line
  // }, [studentId])

  useEffect(() => {
    if (studentId?.length > 0) {
      getEditData(studentId);
    }
    // eslint-disable-next-line
  }, [studentId]);

  const handleFile = (e, i) => {
    if (e.target.files && e.target.files.length > 0) {
      setValue(`document.${i}.docImage`, e.target.files[0].name);
      setValue(`document.${i}.File`, e.target.files[0]);
      e.target.value = null;
    }
  };

  const getStudentList = async () => {
    let apiUrl = {
      url: `/api/ParentModule/GetStudentListByParentID?parentGuidId=${LogDetail?.parentsGuidId}`,
      headers: headers,
    };
    let res = await GetCallApi(apiUrl);
    if (res?.status === 200) {
      if (res.data.success) {
        const dlist = res.data.payload?.map((sd) => {
          return {
            value: sd?.studentGuidId,
            label: sd?.studentName,
          };
        });
        setStdList(dlist?.length > 0 ? dlist : []);
      }
    }
  };

  const getEditData = async (sId) => {
    setOpen(true);
    if (sId) {
      setValue("document", []);
      let editId = {
        url: `/api/StudentDocuments/GetStudentDocumentsById?studentId=${sId}&parentId=${LogDetail?.parentsGuidId}`,
        headers: headers,
      };
      const response = await GetCallApi(editId);
      setOpen(false);
      if (response?.status === 200) {
        if (response?.data?.success) {
          let allData = response?.data?.payload?.map((x, i) => {
            return {
              ...x,
            };
          });
          if (allData?.length > 0) {
            setValue(
              "document",
              allData?.map((x) => {
                return {
                  fileName: x?.documentName,
                  docImage: x?.documentFile,
                  studentDocumentId: x?.studentDocumentId,
                };
              })
            );
          } else {
            append({
              fileName: "",
              docImage: "",
            });
          }
        } else {
          append({
            fileName: "",
            docImage: "",
          });
        }
      }
    }
  };

  const onSubmit = async (data) => {
    setOpen(true);
    let oldArray = [];
    let formData = new FormData();
    formData.append(
      `StudentDocumentId`,
      getValues("studentDocumentId") ? getValues("studentDocumentId") : ""
    );
    formData.append(`StudentId`, studentId);
    formData.append(`DocumentName`, "");
    data?.document?.map((x) => {
      if (x?.File?.name) {
        return formData.append(
          `DocumentFile`,
          x?.File,
          x?.File?.name + ";" + x?.fileName
        );
      }
      return oldArray.push({
        DocumentName: x?.fileName,
        DocumentFile: x?.docImage,
        StudentDocumentId: x?.studentDocumentId,
      });
    });
    formData.append(`ParentId`, LogDetail?.parentsGuidId);

    formData.append(`CreatedBy`, LogDetail?.parentsGuidId);
    if (data?.document?.length === oldArray?.length) {
      let sdOld = {
        url: `/api/StudentDocuments/EditStudentDocuments`,
        body: {
          oldDocumentFile: oldArray,
          createdBy: LogDetail?.parentsGuidId,
        },
      };
      const respo = await PostCallApi(sdOld);
      setOpen(false);

      if (respo?.status === 200) {
        if (respo?.data?.success) {
          setValue("student", "");
          setStudentId("");
          reset({ document: [] });
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: respo.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      }
    } else {
      let seData = {
        url: `/api/StudentDocuments/CreateStudentDocuments`,
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      };

      const respo = await PostCallApi(seData);
      setOpen(false);
      if (respo?.status === 200) {
        if (respo?.data?.success) {
          if (oldArray?.length > 0) {
            let sdOld = {
              url: `/api/StudentDocuments/EditStudentDocuments`,
              body: {
                oldDocumentFile: oldArray,
                createdBy: LogDetail?.parentsGuidId,
              },
            };
            PostCallApi(sdOld);
          }
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: respo.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
          setValue("student", "");
          setStudentId("");
          reset({ document: [] });
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: respo?.data?.message
              ? respo?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: respo?.data?.message
            ? respo?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };

  const handleDelete = async (sdId, ind) => {
    setOpen(true);
    if (sdId && ind) {
      let delUrl = {
        url: `/api/StudentDocuments/DeleteStudentDocument?studentDocumentId=${sdId}`,
        headers: headers,
      };
      const re = await DeleteCallApi(delUrl);
      setOpen(false);
      if (re?.status === 200) {
        if (re?.data?.success) {
          remove(ind);
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: re.data?.message,
            showConfirmButton: false,
            timer: 1500,
            color: "#ffffff",
            background: "#48953e",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        } else {
          Swal.fire({
            position: "top-end",
            width: 400,
            // icon: "success",
            text: re?.data?.message
              ? re?.data?.message
              : "Something went wrong please try again.",
            showConfirmButton: false,
            timer: 2000,
            color: "#ffffff",
            background: "red",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutRight",
            },
          });
        }
      } else {
        Swal.fire({
          position: "top-end",
          width: 400,
          // icon: "success",
          text: re?.data?.message
            ? re?.data?.message
            : "Something went wrong please try again.",
          showConfirmButton: false,
          timer: 2000,
          color: "#ffffff",
          background: "red",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutRight",
          },
        });
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        // open={openCircle}
      >
        <CircularProgress sx={{ color: "#86c65a" }} />
      </Backdrop>
      <Suspense fallback={renderLoader()}>
        <section className="upload_list">
          <div className="main_wrapper">
            <div className="card">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="box_name">
                    <div className="all_title ProductTitle">
                      Student Document Upload
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-5">
                      <div className="form-group">
                        <label>
                          Student
                          <span className="red-color"> *</span>
                        </label>
                        <select
                          name=""
                          className="form-select"
                          {...register("student", {
                            required: "This field is required",
                            onChange: (e) => {
                              setStudentId(e?.target?.value);
                              // setBox(false);
                            },
                          })}
                        >
                          <option value="">Select Student</option>
                          {stdList.map((item, i) => (
                            <option key={i} value={item?.value}>
                              {item?.label}
                            </option>
                          ))}
                        </select>
                        {errors?.student && (
                          <span role="alert" className="error_text">
                            {errors.student.message}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div class="col-md-3 mt-5">
                                            <div class="form-group">
                                                <label class="personal-detail-label">
                                                    <input type="checkbox"
                                                        class="login-check"
                                                        name="Remember Me"
                                                        disabled={studentId ? false : true}
                                                        checked={!studentId ? false : box}
                                                        onChange={(e) => setBox(e?.target?.checked === true ? true : false)} />
                                                    <span class="checkmark"></span>
                                                    <span class="label-text">Is Confidential</span>
                                                </label>
                                            </div>
                                        </div>
                                        {box && studentId &&
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label className="form-label label_with_icon">
                                                        Password
                                                    </label>
                                                    <div className="d-flex position-relative align-items-center">
                                                        <input
                                                            type={passwordShown ? "text" : "password"}
                                                            className="form-control"
                                                            placeholder="Password"
                                                            {...register("password", {
                                                                required: {
                                                                    value: true,
                                                                    message: "This field is required",
                                                                },
                                                                // minLength: {
                                                                //     value: 8,
                                                                //     message: "Must be 8 characters",
                                                                // },
                                                                // maxLength: {
                                                                //     value: 15,
                                                                //     message: "Max 15 characters",
                                                                // },
                                                            })}
                                                        />
                                                        <span
                                                            className="eye"
                                                            onClick={() => setPasswordShown(!passwordShown)}
                                                        >
                                                            {passwordShown ? (
                                                                <FiEye color="#31B680" />
                                                            ) : (
                                                                <FiEyeOff color="#31B680" />
                                                            )}
                                                        </span>
                                                    </div>
                                                    {errors.password && (
                                                        <span role="alert" className="error_text">
                                                            {errors.password.message}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        } */}
                    <div className="mt-3">
                      {studentId?.length > 0 &&
                        fields?.map((x, index) => (
                          <div className="row" key={index}>
                            <div className="col-md-5">
                              <div className="form-group">
                                <label>
                                  Document Name
                                  <span className="red-color"> *</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Document Name"
                                  {...register(`document.${index}.fileName`, {
                                    required: "This field is required",
                                  })}
                                />
                                {errors.document?.[index]?.fileName && (
                                  <span role="alert" className="error_text">
                                    {errors.document?.[index]?.fileName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              {/* <div className="form-group">
                                                                <label>
                                                                    Document File
                                                                    <span className="red-color"> *</span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    placeholder="Document File"
                                                                    {...register(`document.${i}.file`, {
                                                                        required: "This field is required",
                                                                    })}
                                                                />
                                                                {errors.document?.[i]?.file && (
                                                                    <span role="alert" className="error_text">
                                                                        {errors.document?.[i]?.file.message}
                                                                    </span>
                                                                )}
                                                            </div> */}
                              <div className="form-group">
                                <label>
                                  Document File
                                  <span className="red-color"> *</span>
                                </label>
                                <div className="upload_img">
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      x?.studentDocumentId ? "" : "upload_box"
                                    }`}
                                    placeholder="No file Chosen"
                                    readOnly
                                    {...register(`document.${index}.docImage`, {
                                      required: "This field is required",
                                    })}
                                  />

                                  <input
                                    type="file"
                                    id={`school_img_upload_${index}`}
                                    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*, video/*"
                                    // accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="form-control"
                                    style={{ display: "none" }}
                                    onChange={(e) => handleFile(e, index)}
                                  />
                                  {x?.studentDocumentId ? (
                                    ""
                                  ) : (
                                    <label
                                      htmlFor={`school_img_upload_${index}`}
                                      className="upload_img_label mb-0"
                                    >
                                      <img
                                        src={uploadimg}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </label>
                                  )}
                                </div>
                                {errors?.document?.[index]?.docImage && (
                                  <span role="alert" className="error_text">
                                    {
                                      errors?.document?.[index]?.docImage
                                        ?.message
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                            {getValues("studentDocumentId")}
                            <div className="col-md-1">
                              {index === 0 ? (
                                <>
                                  <button
                                    className="mt-5 p-0 border-0"
                                    style={{
                                      background: "transparent",
                                    }}
                                    onClick={() =>
                                      append({ fileName: "", docImage: "" })
                                    }
                                  >
                                    <BsPlusSquareDotted
                                      fontSize={30}
                                      color={"#80c242"}
                                    />
                                  </button>
                                  {fields?.length > 1 ? (
                                    x?.studentDocumentId ? (
                                      <button
                                        className="mt-5 p-0 border-0 ms-2"
                                        style={{
                                          background: "transparent",
                                        }}
                                        onClick={() =>
                                          handleDelete(
                                            x?.studentDocumentId,
                                            index
                                          )
                                        }
                                      >
                                        <MdDelete
                                          className="icon"
                                          fontSize={30}
                                          color={"#80c242"}
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        className="mt-5 p-0 border-0 ms-2"
                                        style={{
                                          background: "transparent",
                                        }}
                                        onClick={() => remove(index)}
                                      >
                                        <MdDelete
                                          className="icon"
                                          fontSize={30}
                                          color={"#80c242"}
                                        />
                                      </button>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </>
                              ) : x?.studentDocumentId ? (
                                <button
                                  className="mt-5 p-0 border-0"
                                  style={{
                                    background: "transparent",
                                  }}
                                  onClick={() =>
                                    handleDelete(x?.studentDocumentId, index)
                                  }
                                >
                                  <MdDelete
                                    className="icon"
                                    fontSize={30}
                                    color={"#80c242"}
                                  />
                                </button>
                              ) : (
                                <button
                                  className="mt-5 p-0 border-0"
                                  style={{
                                    background: "transparent",
                                  }}
                                  onClick={() => remove(index)}
                                >
                                  <MdDelete
                                    className="icon"
                                    fontSize={30}
                                    color={"#80c242"}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    {studentId?.length > 0 && (
                      <div className="ms-auto mt-5">
                        <div className="form-group text-end filter-img">
                          {open ? (
                            <Button className="theme_btn text-end me-3">
                              Uploading{" "}
                              <CircularProgress
                                sx={{
                                  color: "#fff",
                                  marginLeft: "0.5rem",
                                  height: "23px !important",
                                  width: "23px !important",
                                }}
                              />
                            </Button>
                          ) : (
                            <Button
                              className="theme_btn text-end me-3"
                              type="submit"
                            >
                              Upload Document
                            </Button>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Suspense>
    </>
  );
}

export default StudentDocumentUpload;
