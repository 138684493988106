import React from "react";

function CountBox(props) {
  return (
    <div className="box_dash mb-4" >
      {/* <div className="col-md-3 mb-4" > */}
      <div className="meal_sub_box p-3 text-center">
        <img src={props?.image} alt="" />
        <div className="m_name">{props?.name}</div>
        <div className="m_num">{props?.num}</div>
      </div>
    </div>
  );
}

export default CountBox;
